import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MediaControlIDList } from 'wisenet-ui/util/static/constants/mediaControl/mediaControlType';
import {
  LiveMediaControlActions,
  MediaControlActions,
  LayoutActions,
  ThemeActions,
  CameraInfoActions,
} from 'store/actionCreators';
import { List } from 'immutable';

class LiveMediaControlBarContainer extends React.Component {
  state = {
    showExportPopup: false,
    showStatusPopup: false,
    patternDropDownVisible: false,
    showRecordIcon: true,
    showAlarmIcon: true,
    showExportIcon: true,
  };

  componentDidMount() {
    this.checkAccessInfo();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const { accessInfo, isMaunalRecord } = this.props;
    const { accessInfo: prevAccessInfo, isMaunalRecord: prevIsMaunalRecord } = prevProps;
    if (JSON.stringify(accessInfo) !== JSON.stringify(prevAccessInfo)
    || JSON.stringify(isMaunalRecord) !== JSON.stringify(prevIsMaunalRecord)) {
      this.checkAccessInfo();
    }
  }

  checkAccessInfo = () => {
    const { isMaunalRecord, accessInfo } = this.props;
    if (isMaunalRecord) {
      if (accessInfo.recordStopAccess) {
        this.onUpdate({
          showRecordIcon: true,
        });
      } else {
        this.onUpdate({
          showRecordIcon: false,
        });
      }
    } else if (accessInfo.recordStartAccess) {
      this.onUpdate({
        showRecordIcon: true,
      });
    } else {
      this.onUpdate({
        showRecordIcon: false,
      });
    }

    if (!accessInfo.alarmOutputAccess) {
      this.onUpdate({
        showAlarmIcon: false,
      });
    } else {
      this.onUpdate({
        showAlarmIcon: true,
      });
    }

    if (accessInfo.backupAccess) {
      this.onUpdate({
        showExportIcon: true,
      });
    } else {
      this.onUpdate({
        showExportIcon: false,
      });
    }
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  onClick = type => event => {
    const { isMaunalRecord, accessInfo } = this.props;

    switch (type) {
      case MediaControlIDList.RECORD:
        if (isMaunalRecord) {
          if (accessInfo.recordStopAccess) {
            LiveMediaControlActions.manualRecordingStop();
          }
        } else if (accessInfo.recordStartAccess) {
          LiveMediaControlActions.manualRecordingStart();
        }
        break;
      case MediaControlIDList.EXPORT:
        if (accessInfo.backupAccess) {
          this.setState({
            showExportPopup: true,
          });
        }
        break;
      case MediaControlIDList.SOUND:
        break;
      case MediaControlIDList.ALARM:
        if (accessInfo.alarmOutputAccess) {
          LiveMediaControlActions.alarmStop();
        }
        break;
      case MediaControlIDList.OSD:
        LiveMediaControlActions.OSDDisplayControl();
        break;
      case MediaControlIDList.CH_INFO:
        LiveMediaControlActions.channelInfoVisibleControl();
        break;
      case MediaControlIDList.STATUS:
        this.onChangeStatusPopupVisible();
        break;
      case MediaControlIDList.LAYOUT_PAGE_LEFT: {
        const { layoutPageCurrentNumber } = this.props;
        if (layoutPageCurrentNumber > 1) {
          LiveMediaControlActions.setLayoutPageCurrentNumber({
            layoutPageCurrentNumber: layoutPageCurrentNumber - 1,
          });
        }
        break;
      }
      case MediaControlIDList.LAYOUT_PAGE_INPUT: {
        const value = Number(event.target.value);
        const { layoutPageMaxNumber } = this.props;
        if (value >= 1 && value <= layoutPageMaxNumber) {
          LiveMediaControlActions.setLayoutPageCurrentNumber({
            layoutPageCurrentNumber: value,
          });
        }
        break;
      }
      case MediaControlIDList.LAYOUT_PAGE_RIGHT: {
        const { layoutPageCurrentNumber, layoutPageMaxNumber, haveEmptyPage } = this.props;
        if (!haveEmptyPage && layoutPageCurrentNumber === layoutPageMaxNumber) {
          CameraInfoActions.addEmptyTileCameraListPage({
            addPageNumber: layoutPageMaxNumber + 1,
          });
          LiveMediaControlActions.setLayoutPageInfo({
            layoutPageMaxNumber: layoutPageMaxNumber + 1,
            layoutPageCurrentNumber: layoutPageCurrentNumber + 1,
          });
        } else if (layoutPageCurrentNumber < layoutPageMaxNumber) {
          LiveMediaControlActions.setLayoutPageCurrentNumber({
            layoutPageCurrentNumber: layoutPageCurrentNumber + 1,
          });
        }
        break;
      }
      case MediaControlIDList.PATTERN: {
        const { pattern: propsPattern } = this.props;
        const pattern = event.target.value;
        if (propsPattern !== pattern) {
          LiveMediaControlActions.patternControl({ pattern });
        }
        break;
      }
      case MediaControlIDList.ASPECT_RATIO: {
        const { tileCameraList } = this.props;
        if (tileCameraList.size !== 0) {
          MediaControlActions.aspectRatioAllControl({ tileCameraList });
        }
        break;
      }
      case MediaControlIDList.FULLSCREEN:
        this.onFullScreen();
        break;
      default:
        break;
    }
  };

  onFullScreen = () => {
    LayoutActions.fullscreenModeChange(true);
    ThemeActions.changeTheme({
      theme: 'black',
      setStorage: false,
    });
    if (document.body.requestFullscreen) {
      // chrome, firefox(v 66.0.5)
      document.body.addEventListener('fullscreenchange', this.fullscreenModeOff);
      document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
      // edge, safari
      document.body.addEventListener('webkitfullscreenchange', this.fullscreenModeOff);
      document.body.webkitRequestFullscreen();
    }
  }

  fullscreenModeOff = () => {
    const fullScreenElement = document.fullscreenElement
      || document.msFullscreenElement
      || document.mozFullScreenElement
      || document.webkitFullscreenElement;
    if (!fullScreenElement) {
      if (document.body.requestFullscreen) {
        // chrome, firefox(v 66.0.5)
        document.body.removeEventListener('fullscreenchange', this.fullscreenModeOff);
      } else if (document.body.webkitRequestFullscreen) {
        // edge, safari
        document.body.removeEventListener('webkitfullscreenchange', this.fullscreenModeOff);
      }
      ThemeActions.changeTheme({
        theme: localStorage.getItem('WISENET-NVR-THEME'),
        setStorage: false,
      });
      LayoutActions.fullscreenModeChange(false);
    }
  }

  onExportCancel = () => {
    this.setState({
      showExportPopup: false,
    });
  }

  onChangeStatusPopupVisible = () => {
    const { showStatusPopup } = this.state;
    this.setState({
      showStatusPopup: !showStatusPopup,
    });
  }

  onChangePatternDropdownVisible = () => {
    const { patternDropDownVisible } = this.state;
    this.setState({
      patternDropDownVisible: !patternDropDownVisible,
    });
  }

  render() {
    const {
      render,
    } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

LiveMediaControlBarContainer.defaultProps = {
  isMaunalRecord: false,
};

LiveMediaControlBarContainer.propTypes = {
  render: PropTypes.func.isRequired,
  tileCameraList: PropTypes.instanceOf(List).isRequired,
  layoutPageCurrentNumber: PropTypes.number.isRequired,
  layoutPageMaxNumber: PropTypes.number.isRequired,
  isMaunalRecord: PropTypes.bool,
  haveEmptyPage: PropTypes.bool.isRequired,
  accessInfo: PropTypes.instanceOf(Object).isRequired,
  pattern: PropTypes.string.isRequired,
};

export default connect(
  state => {
    const { currentNumber: layoutPageCurrentNumber, maxNumber } = state.liveMediaControlModule.get('layoutPageInfo').toJS();
    const stateTileCameraList = state.cameraInfoModule.get('tileCameraListPage');
    const tileCameraList = stateTileCameraList.toJS();
    const haveEmptyPage = tileCameraList[maxNumber].length === 0;
    return ({
      tileCameraList: stateTileCameraList.get(`${layoutPageCurrentNumber}`),
      layoutPageCurrentNumber,
      layoutPageMaxNumber: maxNumber,
      isMaunalRecord: state.liveMediaControlModule.get('isMaunalRecord'),
      haveEmptyPage,
      pattern: state.liveMediaControlModule.get('pattern'),
      accessInfo: state.systemInfomationModule.get('accessInfo').toJS(),
    });
  },
)(LiveMediaControlBarContainer);
