import React from 'react';
import PropTypes from 'prop-types';
import { VideoTile } from 'wisenet-ui/components/organisms';
import { PlaybackVideoTileControlBar } from 'components/molecules';
import { PlaybackVideoTileContainer } from 'containers/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import umpPlayMode from 'wisenet-ui/util/static/constants/umpPlayer/umpPlayMode';
import {
  PlaybackVideoTileStyled,
  OSDWrapperStyled,
  PlaybackVideoTostStyled,
} from './PlaybackVideoTileStyled';

class PlaybackVideoTile extends React.PureComponent {
  render() {
    const {
      channelName,
      OSDDisplay,
      onMouseEvent,
      openTileControl,
      onTileBarClick,
      setRef,
      openToast,
      timeoutS,
      ...rest
    } = this.props;
    return (
      <PlaybackVideoTileStyled
        onMouseEnter={onMouseEvent('enter')}
        onMouseLeave={onMouseEvent('leave')}
      >
        <VideoTile {...this.props} type={umpPlayMode.PLAYBACK} />
        {
          OSDDisplay && (
            <OSDWrapperStyled>
              <span>{channelName}</span>
            </OSDWrapperStyled>
          )
        }
        {
          openTileControl && (
            <PlaybackVideoTileControlBar onClick={onTileBarClick} {...rest} />
          )
        }
        {
          <PlaybackVideoTostStyled
            timeoutS={timeoutS}
            ref={setRef}
          />
        }
      </PlaybackVideoTileStyled>
    );
  }
}

PlaybackVideoTile.defaultProps = {
  timeoutS: 6,
};

PlaybackVideoTile.propTypes = {
  channelName: PropTypes.string.isRequired,
  OSDDisplay: PropTypes.bool.isRequired,
  onMouseEvent: PropTypes.func.isRequired,
  openTileControl: PropTypes.bool.isRequired,
  onTileBarClick: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  openToast: PropTypes.func.isRequired,
  timeoutS: PropTypes.number,
};

export default withContainer(PlaybackVideoTileContainer, PlaybackVideoTile);
