import React from 'react';
import { CalendarContainer as OriginalCalender } from 'react-datepicker';
import PropTypes from 'prop-types';
import CustomTimePicker from './CustomTimePicker';
import {
  Container,
  TimePickerWrapper,
} from './CalendarContainerStyled';
import DateTimeSubmitter from './DateTimeSubmitter';

const EndCalendarContainer = ({
  className,
  children,
  id,
  actions,
  dateTimeObj,
}) => {
  const customId = typeof id !== 'undefined' ? id : 'end-date-picker-popper';
  return (
    <Container id={customId}>
      <OriginalCalender className={className}>
        {children}
      </OriginalCalender>
      <TimePickerWrapper>
        <CustomTimePicker
          label="end"
          actions={actions}
          dateTimeObj={dateTimeObj}
        />
      </TimePickerWrapper>
      <DateTimeSubmitter
        id={customId}
        flag="end"
        actions={actions}
      />
    </Container>
  );
};

EndCalendarContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.string,
  actions: PropTypes.objectOf(PropTypes.any),
  dateTimeObj: PropTypes.objectOf(PropTypes.any),
};

EndCalendarContainer.defaultProps = {
  className: '',
  children: null,
  id: '',
  actions: null,
  dateTimeObj: {},
};

export default EndCalendarContainer;
