import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SearchTimelineActions, LoadingActions } from 'store/actionCreators';
import { withRouter } from 'react-router-dom';

class TileInstantPlaybackControlContainer extends React.Component {
  onMouseEvent = type => event => {
    switch (type) {
      case 'static': {
        event.stopPropagation();
        break;
      }
      case 'backMode': {
        const { returnTileMode } = this.props;
        returnTileMode();
        break;
      }
      case 'search': {
        // page move
        const {
          currentChannel,
          history,
          systemDataInfo,
          instantPlaybackSeekingTime,
          instantPlayTime,
        } = this.props;

        SearchTimelineActions.setCurrentChannel(currentChannel);
        const convertUTCTime = new Date(`${systemDataInfo.UTCTime.replace(/ /g, 'T')}Z`);
        const currentTime = instantPlaybackSeekingTime - instantPlayTime;
        const currentDate = new Date(
          convertUTCTime.setSeconds(convertUTCTime.getSeconds() + currentTime - 60),
        ).toISOString();

        SearchTimelineActions.setInstantPlaybackSeeking({
          currentTime: currentDate,
          localTime: systemDataInfo.LocalTime,
        });
        history.push('/search');
        break;
      }
      case 'timePicker': {
        const { setInstantPlaybackSeekingTime } = this.props;
        setInstantPlaybackSeekingTime(event);
        break;
      }
      case 'timePickerUp': {
        // mouseup을 할때 ump seek 동작을 수행하기 위해서 구분
        const { setInstantPlaybackSeekingTime } = this.props;
        setInstantPlaybackSeekingTime(event, false, true);
        break;
      }
      case 'playControl': {
        const {
          setInstantPlaybackMode,
          instantPlaybackMode,
          instantPlaybackSeekingTime,
          instantPlayTime,
          instantPlaybackTimeRage: { endTime },
          setInstantPlaybackSeekingTime,
          uid,
        } = this.props;
        const mode = instantPlaybackMode === 'play' ? 'pause' : 'play';
        if (instantPlaybackSeekingTime - instantPlayTime === endTime) {
          if (mode === 'play') {
            LoadingActions.setLayoutTilePendding({ uid, tilePendding: true });
            setInstantPlaybackSeekingTime(0, false, true);
            // ump가 seek을 하는 시간을 기다린 후에 재생을 하기위해서 setTimeout으로 구현함.
            setTimeout(() => {
              LoadingActions.setLayoutTilePendding({ uid, tilePendding: false });
              setInstantPlaybackMode(mode);
            }, 1000);
          } else {
            setInstantPlaybackMode(mode);
          }
        } else {
          setInstantPlaybackMode(mode);
        }
        break;
      }
      default:
        break;
    }
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  render() {
    const { render } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

TileInstantPlaybackControlContainer.propTypes = {
  render: PropTypes.func.isRequired,
  returnTileMode: PropTypes.func.isRequired,
  currentChannel: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  setInstantPlaybackSeekingTime: PropTypes.func.isRequired,
  setInstantPlaybackMode: PropTypes.func.isRequired,
  instantPlaybackMode: PropTypes.string.isRequired,
  systemDataInfo: PropTypes.instanceOf(Object).isRequired,
  instantPlaybackSeekingTime: PropTypes.number.isRequired,
  instantPlayTime: PropTypes.number.isRequired,
  instantPlaybackTimeRage: PropTypes.instanceOf(Object).isRequired,
  uid: PropTypes.string.isRequired,
};

export default withRouter(connect(
  state => ({
    systemDataInfo: state.liveMediaControlModule.get('systemDataInfo'),
  }),
)(TileInstantPlaybackControlContainer));
