import styled from 'styled-components';
import { TileControlBar } from 'wisenet-ui/components/molecules';

export const LiveVideoTileControlBarStyled = styled(TileControlBar)`
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
`;

export const RiseLiveVideoTileControlBarStyled = styled(TileControlBar)`
  position: absolute;
  bottom: 55px;
  left: 50%;
  transform: translateX(-50%);
`;
