import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { TileInstantPlaybackControlContainer } from 'containers/organisms';
import { getLanguage } from 'util/lib';
import {
  TileInstantPlaybackControlWrapperStyled,
  PlayControlIconStyled,
  TileInstantPlaybackControlBottomWrapperStyled,
  IconStyled,
  SpanStyle,
  SliderStyle,
  CustomReactTooltip,
  TooltipSpan,
} from './TileInstantPlaybackControlStyled';

class TileInstantPlaybackControl extends PureComponent {
  render() {
    const {
      onMouseEvent,
      instantPlaybackSeekingTime,
      instantPlaybackMode,
      instantPlaybackTimeRage,
      instantPlayTime,
    } = this.props;

    const { endTime } = instantPlaybackTimeRage;
    const langBack = 'lang_back';
    const langSec = 'lang_sec';
    const langGoToSearch = 'lang_search_more';

    return (
      <TileInstantPlaybackControlWrapperStyled
        onMouseDown={onMouseEvent('static')}
      >
        <PlayControlIconStyled
          className={`wni ${instantPlaybackMode === 'play' ? 'wni-close' : 'wni-play'}`}
          onClick={onMouseEvent('playControl')}
          onDoubleClick={onMouseEvent('static')}
        />
        <TileInstantPlaybackControlBottomWrapperStyled>
          <IconStyled
            className="wni wni-backmode"
            onClick={onMouseEvent('backMode')}
            onDoubleClick={onMouseEvent('static')}
            data-tip={langBack}
            data-for={langBack}
          >
            <CustomReactTooltip
              id={langBack}
              type="light"
              place="top"
              effect="float"
              delayShow={500}
              getContent={() => (
                <TooltipSpan>
                  {getLanguage(langBack)}
                </TooltipSpan>
              )}
            />
          </IconStyled>
          <IconStyled
            className="wni wni-search"
            onClick={onMouseEvent('search')}
            onDoubleClick={onMouseEvent('static')}
            data-tip={langGoToSearch}
            data-for={langGoToSearch}
          >
            <CustomReactTooltip
              id={langGoToSearch}
              type="light"
              place="top"
              effect="float"
              delayShow={500}
              getContent={() => (
                <TooltipSpan>
                  {getLanguage(langGoToSearch)}
                </TooltipSpan>
              )}
            />
          </IconStyled>
          <SliderStyle
            min={0}
            max={endTime}
            defaultValue={instantPlaybackSeekingTime - instantPlayTime}
            value={instantPlaybackSeekingTime - instantPlayTime}
            onChange={onMouseEvent('timePicker')}
            onAfterChange={onMouseEvent('timePickerUp')}
          />
          <SpanStyle>{`${instantPlaybackSeekingTime - instantPlayTime} ${getLanguage(langSec)}`}</SpanStyle>
          <SpanStyle>{`${endTime} ${getLanguage(langSec)}`}</SpanStyle>
        </TileInstantPlaybackControlBottomWrapperStyled>
      </TileInstantPlaybackControlWrapperStyled>
    );
  }
}

TileInstantPlaybackControl.propTypes = {
  onMouseEvent: PropTypes.func.isRequired,
  instantPlaybackSeekingTime: PropTypes.number.isRequired,
  instantPlaybackMode: PropTypes.string.isRequired,
  instantPlaybackTimeRage: PropTypes.shape({
    startTime: PropTypes.number,
    endTime: PropTypes.number,
  }).isRequired,
  instantPlayTime: PropTypes.number.isRequired,
};

export default withContainer(TileInstantPlaybackControlContainer, TileInstantPlaybackControl);
