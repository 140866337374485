import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  WrapperStyled,
  ToastBoxStyled,
} from './ToastStyled';

const getPosition = ({ placement, margin }) => {
  const position = {
    top: null,
    bottom: null,
    left: null,
    right: null,
  };
  switch (placement) {
    case 'top-left':
      position.top = margin;
      position.left = margin;
      break;
    case 'top-right':
      position.top = margin;
      position.right = margin;
      break;
    case 'bottom-right':
      position.top = margin;
      position.right = margin;
      break;
    default: // bottom-left
      position.bottom = margin;
      position.left = margin;
      break;
  }

  return position;
};

class Toast extends Component {
  state = {
    list: [],
  }

  openToast(content, isClear) {
    const { timeoutS } = this.props;
    const { list: _list } = this.state;
    const list = isClear ? [] : _list;
    const id = Math.max(...list.map(v => v.id), 0) + 1;

    list.push({
      id,
      content,
    });

    setTimeout(() => {
      this.closeToast(id);
    }, timeoutS * 1000);

    this.setState({ list });
  }

  closeToast(id) {
    const { list } = this.state;
    const index = list.findIndex(item => item.id === id);

    list.splice(index, 1);

    this.setState({ list });
  }

  render() {
    const {
      width,
      timeoutS,
      placement,
      margin,
      ...rest
    } = this.props;
    const { list } = this.state;
    const position = getPosition({ placement, margin });
    return (
      <WrapperStyled
        width={width}
        timeoutS={timeoutS}
        position={position}
        {...rest}
      >
        {
          list.map(item => (
            <ToastBoxStyled
              key={item.id}
              timeoutS={timeoutS}
              posAnimation={position.top ? 'top' : 'bottom'}
            >
              {item.content}
            </ToastBoxStyled>
          ))
        }
      </WrapperStyled>
    );
  }
}

Toast.propTypes = {
  timeoutS: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placement: PropTypes.string,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Toast.defaultProps = {
  timeoutS: 6,
  width: 200,
  placement: 'bottom-left',
  margin: 0,
};

export default Toast;
