import React from 'react';
import PropTypes from 'prop-types';
import { HeaderUtilContainer } from 'containers/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { Auth, getLanguage } from 'util/lib';
import { PopupContent } from 'components/molecules';
import { UserAuth } from 'components/organisms';
import {
  FuncWrapperStyled,
  ToggleStyled,
  IconButtonStyled,
  ModelNameStyled,
  DropDownMenuStyled,
  UserIDWrapperStyled,
  ButtonStyled,
  PopupStyled,
  PopupButtonStyled,
  CustomReactTooltip,
  TooltipSpan,
} from './HeaderUtilStyled';

const HeaderUtil = ({
  onLogout,
  handleOpenOnlineHelp,
  themeValue,
  handleChangeTheme,
  modelName,
  authPopupVisible,
  onChangeAuthPopupVisible,
  authDropdownVisible,
  onChangeAuthDropdownVisible,
  themeChangeDisabled,
  isAdmin,
}) => {
  const loginUser = Auth.getUserid();
  const dropDownMenu = [
    (
      <UserIDWrapperStyled key="userid">{loginUser}</UserIDWrapperStyled>
    ),
    !isAdmin && (
      <ButtonStyled key="userAuthButton" onClick={onChangeAuthPopupVisible}>{getLanguage('lang_permission')}</ButtonStyled>
    ),
    (
      <ButtonStyled key="logout" onClick={onLogout}>{getLanguage('lang_logout')}</ButtonStyled>
    ),
  ];
  return (
    <>
      <FuncWrapperStyled>
        <ModelNameStyled>{modelName}</ModelNameStyled>
        <DropDownMenuStyled
          isOpend={authDropdownVisible}
          onClickOutside={onChangeAuthDropdownVisible}
          menuItems={dropDownMenu}
        >
          <IconButtonStyled
            data-tip="user"
            data-for="user"
            className="wni wni-user"
            onClick={onChangeAuthDropdownVisible}
          />
        </DropDownMenuStyled>
        <CustomReactTooltip
          id="user"
          type="light"
          place="top"
          effect="float"
          delayShow={500}
          getContent={() => (
            <TooltipSpan>{getLanguage('lang_user')}</TooltipSpan>
          )}
        />
        <IconButtonStyled
          data-tip="help"
          data-for="help"
          className="wni wni-help"
          onClick={handleOpenOnlineHelp}
        />
        <CustomReactTooltip
          id="help"
          type="light"
          place="top"
          effect="float"
          delayShow={500}
          getContent={() => (
            <TooltipSpan>{getLanguage('lang_help')}</TooltipSpan>
          )}
        />
        <div
          data-tip="theme"
          data-for="theme"
        >
          <ToggleStyled
            handleText={['B', 'W']}
            value={themeValue}
            onChange={handleChangeTheme}
            disabled={themeChangeDisabled}
            handleStyle={{
              marginTop: -5,
            }}
          />
          <CustomReactTooltip
            id="theme"
            type="light"
            place="top"
            effect="float"
            delayShow={500}
            getContent={() => (
              <TooltipSpan>{getLanguage('lang_theme')}</TooltipSpan>
            )}
          />
        </div>
      </FuncWrapperStyled>
      <PopupStyled
        isOpen={authPopupVisible}
      >
        <PopupContent
          title="lang_permission"
          hasTopCloseButton
          bottomButtons={[
            (
              <PopupButtonStyled key="userAuthPopupCloseButton" onClick={onChangeAuthPopupVisible}>{getLanguage('OK')}</PopupButtonStyled>
            ),
          ]}
          onClosePopup={onChangeAuthPopupVisible}
        >
          <UserAuth />
        </PopupContent>
      </PopupStyled>
    </>
  );
};

HeaderUtil.defaultProps = {
  modelName: '',
};

HeaderUtil.propTypes = {
  onLogout: PropTypes.func.isRequired,
  handleOpenOnlineHelp: PropTypes.func.isRequired,
  themeValue: PropTypes.number.isRequired,
  handleChangeTheme: PropTypes.func.isRequired,
  modelName: PropTypes.string,
  authPopupVisible: PropTypes.bool.isRequired,
  onChangeAuthPopupVisible: PropTypes.func.isRequired,
  authDropdownVisible: PropTypes.bool.isRequired,
  onChangeAuthDropdownVisible: PropTypes.func.isRequired,
  themeChangeDisabled: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default withContainer(HeaderUtilContainer, HeaderUtil);
