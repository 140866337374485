import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';

class TileDataContainer extends React.Component {
  render() {
    const { render } = this.props;
    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

TileDataContainer.defaultProps = {};

TileDataContainer.propTypes = {
  render: PropTypes.func.isRequired,
};

export default connect(
  (state, props) => {
    // console.log('props ----------', props);
    const cameraList = state.cameraInfoModule.get('cameraList');
    const findTileInfo = cameraList.find(camera => camera.get('channel') === props.channel) || Map({});
    // console.log('findTileInfo', findTileInfo.toJS());
    const {
      videoProfilepolicy,
      videoProfile,
      audioInput,
    } = findTileInfo.toJS();

    if (videoProfilepolicy) {
      let currentProfile = state.liveMediaControlModule.get('pattern') === 'SPECIAL'
        ? videoProfilepolicy.RecordProfile
        : videoProfilepolicy.NetworkProfile;

      if (currentProfile === 0) {
        currentProfile = videoProfilepolicy.LiveProfile;
      }

      if (videoProfile) {
        const findProfiles = videoProfile.Profiles.find(item => item.Profile === currentProfile);

        if (findProfiles) {
          const {
            Bitrate,
            Resolution,
            IsDigitalPTZProfile,
            ViewModeType,
            EncodingType,
            // AudioInputEnable,
          } = videoProfile.Profiles.find(item => item.Profile === currentProfile);
          // } = typeof findProfiles !== 'undefined' ? findProfiles : videoProfile.Profiles[0];
          // const checkAudioIn = AudioInputEnable === true
            // && audioInput === true;
          // NVR에서 audio 정보 노출은 NVR 설정만 보고 결정하기로 정책을 정함
          // audio 지원하지 않는 Speaker 이슈
          const checkAudioIn = audioInput === true;
          const checkAudioOut = audioInput === true;

          return ({
            ...findTileInfo.toJS(),
            currentProfile,
            currentBitrate: Bitrate,
            currentResolution: Resolution,
            isDigitalPTZProfile: IsDigitalPTZProfile,
            viewModeType: ViewModeType,
            checkAudioIn,
            checkAudioOut,
            currentEncodingType: EncodingType,
          });
        }
      }
      return ({
        ...findTileInfo.toJS(),
      });
    }
    return ({
      ...findTileInfo.toJS(),
    });
  },
)(TileDataContainer);
