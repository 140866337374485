import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'wisenet-ui/components/atoms';
import {
  ListTabsStyled,
} from './ListTabsStyled';

const ListTabs = ({
  tabData,
  className,
  ...rest
}) => (
  <ListTabsStyled
    className={className}
    {...rest}
  >
    {tabData.map(data => (
      <Tab
        {...data}
      />
    ))}
  </ListTabsStyled>
);

ListTabs.defaultProps = {
  className: null,
};

ListTabs.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  tabData: PropTypes.instanceOf(Array).isRequired,
};

export default ListTabs;
