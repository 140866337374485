import styled, { withTheme, css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import {
  IconButton,
  Select,
  Input,
  Label,
  PatternIcon,
  Span,
} from 'wisenet-ui/components/atoms';
import { DropDownMenu } from 'wisenet-ui/components/organisms';

export const MediaControlBarStyled = withTheme(styled.div`
  display: flex;
  justify-content: space-between;
`);

export const LeftStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .toast {
    transform: translateY(-40px);
  }
`;

export const CenterStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const RightStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const IconButtonStyled = withTheme(styled(IconButton)`
  padding: 0;
  width: 48px;
  height: 58px;
  font-size: 32px;
  color: ${props => props.theme.text.color5};
`);

export const LayoutIconButtonStyled = withTheme(styled(IconButton)`
  padding: 0;
  font-size: 16px;
`);

export const IconStyled = withTheme(styled.i`
  ${props => props.isActive && css`
    color: #f37321;
  `}
  ${props => props.isDisableButton && css`
    opacity: 0.4;
  `}
  ${props => props.isDisableAlarm && css`
    opacity: 0.4;
  `}
  ${props => props.isDisableExport && css`
    opacity: 0.4;
  `}
`);

export const PlaySpeedSelect = withTheme(styled(Select)`
  height: 20px;
  padding: 0;
  margin-left: 15px;
`);

export const LayoutPageInput = withTheme(styled(Input)`
  width: 15px;
  text-align: center;
  padding: 0;
  border: none;
  color: #f37321;
`);

export const LayoutPageLabel = withTheme(styled(Label)`
  font-weight: 400;
  margin: 0 5px;
`);

export const DropDownMenuStyled = styled(DropDownMenu)`
  & > div {
    bottom: 26px;
    z-index: 2;
    padding: 12px;
  }
`;

export const SliderLabelStyled = withTheme(styled(Label)`
  height: 19px;
  margin-left: 20px;
  margin-right: 12px;
`);

export const SliderDropDownMenuStyled = styled(DropDownMenu)`
  display: flex;
  & > div {
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100px;
    box-shadow: none;
    height: 175px;
    bottom: 38px;
    color: ${props => props.theme.text.color1};
    border: 1px solid ${props => props.theme.text.color3};
    &::before {
      content: "";
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 11.6px 8px 0px 8px;
      border-style: solid;
      border-color: ${props => props.theme.text.color3} transparent transparent transparent;
      }
      &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 11.6px 8px 0px 8px;
      border-style: solid;
      border-color: ${props => props.theme.background.color1} transparent transparent transparent;
      }
  }

  .rc-slider-mark-text-active {
    color: ${props => props.theme.text.color4};
  }
`;

export const TriangleStyled = withTheme(styled.div`
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 6px solid;
`);

export const InvertedTStyled = withTheme(styled.div`
  margin-top: 2px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 6px solid;
`);

export const LayoutPageControlStyled = withTheme(styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
  margin: 0 18px;
`);

export const PatternIconStyled = withTheme(styled(PatternIcon)`
  &:hover {
    & > div {
      border-right: none;
      border-bottom: none;
    }
  }
`);

export const TooltipSpan = withTheme(styled(Span)`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  margin: 0px;
  padding: 0px
`);

export const CustomReactTooltip = withTheme(styled(ReactTooltip)`
  background-color: #555 !important;
  padding: 5px !important;
  &.place-top {
    &:after {
      border-top: none !important;
    }
  }
  &.place-left {
    &:after {
      border-left: 6px solid #555 !important;
    }
  }
  &.place-right {
    &:after {
      border-right: 6px solid #555 !important;
    }
  }
`);
