import React from 'react';
import PropTypes from 'prop-types';

class PopoverContainer extends React.Component {
  managerEl = {};

  popupEl = {};

  state = {
    managerElPositon: {
      top: 0,
      left: 0,
    },
    position: {
      top: 0,
      left: 0,
    },
  }

  componentDidUpdate(prevProps) {
    const { managerElPositon } = this.state;
    const currentManagerElPositon = this.managerEl.getBoundingClientRect();
    const { isOpen } = this.props;
    if (prevProps.isOpen !== isOpen) {
      if (isOpen) {
        document.addEventListener('click', this.onClickEvent);
      } else {
        document.removeEventListener('click', this.onClickEvent);
      }
    }
    if (
      JSON.stringify(currentManagerElPositon) !== JSON.stringify(managerElPositon)
      && (isOpen !== prevProps.isOpen)
    ) {
      const { padding } = this.props;
      const {
        top,
        left,
        // width,
        height,
      } = currentManagerElPositon;
      const newPosition = {
        top: top + height + padding,
        left,
      };
      // TBD
      /*
        let newPosition = null;
        const popupElPosition = this.popupEl.getBoundingClientRect();
        if (location === 'bottom') {
          if (align === 'start') {
            newPosition = {
              top: top + height + padding,
              left,
            };
          } else if (align === 'center') {
          newPosition = {
            top: top + height + padding,
            left: left - ((left + (popupElPosition.width / 2)) - (left + (width / 2))),
          };
        }
      }
      */
      this.onUpdate({
        managerElPositon: currentManagerElPositon,
        position: newPosition,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickEvent);
  }

  onClickEvent = e => {
    if (this.popupEl) {
      if (Object.keys(this.popupEl).length !== 0 && !this.popupEl.contains(e.target)) {
        const { onClickOutside } = this.props;
        onClickOutside();
      }
    }
  }


  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  setRef = (id, el) => {
    this[id] = el;
  }

  onClick = () => {
    const { onChangeOpenStatus } = this.props;
    onChangeOpenStatus();
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

PopoverContainer.defaultProps = {
  onChangeOpenStatus: () => {},
  // location: 'bottom',
  // align: 'start',
  padding: 10,
  onClickOutside: () => {},
};

PopoverContainer.propTypes = {
  render: PropTypes.func.isRequired,
  onChangeOpenStatus: PropTypes.func,
  // location: PropTypes.string,
  // align: PropTypes.string,
  padding: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  onClickOutside: PropTypes.func,
};

export default PopoverContainer;
