import styled from 'styled-components';

export const ContainerStyled = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentStyled = styled.div`
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const GuideWrapperStyled = styled.div`
  margin-top: 38px;
  border: 1px solid #d2d2d2;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px 0 15px;
`;

export const GuideIconStyled = styled.i`
  font-size: 53px;
  color: #4c4c4c;
`;

export const GuideStringStyled = styled.p`
  font-size: 16px;
  letter-spacing: -0.23px;
  margin-left: 25px;
`;
