import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'wisenet-ui/components/atoms';

const RadioGroup = ({
  inputs, selected, onChange,
}) => (
  <div>
    {inputs.map(({ key, text, value }, index) => (
      <div
        key={key}
        style={index > 0 ? {
          marginTop: '5px',
        } : { }}
      >
        <Radio
          name={text}
          checked={selected === value}
          onChange={onChange}
          value={value}
        />
      </div>
    ))
    }
  </div>
);


RadioGroup.defaultProps = {
  inputs: {},
  selected: false,
  onChange: undefined,
};

RadioGroup.propTypes = {
  inputs: PropTypes.oneOfType([PropTypes.any]),
  selected: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RadioGroup;
