import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Auth from 'util/lib/Auth';
import { LoginActions } from 'store/actionCreators';
import { withRouter } from 'react-router-dom';
import { eraseCookie } from 'util/lib/Cookie';
// import * as baseActions from 'store/modules/baseModule';

class LoginPageContainer extends React.Component {
  constructor(props) {
    super(props);

    if (Auth.isAuthenticated()) {
      props.history.push('/');
    }
  }

  componentDidMount() {
    // 인증 실패로 로그인창으로 이동 시 Auth fail 문구 표출 후에 cookie를 지우기 위함.
    eraseCookie('AUTH_FAIL');
  }

  handleSubmit = ({
    userid,
    password,
  }) => {
    const { history } = this.props;
    LoginActions.login({
      userid,
      password,
      history,
    });
  }

  render() {
    const {
      render,
    } = this.props;
    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

LoginPageContainer.propTypes = {
  render: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(connect(
  state => ({
    errorMessage: state.loginModule.get('errorMessage'),
    theme: state.themeModule.get('theme'),
  }),
)(LoginPageContainer));
