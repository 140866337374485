import {
  createAction,
  handleActions,
} from 'redux-actions';
import { Map, fromJS } from 'immutable';

export const CONTROL_AUTO_FOCUS = 'ptzZoomFocus/CONTROL_AUTO_FOCUS';
export const CONTROL_FOCUS = 'ptzZoomFocus/CONTROL_FOCUS';
export const CONTROL_FOCUS_STOP = 'ptzZoomFocus/CONTROL_FOCUS_STOP';
export const CONTROL_FOCUS_SUCCESS = 'ptzZoomFocus/CONTROL_FOCUS_SUCCESS';
export const CONTROL_FOCUS_FAIL = 'ptzZoomFocus/CONTROL_FOCUS_FAIL';
export const CONTROL_ZOOM = 'ptzZoomFocus/CONTROL_ZOOM';
export const CONTROL_ZOOM_STOP = 'ptzZoomFocus/CONTROL_ZOOM_STOP';
export const CONTROL_ZOOM_SUCCESS = 'ptzZoomFocus/CONTROL_ZOOM_SUCCESS';
export const CONTROL_ZOOM_FAIL = 'ptzZoomFocus/CONTROL_ZOOM_FAIL';
export const CONTROL_STOP = 'ptzZoomFocus/CONTROL_STOP';
export const CONTROL_STOP_SUCCESS = 'ptzZoomFocus/CONTROL_STOP_SUCCESS';
export const CONTROL_STOP_FAIL = 'ptzZoomFocus/CONTROL_STOP_FAIL';
export const SET_CUR_QUADRANT_FOR_CHANNEL = 'ptzSequence/SET_CUR_QUADRANT_FOR_CHANNEL';

export const controlAutoFocus = createAction(CONTROL_AUTO_FOCUS);
export const controlFocus = createAction(CONTROL_FOCUS);
export const controlFocusStop = createAction(CONTROL_FOCUS_STOP);
export const controlFocusSuccess = createAction(CONTROL_FOCUS_SUCCESS);
export const controlFocusFail = createAction(CONTROL_FOCUS_FAIL);
export const controlZoom = createAction(CONTROL_ZOOM);
export const controlZoomStop = createAction(CONTROL_ZOOM_STOP);
export const controlZoomSuccess = createAction(CONTROL_ZOOM_SUCCESS);
export const controlZoomFail = createAction(CONTROL_ZOOM_FAIL);
export const controlStop = createAction(CONTROL_STOP);
export const controlStopSuccess = createAction(CONTROL_STOP_SUCCESS);
export const controlStopFail = createAction(CONTROL_STOP_FAIL);
export const setCurQuadForCh = createAction(SET_CUR_QUADRANT_FOR_CHANNEL);

const initialState = Map({
  curQuadForCh: Map({
    quadrant: null,
    channel: null,
  }),
});

// reducer
export default handleActions({
  [CONTROL_FOCUS_SUCCESS]: state => {
    console.info('control focus success');
    return state;
  },
  [CONTROL_FOCUS_FAIL]: state => {
    console.info('control focus fail');
    return state;
  },
  [CONTROL_ZOOM_SUCCESS]: state => {
    console.info('control zoom success');
    return state;
  },
  [CONTROL_ZOOM_FAIL]: state => {
    console.info('control zoom fail');
    return state;
  },
  [CONTROL_STOP_SUCCESS]: state => {
    console.info('control stop success');
    return state;
  },
  [CONTROL_STOP_FAIL]: state => {
    console.info('control stop fail');
    return state;
  },
  [SET_CUR_QUADRANT_FOR_CHANNEL]: (state, action) => {
    const { payload } = action;
    return state
      .set('curQuadForCh', fromJS(payload));
  },
}, initialState);
