import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SearchTimelineActions,
  SearchMediaControlActions,
} from 'store/actionCreators';
import RecordStatusType from 'wisenet-ui/util/static/constants/mediaControl/recordStatusType';

class EventResultContainer extends React.Component {
  state = {
    results: [],
    isDescending: false, // 내림차순 정렬 판단 값. 기본은 오름차순임
    currentItemIndex: this.INIT_PAGE_COUNT,
    accordionState: {
      eventList: true,
    },
    showExportPopup: false,
  };

  INIT_PAGE_COUNT = 200;

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) { // , prevState, snapshot
    const {
      filterEvent: prevFilterEvent,
    } = prevProps;

    const {
      filterEvent: propsFilterEvent,
    } = this.props;

    if (propsFilterEvent && JSON.stringify(prevFilterEvent) !== JSON.stringify(propsFilterEvent)) {
      const filterEvent = propsFilterEvent.toJS();
      const result = filterEvent.map((item, idx) => ({
        Index: (idx + 1).toString().padStart(filterEvent.length.toString().length, '0'),
        Type: item.Type,
        languageKey: RecordStatusType.getLanguageKey(item.Type),
        StartTime: this.toTimeString(item.LocalStartTime),
        oriStartTime: item.StartTime,
        EndTime: this.toTimeString(item.LocalEndTime),
        oriEndTime: item.EndTime,
        dst: item.StartDst || item.EndDst,
        StartDst: item.StartDst,
        EndDst: item.EndDst,
        focusColor: item.Type === 'Manual' || item.Type === 'Normal' ? '#8dc63f' : '#f00',
      }));
      this.onUpdate({
        results: result,
        currentItemIndex: this.INIT_PAGE_COUNT,
      });
    }
  }

  toTimeString = param => param.split('T')[1];

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  onSelectEvent = e => {
    const { filterEvent: propsFilterEvent } = this.props;
    const filterEvent = propsFilterEvent.toJS();
    if (!filterEvent[e.data.Index - 1]) {
      console.log('선택한 항목이 어떤건지 모르겠슴다', filterEvent);
      return;
    }
    SearchTimelineActions.setSelectEvent({
      ...filterEvent[e.data.Index - 1],
      isReopen: true,
      isDst: filterEvent[e.data.Index - 1].StartDst,
      EndTime: undefined,
    });
  }

  descending = () => {
    const { isDescending } = this.state;
    this.onUpdate({
      isDescending: !isDescending,
    });
  }

  onClickViewMore = () => {
    const { currentItemIndex } = this.state;
    this.onUpdate({
      currentItemIndex: currentItemIndex + this.INIT_PAGE_COUNT,
    });
  }

  onClickViewAll = () => {
    const { results } = this.state;
    this.onUpdate({
      currentItemIndex: results.length,
    });
  }

  onClickExportBtn = () => {
    const { results } = this.state;
    const { currentChannel, cameraList } = this.props;
    if (currentChannel !== -1) {
      cameraList.map(camera => {
        if (camera.channel === currentChannel
          && camera.backupAccess
          && results.length > 0) {
          SearchMediaControlActions.playControl({
            playMode: 'pause',
          });
          this.setState({
            showExportPopup: true,
          });
        }
        return null;
      });
    }
  }

  onExportCancel = () => {
    this.setState({
      showExportPopup: false,
    });
  }

  render() {
    const { render } = this.props;

    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

EventResultContainer.propTypes = {
  render: PropTypes.func.isRequired,
  filterEvent: PropTypes.oneOfType([PropTypes.any]).isRequired,
  currentChannel: PropTypes.number.isRequired,
  cameraList: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default connect(
  state => ({
    lang: state.langModule.get('lang'),
    filterEvent: state.searchTimelineModule.get('filterEvent'),
    currentChannel: state.searchTimelineModule.get('currentChannel'),
    cameraList: state.cameraInfoModule.get('cameraList').toJS(),
  }),
)(EventResultContainer);
