import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import umpPlayMode from 'wisenet-ui/util/static/constants/umpPlayer/umpPlayMode';
import { VideoTile } from 'wisenet-ui/components/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { ManualBackupPopupContainer } from 'containers/organisms';
import { getLanguage } from 'util/lib';
import DatePickerWrapper from './DatePickerWrapper';
import GuidePopup from './GuidePopup';
import CompletePopup from './CompletePopup';
import {
  Container,
  Header,
  Body,
  Footer,
  TitleSpan,
  ButtonContainer,
  FooterButton,
  SubBody1,
  SubBody2,
  MenuSpan,
  StyledDatePickerWrapper,
  FileInput,
  StatusLabel,
  StatusWrapper,
  StyledTable,
  TableWrapper,
  ChannelSpan,
  LayoutSelect,
  GuidePopupWrapper,
  CompletePopupWrapper,
  FileNameWrapper,
  StyledPropgressbar,
} from './ManualBackupPopupStyled';

const ManualBackupPopup = ({
  onCancel,
  exportChannelList,
  onChangeCheckTableData,
  exportStartDateObj,
  exportEndDateObj,
  exportStartDateTimeObjBackup,
  exportEndDateTimeObjBackup,
  doBackup,
  onGuidePopupConfirm,
  showGuidePopup,
  showCompletePopup,
  guideData,
  completeData,
  isCalendarOpen,
  onBackup,
  sessionKey,
  startBackup,
  fileName,
  onFileNameChange,
  currentBackupPercent,
  currentBackupChannel,
  totalBackupPercent,
  onStopBackup,
  onCompletePopupConfirm,
  layoutListElements,
  onSelectLayout,
  timeZone,
}) => {
  const checkHeader = [
    {
      Header: (
        <ChannelSpan>{getLanguage('lang_ch')}</ChannelSpan>
      ),
      accessor: 'ch',
      sortable: true,
    },
    {
      Header: getLanguage('lang_name'),
      accessor: 'name',
      sortable: true,
    },
  ];

  const {
    year: sYear,
    month: sMonth,
    day: sDay,
    hour: sHour,
    minute: sMinute,
    second: sSecond,
  } = exportStartDateObj.toJS();

  const {
    year: eYear,
    month: eMonth,
    day: eDay,
    hour: eHour,
    minute: eMinute,
    second: eSecond,
  } = exportEndDateObj.toJS();

  const startTime = new Date(
    sYear,
    sMonth - 1,
    sDay,
    sHour,
    sMinute,
    sSecond,
  );

  const endTime = new Date(
    eYear,
    eMonth - 1,
    eDay,
    eHour,
    eMinute,
    eSecond,
  );

  let startUTCTime = '';
  let endUTCTime = '';

  try {
    startUTCTime = startTime.setHours(startTime.getHours()
      - startTime.getTimezoneOffset() / 60 + Number(timeZone));
    startUTCTime = new Date(startUTCTime).toISOString();

    endUTCTime = endTime.setHours(endTime.getHours()
      - endTime.getTimezoneOffset() / 60 + Number(timeZone));
    endUTCTime = new Date(endUTCTime).toISOString();
  } catch (err) {
    startUTCTime = '';
    endUTCTime = '';
    console.info('backup time not ready ...');
  }
  return (
    <Container>
      <GuidePopupWrapper
        isOpen={showGuidePopup}
        shouldCloseOnOverlayClick
      >
        <GuidePopup
          title={guideData.title}
          content={guideData.content}
          onConfirm={onGuidePopupConfirm}
        />
      </GuidePopupWrapper>
      <CompletePopupWrapper
        isOpen={showCompletePopup}
        shouldCloseOnOverlayClick
      >
        <CompletePopup
          onConfirm={onCompletePopupConfirm}
          completeData={completeData}
        />
      </CompletePopupWrapper>
      <Header>
        <TitleSpan>{getLanguage('lang_export')}</TitleSpan>
      </Header>
      <Body>
        <SubBody1>
          <LayoutSelect
            onChange={onSelectLayout}
            defaultValue="default"
          >
            {layoutListElements}
          </LayoutSelect>
          <TableWrapper>
            <StyledTable
              header={checkHeader}
              data={exportChannelList}
              selectCheck
              onChangeData={onChangeCheckTableData}
              rowHeight={30}
              pageSize={10}
            />
          </TableWrapper>
          <StyledDatePickerWrapper>
            <DatePickerWrapper
              startDateTime={exportStartDateTimeObjBackup.toJS()}
              endDateTime={exportEndDateTimeObjBackup.toJS()}
              isCalendarOpen={isCalendarOpen}
            />
          </StyledDatePickerWrapper>
          <FileNameWrapper>
            <MenuSpan>{getLanguage('lang_file_name')}</MenuSpan>
            <FileInput
              type="text"
              onChange={onFileNameChange}
              placeholder=""
              maxlength="230"
            />
          </FileNameWrapper>
        </SubBody1>
        <SubBody2>
          <StatusWrapper>
            <StatusLabel>{`${getLanguage('lang_total')} : ${totalBackupPercent} %`}</StatusLabel>
            <StyledPropgressbar
              completed={totalBackupPercent - (totalBackupPercent % 5)}
            />
          </StatusWrapper>
          <StatusWrapper>
            <StatusLabel>{`${getLanguage('lang_ch')} ${currentBackupChannel + 1} : ${currentBackupPercent} %`}</StatusLabel>
            <StyledPropgressbar
              completed={currentBackupPercent - (currentBackupPercent % 5)}
            />
          </StatusWrapper>
        </SubBody2>
      </Body>
      <Footer>
        <ButtonContainer>
          <FooterButton
            disabled={startBackup}
            onClick={doBackup}
          >
            {getLanguage('lang_ok')}
          </FooterButton>
          <FooterButton
            onClick={onStopBackup}
            style={{
              display: startBackup ? 'block' : 'none',
            }}
          >
            {getLanguage('lang_stop')}
          </FooterButton>
          <FooterButton
            disabled={startBackup}
            onClick={onCancel}
          >
            {getLanguage('lang_cancel')}
          </FooterButton>
        </ButtonContainer>
      </Footer>
      <VideoTile
        type={umpPlayMode.BACKUP}
        channel={currentBackupChannel}
        backup={startBackup}
        sessionKey={sessionKey}
        startTime={`${startUTCTime.substring(0, 19)}Z`}
        endTime={`${endUTCTime.substring(0, 19)}Z`}
        onBackup={onBackup}
        fileName={fileName}
      />
    </Container>
  );
};

ManualBackupPopup.propTypes = {
  onCancel: PropTypes.func.isRequired,
  exportChannelList: PropTypes.arrayOf(PropTypes.any).isRequired,
  exportStartDateTimeObjBackup: PropTypes.instanceOf(Map).isRequired,
  exportEndDateTimeObjBackup: PropTypes.instanceOf(Map).isRequired,
  onGuidePopupConfirm: PropTypes.func.isRequired,
  showGuidePopup: PropTypes.bool.isRequired,
  doBackup: PropTypes.func.isRequired,
  onChangeCheckTableData: PropTypes.func.isRequired,
  guideData: PropTypes.instanceOf(Object),
  completeData: PropTypes.objectOf(PropTypes.any),
  isCalendarOpen: PropTypes.bool.isRequired,
  onBackup: PropTypes.func.isRequired,
  sessionKey: PropTypes.string.isRequired,
  startBackup: PropTypes.bool.isRequired,
  fileName: PropTypes.string.isRequired,
  onFileNameChange: PropTypes.func.isRequired,
  currentBackupPercent: PropTypes.number,
  currentBackupChannel: PropTypes.number,
  totalBackupPercent: PropTypes.number,
  onStopBackup: PropTypes.func.isRequired,
  onCompletePopupConfirm: PropTypes.func.isRequired,
  showCompletePopup: PropTypes.bool.isRequired,
  onSelectLayout: PropTypes.func.isRequired,
  layoutListElements: PropTypes.arrayOf(PropTypes.element),
  timeZone: PropTypes.number,
  exportStartDateObj: PropTypes.instanceOf(Map).isRequired,
  exportEndDateObj: PropTypes.instanceOf(Map).isRequired,
};

ManualBackupPopup.defaultProps = {
  guideData: {},
  currentBackupPercent: 0,
  currentBackupChannel: 0,
  totalBackupPercent: 0,
  completeData: {},
  layoutListElements: [],
  timeZone: 0,
};

export default withContainer(ManualBackupPopupContainer, ManualBackupPopup);
