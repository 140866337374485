import React from 'react';
import PropTypes from 'prop-types';
import {
  MediaControlIDList,
  MediaControlIconButtonList as IconList,
} from 'wisenet-ui/util/static/constants/mediaControl/mediaControlType';
import { PatternSelect } from 'wisenet-ui/components/organisms';
import { getLanguage } from 'util/lib';
import {
  LeftStyled,
  RightStyled,
  IconButtonStyled,
  IconStyled,
  LayoutPageInput,
  LayoutPageLabel,
  DropDownMenuStyled,
  LayoutPageControlStyled,
  LayoutIconButtonStyled,
  PatternIconStyled,
  CustomReactTooltip,
  TooltipSpan,
} from './MediaControlBarStyled';

const LiveMediaControlBar = props => {
  const {
    onClick,
    layoutPageInputValue,
    layoutPageLimitValue,
    isMaunalRecord,
    patternDropDownVisible,
    onChangePatternDropdownVisible,
    layoutRightButtonDisabled,
    pattern,
    showRecordIcon,
    showAlarmIcon,
    showExportIcon,
  } = props;

  const {
    RECORD,
    EXPORT,
    ALARM,
    OSD,
    CH_INFO,
    STATUS,
    LAYOUT_PAGE_LEFT,
    LAYOUT_PAGE_INPUT,
    LAYOUT_PAGE_RIGHT,
    PATTERN,
    FULL_ASPECT_RATIO,
    FULLSCREEN,
  } = MediaControlIDList;

  const leftItems = [
    RECORD,
    EXPORT,
    ALARM,
  ];

  const rightItems = [
    OSD,
    CH_INFO,
    STATUS,
    'layoutPage',
    PATTERN,
    FULL_ASPECT_RATIO,
    FULLSCREEN,
  ];

  const dropdownMenuItems = [
    (
      <PatternSelect
        key="patternSelect"
        pattern={pattern}
        onClick={type => onClick(PATTERN)({
          target: {
            value: type,
          },
        })}
      />
    ),
  ];

  return (
    <React.Fragment>
      <LeftStyled>
        {leftItems.map(item => (
          <IconButtonStyled onClick={onClick(IconList[item].clickId)} key={IconList[item].iconId}>
            <IconStyled
              data-tip={item}
              data-for={item}
              isActive={item === RECORD && isMaunalRecord}
              className={`${IconList[item].iconId}`}
              isDisableButton={item === RECORD && !showRecordIcon}
              isDisableAlarm={item === ALARM && !showAlarmIcon}
              isDisableExport={item === EXPORT && !showExportIcon}
            />
            <CustomReactTooltip
              id={item}
              type="light"
              place="top"
              effect="float"
              delayShow={500}
              getContent={() => (
                <TooltipSpan>{getLanguage(item)}</TooltipSpan>
              )}
            />
          </IconButtonStyled>
        ))}
      </LeftStyled>
      <RightStyled>
        {rightItems.map(item => {
          if (item === 'layoutPage') {
            const disabled = layoutPageInputValue <= 1;
            return (
              <LayoutPageControlStyled
                key={item}
              >
                <LayoutIconButtonStyled
                  onClick={onClick(IconList[LAYOUT_PAGE_LEFT].clickId)}
                  // key={IconList[LAYOUT_PAGE_LEFT].iconId}
                  disabled={disabled}
                >
                  <IconStyled className={`${IconList[LAYOUT_PAGE_LEFT].iconId}`} />
                </LayoutIconButtonStyled>
                {/* {'Page'} */}
                <LayoutPageInput
                  value={layoutPageInputValue}
                  // key={item}
                  onChange={onClick(LAYOUT_PAGE_INPUT)}
                />
                <LayoutPageLabel>
                  {'/'}
                </LayoutPageLabel>
                <LayoutPageLabel>
                  {layoutPageLimitValue}
                </LayoutPageLabel>
                <LayoutIconButtonStyled
                  fontSize={16}
                  onClick={onClick(IconList[LAYOUT_PAGE_RIGHT].clickId)}
                  // key={IconList[LAYOUT_PAGE_RIGHT].iconId}
                  disabled={layoutRightButtonDisabled}
                >
                  <IconStyled className={`${IconList[LAYOUT_PAGE_RIGHT].iconId}`} />
                </LayoutIconButtonStyled>
              </LayoutPageControlStyled>
            );
          }
          if (item === PATTERN) {
            return (
              <DropDownMenuStyled
                isOpend={patternDropDownVisible}
                onClickOutside={onChangePatternDropdownVisible}
                menuItems={dropdownMenuItems}
                key={IconList[item].iconId}
              >
                <IconButtonStyled
                  data-tip={item}
                  data-for={item}
                  onClick={onChangePatternDropdownVisible}
                >
                  <PatternIconStyled
                    type={pattern}
                  />
                  <CustomReactTooltip
                    id={item}
                    type="light"
                    place="top"
                    effect="float"
                    delayShow={500}
                    getContent={() => (
                      <TooltipSpan>{getLanguage(item)}</TooltipSpan>
                    )}
                  />
                </IconButtonStyled>
              </DropDownMenuStyled>
            );
          }
          return (
            <IconButtonStyled onClick={onClick(IconList[item].clickId)} key={IconList[item].iconId}>
              <IconStyled
                data-tip={item}
                data-for={item}
                className={`${IconList[item].iconId}`}
              />
              <CustomReactTooltip
                id={item}
                type="light"
                place="top"
                effect="float"
                delayShow={500}
                getContent={() => (
                  <TooltipSpan>{getLanguage(item)}</TooltipSpan>
                )}
              />
            </IconButtonStyled>
          );
        })}
      </RightStyled>
    </React.Fragment>
  );
};

LiveMediaControlBar.defaultProps = {
  layoutPageInputValue: 1,
  layoutPageLimitValue: 1,
  isMaunalRecord: true,
  layoutRightButtonDisabled: false,
  pattern: '',
  showRecordIcon: true,
  showAlarmIcon: true,
  showExportIcon: false,
};

LiveMediaControlBar.propTypes = {
  onClick: PropTypes.func.isRequired,
  layoutPageLimitValue: PropTypes.number,
  layoutPageInputValue: PropTypes.number,
  isMaunalRecord: PropTypes.bool,
  patternDropDownVisible: PropTypes.bool.isRequired,
  onChangePatternDropdownVisible: PropTypes.func.isRequired,
  layoutRightButtonDisabled: PropTypes.bool,
  pattern: PropTypes.string,
  showRecordIcon: PropTypes.bool,
  showAlarmIcon: PropTypes.bool,
  showExportIcon: PropTypes.bool,
};

export default LiveMediaControlBar;
