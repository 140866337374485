import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MediaControlIDList } from 'wisenet-ui/util/static/constants/mediaControl/mediaControlType';
import { getLanguage } from 'util/lib';
import {
  SearchMediaControlActions,
  SearchTimelineActions,
  LayoutActions,
  ThemeActions,
  ManualBackupActions,
  EventSearchActions,
} from 'store/actionCreators';
import { Map, List } from 'immutable';

class SearchMediaControlBarContainer extends React.Component {
  timeRangeToast = null;

  waitingNextCommand = false;

  state = {
    playSpeedValue: 'x1',
    currentPlaySpeed: 1,
    searchState: true,
    playSpeedIndex: 3,
    playSpeenDropDownVisible: false,
    showStatusPopup: false,
    showExportPopup: false,
    showExportIcon: true,
  }

  componentDidMount() {
    const { accessInfo } = this.props;
    if (accessInfo.backupAccess) {
      this.setState({
        showExportIcon: true,
      });
    } else {
      this.setState({
        showExportIcon: false,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const copyProps = Object.assign({}, this.props);
    const copyNextProps = Object.assign({}, nextProps);
    // 현재 시간이 변경되었을 때 렌더가 되지 않도록 하기 위한 delete 구문
    delete copyNextProps.currentTime;
    delete copyProps.currentTime;

    if (JSON.stringify(copyNextProps) === JSON.stringify(copyProps)
    && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false;
    }

    return true;
  }


  componentDidUpdate(prevProps) {
    const {
      currentChannel: prevCurrentChannel,
      timeLineSearchResults: prevTimeLineSearchResults,
      playMode: prevPlayMode,
    } = prevProps;
    const {
      currentChannel,
      accessInfo,
      timeLineSearchResults,
      playMode,
    } = this.props;

    // 채널이 변경 되거나 날짜 및 중복 구간이 변경될 때 배속 초기화
    if ((currentChannel !== prevCurrentChannel)
      || (JSON.stringify(timeLineSearchResults) !== JSON.stringify(prevTimeLineSearchResults))) {
      this.speedInit();
    }

    if (playMode !== prevPlayMode) {
      this.setNextClickDelay();
      if (playMode === 'pause') {
        this.speedInit();
      }
    }

    if (accessInfo.backupAccess) {
      this.onUpdate({
        showExportIcon: true,
      });
    } else {
      this.onUpdate({
        showExportIcon: false,
      });
    }
  }

  onChangePlaySpeenDropDownVisible = () => {
    const { playSpeenDropDownVisible } = this.state;
    this.setState({
      playSpeenDropDownVisible: !playSpeenDropDownVisible,
    });
  }

  setNextClickDelay = () => {
    this.waitingNextCommand = true;
    setTimeout(() => {
      this.waitingNextCommand = false;
    }, 1000);
  }

  onClick = type => event => {
    const {
      playMode,
      timeLineSearchResults,
      accessInfo,
    } = this.props;

    const {
      searchState,
    } = this.state;

    switch (type) {
      case MediaControlIDList.EXPORT:
        if (accessInfo.backupAccess) {
          SearchMediaControlActions.playControl({
            playMode: 'pause',
          });
          this.setState({
            showExportPopup: true,
          });
        }
        break;
      case MediaControlIDList.TIMELINE_TIME_RANGE: {
        const { useSelectTimeRange, setCurrentTimeRange } = this.props;
        if (useSelectTimeRange) {
          const selectedTimeRangeInfo = {
            startTime: '',
            endTime: '',
          };
          ManualBackupActions.setTimeRange({
            selectedTimeRangeInfo,
          });
          setCurrentTimeRange(null, null);
        } else if (this.timeRangeToast) {
          this.timeRangeToast.openToast(
            <span>
              {getLanguage('lang_range_popup_msg')}
            </span>, true,
          );
        }

        SearchMediaControlActions.setSelectTimeRange({
          useSelectTimeRange: !useSelectTimeRange,
        });
        ManualBackupActions.setSelectTimeRange({
          useSelectTimeRange: !useSelectTimeRange,
        });
        break;
      }
      case MediaControlIDList.PAUSE:
      case MediaControlIDList.PLAY: {
        const { currentTime } = this.props;
        // pause일 경우 무조건 UI의 배속 상태를 1로 변경하기 위한 코드
        if (this.waitingNextCommand) return;
        if (type === MediaControlIDList.PAUSE) {
          this.speedInit();
        }
        if (timeLineSearchResults.length > 0) {
          SearchMediaControlActions.playControl({
            playMode: type,
            playModeChangeTime: currentTime,
          });
        }
        break;
      }
      case MediaControlIDList.BACKWARD_PLAY:
      case MediaControlIDList.FORWARD_PLAY: {
        if (this.waitingNextCommand) return;
        this.frameStepControl(type);
        this.speedControl(type);
        break;
      }
      case MediaControlIDList.BACKWARD:
      case MediaControlIDList.FORWARD: {
        this.frameStepControl(type);
        break;
      }
      case MediaControlIDList.PREV_EVENT:
      case MediaControlIDList.AFTER_EVENT: {
        if (this.waitingNextCommand) return;
        this.setNextClickDelay();
        if (playMode === 'play') {
          this.eventControl(type);
        }
        break;
      }
      case MediaControlIDList.OSD:
        SearchMediaControlActions.OSDDisplayControl();
        break;
      case MediaControlIDList.STATUS:
        this.onChangeStatusPopupVisible();
        break;
      case MediaControlIDList.FULLSCREEN:
        this.onFullScreen();
        break;
      case MediaControlIDList.PLAY_SPEED: {
        if (playMode === 'play') {
          const speedList = MediaControlIDList.PLAY_SPEED_LIST[event].split('x')[1];
          const playSpeed = searchState ? Number(speedList) : Number(speedList) * -1;
          if (searchState) {
            this.setState({ playSpeedValue: MediaControlIDList.PLAY_SPEED_FRACTION_LIST[event] });
          } else {
            this.setState({ playSpeedValue: MediaControlIDList.PLAY_SPEED_M_FRACTION_LIST[event] });
          }
          this.setState({ currentPlaySpeed: playSpeed, playSpeedIndex: event });
          SearchMediaControlActions.playSpeedControl({
            playSpeed,
          });
        }
        break;
      }
      case MediaControlIDList.TIMELINE_FOLD:
        LayoutActions.timelineFoldingChange();
        break;
      default:
        break;
    }
  };

  checkFullscreenElement = () => (
    document.fullscreenElement
      || document.msFullscreenElement
      || document.mozFullScreenElement
      || document.webkitFullscreenElement
  )

  onFullScreen = () => {
    const fullScreenElement = this.checkFullscreenElement();
    if (!fullScreenElement) {
      LayoutActions.fullscreenModeChange(true);
      ThemeActions.changeTheme({
        theme: 'black',
        setStorage: false,
      });
      if (document.body.requestFullscreen) {
        // chrome, firefox(v 66.0.5)
        document.body.addEventListener('fullscreenchange', this.fullscreenModeOff);
        document.body.requestFullscreen();
      } else if (document.body.webkitRequestFullscreen) {
        // edge, safari
        document.body.addEventListener('webkitfullscreenchange', this.fullscreenModeOff);
        document.body.webkitRequestFullscreen();
      }
    } else if (fullScreenElement) {
      if (document.body.requestFullscreen) {
        // chrome, firefox(v 66.0.5)
        document.exitFullscreen();
      } else if (document.body.webkitRequestFullscreen) {
        // edge, safari
        document.webkitExitFullscreen();
      }
    }
  }

  fullscreenModeOff = () => {
    const fullScreenElement = this.checkFullscreenElement();
    if (!fullScreenElement) {
      if (document.body.requestFullscreen) {
        // chrome, firefox(v 66.0.5)
        document.body.removeEventListener('fullscreenchange', this.fullscreenModeOff);
      } else if (document.body.webkitRequestFullscreen) {
        // edge, safari
        document.body.removeEventListener('webkitfullscreenchange', this.fullscreenModeOff);
      }
      ThemeActions.changeTheme({
        theme: localStorage.getItem('WISENET-NVR-THEME'),
        setStorage: false,
      });
      LayoutActions.fullscreenModeChange(false);
    }
  }

  // 1/8, 1/4 표기로 현재 playSpeed 문자이용에서 숫자로 변경
  getCurrentPlaySpeed = () => {
    const {
      currentPlaySpeed,
    } = this.state;

    const playSpeed = currentPlaySpeed;

    // return type === MediaControlIDList.BACKWARD ? playSpeed * -1 : playSpeed;
    return playSpeed;
  }

  eventControl = type => {
    const { selectEvent, searchDateObj, calendarSearchDate } = this.props;
    const index = typeof selectEvent.toJS().index !== 'undefined' ? selectEvent.toJS().index : 0;
    const nextIndex = type === MediaControlIDList.PREV_EVENT ? index - 1 : index + 1;

    const { filterEvent: propsFilterEvent } = this.props;
    const filterEvent = propsFilterEvent.toJS();
    if (nextIndex >= 0 && nextIndex < filterEvent.length) {
      SearchTimelineActions.setSelectEvent({
        ...filterEvent[nextIndex],
        index: nextIndex,
        isReopen: true,
        isDst: filterEvent[nextIndex].StartDst,
        EndTime: undefined,
      });
    } else {
      console.log('******** 마지막 이벤트 입니다.');
      // 다음 날 있으면 날짜 변경 Action
      // 다음 날 없으면 popup(saga)
      if (searchDateObj !== null) {
        const { year, month, day } = searchDateObj;
        const date = new Date(year, month - 1, day);
        const dateChange = type === MediaControlIDList.PREV_EVENT ? -1 : 1;
        const searchDateIndex = calendarSearchDate.length !== 0
          ? calendarSearchDate.findIndex(calendar => calendar.valueOf() === date.valueOf())
          + dateChange : -1;
        const selectDate = calendarSearchDate[searchDateIndex];
        EventSearchActions.setSearchDate(selectDate);
      }
    }
  }

  onChangeStatusPopupVisible = () => {
    const { showStatusPopup } = this.state;
    this.setState({
      showStatusPopup: !showStatusPopup,
    });
  }

  onExportCancel = () => {
    this.setState({
      showExportPopup: false,
    });
  }

  speedInit = () => {
    const playSpeed = 1;
    const playSpeedValue = MediaControlIDList.PLAY_SPEED_FRACTION_LIST[3];

    SearchMediaControlActions.playSpeedControl({
      playSpeed,
    });

    // 배속상태를 초기화 하기 위해서 넣는 코드
    SearchMediaControlActions.stepControl({
      stepControlInfomation: {
        mode: 'forwardPlay',
        currentStepTime: '',
      },
    });

    this.setState({
      searchState: true,
      playSpeedValue,
      playSpeedIndex: 3,
    });
  }

  speedControl = type => {
    const { playSpeedValue, playSpeedIndex } = this.state;
    let currentPlaySpeedIndex = playSpeedIndex;
    const isBackDirection = playSpeedValue.includes('-');
    const isBackward = type === MediaControlIDList.BACKWARD_PLAY;
    if (isBackDirection) {
      if (isBackward) {
        currentPlaySpeedIndex += 1;
        currentPlaySpeedIndex %= MediaControlIDList.PLAY_SPEED_M_FRACTION_LIST.length;
      } else {
        currentPlaySpeedIndex = 3;
      }
    } else if (!isBackward) {
      currentPlaySpeedIndex += 1;
      currentPlaySpeedIndex %= MediaControlIDList.PLAY_SPEED_FRACTION_LIST.length;
    } else {
      currentPlaySpeedIndex = 3;
    }

    // 배속 설정 시 1 ~ 256배속 사이의 값만 변경되도록 추가
    if (currentPlaySpeedIndex < 2) {
      currentPlaySpeedIndex = 3;
    }

    const changePlaySpeedValue = isBackward ? (
      MediaControlIDList.PLAY_SPEED_M_FRACTION_LIST[currentPlaySpeedIndex]
    ) : MediaControlIDList.PLAY_SPEED_FRACTION_LIST[currentPlaySpeedIndex];
    const speedList = MediaControlIDList.PLAY_SPEED_LIST[currentPlaySpeedIndex].split('x')[1];
    const playSpeed = isBackward ? Number(speedList) * -1 : Number(speedList);
    // const playSpeed = MediaControlIDList.PLAY_SPEED_LIST[currentPlaySpeedIndex].split('x')[1];

    const searchState = !isBackward;

    SearchMediaControlActions.playSpeedControl({
      playSpeed,
    });

    this.setState({
      playSpeedValue: changePlaySpeedValue,
      searchState,
      playSpeedIndex: currentPlaySpeedIndex,
    });
  }

  frameStepControl = type => {
    const { currentTime } = this.props;
    d.log('[frameStepControl', type, currentTime);
    SearchMediaControlActions.stepControl({
      stepControlInfomation: {
        mode: type,
        currentStepTime: currentTime,
      },
    });
  }

  setTimeRangeToastRef = toast => {
    this.timeRangeToast = toast;
  }

  onUpdate = data => (
    this.setState({
      data,
    })
  )

  render() {
    const {
      render,
    } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

SearchMediaControlBarContainer.defaultProps = {
  searchDateObj: null,
};

SearchMediaControlBarContainer.propTypes = {
  render: PropTypes.func.isRequired,
  playMode: PropTypes.oneOf(['play', 'stop', 'pause']).isRequired,
  selectEvent: PropTypes.instanceOf(Map).isRequired,
  filterEvent: PropTypes.instanceOf(List).isRequired,
  useSelectTimeRange: PropTypes.bool.isRequired,
  setCurrentTimeRange: PropTypes.func.isRequired,
  currentTime: PropTypes.string.isRequired,
  currentChannel: PropTypes.number.isRequired,
  timeLineSearchResults: PropTypes.instanceOf(Array).isRequired,
  accessInfo: PropTypes.instanceOf(Object).isRequired,
  searchDateObj: PropTypes.instanceOf(Object),
  calendarSearchDate: PropTypes.instanceOf(Object).isRequired,
};

export default connect(
  state => ({
    playMode: state.searchMediaControlModule.get('playMode'),
    filterEvent: state.searchTimelineModule.get('filterEvent'),
    searchResult: state.textSearchModule.get('searchResult'),
    selectEvent: state.searchTimelineModule.get('selectEvent'),
    isFullscreen: state.layoutModule.get('isFullscreen'),
    timelineFolding: state.layoutModule.get('timelineFolding'),
    useSelectTimeRange: state.searchMediaControlModule.get('useSelectTimeRange'),
    currentChannel: state.searchTimelineModule.get('currentChannel'),
    timeLineSearchResults: state.searchTimelineModule.get('timeLineSearchResults'),
    accessInfo: state.systemInfomationModule.get('accessInfo').toJS(),
    searchDateObj: state.eventSearchModule.get('searchDateObj'),
    calendarSearchDate: state.eventSearchModule.get('calendarSearchDate'),
  }),
)(SearchMediaControlBarContainer);
