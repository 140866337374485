// ump에서 사용하는 properties 중에 사용하는 것들을 intercept하여 try catch를 걸기 위해서 사용
export const methods = [
  'play',
  'stop',
  'backup',
  'capture',
  'unmute',
  'mute',
  'isMute',
  'talk',
  'pause',
  'resume',
  'forward',
  'backward',
  'setAudioVolume',
  'isPlay',
];

export const values = [
  'sessionKey',
  'sunapiClient',
  'playType',
  'useIsoTimeFormat',
  'startTime',
  'endTime',
  'filename',
  'readyState',
  'seekingTime',
  'isTalk',
  'playSpeed',
  'overlappedId',
  'isplay',
];
