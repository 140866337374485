import styled, { withTheme, css } from 'styled-components';

const ButtonStyled = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: 1px solid ${props => props.theme.text.color3};
  color: ${props => props.theme.text.color1};
  background-color: ${props => props.theme.background.color1};

  &:hover {
    border: 1px solid ${props => props.theme.text.color5};
    color: ${props => props.theme.text.color8};
    background-color: ${props => props.theme.text.color5};
  }

  &:active {
    border: 1px solid ${props => props.theme.text.color1};
    color: ${props => props.theme.text.color8};
    background-color: ${props => props.theme.text.color1};
  }
  
  ${props => props.point && css`
    border: 1px solid #f37321;
    color: #f37321;
    background-color: ${props.theme.background.color1};

    &:hover {
      border: 1px solid #f37321;
      color: #ffffff;
      background-color: #f37321;
    }

    &:active {
      border: 1px solid #c25c1a;
      color: #ffffff;
      background-color: #c25c1a;
    }
  `}
  
  ${props => props.disabled && css`
    pointer-events: none;
    border: 1px solid ${props.theme.line.color2};
    color: ${props.theme.line.color4};
    background-color: ${props.theme.background.color1};
  `}
`;

export default withTheme(ButtonStyled);
