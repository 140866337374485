import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  EventSearchActions,
  SearchTimelineActions,
  SearchMediaControlActions,
  ManualBackupActions,
} from 'store/actionCreators';
import { getLanguage } from 'util/lib';
import { Map, List } from 'immutable';

class SearchTimelineContainer extends React.Component {
  state = {
    overlappedList: [`${getLanguage('lang_overlapped')} 0`],
    timeLine: [],
    useMouseoverPopup: true,
    zoom: 0,
    zoomDT: '24.0',
    timeLabelCtrl: false,
    timelineDstRange: [],
    timelineDstEnable: false,
    popupVisible: false,
    currentTimeChange: '0',
    dstEnable: false,
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      timeLine: prevTimeLine,
      overlappedIDList: prevOverlappedIDList,
      selectedOverlappedID: prevSelectedOverlappedID,
      dstInfo: prevDstInfo,
    } = prevProps;

    const {
      popupVisible: prevPopupVisible,
    } = prevState;

    const {
      timeLine: propsTimeLine,
      overlappedIDList,
      selectedOverlappedID,
      dstInfo,
      currentTabName,
      dstEnableInfo,
    } = this.props;

    const {
      popupVisible,
      currentTimeChange,
    } = this.state;

    if (JSON.stringify(prevTimeLine) !== JSON.stringify(propsTimeLine)) {
      const timeLine = propsTimeLine ? propsTimeLine[0] : null;
      this.canceledTimeRange();
      if (timeLine) {
        const { instantPlaybackSeeking } = this.props;
        const { currentTime, localTime } = instantPlaybackSeeking;
        if (currentTime !== '' && localTime !== '') {
          // 인스턴트 플레이백에서 진입
          // const event = filterEvent.toJS();
          const results = [...timeLine.normal, ...timeLine.event];
          SearchTimelineActions.setSelectEvent({
            StartTime: currentTime,
            EndTime: '',
            isDst: false,
            isReopen: true,
          });
          SearchTimelineActions.setInstantPlaybackSeeking({
            currentTime: '',
            localTime: '',
          });
          this.onUpdate({
            timeLine: {
              Channel: timeLine.Channel,
              Results: results,
            },
            startDate: results[0].StartTime,
          });
        } else {
          // const event = filterEvent.toJS();
          const results = [...timeLine.normal, ...timeLine.event];
          if (currentTabName === 'eventTab') {
            SearchTimelineActions.setSelectEvent({
              StartTime: results[0].StartTime,
              EndTime: timeLine.endPlayTime,
              isDst: results[0].StartDst,
              isReopen: true,
              selectedOverlappedIDForUmp: selectedOverlappedID,
            });
          }
          this.onUpdate({
            timeLine: {
              Channel: timeLine.Channel,
              Results: results,
            },
            startDate: results[0].StartTime,
          });
        }
      } else {
        this.onUpdate({
          timeLine: [],
        });
      }
    }

    if (JSON.stringify(prevOverlappedIDList) !== JSON.stringify(overlappedIDList)) {
      // console.log('>>> overlapped id changed >>>', overlappedIDList[0]);
      const overlappedID = overlappedIDList;
      // const findIndex = overlappedID.findIndex(item => item === selectedOverlappedID);
      // const selectedIndex = findIndex !== -1 ? findIndex : 0;
      SearchTimelineActions.setOverlappedId({ overlappedId: overlappedID[0] });
    }

    if (JSON.stringify(prevSelectedOverlappedID) !== JSON.stringify(selectedOverlappedID)) {
      if (currentTabName === 'eventTab') {
        const { searchDateObj } = this.props;
        if (searchDateObj !== null) {
          const { year, month, day } = searchDateObj;
          SearchTimelineActions.getTimeline({
            startDate: new Date(year, month - 1, day),
            type: currentTabName,
          });
        }
      } else {
        // 타임라인만 초기화 하는 구문 추가 필요
        // SearchTimelineActions.setInitSearchTimeline();
      }
    }

    if (prevDstInfo.endDate.valueOf() !== dstInfo.endDate.valueOf()) {
      const newDstInfo = dstInfo;
      if (JSON.stringify(newDstInfo.endDate) !== JSON.stringify({})) {
        const startTime = new Date(newDstInfo.endDate.getFullYear(),
          newDstInfo.endDate.getMonth(),
          newDstInfo.endDate.getDate(),
          newDstInfo.endDate.getHours() - 1,
          newDstInfo.endDate.getMinutes(),
          newDstInfo.endDate.getSeconds());
        const endTime = new Date(newDstInfo.endDate.getFullYear(),
          newDstInfo.endDate.getMonth(),
          newDstInfo.endDate.getDate(),
          newDstInfo.endDate.getHours(),
          newDstInfo.endDate.getMinutes(),
          newDstInfo.endDate.getSeconds());
        this.onUpdate({
          timelineDstRange: { startTime, endTime },
          timelineDstEnable: dstEnableInfo,
        });
      } else {
        this.onUpdate({
          timelineDstRange: null,
        });
      }
    }

    if (prevPopupVisible === true && popupVisible === false) {
      this.onCurrentTimeChange(currentTimeChange);
    }

    // if (JSON.stringify(prevFilter) !== JSON.stringify(filter)) {
    //   const timeLine = propsTimeLine ? propsTimeLine[0] : null;
    //   if (timeLine) {
    //     timeLine.map(timeline => {

    //     })
    //     const checkResult = timeLine.find(data => data.Type === filter);
    //     const results = [...timeLine.normal, ...timeLine.event];

    //     this.onUpdate({
    //       timeLine: {
    //         Channel: timeLine.Channel,
    //         Results: results,
    //       },
    //       startDate: results[0].StartTime,
    //     });
    //   } else {
    //     this.onUpdate({
    //       timeLine: [],
    //     });
    //   }
    // }
  }

  onClick = type => event => {
    switch (type) {
      case 'overlap': {
        const { currentTime } = this.props;
        // console.log('>>> overlapped id:', Number(event.target.value));
        SearchTimelineActions.setOverlappedId({
          overlappedId: Number(event.target.value),
          overlappedIdChangeTime: currentTime,
        });
      }
        break;
      case 'left':
      case 'right': {
        const { currentTabName } = this.props;
        if (currentTabName === 'eventTab') {
          const directionNum = type === 'left' ? -1 : 1;
          const { searchDateObj, calendarSearchDate } = this.props;
          if (searchDateObj !== null) {
            const { year, month, day } = searchDateObj;
            const date = new Date(year, month - 1, day);
            const searchDateIndex = calendarSearchDate.length !== 0
              // eslint-disable-next-line max-len
              ? calendarSearchDate.findIndex(calendar => calendar.valueOf() === date.valueOf()) + directionNum
              : -1;
            const selectDate = calendarSearchDate[searchDateIndex];
            EventSearchActions.setSearchDate(selectDate);
          }
        }
        break;
      }
      case 'plus':
      case 'minus': {
        const { zoom } = this.state;
        this.onUpdate({
          zoom: type === 'plus' ? Number(zoom + 0.2) : Number(zoom - 0.2),
        });
        break;
      }
      case 'channel': {
        const {
          selectEvent,
        } = this.props;

        SearchTimelineActions.setCurrentChannel(Number(event.target.value));
        SearchTimelineActions.getTimeline({
          startDate: selectEvent.toJS().PlayTime,
          type: 'textTab',
        });
        break;
      }
      case 'timeLabel': {
        const { timeLine } = this.state;
        if (typeof timeLine.Channel === 'undefined') {
          break;
        }
        const { timeLabelCtrl } = this.state;
        // const { currentTime, timeZone } = this.props;
        // const timeObj = {
        //   hour: new Date(currentTime).getHours()
        //     - new Date(currentTime).getTimezoneOffset() / 60 - Number(timeZone),
        //   minute: new Date(currentTime).getMinutes(),
        //   second: new Date(currentTime).getSeconds(),
        // };
        // EventSearchActions.setTime(timeObj);
        // SearchMediaControlActions.playControl({
        //   playMode: MediaControlIDList.PAUSE,
        // });
        const { setCurrentTimeWork } = this.props;
        setCurrentTimeWork({ isWork: false });
        this.setState({
          timeLabelCtrl: !timeLabelCtrl,
        });
        break;
      }
      case 'onClickOutside': {
        const { timeLabelCtrl } = this.state;
        const { searchDateObj, searchTimeObj } = this.props;
        if (searchDateObj !== null) {
          const { year, month, day } = searchDateObj;
          const { hour, minute, second } = searchTimeObj;
          const currentTime = new Date(year, month - 1, day, hour, minute, second);
          // const dstRangeIn = this.checkDSTRange(currentTime);
          // if (dstRangeIn && dstEnableInfo) {
          //   currentTime.setHours(currentTime.getHours() - 1);
          //   console.log('onClickOutside dstRangeIn && dstEnableInfo ', dstRangeIn
          // && dstEnableInfo, ' currentTime -1 ', currentTime);
          // }
          const convertTime = this.convertGMTTime(currentTime);
          this.onCurrentTimeChange(convertTime);
          this.setState({
            timeLabelCtrl: !timeLabelCtrl,
          });
        }
        break;
      }
      default:
        break;
    }
  }

  checkDSTRange = time => {
    const { dstInfo, dstEnableInfo } = this.props;
    const { timelineDstRange, dstEnable } = this.state;
    if (!dstEnableInfo) return false;
    const dstRangeIn = dstInfo.startDate.valueOf() < time.valueOf()
      && time.valueOf()
      < (dstEnable ? timelineDstRange.startTime.valueOf() : dstInfo.endDate.valueOf());
    return dstRangeIn;
  }

  onChangeDstSelect = e => {
    this.setState({
      dstEnable: (e.target.value === 'true'),
    });
  }

  onChangePopupVisible = () => {
    const { popupVisible } = this.state;
    this.setState({
      popupVisible: !popupVisible,
    });
  }

  convertGMTTime = time => {
    const { timeZone } = this.props;
    const changeCurrentTime = time.setHours(time.getHours()
      - time.getTimezoneOffset() / 60 + Number(timeZone));
    const convertCurrentTime = new Date(changeCurrentTime).toISOString();

    return convertCurrentTime;
  }

  onTimechanged = event => {
    // console.log('event', event);
    // const { timeZone } = this.props;
    const { timeLabelCtrl } = this.state;

    // const convertCurrentTime = event.time;
    // const changeCurrentTime = convertCurrentTime.setHours(convertCurrentTime.getHours()
    //   - convertCurrentTime.getTimezoneOffset() / 60 + Number(timeZone));
    // const currentTime = new Date(changeCurrentTime).toISOString();
    const currentTime = this.convertGMTTime(event.time);

    if (event.dstInfoChange.inDst) {
      this.setState({
        currentTimeChange: currentTime,
      });
      this.onChangePopupVisible();
    } else {
      this.onCurrentTimeChange(currentTime, event.selectedData.resultId);
    }
    if (timeLabelCtrl) {
      this.setState({
        timeLabelCtrl: false,
      });
    }
  }

  convertDstStartTime = currentTime => {
    const convertCurrentTime = new Date(currentTime);
    const changeCurrentTime = convertCurrentTime.setHours(convertCurrentTime.getHours() - 1);
    return new Date(changeCurrentTime).toISOString();
  }

  /**
   * @name onCurrentTimeChange
   * @param curremtTime string 타입의 시간 값 (Date 객체 아님)
   */
  onCurrentTimeChange = (currentTime, resultId) => {
    // console.log('>>> currentTime', currentTime, typeof currentTime);
    const { setCurrentTimeWork } = this.props;
    const { dstEnable, timelineDstRange, timeLine } = this.state;
    if (!timeLine.Results) return;

    const { filterEvent: propsFilterEvent } = this.props;
    const filterEvent = propsFilterEvent.toJS();

    const distTimeLine = timeLine.Results.map(element => ({
      ...element,
      distance: Math.abs(new Date(element.StartTime) - new Date(currentTime)),
    }));
    const pickedtime = distTimeLine.find(
      element => ((new Date(element.StartTime)).valueOf() < new Date(currentTime).valueOf()
        && (new Date(element.EndTime)).valueOf() > (new Date(currentTime).valueOf())),
    );

    if (!pickedtime) {
      distTimeLine.sort((a, b) => a.distance - b.distance);

      SearchTimelineActions.setSelectEvent({
        isReopen: true,
        StartTime: distTimeLine[0].StartTime,
        isDst: this.checkDSTRange(new Date(distTimeLine[0].StartTime)),
      });
      setCurrentTimeWork({
        isWork: true,
        // currentTime: new Date(distTimeLine[0].StartTime).toISOString(),
        dstMode: dstEnable,
        dstTimeRange: dstEnable ? timelineDstRange : null,
        beforeDSTEnd: dstEnable,
      });
    } else {
      const result = filterEvent.findIndex(item => item.Result === resultId);
      SearchTimelineActions.setSelectEvent({
        index: result,
        isReopen: true,
        StartTime: currentTime,
        isDst: this.checkDSTRange(new Date(currentTime)),
      });
      setCurrentTimeWork({
        isWork: true,
        // currentTime,
        dstMode: dstEnable,
        dstTimeRange: dstEnable ? timelineDstRange : null,
        beforeDSTEnd: dstEnable,
      });
    }
  }

  onMouseDownTimeline = event => {
    if (event.what === 'custom-time') {
      const { setCurrentTimeWork } = this.props;
      setCurrentTimeWork({ isWork: false });
    }
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  );

  customTimeChangeEvent = () => {
    // TimelineClickEvent : getCustomTime
    // const newCurrentTime = e.time;
    // const newSelectedData = e.selectedData;
    // console.log(`CurrentCustomTimeChanged::::::${newCurrentTime}`);
    // console.log(`CurrentSelectedDataChanged::::::${newSelectedData.group}`);
  }

  zoomChangeEvent = e => {
    // console.log(`zoomChanged::::::${e.timelineRange}`);
    this.setState({
      zoom: 0,
      zoomDT: e.timelineRange,
    });
  }

  changedTimeRange = (startTime, endTime) => {
    const { setCurrentTimeRange } = this.props;
    const selectedTimeRangeInfo = {
      startTime: new Date(startTime),
      endTime: new Date(endTime),
    };
    ManualBackupActions.setTimeRange({
      selectedTimeRangeInfo,
    });
    setCurrentTimeRange(startTime, endTime);
  }

  canceledTimeRange = () => {
    const { setCurrentTimeRange } = this.props;
    setCurrentTimeRange(null, null);

    SearchMediaControlActions.setSelectTimeRange({
      useSelectTimeRange: false,
    });
    ManualBackupActions.setSelectTimeRange({
      useSelectTimeRange: false,
    });
  }

  render() {
    const {
      render,
    } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

SearchTimelineContainer.defaultProps = {
  searchDateObj: null,
  instantPlaybackSeeking: {},
  filterEvent: List([]),
  dstEnableInfo: false,
};

SearchTimelineContainer.propTypes = {
  render: PropTypes.func.isRequired,
  timeLine: PropTypes.oneOfType([PropTypes.any]).isRequired,
  overlappedIDList: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedOverlappedID: PropTypes.number.isRequired,
  currentTabName: PropTypes.oneOf(['eventTab', 'textTab']).isRequired,
  selectEvent: PropTypes.instanceOf(Map).isRequired,
  // searchResult: PropTypes.arrayOf(PropTypes.shape({
  //   ChannelIDList: PropTypes.array,
  //   Data: PropTypes.string,
  //   PlayTime: PropTypes.string,
  //   Result: PropTypes.number,
  //   TextData: PropTypes.string,
  // })).isRequired,
  // SearchCurrentTimeContainer 제어
  setCurrentTimeWork: PropTypes.func.isRequired,
  searchDateObj: PropTypes.instanceOf(Object),
  searchTimeObj: PropTypes.instanceOf(Object).isRequired,
  calendarSearchDate: PropTypes.instanceOf(Object).isRequired,
  setCurrentTimeRange: PropTypes.func.isRequired,
  currentTime: PropTypes.string.isRequired,
  dstInfo: PropTypes.oneOfType([PropTypes.any]).isRequired,
  timeZone: PropTypes.number.isRequired,
  instantPlaybackSeeking: PropTypes.instanceOf(Object),
  dstEnableInfo: PropTypes.bool,
  filterEvent: PropTypes.instanceOf(List),
};

export default connect(
  state => ({
    overlappedIDList: state.searchTimelineModule.get('overlappedIDList'),
    selectedOverlappedID: state.searchTimelineModule.get('selectedOverlappedID'),
    timeLine: state.searchTimelineModule.get('timeLineSearchResults'),
    searchResult: state.textSearchModule.get('searchResult'),
    selectEvent: state.searchTimelineModule.get('selectEvent'),
    searchDateObj: state.eventSearchModule.get('searchDateObj'),
    searchTimeObj: state.eventSearchModule.get('searchTimeObj'),
    dstInfo: state.eventSearchModule.get('dstInfo').toJS(),
    filterEvent: state.searchTimelineModule.get('filterEvent'),
    useSelectTimeRange: state.searchMediaControlModule.get('useSelectTimeRange'),
    calendarSearchDate: state.eventSearchModule.get('calendarSearchDate'),
    instantPlaybackSeeking: state.searchTimelineModule.get('instantPlaybackSeeking'),
    filter: state.eventSearchModule.get('eventfilterdata').toJS(),
    timeZone: state.deviceInfoModule.get('timeZone'),
    dstEnableInfo: state.deviceInfoModule.get('dateInfo').toJS().DSTEnable,
  }),
)(SearchTimelineContainer);
