import React from 'react';
import PropTypes from 'prop-types';
import umpPlayMode from 'wisenet-ui/util/static/constants/umpPlayer/umpPlayMode';
import UmpInfomationType from 'wisenet-ui/util/static/constants/umpPlayer/umpInfomationType';
import { MediaControlIDList } from 'wisenet-ui/util/static/constants/mediaControl/mediaControlType';
import { MessageBoxActions } from 'store/actionCreators';
import { getLanguage } from 'util/lib';

class TileLiveContainer extends React.Component {
  state = {
    mode: umpPlayMode.LIVE,
    isBackup: false,
  }

  handleFunctionMode = {};

  currentTime = 0;

  componentDidMount() {
    this.handleFunctionMode = {
      [UmpInfomationType.PC_RECORD]: this.pcRecordFunc,
      [UmpInfomationType.MIC]: this.micFunc,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      umpPlayer: prevUmpPlayer,
      tileControlWork: prevTileControlWork,
      tileMode: prevTileMode,
      instantPlaybackMode: prevPlaybackMode,
      notOverTotalResolution: prevNotOverTotalResolution,
      isInstantPlaybackSeekingTimeChange: prevIsInstantPlaybackSeekingTimeChange,
      mic: prevMic,
      layoutPageCurrentNumber: prevLayoutPageCurrentNumber,
    } = prevProps;
    const {
      umpPlayer,
      tileControlWork,
      tileMode,
      instantPlaybackSeekingTime,
      instantPlaybackMode,
      notOverTotalResolution,
      isInstantPlaybackSeekingTimeChange,
      mic,
      layoutPageCurrentNumber,
      controlResoution,
    } = this.props;

    // notOverTotalResolution 확인 true는 play false는 stop

    // tile을 통해서 내려오는 control을 통합
    if (
      JSON.stringify(prevTileControlWork) !== JSON.stringify(tileControlWork)
    ) {
      let changeValue;
      Object.entries(tileControlWork).map(([key, value]) => {
        if (value && prevTileControlWork[key] !== value) {
          changeValue = key;
        }
        return null;
      });
      if (changeValue && this.handleFunctionMode[changeValue]) {
        this.handleFunctionMode[changeValue](prevProps[changeValue]);
      }
    } else if (prevMic === true && mic === false) {
      umpPlayer.talk(false);
    }

    // Resolution del일 경우
    if (controlResoution) {
      if (prevUmpPlayer === null && umpPlayer !== null) {
        if (notOverTotalResolution) {
          umpPlayer.play();
        } else {
          this.overTotalResolutionPopup();
        }
      } else if (
        JSON.stringify(prevNotOverTotalResolution) !== JSON.stringify(notOverTotalResolution)
        && umpPlayer !== null && (prevLayoutPageCurrentNumber === layoutPageCurrentNumber)
      ) {
        // 다른 Tile의 삭제 변경에 의한 상태 변경된 Tile On/Off하기 위한 코드
        if (notOverTotalResolution) {
          umpPlayer.play();
        } else {
          this.overTotalResolutionPopup();
          setTimeout(() => {
            umpPlayer.stop();
          }, 1000);
        }
      }
    }

    if (prevTileMode !== tileMode) {
      if (tileMode === MediaControlIDList.INSTANT_PLAYBACK) {
        umpPlayer.playType = umpPlayMode.INSTANT_PLAYBACK;
      } else if (prevTileMode === MediaControlIDList.INSTANT_PLAYBACK) {
        umpPlayer.stop();
        umpPlayer.playType = umpPlayMode.LIVE;
        if (notOverTotalResolution) {
          umpPlayer.play();
        }
      }
    }

    if (tileMode === MediaControlIDList.INSTANT_PLAYBACK) {
      if (isInstantPlaybackSeekingTimeChange && (
        isInstantPlaybackSeekingTimeChange !== prevIsInstantPlaybackSeekingTimeChange)
      ) {
        umpPlayer.seekingTime = instantPlaybackSeekingTime.toString();
      }

      if (prevPlaybackMode !== instantPlaybackMode) {
        umpPlayer[instantPlaybackMode]();
      }
    }
  }

  componentWillUnmount() {
    const { umpPlayer } = this.props;

    if (umpPlayer && umpPlayer.isTalk) {
      umpPlayer.talk(false);
    }
  }

  overTotalResolutionPopup = () => {
    MessageBoxActions.controlMessageBox({
      messageBoxInfo: {
        title: getLanguage('lang_warning'),
        content: getLanguage('lang_maximum_resolution'),
        isOpen: true,
      },
    });
  }

  pcRecordFunc = () => {
    const { umpPlayer } = this.props;
    if (umpPlayer) {
      const { fileName, pcRecord } = this.props;
      if (!pcRecord) {
        umpPlayer.filename = fileName;
        umpPlayer.backup(!pcRecord);
      }
      umpPlayer.backup(!pcRecord);
    }
  }

  micFunc = prevPropsMic => {
    const { umpPlayer, onTileInfo } = this.props;
    if (umpPlayer) {
      const { mic } = this.props;
      umpPlayer.talk(!mic);

      if (!prevPropsMic) {
        // error가 올라 오기 전에는 우선 처리하여 응답하는 로직
        onTileInfo(UmpInfomationType.MIC)({
          onData: {
            [UmpInfomationType.MIC]: !mic,
          },
          ...this.props,
        });
      } else {
        onTileInfo(UmpInfomationType.MIC)({
          onData: {
            [UmpInfomationType.MIC]: umpPlayer.isTalk,
          },
          ...this.props,
        });
      }
    }
  };

  onBackupstatechange = event => {
    const { onBackupstatechange, onTileInfo } = this.props;
    onBackupstatechange(event);
    if (onTileInfo) {
      switch (event.detail.state) {
        case 0x0600: // backup Start
        case 0x0601: { // backup Stop
          onTileInfo(UmpInfomationType.PC_RECORD)({
            onData: {
              [UmpInfomationType.PC_RECORD]: event.detail.state === 0x0600,
            },
            ...this.props,
          });
          break;
        }
        case 0x0602: { // backup error
          onTileInfo(UmpInfomationType.PC_RECORD)({
            onData: {
              [UmpInfomationType.PC_RECORD]: false,
              error: event,
            },
            ...this.props,
          });
          break;
        }
        default:
          break;
      }
    }
  };

  onError = event => {
    const { onError, onTileInfo, umpPlayer } = this.props;
    onError(event);
    if (onTileInfo && event.detail) {
      console.log(event.detail);
      switch (event.detail.error) {
        case 0x020A: { // Talk service unavailable
          onTileInfo(UmpInfomationType.MIC)({
            onData: {
              [UmpInfomationType.MIC]: false,
              error: event,
            },
            ...this.props,
          });
          // true -> false로 변경
          umpPlayer.talk(false);
          break;
        }
        case 0x0204: { // Maximum user Reached
          MessageBoxActions.controlMessageBox({
            messageBoxInfo: {
              title: getLanguage('lang_warning'),
              content: getLanguage('lang_live_maximum_user'),
              isOpen: true,
            },
          });
          break;
        }
        case 0x0203: { // RTSP no response Error
          // true로 설정 했을 경우만 다시 play, 반복 play 시도 할 수 없음
          if (umpPlayer && umpPlayer.isTalk) {
            onTileInfo(UmpInfomationType.MIC)({
              onData: {
                [UmpInfomationType.MIC]: false,
                error: event,
              },
              ...this.props,
            });
            umpPlayer.talk(false);
            umpPlayer.play();
          }
          break;
        }
        default:
          break;
      }
    }
  }

  onInstantplayback = event => {
    const { onInstantplayback, onTileInfo } = this.props;
    onInstantplayback(event);

    // console.log('event.detail.state', event.detail);
    if (onTileInfo) {
      switch (event.detail.state) {
        case 0x1100: { // instantPlayback Start
          this.currentTime = Math.floor(event.detail.timeline.currentTime);
          onTileInfo(UmpInfomationType.INSTANT_PLAYBACK)({
            onData: {
              startTime: Math.round(event.detail.timeline.startTime),
              endTime: Math.floor(event.detail.timeline.endTime),
              currentTime: this.currentTime,
            },
            ...this.props,
          });
          break;
        }
        case 0x1103: { // instantPlayback End
          // console.log('instantPlayback End', event.detail);
          const eventCurrentTime = Math.floor(event.detail.currentTime);
          onTileInfo(UmpInfomationType.INSTANT_PLAYBACK)({
            onData: {
              currentTime: eventCurrentTime,
            },
            ...this.props,
          });
          break;
        }
        case 0x1102: { // instantPlayback currentTime
          const eventCurrentTime = Math.floor(event.detail.currentTime);
          if (eventCurrentTime !== this.currentTime) {
            this.currentTime = eventCurrentTime;
            onTileInfo(UmpInfomationType.INSTANT_PLAYBACK)({
              onData: {
                currentTime: this.currentTime,
              },
              ...this.props,
            });
          }
          break;
        }
        default:
          break;
      }
    }
  };

  render() {
    const {
      render,
    } = this.props;

    return render(
      {
        ...this.props,
        ...this.state,
        ...this,
      },
    );
  }
}

TileLiveContainer.defaultProps = {
  umpPlayer: null,
  onBackupstatechange: () => {},
  onTileInfo: () => (() => {}),
  tileControlWork: {},
  fileName: 'none',
  pcRecord: false,
  mic: false,
  onError: () => {},
  onInstantplayback: () => {},
  tileMode: 'normar',
  instantPlaybackSeekingTime: 0,
  instantPlaybackMode: '',
  notOverTotalResolution: true,
  isInstantPlaybackSeekingTimeChange: false,
  layoutPageCurrentNumber: 0,
  controlResoution: true,
};

TileLiveContainer.propTypes = {
  render: PropTypes.func.isRequired,
  umpPlayer: PropTypes.oneOfType([PropTypes.any]),
  onBackupstatechange: PropTypes.func,
  onTileInfo: PropTypes.func, // ump로 얻어야 하는 데이터를 올리는 콜백함수
  tileControlWork: PropTypes.shape({
    [UmpInfomationType.PC_RECORD]: PropTypes.bool,
    [UmpInfomationType.MIC]: PropTypes.bool,
  }),
  fileName: PropTypes.string,
  pcRecord: PropTypes.bool,
  mic: PropTypes.bool,
  onError: PropTypes.func,
  tileMode: PropTypes.string,
  instantPlaybackSeekingTime: PropTypes.number,
  instantPlaybackMode: PropTypes.string,
  onInstantplayback: PropTypes.func,
  notOverTotalResolution: PropTypes.bool,
  isInstantPlaybackSeekingTimeChange: PropTypes.bool,
  layoutPageCurrentNumber: PropTypes.number,
  controlResoution: PropTypes.bool,
};

export default TileLiveContainer;
