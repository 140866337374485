import styled from 'styled-components';
import { Button } from 'wisenet-ui/components/atoms';
import { Toggle } from 'wisenet-ui/components/organisms';

export const TableWrapperStyled = styled.div`
  height: ${props => `${props.height}px`};
  margin-top: 5px;
`;

export const SetupButtonStyled = styled(Button)`
  min-width: 113px;
  height: 28px;
  font-size: 14px;
  padding: 0 10px;
`;

export const RecordTopWrapperStyled = styled.div`
  display: flex;
  height: 30px;
  font-size: 12px;
  align-items: center;
  justify-content: space-between;
`;

export const ToggleWrapperStyled = styled.div`
  margin-top: 3px;
  display: flex;
`;


export const ToggleStyled = styled(Toggle)`
  width: 39px;
  margin: 0 5px;
`;

export const RecordMiddleWrapperStyled = styled.div`
  display: flex;
  height: 28px;
  margin-top: 8px;
  margin-bottom: 57px;
  align-items: center;
  justify-content: space-between;
  color: #797979;
`;

export const WarningWrapperStyled = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
`;

export const WarningIconStyled = styled.i`
  font-size: 20px;
  margin-right: 16px;
`;

export const WarningStatusStyled = styled.span`
  margin-right: 59px;
`;

export const ColorBoxStyled = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;

export const HDDSizeWrapperStyled = styled.div`
  font-size: 12px;
  margin-bottom: 15px;
`;

export const HDDSizeTitleStyled = styled.span`
  margin-right: 44px;
`;

export const RecordingSizeWrapperStyled = styled.div`
  display: flex;
  font-size: 12px;
  justify-content: space-between;
`;

export const RecordingSizeTitleStyled = styled.span`
  margin-right: 38px;
`;
