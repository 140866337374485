import React from 'react';
import PropTypes from 'prop-types';

class LiveVideoTileInstantPlaybackContainer extends React.Component {
  state = {
    instantPlaybackTimeRage: {
      startTime: 0,
      endTime: 0,
    },
    instantPlaybackMode: 'pause',
    instantPlaybackSeekingTime: 0,
    instantPlayTime: 0,
    isInstantPlaybackSeekingTimeChange: false,
  };

  setInstantPlaybackTimeRange = (startTime, endTime) => {
    const isOverTime = endTime > 30;
    this.setState({
      instantPlaybackTimeRage: {
        startTime: isOverTime ? 0 : startTime,
        endTime: isOverTime ? endTime - startTime : endTime,
      },
      instantPlayTime: isOverTime ? startTime : 0,
      instantPlaybackMode: 'pause',
    });
  }

  setInstantPlaybackMode = mode => {
    this.setState({
      instantPlaybackMode: mode,
    });
  }

  setInstantPlaybackSeekingTime = (seektime, isUmp, isChange) => {
    const {
      instantPlayTime,
      instantPlaybackMode,
      instantPlaybackTimeRage: { endTime },
    } = this.state;
    const instantPlaybackSeekingTime = isUmp ? seektime : instantPlayTime + seektime;
    const end = instantPlaybackSeekingTime - instantPlayTime >= endTime;
    this.setState({
      instantPlaybackSeekingTime,
      instantPlaybackMode: end ? 'pause' : instantPlaybackMode,
      isInstantPlaybackSeekingTimeChange: isChange,
    });
  }

  render() {
    const { render } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

LiveVideoTileInstantPlaybackContainer.propTypes = {
  render: PropTypes.func.isRequired,
};

export default LiveVideoTileInstantPlaybackContainer;
