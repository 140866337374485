import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { TimePickerContainer } from 'wisenet-ui/containers/organisms';
import {
  Container,
  Stepper,
  Divider,
  StepperInput,
  StepperButton,
} from './TimePickerStyled';

class CustomTimePicker extends React.PureComponent {
  constructor(props) {
    super(props);
    const { dateTimeObj } = this.props;
    const {
      hour,
      minute,
      second,
    } = dateTimeObj;

    const hourStr = hour.toString();
    const minuteStr = minute.toString();
    const secondStr = second.toString();

    this.state = {
      currentHour: hourStr.length === 1 ? `0${hourStr}` : hourStr,
      currentMinute: minuteStr.length === 1 ? `0${minuteStr}` : minuteStr,
      currentSecond: secondStr.length === 1 ? `0${secondStr}` : secondStr,
    };

    this.onTyped = this.onTyped.bind(this);
    this.onClickUpHour = this.onClickUpHour.bind(this);
    this.onClickUpMinute = this.onClickUpMinute.bind(this);
    this.onClickUpSecond = this.onClickUpSecond.bind(this);
    this.onClickDownHour = this.onClickDownHour.bind(this);
    this.onClickDownMinute = this.onClickDownMinute.bind(this);
    this.onClickDownSecond = this.onClickDownSecond.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  onFocus = (e, target) => {
    document.getElementById('hStepUp1').style.visibility = 'hidden';
    document.getElementById('hStepDown1').style.visibility = 'hidden';
    document.getElementById('hStepUp2').style.visibility = 'hidden';
    document.getElementById('hStepDown2').style.visibility = 'hidden';
    document.getElementById('mStepUp1').style.visibility = 'hidden';
    document.getElementById('mStepDown1').style.visibility = 'hidden';
    document.getElementById('mStepUp2').style.visibility = 'hidden';
    document.getElementById('mStepDown2').style.visibility = 'hidden';
    document.getElementById('sStepUp1').style.visibility = 'hidden';
    document.getElementById('sStepDown1').style.visibility = 'hidden';
    document.getElementById('sStepUp2').style.visibility = 'hidden';
    document.getElementById('sStepDown2').style.visibility = 'hidden';

    const targetUp = target.split('_').join('Up');
    const targetDown = target.split('_').join('Down');
    document.getElementById(targetUp).style.visibility = 'visible';
    document.getElementById(targetDown).style.visibility = 'visible';
  }

  onTyped = (e, target) => {
    const { value } = e.target;
    if (value !== '' && !Number.isInteger(Number(value))) {
      return;
    }
    const { onTimeChange, label } = this.props;
    const { currentHour, currentMinute, currentSecond } = this.state;

    const [hour1, hour2] = currentHour;
    const [minute1, minute2] = currentMinute;
    const [second1, second2] = currentSecond;

    if (target === 'hStep_1') {
      if (value === '') {
        this.setState({
          currentHour: `#${hour2}`,
        });
      } else if (Number(value) > 2) {
        e.target.value = hour1;
      } else {
        const hourText = `${value}${hour2}`;
        if (Number(hourText) >= 24) {
          e.target.value = hour1;
        } else {
          onTimeChange({
            flag: label,
            hour: Number(hourText),
            minute: Number(currentMinute),
            second: Number(currentSecond),
          });
          this.setState({
            currentHour: hourText,
          });
        }
      }
    } else if (target === 'hStep_2') {
      if (value === '') {
        this.setState({
          currentHour: `${hour1}#`,
        });
      } else {
        const hourText = `${hour1}${value}`;
        if (Number(hourText) >= 24) {
          e.target.value = hour2;
        } else {
          onTimeChange({
            flag: label,
            hour: Number(hourText),
            minute: Number(currentMinute),
            second: Number(currentSecond),
          });
          this.setState({
            currentHour: hourText,
          });
        }
      }
    } else if (target === 'mStep_1') {
      if (value === '') {
        this.setState({
          currentMinute: `#${minute2}`,
        });
      } else if (Number(value) > 5) {
        e.target.value = minute1;
      } else {
        const minuteText = `${value}${minute2}`;
        if (Number(minuteText) > 59) {
          e.target.value = minute2;
        } else {
          onTimeChange({
            flag: label,
            hour: Number(currentHour),
            minute: Number(minuteText),
            second: Number(currentSecond),
          });
          this.setState({
            currentMinute: minuteText,
          });
        }
      }
    } else if (target === 'mStep_2') {
      if (value === '') {
        this.setState({
          currentMinute: `${minute1}#`,
        });
      } else {
        const minuteText = `${minute1}${value}`;
        if (Number(minuteText) > 59) {
          e.target.value = minute2;
        } else {
          onTimeChange({
            flag: label,
            hour: Number(currentHour),
            minute: Number(minuteText),
            second: Number(currentSecond),
          });
          this.setState({
            currentMinute: minuteText,
          });
        }
      }
    } else if (target === 'sStep_1') {
      if (value === '') {
        this.setState({
          currentSecond: `#${second2}`,
        });
      } else if (Number(value) > 5) {
        e.target.value = second1;
      } else {
        const secondText = `${value}${second2}`;
        if (Number(secondText) > 59) {
          e.target.value = second1;
        } else {
          onTimeChange({
            flag: label,
            hour: Number(currentHour),
            minute: Number(currentMinute),
            second: Number(secondText),
          });
          this.setState({
            currentSecond: secondText,
          });
        }
      }
    } else if (target === 'sStep_2') {
      if (value === '') {
        this.setState({
          currentSecond: `${second1}#`,
        });
      } else {
        const secondText = `${second1}${value}`;
        if (Number(secondText) > 59) {
          e.target.value = second2;
        } else {
          onTimeChange({
            flag: label,
            hour: Number(currentHour),
            minute: Number(currentMinute),
            second: Number(secondText),
          });
          this.setState({
            currentSecond: secondText,
          });
        }
      }
    }
    const id = target.split('Step_').join('Input');
    setTimeout(() => document.getElementById(id).focus());
  }

  onBlur = (e, target) => {
    const { currentHour, currentMinute, currentSecond } = this.state;

    const [hour1, hour2] = currentHour;
    const [minute1, minute2] = currentMinute;
    const [second1, second2] = currentSecond;

    if (e.target.value === '') {
      if (target === 'hStep_1') {
        this.setState({
          currentHour: `0${hour2}`,
        });
      } else if (target === 'hStep_2') {
        this.setState({
          currentHour: `${hour1}0`,
        });
      } else if (target === 'mStep_1') {
        this.setState({
          currentMinute: `0${minute2}`,
        });
      } else if (target === 'mStep_2') {
        this.setState({
          currentMinute: `${minute1}0`,
        });
      } else if (target === 'sStep_1') {
        this.setState({
          currentSecond: `0${second2}`,
        });
      } else if (target === 'sStep_2') {
        this.setState({
          currentSecond: `${second1}0`,
        });
      }
    }
  }

  onClickUpHour = flag => {
    const { onTimeChange, label } = this.props;
    const { currentHour, currentMinute, currentSecond } = this.state;
    const [hour1, hour2] = currentHour;
    let newHour = '';

    if (flag === 'hour1') {
      newHour = `${Number(hour1) + 1}${hour2}`;
      if (Number(newHour) >= 24) {
        newHour = `0${hour2}`;
        onTimeChange({
          flag: label,
          hour: Number(newHour),
          minute: Number(currentMinute),
          second: Number(currentSecond),
        });
        this.setState({
          currentHour: newHour,
        });
      } else {
        onTimeChange({
          flag: label,
          hour: Number(newHour),
          minute: Number(currentMinute),
          second: Number(currentSecond),
        });
        this.setState({
          currentHour: newHour,
        });
      }
      setTimeout(() => document.getElementById('hInput1').focus());
    } else if (Number(hour2) + 1 > 9) {
      newHour = `${hour1}0`;
      onTimeChange({
        flag: label,
        hour: Number(newHour),
        minute: Number(currentMinute),
        second: Number(currentSecond),
      });
      this.setState({
        currentHour: newHour,
      });
      setTimeout(() => document.getElementById('hInput2').focus());
    } else {
      newHour = `${hour1}${Number(hour2) + 1}`;
      if (Number(newHour) >= 24) {
        newHour = `${hour1}0`;
        onTimeChange({
          flag: label,
          hour: Number(newHour),
          minute: Number(currentMinute),
          second: Number(currentSecond),
        });
        this.setState({
          currentHour: newHour,
        });
      } else {
        newHour = `${hour1}${Number(hour2) + 1}`;
        onTimeChange({
          flag: label,
          hour: Number(newHour),
          minute: Number(currentMinute),
          second: Number(currentSecond),
        });
        this.setState({
          currentHour: newHour,
        });
      }
      setTimeout(() => document.getElementById('hInput2').focus());
    }
  }

  onClickDownHour = flag => {
    const { onTimeChange, label } = this.props;
    const { currentHour, currentMinute, currentSecond } = this.state;
    const [hour1, hour2] = currentHour;
    let newHour = '';

    if (flag === 'hour1') {
      if (Number(hour1) - 1 < 0) {
        newHour = `2${hour2}`;
        if (Number(newHour) > 23) {
          newHour = `1${hour2}`;
        }
        onTimeChange({
          flag: label,
          hour: Number(newHour),
          minute: Number(currentMinute),
          second: Number(currentSecond),
        });
        this.setState({
          currentHour: newHour,
        });
      } else {
        newHour = `${Number(hour1) - 1}${hour2}`;
        onTimeChange({
          flag: label,
          hour: Number(newHour),
          minute: Number(currentMinute),
          second: Number(currentSecond),
        });
        this.setState({
          currentHour: newHour,
        });
      }
      setTimeout(() => document.getElementById('hInput1').focus());
    } else if (Number(hour2) - 1 < 0) {
      if (Number(hour1) === 2) {
        newHour = `${hour1}3`;
        onTimeChange({
          flag: label,
          hour: Number(newHour),
          minute: Number(currentMinute),
          second: Number(currentSecond),
        });
        this.setState({
          currentHour: newHour,
        });
      } else {
        newHour = `${hour1}9`;
        onTimeChange({
          flag: label,
          hour: Number(newHour),
          minute: Number(currentMinute),
          second: Number(currentSecond),
        });
        this.setState({
          currentHour: newHour,
        });
      }
      setTimeout(() => document.getElementById('hInput2').focus());
    } else {
      newHour = `${hour1}${Number(hour2 - 1)}`;
      onTimeChange({
        flag: label,
        hour: Number(newHour),
        minute: Number(currentMinute),
        second: Number(currentSecond),
      });
      this.setState({
        currentHour: newHour,
      });
      setTimeout(() => document.getElementById('hInput2').focus());
    }
  }

  onClickUpMinute = flag => {
    const { onTimeChange, label } = this.props;
    const { currentHour, currentMinute, currentSecond } = this.state;
    const [minute1, minute2] = currentMinute;
    let newMinute = '';

    if (flag === 'minute1') {
      if (Number(minute1) + 1 > 5) {
        newMinute = `0${minute2}`;
        onTimeChange({
          flag: label,
          hour: Number(currentHour),
          minute: Number(newMinute),
          second: Number(currentSecond),
        });
        this.setState({
          currentMinute: newMinute,
        });
      } else {
        newMinute = `${Number(minute1) + 1}${minute2}`;
        onTimeChange({
          flag: label,
          hour: Number(currentHour),
          minute: Number(newMinute),
          second: Number(currentSecond),
        });
        this.setState({
          currentMinute: newMinute,
        });
      }
      setTimeout(() => document.getElementById('mInput1').focus());
    } else if (Number(minute2) + 1 > 9) {
      newMinute = `${minute1}0`;
      onTimeChange({
        flag: label,
        hour: Number(currentHour),
        minute: Number(newMinute),
        second: Number(currentSecond),
      });
      this.setState({
        currentMinute: newMinute,
      });
      setTimeout(() => document.getElementById('mInput2').focus());
    } else {
      newMinute = `${minute1}${Number(minute2) + 1}`;
      onTimeChange({
        flag: label,
        hour: Number(currentHour),
        minute: Number(newMinute),
        second: Number(currentSecond),
      });
      this.setState({
        currentMinute: newMinute,
      });
      setTimeout(() => document.getElementById('mInput2').focus());
    }
  }

  onClickDownMinute = flag => {
    const { onTimeChange, label } = this.props;
    const { currentHour, currentMinute, currentSecond } = this.state;
    const [minute1, minute2] = currentMinute;
    let newMinute = '';

    if (flag === 'minute1') {
      if (Number(minute1) - 1 < 0) {
        newMinute = `5${minute2}`;
        onTimeChange({
          flag: label,
          hour: Number(currentHour),
          minute: Number(newMinute),
          second: Number(currentSecond),
        });
        this.setState({
          currentMinute: newMinute,
        });
      } else {
        newMinute = `${Number(minute1) - 1}${minute2}`;
        onTimeChange({
          flag: label,
          hour: Number(currentHour),
          minute: Number(newMinute),
          second: Number(currentSecond),
        });
        this.setState({
          currentMinute: newMinute,
        });
      }
      setTimeout(() => document.getElementById('mInput1').focus());
    } else if (Number(minute2) - 1 < 0) {
      newMinute = `${minute1}9`;
      onTimeChange({
        flag: label,
        hour: Number(currentHour),
        minute: Number(newMinute),
        second: Number(currentSecond),
      });
      this.setState({
        currentMinute: newMinute,
      });
      setTimeout(() => document.getElementById('mInput2').focus());
    } else {
      newMinute = `${minute1}${Number(minute2) - 1}`;
      onTimeChange({
        flag: label,
        hour: Number(currentHour),
        minute: Number(newMinute),
        second: Number(currentSecond),
      });
      this.setState({
        currentMinute: newMinute,
      });
      setTimeout(() => document.getElementById('mInput2').focus());
    }
  }

  onClickUpSecond = flag => {
    const { onTimeChange, label } = this.props;
    const { currentHour, currentMinute, currentSecond } = this.state;
    const [second1, second2] = currentSecond;
    let newSecond = '';

    if (flag === 'second1') {
      if (Number(second1) + 1 > 5) {
        newSecond = `0${second2}`;
        onTimeChange({
          flag: label,
          hour: Number(currentHour),
          minute: Number(currentMinute),
          second: Number(newSecond),
        });
        this.setState({
          currentSecond: newSecond,
        });
      } else {
        newSecond = `${Number(second1) + 1}${second2}`;
        onTimeChange({
          flag: label,
          hour: Number(currentHour),
          minute: Number(currentMinute),
          second: Number(newSecond),
        });
        this.setState({
          currentSecond: newSecond,
        });
      }
      setTimeout(() => document.getElementById('sInput1').focus());
    } else if (Number(second2) + 1 > 9) {
      newSecond = `${second1}0`;
      onTimeChange({
        flag: label,
        hour: Number(currentHour),
        minute: Number(currentMinute),
        second: Number(newSecond),
      });
      this.setState({
        currentSecond: newSecond,
      });
      setTimeout(() => document.getElementById('sInput2').focus());
    } else {
      newSecond = `${second1}${Number(second2) + 1}`;
      onTimeChange({
        flag: label,
        hour: Number(currentHour),
        minute: Number(currentMinute),
        second: Number(newSecond),
      });
      this.setState({
        currentSecond: newSecond,
      });
      setTimeout(() => document.getElementById('sInput2').focus());
    }
  }

  onClickDownSecond = flag => {
    const { onTimeChange, label } = this.props;
    const { currentHour, currentMinute, currentSecond } = this.state;
    const [second1, second2] = currentSecond;
    let newSecond = '';

    if (flag === 'second1') {
      if (Number(second1) - 1 < 0) {
        newSecond = `5${second2}`;
        onTimeChange({
          flag: label,
          hour: Number(currentHour),
          minute: Number(currentMinute),
          second: Number(newSecond),
        });
        this.setState({
          currentSecond: newSecond,
        });
      } else {
        newSecond = `${Number(second1) - 1}${second2}`;
        onTimeChange({
          flag: label,
          hour: Number(currentHour),
          minute: Number(currentMinute),
          second: Number(newSecond),
        });
        this.setState({
          currentSecond: newSecond,
        });
      }
      setTimeout(() => document.getElementById('sInput1').focus());
    } else if (Number(second2) - 1 < 0) {
      newSecond = `${second1}9`;
      onTimeChange({
        flag: label,
        hour: Number(currentHour),
        minute: Number(currentMinute),
        second: Number(newSecond),
      });
      this.setState({
        currentSecond: newSecond,
      });
      setTimeout(() => document.getElementById('sInput2').focus());
    } else {
      newSecond = `${second1}${Number(second2) - 1}`;
      onTimeChange({
        flag: label,
        hour: Number(currentHour),
        minute: Number(currentMinute),
        second: Number(newSecond),
      });
      this.setState({
        currentSecond: newSecond,
      });
      setTimeout(() => document.getElementById('sInput2').focus());
    }
  }

  render() {
    const {
      currentHour,
      currentMinute,
      currentSecond,
    } = this.state;

    return (
      <Container>
        <Stepper>
          <StepperButton className="wni wni-arrow-up" id="hStepUp1" onClick={() => this.onClickUpHour('hour1')} />
          <StepperInput
            id="hInput1"
            onFocus={e => this.onFocus(e, 'hStep_1')}
            onBlur={e => this.onBlur(e, 'hStep_1')}
            onChange={e => this.onTyped(e, 'hStep_1')}
            type="text"
            value={currentHour[0] === '#' ? '' : currentHour[0]}
            maxLength="1"
          />
          <StepperButton className="wni wni-arrow-down" id="hStepDown1" onClick={() => this.onClickDownHour('hour1')} />
        </Stepper>
        <Stepper>
          <StepperButton className="wni wni-arrow-up" id="hStepUp2" onClick={() => this.onClickUpHour('hour2')} />
          <StepperInput
            id="hInput2"
            onFocus={e => this.onFocus(e, 'hStep_2')}
            onBlur={e => this.onBlur(e, 'hStep_2')}
            onChange={e => this.onTyped(e, 'hStep_2')}
            type="text"
            value={currentHour[1] === '#' ? '' : currentHour[1]}
            maxLength="1"
          />
          <StepperButton className="wni wni-arrow-down" id="hStepDown2" onClick={() => this.onClickDownHour('hour2')} />
        </Stepper>
        <Divider>:</Divider>
        <Stepper>
          <StepperButton className="wni wni-arrow-up" id="mStepUp1" onClick={() => this.onClickUpMinute('minute1')} />
          <StepperInput
            id="mInput1"
            onFocus={e => this.onFocus(e, 'mStep_1')}
            onBlur={e => this.onBlur(e, 'mStep_1')}
            onChange={e => this.onTyped(e, 'mStep_1')}
            type="text"
            value={currentMinute[0] === '#' ? '' : currentMinute[0]}
            maxLength="1"
          />
          <StepperButton className="wni wni-arrow-down" id="mStepDown1" onClick={() => this.onClickDownMinute('minute1')} />
        </Stepper>
        <Stepper>
          <StepperButton className="wni wni-arrow-up" id="mStepUp2" onClick={() => this.onClickUpMinute('minute2')} />
          <StepperInput
            id="mInput2"
            onFocus={e => this.onFocus(e, 'mStep_2')}
            onBlur={e => this.onBlur(e, 'mStep_2')}
            onChange={e => this.onTyped(e, 'mStep_2')}
            type="text"
            value={currentMinute[1] === '#' ? '' : currentMinute[1]}
            maxLength="1"
          />
          <StepperButton className="wni wni-arrow-down" id="mStepDown2" onClick={() => this.onClickDownMinute('minute2')} />
        </Stepper>
        <Divider>:</Divider>
        <Stepper>
          <StepperButton className="wni wni-arrow-up" id="sStepUp1" onClick={() => this.onClickUpSecond('second1')} />
          <StepperInput
            id="sInput1"
            onFocus={e => this.onFocus(e, 'sStep_1')}
            onBlur={e => this.onBlur(e, 'sStep_1')}
            onChange={e => this.onTyped(e, 'sStep_1')}
            type="text"
            value={currentSecond[0] === '#' ? '' : currentSecond[0]}
            maxLength="1"
          />
          <StepperButton className="wni wni-arrow-down" id="sStepDown1" onClick={() => this.onClickDownSecond('second1')} />
        </Stepper>
        <Stepper>
          <StepperButton className="wni wni-arrow-up" id="sStepUp2" onClick={() => this.onClickUpSecond('second2')} />
          <StepperInput
            id="sInput2"
            onFocus={e => this.onFocus(e, 'sStep_2')}
            onBlur={e => this.onBlur(e, 'sStep_2')}
            onChange={e => this.onTyped(e, 'sStep_2')}
            type="text"
            value={currentSecond[1] === '#' ? '' : currentSecond[1]}
            maxLength="1"
          />
          <StepperButton className="wni wni-arrow-down" id="sStepDown2" onClick={() => this.onClickDownSecond('second2')} />
        </Stepper>
      </Container>
    );
  }
}

CustomTimePicker.propTypes = {
  onTimeChange: PropTypes.func,
  label: PropTypes.string,
  dateTimeObj: PropTypes.objectOf(PropTypes.any),
};

CustomTimePicker.defaultProps = {
  onTimeChange: () => {},
  label: '',
  dateTimeObj: null,
};

export default withContainer(TimePickerContainer, CustomTimePicker);
