import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TimePickerWrapper = styled.div`
  height: 60px;
  padding-top: 15px;
  flex-direction: row;
  color: ${props => props.theme.text.color2};
  background-color: ${props => props.theme.background.color1};
  border-left: 1px solid ${props => props.theme.line.color4};
  border-right: 1px solid ${props => props.theme.line.color4};
  border-bottom: 1px solid ${props => props.theme.line.color4};
`;
