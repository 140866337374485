import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { DropDownMenu } from 'wisenet-ui/components/organisms';
import {
  Span,
} from 'wisenet-ui/components/atoms';

export const AlarmCenterWrapperStyled = styled.div`
  font-size: 24px;
`;

export const IconStyled = styled.i`
  cursor: pointer;
  color:  ${props => props.theme.text.color7};
  width: 44px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.isBlink && css`
    @-webkit-keyframes blink {
      0% {opacity:0;}
      100% {opacity:1;}
    }
    @-moz-keyframes blink {
      0% {opacity:0;}
      100% {opacity:1;}
    }
    @keyframes blink {
      0% {opacity:0;}
      100% {opacity:1;}
    }
    -webkit-animation:blink 0.5s ease-in-out infinite alternate;
    -moz-animation:blink 0.5s ease-in-out infinite alternate;
    animation:blink 0.5s ease-in-out infinite alternate;
  `};
  &:hover {
    background-color: ${props => props.theme.background.color3};
  }
  &:active {
    background-color: ${props => props.theme.background.color4};
  }
`;

export const DropDownMenuStyled = styled(DropDownMenu)`
  display: inline-block;
  & > div {
    z-index: 1;
    top: 56px;
    right: -197px;
    padding: 12px 20px 20px 20px;
    box-shadow: none;
    color: ${props => props.theme.text.color1};
    border: 1px solid ${props => props.theme.line.color5};
    &::before {
      content: "";
      position: absolute;
      top: -12px;
      left: 50%;
      border-width: 0 8px 11.6px 8px;
      border-style: solid;
      border-color: transparent transparent ${props => props.theme.line.color5} transparent;
    }
    &::after {
      content: "";
      position: absolute;
      top: -10px;
      left: 50%;
      border-width: 0 8px 11.6px 8px;
      border-style: solid;
      border-color: transparent transparent ${props => props.theme.background.color1} transparent;
    }
  }
`;

export const ErrorMessageWrapperStyled = styled.div`
  width: 410px;
  background-color: ${props => props.theme.background.color3};
  margin-top: 8px;
  padding: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  white-space: normal;
  line-height: initial;
`;

export const ErrorTitleStyled = styled.div`
  font-size: 12px;
  color: ${props => props.theme.text.color2};
  word-break: break-all;
`;

export const ErrorMessageStyled = styled.div`
  font-size: 14px;
  color: ${props => props.theme.text.color1};
  padding-top: 3px;
  word-break: break-all;
`;

export const CustomReactTooltip = styled(ReactTooltip)`
background-color: #555 !important;
padding: 5px !important;
&.place-top {
  &:after {
    border-top: none !important;
  }
}
&.place-bottom {
  &:after {
    border-bottom: none !important;
  }
}
`;

export const TooltipSpan = styled(Span)`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  margin: 0px;
  padding: 0px
`;
