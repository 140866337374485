import styled, { withTheme } from 'styled-components';
import { IconButton } from 'wisenet-ui/components/atoms';

export const SearchOptionRightStyled = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;

  & > label {
    margin-right: 5px;
  }
`;

export const IconButtonStyled = withTheme(styled(IconButton)`
  padding: 0;

  &:hover {
    color: ${props => props.theme.text.color4};
  }

  &:nth-of-type(1) {
    margin: 0 15px;
  }
`);
