import { createAction, handleActions } from 'redux-actions';
import { Map, List, fromJS } from 'immutable';

// action
export const INIT_EVENT_SEARCH_DATA = 'eventSearch/INIT_EVENT_SEARCH_DATA';

export const CHECK_STORAGE_STATUS = 'eventSearch/CHECK_STORAGE_STATUS';
export const CHECK_STORAGE_STATUS_SUCCESS = 'eventSearch/CHECK_STORAGE_STATUS_SUCCESS';
export const CHECK_STORAGE_STATUS_FAILURE = 'eventSearch/CHECK_STORAGE_STATUS_FAILURE';

export const FIND_RECORDING_DATE = 'eventSearch/FIND_RECORDING_DATE';
export const FIND_RECORDING_DATE_SUCCESS = 'eventSearch/FIND_RECORDING_DATE_SUCCESS';
export const FIND_RECORDING_DATE_FAILURE = 'eventSearch/FIND_RECORDING_DATE_FAILURE';

export const SET_SEARCH_DATE = 'eventSearch/SET_SEARCH_DATE';
export const SET_SEARCH_DATE_SUCCESS = 'eventSearch/SET_SEARCH_DATE_SUCCESS';
export const SET_SEARCH_TIME = 'eventSearch/SET_SEARCH_TIME';


export const SET_EVENT_FILTER_DATA = 'eventSearch/SET_EVENT_FILTER_DATA';

export const REQUEST_EVENT_SEARCH = 'eventSearch/REQUEST_EVENT_SEARCH';

// action create
export const setIniteventSearchData = createAction(INIT_EVENT_SEARCH_DATA);

export const checkStorageStatus = createAction(CHECK_STORAGE_STATUS);
export const checkStorageStatusSuccess = createAction(CHECK_STORAGE_STATUS_SUCCESS);
export const checkStorageStatusFailure = createAction(CHECK_STORAGE_STATUS_FAILURE);

export const findRecordingDate = createAction(FIND_RECORDING_DATE);
export const findRecordingDateSuccess = createAction(FIND_RECORDING_DATE_SUCCESS);
export const findRecordingDateFailure = createAction(FIND_RECORDING_DATE_FAILURE);

export const setSearchDate = createAction(SET_SEARCH_DATE);
export const setSearchDateSuccess = createAction(SET_SEARCH_DATE_SUCCESS);
export const setTime = createAction(SET_SEARCH_TIME);

export const setEventFilterData = createAction(SET_EVENT_FILTER_DATA);

export const requestEventSearch = createAction(REQUEST_EVENT_SEARCH);

// const today = new Date();

const defaultState = Map({
  isStorageEnabled: false,
  storageStatus: '',
  calenderSearchResults: [],
  calendarSearchDate: [],
  // searchDateObj: {
  //   year: today.getFullYear(),
  //   month: today.getMonth() + 1,
  //   day: today.getDate(),
  //   hour: 0,
  //   minute: 0,
  //   second: 0,
  // },
  searchDateObj: null,
  searchTimeObj: {
    hour: 0,
    minute: 0,
    second: 0,
  },
  eventfilterdata: List([
    'all',
    'Normal',
    'Manual',
    'MotionDetection',
    'VideoAnalysis',
    'FaceDetection',
    'TamperingDetection',
    'AutoTracking',
    'DefocusDetection',
    'FogDetection',
    'AudioDetection',
    // 'SoundClassification',
    'AudioAnalysis',
    'AlarmInput',
  ]),
  dstInfo: Map({
    dstEnable: false,
    startDate: {},
    endDate: {},
  }),
});

const initialState = defaultState;

// reducer
export default handleActions({
  [CHECK_STORAGE_STATUS_SUCCESS]: state => (
    state.set('isStorageEnabled', true)
  ),
  [CHECK_STORAGE_STATUS_FAILURE]: (state, action) => (
    state.set('isStorageEnabled', false)
      .set('storageStatus', action.payload)
  ),
  [FIND_RECORDING_DATE_SUCCESS]: (state, action) => (
    state.set('calenderSearchResults', action.payload)
      .set('calendarSearchDate', action.payload.threeMonthResultData)
  ),
  [FIND_RECORDING_DATE_FAILURE]: state => (
    state.set('calenderSearchResults', [])
  ),
  [SET_SEARCH_DATE_SUCCESS]: (state, action) => {
    if (action.payload.dstEnable) {
      return state
        .set('searchDateObj', action.payload.searchDateObj)
        .setIn(['dstInfo', 'dstEnable'], action.payload.dstEnable)
        .setIn(['dstInfo', 'startDate'], action.payload.dstStart)
        .setIn(['dstInfo', 'endDate'], action.payload.dstEnd);
    }
    return state.set('searchDateObj', action.payload.searchDateObj);
  },
  [SET_SEARCH_TIME]: (state, action) => {
    const { hour, minute, second } = action.payload;
    const searchTimeObj = {
      hour,
      minute,
      second,
    };
    return state.set('searchTimeObj', Object.assign({}, searchTimeObj));
  },
  [SET_EVENT_FILTER_DATA]: (state, { payload: { eventfilterdata } }) => (
    state.set('eventfilterdata', fromJS(eventfilterdata))
  ),
  [INIT_EVENT_SEARCH_DATA]: () => (
    defaultState
  ),
}, initialState);
