import React from 'react';
import PropTypes from 'prop-types';
import umpPlayMode from 'wisenet-ui/util/static/constants/umpPlayer/umpPlayMode';

class TileBackupContainer extends React.Component {
  currentPercent = '0';

  // componentDidMount에서 ump-player가 등록된지 여부를 알수 없기 때문에 사용
  startBackup = false;

  load = false;

  state = {
    mode: umpPlayMode.BACKUP,
    className: 'noneDisplay',
    umpPlayer: null,
    useIsoTimeFormat: true,
  }

  componentDidUpdate(prevProps) {
    const { backup: prevBackup } = prevProps;
    const { backup } = this.props;
    const { umpPlayer: prevUmpPlayer } = prevProps;
    const { umpPlayer } = this.state;

    if (!this.load && (prevUmpPlayer !== umpPlayer)) {
      this.load = true;
      this.backup();
    }

    if (this.load && umpPlayer) {
      if (prevBackup !== backup) {
        this.backup();
      }
    }
  }

  setUmpPlayer = ump => {
    const umpPlayer = ump;

    this.setState({
      umpPlayer,
    });
  };

  backup = () => {
    const {
      startTime,
      endTime,
      backup,
      fileName,
      sessionKey,
    } = this.props;

    const { umpPlayer } = this.state;

    if (backup) {
      this.currentPercent = '0';
      umpPlayer.startTime = startTime;
      umpPlayer.endTime = new Date(new Date(endTime).getTime() + 10000).toISOString();
      umpPlayer.filename = fileName;
      umpPlayer.sessionKey = sessionKey;
      umpPlayer.backup(true);
      console.log('startTime ', startTime, ' endTime ', endTime);
    }

    if (this.startBackup) {
      umpPlayer.backup(false);
    }
  }

  onBackupstatechange = event => {
    const {
      onBackup,
      channel,
      onBackupstatechange,
    } = this.props;
    onBackupstatechange(event);
    // console.log('tileBackup Container state', event.detail);
    switch (event.detail.state) {
      case 0x0600: // backup Start
      case 0x0601: { // backup Stop
        const startBackup = event.detail.state === 0x0600;
        if (this.startBackup !== startBackup) {
          this.startBackup = startBackup;
          onBackup({
            type: startBackup ? 'start' : 'end',
            channel,
            event,
          });
        }
        break;
      }
      case 0x0604: //  error of create file
      case 0x0605: //  error of update file
      case 0x0606: //  error of file info
      case 0x0602: { // backup error
        this.startBackup = false;
        onBackup({
          type: 'error',
          channel,
          event,
        });
        break;
      }
      // case 0x0603: //  backup timestamp
      // case 0x0607: //  file split message
      // case 0x0608: //  reached maximum file size
      case 0x0609: { // backup socket closed
        this.startBackup = false;
        onBackup({
          type: this.currentPercent === '0' ? 'error' : 'end',
          channel,
          event,
        });
        break;
      }
      default:
        break;
    }
  };

  onTimestamp = event => {
    const { startTime, endTime } = this.props;
    const end = new Date(endTime);
    const start = new Date(startTime);
    const current = new Date(event.detail.timestamp);
    const percent = ((current - start) / (end - start) * 100).toFixed(2);

    if (this.currentPercent !== percent) {
      const { onBackup, channel } = this.props;
      onBackup({
        type: 'progress',
        channel,
        percent,
      });
      this.currentPercent = percent;
    }
    if (percent >= 100) {
      const { umpPlayer } = this.state;
      umpPlayer.backup(false);
    }
  }

  onError = event => {
    const {
      onBackup,
      channel,
      onError,
    } = this.props;

    // console.log('tileBackup Container Error', event);

    onError(event);

    onBackup({
      type: 'error',
      channel,
      event,
    });
  }

  // onStatechange = event => {
  //   console.log('event.detail', event.detail);
  // };

  render() {
    const {
      render,
    } = this.props;

    return render(
      {
        ...this.props,
        ...this.state,
        ...this,
      },
    );
  }
}

TileBackupContainer.defaultProps = {
  umpPlayer: null,
  onBackup: () => {},
  onBackupstatechange: () => {},
  fileName: '',
  onError: () => {},
  sessionKey: '',
};

TileBackupContainer.propTypes = {
  render: PropTypes.func.isRequired,
  umpPlayer: PropTypes.oneOfType([PropTypes.any]),
  backup: PropTypes.bool.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  onBackup: PropTypes.func,
  onBackupstatechange: PropTypes.func,
  fileName: PropTypes.string,
  channel: PropTypes.number.isRequired,
  onError: PropTypes.func,
  sessionKey: PropTypes.string,
};

export default TileBackupContainer;
