import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SearchMediaControlActions } from 'store/actionCreators';
import { Map } from 'immutable';

class SearchCurrentTimeContainer extends React.Component {
  state = {
    currentTime: '0',
    isWork: true,
    currentTimeRange: {
      startTime: null,
      endTime: null,
    },
    dstMode: false,
    dstTimeRange: {
      startTime: null,
      endTime: null,
    },
  }

  componentDidUpdate(prevProps) {
    const {
      timeLineSearchResults: prevTimeline,
      searchDateObj: prevSearchDateObj,
      currentChannel: prevCurrentChannel,
    } = prevProps;
    const {
      timeLineSearchResults: timeLine,
      searchDateObj,
      currentTabName,
      currentChannel,
    } = this.props;

    if (searchDateObj !== null
      && JSON.stringify(prevTimeline) !== JSON.stringify(timeLine)
    ) {
      const { timeZone } = this.props;
      this.index = undefined;
      const { year, month, day } = searchDateObj;
      if (currentTabName === 'eventTab') {
        // SearchTimelineActions.setSelectEvent({});
      }
      const initCurrentTime = new Date(year, month, day, 0, 0, 0);
      const changeCurrentTime = initCurrentTime.setHours(initCurrentTime.getHours()
      - initCurrentTime.getTimezoneOffset() / 60 + Number(timeZone));
      const convertCurrentTime = new Date(changeCurrentTime).toISOString();
      this.onUpdate({
        isWork: true,
        currentTime: convertCurrentTime,
      });
    } else if (searchDateObj !== null
      && JSON.stringify(prevSearchDateObj) !== JSON.stringify(searchDateObj)
    ) {
      const { year, month, day } = searchDateObj;
      this.onUpdate({
        isWork: false,
        currentTime: new Date(year, month, day, 0, 0, 0).toISOString(),
      });
    }

    if (currentChannel !== prevCurrentChannel) {
      // 채널 변경 시 currentTime 초기화
      this.onUpdate({
        currentTime: '0',
      });
    }
  }

  setCurrentTime = currentTime => {
    // const { currentTabName } = this.props;
    const { currentTime: stateCurrentTime, isWork } = this.state;
    const currentTimeSec = Number(/:\d\d\./g.exec(currentTime)[0].replace(/[^0-9]/g, ''));
    const stateCurrentTimeSec = stateCurrentTime
      ? Number(/:\d\d\./g.exec(stateCurrentTime)[0].replace(/[^0-9]/g, ''))
      : 0;
    d.log('[setCurrentTime]:', currentTime);
    if (isWork && (currentTimeSec !== stateCurrentTimeSec)) {
      const checkCurrentTime = this.dstTimeRangeCheck(currentTime);
      // this.nextEventCheck(checkCurrentTime, currentTabName);
      this.setState({ currentTime: checkCurrentTime });
      this.timeRangeCheck(checkCurrentTime);
    }
  }

  findNextIndex = (currentTime, currentTabName) => {
    const { selectEvent: propsSelectEvent, filterEvent: propsFilterEvent } = this.props;
    const selectEvent = propsSelectEvent.toJS();
    // tab을 변경 하였을 때 기존에 선택된 이벤트가 있을 경우 로직을 타게 하기 위해서 넣은 예외처리
    if ((currentTabName === 'eventTab' && selectEvent.Type === 'UserInput')
      || (currentTabName === 'textTab' && (selectEvent.Type && selectEvent.Type !== 'UserInput'))) {
      selectEvent.index = undefined;
    }
    const filterEvent = propsFilterEvent.toJS();
    const currentEventTime = new Date(currentTime);

    if (typeof selectEvent.index !== 'undefined') {
      if (this.index) {
        this.index = undefined;
      }
      return selectEvent.index + 1;
    }

    if (typeof this.index !== 'undefined') {
      return this.index + 1;
    }

    // 처음 진입하였을 때 이벤트가 없는 구간에서 영상을 시작 할 경우 처음 검색할 이벤트를 찾기 위한 로직
    this.index = filterEvent.findIndex(event => {
      const eventStartTime = new Date(event.LocalStartTime);
      const eventEndTime = new Date(event.LocalEndTime);
      const result = (eventEndTime >= currentEventTime && eventStartTime <= currentEventTime);
      return result;
    });
    this.index = this.index === -1 ? 0 : this.index;
    return this.index;
  }

  // nextEventCheck = (currentTime, currentTabName) => {
  //   const {
  //     filterEvent: propsFilterEvent,
  //     selectEvent: propsSelectEvent,
  //   } = this.props;

  //   const selectEvent = propsSelectEvent.toJS();
  //   const filterEvent = propsFilterEvent.toJS();
  //   const nextIndex = this.findNextIndex(currentTime, currentTabName);

  //   if (filterEvent.length !== 0 && filterEvent.length > nextIndex) {
  //     const eventData = filterEvent[nextIndex];
  //     if (this.isInsideCurrentTime(currentTime, eventData.StartTime, eventData.EndTime)) {
  //       const nextEvent = {
  //         ...selectEvent,
  //         ...eventData,
  //         index: nextIndex,
  //       };
  //       if (JSON.stringify(selectEvent) !== JSON.stringify(nextEvent)) {
  //         SearchTimelineActions.setSelectEvent({
  //           ...nextEvent,
  //           isReopen: false,
  //           isDst: nextEvent.StartDst,
  //         });
  //       }
  //     }
  //   }
  // }

  convertGMTTime = time => {
    const { timeZone } = this.props;
    const convertCurrentTime = new Date(
      time.getTime() + time.getTimezoneOffset() * 60000 + ((-timeZone) * 3600000),
    );

    return convertCurrentTime;
  }

  isInsideCurrentTime = (currentTime, startTime, endTime) => {
    const currentTimeDate = this.convertGMTTime(new Date(currentTime));
    const startTimeDate = new Date(startTime);
    const endTimeDate = new Date(endTime);
    return (endTimeDate >= currentTimeDate && startTimeDate <= currentTimeDate);
  }

  setCurrentTimeWork = workState => {
    this.onUpdate(workState);
  }

  setCurrentTimeRange = (startTime, endTime) => {
    this.onUpdate({
      currentTimeRange: {
        startTime,
        endTime,
      },
    });
  }

  timeRangeCheck = currentTime => {
    const { currentTimeRange: { startTime, endTime } } = this.state;
    if (startTime) {
      if (!this.isInsideCurrentTime(currentTime, startTime, endTime)) {
        SearchMediaControlActions.playControl({
          playMode: 'pause',
        });
      }
    }
  }

  dstTimeRangeCheck = currentTime => {
    const { dstEnableInfo } = this.props;
    const { dstMode } = this.state;
    if (dstMode && dstEnableInfo) {
      const { dstTimeRange: { startTime, endTime } } = this.state;
      const convertCurrentTime = new Date(currentTime);
      const changeCurrentTime = convertCurrentTime.setHours(convertCurrentTime.getHours() + 1);
      const dstCurrentTime = new Date(changeCurrentTime).toISOString();

      if (!this.isInsideCurrentTime(dstCurrentTime, startTime, endTime)) {
        this.onUpdate({
          dstMode: false,
          dstTimeRange: {
            startTime: null,
            endTime: null,
          },
        });
        return currentTime;
      }
      return dstCurrentTime;
    }
    return currentTime;
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  );

  render() {
    const {
      render,
    } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

SearchCurrentTimeContainer.defaultProps = {
  searchDateObj: null,
  dstEnableInfo: false,
};

SearchCurrentTimeContainer.propTypes = {
  render: PropTypes.func.isRequired,
  timeLineSearchResults: PropTypes.oneOfType([PropTypes.any]).isRequired,
  currentTabName: PropTypes.oneOf(['eventTab', 'textTab']).isRequired,
  selectEvent: PropTypes.instanceOf(Map).isRequired,
  searchDateObj: PropTypes.oneOfType([PropTypes.any]),
  filterEvent: PropTypes.oneOfType([PropTypes.any]).isRequired,
  timeZone: PropTypes.number.isRequired,
  currentChannel: PropTypes.number.isRequired,
  dstEnableInfo: PropTypes.bool,
};

export default connect(
  state => ({
    timeLineSearchResults: state.searchTimelineModule.get('timeLineSearchResults'),
    selectEvent: state.searchTimelineModule.get('selectEvent'),
    searchDateObj: state.eventSearchModule.get('searchDateObj'),
    filterEvent: state.searchTimelineModule.get('filterEvent'),
    currentChannel: state.searchTimelineModule.get('currentChannel'),
    timeZone: state.deviceInfoModule.get('timeZone'),
    dstEnableInfo: state.deviceInfoModule.get('dateInfo').toJS().DSTEnable,
  }),
)(SearchCurrentTimeContainer);
