import React from 'react';
import PropTypes from 'prop-types';
import { NetworkStatusContainer } from 'containers/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { Table } from 'wisenet-ui/components/organisms';
import { getLanguage } from 'util/lib';
import {
  TableWrapperStyled,
} from './NetworkStatusStyled';

const rowHeight = 41;
const defaultTableHeight = 138;
const maxTableHeight = 442;

const NetworkStatus = ({
  networkStatusData,
}) => {
  let tableHeight = defaultTableHeight;
  if (networkStatusData.length !== 0) {
    tableHeight = (networkStatusData.length * rowHeight + rowHeight);
    if (tableHeight > maxTableHeight) {
      tableHeight = maxTableHeight;
    }
  }
  const networkStatusHeader = [
    {
      Header: '',
      accessor: 'title',
      sortable: false,
      width: 150,
    },
    {
      Header: `${getLanguage('lang_receive')} (bps)`,
      accessor: 'inBound',
      sortable: true,
    },
    {
      Header: `${getLanguage('lang_transfer')} (bps)`,
      accessor: 'outBound',
      sortable: true,
    },
  ];
  const translationData = networkStatusData.map(item => ({
    ...item,
    title: getLanguage(item.title),
  }));
  return (
    <TableWrapperStyled
      height={tableHeight}
    >
      <Table
        header={networkStatusHeader}
        data={translationData}
        scroll
      />
    </TableWrapperStyled>
  );
};

// NetworkStatus.defaultProps = { };

NetworkStatus.propTypes = {
  networkStatusData: PropTypes.instanceOf(Array).isRequired,
};

export default withContainer(NetworkStatusContainer, NetworkStatus);
