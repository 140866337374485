import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { TileDigitalZoomControlContainer } from 'containers/organisms';

import {
  TileDigitalZoomControlStyled,
  MiniMapContainerStyled,
  MimiMapCanvasStyled,
  MiniMapBoxStyled,
  MiniMapBlackOpacityStyled,
} from './TileDigitalZoomControlStyled';

class TileDigitalZoomControl extends React.PureComponent {
  render() {
    const {
      onTileDZoomControlEvent,
      // onMiniMapControlEvent,
      setRef,
      mouseIn,
    } = this.props;

    return (
      <TileDigitalZoomControlStyled
        onMouseDown={onTileDZoomControlEvent('down')}
        onMouseMove={onTileDZoomControlEvent('move')}
        onMouseUp={onTileDZoomControlEvent('up')}
        // onWheel={onTileDZoomControlEvent('wheel')}
        onMouseEnter={onTileDZoomControlEvent('enter')}
        onMouseLeave={onTileDZoomControlEvent('leave')}
      >
        { mouseIn && (
          <MiniMapContainerStyled
            ref={ref => { setRef('mapContainer', ref); }}
            // onMouseEnter={onMiniMapControlEvent('enter')}
            // onMouseLeave={onMiniMapControlEvent('leave')}
          >
            <MimiMapCanvasStyled
              ref={ref => { setRef('mapCanvas', ref); }}
            />
            <MiniMapBlackOpacityStyled />
            <MiniMapBoxStyled
              ref={ref => { setRef('mapBox', ref); }}
              // onMouseDown={onMiniMapControlEvent('down')}
              // onMouseMove={onMiniMapControlEvent('move')}
              // onMouseUp={onMiniMapControlEvent('up')}
            />
          </MiniMapContainerStyled>
        )}
      </TileDigitalZoomControlStyled>
    );
  }
}

TileDigitalZoomControl.propTypes = {
  onTileDZoomControlEvent: PropTypes.func.isRequired,
  // onMiniMapControlEvent: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  mouseIn: PropTypes.bool.isRequired,
};

export default withContainer(TileDigitalZoomControlContainer, TileDigitalZoomControl);
