import styled, { withTheme } from 'styled-components';
import {
  Button,
  Span,
} from 'wisenet-ui/components/atoms';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.text.color1};
  background-color: ${props => props.theme.background.color1};
`;

export const Header = withTheme(styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid ${props => props.theme.text.color1};
  background-color: ${props => props.theme.line.color4};
`);

export const Body = withTheme(styled.div`
  width: 100%;
  height: 131px;
  display: flex;
  justify-content: center;
  align-items: center;
`);

export const Footer = withTheme(styled.div`
  height: 40px;
  width: 100%;
  display: block;
  display: flex;
  flex-direction: row;
  justify-content: center;
`);

export const TitleSpan = withTheme(styled(Span)`
  width: 165px;
  height: 19px;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${props => props.theme.background.color1};
  margin-left: 16px;
  margin-top: 4px;
  margin-bottom: 8px;
`);

export const ContentSpan = withTheme(styled(Span)`
  width: auto;
  height: auto;
  position: absolute;
  margin: auto;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  padding: 0 20px;
  color: ${props => props.theme.text.color2};
`);

export const ConfirmButton = styled(Button)`
  width: 100px;
  height: 25px;
  object-fit: contain;
  padding-top: 2px;
`;
