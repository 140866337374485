import styled, { withTheme, css } from 'styled-components';
import { IconButton, Span } from 'wisenet-ui/components/atoms';
import ReactTooltip from 'react-tooltip';

export const TileControlBarStyled = styled.div`
  display: flex;
`;

export const IconButtonStyled = withTheme(styled(IconButton)`
  padding: 0;
  width: 48px;
  height:48px;
  font-size: 24px;
  color: #ffffff;
  text-shadow: -1px -1px 2px #000, 1px -1px 2px #000, -1px 1px 2px #000, 1px 1px 2px #000;
  ${props => props.isActive && css`
    color: #f37321;
  `}
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.4);
  }
`);

export const CustomReactTooltip = withTheme(styled(ReactTooltip)`
  background-color: #555 !important;
  padding: 5px !important;
  &.place-top {
    &:after {
      border-top: none !important;
    }
  }
  &.place-left {
    &:after {
      border-left: 6px solid #555 !important;
    }
  }
  &.place-right {
    &:after {
      border-right: 6px solid #555 !important;
    }
  }
`);

export const TooltipSpan = withTheme(styled(Span)`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  margin: 0px;
  padding: 0px
`);
