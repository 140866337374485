export const MediaControlIDList = {
  CAPTURE: 'capture',
  PC_RECORD: 'pcRecord',
  INSTANT_PLAYBACK: 'instantPlayback',
  PTZ_CONTROL: 'ptzControl',
  D_ZOOM: 'dZoom',
  SOUND: 'sound',
  ROTATE: 'rotate',
  // FIT: 'fit',
  ASPECT_RATIO: 'aspectRatio',
  FULL_ASPECT_RATIO: 'lang_full_aspect_ratio',
  EXPORT: 'lang_export',
  OSD: 'lang_osd_show_hide',
  CH_INFO: 'lang_channel_information',
  STATUS: 'lang_all_camera_status',
  LAYOUT_PAGE_LEFT: 'layoutPageLeft',
  LAYOUT_PAGE_INPUT: 'layoutPageInput',
  LAYOUT_PAGE_RIGHT: 'layoutPageRight',
  PATTERN: 'lang_pattern',
  FULLSCREEN: 'lang_full_screen',
  AREA: 'area',
  PREV_EVENT: 'prevEvent',
  BACKWARD_PLAY: 'backwardPlay',
  BACKWARD: 'backward',
  PAUSE: 'pause',
  PLAY: 'play',
  FORWARD: 'forward',
  FORWARD_PLAY: 'forwardPlay',
  AFTER_EVENT: 'afterEvent',
  PLAY_SPEED: 'playSpeed',
  PLAY_SPEED_LIST: ['x0.125', 'x0.25', 'x0.50', 'x1', 'x2', 'x4', 'x8', 'x16', 'x32', 'x64', 'x128', 'x256'],
  PLAY_SPEED_FRACTION_LIST: ['x1/8', 'x1/4', 'x1/2', 'x1', 'x2', 'x4', 'x8', 'x16', 'x32', 'x64', 'x128', 'x256'],
  PLAY_SPEED_M_FRACTION_LIST: ['-x1/8', '-x1/4', '-x1/2', '-x1', '-x2', '-x4', '-x8', '-x16', '-x32', '-x64', '-x128', '-x256'],
  RECORD: 'lang_manual_recording',
  ALARM: 'lang_stop_alarm_output',
  PIXEL: 'pixel',
  MIC: 'mic',
  SETUP: 'setup',
  TIMELINE_FOLD: 'tilelineFold',
  TIMELINE_TIME_RANGE: 'lang_time_range',
  FORWARD_STEP: 'forwardStep',
  BACKWARD_STEP: 'backwardStep',
  UP: 'up',
  DOWN: 'down',
};

export const MediaControlIconButtonList = {
  [MediaControlIDList.DOWN]: {
    clickId: MediaControlIDList.DOWN,
    iconId: 'wni wni-arrow-down',
  },
  [MediaControlIDList.UP]: {
    clickId: MediaControlIDList.UP,
    iconId: 'wni wni-arrow-up',
  },
  [MediaControlIDList.CAPTURE]: {
    clickId: MediaControlIDList.CAPTURE,
    iconId: 'wni wni-capture',
    lang: 'lang_capture',
  },
  [MediaControlIDList.PC_RECORD]: {
    clickId: MediaControlIDList.PC_RECORD,
    iconId: 'wni wni-pc-recording',
    lang: 'lang_pc_rec',
  },
  [MediaControlIDList.INSTANT_PLAYBACK]: {
    clickId: MediaControlIDList.INSTANT_PLAYBACK,
    iconId: 'wni wni-instant-playback',
    lang: 'lang_instant_viewer',
  },
  [MediaControlIDList.PTZ_CONTROL]: {
    clickId: MediaControlIDList.PTZ_CONTROL,
    iconId: 'wni wni-ptz-control',
    lang: 'lang_ptz',
  },
  [MediaControlIDList.D_ZOOM]: {
    clickId: MediaControlIDList.D_ZOOM,
    iconId: 'wni wni-areazoom', // 임시
    lang: 'lang_digital_zoom',
  },
  [MediaControlIDList.SOUND]: {
    clickId: MediaControlIDList.SOUND,
    iconId: 'wni wni-sound',
    lang: 'lang_audio',
  },
  [MediaControlIDList.ROTATE]: {
    clickId: MediaControlIDList.ROTATE,
    iconId: 'wni wni-rotate',
    lang: 'lang_videosrc',
  },
  // [MediaControlIDList.FIT]: {
  //   clickId: MediaControlIDList.FIT,
  //   iconId: 'tui-ch-live-view-fit',
  // },
  [MediaControlIDList.ASPECT_RATIO]: {
    clickId: MediaControlIDList.ASPECT_RATIO,
    iconId: 'wni wni-aspact-ratio',
    lang: 'lang_aspect_ratio',
  },
  [MediaControlIDList.FULL_ASPECT_RATIO]: {
    clickId: MediaControlIDList.ASPECT_RATIO,
    iconId: 'wni wni-aspact-ratio',
  },
  [MediaControlIDList.EXPORT]: {
    clickId: MediaControlIDList.EXPORT,
    iconId: 'wni wni-export',
  },
  [MediaControlIDList.OSD]: {
    clickId: MediaControlIDList.OSD,
    iconId: 'wni wni-osd-showhide',
  },
  [MediaControlIDList.CH_INFO]: {
    clickId: MediaControlIDList.CH_INFO,
    iconId: 'wni wni-camerainfo-showhide',
  },
  [MediaControlIDList.STATUS]: {
    clickId: MediaControlIDList.STATUS,
    iconId: 'wni wni-status',
  },
  [MediaControlIDList.LAYOUT_PAGE_LEFT]: {
    clickId: MediaControlIDList.LAYOUT_PAGE_LEFT,
    iconId: 'wni wni-arrow-left', // 임시
  },
  [MediaControlIDList.LAYOUT_PAGE_RIGHT]: {
    clickId: MediaControlIDList.LAYOUT_PAGE_RIGHT,
    iconId: 'wni wni-arrow-right',
  },
  [MediaControlIDList.PATTERN]: {
    clickId: MediaControlIDList.PATTERN,
    iconId: 'wni wni-pattern-1',
  },
  [MediaControlIDList.FULLSCREEN]: {
    clickId: MediaControlIDList.FULLSCREEN,
    iconId: 'wni wni-full-screen',
  },
  [MediaControlIDList.AREA]: {
    clickId: MediaControlIDList.AREA,
    iconId: 'wni wni-close', // 임시
  },
  [MediaControlIDList.PREV_EVENT]: {
    clickId: MediaControlIDList.PREV_EVENT,
    iconId: 'wni wni-back-jump',
    lang: 'lang_previous_event',
  },
  [MediaControlIDList.BACKWARD_PLAY]: {
    clickId: MediaControlIDList.BACKWARD_PLAY,
    iconId: 'wni wni-back-slow',
    lang: 'lang_backward_playback',
  },
  [MediaControlIDList.BACKWARD]: {
    clickId: MediaControlIDList.BACKWARD,
    iconId: 'wni wni-backward-slow',
    lang: 'lang_step_backward',
  },
  [MediaControlIDList.PAUSE]: {
    clickId: MediaControlIDList.PAUSE,
    iconId: 'wni wni-pause',
    lang: 'lang_pause',
  },
  [MediaControlIDList.PLAY]: {
    clickId: MediaControlIDList.PLAY,
    iconId: 'wni wni-play',
    lang: 'lang_play',
  },
  [MediaControlIDList.MIC]: {
    clickId: MediaControlIDList.MIC,
    iconId: 'wni wni-mic',
    lang: 'lang_microphone',
  },
  [MediaControlIDList.FORWARD_PLAY]: {
    clickId: MediaControlIDList.FORWARD_PLAY,
    iconId: 'wni wni-front-slow',
    lang: 'lang_forward_playback',
  },
  [MediaControlIDList.FORWARD]: {
    clickId: MediaControlIDList.FORWARD,
    iconId: 'wni wni-forward-slow',
    lang: 'lang_step_forward',
  },
  [MediaControlIDList.AFTER_EVENT]: {
    clickId: MediaControlIDList.AFTER_EVENT,
    iconId: 'wni wni-front-jump',
    lang: 'lang_next_event',
  },
  [MediaControlIDList.RECORD]: {
    clickId: MediaControlIDList.RECORD,
    iconId: 'wni wni-recording',
  },
  [MediaControlIDList.ALARM]: {
    clickId: MediaControlIDList.ALARM,
    iconId: 'wni wni-alarm-off',
  },
  [MediaControlIDList.SETUP]: {
    clickId: MediaControlIDList.SETUP,
    iconId: 'wni wni-setup',
  },
  [MediaControlIDList.TIMELINE_FOLD]: {
    clickId: MediaControlIDList.TIMELINE_FOLD,
    iconId: 'wni wni-arrow',
  },
  [MediaControlIDList.TIMELINE_TIME_RANGE]: {
    clickId: MediaControlIDList.TIMELINE_TIME_RANGE,
    iconId: 'wni wni-timelinepicker',
  },
};
