import styled, { withTheme } from 'styled-components';

export const Container = withTheme(styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: center;
  color: ${props => props.theme.text.color2};
  background-color: ${props => props.theme.background.color1};
`);

export const Stepper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3px;
  margin-right: 3px;
  align-items: center;
  /* justify-content: space-between; */
  height: 55px;
`;

export const StepperInput = withTheme(styled.input`
  /* margin-top: 5px;
  margin-bottom: 5px; */
  width: 18px;
  height: 28px;
  text-align: center;
  border: 1px solid ${props => props.theme.line.color4};
  background: transparent;
  color: ${props => props.theme.text.color2};
`);

export const StepperButton = withTheme(styled.i`
  font-size: 1em;
  cursor: default;
  color: ${props => props.theme.text.color2};
  visibility: hidden;
`);

export const Divider = styled.span`
  margin-bottom: 8px;
`;
