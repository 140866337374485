import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { MediaControlIDList } from 'wisenet-ui/util/static/constants/mediaControl/mediaControlType';
import { LiveVideoTileControlBarStyled, RiseLiveVideoTileControlBarStyled } from './LiveVideoTileControlBarStyled';

class LiveVideoTileControlBar extends PureComponent {
  render() {
    const {
      checkAudioIn,
      checkAudioOut,
      ptzInfo,
      width,
      rise,
    } = this.props;
    const {
      CAPTURE,
      PC_RECORD,
      INSTANT_PLAYBACK,
      MIC,
      PTZ_CONTROL,
      SOUND,
      ROTATE,
      ASPECT_RATIO,
    } = MediaControlIDList;

    const tileControlIcon = {
      CAPTURE,
      PC_RECORD,
      INSTANT_PLAYBACK,
      MIC: checkAudioOut ? MIC : undefined,
      PTZ_CONTROL: ptzInfo.pan || ptzInfo.tilt || ptzInfo.zoom ? PTZ_CONTROL : undefined,
      SOUND: checkAudioIn ? SOUND : undefined,
      ROTATE,
      ASPECT_RATIO,
    };

    let buttonItems = [];
    let riseButtonItem = [];
    const riseButtonWidth = 400;

    Object.keys(tileControlIcon).map(key => {
      if (tileControlIcon[key]) {
        buttonItems.push(tileControlIcon[key]);
      }
      return null;
    });

    riseButtonItem = buttonItems;

    if (width < riseButtonWidth) {
      buttonItems = buttonItems.slice(0, 4);
      if (rise) {
        buttonItems.push('down');
      } else {
        buttonItems.push('up');
      }

      riseButtonItem = riseButtonItem.slice(4);
    }

    const exceptionList = [
      ROTATE,
    ];

    console.log('!! ptzInfo', this.props);

    const riseButtonItems = [
      (
        <RiseLiveVideoTileControlBarStyled
          key="RiseLiveVideoTileControl"
          buttonItems={riseButtonItem}
          exceptionList={exceptionList}
          {...this.props}
        />
      ),
    ];

    return (
      <React.Fragment>
        <LiveVideoTileControlBarStyled
          buttonItems={buttonItems}
          exceptionList={exceptionList}
          {...this.props}
        />
        { (rise && width < riseButtonWidth) ? (
          riseButtonItems
        ) : undefined
        }
      </React.Fragment>
    );
  }
}

LiveVideoTileControlBar.defaultProps = {
  checkAudioIn: false,
  checkAudioOut: false,
  ptzInfo: {},
  width: '',
  rise: false,
};

LiveVideoTileControlBar.propTypes = {
  checkAudioIn: PropTypes.bool,
  checkAudioOut: PropTypes.bool,
  ptzInfo: PropTypes.instanceOf(Object),
  width: PropTypes.number,
  rise: PropTypes.bool,
};

export default LiveVideoTileControlBar;
