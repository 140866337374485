import styled from 'styled-components';
import { ReactModalAdapter, Button } from 'wisenet-ui/components/atoms';

export const StatusPopupStyled = styled(ReactModalAdapter).attrs({
  className: 'Popup',
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  
  .ReactModal__Overlay {
    width: 100%;
    height: 100%;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    background-color: rgba(0,0,0,.5) !important;
  }

  .ReactModal__Content {
    top: 50% !important;
    left: 50% !important;
    width: 1030px;
    height: 692px;
    transform: translate(-50%, -50%) !important;
    background-color: ${props => props.theme.background.color1}!important;
    border: 1px solid ${props => props.theme.line.color5} !important;
    border-radius: 0px !important;
  }

  .ReactModal__Content--after-open {
    padding: 0px !important;
    overflow: hidden !important;
  }
`;

export const PopupButtonStyled = styled(Button)`
  width: 100px;
  height: 28px;
  font-size: 14px;
  padding: 0;
`;

export const ExportPopupStyled = styled(ReactModalAdapter).attrs({
  className: 'Popup',
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  
  .ReactModal__Overlay {
    width: 100%;
    height: 100%;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    background-color: rgba(0,0,0,.5) !important;
  }

  .ReactModal__Content {
    top: 50% !important;
    left: 50% !important;
    height: 550px;
    width: 650px;
    transform: translate(-50%, -50%) !important;
    background-color: #fff !important;
    border: 1px solid #797979 !important;
    border-radius: 0px !important;
  }

  .ReactModal__Content--after-open {
    padding: 0px !important;
    overflow: hidden !important;
  }
`;
