import { createAction, handleActions } from 'redux-actions';
import { Map, List } from 'immutable';

export const SET_PAGE_PENDDING = 'loading/SET_PAGE_PENDDING';
export const SET_LAYOUT_TILE_PENDDING = 'loading/SET_LAYOUT_TILE_PENDDING';

export const setPagePendding = createAction(SET_PAGE_PENDDING);
export const setLayoutTilePendding = createAction(SET_LAYOUT_TILE_PENDDING);

const initialState = Map({
  pagePendding: false,
  layoutTilePenddingList: List([]),
});

export default handleActions({
  [SET_PAGE_PENDDING]: (state, { payload: { pagePendding } }) => (
    state.set('pagePendding', pagePendding)
  ),
  [SET_LAYOUT_TILE_PENDDING]: (state, { payload: { uid, tilePendding } }) => {
    const currentLayoutTilePenddingList = state.get('layoutTilePenddingList');
    if (tilePendding) {
      return state.set('layoutTilePenddingList', currentLayoutTilePenddingList.push(uid));
    }
    const findTileIndex = currentLayoutTilePenddingList.findIndex(tileUid => tileUid === uid);

    return state.set('layoutTilePenddingList', currentLayoutTilePenddingList.delete(findTileIndex));
  },
}, initialState);
