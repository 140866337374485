import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LoginActions, ThemeActions } from 'store/actionCreators';

class HeaderContainer extends React.Component {
  state = {
    themeValue: 0,
    authPopupVisible: false,
    authDropdownVisible: false,
    themeChangeDisabled: false,
  }

  componentDidMount() {
    const { theme } = this.props;
    this.setState({
      themeValue: theme === 'black' ? 0 : 1,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const { theme, location } = this.props;
    const { themeChangeDisabled } = this.state;
    if (prevProps.theme !== theme) {
      this.onUpdate({
        themeValue: theme === 'black' ? 0 : 1,
      });
    }
    const currentPath = location && location.pathname.split('/')[1];
    if (currentPath === 'setup' && !themeChangeDisabled) {
      this.onUpdate({
        themeChangeDisabled: true,
      });
    }
    if (currentPath !== 'setup' && themeChangeDisabled) {
      this.onUpdate({
        themeChangeDisabled: false,
      });
    }
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  onLogout = () => {
    LoginActions.logout();
  }

  handleChangeTheme = currentValue => {
    /*
      0 - black
      1 - white
    */
    const newValue = currentValue === 0 ? 1 : 0;
    const newThemeColor = newValue === 0 ? 'black' : 'white';
    ThemeActions.changeTheme({
      theme: newThemeColor,
      setStorage: true,
    });
    this.setState({
      themeValue: newValue,
    });
  }

  handleOpenOnlineHelp = () => {
    const features = 'width=850, height=650, top=0, left=0, scrollbars=yes, resizable=yes';
    window.open('https://www.hanwha-security.com/', '_blank', features);
  }

  onChangeAuthPopupVisible = () => {
    const { authPopupVisible } = this.state;
    this.setState({
      authPopupVisible: !authPopupVisible,
    });
  }

  onChangeAuthDropdownVisible = () => {
    const { authDropdownVisible } = this.state;
    this.setState({
      authDropdownVisible: !authDropdownVisible,
    });
  }

  render() {
    const { render, deviceInfo } = this.props;
    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
        modelName: deviceInfo ? deviceInfo.Model : '',
      },
    );
  }
}

HeaderContainer.defaultProps = {
  location: {},
};


HeaderContainer.propTypes = {
  render: PropTypes.func.isRequired,
  deviceInfo: PropTypes.instanceOf(Object).isRequired,
  theme: PropTypes.string.isRequired,
  location: PropTypes.instanceOf(Object),
};

export default withRouter(connect(
  state => ({
    theme: state.themeModule.get('theme'),
    deviceInfo: state.deviceInfoModule.get('deviceInfo').toJS(),
    isAdmin: state.loginModule.get('isAdmin'),
  }),
)(HeaderContainer));
