import styled, { withTheme } from 'styled-components';
import { Span } from 'wisenet-ui/components/atoms';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const DateInputWrapper = withTheme(styled(Span)`
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid ${props => props.theme.line.color4};
  text-align: center;
  height: 22px;
  width: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
`);

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: default;
`;

export const DateInputTitle = withTheme(styled(Span)`
  font-family: NotoSans;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.color2};
  margin-right: 4px;
  margin-top: 7px;
`);

export const DateTimeSpan = withTheme(styled(Span)`
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.color2};
`);
