import {
  take,
  all,
  put,
  // call,
  // actionChannel,
  select,
} from 'redux-saga/effects';
import { SunapiClient, getLanguage } from 'util/lib';
import * as eventSearchActions from 'store/modules/eventSearch/eventSearchModule';
import * as messageBoxActions from 'store/modules/base/messageBoxModule';

const CALENDAR_SEARCH_URL = '/stw-cgi/recording.cgi?msubmenu=calendarsearch&action=view';

function* asyncGetCalendarSearch() {
  while (true) {
    const action = yield take(eventSearchActions.FIND_RECORDING_DATE);

    try {
      const yearAndMonth = action.payload.date.split('-');
      const year = Number(yearAndMonth[0]);
      const month = Number(yearAndMonth[1]);
      const resultData = [];

      const prevParam = {
        // 1월인 경우 전년도 12월로 조정
        Month: month === 1 ? `${year - 1}-${12}` : `${year}-${month - 1}`,
        ChannelIDList: action.payload.index,
      };
      const prevMonthResult = yield SunapiClient.get(CALENDAR_SEARCH_URL, prevParam);
      prevMonthResult.data.CalenderSearchResults[0].Result.split('').forEach((item, index) => {
        if (item === '1') {
          resultData.push(new Date(year, (month - 1) - 1, index + 1));
        }
      });

      const param = {
        Month: `${year}-${month}`,
        ChannelIDList: action.payload.index,
      };
      const curMonthResult = yield SunapiClient.get(CALENDAR_SEARCH_URL, param);
      curMonthResult.data.CalenderSearchResults[0].Result.split('').forEach((item, index) => {
        if (item === '1') {
          resultData.push(new Date(year, (month) - 1, index + 1));
        }
      });

      const nextParam = {
        // 12월인 경우 다음년도 1월로 조정
        Month: month === 12 ? `${year + 1}-${1}` : `${year}-${month + 1}`,
        ChannelIDList: action.payload.index,
      };
      const nextMonthResult = yield SunapiClient.get(CALENDAR_SEARCH_URL, nextParam);
      nextMonthResult.data.CalenderSearchResults[0].Result.split('').forEach((item, index) => {
        if (item === '1') {
          resultData.push(new Date(year, (month + 1) - 1, index + 1));
        }
      });

      const successData = {
        ...curMonthResult.data,
        requestData: param,
        threeMonthResultData: resultData,
      };

      yield put(eventSearchActions.findRecordingDateSuccess(successData));
    } catch (error) {
      console.log('ERROR', 'asyncGetCalendarSearch()');
      yield put(eventSearchActions.findRecordingDateFailure());
    }
  }
}

function* setSearchDateObj() {
  while (true) {
    const action = yield take(eventSearchActions.SET_SEARCH_DATE);
    try {
      const deviceInfo = yield select(state => state.deviceInfoModule);
      if (action.payload === null) {
        yield put(eventSearchActions.setSearchDateSuccess({
          searchDateObj: null,
        }));
      } else {
        const searchDateObj = {
          year: action.payload.getFullYear(),
          month: action.payload.getMonth() + 1,
          day: action.payload.getDate(),
          hour: 0,
          minute: 0,
          second: 0,
        };

        const { dstInfo } = deviceInfo.toJS();
        const dstStart = new Date(
          searchDateObj.year,
          dstInfo.dstStartInfo.month - 1,
          1,
          dstInfo.dstStartInfo.hours,
          dstInfo.dstStartInfo.minutes,
          dstInfo.dstStartInfo.seconds,
        );
        const dateFromCalandar = (dayOfWeek, firstOfWeek, weekOfMonth) => (
          dayOfWeek < firstOfWeek
            ? (weekOfMonth) * 7 - firstOfWeek + (dayOfWeek + 1)
            : (weekOfMonth - 1) * 7 - firstOfWeek + (dayOfWeek + 1)
        );
        let firstDayOfMonth = new Date(
          dstStart.getFullYear(),
          dstStart.getMonth(),
          1,
        ).getDay(); // 해당 월의 1일이 무슨 요일

        dstStart.setDate(
          dateFromCalandar(dstInfo.dstStartInfo.dayOfWeek,
            firstDayOfMonth, dstInfo.dstStartInfo.weekOfMonth),
        );
        if (dstStart.getMonth() !== dstInfo.dstStartInfo.month - 1) {
          dstStart.setDate(dstStart.getDate() - 7);
        }
        const dstEnd = new Date(
          searchDateObj.year,
          dstInfo.dstEndInfo.month - 1,
          1,
          dstInfo.dstEndInfo.hours,
          dstInfo.dstEndInfo.minutes,
          dstInfo.dstEndInfo.seconds,
        );
        firstDayOfMonth = new Date(dstEnd.getFullYear(), dstEnd.getMonth(), 1).getDay();

        dstEnd.setDate(
          dateFromCalandar(dstInfo.dstEndInfo.dayOfWeek,
            firstDayOfMonth, dstInfo.dstEndInfo.weekOfMonth),
        );
        if (dstEnd.getMonth() !== dstInfo.dstEndInfo.month - 1) {
          dstEnd.setDate(dstEnd.getDate() - 7);
        }
        // console.log('!!! DST', dstStart, dstEnd);
        yield put(eventSearchActions.setSearchDateSuccess({
          searchDateObj,
          dstEnable: true,
          dstStart,
          dstEnd,
        }));
      }
    } catch (error) {
      console.log('ERROR', 'setSearchDateObj()');
      yield put(messageBoxActions.controlMessageBox({
        messageBoxInfo: {
          title: getLanguage('lang_no_data'),
          content: getLanguage('lang_no_recorded'),
          isOpen: true,
        },
      }));
    }
  }
}

export default function* rootSearchSaga() {
  yield all([
    asyncGetCalendarSearch(),
    setSearchDateObj(),
  ]);
}
