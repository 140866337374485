import { createAction, handleActions } from 'redux-actions';
import { Map } from 'immutable';

export const GET_PHP_SETUP_PAGE = 'phpSetup/GET_PHP_SETUP_PAGE';
export const GET_PHP_SETUP_PAGE_SUCCESS = 'phpSetup/GET_PHP_SETUP_PAGE_SUCCESS';
export const GET_PHP_SETUP_PAGE_FAILURE = 'phpSetup/GET_PHP_SETUP_PAGE_FAILURE';

// ex) action { requestPageUrl: /#system/system_system_log }
export const getPhpSetupPage = createAction(GET_PHP_SETUP_PAGE);
export const getPhpSetupPageSuccess = createAction(GET_PHP_SETUP_PAGE_SUCCESS);
export const getPhpSetupPageFailure = createAction(GET_PHP_SETUP_PAGE_FAILURE);

const initialState = Map({
  phpSetupPage: null,
});

// reducer
export default handleActions({
  [GET_PHP_SETUP_PAGE_SUCCESS]: (state, { payload: { phpSetupPage } }) => (
    state.set('phpSetupPage', phpSetupPage)
  ),
  [GET_PHP_SETUP_PAGE_FAILURE]: state => (
    state.set('phpSetupPage', null)
  ),
}, initialState);
