import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List as ListImmutable, fromJS, Map } from 'immutable';
import styled from 'styled-components';
import { CameraInfoActions } from 'store/actionCreators';
import MediaStatusType from 'wisenet-ui/util/static/constants/mediaControl/mediaStatusType';

const I = styled.i`
  display: inline-block;
  margin-right:4px;
  text-align: center;
  font-size:20px;
`;

const PTZ_ICON_STYLE = {
  [MediaStatusType.DISCONNECTED]: 'wni wni-close',
  [MediaStatusType.VIDEOLOSS]: 'wni wni-camera-ptz-videoloss',
  [MediaStatusType.CONNECTFAIL]: 'wni wni-camera-ptz-videoloss',
  [MediaStatusType.COVERT1]: 'wni wni-camera-ptz-videoloss',
  [MediaStatusType.COVERT2]: 'wni wni-camera-ptz-videoloss',
  [MediaStatusType.AUTHFAIL]: 'wni wni-camera-ptz-authority',
  [MediaStatusType.ACCBLOCK]: 'wni wni-camera-ptz',
  [MediaStatusType.SUCCESS]: 'wni wni-camera-ptz',
};

const ANALOG_ICON_STYLE = {
  [MediaStatusType.DISCONNECTED]: 'wni wni-close',
  [MediaStatusType.VIDEOLOSS]: 'wni wni-camera-analog-videoloss',
  [MediaStatusType.CONNECTFAIL]: 'wni wni-camera-analog-videoloss',
  [MediaStatusType.COVERT1]: 'wni wni-camera-analog-videoloss',
  [MediaStatusType.COVERT2]: 'wni wni-camera-analog-videoloss',
  [MediaStatusType.AUTHFAIL]: 'wni wni-camera-analog-authority',
  [MediaStatusType.ACCBLOCK]: 'wni wni-camera-analog',
  [MediaStatusType.SUCCESS]: 'wni wni-camera-analog',
};

const CAMERA_ICON_STYLE = {
  [MediaStatusType.DISCONNECTED]: 'wni wni-close',
  [MediaStatusType.VIDEOLOSS]: 'wni wni-camera-box-videoloss',
  [MediaStatusType.CONNECTFAIL]: 'wni wni-camera-box-videoloss',
  [MediaStatusType.COVERT1]: 'wni wni-camera-box-videoloss',
  [MediaStatusType.COVERT2]: 'wni wni-camera-box-videoloss',
  [MediaStatusType.AUTHFAIL]: 'wni wni-camera-box-authority',
  [MediaStatusType.ACCBLOCK]: 'wni wni-camera-box',
  [MediaStatusType.SUCCESS]: 'wni wni-camera-box',
};

class LiveCameraListContainer extends React.Component {
  state = {
    data: ListImmutable([]),
  }

  componentDidMount() {
    const {
      cameraList: curPropsListDataTemp,
      onTileCameraList: curAssignedListTemp,
    } = this.props;
    const curPropsListData = curPropsListDataTemp.toJS();
    const curAssignedList = curAssignedListTemp.toJS();

    const cameraListData = this.convertCameraList(curPropsListData, curAssignedList, null);
    this.onUpdate(
      fromJS(cameraListData),
    );
  }

  componentDidUpdate(prevProps) {
    const {
      cameraList: curPropsListDataTemp,
      onTileCameraList: curAssignedListTemp,
      focusedCamera: curFocusedCameraTemp,
    } = this.props;
    const {
      cameraList: prevPropsListDataTemp,
      onTileCameraList: prevPropsAssignedListTemp,
      focusedCamera: prevFocusedCameraTemp,
    } = prevProps;
    const prevPropsListData = prevPropsListDataTemp.toJS();
    const curPropsListData = curPropsListDataTemp.toJS();
    const prevPropsAssignedList = prevPropsAssignedListTemp.toJS();
    const curAssignedList = curAssignedListTemp.toJS();
    const prevFocusedCamera = prevFocusedCameraTemp.toJS();
    const curFocusedCamera = curFocusedCameraTemp.toJS();

    if ((JSON.stringify(prevPropsListData) !== JSON.stringify(curPropsListData))
      || (JSON.stringify(prevPropsAssignedList) !== JSON.stringify(curAssignedList))) {
      const cameraListData = this.convertCameraList(curPropsListData, curAssignedList, null);
      this.onUpdate(
        fromJS(cameraListData),
      );
    }

    if (JSON.stringify(prevFocusedCamera) !== JSON.stringify(curFocusedCamera)) {
      const cameraListData = this.convertCameraList(
        curPropsListData,
        curAssignedList,
        curFocusedCamera,
      );
      this.onUpdate(
        fromJS(cameraListData),
      );
    }
  }

  onUpdate = data => (
    this.setState({
      data,
    })
  )

  convertCameraList = (cameraList, assignedList, focusedCamera) => (
    cameraList.reduce((arr, item) => {
      if (item.status === MediaStatusType.DISCONNECTED) {
        return arr;
      }
      const remakedItem = {
        id: '',
        text: '',
        data: {
          ipAddress: '',
          model: '',
          status: '',
        },
      };
      if (item.channel !== undefined) {
        if (Number.isNaN(item.channel)) {
          remakedItem.id = item.channel;
        } else {
          remakedItem.id = String(item.channel);
        }
      }
      if (item.channelName !== undefined) {
        remakedItem.text = item.channelName;
      }

      if (assignedList.length > 0) {
        for (let i = 0; i < assignedList.length; i += 1) {
          if (assignedList[i].channel === item.channel) {
            remakedItem.assigned = true;
          }
        }
      }

      if (item.ipAddress !== undefined) {
        remakedItem.data.ipAddress = item.ipAddress;
      }
      if (item.model !== undefined) {
        remakedItem.data.model = item.model;
      }
      if (item.status === MediaStatusType.VIDEOLOSS
        || item.status === MediaStatusType.CONNECTFAIL
      ) {
        remakedItem.data.status = true;
      }

      if (item.protocol === 'ANALOG') {
        remakedItem.iconLeft = this.toAnalogIcon(item.status);
      } else if (item.isOpticalPTZ) {
        remakedItem.iconLeft = this.toPtzIcon(item.status);
      } else {
        remakedItem.iconLeft = this.toCameraIcon(item.status);
      }

      if (focusedCamera) {
        if (Number(focusedCamera.channel) === Number(item.channel)) {
          remakedItem.focused = true;
        }
      } else if (item.focused !== undefined) {
        remakedItem.focused = item.focused;
      }
      arr.push(remakedItem);
      return arr;
    }, [])
  )

  toPtzIcon = type => <I className={PTZ_ICON_STYLE[type]} />;

  toAnalogIcon = type => <I className={ANALOG_ICON_STYLE[type]} />;

  toCameraIcon = type => <I className={CAMERA_ICON_STYLE[type]} />;

  onDBClickListItem = onTileCameraItems => {
    const { data } = this.state;
    const cameraList = data.toJS();
    const onTileList = [
      Number(cameraList[onTileCameraItems].id),
    ];
    CameraInfoActions.onTileCameraList({
      selectCameraList: onTileList,
    });
  }

  onDragListItem = onTileCameraItems => {
    const {
      setDragTileCamera,
    } = this.props;
    const { data } = this.state;
    const cameraList = data.toJS();
    const onTileList = [];
    for (let i = 0; i < onTileCameraItems.length; i += 1) {
      onTileList.push(Number(cameraList[onTileCameraItems[i]].id));
    }
    setDragTileCamera(ListImmutable(onTileList));
  }

  render() {
    const { render } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

LiveCameraListContainer.defaultProps = {
  focusedCamera: [],
  setDragTileCamera: () => {},
};

LiveCameraListContainer.propTypes = {
  render: PropTypes.func.isRequired,
  cameraList: PropTypes.instanceOf(ListImmutable).isRequired,
  onTileCameraList: PropTypes.instanceOf(ListImmutable).isRequired,
  focusedCamera: PropTypes.instanceOf(Map),
  setDragTileCamera: PropTypes.func,
};

export default connect(
  state => {
    const { currentNumber: layoutPageCurrentNumber } = state.liveMediaControlModule.get('layoutPageInfo').toJS();

    return ({
      lang: state.langModule.get('lang'),
      cameraList: state.cameraInfoModule.get('cameraList'),
      onTileCameraList: state.cameraInfoModule.get('tileCameraListPage').get(`${layoutPageCurrentNumber}`),
      focusedCamera: state.cameraInfoModule.get('selectTileCamera'),
    });
  },
)(LiveCameraListContainer);
