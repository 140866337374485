import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'wisenet-ui/components/atoms';
import WhiteThemeLogo from 'util/static/images/login_logo.svg';
import BlackThemeLogo from 'util/static/images/login_logo_black_theme.svg';
import UnderLine from 'util/static/images/login_underline.svg';
import { getLanguage } from 'util/lib';
import {
  ImageSectionStyled,
  LogoWrapperstyled,
  UnderLineWrapperstyled,
  FormSectionStyled,
  InputSectionStyled,
  ButtonSectionStyled,
  InputStyled,
  ButtonStyled,
  CheckboxSectionStyled,
  CheckboxLabelStyled,
  ErrorSectionStyled,
  UnderLineImage,
} from './LoginFormStyled';

class LoginForm extends Component {
  state = {
    userid: '',
    password: '',
    showPassword: false,
  }

  handleChange = e => (
    this.setState({
      [e.target.id]: e.target.value,
    })
  )

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { userid, password } = this.state;

    onSubmit({
      userid,
      password,
    });
  }

  handleKeyUp = e => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  }

  onChangeShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword,
    });
  }

  translationErrorMsg = languageKey => {
    let result = getLanguage(languageKey);
    if (languageKey === 'lang_password_error_least_character') {
      result = result.replace('%1', 8);
    }
    return result;
  }

  render() {
    const { showPassword } = this.state;
    const { error, theme } = this.props;
    return (
      <div style={{ userSelect: 'none' }}>
        <ImageSectionStyled>
          <LogoWrapperstyled>
            <img src={theme === 'white' ? WhiteThemeLogo : BlackThemeLogo} alt="wisenet" />
          </LogoWrapperstyled>
          <UnderLineWrapperstyled>
            <UnderLineImage src={UnderLine} alt="underline" />
          </UnderLineWrapperstyled>
        </ImageSectionStyled>
        <FormSectionStyled>
          <InputSectionStyled>
            <InputStyled
              id="userid"
              placeholder={getLanguage('lang_id')}
              onChange={this.handleChange}
              onKeyUp={this.handleKeyUp}
            />
            <InputStyled
              type={showPassword ? 'text' : 'password'}
              id="password"
              placeholder={getLanguage('lang_password')}
              onChange={this.handleChange}
              onKeyUp={this.handleKeyUp}
            />
          </InputSectionStyled>
          <ButtonSectionStyled>
            <ButtonStyled
              onClick={this.handleSubmit}
            >
              {getLanguage('lang_login')}
            </ButtonStyled>
          </ButtonSectionStyled>
        </FormSectionStyled>
        <CheckboxSectionStyled>
          <Checkbox
            checked={showPassword}
            onClick={this.onChangeShowPassword}
          />
          <CheckboxLabelStyled>{getLanguage('lang_view_password')}</CheckboxLabelStyled>
        </CheckboxSectionStyled>
        {
          error && (
            <ErrorSectionStyled>
              {this.translationErrorMsg(error)}
            </ErrorSectionStyled>
          )
        }
      </div>
    );
  }
}

LoginForm.defaultProps = {
  error: '',
  theme: 'white',
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  theme: PropTypes.string,
};

export default LoginForm;
