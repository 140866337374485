import styled from 'styled-components';

export const EventFilterWrapper = styled.div`
  width: 292px;
  height: 410px;
`;

export const EventFilterTitle = styled.p`
  font-size: 14px;
  color: var(--greyish-brown);
`;

export const EventTreeWrapper = styled.div`
  height: 330px;
`;

export const Border = styled.hr`
  border: 0.5px solid #797979;
  text-align: center;
`;
