import styled, { withTheme, css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import {
  IconButton,
  Button,
  ReactModalAdapter,
  Span,
} from 'wisenet-ui/components/atoms';
import { ResizableAccordion } from 'wisenet-ui/components/organisms';

export const EventResultListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const EventResultListStyled = styled.div`

`;

export const EventResultTopAreaStyled = styled.div`
  display: flex;
  height: 28px;
  flex-basis: 28px;
  line-height: 28px;
  margin-top: 18px;
  margin-bottom: 16px;
  justify-content: flex-end;
`;

export const EventResultMiddleAreaStyled = styled.div`
  overflow-y: auto;
  flex: 1;
  .NewList {
    padding-top: 0px;
    & > li {
      & > div {
        padding-left: 0px;
      }
    }
  }
`;

export const EventResultBottomStyled = styled.div`
  display: flex;
  flex-direction: row;
  height: 28px;
  flex-basis: 28px;
  align-self: left;
  margin-top: 16px;
  margin-bottom: 19px;
`;

export const ButtonStyled = styled(Button)`
  flex-grow: 0;
  min-width: 70px;
  height: 28px;
  padding: 0 10px;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  background-color: Transparent;
  ${props => props.isAll && css`
    margin-left: 9.5px;
  `}
`;

export const ResultListAccordionStyled = styled(ResizableAccordion)`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  .accordion-item {
    border-color: ${props => props.theme.line.color2};
    &.textList {
      height: 100%;
    }
  }
  .accordion-item-header {
    border-color: ${props => props.theme.line.color2};
  }
  .accordion-item-content {
    padding: 0 20px;
  }
`;

export const SortButtonStyled = styled(IconButton)`
  ${props => props.isDescending && css`
    color: red;
  `}
  width: 24px;
  height: 24px;
  padding: 0;
`;

export const ListCounter = styled.div`
  flex-grow: 0;
  font-size: 14px;
  align-self: center;
`;

export const DummyDiv = styled.div`
  flex-grow: 1;
`;

export const CustomIconButtonStyled = styled(IconButton)`
  width: 32px;
  height: 42px;
  font-size: 20px;
  padding: 0;
`;

export const HeadTitleStyled = styled.span`
  font-size: 16px;
`;

export const StyledPopup = styled(ReactModalAdapter).attrs({
  className: 'Popup',
})`
position: fixed;
top: 0;
left: 0;
z-index: 999;


.ReactModal__Overlay {
  width: 100%;
  height: 100%;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  background-color: rgba(0,0,0,.5) !important;
}

.ReactModal__Content {
  top: 50% !important;
  left: 50% !important;
  height: 513px;
  width: 704px;
  transform: translate(-50%, -50%) !important;
  background-color: ${props => props.theme.background.color1}!important;
  border: 1px solid ${props => props.theme.line.color5} !important;
  border-radius: 0px !important;
}

.ReactModal__Content--after-open {
  padding: 0px !important;
  overflow: hidden !important;
}
`;

export const TooltipSpan = withTheme(styled(Span)`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  margin: 0px;
  padding: 0px
`);

export const CustomReactTooltip = withTheme(styled(ReactTooltip)`
  background-color: #555 !important;
  padding: 5px !important;
  &.place-top {
    &:after {
      border-top: none !important;
    }
  }
  &.place-left {
    &:after {
      border-left: 6px solid #555 !important;
    }
  }
  &.place-right {
    &:after {
      border-right: 6px solid #555 !important;
    }
  }
`);
