import styled from 'styled-components';
import dZoomCursor from 'util/static/images/dzoom_1.cur';

export const TileDigitalZoomControlStyled = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  user-select: none;
  cursor: url(${dZoomCursor}), pointer;
`;

export const MiniMapContainerStyled = styled.div`
  position: absolute;
  top: 33px;
  right: 6px;
  border: 1px solid #f37321;
  cursor: default;
`;

export const MimiMapCanvasStyled = styled.canvas`
`;

export const MiniMapBlackOpacityStyled = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.35);
  user-select: none;
`;

export const MiniMapBoxStyled = styled.div`
  position: absolute;
  background-color: rgba(243,115,33,0.55);
  user-select: none;
`;
