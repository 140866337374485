import styled, { withTheme } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  pointer-events: 'none';
`;

export const SelectWrapper = styled.div`
  margin-top: 15px;
`;

export const GroupDisableMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: block;
  background-color: rgb(204, 204, 204);
  opacity: 0.3;
`;

export const TableWrapper = withTheme(styled.div`
  margin: 15px;
  height: 401px;
`);
