import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { ManualBackupActions, PreLoadActions } from 'store/actionCreators';
import { getLanguage } from 'util/lib';

class ManualBackupPopupContainer extends React.Component {
  state = {
    showGuidePopup: false,
    showCompletePopup: false,
    guideData: {},
    completeData: {
      message: '',
      period: '',
      tableData: [],
    },
    startBackup: false,
    fileName: '',
    currentBackupPercent: 0,
    totalBackupPercent: 0,
    processedTotalBackupPercent: 0,
    currentBackupChannel: 0,
    channelOffset: 0,
    exportChannelList: [],
    selectedChannel: 0,
    layoutListElements: [],
    selectedLayout: {
      id: 0,
    },
  };

  tick = 0;

  constructor(props) {
    super(props);
    PreLoadActions.sessionKeyGet();
  }

  componentDidMount() {
    this.initLayoutList();
    const dstChecked = this.checkDstRange();
    if (dstChecked) {
      const { startDateTime, endDateTime } = dstChecked;
      ManualBackupActions.applyDSTDateTime({
        startDateTime,
        endDateTime,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) {
      const { isRecordedDataExisting, existingRecordedData } = nextProps;
      if (isRecordedDataExisting === 'empty') {
        this.setState({
          showGuidePopup: true,
          guideData: {
            id: 0,
            title: getLanguage('lang_error'),
            content: getLanguage('lang_export_nodata_msg'),
          },
        });
        ManualBackupActions.setIsRecordedDataExisting('init');
      } else if (isRecordedDataExisting === 'exist') {
        this.setState({
          startBackup: true,
          currentBackupChannel: existingRecordedData.toJS()[0],
        });
        ManualBackupActions.setIsRecordedDataExisting('init');
      }
      return true;
    }

    if (JSON.stringify(nextState) !== JSON.stringify(this.state)) {
      const {
        showCompletePopup,
      } = nextState;
      // 백업 중지
      // 백업 완료 || 백업 중간 중지
      if (showCompletePopup) {
        this.tick = 0;
        this.setState({
          currentBackupChannel: 0,
          totalBackupPercent: 0,
          processedTotalBackupPercent: 0,
          currentBackupPercent: 0,
          channelOffset: 0,
        });
      }
      return true;
    }

    return false;
  }

  initCameraList = channelList => {
    const {
      selectedChannel: liveSelectedChannel,
      currentChannel: searchSelectedChannel,
      cameraList,
      flag,
    } = this.props;
    let selectedChannel;
    let index = 0;
    const cameraListData = cameraList.toJS();
    if (flag === 'live') {
      selectedChannel = liveSelectedChannel.toJS().channel;
    } else if (flag === 'search') {
      selectedChannel = searchSelectedChannel;
    }

    const resultArray = [];
    channelList.forEach(channelInfo => {
      cameraListData.forEach(cameraInfo => {
        if (channelInfo.Channel === cameraInfo.channel && cameraInfo.backupAccess) {
          resultArray.push({
            index,
            ch: cameraInfo.channel + 1,
            name: cameraInfo.channelName,
            isSelected: typeof selectedChannel !== 'undefined'
              && selectedChannel === cameraInfo.channel,
          });
          index += 1;
        }
      });
    });

    this.setState({
      exportChannelList: resultArray,
    });
  }

  initLayoutList = () => {
    const { layoutListData } = this.props;
    const { selectedLayout } = this.state;
    const layoutList = layoutListData.toJS();
    const result = [];
    let channelList = [];
    let found = false;

    layoutList.forEach(layout => {
      if (String(selectedLayout.id) === String(layout.LayoutId)) {
        result.push(
          <option
            id={layout.LayoutId}
            key={layout.LayoutId}
            value="default"
          >
            {layout.LayoutTitle}
          </option>,
        );
        channelList = layout.ScreenInfos;
        found = true;
      } else {
        result.push(
          <option
            id={layout.LayoutId}
            key={layout.LayoutId}
          >
            {layout.LayoutTitle}
          </option>,
        );
      }
    });

    if (layoutList.length > 0 && !found) {
      channelList = layoutList[0].ScreenInfos;
    }

    this.setState({
      layoutListElements: result,
    });

    this.initCameraList(channelList);
  }

  onSelectLayout = event => {
    const { layoutListData } = this.props;
    const layoutList = layoutListData.toJS();

    layoutList.forEach(layout => {
      if (layout.LayoutId === event.currentTarget.selectedIndex) {
        this.initCameraList(layout.ScreenInfos);
      }
    });

    this.setState({
      selectedLayout: {
        id: event.currentTarget.selectedIndex,
      },
    });
  }

  onChangeCheckTableData = (newData, selectedOne) => {
    if (typeof selectedOne !== 'undefined') {
      if (selectedOne.isSelected) {
        const channel = selectedOne.ch;
        this.setState({
          selectedChannel: channel,
        });
      }
      this.setState({
        exportChannelList: newData,
      });
    } else {
      this.setState({
        exportChannelList: newData,
      });
    }
  }

  validateChannel = () => {
    const {
      exportChannelList,
    } = this.state;
    let showPopup = false;
    if (exportChannelList.length < 1) {
      showPopup = true;
    } else {
      showPopup = true;
      exportChannelList.forEach(item => {
        if (item.isSelected) {
          showPopup = false;
        }
      });
    }

    if (showPopup) {
      this.setState({
        showGuidePopup: true,
        guideData: {
          id: 0,
          title: getLanguage('lang_error'),
          content: getLanguage('lang_export_select_ch_msg'),
        },
      });
      return false;
    }
    return true;
  }

  validateTime = () => {
    const {
      exportStartDateObj,
      exportEndDateObj,
    } = this.props;

    const startDate = exportStartDateObj.toJS();
    const endData = exportEndDateObj.toJS();
    console.log('validateTime startDate', startDate, ' endData ', endData);

    const {
      hour: sHour,
      minute: sMinute,
      second: sSecond,
    } = startDate;

    const {
      hour: eHour,
      minute: eMinute,
      second: eSecond,
    } = endData;

    let showPopup = false;

    if (sHour === eHour && sMinute === eMinute && sSecond === eSecond) {
      showPopup = true;
    } else if (sHour > eHour) {
      showPopup = true;
    } else if (sHour === eHour) {
      if (sMinute > eMinute) {
        showPopup = true;
      } else if (sMinute === eMinute) {
        if (sSecond > eSecond) {
          showPopup = true;
        }
      }
    }

    if (showPopup) {
      this.setState({
        showGuidePopup: true,
        guideData: {
          id: 0,
          title: getLanguage('lang_error'),
          content: getLanguage('lang_set_end_time_larger_msg'),
        },
      });
      return false;
    }
    return true;
  }

  doBackup = () => {
    const dstChecked = this.checkDstRange();
    if (dstChecked) {
      const { startDateTime, endDateTime } = dstChecked;
      ManualBackupActions.applyDSTDateTime({
        startDateTime,
        endDateTime,
      });
      console.log('dstChecked startDateTime', startDateTime, ' endDateTime ', endDateTime);
    }
    if (this.validateChannel()) {
      if (this.validateTime()) {
        const { exportStartDateObj } = this.props;
        const { exportChannelList } = this.state;
        const targetDate = exportStartDateObj.toJS();
        const targetChannels = [];
        exportChannelList.forEach(item => {
          if (item.isSelected) {
            targetChannels.push(item.ch - 1);
          }
        });
        ManualBackupActions.setIsRecordedDataExisting('init');
        ManualBackupActions.findRecordingDate({
          channelIdList: targetChannels.join(','),
          month: `${targetDate.year}-${targetDate.month}`,
        });
      }
    }
  }

  onGuidePopupConfirm = () => {
    this.setState({
      showGuidePopup: false,
    });
  }

  onCompletePopupConfirm = () => {
    this.setState({
      showCompletePopup: false,
      completeData: {
        message: '',
        period: '',
        tableData: [],
      },
    });
  }

  onBackup = type => {
    const { startBackup } = this.state;
    if (!startBackup) {
      return;
    }

    const callbackData = type;
    const {
      percent,
      channel,
      type: callbackType,
      event,
    } = callbackData;

    if (callbackType === 'progress') {
      this.tick += 1;
      if (!(this.tick % 50)) {
        this.handleBackupProgress(percent, channel);
      }
    } else if (callbackType === 'end') {
      this.handleBackupEnd(event.detail, channel);
    } else if (callbackType === 'error') {
      // this.handleBackupError(event.detail, channel);
      this.handleBackupEnd(event.detail, channel, true);
    }
  }

  handleBackupEnd = (info, endedChannel, isError) => {
    const { existingRecordedData } = this.props;
    const {
      channelOffset,
      completeData,
    } = this.state;
    const recordedChannel = existingRecordedData.toJS();
    if (info.error === 768) {
      return;
    }
    console.log('endedChannel', endedChannel + 1, 'info.state', info.state, 'info.error', info.error);

    const completeTableData = completeData.tableData;
    if (info.state === 1538) {
      completeTableData.push({
        ch: endedChannel + 1,
        status: getLanguage('lang_export_network_error'),
      });
    } else if (info.error === 515) {
      completeTableData.push({
        ch: endedChannel + 1,
        status: getLanguage('lang_export_no_video'),
      });
    } else if (info.state === 1539
        || info.state === 1540
        || info.state === 1541
        || info.state === 1542
        || info.state === 1543
        || info.state === 1544) {
      completeTableData.push({
        ch: endedChannel + 1,
        status: getLanguage('lang_export_failure'),
      });
    } else {
      completeTableData.push({
        ch: endedChannel + 1,
        status: isError ? getLanguage('lang_export_no_video') : getLanguage('lang_export_complete'),
      });
    }

    if (typeof recordedChannel[channelOffset + 1] !== 'undefined') {
      this.setState({
        startBackup: false,
        currentBackupChannel: recordedChannel[channelOffset],
        currentBackupPercent: 0,
        processedTotalBackupPercent: parseInt(100 * (
          (channelOffset + 1) / recordedChannel.length), 10),
        totalBackupPercent: parseInt(100 * ((channelOffset + 1) / recordedChannel.length), 10),
      });
      this.tick = 0;
      setTimeout(() => { // 연속 백업시 오류 발생 일정 간격 생성
        this.setState({
          startBackup: true,
          currentBackupChannel: recordedChannel[channelOffset + 1],
          channelOffset: channelOffset + 1,
          completeData: {
            tableData: completeTableData,
          },
        });
      }, 1500);
    } else {
      this.setState({
        startBackup: false,
      });
      this.tick = 0;

      const { emptyRecordedData } = this.props;
      emptyRecordedData.toJS().forEach(item => {
        completeTableData.push({
          ch: item + 1,
          status: getLanguage('lang_export_no_video'),
        });
      });
      this.setState({
        startBackup: false,
        showCompletePopup: true,
        completeData: {
          message: getLanguage('lang_export_complete_msg'),
          period: this.makePeriodString(),
          tableData: completeTableData,
        },
      });
    }
  }

  handleBackupError = (info, endedChannel) => {
    if (info.error === 768) {
      return;
    }

    const {
      completeData,
    } = this.state;
    const completeTableData = completeData.tableData;

    this.setState({
      startBackup: false,
    });

    if (info.state === 1538) {
      completeTableData.push({
        ch: endedChannel + 1,
        status: getLanguage('lang_export_network_error'),
      });
      this.setState({
        showCompletePopup: true,
        completeData: {
          message: getLanguage('lang_export_complete_msg'),
          period: this.makePeriodString(),
          tableData: completeTableData,
        },
      });
    } else if (info.error === 515) {
      completeTableData.push({
        ch: endedChannel + 1,
        status: getLanguage('lang_export_no_video'),
      });
      this.setState({
        showCompletePopup: true,
        completeData: {
          message: getLanguage('lang_export_complete_msg'),
          period: this.makePeriodString(),
          tableData: completeTableData,
        },
      });
    } else if (info.state === 1539
        || info.state === 1540
        || info.state === 1541
        || info.state === 1542
        || info.state === 1543
        || info.state === 1544) {
      completeTableData.push({
        ch: endedChannel + 1,
        status: getLanguage('lang_export_failure'),
      });
      this.setState({
        showCompletePopup: true,
        completeData: {
          message: getLanguage('lang_export_complete_msg'),
          period: this.makePeriodString(),
          tableData: completeTableData,
        },
      });
    } else {
      completeTableData.push({
        ch: endedChannel + 1,
        status: getLanguage('lang_complex_error'),
      });
      this.setState({
        showCompletePopup: true,
        completeData: {
          message: getLanguage('lang_export_complete_msg'),
          period: this.makePeriodString(),
          tableData: completeTableData,
        },
      });
    }
  }

  handleBackupProgress = (progress, channel) => {
    const { existingRecordedData } = this.props;
    const {
      processedTotalBackupPercent,
      currentBackupChannel,
      currentBackupPercent,
    } = this.state;

    if (currentBackupChannel === channel) {
      const recordedChannel = existingRecordedData.toJS();
      const percent = progress > 100 ? 100 : parseInt(progress, 10);

      if (currentBackupPercent !== percent) {
        this.setState({
          currentBackupPercent: percent,
          totalBackupPercent: processedTotalBackupPercent
            + parseInt(percent / recordedChannel.length, 10),
        });
      }
    }
  }

  onStopBackup = () => {
    const {
      existingRecordedData,
    } = this.props;

    const { completeData, currentBackupChannel } = this.state;
    const completedArray = completeData.tableData;
    const recordedChannel = existingRecordedData.toJS();

    const index = recordedChannel.indexOf(currentBackupChannel);

    completedArray[index] = {
      ch: currentBackupChannel + 1,
      status: getLanguage('lang_export_failure'),
    };

    this.setState({
      startBackup: false,
      showCompletePopup: true,
      completeData: {
        message: getLanguage('lang_export_complete_msg'),
        period: this.makePeriodString(),
        tableData: completedArray,
      },
      currentBackupChannel: null,
      totalBackupPercent: 0,
      processedTotalBackupPercent: 0,
      currentBackupPercent: 0,
      channelOffset: 0,
    });
  }

  makePeriodString = () => {
    const {
      exportStartDateObj,
      exportEndDateObj,
    } = this.props;
    const {
      year: sYear,
      month: sMonth,
      day: sDay,
      hour: sHour,
      minute: sMinute,
      second: sSecond,
    } = exportStartDateObj.toJS();

    const {
      year: eYear,
      month: eMonth,
      day: eDay,
      hour: eHour,
      minute: eMinute,
      second: eSecond,
    } = exportEndDateObj.toJS();

    const sMonthStr = sMonth.toString().length === 1 ? `0${sMonth}` : sMonth;
    const sDayStr = sDay.toString().length === 1 ? `0${sDay}` : sDay;
    const sHourStr = sHour.toString().length === 1 ? `0${sHour}` : sHour;
    const sMinuteStr = sMinute.toString().length === 1 ? `0${sMinute}` : sMinute;
    const sSecondStr = sSecond.toString().length === 1 ? `0${sSecond}` : sSecond;

    const eMonthStr = eMonth.toString().length === 1 ? `0${eMonth}` : eMonth;
    const eDayStr = eDay.toString().length === 1 ? `0${eDay}` : eDay;
    const eHourStr = eHour.toString().length === 1 ? `0${eHour}` : eHour;
    const eMinuteStr = eMinute.toString().length === 1 ? `0${eMinute}` : eMinute;
    const eSecondStr = eSecond.toString().length === 1 ? `0${eSecond}` : eSecond;

    return `${getLanguage('lang_period')} : `
      + `${sYear}/${sMonthStr}/${sDayStr} ${sHourStr}:${sMinuteStr}:${sSecondStr} ~ 
      ${eYear}/${eMonthStr}/${eDayStr} ${eHourStr}:${eMinuteStr}:${eSecondStr}`;
  }

  onFileNameChange = e => {
    this.setState({
      fileName: e.target.value,
    });
  }

  checkDstRange = () => {
    const {
      exportStartDateTimeObjBackup,
      exportEndDateTimeObjBackup,
      dstInfo,
      dstEnableInfo,
      timeZone,
    } = this.props;

    if (!dstEnableInfo) {
      return false;
    }

    const {
      year: sYear, month: sMonth, day: sDay, hour: sHour, minute: sMinute, second: sSecond,
    } = exportStartDateTimeObjBackup.toJS();
    const {
      year: eYear, month: eMonth, day: eDay, hour: eHour, minute: eMinute, second: eSecond,
    } = exportEndDateTimeObjBackup.toJS();

    const dstStart = new Date(
      sYear,
      dstInfo.dstStartInfo.month - 1,
      1,
      dstInfo.dstStartInfo.hours,
      dstInfo.dstStartInfo.minutes,
      dstInfo.dstStartInfo.seconds,
    );
    let firstDayOfWeek = new Date(dstStart.getFullYear(), dstStart.getMonth(), 1).getDay();

    const dateFromCalandar = (dayOfWeek, firstOfWeek, weekOfMonth) => (
      dayOfWeek < firstOfWeek
        ? (weekOfMonth) * 7 - firstOfWeek + (dayOfWeek + 1)
        : (weekOfMonth - 1) * 7 - firstOfWeek + (dayOfWeek + 1)
    );

    dstStart.setDate(
      dateFromCalandar(dstInfo.dstStartInfo.dayOfWeek,
        firstDayOfWeek, dstInfo.dstStartInfo.weekOfMonth),
    );
    if (dstStart.getMonth() !== dstInfo.dstStartInfo.month - 1) {
      dstStart.setDate(dstStart.getDate() - 7);
    }

    const dstEnd = new Date(
      eYear,
      dstInfo.dstEndInfo.month - 1,
      1,
      dstInfo.dstEndInfo.hours,
      dstInfo.dstEndInfo.minutes,
      dstInfo.dstEndInfo.seconds,
    );
    firstDayOfWeek = new Date(dstEnd.getFullYear(), dstEnd.getMonth(), 1).getDay();

    dstEnd.setDate(
      dateFromCalandar(dstInfo.dstEndInfo.dayOfWeek,
        firstDayOfWeek, dstInfo.dstEndInfo.weekOfMonth),
    );
    if (dstEnd.getMonth() !== dstInfo.dstEndInfo.month - 1) {
      dstEnd.setDate(dstEnd.getDate() - 7);
    }

    const localStartTime = new Date(sYear, sMonth - 1, sDay, sHour, sMinute, sSecond);
    let startUTCTime = localStartTime.setHours(localStartTime.getHours()
      - localStartTime.getTimezoneOffset() / 60 + Number(timeZone));
    startUTCTime = new Date(startUTCTime).toISOString();

    const localEndTime = new Date(eYear, eMonth - 1, eDay, eHour, eMinute, eSecond);
    let endUTCTime = localEndTime.setHours(localEndTime.getHours()
      - localEndTime.getTimezoneOffset() / 60 + Number(timeZone));
    endUTCTime = new Date(endUTCTime).toISOString();

    const convertStartUTCTime = startUTCTime === '0' ? new Date(Number(startUTCTime)) : new Date(startUTCTime);
    const changeCurrentStartTime = convertStartUTCTime.setHours(convertStartUTCTime.getHours()
      + convertStartUTCTime.getTimezoneOffset() / 60 - Number(timeZone));
    const targetStartTime = new Date(changeCurrentStartTime);

    const convertEndUTCTime = endUTCTime === '0' ? new Date(Number(endUTCTime)) : new Date(endUTCTime);
    const changeCurrentEndTime = convertEndUTCTime.setHours(convertEndUTCTime.getHours()
      + convertEndUTCTime.getTimezoneOffset() / 60 - Number(timeZone));
    const targetEndTime = new Date(changeCurrentEndTime);

    const dstRangeStartIn = dstStart.valueOf() < targetStartTime.valueOf()
      && targetStartTime.valueOf() < dstEnd.valueOf();

    const dstRangeEndIn = dstStart.valueOf() < targetEndTime.valueOf()
      && targetEndTime.valueOf() < dstEnd.valueOf();

    if (dstRangeStartIn) {
      targetStartTime.setHours(targetStartTime.getHours() + 1);
    }

    if (dstRangeEndIn) {
      targetEndTime.setHours(targetEndTime.getHours() + 1);
    }

    const startDateTime = {
      month: targetStartTime.getMonth() + 1,
      day: targetStartTime.getDate(),
      hour: targetStartTime.getHours(),
      minute: targetStartTime.getMinutes(),
      second: targetStartTime.getSeconds(),
    };

    const endDateTime = {
      month: targetEndTime.getMonth() + 1,
      day: targetEndTime.getDate(),
      hour: targetEndTime.getHours(),
      minute: targetEndTime.getMinutes(),
      second: targetEndTime.getSeconds(),
    };

    if (dstRangeStartIn || dstRangeEndIn) {
      // console.log('startDateTime ', startDateTime, ' endDateTime', endDateTime);
      return {
        startDateTime,
        dstRangeStartIn,
        endDateTime,
        dstRangeEndIn,
      };
    }
    return false;
  }

  render() {
    const {
      render,
    } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

ManualBackupPopupContainer.propTypes = {
  render: PropTypes.func.isRequired,
  selectedChannel: PropTypes.instanceOf(Map),
  cameraList: PropTypes.instanceOf(List).isRequired,
  exportStartDateTimeObjBackup: PropTypes.instanceOf(Map).isRequired,
  exportEndDateTimeObjBackup: PropTypes.instanceOf(Map).isRequired,
  isRecordedDataExisting: PropTypes.string,
  flag: PropTypes.string.isRequired,
  currentChannel: PropTypes.number,
  existingRecordedData: PropTypes.instanceOf(List),
  emptyRecordedData: PropTypes.instanceOf(List),
  layoutListData: PropTypes.instanceOf(List).isRequired,
  exportStartDateObj: PropTypes.instanceOf(Map).isRequired,
  exportEndDateObj: PropTypes.instanceOf(Map).isRequired,
  dstInfo: PropTypes.oneOfType([PropTypes.any]).isRequired,
  dstEnableInfo: PropTypes.bool.isRequired,
  timeZone: PropTypes.objectOf(PropTypes.any).isRequired,
};

ManualBackupPopupContainer.defaultProps = {
  selectedChannel: Map({}),
  isRecordedDataExisting: '',
  currentChannel: 0,
  existingRecordedData: List([]),
  emptyRecordedData: List([]),
};

export default connect(
  state => ({
    sessionKey: state.preLoadModule.get('backupSessionKey'),
    selectedChannel: state.cameraInfoModule.get('selectTileCamera'),
    cameraList: state.cameraInfoModule.get('cameraList'),
    exportStartDateTimeObjBackup: state.manualBackupModule.get('exportStartDateTimeObjBackup'),
    exportEndDateTimeObjBackup: state.manualBackupModule.get('exportEndDateTimeObjBackup'),
    isRecordedDataExisting: state.manualBackupModule.get('isRecordedDataExisting'),
    isCalendarOpen: state.manualBackupModule.get('isCalendarOpen'),
    existingRecordedData: state.manualBackupModule.get('existingRecordedData'),
    emptyRecordedData: state.manualBackupModule.get('emptyRecordedData'),
    currentChannel: state.searchTimelineModule.get('currentChannel'),
    layoutListData: state.layoutListModule.get('layoutListData'),
    timeZone: state.deviceInfoModule.get('timeZone'),
    exportStartDateObj: state.manualBackupModule.get('exportStartDateObj'),
    exportEndDateObj: state.manualBackupModule.get('exportEndDateObj'),
    dstInfo: state.deviceInfoModule.get('dstInfo').toJS(),
    dstEnableInfo: state.deviceInfoModule.get('dateInfo').toJS().DSTEnable,
  }),
)(ManualBackupPopupContainer);
