import React from 'react';
import PropTypes from 'prop-types';
import { LoadingShape, ScreenMask } from 'wisenet-ui/components/atoms';
import { LoadingStyled, LoadingWrapper } from './LoadingStyled';

const Loading = ({ isPage, ...rest }) => (
  <LoadingWrapper isPage={isPage} {...rest}>
    <LoadingStyled>
      <LoadingShape />
    </LoadingStyled>
    <ScreenMask />
  </LoadingWrapper>
);

Loading.propTypes = {
  isPage: PropTypes.bool,
};

Loading.defaultProps = {
  isPage: false,
};

export default Loading;
