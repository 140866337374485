import styled from 'styled-components';
import { Button } from 'wisenet-ui/components/atoms';

export const TimelineWrapperStyled = styled.div`
  margin-top: 100px;
  padding: 0 20px;
`;

export const ButtonStyled = styled(Button)`
  margin-bottom: 40px;
`;
