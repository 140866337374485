import styled from 'styled-components';
import { Table } from 'wisenet-ui/components/organisms';

export const TableStyled = styled(Table)`
  .custom-header {
    border: 1px solid red;
  }
`;

export const TableExamplePageWrapperStyled = styled.div`
  padding: 0 20px 20px 20px;
`;

export const ExampleTitleStyled = styled.div`
  padding: 30px 0 10px 0;
`;
