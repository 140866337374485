import React from 'react';
import PropTypes from 'prop-types';
import {
  CustomDatePicker,
  StartCalendarContainer,
  EndCalendarContainer,
} from 'wisenet-ui/components/organisms';
import { TextSearchActions } from 'store/actionCreators';
import { getLanguage } from 'util/lib';
import {
  Container,
  Wrapper,
  DateInputWrapper,
  DateWrapper,
  DateInputTitle,
  StyledSpan,
} from './DatePickerWrapperStyled';

class DatePickerWrapper extends React.PureComponent {
  render() {
    const {
      isCalendarOpen,
      startDateTime,
      endDateTime,
    } = this.props;
    const {
      year: sYear,
    } = startDateTime;
    let {
      month: sMonth,
      day: sDay,
      hour: sHour,
      minute: sMinute,
      second: sSecond,
    } = startDateTime;

    sMonth = sMonth.toString().length === 1 ? `0${sMonth}` : sMonth;
    sDay = sDay.toString().length === 1 ? `0${sDay}` : sDay;
    sHour = sHour.toString().length === 1 ? `0${sHour}` : sHour;
    sMinute = sMinute.toString().length === 1 ? `0${sMinute}` : sMinute;
    sSecond = sSecond.toString().length === 1 ? `0${sSecond}` : sSecond;

    const {
      year: eYear,
    } = endDateTime;
    let {
      month: eMonth,
      day: eDay,
      hour: eHour,
      minute: eMinute,
      second: eSecond,
    } = endDateTime;

    eMonth = eMonth.toString().length === 1 ? `0${eMonth}` : eMonth;
    eDay = eDay.toString().length === 1 ? `0${eDay}` : eDay;
    eHour = eHour.toString().length === 1 ? `0${eHour}` : eHour;
    eMinute = eMinute.toString().length === 1 ? `0${eMinute}` : eMinute;
    eSecond = eSecond.toString().length === 1 ? `0${eSecond}` : eSecond;
    return (
      <Container>
        <Wrapper>
          <CustomDatePicker
            title="Start"
            label="start"
            id="start-date-picker-popper"
            actions={TextSearchActions}
            isCalendarOpen={isCalendarOpen}
            popperModifiers={{
              flip: {
                enabled: false,
              },
              offset: {
                enabled: true,
                offset: '-8px, -55px',
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
                boundariesElement: 'viewport',
              },
            }}
            dateTimeObj={startDateTime}
          >
            <DateWrapper>
              <DateInputTitle>{getLanguage('lang_start_time')}</DateInputTitle>
              <DateInputWrapper>
                <StyledSpan>
                  {`${sYear}-${sMonth}-${sDay} `}
                  {` ${sHour}:${sMinute}:${sSecond}`}
                </StyledSpan>
              </DateInputWrapper>
            </DateWrapper>
            <StartCalendarContainer
              id="search-start-date-picker-popper"
              actions={TextSearchActions}
              dateTimeObj={startDateTime}
            />
          </CustomDatePicker>
        </Wrapper>
        <Wrapper>
          <CustomDatePicker
            title="End"
            label="end"
            id="end-date-picker-popper"
            actions={TextSearchActions}
            isCalendarOpen={isCalendarOpen}
            popperModifiers={{
              flip: {
                enabled: false,
              },
              offset: {
                enabled: true,
                offset: '-8px, -100px',
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
                boundariesElement: 'viewport',
              },
            }}
            dateTimeObj={endDateTime}
          >
            <DateWrapper>
              <DateInputTitle>{getLanguage('lang_end_time')}</DateInputTitle>
              <DateInputWrapper>
                <StyledSpan>
                  {`${eYear}-${eMonth}-${eDay} `}
                  {`${eHour}:${eMinute}:${eSecond}`}
                </StyledSpan>
              </DateInputWrapper>
            </DateWrapper>
            <EndCalendarContainer
              id="search-end-date-picker-popper"
              actions={TextSearchActions}
              dateTimeObj={endDateTime}
            />
          </CustomDatePicker>
        </Wrapper>
      </Container>
    );
  }
}

DatePickerWrapper.propTypes = {
  startDateTime: PropTypes.objectOf(PropTypes.any),
  endDateTime: PropTypes.objectOf(PropTypes.any),
  isCalendarOpen: PropTypes.bool,
};

DatePickerWrapper.defaultProps = {
  startDateTime: {},
  endDateTime: {},
  isCalendarOpen: true,
};

export default DatePickerWrapper;
