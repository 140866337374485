import styled from 'styled-components';
import { Rnd } from 'react-rnd';
import { IconButton } from 'wisenet-ui/components/atoms';
import { ResizableAccordion } from 'wisenet-ui/components/organisms';

export const AccordionWrapperStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const RndStyled = styled(Rnd)`
  position: relative !important;
  transform: none !important;
  flex-grow: 1;
`;

export const DeviceAccordionStyled = styled(ResizableAccordion)`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-top: 1px solid ${props => props.theme.line.color3};
  .accordion-item {
    border-color: ${props => props.theme.line.color3}
  }
  .accordion-item-header {
    border-color: ${props => props.theme.line.color1}
  }
  .accordion-item-content {
    padding: 0 12px;
  }
`;

export const DateAccordionStyled = styled(ResizableAccordion)`
  flex-shrink: 0;
  overflow-y: auto;
  border-top: 1px solid ${props => props.theme.line.color6};
  min-height: ${props => props.minHeight};
  max-height: ${props => props.maxHeight};
  .accordion-item {
    border-color: ${props => props.theme.line.color3}
  }
  .accordion-item-header {
    border-color: ${props => props.theme.line.color1}
  }
  .accordion-item-content {
    padding: 0 12px;
  }
`;

export const CustomIconButtonStyled = styled(IconButton)`
  font-size: 20px;
  padding: 0;
  &:hover {
    color: #f37321;
  }
`;

export const HeadTitleStyled = styled.span`
  font-size: 16px;
`;
