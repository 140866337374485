import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CameraInfoActions, LiveMediaControlActions } from 'store/actionCreators';
import { Map, List } from 'immutable';

class LiveVideoLayoutContainer extends React.Component {
  state = {
    tileList: [],
    restart: false,
  }

  componentDidMount() {
    const {
      tileCameraList,
    } = this.props;

    const tileList = tileCameraList.toJS();
    if (tileList.length !== 0) {
      this.onUpdate({
        tileList,
        isReset: false,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    // const { restart } = this.state;
    const {
      layoutPageCurrentNumber: prevLayoutPageCurrentNumber,
      tileCameraList: prevTileCameraList,
      layoutPagePatternTileCount: prevLayoutPagePatternTileCount,
    } = prevProps;

    const {
      dragTileCameraList,
      layoutPageCurrentNumber,
      tileCameraList,
      layoutPagePatternTileCount,
    } = this.props;

    const { tileList: stateTileList } = this.state;

    const tileList = tileCameraList.toJS();

    if (dragTileCameraList.size !== 0) {
      this.onDragTileCameaList({ dragTileCameraList });
      this.onUpdate({
        isReset: false,
      });
    }

    if ((prevLayoutPageCurrentNumber !== layoutPageCurrentNumber) || (
      prevLayoutPagePatternTileCount !== layoutPagePatternTileCount)) {
      const newTileList = [];
      if (JSON.stringify(prevTileCameraList) !== JSON.stringify(tileCameraList)) {
        this.onUpdate({
          tileList: [],
          isReset: true,
        });
      } else {
        tileList.map(tile => {
          if (tile.index < layoutPagePatternTileCount) {
            newTileList.push(tile);
          }
          return null;
        });
        this.onUpdate({
          tileList: newTileList,
          isReset: true,
        });
      }
    } else {
      const diffTileCount = this.countCheckPropsTileCameraList(
        prevTileCameraList.toJS(), tileCameraList.toJS(),
      );
      if (diffTileCount === 0) {
        if (stateTileList.length === 0 && tileList.length !== 0) {
          this.onUpdate({
            tileList,
            isReset: false,
          });
        }
      } else if (diffTileCount === 1) {
        this.onUpdate({
          tileList,
        });
      } else {
        const { restart } = this.state;
        this.onUpdate({
          tileList: [],
          isReset: true,
          restart: !restart,
        });
      }
    }
  }

  // returun의 값이 2 이상인 경우는 페이지 전체를 지우고 다시 그려야 할 필요성이 있을 때 이다.
  countCheckPropsTileCameraList = (prevTileList, tileList) => {
    let diffCount = 0;
    if (prevTileList.length === 1 && tileList.length === 1) {
      if (prevTileList[0].uid !== tileList[0].uid) {
        return 2;
      }
    }
    if (tileList.length !== 0) {
      if (Math.abs(prevTileList.length - tileList.length) > 1) {
        return 2;
      }

      const overTileList = prevTileList.length >= tileList.length ? prevTileList : tileList;
      const lessTileList = prevTileList.length < tileList.length ? prevTileList : tileList;

      overTileList.map(overTile => {
        const findTile = lessTileList.find(lessTile => lessTile.uid === overTile.uid);
        if (!findTile) {
          diffCount += 1;
        }
        return null;
      });

      return diffCount;
    }
    return prevTileList.length !== 0 ? prevTileList.length : 0;
  }

  getCurrentTileList = ({ tileList, emptyTileList }) => {
    LiveMediaControlActions.backupLayoutControl({
      needBackupLayoutData: false,
      backupLayout: Map({ tileList, emptyTileList }),
    });
    LiveMediaControlActions.patternControl({ pattern: 'SPECIAL' });
  }

  setCurrentTileList = ({ layoutPositionInfoList }) => {
    const { layoutPageCurrentNumber } = this.props;
    CameraInfoActions.setTileCameraList({
      layoutPositionInfoList,
      layoutPageCurrentNumber,
    });

    this.onUpdate({
      isReset: false,
    });
  }

  onDragTileCameaList = ({ dragTileCameraList }) => {
    CameraInfoActions.onTileCameraList({
      selectCameraList: dragTileCameraList.toJS(),
      toItem: (dragTileCameraList.size === 1 && this.moveItem.empty) ? this.moveItem : undefined,
    });

    // tile에 다 비치하면 비우기
    const { setDragTileCamera } = this.props;
    setDragTileCamera();
  }

  setMoveItem = moveItem => {
    this.moveItem = moveItem;
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  onError = event => {
    const { tileList } = this.state;
    // const { tileCameraList } = this.props;
    if (event.detail) {
      switch (event.detail.error) {
        case 0x020A: { // Talk service unavailable
          break;
        }
        default:
          break;
      }
    } else if (event.errorCode) {
      switch (event.errorCode) {
        case 0x0303: { // Mute service unavailable
          const copyTileList = tileList.splice(0);
          const removeIndex = copyTileList.findIndex(x => x.uid === event.uid);
          copyTileList.splice(removeIndex, 1);
          this.onUpdate({
            tileList: copyTileList,
            restart: true,
          });
          break;
        }
        default:
          break;
      }
    }
  }

  render() {
    const { render, selectTileCamera } = this.props;
    const selectUid = selectTileCamera.get('uid');
    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
        selectUid,
      },
    );
  }
}

LiveVideoLayoutContainer.propTypes = {
  render: PropTypes.func.isRequired,
  tileCameraList: PropTypes.oneOfType([PropTypes.any]).isRequired,
  selectTileCamera: PropTypes.instanceOf(Map).isRequired,
  dragTileCameraList: PropTypes.instanceOf(List).isRequired,
  setDragTileCamera: PropTypes.func.isRequired,
  layoutPageCurrentNumber: PropTypes.number.isRequired,
  layoutPagePatternTileCount: PropTypes.number.isRequired,
};

export default connect(
  state => {
    const { currentNumber: layoutPageCurrentNumber } = state.liveMediaControlModule.get('layoutPageInfo').toJS();
    return (
      {
        tileCameraList: state.cameraInfoModule.get('tileCameraListPage').get(`${layoutPageCurrentNumber}`),
        layoutPageCurrentNumber,
        layoutPagePatternTileCount: state.liveMediaControlModule.get('layoutPageInfo').get('patternTileCount'),
        pattern: state.liveMediaControlModule.get('pattern'),
        type: state.liveMediaControlModule.get('layoutType'),
        selectTileCamera: state.cameraInfoModule.get('selectTileCamera'),
        needBackupLayoutData: state.liveMediaControlModule.get('needBackupLayoutData'),
        backupLayout: state.liveMediaControlModule.get('backupLayout'),
        layoutPendding: state.cameraInfoModule.get('layoutPendding'),
      }
    );
  },
)(LiveVideoLayoutContainer);
