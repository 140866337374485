import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'store';
import { PrivateRoute } from 'routes';
import {
  LoginPage,
  HelpPage,
  MainPage,
  PreLoader,
  MonitordiffGuide,
} from 'pages';
import { hot } from 'react-hot-loader/root';

const basename = process.env.NODE_ENV === 'development' ? '' : `${process.env.PUBLIC_URL}`;

const Root = () => (
  <Provider store={store}>
    <BrowserRouter basename={basename}>
      <PreLoader>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/error" component={MonitordiffGuide} />
          <PrivateRoute path="/help" component={HelpPage} />
          <PrivateRoute component={MainPage} />
        </Switch>
      </PreLoader>
    </BrowserRouter>
  </Provider>
);

export default hot(Root);
