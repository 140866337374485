import React from 'react';
import PropTypes from 'prop-types';
// import { Popover } from 'wisenet-ui/components/organisms';
import { DropDownMenu } from 'wisenet-ui/components/organisms';
import { MediaControlIconButtonList as IconList } from 'wisenet-ui/util/static/constants/mediaControl/mediaControlType';
import { getLanguage } from 'util/lib';
import {
  TileControlBarStyled,
  IconButtonStyled,
  CustomReactTooltip,
  TooltipSpan,
} from './TileControlBarStyled';

class TileControlBar extends React.PureComponent {
  render() {
    const {
      onClick,
      className,
      buttonItems,
      exceptionList,
      popOverList,
      overlayFilterDropdownVisible,
      onChangeOverlayFilterDropdownVisible,
      profileDropdownVisible,
      onChangeProfileDropdownVisible,
    } = this.props;

    return (
      <TileControlBarStyled className={className}>
        {buttonItems.map(item => {
          const isExceptionButton = exceptionList.find(exception => exception === item);
          const { props } = this;
          const isActive = isExceptionButton ? false : props[item];
          const popOverComponent = popOverList.find(popOver => popOver.type === item);
          if (popOverComponent) {
            const callback = item === 'up' ? onChangeOverlayFilterDropdownVisible : onChangeProfileDropdownVisible;
            const stateValue = item === 'up' ? overlayFilterDropdownVisible : profileDropdownVisible;
            return (
              <DropDownMenu
                isOpend={stateValue}
                onClickOutside={callback}
                menuItems={[popOverComponent.component]}
                key={`dropdown-${IconList[item].iconId}`}
              >
                <IconButtonStyled
                  onClick={callback}
                  onDoubleClick={event => event.stopPropagation()}
                  key={`iconbutton-${IconList[item].iconId}`}
                  isActive={isActive}
                  className={`${IconList[item].iconId}`}
                />
              </DropDownMenu>
            );
          }
          return (
            <IconButtonStyled
              onClick={onClick(IconList[item].clickId)}
              onDoubleClick={event => event.stopPropagation()}
              key={`iconbutton-${IconList[item].iconId}`}
              isActive={isActive}
              className={`${IconList[item].iconId}`}
              data-tip={item}
              data-for={item}
            >
              <CustomReactTooltip
                id={item}
                type="light"
                place="top"
                effect="float"
                delayShow={500}
                getContent={() => (
                  <TooltipSpan>
                    {getLanguage(IconList[item].lang ? IconList[item].lang : item)}
                  </TooltipSpan>
                )}
              />
            </IconButtonStyled>
          );
        })}
      </TileControlBarStyled>
    );
  }
}

TileControlBar.defaultProps = {
  onClick: () => {},
  // disable: {},
  className: null,
  buttonItems: [],
  exceptionList: [],
  popOverList: [],
  overlayFilterDropdownVisible: false,
  onChangeOverlayFilterDropdownVisible: () => {},
  profileDropdownVisible: false,
  onChangeProfileDropdownVisible: () => {},
};

TileControlBar.propTypes = {
  onClick: PropTypes.func,
  // disable: PropTypes.oneOfType([PropTypes.any]),
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  buttonItems: PropTypes.arrayOf(PropTypes.any),
  exceptionList: PropTypes.arrayOf(PropTypes.any),
  popOverList: PropTypes.arrayOf(PropTypes.any),
  overlayFilterDropdownVisible: PropTypes.bool,
  onChangeOverlayFilterDropdownVisible: PropTypes.func,
  profileDropdownVisible: PropTypes.bool,
  onChangeProfileDropdownVisible: PropTypes.func,
};

export default TileControlBar;
