import React from 'react';
import PropTypes from 'prop-types';
import {
  // SunapiActions,
  PostLoadActions,
  PreLoadActions,
  DeviceInfoActions,
  LoginActions,
} from 'store/actionCreators';
import { Auth } from 'util/lib';
import { getCookie } from 'util/lib/Cookie';
import axios from 'axios';

class MainPageContainer extends React.Component {
  componentDidMount() {
    const { history } = this.props;
    Auth.setHistory(history);
    PreLoadActions.sessionKeyGet();
    LoginActions.checkIsAdmin();
    DeviceInfoActions.getdeviceInfo();
    DeviceInfoActions.getDateInfo();
    PostLoadActions.setPosDataCheck({
      posDataCheck: !(navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1),
    });
    DeviceInfoActions.getMaxChannelInfo();
    PostLoadActions.setEventStatusMonitorWokrer({
      eventMonitorWorker: true,
      userid: atob(getCookie('ID')),
      authKey: getCookie('AUTH_KEY'),
      baseURL: axios.defaults.baseURL,
      authHeader: getCookie('AUTH_HEADER_UWA') ? JSON.parse(getCookie('AUTH_HEADER_UWA')) : null,
      // safari인 경우 polling으로 동작
      isPolling: navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1,
    });
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

MainPageContainer.propTypes = {
  render: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default MainPageContainer;
