import { createAction, handleActions } from 'redux-actions';
import { Map } from 'immutable';

export const CONTROL_MESSAGE_BOX = 'messageBox/OPEN_MESSAGE_BOX';

export const controlMessageBox = createAction(CONTROL_MESSAGE_BOX);

const initialState = Map({
  messageBoxInfo: {
    title: 'Info',
    content: 'Message...',
    isOpen: false,
  },
});

export default handleActions({
  [CONTROL_MESSAGE_BOX]: (state, { payload: { messageBoxInfo } }) => (
    state.set('messageBoxInfo', messageBoxInfo)
  ),
}, initialState);
