import React from 'react';
import PropTypes from 'prop-types';
import { PTZSwingContainer } from 'containers/organisms';
import { Table } from 'wisenet-ui/components/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { getLanguage } from 'util/lib';
import {
  Container,
  SwingDisableMask,
  TableWrapper,
} from './PTZSwingStyled';

const PTZSwing = ({
  onChangeData,
  currentSwingList,
}) => {
  const swingHeader = [
    {
      Header: getLanguage('lang_number'),
      accessor: 'no',
      sortable: true,
    },
    {
      Header: getLanguage('lang_name'),
      accessor: 'name',
      sortable: true,
    },
  ];

  return (
    <Container id="swing_wrapper">
      <SwingDisableMask id="swing_disable_mask" />
      <TableWrapper>
        { currentSwingList !== null
        && currentSwingList !== false
        && (
          <Table
            onChangeData={onChangeData}
            scroll
            header={swingHeader}
            data={currentSwingList}
            selectRow
            pageSize={9}
          />
        )
      }
      </TableWrapper>
    </Container>
  );
};

PTZSwing.propTypes = {
  currentSwingList: PropTypes.arrayOf(PropTypes.any),
  onChangeData: PropTypes.func,
};

PTZSwing.defaultProps = {
  currentSwingList: [],
  onChangeData: null,
};

export default withContainer(PTZSwingContainer, PTZSwing);
