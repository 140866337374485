import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import {
  IconButton,
} from 'wisenet-ui/components/atoms';

export const PosWrapperStyled = styled.div`
  border-bottom: 1px solid ${props => props.theme.line.color2};
  margin-top: 8px;
`;

export const PosTitleWrapperStyled = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: -0.2px;
  padding-left: 8px;
  padding-right: 2px;
`;

export const PosTitleStyled = styled.span`
  font-size: 14px;
  ${props => props.isBlink && css`
    @-webkit-keyframes blink {
      0% {opacity:0;}
      100% {opacity:1;}
    }
    @-moz-keyframes blink {
      0% {opacity:0;}
      100% {opacity:1;}
    }
    @keyframes blink {
      0% {opacity:0;}
      100% {opacity:1;}
    }
    -webkit-animation:blink 0.5s ease-in-out infinite alternate;
    -moz-animation:blink 0.5s ease-in-out infinite alternate;
    animation:blink 0.5s ease-in-out infinite alternate;
  `};
`;

export const PosUtilBtnWrapperStyled = styled.div`
  display: flex;
  color: ${props => props.theme.text.color5};
`;

export const IconButtonStyled = styled(IconButton)`
  width: 32px;
  height: 32px;
  font-size: 20px;
  padding: 0;
`;

export const PosDeviceWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: ${props => props.theme.text.color10};
  margin-top: 4px;
  padding: 0 8px;
`;

export const PosDeviceNameStyled = styled.span`
  letter-spacing: -0.17px;
`;

export const PosEventTimeStyled = styled.span`
  letter-spacing: -0.2px;
`;

export const PosChannelWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: ${props => props.theme.text.color10};
  margin-top: 4px;
  padding: 0 8px;
`;

export const PosChannelsStyled = styled.div`
  display: flex;
`;

export const PosChannelStyled = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${props => props.theme.background.color11};
  color: ${props => props.theme.text.color8};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  ${props => props.isEtc && css`
    padding-bottom: 6px;
  `}
`;

export const ReactTooltipStyled = styled(ReactTooltip)`
  background-color: #555 !important;
  padding: 5px !important;
  &.place-top {
    &:after {
      border-top: none !important;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom: none !important;
    }
  }
`;

export const TooltipSpanStyled = styled.span`
  font-family: NotoSans;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  padding: 0px;
  white-space: pre;
`;

export const PosDataWrapperStyled = styled.div`
  border: 1px solid #e9e9e9;
  margin-top: 10px;
  margin-bottom: 16px;
  background-color: ${props => props.theme.background.color1};
  display: flex;
`;

export const PosDataStyled = styled.div`
  font-size: 12px;
  white-space: pre;
  overflow-x: scroll;
  font-family: GulimChe, sans-serif;
  background-color: ${props => props.theme.background.color8};
  color: #000;
  flex: 1;
`;
