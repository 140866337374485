import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StatusPopupActions } from 'store/actionCreators';

class StatusPopupContainer extends React.Component {
  state = {
    renewalTime: '',
  };

  componentDidMount() {
    StatusPopupActions.getStatus();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
    && JSON.stringify(nextState) === JSON.stringify(this.state)
    ) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const {
      getStatusTime,
    } = this.props;
    const {
      renewalTime,
    } = this.state;

    if (!renewalTime || prevProps.getStatusTime !== getStatusTime) {
      this.onUpdate({
        renewalTime: getStatusTime.toLocaleString(
          'en-US',
          {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
          },
        ),
      });
    }
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  onRefresh = () => {
    StatusPopupActions.getStatus();
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

StatusPopupContainer.defaultProps = { };

StatusPopupContainer.propTypes = {
  render: PropTypes.func.isRequired,
  getStatusTime: PropTypes.instanceOf(Object).isRequired,
};

export default connect(
  state => ({
    getStatusTime: state.statusPopupModule.get('getStatusTime'),
  }),
)(StatusPopupContainer);
