import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { LiveVideoTileBarContainer } from 'containers/organisms';
import {
  OSDWrapperStyled,
  OSDLeftWrapperStyled,
  OSDRightWrapperStyled,
  ChannelNameStyled,
  EventIconStyled,
  RecStatusStyled,
} from './LiveVideoTileBarStyled';

class LiveVideoTileBar extends React.PureComponent {
  render() {
    const {
      channelName,
      // sdFail,
      // sdFull,
      motionDetection,
      defocusDetection,
      ivaDetection,
      faceDetection,
      tampering,
      networkAlarmInput,
      recorderAlarmInput,
      posEventDetection,
      mic,
      pcRecord,
    } = this.props;
    return (
      <OSDWrapperStyled>
        <OSDLeftWrapperStyled>
          {
            pcRecord && (
              <RecStatusStyled className="wni wni-rec-status" />
            )
          }
          <ChannelNameStyled>{channelName}</ChannelNameStyled>
        </OSDLeftWrapperStyled>
        <OSDRightWrapperStyled>
          {/* {sdFail && <EventIconStyled className="wni wni-event-sdcard-over" />}
          {sdFull && <EventIconStyled className="wni wni-event-sdcard-full" />} */}
          {motionDetection && <EventIconStyled className="wni wni-event-motion" />}
          {ivaDetection && <EventIconStyled className="wni wni-event-iva" />}
          {defocusDetection && <EventIconStyled className="wni wni-event-defoucs" />}
          {faceDetection && <EventIconStyled className="wni wni-event-face" />}
          {tampering && <EventIconStyled className="wni wni-event-tampering" />}
          {(networkAlarmInput || recorderAlarmInput) && <EventIconStyled className="wni wni-event-sensor" />}
          {posEventDetection && <EventIconStyled className="wni wni-event-textevent" />}
          {mic && <EventIconStyled className="wni wni-mic" />}
        </OSDRightWrapperStyled>
      </OSDWrapperStyled>
    );
  }
}

LiveVideoTileBar.defaultProps = {
  channelName: '',
  // sdFail: false,
  // sdFull: false,
  motionDetection: false,
  defocusDetection: false,
  ivaDetection: false,
  faceDetection: false,
  tampering: false,
  networkAlarmInput: false,
  recorderAlarmInput: false,
  mic: false,
  pcRecord: false,
};

LiveVideoTileBar.propTypes = {
  channelName: PropTypes.string,
  // sdFail: PropTypes.bool,
  // sdFull: PropTypes.bool,
  motionDetection: PropTypes.bool,
  defocusDetection: PropTypes.bool,
  ivaDetection: PropTypes.bool,
  faceDetection: PropTypes.bool,
  tampering: PropTypes.bool,
  networkAlarmInput: PropTypes.bool,
  recorderAlarmInput: PropTypes.bool,
  posEventDetection: PropTypes.bool.isRequired,
  mic: PropTypes.bool,
  pcRecord: PropTypes.bool,
};

export default withContainer(LiveVideoTileBarContainer, LiveVideoTileBar);
