import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { Map } from 'immutable';
import { PTZTabContainer } from 'containers/organisms';
import {
  PTZFocusZoomController,
  PTZPreset,
  PTZSwing,
  PTZGroup,
  PTZPresetTour,
  PTZPresetTrace,
  PTZAuxController,
} from 'components/organisms';
import { MenuTabs } from 'components/molecules';
import { getLanguage } from 'util/lib';
import {
  Container,
  DisableMask,
  ZoomFocusWrapper,
  TabWrapper,
  TabIconStyled,
  AuxWrapper,
  CustomReactTooltip,
  TooltipSpan,
} from './PTZTabStyled';

const PTZTab = ({
  selectedChannel,
  onDragStart,
  onDragOver,
  viewModeType,
}) => {
  let channel = selectedChannel.get('channel');
  const imageInfo = selectedChannel.get('imageInfo');
  const ptzInfo = selectedChannel.get('ptzInfo');

  channel = typeof channel !== 'undefined' ? channel - 1 : channel;
  const tabData = [
    {
      key: 'tab_preset',
      header: (
        <div>
          <TabIconStyled
            data-tip="preset"
            data-for="preset"
            className="wni wni-ptz-preset"
          />
          <CustomReactTooltip
            id="preset"
            type="light"
            place="top"
            effect="float"
            delayShow={500}
            getContent={() => (
              <TooltipSpan>{getLanguage('lang_preset')}</TooltipSpan>
            )}
          />
        </div>
      ),
      component: (
        <PTZPreset currentChannel={channel + 1} ptzInfo={ptzInfo} />
      ),
    },
    {
      key: 'tab_swing',
      header: (
        <div>
          <TabIconStyled
            data-tip="swing"
            data-for="swing"
            className="wni wni-ptz-swing"
          />
          <CustomReactTooltip
            id="swing"
            type="light"
            place="top"
            effect="float"
            delayShow={500}
            getContent={() => (
              <TooltipSpan>{getLanguage('lang_swing')}</TooltipSpan>
            )}
          />
        </div>
      ),
      component: (
        <PTZSwing currentChannel={channel + 1} ptzInfo={ptzInfo} />
      ),
    },
    {
      key: 'tab_group',
      header: (
        <div>
          <TabIconStyled
            data-tip="group"
            data-for="group"
            className="wni wni-ptz-group"
          />
          <CustomReactTooltip
            id="group"
            type="light"
            place="top"
            effect="float"
            delayShow={500}
            getContent={() => (
              <TooltipSpan>{getLanguage('lang_group')}</TooltipSpan>
            )}
          />
        </div>
      ),
      component: (
        <PTZGroup currentChannel={channel + 1} ptzInfo={ptzInfo} />
      ),
    },
    {
      key: 'tab_tour',
      header: (
        <div>
          <TabIconStyled
            data-tip="tour"
            data-for="tour"
            className="wni wni-ptz-tour"
          />
          <CustomReactTooltip
            id="tour"
            type="light"
            place="top"
            effect="float"
            delayShow={500}
            getContent={() => (
              <TooltipSpan>{getLanguage('lang_tour')}</TooltipSpan>
            )}
          />
        </div>
      ),
      component: (
        <PTZPresetTour currentChannel={channel + 1} ptzInfo={ptzInfo} />
      ),
    },
    {
      key: 'tab_trace',
      header: (
        <div>
          <TabIconStyled
            data-tip="trace"
            data-for="trace"
            className="wni wni-ptz-trace"
          />
          <CustomReactTooltip
            id="trace"
            type="light"
            place="top"
            effect="float"
            delayShow={500}
            getContent={() => (
              <TooltipSpan>{getLanguage('lang_trace')}</TooltipSpan>
            )}
          />
        </div>
      ),
      component: (
        <PTZPresetTrace currentChannel={channel + 1} ptzInfo={ptzInfo} />
      ),
    },
  ];

  return (
    <Container
      id="ptztab_wrapper"
      draggable="true"
      onDragOver={onDragOver}
      onDragStart={onDragStart}
    >
      <DisableMask id="ptz_disable_mask" />
      <ZoomFocusWrapper>
        <PTZFocusZoomController
          currentChannel={channel + 1}
          imageInfo={imageInfo}
          ptzInfo={ptzInfo}
          viewModeType={viewModeType}
        />
      </ZoomFocusWrapper>
      <AuxWrapper>
        <PTZAuxController
          currentChannel={channel + 1}
          ptzInfo={ptzInfo}
        />
      </AuxWrapper>
      <TabWrapper>
        <MenuTabs tabData={tabData} />
      </TabWrapper>
    </Container>
  );
};

PTZTab.propTypes = {
  selectedChannel: PropTypes.instanceOf(Map),
  onDragStart: PropTypes.func,
  onDragOver: PropTypes.func,
  viewModeType: PropTypes.string,
};

PTZTab.defaultProps = {
  selectedChannel: Map({}),
  onDragStart: () => {},
  onDragOver: () => {},
  viewModeType: undefined,
};

export default withContainer(PTZTabContainer, PTZTab);
