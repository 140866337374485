import React from 'react';
import PropTypes from 'prop-types';
import { HeaderContainer } from 'containers/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import {
  HeaderNavi,
  HeaderUtil,
  AlarmCenter,
} from 'components/organisms';
import WhiteThemeLogo from 'util/static/images/wisenet_logo.svg';
import BlackThemeLogo from 'util/static/images/wisenet_logo_black_theme.svg';
import {
  HeaderWrapperStyled,
  LogoWrapperStyled,
  CenterWrapperStyled,
  AlarmAndFuncWrapperStyled,
  AlarmWrapperStyled,
} from './HeaderStyled';

const Header = ({
  theme,
  isFullscreen,
}) => (
  <HeaderWrapperStyled
    isFullscreen={isFullscreen}
  >
    <LogoWrapperStyled>
      <img src={theme === 'white' ? WhiteThemeLogo : BlackThemeLogo} alt="Wisenet" />
    </LogoWrapperStyled>
    <CenterWrapperStyled>
      <HeaderNavi />
      <AlarmAndFuncWrapperStyled>
        <AlarmWrapperStyled>
          <AlarmCenter />
        </AlarmWrapperStyled>
        <HeaderUtil />
      </AlarmAndFuncWrapperStyled>
    </CenterWrapperStyled>
  </HeaderWrapperStyled>
);

Header.propTypes = {
  theme: PropTypes.string.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
};

export default withContainer(HeaderContainer, Header);
