import React from 'react';
import PropTypes from 'prop-types';

class ResizableAccordionContainer extends React.Component {
  state = {
    height: [],
  };

  componentDidMount() {
    const { items, resize } = this.props;
    if (resize) {
      const height = items.map(() => 'auto');
      this.onUpdate({
        height,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { items: prevItems } = prevProps;
    const { items, resize } = this.props;
    if (prevItems.length !== items.length && resize) {
      const height = items.map(() => 'auto');
      this.onUpdate({
        height,
      });
    }
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  onResize = (e, ref, selectedIndex) => {
    const { height } = this.state;
    const newHeight = height.map((currentHeight, index) => {
      if (selectedIndex === index) {
        return ref.style.height;
      }
      return currentHeight;
    });
    this.setState({
      height: newHeight,
    });
  }

  onChanged = e => {
    const { onChanged } = this.props;
    if (onChanged) {
      onChanged(e);
    }
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

ResizableAccordionContainer.defaultProps = {
  resize: false,
  onChanged: () => {},
};

ResizableAccordionContainer.propTypes = {
  render: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(Array).isRequired,
  resize: PropTypes.bool,
  onChanged: PropTypes.func,
};

export default ResizableAccordionContainer;
