import React from 'react';
import PropTypes from 'prop-types';
import UmpInfomationType from 'wisenet-ui/util/static/constants/umpPlayer/umpInfomationType';

// Live, Playback 공통으로 사용하는 타일 기능 정의
class TileContainer extends React.Component {
  changeTileState = 0;

  borderValue = 1 * 2;

  keepSoundUid = false;

  state = {
    umpPlayer: null,
    resolution: {
      width: 400,
      height: 300,
    },
  };

  handleFunctionMode = {};

  componentDidMount() {
    this.handleFunctionMode = {
      [UmpInfomationType.CAPTURE]: this.captureFunc,
      [UmpInfomationType.SOUND]: this.soundFunc,
      [UmpInfomationType.ROTATE]: this.rotateFunc,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      tileControlWork: prevTileControlWork,
      sound: prevSound,
    } = prevProps;

    const { tileControlWork, sound } = this.props;
    const { umpPlayer } = this.state;
    // tile을 통해서 내려오는 control을 통합
    if (
      JSON.stringify(prevTileControlWork) !== JSON.stringify(tileControlWork)
    ) {
      let changeValue;
      Object.entries(tileControlWork).map(([key, value]) => {
        if (value && prevTileControlWork[key] !== value) {
          changeValue = key;
        }
        return null;
      });
      if (changeValue && this.handleFunctionMode[changeValue]) {
        this.handleFunctionMode[changeValue]();
      }
    } else if (prevSound === true && sound === false) {
      this.keepSoundUid = true;
      umpPlayer.mute();
    }
  }

  componentWillUnmount() {
    const { umpPlayer } = this.state;
    if (umpPlayer) {
      if (!umpPlayer.isMute()) {
        this.keepSoundUid = true;
        umpPlayer.mute();
      }
      umpPlayer.stop();
    }
  }

  captureFunc = () => {
    const { umpPlayer } = this.state;

    if (umpPlayer) {
      umpPlayer.capture();
    }
  };

  onCapture = evnet => {
    this.reciveData(UmpInfomationType.CAPTURE, evnet);
  };

  soundFunc = () => {
    const { umpPlayer } = this.state;

    if (umpPlayer) {
      const { sound } = this.props;
      this.keepSoundUid = false;
      if (sound) {
        umpPlayer.mute();
      } else {
        umpPlayer.unmute();
        umpPlayer.setAudioVolume(10);
      }
    }
  };

  rotateFunc = () => {
    const { umpPlayer } = this.state;

    if (umpPlayer) {
      const { rotate } = this.props;
      const rotateDeg = (rotate + 90) % 360;
      this.reciveData(UmpInfomationType.ROTATE, rotateDeg);
    }
  };

  setUmpPlayer = ump => {
    const umpPlayer = ump;

    this.setState({
      umpPlayer,
    });
  };

  onStatechange = event => {
    const { onStatechange } = this.props;
    onStatechange(event);
    d.log('[TileContainer] onStatechange : event(', event.detail, ')');
    d.log('[TileContainer] onStatechange : event.detail.readyState(', event.detail.readyState, ')');
    if (event.detail) {
      // 영상 상태가 되지 않았을 때 다른 동작 수행을 하지 않도록 막기 위해서 넣음.
      this.changeTileState = event.detail.readyState;
      switch (event.detail.readyState) {
        case 0:
          this.reciveData(UmpInfomationType.START, false);
          break;
        case 1:
          this.reciveData(UmpInfomationType.START, true);
          break;
        default:
          break;
      }
    }
    // if (!this.isPlay) {
    //   // 초기 값을 설정하기 위한 부분
    //   this.isPlay = true;
    //   const { umpPlayer } = this.state;
    //   console.log('event umpPlayer.isMute', !umpPlayer.isMute());
    //   this.reciveData(UmpInfomationType.SOUND, !umpPlayer.isMute());
    // }
  };

  onResize = event => {
    const { onResize } = this.props;
    onResize(event);
    // console.log('resize', event);
    this.profileInfo(UmpInfomationType.RESOLUTION, {
      resolution: {
        ...event.detail,
      },
    });
    this.setState({
      resolution: {
        ...event.detail,
      },
    });
  };

  onChangePlayermode = event => {
    const { onChangePlayermode } = this.props;
    onChangePlayermode(event);
    // console.log('playmode', event);
    this.profileInfo(UmpInfomationType.TAG_TYPE, {
      tagType: event.detail.mode,
    });
  };

  onStatistics = event => {
    const { onStatistics, channelInfoDisplay } = this.props;
    const { isVisible, isDevelopMode } = channelInfoDisplay;
    onStatistics(event);
    if (event.detail.statistics.type === 'rtp' && event.detail.statistics.media === 'video') {
      const eventCodec = event.detail.statistics.codec;
      const { codec } = this.props;
      if (codec !== eventCodec) {
        // console.log('onStatistics', event);
        if (isVisible) {
          this.profileInfo(UmpInfomationType.CODEC, {
            codec: eventCodec,
          });
        }
      }
    }
    if (isDevelopMode) {
      this.profileInfo(UmpInfomationType.FPS, {
        bps: event.detail.statistics.bps,
        fps: event.detail.statistics.fps,
      });
    }
  };

  // tileBar를 통해서 제어할 때 값을 올려주는 용도
  reciveData = (type, data) => {
    const { onTileInfo } = this.props;
    onTileInfo(type)({
      onData: {
        [type]: data,
      },
      ...this.props,
    });
  };

  // channelInfo 용으로 조금 다르게 설정됨
  profileInfo = (type, data) => {
    const { onTileInfo } = this.props;
    if (onTileInfo) {
      onTileInfo(type)({
        onData: data,
        ...this.props,
      });
    }
  };

  onError = event => {
    const { onTileInfo } = this.props;
    // onError(event);

    if (onTileInfo) {
      if (event.errorCode) {
        switch (event.errorCode) {
          case 0x0303: {
            onTileInfo(UmpInfomationType.SOUND)({
              onData: {
                soundActivation: false,
                [UmpInfomationType.SOUND]: false,
                error: event,
              },
              ...this.props,
            });
            break;
          }
          default:
            break;
        }
      }
    }
  };

  onSuccess = event => {
    const { umpPlayer } = this.state;
    const { onTileInfo } = this.props;

    switch (event.name) {
      case 'mute':
      case 'unmute': {
        if (!this.keepSoundUid) {
          onTileInfo(UmpInfomationType.SOUND)({
            onData: {
              [UmpInfomationType.SOUND]: !umpPlayer.isMute(),
            },
            ...this.props,
          });
        }
        break;
      }
      default:
        break;
    }
  }

  render() {
    const { render, width, height } = this.props;

    return render({
      ...this.props,
      ...this.state,
      ...this,
      width: width - this.borderValue,
      height: height - this.borderValue,
    });
  }
}

TileContainer.defaultProps = {
  onStatechange: () => {},
  onTileInfo: () => (() => {}),
  onResize: () => {},
  onChangePlayermode: () => {},
  onStatistics: () => {},
  tileControlWork: {},
  sound: false,
  rotate: 0,
  width: 300,
  height: 300,
  channelInfoDisplay: {
    isVisible: false,
    isDevelopMode: false,
  },
  codec: '',
  // onError: () => {},
};

TileContainer.propTypes = {
  render: PropTypes.func.isRequired,
  onStatechange: PropTypes.func,
  onTileInfo: PropTypes.func, // ump로 얻어야 하는 데이터를 올리는 콜백함수
  onResize: PropTypes.func,
  onChangePlayermode: PropTypes.func,
  onStatistics: PropTypes.func,
  sound: PropTypes.bool,
  rotate: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  codec: PropTypes.string,
  tileControlWork: PropTypes.shape({
    [UmpInfomationType.CAPTURE]: PropTypes.bool,
    [UmpInfomationType.SOUND]: PropTypes.bool,
    [UmpInfomationType.ROTATE]: PropTypes.bool,
  }),
  channelInfoDisplay: PropTypes.shape({
    isVisible: PropTypes.bool,
    isDevelopMode: PropTypes.bool,
  }),
  // onError: PropTypes.func,
};

export default TileContainer;
