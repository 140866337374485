import styled, { keyframes, css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { Rnd } from 'react-rnd';
import { IconButton } from 'wisenet-ui/components/atoms';

const fadein = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

export const AccordionItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid;
  ${props => props.maxHeight && css`
    max-height: calc(100% - 44px);
  `}
  ${props => props.isOpen && css`
    &:nth-last-child(1) {
      border-bottom: none;
    }
  `}
  ${props => !props.isOpen && css`
    border-bottom: none;
  `}
`;

export const AccordionItemHeaderStyled = styled.div`
  flex-basis: 44px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 0 20px;
  padding-left: 20px;
  padding-right: 14px;
  user-select: none;
  justify-content: space-between;
  border-bottom: 1px solid;
  position: relative;
`;

export const HeaderLeftStyled = styled.div`
  font-size: 16px;
`;

export const HeaderRightStyled = styled.div`
  display: flex;
`;

export const HeaderButtonStyled = styled(IconButton)`
  width: 32px;
  height: 42px;
  padding: 0;
  cursor: pointer;
  &:hover {
    color: #f37321;
  }
  &:before , &:after {
    content: "";
    transition: all 0.25s ease-in-out;
    position: absolute;
    background-color: ${props => props.theme.text.color5};
    width: 1px;
    height: 9px;
  }
  &:before {
    transform: translate( -4px , 2px ) rotate( 45deg );
  }
  &:after {
    transform: translate( 2px , 2px ) rotate( -45deg );
  }
  ${props => props.isOpen && css`
    &:before {
      transform: translate( 2px , 2px ) rotate( 45deg );
    }
    &:after {
      transform: translate( -4px , 2px ) rotate( -45deg );
    }
  `}
`;

export const AccordionItemContentStyled = styled.div`
  ${props => !props.isOpen && css`
    display: none;
  `}
  flex: 1;
  animation: ${fadein} 0.25s ease-in;
  overflow: hidden;
  user-select: none;
  ${props => (props.resize || props.scroll) && css`
    overflow-y: auto;
    overflow-x: hidden;
  `}
  ${props => !props.scroll && css`
    overflow: hidden;
  `}
`;

export const RndStyled = styled(Rnd)`
  position: relative !important;
  transform: none !important;
  ${props => props.isdisplay === 'false' && css`
    display: none !important;
  `}
`;

export const TooltipSpan = styled.span`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  margin: 0px;
  padding: 0px
`;

export const CustomReactTooltip = styled(ReactTooltip)`
  background-color: #555 !important;
  padding: 5px !important;
  &.place-top {
    &:after {
      border-top: none !important;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom: none !important;
    }
  }
`;
