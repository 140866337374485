import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import {
  SearchTimelineActions,
  EventSearchActions,
  SearchMediaControlActions,
  LayoutListActions,
  TextSearchActions,
  DeviceInfoActions,
} from 'store/actionCreators';

class SearchPageContainer extends React.Component {
  state = {
    currentTabName: 'eventTab',
    resultFolding: false,
    isFolding: false,
  }

  defaultTabIndex = 0;

  constructor(props) {
    super(props);
    LayoutListActions.getLayoutList();
    const { liveTextEventSelect } = this.props;
    if (liveTextEventSelect && liveTextEventSelect.toJS().deviceID !== '') {
      this.defaultTabIndex = 1;
    }
  }

  componentDidMount() {
    DeviceInfoActions.getMaxChannelInfo();

    if (this.defaultTabIndex === 1) {
      this.setState({
        currentTabName: 'textTab',
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isFullscreen,
    } = this.props;

    if (prevProps.isFullscreen !== isFullscreen && isFullscreen) {
      this.onUpdate({
        resultFolding: true,
      });
    }
  }

  componentWillUnmount() {
    SearchTimelineActions.setInitSearchTimeline();
    EventSearchActions.setIniteventSearchData();
    SearchMediaControlActions.playControl({
      playMode: 'stop',
    });
    SearchMediaControlActions.setSelectTimeRange({
      useSelectTimeRange: false,
    });
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  onSelectedTab = name => {
    SearchTimelineActions.setInitSearchTimeline();
    EventSearchActions.setIniteventSearchData();
    TextSearchActions.setSearchResultInit();
    this.setState({
      currentTabName: name,
    });
  }

  onChangeFolding = type => {
    const { isFolding, resultFolding } = this.state;
    if (type === 'result') {
      this.setState({
        resultFolding: !resultFolding,
      });
    } else {
      this.setState({
        isFolding: !isFolding,
      });
    }
  }

  onMessageBoxConfirm = () => this.setState({
    isOpenMessageBox: false,
  });

  render() {
    const { render, cameraList } = this.props;
    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
        cameraList,
      },
    );
  }
}

SearchPageContainer.defaultProps = {
  liveTextEventSelect: null,
};

SearchPageContainer.propTypes = {
  render: PropTypes.func.isRequired,
  cameraList: PropTypes.instanceOf(List).isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  liveTextEventSelect: PropTypes.instanceOf(Object),
};

export default connect(
  state => ({
    cameraList: state.cameraInfoModule.get('cameraList'),
    isFullscreen: state.layoutModule.get('isFullscreen'),
    timelineFolding: state.layoutModule.get('timelineFolding'),
    liveTextEventSelect: state.textSearchModule.get('liveTextEventSelect'),
  }),
)(SearchPageContainer);
