import {
  take,
  put,
  all,
  select,
  call,
} from 'redux-saga/effects';
import {
  delay,
} from 'redux-saga';
import * as cameraInfoActions from 'store/modules/camera/cameraInfoModule';
import * as liveMediaControlActions from 'store/modules/mediaControl/liveMediaControlModule';
import { makeCameraUID } from 'wisenet-ui/util/function/make';
import staticLayoutPattern from 'wisenet-ui/util/static/constants/layoutPattern/staticLayoutPattern';

const haveEmptyLayoutCount = (layoutPageNumber, tileCameraListPage, patternTileCount) => {
  const currentPageNumberLayoutCameraList = tileCameraListPage[layoutPageNumber];
  if (currentPageNumberLayoutCameraList) {
    return {
      layoutPageTileCameraList: tileCameraListPage[layoutPageNumber],
      emptyCount: patternTileCount - currentPageNumberLayoutCameraList.length,
    };
  }
  return {
    layoutPageTileCameraList: [],
    emptyCount: patternTileCount,
  };
};

function* spreadTileCameraList(
  stateTileCameraListPage, makeCameraList, layoutPageCurrentNumber, patternTileCount,
) {
  let pageCount = Number(layoutPageCurrentNumber);
  const spreadTileCameraListObject = Object.assign(stateTileCameraListPage, {});
  while (makeCameraList.length !== 0) {
    const { layoutPageTileCameraList, emptyCount } = yield call(
      haveEmptyLayoutCount, pageCount, stateTileCameraListPage, patternTileCount,
    );
    const sliceCameraList = makeCameraList.splice(0, emptyCount);
    spreadTileCameraListObject[pageCount] = [...layoutPageTileCameraList, ...sliceCameraList];
    pageCount += 1;
  }
  return spreadTileCameraListObject;
}

function* asyncOnTileCameraListSaga() {
  while (true) {
    const action = yield take(cameraInfoActions.ON_TILE_CAMERA_LIST);
    const { selectCameraList, toItem } = action.payload;
    const moduleState = yield select(state => ({
      cameraList: state.cameraInfoModule.get('cameraList').toJS(),
      layoutPageMaxNumber: state.liveMediaControlModule.get('layoutPageInfo').get('maxNumber'),
      layoutPageCurrentNumber: state.liveMediaControlModule.get('layoutPageInfo').get('currentNumber'),
      stateTileCameraListPage: state.cameraInfoModule.toJS().tileCameraListPage,
      pattern: state.liveMediaControlModule.get('pattern'),
      backupTileCameraListPage: state.cameraInfoModule.get('backupTileCameraListPage').toJS(),
      patternTileCount: state.liveMediaControlModule.get('layoutPageInfo').get('patternTileCount'),
      backupPattern: state.liveMediaControlModule.get('backupPattern'),
    }));

    const {
      cameraList,
      layoutPageMaxNumber,
      layoutPageCurrentNumber,
      stateTileCameraListPage,
      pattern,
      patternTileCount,
    } = moduleState;

    const newSelectCameraList = selectCameraList.map(selectIndex => {
      const selectCamera = cameraList[selectIndex];
      const uid = makeCameraUID(selectCamera.channel);
      selectCamera.uid = uid;

      if (selectCameraList.length === 1 && toItem) {
        delete toItem.channel;
        return {
          ...toItem,
          ...selectCamera,
        };
      }
      return selectCamera;
    });

    if (pattern === 'SPECIAL') {
      const { backupTileCameraListPage, backupPattern } = moduleState;
      const stateTile = stateTileCameraListPage[layoutPageCurrentNumber][0];
      let findKey;
      Object.keys(backupTileCameraListPage).map(key => {
        const findTile = backupTileCameraListPage[key].find(
          backupTile => backupTile.uid === stateTile.uid,
        );
        if (findTile) {
          findKey = key;
        }
        return null;
      });

      const backupPatternLength = staticLayoutPattern[backupPattern].items.length;
      const newSelectCameraListCopy = newSelectCameraList.slice(0);

      const backupChangeTileCameraListPage = yield call(
        spreadTileCameraList,
        backupTileCameraListPage,
        newSelectCameraListCopy,
        findKey,
        backupPatternLength,
      );

      yield put(cameraInfoActions.setBackupTileCameraListPage({
        backupTileCameraListPage: backupChangeTileCameraListPage,
      }));
    }

    const tileCameraListPage = yield call(
      spreadTileCameraList,
      stateTileCameraListPage,
      newSelectCameraList,
      layoutPageCurrentNumber,
      patternTileCount,
    );
    const currentLayoutPageMaxNumber = Object.keys(tileCameraListPage).length;

    if (currentLayoutPageMaxNumber === 0) {
      tileCameraListPage[layoutPageCurrentNumber] = [];
    } else if (layoutPageMaxNumber !== currentLayoutPageMaxNumber) {
      yield put(liveMediaControlActions.setLayoutPageMaxNumber({
        layoutPageMaxNumber: currentLayoutPageMaxNumber,
      }));
    }

    yield put(cameraInfoActions.setTileCameraListPage({
      tileCameraListPage,
    }));
  }
}

function* asyncOnLayoutListSaga() {
  while (true) {
    const action = yield take(cameraInfoActions.ON_LAYOUT_LIST);
    yield put(cameraInfoActions.layoutPenddingControl({ layoutPendding: true }));
    const { layoutList, pattern } = action.payload;
    // list를 비우기 기위해서 layoutListInject 값 사용
    yield put(liveMediaControlActions.patternControl({ pattern, layoutListInject: true }));
    // layout container의 동작(생명주기)를 위해서 delay
    yield call(delay, 100);

    const moduleState = yield select(state => ({
      cameraList: state.cameraInfoModule.get('cameraList').toJS(),
    }));

    const {
      cameraList,
    } = moduleState;

    const tileCameraListPage = { 1: [] };

    layoutList.map(layoutItem => {
      const selectCamera = cameraList.find(camera => camera.channel === layoutItem.Channel);
      // camera list를 전부 가져오는 방식으로 변경되어 연결되지 않은 카메라를 포함하지 않도록 수정
      if (selectCamera && selectCamera.status !== 'Disconnected') {
        const uid = makeCameraUID(selectCamera.channel);
        selectCamera.uid = uid;
        const tilePage = tileCameraListPage[layoutItem.Page]
          ? tileCameraListPage[layoutItem.Page] : [];

        tileCameraListPage[layoutItem.Page] = [...tilePage, {
          ...selectCamera,
          ...layoutItem,
        }];
      }
      return null;
    });

    const currentLayoutPageMaxNumber = Object.keys(tileCameraListPage).length;

    yield put(cameraInfoActions.setTileCameraListPage({
      tileCameraListPage,
    }));

    yield put(liveMediaControlActions.setLayoutPageInfo({
      layoutPageMaxNumber: currentLayoutPageMaxNumber,
      layoutPageCurrentNumber: 1,
    }));

    yield put(cameraInfoActions.layoutPenddingControl({ layoutPendding: false }));
  }
}

export default function* rootCameraInfoSaga() {
  yield all([
    asyncOnTileCameraListSaga(),
    asyncOnLayoutListSaga(),
  ]);
}
