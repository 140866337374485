import {
  take,
  put,
  all,
  call,
  select,
} from 'redux-saga/effects';
import { parseString } from 'xml2js';
import { SunapiClient } from 'util/lib';
import * as deviceInfoActions from 'store/modules/deviceInfo/deviceInfoModule';
import * as langActions from 'store/modules/base/langModule';

function* asyncGetModelNameSaga() {
  while (true) {
    yield take(deviceInfoActions.GET_DEVICE_INFO);
    try {
      const uri = '/stw-cgi/system.cgi?msubmenu=deviceinfo&action=view';
      const result = yield SunapiClient.get(uri);
      yield put(deviceInfoActions.getdeviceInfoSuccess({
        deviceInfo: result.data,
      }));
    } catch (error) {
      console.log('[GET_DEVICE_INFO_FAILURE]', error);
      // yield put(deviceInfoActions.getdeviceInfoFailure(error));
    }
  }
}

/**
   * @description M4.5.1/15:00:00 ==> 4월 5주 화요일 15시 Date 정보로 변환
   */
const posixToDate = posixString => {
  try {
    const tokenSlash = posixString.split('/');
    const dateToken = tokenSlash[0].split('.');
    const timeToken = tokenSlash[1].split(':');

    const month = Number(dateToken[0].replace('M', ''));
    const weekOfMonth = Number(dateToken[1]);
    const dayOfWeek = Number(dateToken[2]);

    return {
      month,
      weekOfMonth,
      dayOfWeek,
      hours: Number(timeToken[0]),
      minutes: Number(timeToken[1]),
      seconds: Number(timeToken[2]),
    };
    // console.log(month, weekOfMonth, dayOfWeek, timeToken.join(','));
  } catch (_) {
    return null;
  }
};

function* asyncGetDateInfoSaga() {
  while (true) {
    yield take(deviceInfoActions.GET_DATE_INFO);
    try {
      const uri = '/stw-cgi/system.cgi?msubmenu=date&action=view';
      const result = yield SunapiClient.get(uri);
      yield put(deviceInfoActions.getDateInfoSuccess({
        systemDate: result.data,
      }));
    } catch (error) {
      console.log('[GET_DATE_INFO_FAILURE]', error);
    }
  }
}

function* asyncGetMaxChannelInfoSaga() {
  while (true) {
    yield take(deviceInfoActions.GET_MAXCHANNEL_INFO);
    try {
      const uri = '/stw-cgi/attributes.cgi/attributes/System/Limit/MaxChannel/';
      const result = yield SunapiClient.get(uri);
      let maxChannel = 0;
      parseString(result.data, (err, parseResult) => {
        if (!err) {
          maxChannel = parseResult.attribute.$.value;
        }
      });

      yield put(deviceInfoActions.getMaxChannelSuccess({
        maxChannel: Number(maxChannel) || 1,
      }));
    } catch (error) {
      console.log('[GET_MAXCHANNEL_FAILURE]', error);
    }
  }
}

function* asyncCheckLanguageChangeSaga() {
  while (true) {
    // deviceInfo가 변경 되었을 때 언어가 변경되면 반영하도록 하는 saga
    const action = yield take(deviceInfoActions.GET_DEVICE_INFO_SUCCESS);
    const moduleState = yield select(state => ({
      currentLanguage: state.langModule.get('currentLanguage'),
      isFirst: state.langModule.get('isFirst'),
      configRestore: state.systemInfomationModule.get('systemEvent').get('configRestore'),
    }));
    const { currentLanguage, isFirst, configRestore } = moduleState;
    const { payload: { deviceInfo: { Language } } } = action;

    if (isFirst) {
      yield put(langActions.setCurrentLanguage(
        Language,
      ));
    } else if (!configRestore && currentLanguage.key !== Language) {
      // 언어가 변경되면 페이지 새로고침
      window.location.reload();
    }
  }
}

const calcTimeZone = timezone => {
  const splitDate = timezone.split(':');
  let numberTimezone = splitDate[0];

  if (splitDate.length === 2) {
    // 분단위가 있는 경우 60분을 기준으로 소수점으로 숫자 표현
    const floatDate = String(Number(splitDate[1]) / 60).split('.')[1];
    numberTimezone += `.${floatDate}`;
  }
  return Number(numberTimezone);
};

function* asyncSetDateInfoSaga() {
  while (true) {
    // deviceInfo가 변경 되었을 때 언어가 변경되면 반영하도록 하는 saga
    const action = yield take(deviceInfoActions.GET_DATE_INFO_SUCCESS);
    const { systemDate } = action.payload;
    // NTPLastUpdatedTime=2019-05-07 13:56:52
    // LocalTime=2019-05-07 13:56:52
    // UTCTime=2019-05-07 04:56:52
    // SyncType=Manual
    // NTPURLList=203.248.240.140
    // NTPStatus=Fail
    // DSTEnable=True
    // POSIXTimeZone=STWT-9STWST,M4.5.1/15:00:00,M5.1.5/10:00:00

    // STWT와 STWST 사이에 있는 값이 타임존 값
    // 타임존 값이 seoul 의 경우 GMT+9 이면 해당 timezone 값은 -9로 표시됨
    const timeZone = systemDate.POSIXTimeZone.substring(
      systemDate.POSIXTimeZone.indexOf('STWT') + 4,
      systemDate.POSIXTimeZone.lastIndexOf('STWST'),
    );
    const dstStartInfo = yield call(posixToDate, systemDate.POSIXTimeZone.split(',')[1]);
    const dstEndInfo = yield call(posixToDate, systemDate.POSIXTimeZone.split(',')[2]);
    const dstInfo = {
      dstStartInfo,
      dstEndInfo,
    };

    yield put(deviceInfoActions.setDateInfo({
      dateInfo: systemDate,
      timeZone: calcTimeZone(timeZone),
      dstInfo,
    }));
  }
}

export default function* rootHeaderSaga() {
  yield all([
    asyncGetModelNameSaga(),
    asyncGetDateInfoSaga(),
    asyncGetMaxChannelInfoSaga(),
    asyncCheckLanguageChangeSaga(),
    asyncSetDateInfoSaga(),
  ]);
}
