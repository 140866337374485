import styled, { keyframes } from 'styled-components';
import { ToastBox } from 'wisenet-ui/components/atoms';

export const WrapperStyled = styled.div`
  width: ${props => (typeof props.width === 'number' ? `${props.width}px` : props.width)};

  position: absolute;
  z-index: 999;
  top: ${props => props.position.top};
  bottom: ${props => props.position.bottom};
  left: ${props => props.position.left};
  right: ${props => props.position.right};

  text-align: center;
`;

const topAnimation = keyframes`
  0% { opacity: 0; transform: translateY(-100%); }
  7% { opacity: 1; transform: none; }
  93% { opacity: 1; transform: none; }
  100% { opacity: 0; transform: translateY(-100%); }
`;

const bottomAnimation = keyframes`
  0% { opacity: 0; transform: translateY(100%); }
  7% { opacity: 1; transform: none; }
  93% { opacity: 1; transform: none; }
  100% { opacity: 0; transform: translateY(100%); }
`;

export const ToastBoxStyled = styled(ToastBox)`
  width: 100%;
  animation: ${props => (
    props.posAnimation === 'top'
      ? topAnimation
      : bottomAnimation
  )} ${props => props.timeoutS}s ease-in-out;
`;
