import React from 'react';
import PropTypes from 'prop-types';
import ListSelectStyled from './ListSelectStyled';

const ListSelect = ({
  listData,
  ...rest
}) => {
  let selectedValue;
  const options = listData.map(ldata => {
    const { data, text, selected } = ldata;
    if (selected) {
      selectedValue = data;
    }
    return (
      <option
        key={`${data}`}
        value={data}
      >
        {text}
      </option>
    );
  });
  return (
    <ListSelectStyled
      value={selectedValue}
      {...rest}
    >
      {options}
    </ListSelectStyled>
  );
};

ListSelect.propTypes = {
  listData: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ListSelect;
