import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SearchMediaControlBarContainer } from 'containers/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { PopupContent } from 'components/molecules';
import { StatusPopup, ManualBackupPopup } from 'components/organisms';
import umpPlayMode from 'wisenet-ui/util/static/constants/umpPlayer/umpPlayMode';
import { MediaControlBar } from 'wisenet-ui/components/organisms';
import { getLanguage } from 'util/lib';
import {
  StatusPopupStyled,
  PopupButtonStyled,
  ExportPopupStyled,
} from './SearchMediaControlBarStyled';

class SearchMediaControlBar extends PureComponent {
  render() {
    const {
      onClick,
      playSpeedValue,
      playMode,
      playSpeedIndex,
      isFullscreen,
      timelineFolding,
      className,
      playSpeenDropDownVisible,
      onChangePlaySpeenDropDownVisible,
      showStatusPopup,
      onChangeStatusPopupVisible,
      showExportPopup,
      onExportCancel,
      setTimeRangeToastRef,
      showExportIcon,
    } = this.props;

    return (
      <div
        className={className}
      >
        <ExportPopupStyled
          isOpen={showExportPopup}
          shouldCloseOnOverlayClick
        >
          <ManualBackupPopup
            flag="search"
            onCancel={onExportCancel}
          />
        </ExportPopupStyled>
        <StatusPopupStyled
          isOpen={showStatusPopup}
        >
          <PopupContent
            title="lang_all_camera_status"
            hasTopCloseButton
            bottomButtons={[
              (
                <PopupButtonStyled key="statusPopupCloseButton" onClick={onChangeStatusPopupVisible}>{getLanguage('lang_ok')}</PopupButtonStyled>
              ),
            ]}
            onClosePopup={onChangeStatusPopupVisible}
          >
            <StatusPopup />
          </PopupContent>
        </StatusPopupStyled>
        <MediaControlBar
          type={umpPlayMode.PLAYBACK}
          playSpeedValue={playSpeedValue}
          onClick={onClick}
          playMode={playMode}
          playSpeedIndex={playSpeedIndex}
          isFullscreen={isFullscreen}
          timelineFolding={timelineFolding}
          playSpeenDropDownVisible={playSpeenDropDownVisible}
          onChangePlaySpeenDropDownVisible={onChangePlaySpeenDropDownVisible}
          setTimeRangeToastRef={setTimeRangeToastRef}
          showExportIcon={showExportIcon}
        />
      </div>
    );
  }
}

SearchMediaControlBar.defaultProps = {
  className: null,
  showExportIcon: false,
};

SearchMediaControlBar.propTypes = {
  playSpeedValue: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  playMode: PropTypes.string.isRequired,
  playSpeedIndex: PropTypes.number.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  timelineFolding: PropTypes.bool.isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  playSpeenDropDownVisible: PropTypes.bool.isRequired,
  onChangePlaySpeenDropDownVisible: PropTypes.func.isRequired,
  showStatusPopup: PropTypes.bool.isRequired,
  showExportPopup: PropTypes.bool.isRequired,
  onChangeStatusPopupVisible: PropTypes.func.isRequired,
  onExportCancel: PropTypes.func.isRequired,
  setTimeRangeToastRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]).isRequired,
  showExportIcon: PropTypes.bool,
};

export default withContainer(SearchMediaControlBarContainer, SearchMediaControlBar);
