import React from 'react';
// import PropTypes from 'prop-types';

const SearchOptionSmartTab = () => (
  <div>
    {'SearchOptionSmartTab'}
  </div>
);

SearchOptionSmartTab.propTypes = {
};

export default SearchOptionSmartTab;
