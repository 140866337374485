import React from 'react';
import PropTypes from 'prop-types';
import { PTZTourContainer } from 'containers/organisms';
import { Table } from 'wisenet-ui/components/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { getLanguage } from 'util/lib';
import {
  Container,
  TourDisableMask,
  TableWrapper,
} from './PTZTourStyled';

const PTZPresetTour = ({
  onChangeData,
  currentTourList,
}) => {
  const tourHeader = [
    {
      Header: getLanguage('lang_number'),
      accessor: 'no',
      sortable: true,
    },
    {
      Header: getLanguage('lang_name'),
      accessor: 'name',
      sortable: true,
    },
  ];

  return (
    <Container id="tour_wrapper">
      <TourDisableMask id="tour_disable_mask" />
      <TableWrapper>
        { currentTourList !== null
        && currentTourList !== false
        && (
          <Table
            onChangeData={onChangeData}
            scroll
            header={tourHeader}
            data={currentTourList}
            selectRow
            pageSize={9}
          />
        )
      }
      </TableWrapper>
    </Container>
  );
};

PTZPresetTour.propTypes = {
  currentTourList: PropTypes.arrayOf(PropTypes.any),
  onChangeData: PropTypes.func,
};

PTZPresetTour.defaultProps = {
  currentTourList: [],
  onChangeData: null,
};

export default withContainer(PTZTourContainer, PTZPresetTour);
