import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextSearchActions } from 'store/actionCreators';
import { getLanguage } from 'util/lib';

class LiveEventFilterContainer extends React.Component {
  eventFilterList = [];

  state = {
    willExportTree: false,
    posConfigList: [],
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      posConfigList: nextPosConfigList,
      realTimeFilteredPosDeviceList: nextRealTimeFilteredPosDeviceList,
    } = nextProps;
    const {
      posConfigList,
      realTimeFilteredPosDeviceList,
    } = this.props;
    const {
      willExportTree: nextStatewillExportTree,
    } = nextState;
    const {
      willExportTree,
    } = this.state;

    if (JSON.stringify(nextStatewillExportTree) !== JSON.stringify(willExportTree)) {
      return true;
    }

    if (JSON.stringify(nextPosConfigList) === JSON.stringify(posConfigList)
      && JSON.stringify(nextRealTimeFilteredPosDeviceList)
        === JSON.stringify(realTimeFilteredPosDeviceList)) {
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    const {
      posConfigList: prevPosConfigList,
      realTimeFilteredPosDeviceList: prevRealTimeFilteredPosDeviceList,
    } = prevProps;
    const {
      posConfigList,
      realTimeFilteredPosDeviceList,
    } = this.props;

    if (JSON.stringify(prevPosConfigList) !== JSON.stringify(posConfigList)
      || JSON.stringify(prevRealTimeFilteredPosDeviceList)
        !== JSON.stringify(realTimeFilteredPosDeviceList)) {
      this.makeListFunc(posConfigList, realTimeFilteredPosDeviceList);
    }
  }

  makeListFunc = (posConfigList, realTimeFilteredPosDeviceList) => {
    if (realTimeFilteredPosDeviceList.length === 0
      || realTimeFilteredPosDeviceList[0] === 'textEvent'
      || realTimeFilteredPosDeviceList[0] === undefined) {
      this.eventFilterList = [
        {
          id: 'textEvent',
          name: getLanguage('lang_text'),
          checked: 1,
          data: {},
          expanded: true,
          children: this.convertDevice2TreeData(posConfigList, 1),
        },
      ];
    } else if (realTimeFilteredPosDeviceList[0] === 'Nothing') {
      this.eventFilterList = [
        {
          id: 'textEvent',
          name: getLanguage('lang_text'),
          checked: 0,
          data: {},
          expanded: true,
          children: this.convertDevice2TreeData(posConfigList, 0),
        },
      ];
    } else {
      this.eventFilterList = [
        {
          id: 'textEvent',
          name: getLanguage('lang_text'),
          checked: 2,
          data: {},
          expanded: true,
          children: this.convertDevice2FilteredTreeData(
            posConfigList,
            realTimeFilteredPosDeviceList,
          ),
        },
      ];
    }
  }

  returnCheckedTreeItem = treeData => {
    const items = treeData.map(tItem => {
      const treeItem = tItem;
      if (treeItem.checked === 1 || treeItem.checked === 2) {
        return treeItem.id;
      }
      return null;
    });
    const result = items.filter(this.checkValue);
    return result;
  }

  checkValue = data => data !== null;

  convertDevice2TreeData = (posConfigList, setCheckValue) => {
    const list = posConfigList.map(item => ({
      id: item.DeviceName,
      name: item.DeviceName,
      data: {
        extraData: item.ChannelIDList.length > 0 ? item.ChannelIDList.reduce((arr, channel) => {
          if (channel === 'None') {
            return arr;
          }
          if (channel === 'All') {
            const { maxChannel } = this.props;
            for (let i = 0; i < maxChannel; i += 1) {
              arr.push(i + 1);
            }
            return arr;
          }
          arr.push(Number(channel) + 1);
          return arr;
        }, []) : [],
        deviceId: item.DeviceID,
      },
      checked: setCheckValue,
      parents: ['textEvent'],
      icon: {
        type: 'circle',
        color: 'red',
      },
    }));
    return list;
  }

  convertDevice2FilteredTreeData = (posConfigList, realTimeFilteredPosDeviceList) => {
    const list = posConfigList.map(item => ({
      id: item.DeviceName,
      name: item.DeviceName,
      data: {
        extraData: item.ChannelIDList.length > 0 ? item.ChannelIDList.reduce((arr, channel) => {
          if (channel === 'None') {
            return arr;
          }
          if (channel === 'All') {
            const { maxChannel } = this.props;
            for (let i = 0; i < maxChannel; i += 1) {
              arr.push(i + 1);
            }
            return arr;
          }
          arr.push(Number(channel) + 1);
          return arr;
        }, []) : [],
        deviceId: item.DeviceID,
      },
      checked: this.setEachCheckedValue(item.DeviceName, realTimeFilteredPosDeviceList),
      parents: ['textEvent'],
      icon: {
        type: 'circle',
        color: 'red',
      },
    }));
    return list;
  }

  setEachCheckedValue = (deviceName, realTimeFilteredPosDeviceList) => {
    let result = 0;
    for (let i = 0; i < realTimeFilteredPosDeviceList[0].length; i += 1) {
      if (realTimeFilteredPosDeviceList[0][i] === deviceName) {
        result = 1;
      }
    }
    return result;
  }

  setApply = willExportTree => {
    this.setState({
      willExportTree,
    });
  }

  applyEventFilter = treeDatas => {
    const { onCloseFilter } = this.props;
    const filterData = treeDatas.map(tItem => {
      const treeItem = tItem;
      if (treeItem.checked === 1) {
        return treeItem.id;
      }
      if (treeItem.checked === 2) {
        return this.returnCheckedTreeItem(treeItem.children);
      }
      return null;
    });
    const result = filterData.filter(this.checkValue);

    TextSearchActions.setRealTimePOSDeviceList(result.length === 0 ? ['Nothing'] : result);
    onCloseFilter();
  }

  setCancel = () => {
    const { onCloseFilter } = this.props;
    onCloseFilter();
  }

  render() {
    const { render } = this.props;
    const { posConfigList, realTimeFilteredPosDeviceList } = this.props;

    this.makeListFunc(posConfigList, realTimeFilteredPosDeviceList);

    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

LiveEventFilterContainer.defaultProps = {
  posConfigList: [{
    DeviceName: '',
    Enable: false,
    Port: 0,
    EventPlaybackStartTime: 0,
    EncodingType: '',
    ReceiptEnd: '',
    ReceiptStart: '',
    ChannelIDList: [],
  }],
  realTimeFilteredPosDeviceList: [],
  maxChannel: 0,
};

LiveEventFilterContainer.propTypes = {
  render: PropTypes.func.isRequired,
  posConfigList: PropTypes.arrayOf(PropTypes.shape({
    DeviceName: PropTypes.string,
    Enable: PropTypes.bool,
    Port: PropTypes.number,
    EventPlaybackStartTime: PropTypes.number,
    EncodingType: PropTypes.string,
    ReceiptEnd: PropTypes.string,
    ReceiptStart: PropTypes.string,
    ChannelIDList: PropTypes.arrayOf(PropTypes.string),
  })),
  realTimeFilteredPosDeviceList: PropTypes.arrayOf(PropTypes.any),
  onCloseFilter: PropTypes.func.isRequired,
  maxChannel: PropTypes.number,
};

export default connect(
  state => ({
    lang: state.langModule.get('lang'),
    posConfigList: state.textSearchModule.get('posConfigList'),
    maxChannel: state.deviceInfoModule.get('maxChannel'),
  }),
)(LiveEventFilterContainer);
