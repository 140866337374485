import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { Button } from 'wisenet-ui/components/atoms';
import { Toast } from 'wisenet-ui/components/organisms';
import { ToastPageContainer } from 'containers/pages';
import { WrapperStyled } from './ToastPageStyled';

class ToastPage extends React.Component {
  componentDidMount() {
  }

  render() {
    const {
      setRef,
      openToast,
    } = this.props;

    return (
      <React.Fragment>
        <WrapperStyled>
          <Button onClick={openToast}>Open</Button>
          <Toast
            ref={setRef}
          />
        </WrapperStyled>
      </React.Fragment>
    );
  }
}

ToastPage.propTypes = {
  setRef: PropTypes.func.isRequired,
  openToast: PropTypes.func.isRequired,
};

ToastPage.defaultProps = {
};

export default withContainer(ToastPageContainer, ToastPage);
