import React from 'react';
import PropTypes from 'prop-types';

class DateTimeSubmitterContainer extends React.Component {
  onApply = () => {
    const { flag, actions } = this.props;
    actions.applyDateTime(flag);
    actions.setCalendarOpen(false);
  }

  onCancel = () => {
    const { actions } = this.props;
    actions.setCalendarOpen(false);
    actions.cancelDateTime();
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

DateTimeSubmitterContainer.propTypes = {
  render: PropTypes.func.isRequired,
  flag: PropTypes.string.isRequired,
  actions: PropTypes.objectOf(PropTypes.any),
};

DateTimeSubmitterContainer.defaultProps = {
  actions: null,
};

export default DateTimeSubmitterContainer;
