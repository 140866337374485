import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import tilePTZControlType from 'wisenet-ui/util/static/constants/mediaControl/tilePTZControlType';
import classNames from 'classnames';
import {
  TilePTZDirectionWrapperStyled,
  ArrowIconStyled,
  IconStyled,
  TilePTZPointerStyled,
} from './TilePTZDirectionControlStyled';

class TilePTZDirectionControl extends PureComponent {
  state = { isOpen: false };

  onMouseOver = type => e => {
    if (e.buttons !== 0) return;
    const { ptzInfo, viewModeType } = this.props;
    // 추후 IA 시나리오 확정 시 수정 필요
    if (viewModeType === 'QuadView' || viewModeType === 'DoublePanorama') return;
    if (ptzInfo.pan || ptzInfo.tilt) {
      switch (type) {
        case 'enter':
          this.setState({
            isOpen: true,
          });
          break;
        case 'leave':
          this.setState({
            isOpen: false,
          });
          break;
        default:
          break;
      }
    }
  }

  render() {
    const {
      onMouseDown,
      onMouseUp,
      className,
    } = this.props;

    const {
      isOpen,
    } = this.state;

    const {
      TOP_LEFT,
      TOP,
      TOP_RIGHT,
      RIGHT,
      BOTTOM_RIGHT,
      BOTTOM,
      BOTTOM_LEFT,
      LEFT,
    } = tilePTZControlType;

    const degs = [0, 45, 90, 135, 180, 225, 270, 315];

    const ptzDirectionItems = [
      {
        clickId: TOP,
        deg: degs[0],
      },
      {
        clickId: TOP_RIGHT,
        deg: degs[1],
      },
      {
        clickId: RIGHT,
        deg: degs[2],
      },
      {
        clickId: BOTTOM_RIGHT,
        deg: degs[3],
      },
      {
        clickId: BOTTOM,
        deg: degs[4],
      },
      {
        clickId: BOTTOM_LEFT,
        deg: degs[5],
      },
      {
        clickId: LEFT,
        deg: degs[6],
      },
      {
        clickId: TOP_LEFT,
        deg: degs[7],
      },
    ];

    return (
      <React.Fragment>
        <TilePTZPointerStyled
          onMouseEnter={this.onMouseOver('enter')}
        >
          <IconStyled className="wni wni-add" /* style={{ display: !isOpen && 'none' }} */ />
        </TilePTZPointerStyled>
        {
          isOpen && (
          <TilePTZDirectionWrapperStyled
            className={className}
            onMouseLeave={this.onMouseOver('leave')}
          >
            {ptzDirectionItems.map(item => (
              <ArrowIconStyled
                key={item.clickId}
                onMouseDown={onMouseDown(item.clickId)}
                onMouseUp={onMouseUp(item.clickId)}
                onDoubleClick={e => e.stopPropagation()}
                deg={item.deg}
                className={`${classNames('wni wni-ptz-mode-arrow')}`}
              />
            ))}
          </TilePTZDirectionWrapperStyled>
          )
        }
      </React.Fragment>
    );
  }
}

TilePTZDirectionControl.defaultProps = {
  onMouseDown: () => {},
  onMouseUp: () => {},
  className: null,
};

TilePTZDirectionControl.propTypes = {
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  ptzInfo: PropTypes.instanceOf(Object).isRequired,
  viewModeType: PropTypes.string.isRequired,
};

export default TilePTZDirectionControl;
