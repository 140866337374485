import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { TextResultTabContainer } from 'containers/organisms';
import { TextList, TextResult } from 'components/organisms';
import { CsvExport } from 'wisenet-ui/components/organisms';
import { getLanguage } from 'util/lib';
import {
  ResultWrapperStyled,
  ResultListAccordionStyled,
  HeadTitleStyled,
  CustomIconButtonStyled,
} from './TextResultTabStyled';

const TextSearchTab = ({
  currentTime,
  accordionState,
  onAccordionClick,
  headers,
  exportData,
}) => {
  const menuItems = [
    {
      id: 'textList',
      headerLeft: <HeadTitleStyled>{getLanguage('lang_text_list')}</HeadTitleStyled>,
      headerIcons: [
        <CsvExport disabled={exportData.data.length === 0} key="export" data={exportData.data} headers={headers} fileName={exportData.fileName}>
          <CustomIconButtonStyled
            className="wni wni-export"
            disabled={exportData.data.length === 0}
          />
        </CsvExport>,
      ],
      content: <TextList currentTime={currentTime} />,
      isOpen: accordionState.textList,
    },
    {
      id: 'data',
      headerLeft: <HeadTitleStyled>{getLanguage('lang_text_details')}</HeadTitleStyled>,
      content: <TextResult />,
      isOpen: accordionState.data,
    },
  ];

  return (
    <ResultWrapperStyled>
      <ResultListAccordionStyled
        items={menuItems}
        onClick={onAccordionClick}
        resize
      />
    </ResultWrapperStyled>
  );
};

TextSearchTab.propTypes = {
  currentTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.number,
  ]).isRequired,
  accordionState: PropTypes.instanceOf(Object).isRequired,
  onAccordionClick: PropTypes.func.isRequired,
  headers: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  exportData: PropTypes.instanceOf(Object),
};

TextSearchTab.defaultProps = {
  exportData: {},
};

export default withContainer(TextResultTabContainer, TextSearchTab);
