import styled from 'styled-components';
import {
  Input,
  Button,
} from 'wisenet-ui/components/atoms';

export const ImageSectionStyled = styled.div`
  margin-top: 79.5px;
  display: flex;
  flex-direction: column;
`;

export const LogoWrapperstyled = styled.div`
  margin-left: 69px;
  margin-bottom: 10.5px;
`;
export const UnderLineWrapperstyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const FormSectionStyled = styled.div`
  margin-top: 43px;
  margin-left: 146.5px;
  display: flex;
`;

export const InputSectionStyled = styled.div`
  width: 158px;
`;

export const InputStyled = styled(Input)`
  width: 100%;
  height: 28px;
  margin-bottom: 7px;
`;

export const ButtonSectionStyled = styled.div`
  margin-left: 8px;
`;

export const ButtonStyled = styled(Button)`
  width: 90px;
  height: 63px;
  padding: 0;
`;

export const CheckboxSectionStyled = styled.div`
  display: flex;
  margin-left: 146.5px;
  margin-top: 5px;
  align-items: center;
`;

export const CheckboxLabelStyled = styled.span`
  margin-left: 10px;
  font-size: 14px;
`;

export const ErrorSectionStyled = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #ff0000;
  margin-top: 26.6px;
`;

export const UnderLineImage = styled.img`
  background-color: #fff;
`;
