export default [
  {
    name: 'english',
    key: 'English',
    value: 'English',
  },
  {
    name: 'french',
    key: 'French',
    value: 'Français',
  },
  {
    name: 'german',
    key: 'German',
    value: 'Deutsch',
  },
  {
    name: 'spanish',
    key: 'Spanish',
    value: 'Español',
  },
  {
    name: 'italian',
    key: 'Italian',
    value: 'Italiano',
  },
  {
    name: 'chinese',
    key: 'Chinese',
    value: '中文',
  },
  {
    name: 'russian',
    key: 'Russian',
    value: 'Русский',
  },
  {
    name: 'korean',
    key: 'Korean',
    value: '한국어',
  },
  {
    name: 'polish',
    key: 'Polish',
    value: 'polski',
  },
  {
    name: 'japanese',
    key: 'Japanese',
    value: '日本語',
  },
  {
    name: 'dutch',
    key: 'Dutch',
    value: 'Nederlands',
  },
  {
    name: 'portuguish',
    key: 'Portuguese',
    value: 'Português',
  },
  {
    name: 'turkish',
    key: 'Turkish',
    value: 'Türkçe',
  },
  {
    name: 'czech',
    key: 'Czech',
    value: 'Čeština',
  },
  {
    name: 'danish',
    key: 'Danish',
    value: 'Dansk',
  },
  {
    name: 'swedish',
    key: 'Swedish',
    value: 'Svenska',
  },
  {
    name: 'thai',
    key: 'Thai',
    value: 'ไทย',
  },
  {
    name: 'rumanian',
    key: 'Romanian',
    value: 'Română',
  },
  {
    name: 'serbian',
    key: 'Serbian',
    value: 'Srpski',
  },
  {
    name: 'croatian',
    key: 'Croatian',
    value: 'Hrvatski',
  },
  {
    name: 'hungarian',
    key: 'Hungarian',
    value: 'Magyar',
  },
  {
    name: 'greek',
    key: 'Greek',
    value: 'Ελληνικά',
  },
  {
    name: 'finnish',
    key: 'Finnish',
    value: 'Suomi',
  },
  {
    name: 'norsk',
    key: 'Norsk',
    value: 'Norsk',
  },
];
