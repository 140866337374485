import React from 'react';
import PropTypes from 'prop-types';
import { Slider } from 'wisenet-ui/components/molecules';
import { Label } from 'wisenet-ui/components/atoms';
import { Toast } from 'wisenet-ui/components/organisms';
import {
  MediaControlIDList,
  MediaControlIconButtonList as IconList,
} from 'wisenet-ui/util/static/constants/mediaControl/mediaControlType';
import { getLanguage } from 'util/lib';
import {
  LeftStyled,
  CenterStyled,
  RightStyled,
  IconButtonStyled,
  IconStyled,
  // PlaySpeedSelect,
  SliderDropDownMenuStyled,
  TriangleStyled,
  InvertedTStyled,
  SliderLabelStyled,
  CustomReactTooltip,
  TooltipSpan,
} from './MediaControlBarStyled';

const SearchMediaControlBar = props => {
  const {
    playSpeedValue,
    onClick,
    playMode,
    playSpeedIndex,
    isFullscreen,
    timelineFolding,
    playSpeenDropDownVisible,
    onChangePlaySpeenDropDownVisible,
    setTimeRangeToastRef,
    showExportIcon,
  } = props;

  const {
    EXPORT,
    PREV_EVENT,
    BACKWARD_PLAY,
    BACKWARD,
    PAUSE,
    PLAY,
    FORWARD,
    FORWARD_PLAY,
    AFTER_EVENT,
    OSD,
    STATUS,
    FULLSCREEN,
    PLAY_SPEED,
    TIMELINE_FOLD,
    TIMELINE_TIME_RANGE,
  } = MediaControlIDList;

  const leftItems = [
    EXPORT,
    TIMELINE_TIME_RANGE,
  ];
  const centerItems = [
    PREV_EVENT,
    playMode === 'play' ? BACKWARD_PLAY : BACKWARD,
    playMode === 'play' ? PAUSE : PLAY,
    playMode === 'play' ? FORWARD_PLAY : FORWARD,
    AFTER_EVENT,
  ];
  const rightItems = [
    OSD,
    STATUS,
    FULLSCREEN,
    TIMELINE_FOLD,
  ];

  const numbers = {
    0: 'x1/8',
    1: 'x1/4',
    2: 'x1/2',
    3: 'x1',
    4: 'x2',
    5: 'x4',
    6: 'x8',
    7: 'x16',
    8: 'x32',
    9: 'x64',
    10: 'x128',
    11: 'x256',
  };

  const dropdownMenuItems = [
    (
      <Slider
        vertical
        min={0}
        max={11}
        defaultValue={playSpeedIndex}
        dots
        marks={numbers}
        included={false}
        dotStyle={{ display: 'none' }}
        disableTooltip
        onChange={onClick(PLAY_SPEED)}
        key="searchSlider"
      />
    ),
  ];
  return (
    <React.Fragment>
      <LeftStyled>
        <Toast width={300} className="toast" ref={setTimeRangeToastRef} />
        {leftItems.map(item => (
          <IconButtonStyled onClick={onClick(IconList[item].clickId)} key={IconList[item].iconId}>
            <IconStyled
              data-tip={item}
              data-for={item}
              className={`${IconList[item].iconId}`}
              isDisableExport={item === 'export' && !showExportIcon}
            />
            <CustomReactTooltip
              id={item}
              type="light"
              place="top"
              effect="float"
              delayShow={500}
              getContent={() => (
                <TooltipSpan>{getLanguage(item)}</TooltipSpan>
              )}
            />
          </IconButtonStyled>
        ))}
      </LeftStyled>
      <CenterStyled>
        {centerItems.map(item => (
          <IconButtonStyled onClick={onClick(IconList[item].clickId)} key={IconList[item].iconId}>
            <IconStyled
              data-tip={item}
              data-for={item}
              className={`${IconList[item].iconId}`}
            />
            <CustomReactTooltip
              id={item}
              type="light"
              place="top"
              effect="float"
              delayShow={500}
              getContent={() => (
                <TooltipSpan>
                  {getLanguage(IconList[item].lang ? IconList[item].lang : item)}
                </TooltipSpan>
              )}
            />
          </IconButtonStyled>
        ))}
        <SliderDropDownMenuStyled
          isOpend={playSpeenDropDownVisible}
          onClickOutside={onChangePlaySpeenDropDownVisible}
          menuItems={dropdownMenuItems}
        >
          <SliderLabelStyled onClick={onChangePlaySpeenDropDownVisible}>
            <Label data-tip="playspeed" data-for="playspeed">{playSpeedValue}</Label>
            <CustomReactTooltip
              id="playspeed"
              type="light"
              place="top"
              effect="float"
              delayShow={500}
              getContent={() => (
                <TooltipSpan>{getLanguage('lang_play_speed')}</TooltipSpan>
              )}
            />
          </SliderLabelStyled>
        </SliderDropDownMenuStyled>
        <div>
          <TriangleStyled />
          <InvertedTStyled />
        </div>
      </CenterStyled>
      <RightStyled>
        {rightItems.map(item => {
          if (item === 'tilelineFold') {
            if (!isFullscreen) {
              return null;
            }
            return (
              <IconButtonStyled
                onClick={onClick(IconList[item].clickId)}
                key={IconList[item].iconId}
              >
                <IconStyled className={`${IconList[item].iconId}-${timelineFolding ? 'up' : 'down'}`} />
              </IconButtonStyled>
            );
          }
          return (
            <IconButtonStyled onClick={onClick(IconList[item].clickId)} key={IconList[item].iconId}>
              <IconStyled
                data-tip={item}
                data-for={item}
                className={`${IconList[item].iconId}`}
              />
              <CustomReactTooltip
                id={item}
                type="light"
                place="top"
                effect="float"
                delayShow={500}
                getContent={() => (
                  <TooltipSpan>{getLanguage(item)}</TooltipSpan>
                )}
              />
            </IconButtonStyled>
          );
        })}
      </RightStyled>
    </React.Fragment>
  );
};

SearchMediaControlBar.defaultProps = {
  playSpeedValue: 'x1',
  playSpeedIndex: 3,
  isFullscreen: false,
  timelineFolding: false,
  playSpeenDropDownVisible: false,
  onChangePlaySpeenDropDownVisible: () => {},
  showExportIcon: false,
};

SearchMediaControlBar.propTypes = {
  playSpeedValue: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  playMode: PropTypes.oneOf([MediaControlIDList.PAUSE, MediaControlIDList.PLAY, 'stop']).isRequired,
  playSpeedIndex: PropTypes.number,
  isFullscreen: PropTypes.bool,
  timelineFolding: PropTypes.bool,
  playSpeenDropDownVisible: PropTypes.bool,
  onChangePlaySpeenDropDownVisible: PropTypes.func,
  setTimeRangeToastRef: PropTypes.func.isRequired,
  showExportIcon: PropTypes.bool,
};

export default SearchMediaControlBar;
