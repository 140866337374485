import styled, { withTheme } from 'styled-components';
import { Span } from 'wisenet-ui/components/atoms';

export const Wrapper = styled.div`
  padding: 0px;
`;

export const StyledSpan = withTheme(styled(Span)`
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.color2};
`);

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 0px;
`;

export const DateInputWrapper = withTheme(styled.span`
  margin-bottom: 5px;
  border: 0px;
  border-bottom: 1px solid ${props => props.theme.line.color1};
  width: 188px;
  display:flex;
`);

export const DateWrapper = styled.div`
  display:flex;
  flex-direction:column;
  margin-left: 17px;
  cursor: default;
`;

export const DateInputTitle = withTheme(styled(Span)`
  font-family: NotoSans;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.color2};
`);
