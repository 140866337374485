import styled, { withTheme } from 'styled-components';
import { Button, Label } from 'wisenet-ui/components/atoms';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border: 1px solid ${props => props.theme.text.color2};
  margin-bottom: 10px;
`;

export const Content = withTheme(styled.div`
  height: 40px;
  width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 7px;
`);

export const AuxLabel = withTheme(styled(Label)`
  width: 36px;
  height: 40px;
  font-family: NotoSans;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.color2};
  margin-top: 2px;
`);

export const AuxButton = withTheme(styled(Button)`
  border-left-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.text.color1};
  text-align: center;
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  width: 70px;
  height: 30px;
  padding: 0px;
`);
