import styled, { withTheme, css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { IconButton, Span } from 'wisenet-ui/components/atoms';

export const EventFilterHeaderStyled = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-left: 16px;
  padding-right: 10px;
`;

export const EventFilterTitle = styled.p`
  font-size: 14px;
`;

export const EventFilterCloseStyled = styled(IconButton)`
  width: 32px;
  height: 32px;
  font-size: 20px;
  padding: 0;
`;

export const EventFilterBottomStyled = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Border = styled.hr`
  width: 100%;
  border: 0.5px solid #797979;
  text-align: center;
`;

export const EventFilterWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 315px;
`;

export const TreeWrapperStyled = styled.div`
  height: 300px;
  padding: 15px 0px;
  margin: 0 16px;
  border-top: 1px solid ${props => props.theme.line.color2};
  border-bottom: 1px solid ${props => props.theme.line.color2};
`;

export const LayoutItemsWrapperStyled = styled.span`
/* margin-left: 50px; */
  float: right;
  display: flex;
`;

export const LayoutIconStyled = styled.i`
width: 20px;
height: 20px;
font-size: 20px;
`;

export const LayoutButtonStyled = styled(IconButton)`
/* height: 20px;
width: 20px; */
margin: 0;
padding: 0px 5px;
display: inline-block;
${props => props.displayNone && css`
  display: none;
`}
`;

export const TooltipSpan = withTheme(styled(Span)`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  margin: 0px;
  padding: 0px
`);

export const CustomReactTooltip = withTheme(styled(ReactTooltip)`
  background-color: #555 !important;
  padding: 5px !important;
  &.place-top {
    &:after {
      border-top: none !important;
    }
  }
  &.place-left {
    &:after {
      border-left: 6px solid #555 !important;
    }
  }
  &.place-right {
    &:after {
      border-right: 6px solid #555 !important;
    }
  }
`);
