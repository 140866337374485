import styled, { withTheme, css } from 'styled-components';
import ReactTooltip from 'react-tooltip';

const Container = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: calc(100% - 40px);
  height: 100%;
  overflow-y: auto;
`);

const PosWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 26px;
`;

const ChannelContainer = withTheme(styled.div`
  flex-basis: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`);

const HrStyled = withTheme(styled.hr`
  width: 100%;
  height: 1px;
  color: ${props => props.theme.line.color1};
`);

const SpanStyled = withTheme(styled.span`
  font-size: 12px;
  color: ${props => props.theme.text.color2};
`);

const SpanDateTimeStyled = withTheme(styled.div`
  width: 112px;
  height: 17px;
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: right;
  color: ${props => props.theme.text.color2};
`);

const SpanChannelStyled = withTheme(styled.span`
  height: 17px;
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.color2};
`);

const SpanChannelButtonStyled = withTheme(styled.span`
  display: flex;
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  button {
    width : 24px;
    height: 16px;
    border-radius: 8px;
    margin: 0px 2px;
    font-size: 11px;
    padding: 0px 0px;
    color: #ffffff;
    background-color: ${props => props.theme.line.color4};
  }
`);

const SpanTitleStyled = withTheme(styled.span`
  height: 22px;
  margin: 0px;
  font-family: NotoSans;
  font-size: 16px;
  font-family: NotoSans;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: ${props => props.theme.text.color1};
`);

const PosSpanStyled = withTheme(styled.span`
  font-size: 12px;
  width: 276px;
  white-space: pre;
  font-family: GulimChe, sans-serif;
  color: #000000;
`);

const HighlightSpanStyled = withTheme(styled.span`
  font-size: 12px;
  white-space: pre;
  background-color: #ffff00;
  font-family: GulimChe, sans-serif;
  color: #000000;
`);

const ReceiptContainerStyled = withTheme(styled.div`
  margin-top: 10px;
  background-color: ${props => props.theme.background.color8};
  flex: 1;
`);

const PosChannelsStyled = styled.div`
  display: flex;
`;

const PosChannelStyled = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${props => props.theme.background.color11};
  color: ${props => props.theme.text.color8};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  ${props => props.isEtc && css`
    padding-bottom: 6px;
  `}
`;

const ReactTooltipStyled = styled(ReactTooltip)`
  background-color: #555 !important;
  padding: 5px !important;
  &.place-top {
    &:after {
      border-top: none !important;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom: none !important;
    }
  }
`;

const TooltipSpanStyled = styled.span`
  font-family: NotoSans;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  padding: 0px;
  white-space: pre;
`;

export {
  Container,
  PosWrapperStyled,
  ChannelContainer,
  HrStyled,
  SpanStyled,
  SpanTitleStyled,
  SpanDateTimeStyled,
  PosSpanStyled,
  HighlightSpanStyled,
  SpanChannelStyled,
  SpanChannelButtonStyled,
  ReceiptContainerStyled,
  PosChannelsStyled,
  PosChannelStyled,
  ReactTooltipStyled,
  TooltipSpanStyled,
};
