import React from 'react';
import PropTypes from 'prop-types';
import { PTZGroupContainer } from 'containers/organisms';
import { Table } from 'wisenet-ui/components/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { getLanguage } from 'util/lib';
import {
  Container,
  GroupDisableMask,
  TableWrapper,
} from './PTZGroupStyled';

const PTZGroup = ({
  onChangeData,
  currentGroupList,
}) => {
  const groupHeader = [
    {
      Header: getLanguage('lang_number'),
      accessor: 'no',
      sortable: true,
    },
    {
      Header: getLanguage('lang_name'),
      accessor: 'name',
      sortable: true,
    },
  ];

  return (
    <Container id="group_wrapper">
      <GroupDisableMask id="group_disable_mask" />
      <TableWrapper>
        { currentGroupList !== null
        && currentGroupList !== false
        && (
          <Table
            onChangeData={onChangeData}
            scroll
            header={groupHeader}
            data={currentGroupList}
            selectRow
            pageSize={9}
          />
        )
      }
      </TableWrapper>
    </Container>
  );
};

PTZGroup.propTypes = {
  currentGroupList: PropTypes.arrayOf(PropTypes.any),
  onChangeData: PropTypes.func,
};

PTZGroup.defaultProps = {
  currentGroupList: [],
  onChangeData: null,
};

export default withContainer(PTZGroupContainer, PTZGroup);
