import React from 'react';
import PropTypes from 'prop-types';
import { UmpPlayer } from 'wisenet-ui/components/molecules';
import TileStyled from './TileStyled';

class Tile extends React.PureComponent {
  render() {
    const {
      uid,
      onCapture,
      className,
    } = this.props;

    return (
      <TileStyled
        className={className}
        key={uid}
      >
        <UmpPlayer
          {...this.props}
          onCapture={event => onCapture(event, this.props)}
        />
      </TileStyled>
    );
  }
}

Tile.defaultProps = {
  // event function
  onCapture: () => {},
  uid: '',
  className: '',
};

Tile.propTypes = {
  onCapture: PropTypes.func,
  uid: PropTypes.string,
  className: PropTypes.string,
};

export default Tile;
