import styled, { withTheme } from 'styled-components';

const borderSize = '1px';

export const PatternIconTile = withTheme(styled.div`
  width: calc(${props => props.widthRatio * props.w}%);
  height: calc(${props => props.heightRatio * props.h}%);
  top: calc(${props => props.heightRatio * props.y}%);
  left: calc(${props => props.widthRatio * props.x}%);
  position: absolute;
  border: ${borderSize} solid ${props => props.theme.text.color5};
  border-right: none;
  border-bottom: none;
`);

export const PatternIconLayout = withTheme(styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  position: relative;
  background-color: ${props => props.theme.background.color1};
  border-right: ${borderSize} solid ${props => props.theme.text.color5};
  border-bottom: ${borderSize} solid ${props => props.theme.text.color5};

  &.select {
    border-right: ${borderSize} solid ${props => props.theme.background.color6};
    border-bottom: ${borderSize} solid ${props => props.theme.background.color6};
    & > div {
      border: ${borderSize} solid ${props => props.theme.background.color6};
      border-right: none;
      border-bottom: none;
    }
  }
`);
