import styled from 'styled-components';

export const EventTabWrapper = styled.div`
  .NewList {
    position: static !important;
    max-height: unset;
    .realTimeEventComponent{
      & > div {
        padding-left: 0;
      }
    }
  }
`;
