import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  TreeTitleStyled,
  TreeCheckboxStyled,
  TreeSpanStyled,
  TreeExtraSpanStyled,
  TreeIconSpanStyled,
  IconSquare,
  CustomReactTooltip,
  TooltipSpan,
} from './TreeStyled';

class TreeTitle extends React.Component {
  shouldComponentUpdate(nextProps) {
    const {
      treeItem: nextTreeItem,
    } = nextProps;

    const {
      treeItem,
    } = this.props;

    if (JSON.stringify(nextTreeItem) === JSON.stringify(treeItem)) {
      return false;
    }
    return true;
  }

  render() {
    const {
      treeItem,
      showCheckbox,
      showIcon,
      onClickNode,
    } = this.props;

    let channelList = treeItem.data && treeItem.data.extraData;
    let etcChannelList = [];
    if (channelList && channelList.length > 5) {
      channelList = treeItem.data.extraData.slice(0, 4);
      etcChannelList = treeItem.data.extraData.slice(4, treeItem.data.extraData.length);
    }
    const Channels = (treeItem.data !== undefined && treeItem.data.extraData.length > 0)
      && (treeItem.data.extraData.length > 5 ? (
        <React.Fragment>
          {channelList.map(item => (
            <TreeExtraSpanStyled
              key={item}
            >
              {item}
            </TreeExtraSpanStyled>
          ))}
          <TreeExtraSpanStyled
            data-tip={`${treeItem.data.extraData[0]}-etc`}
            data-for={`${treeItem.data.extraData[0]}-etc`}
            isEtc
          >
            {'...'}
          </TreeExtraSpanStyled>
          <CustomReactTooltip
            id={`${treeItem.data.extraData[0]}-etc`}
            type="light"
            effect="solid"
            place="bottom"
            delayShow={500}
            getContent={() => (
              <TooltipSpan>
                <div>
                  {'CH : '}
                </div>
                {
                  etcChannelList.map((item, index) => {
                    const value = item >= 10 ? item : `0${item}`;
                    return ((index + 1) % 5 !== 0) ? `${value} ` : `${value} \n`;
                  })
                }
              </TooltipSpan>
            )}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {channelList.map(item => (
            <TreeExtraSpanStyled
              type="button"
              key={item}
            >
              {item}
            </TreeExtraSpanStyled>
          ))}
        </React.Fragment>
      ));
    return (
      <TreeTitleStyled>
        {showCheckbox
          ? (
            <React.Fragment>
              <TreeCheckboxStyled
                name="treeCheckbox"
                onChange={() => { onClickNode(treeItem); }}
                checked={treeItem.checked === 1}
                className={classNames({ haveCheckedChild: treeItem.checked === 2 })}
              />
              {(showIcon && treeItem.icon)
                && (
                  <TreeIconSpanStyled>
                    {/* {treeItem.icon} */}
                    <IconSquare color={treeItem.icon.color} />
                  </TreeIconSpanStyled>
                )
              }
              <TreeSpanStyled>
                {treeItem.title}
              </TreeSpanStyled>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {(showIcon && treeItem.icon)
                && (
                  <TreeIconSpanStyled>
                    {/* {treeItem.icon} */}
                    <IconSquare color={treeItem.icon.color} />
                  </TreeIconSpanStyled>
                )
              }
              <TreeSpanStyled onClick={() => { onClickNode(treeItem); }}>
                {treeItem.title}
              </TreeSpanStyled>
            </React.Fragment>
          )}
        {Channels && (
          <React.Fragment>
            {Channels}
          </React.Fragment>
        )}
      </TreeTitleStyled>
    );
  }
}

TreeTitle.defaultProps = {
  showCheckbox: false,
  showIcon: false,
  onClickNode: () => {},
};

TreeTitle.propTypes = {
  treeItem: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.instanceOf(Object),
    icon: PropTypes.instanceOf(Object),
    checked: PropTypes.number, // 0 : checked false, 1: checked true, 2: checked some children
    expanded: PropTypes.bool,
    parents: PropTypes.any,
    children: PropTypes.any,
  }).isRequired,
  showCheckbox: PropTypes.bool,
  showIcon: PropTypes.bool,
  onClickNode: PropTypes.func,
};

export default TreeTitle;
