import React from 'react';
import PropTypes from 'prop-types';
import {
  TableStyled,
  TdStyled,
} from './SupportChannelStyled';

const makeTableData = (maxChannel, data) => {
  const isAllSupport = data.length !== 0 && data.length === maxChannel;
  const isAllNonSupport = data.length === 1 && data[0] === 'None';
  const supportData = [];
  for (let i = 0; i < maxChannel; i += 1) {
    if (isAllSupport) {
      supportData[i] = true;
    } else {
      supportData[i] = false;
    }
  }
  if (!isAllSupport && !isAllNonSupport) {
    data.map(item => {
      supportData[parseInt(item, 10)] = true;
      return item;
    });
  }

  const tableData = [];
  const lineLength = Math.ceil(maxChannel / 8);
  for (let i = 0; i < lineLength; i += 1) {
    const td = [];
    for (let j = 0; j < 8; j += 1) {
      const channel = (i * 8) + j + 1;
      td[j] = (
        <TdStyled
          key={`supportTd${channel}`}
          isActive={supportData[channel - 1]}
        >
          {(channel <= maxChannel) && channel}
        </TdStyled>
      );
    }
    tableData.push((
      <tr key={`supportTr${i}`}>
        {td}
      </tr>
    ));
  }
  return tableData;
};

const SupportChannel = ({
  className,
  data,
  maxChannel,
}) => (
  <TableStyled className={className}>
    <tbody>
      {
        makeTableData(maxChannel, data)
      }
    </tbody>
  </TableStyled>
);

SupportChannel.defaultProps = {
  className: null,
  data: [],
};

SupportChannel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  data: PropTypes.instanceOf(Array),
  maxChannel: PropTypes.number.isRequired,
};

export default SupportChannel;
