import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { WrapperSunapiClient } from 'util/lib';
import umpPlayMode from 'wisenet-ui/util/static/constants/umpPlayer/umpPlayMode';
import { methods, values } from 'wisenet-ui/util/static/constants/umpPlayer/umpPlayerProperties';
import { getCookie } from 'util/lib/Cookie';

import UmpPlayerStyled from './UmpPlayerStyled';

class UmpPlayer extends PureComponent {
  componentDidMount() {
    if (this.umpPlayer.play) {
      const {
        addEvent,
        errorCatch,
      } = this;

      const {
        sessionKey,
        setUmpPlayer,
        mode,
        useIsoTimeFormat,
      } = this.props;

      this.umpPlayer.sessionKey = sessionKey;
      this.umpPlayer.sunapiClient = WrapperSunapiClient;
      this.umpPlayer.playType = mode;
      this.umpPlayer.useIsoTimeFormat = useIsoTimeFormat;
      // this.umpPlayer.play();
      addEvent();
      const errorCatchUmpPlayer = errorCatch(this.umpPlayer);
      setUmpPlayer(errorCatchUmpPlayer);
    }
  }

  componentWillUnmount() {
    if (this.umpPlayer.stop) {
      // this.umpPlayer.stop();
    }
  }

  errorCatch = umpPlayer => {
    const { onError, uid } = this.props;
    const copyUmpPlayer = Object.assign({}, umpPlayer);
    values.map(value => {
      try {
        Object.defineProperties(copyUmpPlayer, {
          [value]: {
            get: () => this.umpPlayer[value],
            set: input => {
              try {
                this.umpPlayer[value] = input;
                d.log('[ump interface value]', value, input);
              } catch (event) {
                // 데이터를 넣을 때 error가 났을 때 페이지가 죽지 않도록 예외처리
                // instant playback에서만 현재 에러가 나타남.
                d.log('value', value);
                d.log('event', event);
              }
            },
          },
        });
      } catch (event) {
        onError({
          type: 'value',
          name: value,
          channel: event.channel,
          element: event.element,
          errorCode: event.errorCode,
          message: event.message,
          place: event.place,
          uid,
        });
      }
      return null;
    });

    methods.map(method => {
      copyUmpPlayer[method] = value => {
        try {
          const resultValue = this.umpPlayer[method](value);
          const { channel, onSuccess } = this.props;
          d.log('[ump interface methods]  :', method, 'CH(', channel, ')');
          onSuccess({
            type: 'method',
            name: method,
            channel,
            uid,
          });
          return resultValue;
        } catch (event) {
          d.error('[ump interface methods]  :', method, 'event(', event, ')');
          onError({
            type: 'method',
            name: method,
            channel: event.channel,
            element: event.element,
            errorCode: event.errorCode,
            message: event.message,
            place: event.place,
            uid,
          });
        }
        return null;
      };
      return null;
    });

    return copyUmpPlayer;
  }

  addEvent = () => {
    const {
      onError,
      onMeta,
      onClose,
      onStatechange,
      onTimestamp,
      onCapture,
      onVideoText,
      onResize,
      onChangePlayermode,
      onStatistics,
      onBackupstatechange,
      onInstantplayback,
      onWaiting,
    } = this.props;

    this.umpPlayer.addEventListener('error', onError);
    this.umpPlayer.addEventListener('meta', onMeta);
    this.umpPlayer.addEventListener('close', onClose);
    this.umpPlayer.addEventListener('statechange', onStatechange);
    this.umpPlayer.addEventListener('timestamp', onTimestamp);
    this.umpPlayer.addEventListener('capture', onCapture);
    this.umpPlayer.addEventListener('vtext', onVideoText);
    this.umpPlayer.addEventListener('resize', onResize);
    this.umpPlayer.addEventListener('changeplayermode', onChangePlayermode);
    this.umpPlayer.addEventListener('statistics', onStatistics);
    this.umpPlayer.addEventListener('backupstatechange', onBackupstatechange);
    this.umpPlayer.addEventListener('instantplayback', onInstantplayback);
    this.umpPlayer.addEventListener('waiting', onWaiting);
  }

  render() {
    const {
      channel: propsChannel,
      device,
      profile,
      profileNumber,
      userID: propsUserID,
      mode: propsMode,
      aspectRatio,
      uid,
      width,
      height,
      rotate,
      dZoom,
      tileMode,
      resolution,
      digitalZoom,
      isSingleChannel,
    } = this.props;

    const username = propsUserID || atob(getCookie('ID'));
    const mode = propsMode === umpPlayMode.LIVE ? 'Live' : 'Playback';
    const channel = isSingleChannel ? null : (propsChannel + 1);
    const ipaddress = axios.defaults.baseURL.replace(`${window.location.protocol}//`, '') || window.location.host;

    let ip;
    let port;
    const isHttps = window.location.protocol === 'https:';
    // IPv6 와 IPv4 구분 방법 [ 유무로 판단
    if (ipaddress.indexOf('[') !== -1) {
      ip = ipaddress;
      port = undefined;
    } else {
      const hostname = ipaddress.split(':');
      // ip = hostname[0];
      // port = hostname[1];
      [ip, port] = hostname;
    }

    return (
      <UmpPlayerStyled
        aspectRatio={aspectRatio}
        width={width}
        height={height}
        rotate={rotate}
        id={`ump-${uid}`}
        dZoom={dZoom}
        tileMode={tileMode}
        resolution={resolution}
        digitalZoom={digitalZoom}
      >
        <ump-player
          ref={ref => {
            this.umpPlayer = ref;
          }}
          id={`ump-player-${uid}-${mode}`}
          hostname={ip}
          profile={profile}
          profile_number={profileNumber}
          channel={channel}
          device={device}
          width={width}
          height={height}
          username={username}
          port={port}
          https={isHttps}
        />
      </UmpPlayerStyled>
    );
  }
}

UmpPlayer.defaultProps = {
  // profile name.
  // If this device type is Camera, this attribute is mandatory.
  // But, If this device type is NVR, this attribute is not mandatory.
  // It is not necessary to play back.
  profile: undefined, // MJPEG
  // camera profile number.
  // If this device type is Camera, this attribute is not mandatory.
  // But, If this device type is NVR, this attribute is mandatory.
  // It is not necessary to play back.
  profileNumber: undefined, // '1'
  device: 'nvr',
  userID: undefined,
  useIsoTimeFormat: undefined,
  aspectRatio: false,
  width: 300,
  height: 300,
  rotate: 0,
  dZoom: false,
  tileMode: 'normal',
  resolution: {
    channelId: 0,
    elementId: '',
    height: 0,
    width: 0,
  },
  channel: 0,
  isSingleChannel: false,
  digitalZoom: 1,

  // event function
  onError: () => {},
  onMeta: () => {},
  onClose: () => {},
  onStatechange: () => {},
  onTimestamp: () => {},
  onVideoText: () => {},
  onResize: () => {},
  onChangePlayermode: () => {},
  onStatistics: () => {},
  onBackupstatechange: () => {},
  onInstantplayback: () => {},
  onWaiting: () => {},

  // set function
  setUmpPlayer: () => {},
  onSuccess: () => {},
};

UmpPlayer.propTypes = {
  channel: PropTypes.number,
  sessionKey: PropTypes.string.isRequired,
  profile: PropTypes.string,
  profileNumber: PropTypes.string,
  device: PropTypes.string,
  userID: PropTypes.string,
  onError: PropTypes.func,
  onMeta: PropTypes.func,
  onClose: PropTypes.func,
  onStatechange: PropTypes.func,
  onTimestamp: PropTypes.func,
  onCapture: PropTypes.func.isRequired,
  setUmpPlayer: PropTypes.func,
  mode: PropTypes.oneOf([umpPlayMode.LIVE, umpPlayMode.PLAYBACK, umpPlayMode.BACKUP]).isRequired,
  useIsoTimeFormat: PropTypes.bool,
  onVideoText: PropTypes.func,
  aspectRatio: PropTypes.bool,
  uid: PropTypes.string.isRequired,
  onResize: PropTypes.func,
  onChangePlayermode: PropTypes.func,
  onStatistics: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  rotate: PropTypes.number,
  onBackupstatechange: PropTypes.func,
  tileMode: PropTypes.string,
  dZoom: PropTypes.bool,
  onSuccess: PropTypes.func,
  onInstantplayback: PropTypes.func,
  resolution: PropTypes.shape({
    channelId: PropTypes.number,
    elementId: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  isSingleChannel: PropTypes.bool,
  digitalZoom: PropTypes.number,
  onWaiting: PropTypes.func,
};

export default UmpPlayer;
