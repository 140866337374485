import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import { Timeline } from 'wisenet-ui/components/organisms';
import {
  SearchTimelineOptionLeft,
  SearchTimelineOptionMiddle,
  SearchTimelineOptionRight,
  PopupContent,
} from 'components/molecules';
import { getLanguage } from 'util/lib';
import { SearchTimelineContainer } from 'containers/organisms';
import { RadioGroup } from 'wisenet-ui/components/molecules';
import { TimelineAlmende } from 'wisenet-ui/components/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import {
  SearchOptionStyled,
  IconStyled,
  PopupStyled,
  PopupButtonStyled,
  PopupContentWrapperStyled,
} from './SearchTimelineStyled';

class SearchTimeline extends PureComponent {
  render() {
    const {
      className,
      popupVisible,
      onChangePopupVisible,
      dstEnable,
      onChangeDstSelect,
    } = this.props;

    const radiogroupInputs = [{
      key: 'dstFalse',
      text: getLanguage('lang_before_dst_end'),
      value: false,
    }, {
      key: 'dstTrue',
      text: getLanguage('lang_after_dst_end'),
      value: true,
    }];

    return (
      <div
        className={className}
      >
        <SearchOptionStyled>
          <SearchTimelineOptionLeft
            {...this.props}
            IconStyled={IconStyled}
          />
          <SearchTimelineOptionMiddle
            {...this.props}
            IconStyled={IconStyled}
          />
          <SearchTimelineOptionRight
            {...this.props}
            IconStyled={IconStyled}
          />
        </SearchOptionStyled>
        <TimelineAlmende {...this.props} />
        <PopupStyled
          isOpen={popupVisible}
        >
          <PopupContent
            title="lang_select_overlapped_section"
            bottomButtons={[
              (
                <PopupButtonStyled
                  key="userAuthDetailPopupCloseButton"
                  onClick={onChangePopupVisible}
                >
                  {getLanguage('lang_ok')}
                </PopupButtonStyled>
              ),
            ]}
          >
            <PopupContentWrapperStyled>
              <RadioGroup
                inputs={radiogroupInputs}
                selected={dstEnable}
                onChange={onChangeDstSelect}
              />
            </PopupContentWrapperStyled>
          </PopupContent>
        </PopupStyled>
      </div>
    );
  }
}

SearchTimeline.defaultProps = {
  className: null,
  popupVisible: false,
  onChangePopupVisible: () => {},
  dstEnable: false,
  onChangeDstSelect: () => {},
};

SearchTimeline.propTypes = {
  onClick: PropTypes.func.isRequired,
  popupVisible: PropTypes.bool,
  onChangePopupVisible: PropTypes.func,
  dstEnable: PropTypes.bool,
  onChangeDstSelect: PropTypes.func,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
};

export default withContainer(SearchTimelineContainer, SearchTimeline);
