import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { UserAuthDetailContainer } from 'containers/organisms';
import { SupportDetailMenu } from 'components/molecules';
import { getLanguage } from 'util/lib';
import {
  UserAuthDetailWrapperStyled,
  CheckAllWrapperStyled,
  CheckboxStyled,
  CheckTitleStyled,
  DetailTabsStyled,
} from './UserAuthDetailStyled';

const UserAuthDetail = ({
  className,
  allSupport,
  cameraMenu,
  systemMenu,
  deviceMenu,
  recordMenu,
  eventMenu,
  networkMenu,
}) => {
  const detailTabs = [
    {
      key: 'tabDetailCamera',
      header: getLanguage('lang_camera'),
      component: (
        <SupportDetailMenu data={cameraMenu} />
      ),
    },
    {
      key: 'tabDetailRecord',
      header: getLanguage('lang_record'),
      component: (
        <SupportDetailMenu data={recordMenu} />
      ),
    },
    {
      key: 'tabDetailEvent',
      header: getLanguage('lang_event'),
      component: (
        <SupportDetailMenu data={eventMenu} />
      ),
    },
    {
      key: 'tabDetailDevice',
      header: getLanguage('lang_device'),
      component: (
        <SupportDetailMenu data={deviceMenu} />
      ),
    },
    {
      key: 'tabDetailNetwork',
      header: getLanguage('lang_network'),
      component: (
        <SupportDetailMenu data={networkMenu} />
      ),
    },
    {
      key: 'tabDetailSystem',
      header: getLanguage('lang_system'),
      component: (
        <SupportDetailMenu data={systemMenu} />
      ),
    },
  ];
  return (
    <UserAuthDetailWrapperStyled className={className}>
      <CheckAllWrapperStyled>
        <CheckboxStyled
          checked={allSupport}
          disabled
        />
        <CheckTitleStyled>{getLanguage('lang_all')}</CheckTitleStyled>
      </CheckAllWrapperStyled>
      <DetailTabsStyled tabData={detailTabs} />
    </UserAuthDetailWrapperStyled>
  );
};

UserAuthDetail.defaultProps = {
  className: null,
  cameraMenu: [],
  systemMenu: [],
  deviceMenu: [],
  recordMenu: [],
  eventMenu: [],
  networkMenu: [],
};

UserAuthDetail.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  allSupport: PropTypes.bool.isRequired,
  cameraMenu: PropTypes.instanceOf(Object),
  systemMenu: PropTypes.instanceOf(Object),
  deviceMenu: PropTypes.instanceOf(Object),
  recordMenu: PropTypes.instanceOf(Object),
  eventMenu: PropTypes.instanceOf(Object),
  networkMenu: PropTypes.instanceOf(Object),
};

export default withContainer(UserAuthDetailContainer, UserAuthDetail);
