import React from 'react';
import PropTypes from 'prop-types';
import { withContainers } from 'wisenet-ui/util/hoc';
import { VideoTile } from 'wisenet-ui/components/organisms';
import {
  TilePTZControl,
  TileDigitalZoomControl,
  LiveVideoTileBar,
  ChannelInfo,
  TileInstantPlaybackControl,
} from 'components/organisms';
import { LiveVideoTileControlBar } from 'components/molecules';
import MediaStatusType from 'wisenet-ui/util/static/constants/mediaControl/mediaStatusType';
import {
  LiveVideoTileContainer,
  LiveVideoTileInstantPlaybackContainer,
  LiveVideoTileDigitalZoomContainer,
  TileDataContainer,
} from 'containers/organisms';

import umpPlayMode from 'wisenet-ui/util/static/constants/umpPlayer/umpPlayMode';
import { MediaControlIDList } from 'wisenet-ui/util/static/constants/mediaControl/mediaControlType';

import {
  LiveVideoTileStyled,
  LiveVideoTileStatusStyled,
  TileCloseWrapper,
  CloseIconStyled,
  VerticalLineStyled,
  LiveVideoTileTostStyled,
  LoadingStyled,
} from './LiveVideoTileStyled';

class LiveVideoTile extends React.PureComponent {
  render() {
    const {
      onTileBarClick,
      onMouseEvent,
      statusIcon,
      OSDDisplay,
      channelName,
      channelInfo,
      channelInfoDisplay,
      tileMode,
      setDZoomMode,
      setOpenTileBar,
      returnTileMode,
      onDeleteTile,
      status,
      setInstantPlaybackMode,
      setInstantPlaybackSeekingTime,
      instantPlaybackTimeRage,
      instantPlaybackMode,
      instantPlaybackSeekingTime,
      instantPlayTime,
      mouseIn,
      openTileControl,
      setRef,
      openToast,
      timeoutS,
      isSelectTile,
      onKeyUp,
      viewModeType,
      rotate,
      digitalZoom,
      eventPos,
      setDigitalZoomInOut,
      umpStart,
      resolutionIcon,
      resolutionUid,
      needRightBold,
      tilePendding,
      ...rest
    } = this.props;
    const OSDVisible = OSDDisplay && status !== 'Covert2' && status !== 'Disconnected';
    const channelInfoVisible = channelInfoDisplay.isVisible && status !== 'Covert2' && status !== 'Disconnected';
    const controlBarVisible = (status === 'Success' || typeof status === 'undefined' || status === 'AccBlock') && umpStart;
    // const controlBarVisible = true;

    return (
      <LiveVideoTileStyled
        tabIndex="-1"
        onMouseEnter={onMouseEvent('enter')}
        onMouseLeave={onMouseEvent('leave')}
        onDoubleClick={onMouseEvent('doubleClick')}
        onMouseDown={onMouseEvent('down')}
        onWheel={onMouseEvent('wheel')}
        onClick={onMouseEvent('click')}
        onKeyUp={onKeyUp}
        isSelectTile={isSelectTile}
        needRightBold={needRightBold}
      >
        {/* {tileMode === MediaControlIDList.INSTANT_PLAYBACK && <Loading />} */}
        <VideoTile
          {...rest}
          type={umpPlayMode.LIVE}
          channelInfoDisplay={channelInfoDisplay}
          codec={channelInfo.codec}
          tileMode={tileMode}
          instantPlaybackMode={instantPlaybackMode}
          instantPlaybackSeekingTime={instantPlaybackSeekingTime}
          rotate={rotate}
          digitalZoom={digitalZoom}
        />
        {(tileMode === MediaControlIDList.INSTANT_PLAYBACK) && (
          <TileInstantPlaybackControl
            currentChannel={rest.channel}
            returnTileMode={returnTileMode}
            setInstantPlaybackMode={setInstantPlaybackMode}
            setInstantPlaybackSeekingTime={setInstantPlaybackSeekingTime}
            instantPlaybackTimeRage={instantPlaybackTimeRage}
            instantPlaybackMode={instantPlaybackMode}
            instantPlaybackSeekingTime={instantPlaybackSeekingTime}
            instantPlayTime={instantPlayTime}
            uid={rest.uid}
          />
        )}
        {
          OSDVisible && (
            <LiveVideoTileBar
              channel={rest.channel}
              channelName={channelName}
              onDeleteTile={onDeleteTile}
              mic={rest.mic}
              pcRecord={rest.pcRecord}
            />
          )
        }
        {
          channelInfoVisible && (
            <ChannelInfo info={channelInfo} mode={channelInfoDisplay.isDevelopMode} />
          )
        }
        {
          statusIcon && (
            <LiveVideoTileStatusStyled>
              <i className={`wni ${statusIcon}`} />
            </LiveVideoTileStatusStyled>
          )
        }
        {
          (!rest.notOverTotalResolution && typeof rest.notOverTotalResolution !== 'undefined')
          && (status === 'Success' || status === 'AccBlock') && (
            <LiveVideoTileStatusStyled>
              <i className="wni wni-caution" />
            </LiveVideoTileStatusStyled>
          )
        }
        {(tileMode === MediaControlIDList.PTZ_CONTROL) && (
          <TilePTZControl
            currentChannel={rest.channel}
            ptzInfo={rest.ptzInfo}
            returnTileMode={returnTileMode}
            onTileBarClick={onTileBarClick}
            uid={rest.uid}
            width={rest.width}
            height={rest.height}
            mouseIn={mouseIn}
            viewModeType={viewModeType}
            rotate={rotate}
            aspectRatio={rest.aspectRatio}
            dZoom={rest.dZoom}
            setDigitalZoomInOut={setDigitalZoomInOut}
            digitalZoom={digitalZoom}
            eventPos={eventPos}
            isAnalog={rest.protocol === 'ANALOG'}
          />
        )}
        { rest.dZoom && (
          <TileDigitalZoomControl
            uid={rest.uid}
            width={rest.width}
            height={rest.height}
            setDZoomMode={setDZoomMode}
            setOpenTileBar={setOpenTileBar}
            mouseIn={mouseIn}
            rotate={rotate}
            aspectRatio={rest.aspectRatio}
            setDigitalZoomInOut={setDigitalZoomInOut}
            digitalZoom={digitalZoom}
            eventPos={eventPos}
          />
        )}
        {
          mouseIn && controlBarVisible && openTileControl && (
            <LiveVideoTileControlBar onClick={onTileBarClick} {...rest} />
          )
        }
        {
          mouseIn && (
            <TileCloseWrapper>
              <VerticalLineStyled />
              <CloseIconStyled className="wni wni-close" onClick={onDeleteTile} mouseInStyleDisabled />
            </TileCloseWrapper>
          )
        }
        {
          <LiveVideoTileTostStyled
            timeoutS={timeoutS}
            ref={setRef}
          />
        }
        {tilePendding && <LoadingStyled />}
      </LiveVideoTileStyled>
    );
  }
}

LiveVideoTile.defaultProps = {
  statusIcon: null,
  resolutionIcon: null,
  channelName: '',
  sdFail: false,
  sdFull: false,
  motionDetection: false,
  defocusDetection: false,
  ivaDetection: false,
  timeoutS: 6,
  mouseIn: false,
  openTileControl: true,
  status: 'ConnectFail',
  viewModeType: '',
  rotate: 0,
  digitalZoom: 0,
  setDigitalZoomInOut: () => {},
  instantPlayTime: 0,
  umpStart: false,
  eventPos: null,
  resolutionUid: '',
  needRightBold: false,
  tilePendding: false,
};

LiveVideoTile.propTypes = {
  onTileBarClick: PropTypes.func.isRequired,
  onMouseEvent: PropTypes.func.isRequired,
  statusIcon: PropTypes.oneOfType([PropTypes.any, PropTypes.string]),
  resolutionIcon: PropTypes.oneOfType([PropTypes.any, PropTypes.string]),
  channelName: PropTypes.string,
  sdFail: PropTypes.bool,
  sdFull: PropTypes.bool,
  motionDetection: PropTypes.bool,
  defocusDetection: PropTypes.bool,
  ivaDetection: PropTypes.bool,
  channelInfo: PropTypes.shape({
    resolution: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    codec: PropTypes.string,
    bps: PropTypes.string,
    fps: PropTypes.number,
  }).isRequired,
  OSDDisplay: PropTypes.bool.isRequired,
  channelInfoDisplay: PropTypes.shape({
    isVisible: PropTypes.bool,
    isDevelopMode: PropTypes.bool,
  }).isRequired,
  tileMode: PropTypes.oneOf(['normal', MediaControlIDList.PTZ_CONTROL, MediaControlIDList.INSTANT_PLAYBACK, MediaControlIDList.D_ZOOM]).isRequired,
  setDZoomMode: PropTypes.func.isRequired,
  setOpenTileBar: PropTypes.func.isRequired,
  returnTileMode: PropTypes.func.isRequired,
  onDeleteTile: PropTypes.func.isRequired,
  status: PropTypes.oneOf(Object.entries(MediaStatusType).map(([, value]) => value)),
  // instantPlayback use
  setInstantPlaybackMode: PropTypes.func.isRequired,
  setInstantPlaybackSeekingTime: PropTypes.func.isRequired,
  instantPlaybackTimeRage: PropTypes.shape({
    startTime: PropTypes.number,
    endTime: PropTypes.number,
  }).isRequired,
  instantPlaybackMode: PropTypes.string.isRequired,
  instantPlaybackSeekingTime: PropTypes.number.isRequired,
  mouseIn: PropTypes.bool,
  openTileControl: PropTypes.bool,
  setRef: PropTypes.func.isRequired,
  openToast: PropTypes.func.isRequired,
  timeoutS: PropTypes.number,
  isSelectTile: PropTypes.bool.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  viewModeType: PropTypes.string,
  rotate: PropTypes.number,
  digitalZoom: PropTypes.number,
  eventPos: PropTypes.instanceOf(Object),
  setDigitalZoomInOut: PropTypes.func,
  instantPlayTime: PropTypes.number,
  umpStart: PropTypes.bool,
  resolutionUid: PropTypes.string,
  needRightBold: PropTypes.bool,
  tilePendding: PropTypes.bool,
};

export default withContainers(
  [
    LiveVideoTileContainer,
    TileDataContainer,
    LiveVideoTileInstantPlaybackContainer,
    LiveVideoTileDigitalZoomContainer,
  ], LiveVideoTile,
);
