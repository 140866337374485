import styled, { withTheme } from 'styled-components';
import {
  Label,
  Select,
  Input,
  Button,
  Span,
  ReactModalAdapter,
} from 'wisenet-ui/components/atoms';
import { Table } from 'wisenet-ui/components/organisms';
import Progress from 'react-progressbar';

export const StyledPropgressbar = withTheme(styled(Progress)`
  width: 410px;
  height: 14px;
  margin-left: 10px;
  margin-top: 2px;
  border: 1px solid ${props => props.theme.line.color4};
  padding: 1px;
  .progressbar-progress {
    background-color: grey !important;
    border: 1px solid ${props => props.theme.line.color4};
  }
`);

export const Container = withTheme(styled.div`
  height: 550px;
  border: 1px solid ${props => props.theme.text.color2};
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${props => props.theme.background.color2};
`);

export const Header = withTheme(styled.div`
  margin-top: 0px;
  height: 44px;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.text.color2};
`);

export const Footer = styled.div`
  height: 60px;
  width: 100%;
`;

export const Body = withTheme(styled.div`
  height: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
`);

export const SubBody1 = withTheme(styled.div`
  height: 380px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.text.color2};
`);

export const SubBody2 = withTheme(styled.div`
  margin-top: 30px;
  height: 100px;
  width: 100%;
`);

export const TitleSpan = withTheme(styled(Span)`
  width: auto;
  height: 19px;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: bolder;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  float: left;
  margin-left: 16px;
  margin-top: 10px;
  color: ${props => props.theme.text.color2};
`);

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const FooterButton = styled(Button)`
  width: 100px;
  height: 28px;
  margin: 16px;
  object-fit: contain;
  padding-top: 4px;
  padding-bottom: 5px;
`;

export const TableWrapper = withTheme(styled.div`
  width: 100%;
  height: 200px;
  border: 1px solid ${props => props.theme.text.color2};
  overflow: auto;
  margin-top: 10px;
`);

export const LayoutSelect = styled(Select)`
  width: 136px;
  height: 38px;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  float: left;
  text-align: left;
`;

export const StyledDatePickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  height: 38px;
  margin-left: 0px;
  margin-top: 7px;
`;

export const FileNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0px;
  margin-top: 7px;
`;

export const MenuSpan = withTheme(styled(Span)`
  // width: 66px;
  height: 19px;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  float: left;
  text-align: left;
  color: ${props => props.theme.text.color2};
`);

export const FileInput = styled(Input)`
  margin-left: 20px;
  width: 248px;
  height: 28px;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: ${props => props.theme.text.color2};
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  width: 510px;
  height: 38px;
  margin-left: 30px;
  padding: 0px;
`;

export const StatusLabel = withTheme(styled(Label)`
  width: auto;
  height: 17px;
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.color2};
  margin-left: 10px;
`);

export const StyledTable = withTheme(styled(Table)`
  .custom-header {
    background-color: ${props => props.theme.background.color2};
  }
`);

export const ChannelSpan = withTheme(styled(Span)`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.color2};
`);

export const GuidePopupWrapper = withTheme(styled(ReactModalAdapter).attrs({
  className: 'Popup',
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  
  .ReactModal__Overlay {
    width: 100%;
    height: 100%;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    background-color: rgba(0,0,0,0.5) !important;
  }

  .ReactModal__Content {
    top: 50% !important;
    left: 50% !important;
    width: 300px;
    height: 170px;
    transform: translate(-50%, -50%) !important;
    background-color: #fff !important;
  }

  .ReactModal__Content--after-open {
    padding: 0px !important;
    overflow: hidden !important;
  }
`);

export const CompletePopupWrapper = withTheme(styled(ReactModalAdapter).attrs({
  className: 'Popup',
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  
  .ReactModal__Overlay {
    width: 100%;
    height: 100%;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    background-color: rgba(0,0,0,0.5) !important;
  }

  .ReactModal__Content {
    top: 50% !important;
    left: 50% !important;
    width: 386px;
    height: 380px;
    transform: translate(-50%, -50%) !important;
    background-color: #fff !important;
  }

  .ReactModal__Content--after-open {
    padding: 0px !important;
    overflow: hidden !important;
  }
`);
