import React from 'react';
import PropTypes from 'prop-types';
import { getLanguage } from 'util/lib';
import {
  Container,
  Body,
  Header,
  Footer,
  TitleSpan,
  CloseButton,
  StatusSpan,
  TableWrapper,
  StyledTable,
  StatusWrapper,
} from './CompletePopupStyled';

class CompletePopup extends React.PureComponent {
  render() {
    const {
      onConfirm,
      completeData,
    } = this.props;

    const tableHeader = [
      {
        Header: getLanguage('lang_number'),
        accessor: 'no',
        sortable: true,
      },
      {
        Header: getLanguage('lang_status'),
        accessor: 'status',
        sortable: true,
      },
    ];

    return (
      <Container>
        <Header>
          <TitleSpan>{getLanguage('lang_export')}</TitleSpan>
        </Header>
        <Body>
          <StatusWrapper>
            <StatusSpan>{completeData.message}</StatusSpan>
          </StatusWrapper>
          <TableWrapper>
            <StyledTable
              scroll
              header={tableHeader}
              data={completeData.tableData}
              rowHeight={20}
              pageSize={6}
            />
          </TableWrapper>
        </Body>
        <Footer>
          <CloseButton onClick={onConfirm}>{getLanguage('lang_close')}</CloseButton>
        </Footer>
      </Container>
    );
  }
}

CompletePopup.propTypes = {
  completeData: PropTypes.objectOf(PropTypes.any),
  onConfirm: PropTypes.func,
};

CompletePopup.defaultProps = {
  completeData: {},
  onConfirm: null,
};

export default CompletePopup;
