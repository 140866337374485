import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { DropDownMenu, Toggle } from 'wisenet-ui/components/organisms';
import {
  Button,
  IconButton,
  ReactModalAdapter,
  Span,
} from 'wisenet-ui/components/atoms';

export const FuncWrapperStyled = styled.div`
  display: flex;
  font-size: 24px;
  align-items: center;
`;

export const VerticalLine = styled.div`
  width: 1px;
  height: 20px;
  border-left: 1px solid #c8c8c8;
  margin-top: 2px;
`;

export const IconButtonStyled = styled(IconButton)`
  width: 44px;
  height: 60px;
  font-size: 28px;
  padding: 0;
  color: ${props => props.theme.text.color3};
`;

export const ToggleStyled = styled(Toggle)`
  width: 40px;
  margin-left: 12px;
`;

export const ModelNameStyled = styled.span`
  font-size: 14px;
  color: #a5a5a5;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
`;

export const DropDownMenuStyled = styled(DropDownMenu)`
  display: flex;
  & > div {
    top: 56px;
    min-width: 140px;
    box-shadow: none;
    color: ${props => props.theme.text.color1};
    border: 1px solid ${props => props.theme.text.color3};
    left: 49%;
    transform: translate(-49%, 0%);
    right: unset;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &::before {
      content: "";
      position: absolute;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 0 8px 8px 8px;
      border-style: solid;
      border-color: transparent transparent ${props => props.theme.text.color3} transparent;
    }
    &::after {
      content: "";
      position: absolute;
      top: -7px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 0 8px 8px 8px;
      border-style: solid;
      border-color: transparent transparent ${props => props.theme.background.color1} transparent;
    }
  }
`;

export const UserIDWrapperStyled = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  text-align: center;
`;

export const ButtonStyled = styled(Button)`
  min-width: 100px;
  height: 28px;
  font-size: 14px;
  padding: 0;
  margin-top: 10px;
`;

export const PopupStyled = styled(ReactModalAdapter).attrs({
  className: 'Popup',
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  
  .ReactModal__Overlay {
    width: 100%;
    height: 100%;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    background-color: rgba(0,0,0,.5) !important;
  }

  .ReactModal__Content {
    top: 50% !important;
    left: 50% !important;
    width: 345px;
    height: 504px;
    transform: translate(-50%, -50%) !important;
    background-color: ${props => props.theme.background.color1}!important;
    border: 1px solid ${props => props.theme.line.color5} !important;
    border-radius: 0px !important;
  }

  .ReactModal__Content--after-open {
    padding: 0px !important;
    overflow: hidden !important;
  }
`;

export const PopupButtonStyled = styled(Button)`
  min-width: 100px;
  height: 28px;
  font-size: 14px;
  padding: 0;
`;

export const TooltipSpan = styled(Span)`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  margin: 0px;
  padding: 0px
`;

export const CustomReactTooltip = styled(ReactTooltip)`
  background-color: #555 !important;
  padding: 5px !important;
  &.place-top {
    &:after {
      border-top: none !important;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom: none !important;
    }
  }
`;
