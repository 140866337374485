import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';
import { MediaControlIDList } from 'wisenet-ui/util/static/constants/mediaControl/mediaControlType';
import { PlaybackVideoTileControlBarStyled } from './PlaybackVideoTileControlBarStyled';

class PlaybackVideoTileControlBar extends PureComponent {
  render() {
    const {
      CAPTURE,
      SOUND,
      ROTATE,
      ASPECT_RATIO,
    } = MediaControlIDList;

    const buttonItems = [
      CAPTURE,
      SOUND,
      ROTATE,
      ASPECT_RATIO,
    ];

    const exceptionList = [
      ROTATE,
    ];

    return (
      <PlaybackVideoTileControlBarStyled
        buttonItems={buttonItems}
        exceptionList={exceptionList}
        {...this.props}
      />
    );
  }
}

// PlaybackVideoTileControlBar.propTypes = {

// };

export default PlaybackVideoTileControlBar;
