import styled from 'styled-components';

export const OSDWrapperStyled = styled.div`
  position: absolute;
  width: calc(100% - 2px);
  height: 28px;
  top: 1px;
  left: 1px;
  right: 1px;
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, rgba(30, 30, 30, 0.6), rgba(30, 30, 30, 0.6));
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 45px;
`;

export const OSDLeftWrapperStyled = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OSDRightWrapperStyled = styled.span`
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

export const RecStatusStyled = styled.i`
  font-size: 12px;
  margin-right: 4px;
  color: #ff0000;
`;

export const ChannelNameStyled = styled.span`
  font-size: 14px;
`;

export const EventIconStyled = styled.i`
  font-size: 24px;
  margin-left: 4px;
`;
