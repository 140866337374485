import styled, { withTheme } from 'styled-components';

export default withTheme(styled.div`
  width: 100%;
  height: 100%;
  background-color: black;

  &.noneDisplay {
    display: none;
  }
`);
