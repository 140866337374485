import styled, { css } from 'styled-components';
import { Tabs } from 'wisenet-ui/components/organisms';

export const MenuTabsStyled = styled(Tabs)`
  .tab-wrapper {
    height: 35px;
    .tab {
      width: 100px;
      color: ${props => props.theme.text.color3};
      &.active {
        color: ${props => props.theme.text.color4};
      }
      &:hover {
        background-color: ${props => props.theme.background.color3};
      }
      &:active {
        background-color: ${props => props.theme.background.color4};
      }
    }
    ${props => props.align === '' && css`
      .tab {
        flex: 1;
        border-bottom: 1px solid ${props.theme.line.color4};
        &.active {
          border-left: 1px solid ${props.theme.line.color4};
          border-right: 1px solid ${props.theme.line.color4};
          border-top: 3px solid;
          border-bottom: none;
        }
      }
    `}
    ${props => props.align !== '' && css`
        position: relative;
        bottom: -1px;
        .tab {
          flex: unset;
          border-bottom: 1px solid ${props.theme.line.color4};
          &.active {
            border-left: 1px solid ${props.theme.line.color4};
            border-right: 1px solid ${props.theme.line.color4};
            border-top: 3px solid;
            border-bottom: 1px solid ${props.theme.background.color1};
          }
        }
    `}
    ${props => props.align === 'right' && css`
      flex-flow: row-reverse wrap;
    `}
  }
  .content-wrapper {
    ${props => props.align !== '' && css`
      border-top: 1px solid ${props.theme.line.color4};
    `}
  }
`;
