import React from 'react';
import PropTypes from 'prop-types';
import { PTZControlActions } from 'store/actionCreators';
import tilePTZControlType from 'wisenet-ui/util/static/constants/mediaControl/tilePTZControlType';

const minMove = 8;
const moveLevel = 30;

const minZoom = 1;
const maxZoom = 32;

class TilePTZControlContainer extends React.Component {
  state = {
    openPTZDirectionControl: true,
    openPTZFunctionBar: true,
    ptzMode: 'ptz',
    mouseIn: true,
    controllingDirection: false,
  };

  downCheck = false;

  startPoint = {
    x: 0,
    y: 0,
  };

  curPTZ = {
    pan: 0,
    tilt: 0,
    zoom: 0,
  };

  zoomTimer = null;

  componentDidMount() {
    this.setSubViewIndex();
  }

  componentDidUpdate(prevProps, prevState) {
    const { viewModeType } = this.props;
    const { mouseIn, controllingDirection } = this.state;
    if (prevProps.viewModeType !== viewModeType) {
      this.setSubViewIndex();
    }
    if (prevState.mouseIn !== mouseIn) {
      if (!mouseIn && controllingDirection) {
        this.stopPTZ();
        this.onUpdate({
          controllingDirection: false,
        });
      }
    }
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  stopPTZ = () => {
    const { currentChannel, viewModeType } = this.props;
    const stopParams = {
      Channel: currentChannel,
    };

    if (viewModeType.indexOf('Panorama') !== -1) {
      stopParams.ViewModeType = viewModeType;
    }
    if (viewModeType.indexOf('QuadView') !== -1) {
      stopParams.SubViewIndex = this.subViewIndex;
    }

    PTZControlActions.requestPtzControl({
      requestType: 'CONTROL_STOP',
      params: stopParams,
    });

    this.zoomTimer = null;
    this.delta = 0;
  }

  setSubViewIndex = event => {
    const { viewModeType, width, height } = this.props;
    if (viewModeType === 'QuadView' && event) {
      let index = null;
      const centerX = width / 2;
      const centerY = height / 2;
      if (event.nativeEvent.offsetY < centerY) {
        index = event.nativeEvent.offsetX < centerX ? 1 : 2;
      } else {
        index = event.nativeEvent.offsetX < centerX ? 3 : 4;
      }
      this.subViewIndex = index;
    } else if (viewModeType.indexOf('QuadView.') !== -1) {
      const index = viewModeType.split('.')[1];
      this.subViewIndex = index;
    }
  }

  onTilePTZControlEvent = type => event => {
    if (type !== 'leave') {
      event.stopPropagation();
    }
    const { ptzMode } = this.state;
    switch (type) {
      case 'down': {
        if (event.button === 2) return;
        this.setSubViewIndex(event);
        this.setOpenPTZFucntionBar(false);
        this.startPoint.x = event.clientX;
        this.startPoint.y = event.clientY;
        this.downCheck = true;
        break;
      }
      case 'move': {
        if (this.downCheck) {
          const { isAnalog } = this.props;
          const minVal = isAnalog ? 2 : 1;
          if (ptzMode === tilePTZControlType.AREA_ZOOM) return;
          let pan = 0;
          let tilt = 0;
          const moveX = this.startPoint.x - event.clientX;
          const moveY = this.startPoint.y - event.clientY;
          // console.log(moveX, moveY);

          if (moveX < -minMove) { // left -> right
            if (moveX < -moveLevel * 6) pan = 6;
            else if (moveX < -moveLevel * 5) pan = 5;
            else if (moveX < -moveLevel * 4) pan = 4;
            else if (moveX < -moveLevel * 3) pan = 3;
            else if (moveX < -moveLevel * 2) pan = 2;
            else {
              pan = minVal;
            }
          } else if (moveX > minMove) { // right -> left
            if (moveX > moveLevel * 6) pan = -6;
            else if (moveX > moveLevel * 5) pan = -5;
            else if (moveX > moveLevel * 4) pan = -4;
            else if (moveX > moveLevel * 3) pan = -3;
            else if (moveX > moveLevel * 2) pan = -2;
            else {
              pan = minVal * -1;
            }
          }

          if (moveY < -minMove) { // top -> bottom
            if (moveY < -moveLevel * 6) tilt = -6;
            else if (moveY < -moveLevel * 5) tilt = -5;
            else if (moveY < -moveLevel * 4) tilt = -4;
            else if (moveY < -moveLevel * 3) tilt = -3;
            else if (moveY < -moveLevel * 2) tilt = -2;
            else {
              tilt = minVal * -1;
            }
          } else if (moveY > minMove) { // bottom -> top
            if (moveY > moveLevel * 6) tilt = 6;
            else if (moveY > moveLevel * 5) tilt = 5;
            else if (moveY > moveLevel * 4) tilt = 4;
            else if (moveY > moveLevel * 3) tilt = 3;
            else if (moveY > moveLevel * 2) tilt = 2;
            else {
              tilt = minVal;
            }
          }
          if (this.curPTZ.pan !== pan || this.curPTZ.tilt !== tilt) {
            this.curPTZ.pan = pan;
            this.curPTZ.tilt = tilt;
            this.startPTZ(pan, tilt, 0);
          }
        }
        break;
      }
      case 'enter': {
        this.setState({ mouseIn: true });
        this.setOpenPTZFucntionBar(true);
        break;
      }
      case 'leave':
      case 'up': {
        if (type === 'leave') {
          this.setState({ mouseIn: false });
          this.setOpenPTZFucntionBar(false);
        }
        if (type === 'up') {
          this.setOpenPTZFucntionBar(true);
        }
        if (!this.downCheck) return;
        this.downCheck = false;

        const domRect = event.currentTarget.getBoundingClientRect();
        if (ptzMode === tilePTZControlType.AREA_ZOOM) {
          this.startAreaZoom(this.startPoint.x - domRect.left, this.startPoint.y - domRect.top,
            event.clientX - domRect.left, event.clientY - domRect.top,
            event.currentTarget.clientWidth, event.currentTarget.clientHeight);
          return;
        }
        // ptz Mode
        if (Math.abs(this.startPoint.x - event.clientX) <= minMove
        && Math.abs(this.startPoint.y - event.clientY) <= minMove) {
          // click 위치로 이동
          this.startPoint.x = event.clientX;
          this.startPoint.y = event.clientY;
          this.startAreaZoom(this.startPoint.x - domRect.left, this.startPoint.y - domRect.top,
            event.clientX - domRect.left, event.clientY - domRect.top,
            event.currentTarget.clientWidth, event.currentTarget.clientHeight);
        } else {
          this.stopPTZ();
        }
        break;
      }
      case 'wheel': {
        if (ptzMode === tilePTZControlType.D_ZOOM) {
          const { digitalZoom, setDigitalZoomInOut } = this.props;

          const rate = event.deltaY < 0 ? 1.1 : 0.9;
          let result = Math.round(digitalZoom * rate * 1e2) / 1e2;

          if (result >= maxZoom) result = maxZoom;
          if (result <= minZoom) result = minZoom;

          if (result === digitalZoom) return;

          setDigitalZoomInOut({
            digitalZoom: result,
            eventPos: {
              offsetX: event.nativeEvent.offsetX,
              offsetY: event.nativeEvent.offsetY,
            },
          });
        } else {
          this.setSubViewIndex(event);
          const zoom = event.deltaY < 0 ? 2 : -2;
          if (!this.zoomTimer) {
            this.zoomTimer = setTimeout(this.stopPTZ, 800);
            this.startPTZ(0, 0, zoom);
          } else {
            this.resetZoomTimer();
            if (zoom !== this.curPTZ.zoom) {
              this.startPTZ(0, 0, zoom);
            }
          }
        }
        break;
      }
      default:
        break;
    }
  }

  startAreaZoom = (x1, y1, x2, y2, tileWidth, tileHeight) => {
    const { currentChannel } = this.props;
    const areaZoomParam = {
      Channel: currentChannel,
      X1: x1,
      Y1: y1,
      X2: x2,
      Y2: y2,
      TileWidth: tileWidth,
      TileHeight: tileHeight,
    };

    PTZControlActions.requestPtzControl({
      requestType: 'CONTROL_AREAZOOM',
      params: areaZoomParam,
    });
  }

  startPTZ = (pan, tilt, zoom) => {
    const { currentChannel, viewModeType } = this.props;
    const continuousParam = {
      Channel: currentChannel,
      Pan: pan,
      Tilt: tilt,
      Zoom: zoom,
    };
    if (viewModeType.indexOf('Panorama') !== -1) {
      continuousParam.ViewModeType = viewModeType;
    }
    if (viewModeType.indexOf('QuadView') !== -1) {
      continuousParam.SubViewIndex = this.subViewIndex;
    }

    this.curPTZ.pan = pan;
    this.curPTZ.tilt = tilt;
    this.curPTZ.zoom = zoom;

    PTZControlActions.requestPtzControl({
      requestType: 'CONTROL_CONTINUOUS',
      params: continuousParam,
    });
  }

  // 연속적으로 Wheel Event 발생 시, 마지막 Event에 대해 Zoom Stop 하기 위해 Timer Reset
  resetZoomTimer = () => {
    clearTimeout(this.zoomTimer);
    this.zoomTimer = setTimeout(this.stopPTZ, 800);
  }

  onTilePTZDirectionMouseDown = type => event => {
    event.stopPropagation();
    const { isAnalog } = this.props;
    let pan = 0;
    let tilt = 0;
    const minVal = isAnalog ? 2 : 1;
    switch (type) {
      case tilePTZControlType.TOP_LEFT:
        pan = minVal * -1;
        tilt = minVal;
        break;
      case tilePTZControlType.TOP:
        tilt = minVal;
        break;
      case tilePTZControlType.TOP_RIGHT:
        pan = minVal;
        tilt = minVal;
        break;
      case tilePTZControlType.RIGHT:
        pan = minVal;
        break;
      case tilePTZControlType.BOTTOM_RIGHT:
        pan = minVal;
        tilt = minVal * -1;
        break;
      case tilePTZControlType.BOTTOM:
        tilt = minVal * -1;
        break;
      case tilePTZControlType.BOTTOM_LEFT:
        pan = minVal * -1;
        tilt = minVal * -1;
        break;
      case tilePTZControlType.LEFT:
        pan = minVal * -1;
        break;
      default:
        break;
    }
    this.startPTZ(pan, tilt, 0);
    this.setState({
      controllingDirection: true,
    });
  }

  onTilePTZDirectionMouseUp = () => event => {
    event.stopPropagation();
    this.stopPTZ();
    this.setState({
      controllingDirection: false,
    });
  }

  setAutoTracking = type => {
    const { currentChannel } = this.props;
    const { ptzMode } = this.state;
    const enable = (ptzMode !== type);
    const autoTrackingParam = {
      Channel: currentChannel,
      Enable: enable ? 'True' : 'False',
    };

    this.setState({
      ptzMode: enable ? type : 'ptz',
    });

    PTZControlActions.requestPtzControl({
      requestType: 'SET_AUTOTRACKING',
      params: autoTrackingParam,
    });
  }

  setAreaZoom = type => {
    const { ptzMode } = this.state;
    this.setState({
      ptzMode: (ptzMode !== type) ? type : 'ptz',
    });
  }

  setReturnX1 = () => {
    const { currentChannel, ptzInfo } = this.props;
    if (ptzInfo.areaZoom) {
      const areaZoomX1Param = {
        Channel: currentChannel,
        Type: '1x',
      };
      PTZControlActions.requestPtzControl({
        requestType: 'CONTROL_AREAZOOM',
        params: areaZoomX1Param,
      });
    } else {
      this.startPTZ(0, 0, -4);
    }
  }

  setDigitalZoom= bDZoom => {
    this.setState({
      ptzMode: bDZoom ? tilePTZControlType.D_ZOOM : 'ptz',
    });
  }

  setOpenPTZFucntionBar= isOpen => {
    this.setState({
      openPTZFunctionBar: isOpen,
    });
  }

  onTilePTZFunctionEvent = type => event => {
    event.stopPropagation();
    // console.log('onTilePTZFunctionClick::', type);
    switch (type) {
      case tilePTZControlType.MANUAL_TRACKING:
        break;
      case tilePTZControlType.AUTO_TRACKING: {
        this.setAutoTracking(type);
        break;
      }
      case tilePTZControlType.AREA_ZOOM:
        this.setAreaZoom(type);
        break;
      case tilePTZControlType.RETURN_1X:
        this.setReturnX1();
        break;
      case tilePTZControlType.D_ZOOM: {
        const { ptzMode } = this.state;
        this.setDigitalZoom(ptzMode !== type);
        break;
      }
      case tilePTZControlType.BACK: {
        const { returnTileMode } = this.props;
        returnTileMode();
        break;
      }
      default: {
        const { onTileBarClick } = this.props;
        onTileBarClick(type)(event);
        break;
      }
    }
  }

  render() {
    const { render } = this.props;
    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

TilePTZControlContainer.propTypes = {
  render: PropTypes.func.isRequired,
  currentChannel: PropTypes.number,
  returnTileMode: PropTypes.func.isRequired,
  onTileBarClick: PropTypes.func.isRequired,
  ptzInfo: PropTypes.instanceOf(Object).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewModeType: PropTypes.string.isRequired,
  digitalZoom: PropTypes.number.isRequired,
  setDigitalZoomInOut: PropTypes.func.isRequired,
  isAnalog: PropTypes.bool,
};

TilePTZControlContainer.defaultProps = {
  currentChannel: 1,
  isAnalog: false,
};

export default TilePTZControlContainer;
