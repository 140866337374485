import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { EventExportPopupContainer } from 'containers/organisms';
import umpPlayMode from 'wisenet-ui/util/static/constants/umpPlayer/umpPlayMode';
import { VideoTile, Table } from 'wisenet-ui/components/organisms';
import { getLanguage } from 'util/lib';
import GuidePopup from './GuidePopup';
import CompletePopup from './CompletePopup';
import {
  Container,
  Header,
  Body,
  Footer,
  TitleSpan,
  ButtonContainer,
  FooterButton,
  StatusLabel,
  StatusWrapper,
  TableWrapper,
  GuidePopupWrapper,
  CompletePopupWrapper,
  StyledPropgressbar,
  DateSpan,
  Icontyled,
} from './EventExportPopupStyled';

const EventExportPopup = ({
  onCancel,
  onChangeCheckTableData,
  doBackup,
  onGuidePopupConfirm,
  showGuidePopup,
  showCompletePopup,
  guideData,
  completeData,
  onBackup,
  sessionKey,
  startBackup,
  currentBackupPercent,
  totalBackupPercent,
  onStopBackup,
  onCompletePopupConfirm,
  searchDateObj,
  exportEventList,
  targetEvent,
}) => {
  const checkHeader = [
    {
      Header: getLanguage('lang_num'),
      accessor: 'no',
      sortable: true,
      width: 60,
    },
    {
      Header: getLanguage('lang_ch'),
      accessor: 'ch',
      sortable: true,
      width: 60,
    },
    {
      Header: getLanguage('lang_start_time'),
      accessor: 'startTime',
      sortable: true,
    },
    {
      Header: getLanguage('lang_end_time'),
      accessor: 'endTime',
      sortable: true,
    },
    {
      Header: getLanguage('lang_event'),
      accessor: 'event',
      sortable: true,
      width: 200,
    },
    {
      Header: 'DST',
      accessor: 'dst',
      sortable: true,
    },
  ];

  const {
    oriStartTime,
    oriEndTime,
    ch,
    no,
  } = targetEvent;
  const {
    year: selYear,
    month,
    day,
  } = searchDateObj;
  const selMonth = month.toString().length === 1 ? `0${month}` : month;
  const selDay = day.toString().length === 1 ? `0${day}` : day;

  return (
    <Container>
      <GuidePopupWrapper
        isOpen={showGuidePopup}
        shouldCloseOnOverlayClick
      >
        <GuidePopup
          title={guideData.title}
          content={guideData.content}
          onConfirm={onGuidePopupConfirm}
        />
      </GuidePopupWrapper>
      <CompletePopupWrapper
        isOpen={showCompletePopup}
        shouldCloseOnOverlayClick
      >
        <CompletePopup
          onConfirm={onCompletePopupConfirm}
          completeData={completeData}
        />
      </CompletePopupWrapper>
      <Header>
        <TitleSpan>{getLanguage('lang_export_event_record')}</TitleSpan>
        <Icontyled className="wni wni-close" onClick={onCancel} />
      </Header>
      <Body>
        <div>
          <DateSpan>{`${selYear}-${selMonth}-${selDay}`}</DateSpan>
        </div>
        <TableWrapper>
          <Table
            header={checkHeader}
            data={exportEventList}
            selectCheck
            onChangeData={onChangeCheckTableData}
            // rowHeight={30}
            pageSize={10}
            scroll
          />
        </TableWrapper>
        <div>
          <StatusWrapper>
            <StatusLabel>
              <span>{getLanguage('lang_total')}</span>
              <span>{`${totalBackupPercent}%`}</span>
            </StatusLabel>
            <StyledPropgressbar
              completed={totalBackupPercent - (totalBackupPercent % 5)}
            />
          </StatusWrapper>
          <StatusWrapper>
            <StatusLabel>
              <span>{`${getLanguage('lang_number')} ${no}`}</span>
              <span>{`${currentBackupPercent}%`}</span>
            </StatusLabel>
            <StyledPropgressbar
              completed={currentBackupPercent - (currentBackupPercent % 5)}
            />
          </StatusWrapper>
        </div>
      </Body>
      <Footer>
        <ButtonContainer>
          <FooterButton
            disabled={startBackup}
            onClick={doBackup}
          >
            {getLanguage('lang_ok')}
          </FooterButton>
          <FooterButton
            onClick={onStopBackup}
            style={{
              display: startBackup ? 'block' : 'none',
            }}
          >
            {getLanguage('lang_stop')}
          </FooterButton>
          <FooterButton
            disabled={startBackup}
            onClick={onCancel}
          >
            {getLanguage('lang_cancel')}
          </FooterButton>
        </ButtonContainer>
      </Footer>
      <VideoTile
        type={umpPlayMode.BACKUP}
        channel={ch - 1}
        backup={startBackup}
        sessionKey={sessionKey}
        startTime={oriStartTime}
        endTime={oriEndTime}
        onBackup={onBackup}
        fileName=""
        tileMode=""
      />
    </Container>
  );
};

EventExportPopup.propTypes = {
  showCompletePopup: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  exportEventList: PropTypes.arrayOf(PropTypes.any).isRequired,
  onGuidePopupConfirm: PropTypes.func.isRequired,
  showGuidePopup: PropTypes.bool.isRequired,
  doBackup: PropTypes.func.isRequired,
  onChangeCheckTableData: PropTypes.func.isRequired,
  guideData: PropTypes.instanceOf(Object),
  searchDateObj: PropTypes.objectOf(PropTypes.any),
  completeData: PropTypes.instanceOf(Object),
  onBackup: PropTypes.func.isRequired,
  sessionKey: PropTypes.string,
  startBackup: PropTypes.bool,
  currentBackupPercent: PropTypes.number,
  totalBackupPercent: PropTypes.number,
  onStopBackup: PropTypes.func.isRequired,
  onCompletePopupConfirm: PropTypes.func.isRequired,
  targetEvent: PropTypes.objectOf(PropTypes.any),
};

EventExportPopup.defaultProps = {
  guideData: {},
  searchDateObj: {},
  completeData: {},
  sessionKey: '',
  startBackup: false,
  currentBackupPercent: 0,
  totalBackupPercent: 0,
  targetEvent: {},
};

export default withContainer(EventExportPopupContainer, EventExportPopup);
