import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { RecordStatusContainer } from 'containers/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { Table } from 'wisenet-ui/components/organisms';
import { getLanguage } from 'util/lib';
import {
  TableWrapperStyled,
  SetupButtonStyled,
  RecordTopWrapperStyled,
  ToggleWrapperStyled,
  ToggleStyled,
  RecordMiddleWrapperStyled,
  WarningWrapperStyled,
  WarningIconStyled,
  WarningStatusStyled,
  ColorBoxStyled,
  HDDSizeWrapperStyled,
  HDDSizeTitleStyled,
  RecordingSizeWrapperStyled,
  RecordingSizeTitleStyled,
} from './RecordStatusStyled';

const rowHeight = 41;
const defaultTableHeight = 312;
const maxTableHeight = 312;

const RecordStatus = ({
  recordStatusData,
  toggleValue,
  onChangeToggle,
}) => {
  let tableHeight = defaultTableHeight;
  if (recordStatusData.channelStatus && recordStatusData.channelStatus.length !== 0) {
    tableHeight = (recordStatusData.channelStatus.length * rowHeight + rowHeight);
    if (tableHeight > maxTableHeight) {
      tableHeight = maxTableHeight;
    }
  }
  const recordStatusHeader = [
    {
      Header: getLanguage('lang_camera'),
      columns: [
        {
          Header: getLanguage('lang_ch'),
          accessor: 'ch',
          sortable: true,
          width: 54,
        },
        {
          Header: getLanguage('lang_profile'),
          accessor: 'profile',
          sortable: true,
          // width: 90,
        },
      ],
    },
    {
      Header: getLanguage('lang_record'),
      columns: [
        {
          Header: getLanguage('lang_type'),
          accessor: 'recordType',
          sortable: true,
          width: 90,
        },
        {
          Header: getLanguage('lang_frame'),
          accessor: 'recordFrame',
          sortable: true,
          width: 90,
        },
      ],
    },
    {
      Header: `${getLanguage('lang_frame_rate')} (fps)`,
      columns: [
        {
          Header: getLanguage('lang_receive'),
          accessor: 'framerateInput',
          sortable: true,
          width: 90,
        },
        {
          Header: getLanguage('lang_record'),
          accessor: 'framerateRecord',
          sortable: true,
          width: 90,
        },
      ],
    },
    {
      Header: getLanguage('lang_bitrate_bps'),
      columns: [
        {
          Header: getLanguage('lang_limit'),
          accessor: 'bitrateLimit',
          sortable: true,
          width: 90,
        },
        {
          Header: getLanguage('lang_receive'),
          accessor: 'bitrateInput',
          sortable: true,
          width: 90,
        },
        {
          Header: getLanguage('lang_record'),
          accessor: 'bitrateRecord',
          sortable: true,
          width: 90,
        },
        {
          Header: getLanguage('lang_receive_limit'),
          accessor: 'bitrateInputAndLimit',
          sortable: true,
          // width: 90,
        },
      ],
    },
  ];
  const translationData = recordStatusData.channelStatus
    && recordStatusData.channelStatus.map(item => ({
      ...item,
      recordType: getLanguage(item.recordType),
      recordFrame: getLanguage(item.recordFrame),
    }));
  return (
    <>
      <RecordTopWrapperStyled>
        <div>
          <span>{`${getLanguage('lang_total_bitrate')} : ${recordStatusData.totalBitrateRecord}/${recordStatusData.totalBitrateMax} Mbps`}</span>
        </div>
        <ToggleWrapperStyled>
          <span>
            {getLanguage('lang_current')}
          </span>
          <ToggleStyled
            value={toggleValue}
            onChange={onChangeToggle}
            handleStyle={{
              height: 21,
              width: 21,
              marginLeft: toggleValue === 0 ? -1 : -20,
            }}
            trackStyle={{
              height: 13,
            }}
            railStyle={{
              height: 13,
            }}
          />
          <span>
            {getLanguage('lang_max')}
          </span>
        </ToggleWrapperStyled>
      </RecordTopWrapperStyled>
      <TableWrapperStyled height={tableHeight}>
        <Table
          header={recordStatusHeader}
          data={translationData}
          isMultiHeader
          scroll
          changeRowColor
        />
      </TableWrapperStyled>
      <RecordMiddleWrapperStyled>
        <WarningWrapperStyled>
          <WarningIconStyled className="wni wni-caution" />
          <ColorBoxStyled style={{ backgroundColor: '#e12d33' }} />
          <WarningStatusStyled>
            {getLanguage('lang_receive_data_exceeded')}
          </WarningStatusStyled>
          <ColorBoxStyled style={{ backgroundColor: 'rgba(255, 229, 182, 0.9)' }} />
          <WarningStatusStyled>
            {getLanguage('lang_profile_error')}
          </WarningStatusStyled>
          <ColorBoxStyled style={{ backgroundColor: '#4b37be' }} />
          <WarningStatusStyled>
            {getLanguage('lang_hdd_capacity_exceeded')}
          </WarningStatusStyled>
        </WarningWrapperStyled>
        <Link
          to={{
            pathname: '/setup',
            requestPageUrl: '#record/record_record_setup',
          }}
        >
          <SetupButtonStyled>
            {getLanguage('lang_record_settings')}
          </SetupButtonStyled>
        </Link>
      </RecordMiddleWrapperStyled>
      <HDDSizeWrapperStyled>
        <HDDSizeTitleStyled>{getLanguage('lang_hdd_capacity_free_total')}</HDDSizeTitleStyled>
        <span>
          {recordStatusData.overWrite === 'On' ? '-' : recordStatusData.hddFreeSize}
          {` / ${recordStatusData.hddTotalSize} GB`}
        </span>
      </HDDSizeWrapperStyled>
      <RecordingSizeWrapperStyled>
        <div>
          <RecordingSizeTitleStyled>{getLanguage('lang_recording_free_total')}</RecordingSizeTitleStyled>
          <span>
            {
              (recordStatusData.overWrite === 'On' || recordStatusData.totalBitrateRecord === '0.0')
                ? '-'
                : `${recordStatusData.recordFreeDays} ${getLanguage('lang_days')}  ${recordStatusData.recordFreeHours} ${getLanguage('lang_hours')} `
            }
            {` / ${recordStatusData.recordTotalDays} ${getLanguage('lang_days')}  ${recordStatusData.recordTotalHours} ${getLanguage('lang_hours')} `}
          </span>
        </div>
        <span>{`${getLanguage('lang_overwrite')} : ${getLanguage(recordStatusData.overWrite)}`}</span>
      </RecordingSizeWrapperStyled>
    </>
  );
};


// RecordStatus.defaultProps = { };

RecordStatus.propTypes = {
  recordStatusData: PropTypes.instanceOf(Object).isRequired,
  toggleValue: PropTypes.number.isRequired,
  onChangeToggle: PropTypes.func.isRequired,
};

export default withContainer(RecordStatusContainer, RecordStatus);
