import styled, { css } from 'styled-components';
import { Checkbox } from 'wisenet-ui/components/atoms';

export const MenuWrapperStyled = styled.div`
  display: flex;
  margin: 0 16px;
  padding-top: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e9e9e9;
  &:nth-last-child(1) {
    border-bottom: none;
  }
`;

export const ParentWrapperStyled = styled.div`
  flex-basis: 220px;
  margin-bottom: 6px;
  word-break: break-all;
`;

export const ChildWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;

export const ChildItemWrapperStyled = styled.div`
  margin-right: 30px;
  margin-bottom: 6px;
`;

export const CheckboxStyled = styled(Checkbox)`
  & > span {
    &::before {
      border-color: #d2d2d2;
    }
    &::after {
      border-color: #b2b2b2;
    }
  }
`;

export const CheckTitleStyled = styled.span`
  font-size: 14px;
  margin-left: 8px;
  color: #797979;
  ${props => props.isChild && css`
    font-size: 12px;
    color: #a5a5a5;
  `}
`;
