import React from 'react';
import { withContainer } from 'wisenet-ui/util/hoc';
import { TabExamplePageContainer } from 'containers/pages';
import { ListTabs, MenuTabs } from 'components/molecules';
import {
  IconStyled,
  TitleStyled,
  CustomListTabs,
  PTZIconStyled,
  StatusTabs,
  HeaderWrapperStyled,
} from './TabExamplePageStyled';

const TabExamplePage = () => {
  const listTabData = [
    {
      key: 'tab_list',
      header: (
        <HeaderWrapperStyled>
          <IconStyled className="wni wni-list-view" />
          <TitleStyled>List</TitleStyled>
        </HeaderWrapperStyled>
      ),
      component: (
        <div>
          {'Camera list & layout'}
        </div>
      ),
    },
    {
      key: 'tab_event',
      header: (
        <HeaderWrapperStyled isLast>
          <IconStyled className="wni wni-alarm" />
          <TitleStyled>Event</TitleStyled>
        </HeaderWrapperStyled>
      ),
      component: (
        <div>
          {'Event list'}
        </div>
      ),
    },
  ];
  const customListTabData = [
    {
      key: 'tab_list2',
      header: (
        <HeaderWrapperStyled>
          <IconStyled className="wni wni-list-view" />
          <TitleStyled>List</TitleStyled>
        </HeaderWrapperStyled>
      ),
      component: (
        <div>
          {'Camera list & layout'}
        </div>
      ),
    },
    {
      key: 'tab_event2',
      header: (
        <HeaderWrapperStyled>
          <IconStyled className="wni wni-alarm" />
          <TitleStyled>Event</TitleStyled>
        </HeaderWrapperStyled>
      ),
      component: (
        <div>
          {'Event list'}
        </div>
      ),
    },
    {
      key: 'tab_ptz2',
      header: (
        <HeaderWrapperStyled isLast>
          <IconStyled className="wni wni-ptz-control" />
          <TitleStyled>PTZ</TitleStyled>
        </HeaderWrapperStyled>
      ),
      component: (
        <div>
          {'PTZ'}
        </div>
      ),
    },
  ];
  const ptzTabData = [
    {
      key: 'tab_preset',
      header: <PTZIconStyled className="wni wni-ptz-preset" />,
      component: (
        <div>
          {'PTZ preset'}
        </div>
      ),
      toolTip: 'Preset',
    },
    {
      key: 'tab_swing',
      header: <PTZIconStyled className="wni wni-ptz-swing" />,
      component: (
        <div>
          {'PTZ swing'}
        </div>
      ),
      toolTip: 'Swing',
    },
    {
      key: 'tab_group',
      header: <PTZIconStyled className="wni wni-ptz-group" />,
      component: (
        <div>
          {'PTZ group'}
        </div>
      ),
      toolTip: 'Group',
    },
    {
      key: 'tab_tour',
      header: <PTZIconStyled className="wni wni-ptz-tour" />,
      component: (
        <div>
          {'PTZ tour'}
        </div>
      ),
      toolTip: 'Tour',
    },
    {
      key: 'tab_trace',
      header: <PTZIconStyled className="wni wni-ptz-trace" />,
      component: (
        <div>
          {'PTZ trace'}
        </div>
      ),
      toolTip: 'Trace',
    },
  ];
  const statusTabData = [
    {
      key: 'tab_live_status',
      header: (
        <TitleStyled>Live</TitleStyled>
      ),
      component: (
        <div>
          {'Live status'}
        </div>
      ),
    },
    {
      key: 'tab_record_status',
      header: (
        <TitleStyled>Record</TitleStyled>
      ),
      component: (
        <div>
          {'Record status'}
        </div>
      ),
    },
  ];
  return (
    <>
      <hr />
      <h1>[List tabs]</h1>
      <div style={{ width: '292px', marginTop: '20px' }}>
        <ListTabs tabData={listTabData} />
      </div>
      <hr />
      <h1>[List tabs - Custom]</h1>
      <div style={{ width: '292px', marginTop: '20px' }}>
        <CustomListTabs tabData={customListTabData} />
      </div>
      <hr />
      <h1>[Menu tabs - default]</h1>
      <div style={{ width: '252px', marginTop: '20px' }}>
        <MenuTabs tabData={ptzTabData} />
      </div>
      <hr />
      <h1>[Menu tabs - Custom & align(left)]</h1>
      <div style={{ width: '800px', marginTop: '20px' }}>
        <StatusTabs
          tabData={statusTabData}
          align="left"
        />
      </div>
      <hr />
      <h1>[Menu tabs - Custom & align(right)]</h1>
      <div style={{ width: '800px', marginTop: '20px' }}>
        <StatusTabs
          tabData={statusTabData.reverse()}
          align="right"
        />
      </div>
    </>
  );
};

TabExamplePage.defaultProps = {
};

TabExamplePage.propTypes = {
};

export default withContainer(TabExamplePageContainer, TabExamplePage);
