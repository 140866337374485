import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { PTZFocusZoomControllerContainer } from 'containers/organisms';
import { getLanguage } from 'util/lib';
import {
  ZoomButton,
  NearButton,
  FarButton,
  AutoButton,
  ButtonWrapper,
  Container,
  StyledSlider,
  ButtonIconStyled,
  CustomReactTooltip,
  TooltipSpan,
} from './PTZFocusZoomControllerStyled';

const PTZFocusZoomController = ({
  zoom,
  onSliderAfterChange,
  onSliderChange,
  onZoomBtnChange,
  onFocusBtnChange,
  onAutoFocusBtnChange,
  onZoomStop,
  onFocusStop,
}) => (
  <Container>
    <StyledSlider
      min={-4}
      max={4}
      value={zoom}
      leftButton={(
        <ZoomButton
          onMouseDown={() => onZoomBtnChange('-')}
          onMouseUp={onZoomStop}
        >
          <ButtonIconStyled
            data-tip="zoom_out"
            data-for="zoom_out"
            className="wni wni-ptz-zoomout"
            isZoom
          />
        </ZoomButton>
      )}
      rightButton={(
        <ZoomButton
          onMouseDown={() => onZoomBtnChange('+')}
          onMouseUp={onZoomStop}
        >
          <ButtonIconStyled
            data-tip="zoom_in"
            data-for="zoom_in"
            className="wni wni-ptz-zoomin"
            isZoom
          />
        </ZoomButton>
      )}
      trackStyle={{
        // backgroundColor: 'rgb(200, 200, 200)',
      }}
      onChange={value => onSliderChange({ value, id: 'zoom' })}
      onAfterChange={() => onSliderAfterChange({ id: 'zoom' })}
    />
    <CustomReactTooltip
      id="zoom_out"
      type="light"
      place="top"
      effect="float"
      delayShow={500}
      getContent={() => (
        <TooltipSpan>{getLanguage('lang_zoom_out')}</TooltipSpan>
      )}
    />
    <CustomReactTooltip
      id="zoom_in"
      type="light"
      place="top"
      effect="float"
      delayShow={500}
      getContent={() => (
        <TooltipSpan>{getLanguage('lang_zoom_in')}</TooltipSpan>
      )}
    />
    <ButtonWrapper>
      <NearButton
        onMouseDown={() => onFocusBtnChange('Near')}
        onMouseUp={onFocusStop}
      >
        <ButtonIconStyled
          data-tip="near"
          data-for="near"
          className="wni wni-ptz-near"
        />
        <CustomReactTooltip
          id="near"
          type="light"
          place="top"
          effect="float"
          delayShow={500}
          getContent={() => (
            <TooltipSpan>{getLanguage('lang_near')}</TooltipSpan>
          )}
        />
      </NearButton>
      <FarButton
        onMouseDown={() => onFocusBtnChange('Far')}
        onMouseUp={onFocusStop}
      >
        <ButtonIconStyled
          data-tip="far"
          data-for="far"
          className="wni wni-ptz-far"
        />
        <CustomReactTooltip
          id="far"
          type="light"
          place="top"
          effect="float"
          delayShow={500}
          getContent={() => (
            <TooltipSpan>{getLanguage('lang_far')}</TooltipSpan>
          )}
        />
      </FarButton>
      <AutoButton
        data-tip="auto_focus"
        data-for="auto_focus"
        onClick={() => onAutoFocusBtnChange()}
      >
        {'SF'}
        <CustomReactTooltip
          id="auto_focus"
          type="light"
          place="top"
          effect="float"
          delayShow={500}
          getContent={() => (
            <TooltipSpan>{getLanguage('lang_simple_focus')}</TooltipSpan>
          )}
        />
      </AutoButton>
    </ButtonWrapper>
  </Container>
);

PTZFocusZoomController.defaultProps = {
  zoom: 0,
};

PTZFocusZoomController.propTypes = {
  zoom: PropTypes.number,
  onSliderAfterChange: PropTypes.func.isRequired,
  onSliderChange: PropTypes.func.isRequired,
  onZoomBtnChange: PropTypes.func.isRequired,
  onFocusBtnChange: PropTypes.func.isRequired,
  onAutoFocusBtnChange: PropTypes.func.isRequired,
  onZoomStop: PropTypes.func.isRequired,
  onFocusStop: PropTypes.func.isRequired,
};

export default withContainer(PTZFocusZoomControllerContainer, PTZFocusZoomController);
