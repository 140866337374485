import {
  take,
  all,
  put,
  select,
} from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { Auth, SunapiClient } from 'util/lib';
import * as layoutListActions from 'store/modules/layoutList/layoutListModule';

function* asyncGetLayoutListSaga() {
  while (true) {
    yield take(layoutListActions.GET_LAYOUTLIST);
    try {
      const loginUser = Auth.getUserid();
      const result = yield SunapiClient.get(`/stw-cgi/display.cgi?msubmenu=webviewerlayout&action=view&UserId=${loginUser}`);
      yield put(layoutListActions.getLayoutListSuccess(
        fromJS(result.data.WeblayoutInfos[0].LayoutInfos),
      ));
    } catch (error) {
      yield put(layoutListActions.getLayoutListFailure());
    }
  }
}

function* asyncAddLayoutListSaga() {
  while (true) {
    const { payload } = yield take(layoutListActions.ADD_LAYOUTLIST);
    const loginUser = Auth.getUserid();
    const dataForSave = {
      UserId: loginUser,
      LayoutInfos: [],
    };

    dataForSave.LayoutInfos.push(payload);

    try {
      const result = yield SunapiClient.post('/stw-cgi/display.cgi?msubmenu=webviewerlayout&action=add', undefined, dataForSave);
      const layoutListModuleState = yield select(state => state.layoutListModule);
      const { layoutListData } = layoutListModuleState.toJS();
      let checkLayoutListUpdate = false;
      if (result.data.Response === 'Success') {
        layoutListData.push(payload);
        checkLayoutListUpdate = true;
        yield put(layoutListActions.getLayoutList());
        // yield put(layoutListActions.addLayoutListSuccess({
        //   layoutListData,
        //   checkLayoutListUpdate,
        // }));
      } else {
        const { data: { Error: error } } = result;
        let errorMsg = '';
        if (error.Code === 604) {
          errorMsg = 'duplicated';
        } else if (error.Code === 702) {
          errorMsg = 'menuUsed';
        }
        checkLayoutListUpdate = false;
        yield put(layoutListActions.addLayoutListSuccess({
          layoutListData,
          checkLayoutListUpdate,
          errorMsg,
        }));
      }
    } catch (error) {
      yield put(layoutListActions.addLayoutListFailure());
    }
  }
}

function* asyncRemoveLayoutListSaga() {
  while (true) {
    const { payload } = yield take(layoutListActions.REMOVE_LAYOUTLIST);
    const layoutID = payload;

    try {
      const loginUser = Auth.getUserid();
      const result = yield SunapiClient.get(`/stw-cgi/display.cgi?msubmenu=webviewerlayout&action=remove&UserId=${loginUser}&LayoutId=${layoutID}`);
      const layoutListModuleState = yield select(state => state.layoutListModule);
      const { layoutListData } = layoutListModuleState.toJS();
      if (result.data.Response === 'Success') {
        for (let i = 0; i < layoutListData.length; i += 1) {
          if (layoutListData[i].LayoutId === Number(layoutID)) {
            layoutListData.splice(i, 1);
          }
        }
        yield put(layoutListActions.getLayoutList());
        // yield put(layoutListActions.removeLayoutListSuccess(layoutListData));
      } else {
        const { data: { Error: error } } = result;
        let errorMsg = '';
        let checkLayoutListUpdate = true;
        if (error.Code === 702) {
          errorMsg = 'menuUsed';
          checkLayoutListUpdate = false;
        }
        yield put(layoutListActions.removeLayoutListSuccess({
          layoutListData,
          checkLayoutListUpdate,
          errorMsg,
        }));
      }
    } catch (error) {
      yield put(layoutListActions.removeLayoutListFailure());
    }
  }
}

function* asyncEditLayoutListSaga() {
  while (true) {
    const { payload } = yield take(layoutListActions.EDIT_LAYOUTLIST);
    const loginUser = Auth.getUserid();
    const dataForSave = {
      UserId: loginUser,
      LayoutInfos: [],
    };

    dataForSave.LayoutInfos.push(payload);

    try {
      const result = yield SunapiClient.post('/stw-cgi/display.cgi?msubmenu=webviewerlayout&action=update', undefined, dataForSave);
      const layoutListModuleState = yield select(state => state.layoutListModule);
      const { layoutListData } = layoutListModuleState.toJS();
      let checkLayoutListUpdate = false;
      if (result.data.Response === 'Success') {
        for (let i = 0; i < layoutListData.length; i += 1) {
          if (layoutListData[i].LayoutId === Number(payload.LayoutId)) {
            layoutListData[i].LayoutTitle = payload.LayoutTitle;
          }
        }
        checkLayoutListUpdate = true;
        yield put(layoutListActions.getLayoutList());
        // yield put(layoutListActions.editLayoutListSuccess({
        //   layoutListData,
        //   checkLayoutListUpdate,
        // }));
      } else {
        const { data: { Error: error } } = result;
        let errorMsg = '';
        if (error.Code === 604) {
          errorMsg = 'duplicated';
        } else if (error.Code === 702) {
          errorMsg = 'menuUsed';
        }
        checkLayoutListUpdate = false;
        yield put(layoutListActions.editLayoutListSuccess({
          layoutListData,
          checkLayoutListUpdate,
          errorMsg,
        }));
      }
    } catch (error) {
      yield put(layoutListActions.editLayoutListFailure());
    }
  }
}


export default function* rootSearchSaga() {
  yield all([
    asyncGetLayoutListSaga(),
    asyncAddLayoutListSaga(),
    asyncRemoveLayoutListSaga(),
    asyncEditLayoutListSaga(),
  ]);
}
