import React from 'react';
import PropTypes from 'prop-types';
import { LoginPageContainer } from 'containers/pages';
import { LoginTemplate } from 'templates';
import { LoginForm } from 'wisenet-ui/components/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';

const LoginPage = ({
  handleSubmit,
  errorMessage,
  theme,
}) => (
  <LoginTemplate>
    <LoginForm onSubmit={handleSubmit} error={errorMessage} theme={theme} />
  </LoginTemplate>
);

LoginPage.defaultProps = {
  errorMessage: '',
};

LoginPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  theme: PropTypes.string.isRequired,
};

export default withContainer(LoginPageContainer, LoginPage);
