import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Input,
  // Button,
} from 'wisenet-ui/components/atoms';
import {
  NewListCheckbox,
} from 'wisenet-ui/components/molecules';
import {
  ListTitleStyled,
  ListMoveToTextSearchIconStyled,
  ListCloseIconStyled,
  ListTextStyled,
  ListIndexIconStyled,
  ListIconStyled,
  EventResultListItemStyled,
  EventResultListItemNormalStyled,
  EventResultListItemDstStyled,
  EventResultListItemIndexStyled,
  EventResultListItemDatetimeStyled,
  TextResultListItemStyled,
  TextResultListItemNormalStyled,
  TextResultListItemIndexStyled,
  TextResultListItemDatetimeStyled,
  TextResultListItemKeywordStyled,
  RealTimeEvnetWrapper,
  EventTitleStyled,
  EventSubTitleWrapperStyled,
  // EventChannelIDStyled,
  // EventChannelTitleStyled,
  // EventChannelListStyled,
  EventDeviceNameStyled,
  EventStartTimeStyled,
  EventContentsStyled,
} from './NewListItemStyled';

class NewListTitle extends React.Component {
  // state = {
  //   inputValue: '',
  // };

  shouldComponentUpdate(nextProps) {
    const {
      listItem: nextListItem,
      selected: nextSelected,
    } = nextProps;

    const {
      listItem,
      selected,
    } = this.props;

    if ((JSON.stringify(nextSelected) === JSON.stringify(selected))
      && (JSON.stringify(nextListItem) === JSON.stringify(listItem))) {
      return false;
    }
    return true;
  }

  handleChange = e => {
    const inputValue = e.target.value;
    const { onInputChange } = this.props;
    onInputChange(inputValue);

    // this.setState({
    //   inputValue: getInputValue,
    // });
  }

  render() {
    const {
      listItem,
      disableItem,
      idx,
      selected,
      showCheckbox,
      showIndex,
      showCamChannelIndexIcon,
      showIcon,
      dragAndDrop,
      dragFunc,
      onClickCheckbox,
      onClickMoveToOtherPage,
      onClickDeleteEventItem,
      realTimeEventComponent,
      useBlinkAnimation,
    } = this.props;
    return (
      <ListTitleStyled
        className={classNames({ selected, disableItem })}
        draggable={dragAndDrop}
        onDragEnd={e => dragFunc(e)}
      >
        {realTimeEventComponent
          //  posEvent: PropTypes.instanceOf(Map).isRequired,
          //    channelIDList: PropTypes.instanceOf(List), // [1, 2, 3]
          //    deviceID: 0,
          //    deviceName: '', // TEXT 03
          //    encodingType: '', // EUC-KR
          //    receipt: '', // ...what the
          //    receivedDate: '', // 2019-04-19T09:43:57Z
          ? (
            <RealTimeEvnetWrapper useBlinkAnimation={useBlinkAnimation}>
              <EventTitleStyled>
                {listItem.data.Type}
                <ListCloseIconStyled
                  onClick={() => onClickDeleteEventItem(listItem.data.StartTime)}
                  className="wni wni-close"
                />
                <ListMoveToTextSearchIconStyled
                  onClick={() => onClickMoveToOtherPage(listItem.data)}
                  className="wni wni-play"
                />
              </EventTitleStyled>
              <EventSubTitleWrapperStyled>
                <div>
                  <EventDeviceNameStyled>
                    {listItem.data.DeviceName}
                  </EventDeviceNameStyled>
                  <EventStartTimeStyled>
                    {listItem.data.LocalTimeFormat}
                  </EventStartTimeStyled>
                </div>
                {/* <EventChannelIDStyled>
                  <EventChannelListStyled>
                    <EventChannelTitleStyled>
                      {'CH'}
                    </EventChannelTitleStyled>
                    {
                      listItem.data.ChannelList && listItem.data.ChannelList.map(item => (
                        <Button
                          key={item}
                          // onClick={onChannelButtonClick}
                          disabled
                        >
                          {item}
                        </Button>
                      ))
                    }
                  </EventChannelListStyled>
                </EventChannelIDStyled> */}
              </EventSubTitleWrapperStyled>
              {listItem.data.Data !== undefined
                && (
                  <EventContentsStyled>
                    {
                      listItem.data.Data.replace(/<keyword>/gi, '').replace(/<\/keyword>/gi, '')
                    }
                  </EventContentsStyled>
                )
              }
            </RealTimeEvnetWrapper>
          ) : (
            <React.Fragment>
              {showCheckbox
                && (
                  <NewListCheckbox
                    onClickNode={onClickCheckbox}
                    itemChecked={listItem.checked}
                    listItemId={listItem.id}
                  />
                )
              }
              {showIndex
                && (
                  <span>
                    {idx + 1}
                  </span>
                )
              }
              {showCamChannelIndexIcon
                && (
                  <ListIndexIconStyled
                    className={classNames({
                      checked: listItem.assigned,
                      focused: listItem.focused,
                      disableItem,
                    })}
                  >
                    {Number(listItem.id) + 1}
                  </ListIndexIconStyled>
                )
              }
              {(showIcon && listItem.iconLeft)
                && (
                  <ListIconStyled
                    className={classNames({ focused: listItem.focused, disableItem })}
                  >
                    {listItem.iconLeft}
                  </ListIconStyled>
                )
              }
              {( // Event Search 결과 리스트 만들기
                typeof listItem.data !== 'undefined'
                && typeof listItem.data.Index !== 'undefined'
                && typeof listItem.data.Type !== 'undefined'
                && typeof listItem.data.StartTime !== 'undefined'
                && typeof listItem.data.EndTime !== 'undefined'
              )
                && (
                  <EventResultListItemStyled>
                    <EventResultListItemDstStyled title="DST" dst={listItem.data.dst} />
                    <EventResultListItemIndexStyled
                      className={classNames({ focused: listItem.focused })}
                      focusColor={listItem.data.focusColor}
                    >
                      {listItem.data.Index}
                    </EventResultListItemIndexStyled>
                    <EventResultListItemNormalStyled
                      className={classNames({ focused: listItem.focused })}
                      focusColor={listItem.data.focusColor}
                    >
                      {listItem.data.Type}
                    </EventResultListItemNormalStyled>
                    <EventResultListItemDatetimeStyled
                      className={classNames({ focused: listItem.focused })}
                      focusColor={listItem.data.focusColor}
                    >
                      {listItem.data.StartTime}
                    </EventResultListItemDatetimeStyled>
                    <EventResultListItemDatetimeStyled
                      className={classNames({ focused: listItem.focused })}
                      focusColor={listItem.data.focusColor}
                    >
                      {'~'}
                      {listItem.data.EndTime}
                    </EventResultListItemDatetimeStyled>
                  </EventResultListItemStyled>
                )
              }
              {( // Text Search 결과 리스트 만들기
                typeof listItem.data !== 'undefined'
                && typeof listItem.data.Index !== 'undefined'
                && typeof listItem.data.Device !== 'undefined'
                && typeof listItem.data.Time !== 'undefined'
                && typeof listItem.data.Keyword !== 'undefined'
              )
                && (
                  <TextResultListItemStyled id="TextResultListItemStyled">
                    <EventResultListItemDstStyled title="DST" dst={listItem.data.dst} />
                    <TextResultListItemIndexStyled
                      className={classNames({ focused: listItem.focused })}
                    >
                      {listItem.data.Index}
                    </TextResultListItemIndexStyled>
                    <TextResultListItemNormalStyled
                      id="TextResultListItemNormalStyled1"
                      className={classNames({ focused: listItem.focused })}
                    >
                      {listItem.data.Device}
                    </TextResultListItemNormalStyled>
                    <TextResultListItemDatetimeStyled
                      id="TextResultListItemNormalStyled2"
                      className={classNames({ focused: listItem.focused })}
                    >
                      {listItem.data.Time}
                    </TextResultListItemDatetimeStyled>
                    <TextResultListItemKeywordStyled
                      id="TextResultListItemNormalStyled3"
                      title={listItem.data.Keyword}
                      className={classNames({ focused: listItem.focused })}
                    >
                      {listItem.data.Keyword}
                    </TextResultListItemKeywordStyled>
                  </TextResultListItemStyled>
                )
              }
              {(typeof listItem.data.input !== 'undefined' || listItem.text !== '') && (
                (listItem.data.input)
                  ? (
                    <Input
                      type="text"
                      name="input"
                      maxLength="15"
                      onChange={this.handleChange}
                      autoFocus
                    />
                  ) : (
                    <ListTextStyled
                      className={classNames({ focused: listItem.focused })}
                    >
                      {listItem.text}
                    </ListTextStyled>
                  )
              )}
              {(showIcon && listItem.iconRight)
                && (
                  <ListIconStyled>
                    {listItem.iconLeft}
                  </ListIconStyled>
                )
              }
            </React.Fragment>
          )
        }
      </ListTitleStyled>
    );
  }
}

NewListTitle.defaultProps = {
  idx: -1,
  disableItem: false,
  selected: false,
  showIndex: false,
  showCamChannelIndexIcon: false,
  showIcon: false,
  showCheckbox: false,
  dragAndDrop: false,
  dragFunc: () => {},
  onClickCheckbox: () => {},
  onInputChange: () => {},
  onClickMoveToOtherPage: () => {},
  onClickDeleteEventItem: () => {},
  realTimeEventComponent: false,
  useBlinkAnimation: true,
};

NewListTitle.propTypes = {
  listItem: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.any,
    title: PropTypes.instanceOf(Object),
    iconLeft: PropTypes.instanceOf(Object),
    iconRight: PropTypes.instanceOf(Object),
    assigned: PropTypes.bool,
    focused: PropTypes.bool,
    data: PropTypes.instanceOf(Object),
  }).isRequired,
  disableItem: PropTypes.bool,
  idx: PropTypes.number,
  selected: PropTypes.bool,
  showIndex: PropTypes.bool,
  showCamChannelIndexIcon: PropTypes.bool,
  showIcon: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  dragAndDrop: PropTypes.bool,
  dragFunc: PropTypes.func,
  onClickCheckbox: PropTypes.func,
  onInputChange: PropTypes.func,
  onClickMoveToOtherPage: PropTypes.func,
  onClickDeleteEventItem: PropTypes.func,
  realTimeEventComponent: PropTypes.bool,
  useBlinkAnimation: PropTypes.bool,
};

export default NewListTitle;
