import { createAction, handleActions } from 'redux-actions';
import { Map, List, fromJS } from 'immutable';

// action
export const ON_TILE_CAMERA_LIST = 'camera/ON_TILE_CAMERA_LIST';
export const SET_TILE_CAMERA_LIST_PAGE = 'camera/SET_TILE_CAMERA_LIST_PAGE';
export const SET_TILE_CAMERA_LIST = 'camera/SET_TILE_CAMERA_LIST';
export const SET_SELECT_TILE_CAMERA = 'camera/SET_SELECT_TILE_CAMERA';
export const SET_TILE_CAMERA_STATE = 'camera/SET_TILE_CAMERA_STATE';
export const DELETE_TILE = 'camera/DELETE_TILE';
export const ADD_EMPTY_TILE_CAMERA_LIST_PAGE = 'camera/ADD_EMPTY_TILE_CAMERA_LIST_PAGE';

export const SET_CAMERA_LIST = 'camera/SET_CAMERA_LIST';
export const SET_CAMERA_EVENT_LIST = 'camera/SET_CAMERA_EVENT_LIST';

export const SET_BACKUP_TILE_CAMERA_LIST_PAGE = 'camera/SET_BACKUP_TILE_CAMERA_LIST_PAGE';

export const ON_LAYOUT_LIST = 'camera/ON_LAYOUT_LIST';
export const LAYOUT_PENDDING_CONTROL = 'camera/LAYOUT_PENDDING_CONTROL';

// rotate 처럼 store에 기록이 필요해진 데이터가 있을 때 사용하는 액션
export const SET_TILE_CAMERA_DATA = 'camera/SET_TILE_CAMERA_DATA';

// action create
export const onTileCameraList = createAction(ON_TILE_CAMERA_LIST);
export const setTileCameraListPage = createAction(SET_TILE_CAMERA_LIST_PAGE);
export const setTileCameraList = createAction(SET_TILE_CAMERA_LIST);
export const setSelectTileCamera = createAction(SET_SELECT_TILE_CAMERA);
export const deleteTile = createAction(DELETE_TILE);
export const addEmptyTileCameraListPage = createAction(ADD_EMPTY_TILE_CAMERA_LIST_PAGE);

export const setCameraList = createAction(SET_CAMERA_LIST);
export const setCameraEventList = createAction(SET_CAMERA_EVENT_LIST);

export const setBackupTileCameraListPage = createAction(SET_BACKUP_TILE_CAMERA_LIST_PAGE);

export const onLayoutList = createAction(ON_LAYOUT_LIST);
export const layoutPenddingControl = createAction(LAYOUT_PENDDING_CONTROL);

export const setTileCameraData = createAction(SET_TILE_CAMERA_DATA);

const initialState = Map({
  cameraList: List([]),
  tileCameraListPage: Map({
    1: List([]),
  }),
  selectTileCamera: Map({}),
  cameraEventList: List([]),
  backupTileCameraListPage: Map({
    1: List([]),
  }),
  layoutPendding: false,
  cameraListPendding: true,
});

// reducer
export default handleActions({
  [SET_TILE_CAMERA_LIST_PAGE]: (state, { payload: { tileCameraListPage } }) => (
    state.set('tileCameraListPage', fromJS(tileCameraListPage))
  ),
  [SET_TILE_CAMERA_LIST]: (
    state,
    { payload: { layoutPositionInfoList, layoutPageCurrentNumber } },
  ) => {
    const currentTileList = state.get('tileCameraListPage').get(`${layoutPageCurrentNumber}`);
    const newTileList = layoutPositionInfoList.map(layoutPositionInfo => {
      const findTile = currentTileList.find(currentTile => currentTile.get('uid') === layoutPositionInfo.get('uid'));

      if (findTile) {
        return Map({
          ...findTile.toJS(),
          // 위치와 변경사항에 대해서만 값이 변경하도록 필요한 정보 표시
          w: layoutPositionInfo.get('w'),
          h: layoutPositionInfo.get('h'),
          x: layoutPositionInfo.get('x'),
          y: layoutPositionInfo.get('y'),
          index: layoutPositionInfo.get('index'),
          empty: layoutPositionInfo.get('empty'),
          isDraggable: layoutPositionInfo.get('isDraggable'),
          isResizable: layoutPositionInfo.get('isResizable'),
          static: layoutPositionInfo.get('static'),
        });
      }
      return layoutPositionInfo;
    });
    return state.setIn(['tileCameraListPage', `${layoutPageCurrentNumber}`], newTileList);
  },
  [SET_SELECT_TILE_CAMERA]: (state, { payload: { uid, layoutPageCurrentNumber } }) => {
    const selectTileCamera = state.get('tileCameraListPage').get(`${layoutPageCurrentNumber}`).find(tileCamera => tileCamera.get('uid') === uid);
    return state.set('selectTileCamera', selectTileCamera || Map({}));
  },
  [DELETE_TILE]: (state, { payload: { uid, layoutPageCurrentNumber } }) => {
    const currentTileList = state.get('tileCameraListPage').get(`${layoutPageCurrentNumber}`);
    const newTileList = currentTileList.filter(tile => tile.toJS().uid !== uid);
    return state.setIn(['tileCameraListPage', `${layoutPageCurrentNumber}`], newTileList);
  },
  [SET_CAMERA_LIST]: (state, { payload: { cameraList } }) => (
    state.set('cameraList', fromJS(cameraList))
      .set('cameraListPendding', false) // 처음 카메라 리스트를 얻어 오지 못할때 다른 동작을 하지 못하도록 하기 위해서 넣은 로직
  ),
  [SET_CAMERA_EVENT_LIST]: (state, { payload: { cameraEventList } }) => (
    state.set('cameraEventList', fromJS(cameraEventList))
  ),
  [SET_BACKUP_TILE_CAMERA_LIST_PAGE]: (state, { payload: { backupTileCameraListPage } }) => (
    state.set('backupTileCameraListPage', fromJS(backupTileCameraListPage))
  ),
  [SET_TILE_CAMERA_DATA]: (state, { payload: { uid, layoutPageCurrentNumber, data } }) => {
    const newTileList = state.get('tileCameraListPage').get(`${layoutPageCurrentNumber}`).map(tileCamera => (
      tileCamera.get('uid') === uid ? Map({
        ...tileCamera.toJS(),
        ...data,
      }) : tileCamera
    ));

    return state.setIn(['tileCameraListPage', `${layoutPageCurrentNumber}`], newTileList);
  },
  [ADD_EMPTY_TILE_CAMERA_LIST_PAGE]: (state, { payload: { addPageNumber } }) => (
    state.setIn(['tileCameraListPage', `${addPageNumber}`], List([]))
  ),
  [LAYOUT_PENDDING_CONTROL]: (state, { payload: { layoutPendding } }) => (
    state.set('layoutPendding', layoutPendding)
  ),
}, initialState);
