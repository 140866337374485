/* eslint-disable max-len */
import {
  take, all, put, call, select,
} from 'redux-saga/effects';
import {
  SunapiClient,
  getLanguage,
} from 'util/lib';
import * as searchTimelineActions from 'store/modules/mediaControl/searchTimelineModule';
import * as searchMediaControlActions from 'store/modules/mediaControl/searchMediaControlModule';
import * as messageBoxActions from 'store/modules/base/messageBoxModule';

const GET_OVERLAPPED_ID_LIST_URL = '/stw-cgi/recording.cgi?msubmenu=overlapped&action=view';
const GET_TIMELINE_URL = '/stw-cgi/recording.cgi?msubmenu=timeline&action=view';

const getSearchTime = (channel, { startDate, endDate }) => new Promise(resolve => {
  const s = new Date(startDate);
  let fromDate;
  let toDate;

  if (endDate) {
    fromDate = startDate;
    toDate = endDate;
  } else {
    fromDate = new Date(s.getFullYear(), s.getMonth(), s.getDate(), 0, 0, 0);
    toDate = new Date(s.getFullYear(), s.getMonth(), s.getDate(), 23, 59, 59);
  }
  const pad = (num, width) => {
    const n = num.toString();
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  };
  const toTimeString = date => `${date.getFullYear()}-${pad(date.getMonth() + 1, 2)}-${pad(date.getDate(), 2)}%20${pad(date.getHours(), 2)}:${pad(date.getMinutes(), 2)}:${pad(date.getSeconds(), 2)}`;

  const params = {
    FromDate: toTimeString(fromDate),
    ToDate: toTimeString(toDate),
  };

  const resultParams = channel !== -1 ? {
    ...params,
    ChannelIDList: channel,
  } : params;

  resolve(resultParams);
});

function* asyncGetOverlappedIdListSaga() {
  while (true) {
    const action = yield take(searchTimelineActions.GET_OVERLAPPED_ID_LIST);
    const serchTimelineState = yield select(state => state.searchTimelineModule);
    const { currentChannel } = serchTimelineState.toJS();

    const searchDate = yield call(getSearchTime, currentChannel, action.payload);

    try {
      const promise = yield call([SunapiClient, 'get'], GET_OVERLAPPED_ID_LIST_URL, searchDate);

      if (promise.status === 200 && typeof promise.data.Error === 'undefined') {
        yield put(searchTimelineActions.getOverlappedIdListSuccess(promise.data.OverlappedIDList));
      } else {
        yield put(searchTimelineActions.getOverlappedIdListFailure());
      }
    } catch (_) {
      yield put(searchTimelineActions.getOverlappedIdListFailure());
    }
  }
}

const pad2 = n => n.toString().padStart(2, '0');

const redistributeTimelineResult = (endDate, timeLineSearchResults, timeZone) => (
  new Promise(resolve => {
    const timezoneString = timezone => (Number(timezone) < 0
      ? `+${pad2(Math.abs(Number(timezone)))}:00`
      : `-${pad2(Math.abs(Number(timezone)))}:00`);

    // DST 문구가 포함되어있다면 제거하는 함수
    const removeDST = timestring => timestring.replace(' DST', '').replace(' ', 'T');
    // 로컬 시간을 UTC기준으로 표시
    const toISODate = timestring => new Date(timestring).toISOString().split('.')[0].concat('Z');
    // DST 제거 및 GMT Timezone 반영하는 함수
    const toDateFromDstString = timestring => {
      const dst = !!timestring.includes('DST');
      return {
        time: toISODate(`${removeDST(timestring)}${timezoneString(timeZone)}`),
        dst,
      };
    };

    const redistributeTimelineResultsData = [];
    const convertEndDate = toDateFromDstString(endDate.replace('%20', ' '));
    timeLineSearchResults.map(timeLineSearchResult => {
      const normal = [];
      const event = [];
      timeLineSearchResult.Results.map(result => {
        const LocalStartTime = result.PlayTime ? result.PlayTime : result.StartTime;
        const LocalEndTime = result.PlayTime ? null : result.EndTime;
        const StartTime = result.PlayTime
          ? toDateFromDstString(result.PlayTime) : toDateFromDstString(result.StartTime);
        const EndTime = result.PlayTime
          ? null : toDateFromDstString(result.EndTime);
        if (result.Type === 'Normal') {
          normal.push({
            ...result,
            StartTime: StartTime.time,
            EndTime: EndTime.time,
            StartDst: StartTime.dst,
            EndDst: EndTime.dst,
            LocalStartTime: removeDST(LocalStartTime),
            LocalEndTime: removeDST(LocalEndTime),
          });
        } else {
          event.push({
            ...result,
            StartTime: StartTime.time,
            EndTime: EndTime.time,
            StartDst: StartTime.dst,
            EndDst: EndTime.dst,
            LocalStartTime: removeDST(LocalStartTime),
            LocalEndTime: removeDST(LocalEndTime),
          });
        }
        return null;
      });
      redistributeTimelineResultsData.push({
        Channel: timeLineSearchResult.Channel,
        normal,
        event,
        endPlayTime: convertEndDate.time,
      });
      return null;
    });
    resolve(redistributeTimelineResultsData);
  })
);

const makeTextEndTimeData = playTime => {
  const splitArray = playTime.split(':');
  const calcMin = Number(splitArray[1]) + 1;
  return `${splitArray[0]}:${calcMin > 10 ? calcMin : `0${calcMin}`}:${splitArray[2]}`;
};

const makeTimelineParams = (overlappedIDList, selectedOverlappedID, searchDate) => (
  overlappedIDList.length > 0 ? {
    ...searchDate,
    OverlappedID: selectedOverlappedID,
    // OverlappedIDList: overlappedIDList,
  } : searchDate
);

const convertEventFilterDatas = (
  {
    type,
    searchEvent,
    // searchDate,
    filters,
  },
) => (new Promise(resolve => {
  let index = 0;
  const isEventTab = type === 'eventTab';
  const convertEvent = [];

  searchEvent.map((event, i) => {
    const startTime = isEventTab ? event.StartTime : event.PlayTime;
    const checkArray = isEventTab ? filters : event.ChannelIDList;
    const checkData = isEventTab ? event.Type : filters;
    const checkResult = checkArray.find(data => data === checkData);
    if (typeof checkResult !== 'undefined') {
      const itemDate = new Date(startTime);
      const pushData = isEventTab ? {
        ...event,
        viewFormatDate: `${pad2(itemDate.getMonth() + 1)}/${pad2(itemDate.getDate())}  ${pad2(itemDate.getHours())}:${pad2(itemDate.getMinutes())}:${pad2(itemDate.getSeconds())}`,
      } : ({
        ...event,
        StartTime: startTime,
        EndTime: searchEvent[i + 1]
          ? searchEvent[i + 1].PlayTime
          : makeTextEndTimeData(startTime),
        Type: 'UserInput',
      });

      convertEvent.push({
        ...pushData,
        index,
      });

      index += 1;
    }
    return null;
  });

  resolve(convertEvent);
}));


function* asyncConvertEventTabEventFilter(timelineSearchData, searchDate) {
  if (timelineSearchData) {
    try {
      // filter를 검사할 것을 불러야 한다.
      const eventSearchModuleState = yield select(state => state.eventSearchModule);
      const filterEvent = eventSearchModuleState.get('eventfilterdata');
      const filters = filterEvent.toJS(); // ['MotionDetection'];
      // 1ch이라서 [0]
      const events = timelineSearchData[0] ? timelineSearchData[0].event : [];
      const convertData = yield call(convertEventFilterDatas, {
        type: 'eventTab',
        searchEvent: events,
        searchDate,
        filters,
      });
      return convertData;
    } catch (error) {
      console.log('asyncConvertEventTabEventFilter - error', error);
    }
  }
  return null;
}

function* asyncConvertTextTabEventFilter(currentChannel, searchDate) {
  if (typeof currentChannel !== 'undefined') {
    try {
      const textSearchModuleState = yield select(state => state.textSearchModule);
      const searchResult = textSearchModuleState.get('searchResult');

      const convertData = yield call(convertEventFilterDatas, {
        type: 'textTab',
        searchEvent: searchResult.slice().reverse(),
        searchDate,
        filters: currentChannel,
      });

      return convertData;
    } catch (error) {
      console.log('asyncConvertTextTabEventFilter - error', error);
    }
  }
  return null;
}

function* asyncGetTimelineSaga() {
  while (true) {
    const action = yield take(searchTimelineActions.GET_TIMELINE);
    yield put(searchTimelineActions.getOverlappedIdList(action.payload));
    yield take(searchTimelineActions.GET_OVERLAPPED_ID_LIST_SUCCESS);
    const deviceInfoModuleState = yield select(state => state.deviceInfoModule);
    const { timeZone } = deviceInfoModuleState.toJS();

    const serchTimelineState = yield select(state => state.searchTimelineModule);
    const { currentChannel, overlappedIDList, selectedOverlappedID } = serchTimelineState.toJS();

    const searchDate = yield call(getSearchTime, currentChannel, action.payload);

    try {
      const params = makeTimelineParams(overlappedIDList, selectedOverlappedID, searchDate);
      const promise = yield call([SunapiClient, 'get'], GET_TIMELINE_URL, params);

      if (promise.status === 200 && typeof promise.data.Error === 'undefined') {
        const searchResult = promise.data.TimeLineSearchResults;
        const convertData = yield call(redistributeTimelineResult, searchDate.ToDate, searchResult, timeZone);
        // tileline에 표시 하기 위한 filterEvent Set
        const { type } = action.payload;
        const convertResult = type === 'eventTab'
          ? yield call(asyncConvertEventTabEventFilter, convertData, searchDate)
          : yield call(asyncConvertTextTabEventFilter, currentChannel, searchDate);

        yield put(searchTimelineActions.getTimelineSuccess({
          filterEvent: convertResult,
          timeLineSearchResults: convertData,
        }));
      } else {
        yield put(searchTimelineActions.getTimelineFailure());
      }
    } catch (_) {
      yield put(searchTimelineActions.getTimelineFailure());
    }
  }
}

/**
 * @description Search Filter (SearchOptions.jsx) 에서 필터 적용 했을때 호출하는 구문
 */
function* asyncApplyEventListFilter() {
  while (true) {
    const action = yield take(searchTimelineActions.APPLY_EVENT_LIST_FILTER);
    const serchTimelineState = yield select(state => state.searchTimelineModule);
    const { currentChannel, timeLineSearchResults } = serchTimelineState.toJS();
    try {
      // tileline에 표시 하기 위한 filterEvent Set
      const { type } = action.payload;
      const searchDate = yield call(getSearchTime, currentChannel, action.payload);

      const convertResult = type === 'eventTab'
        ? yield call(asyncConvertEventTabEventFilter, timeLineSearchResults, searchDate)
        : yield call(asyncConvertTextTabEventFilter, currentChannel, searchDate);
      yield put(searchTimelineActions.getTimelineSuccess({
        filterEvent: convertResult,
        timeLineSearchResults,
      }));
    } catch (_) {
      yield put(searchTimelineActions.getTimelineFailure());
    }
  }
}

function* executePopup() {
  while (true) {
    const action = yield take(searchTimelineActions.GET_TIMELINE_SUCCESS);
    try {
      if (action.payload
        && action.payload.timeLineSearchResults
        && action.payload.timeLineSearchResults.length === 0
      ) {
        yield put(messageBoxActions.controlMessageBox({
          messageBoxInfo: {
            title: getLanguage('lang_warning'),
            content: getLanguage('lang_nodata_recording'),
            isOpen: true,
          },
        }));
      }
    } catch (error) {
      console.log(error, 'executePopup()');
    }
  }
}

function* asyncPlayTypeOffSaga() {
  while (true) {
    yield take(searchTimelineActions.SET_CURRENT_CHANNEL);
    yield put(searchMediaControlActions.playControl({
      playMode: 'stop',
    }));
  }
}

export default function* rootSearchTimelineSaga() {
  yield all([
    asyncGetOverlappedIdListSaga(),
    asyncGetTimelineSaga(),
    asyncConvertEventTabEventFilter(),
    asyncConvertTextTabEventFilter(),
    asyncApplyEventListFilter(),
    executePopup(),
    asyncPlayTypeOffSaga(),
    // asyncSetInstantPlaybackSeeking(),
  ]);
}
