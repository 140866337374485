import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class AlarmCenterContainer extends React.Component {
  state = {
    systemDropDownVisible: false,
    hddDropDownVisible: false,
    networkDropDownVisible: false,
    notificationDropDownVisible: false,
    systemStatusConfirmed: true,
    hddStatusConfirmed: true,
    networkStatusConfirmed: true,
    notificationStatusConfirmed: true,
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const { systemEvent, alarmStop } = this.props;
    const { systemEvent: prevSystemEvent, alarmStop: prevAlarmStop } = prevProps;
    let newState = {};
    if (alarmStop !== prevAlarmStop) {
      newState = {
        ...newState,
        systemStatusConfirmed: true,
        hddStatusConfirmed: true,
        networkStatusConfirmed: true,
        notificationStatusConfirmed: true,
      };
    }
    if (JSON.stringify(systemEvent) !== JSON.stringify(prevSystemEvent)) {
      const currentStatus = this.checkErrorStatus(systemEvent);
      const prevStatus = this.checkErrorStatus(prevSystemEvent);
      if (currentStatus.systemError !== prevStatus.systemError) {
        if (currentStatus.systemError) {
          newState = {
            ...newState,
            systemStatusConfirmed: false,
          };
        } else {
          newState = {
            ...newState,
            systemDropDownVisible: false,
          };
        }
      }
      if (currentStatus.hddError !== prevStatus.hddError) {
        if (currentStatus.hddError) {
          newState = {
            ...newState,
            hddStatusConfirmed: false,
          };
        } else {
          newState = {
            ...newState,
            hddDropDownVisible: false,
          };
        }
      }
      if (currentStatus.networkError !== prevStatus.networkError) {
        if (currentStatus.networkError) {
          newState = {
            ...newState,
            networkStatusConfirmed: false,
          };
        } else {
          newState = {
            ...newState,
            networkDropDownVisible: false,
          };
        }
      }
      if (currentStatus.notification !== prevStatus.notification) {
        if (currentStatus.notification) {
          newState = {
            ...newState,
            notificationStatusConfirmed: false,
          };
        } else {
          newState = {
            ...newState,
            notificationDropDownVisible: false,
          };
        }
      }
    }
    this.onUpdate(newState);
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  onChangeDropDownVisible = type => {
    const {
      systemDropDownVisible,
      hddDropDownVisible,
      networkDropDownVisible,
      notificationDropDownVisible,
      systemStatusConfirmed,
      hddStatusConfirmed,
      networkStatusConfirmed,
      notificationStatusConfirmed,
    } = this.state;
    let value = null;
    let confirmedStatus = null;
    switch (type) {
      case 'system':
        value = !systemDropDownVisible;
        confirmedStatus = systemStatusConfirmed || !systemStatusConfirmed;
        break;
      case 'hdd':
        value = !hddDropDownVisible;
        confirmedStatus = hddStatusConfirmed || !hddStatusConfirmed;
        break;
      case 'network':
        value = !networkDropDownVisible;
        confirmedStatus = networkStatusConfirmed || !networkStatusConfirmed;
        break;
      case 'notification':
        value = !notificationDropDownVisible;
        confirmedStatus = notificationStatusConfirmed || !notificationStatusConfirmed;
        break;
      default:
        break;
    }
    if (value !== null) {
      this.setState({
        [`${type}DropDownVisible`]: value,
        [`${type}StatusConfirmed`]: confirmedStatus,
      });
    }
  }

  checkErrorStatus = data => {
    const {
      leftFanError,
      rightFanError,
      CPUFanError,
      frameFanError,
      dualSMPSError,
      recordFrameDrop,
      recordFiltering,
      hddRaidEvents,
      hddFull,
      hddNone,
      hddFail,
      iSCSIDisconnect,
      netCamTrafficOverFlow,
      newFWAvailable,
    } = data;
    const fanError = leftFanError || rightFanError || CPUFanError || frameFanError;
    const systemError = fanError || dualSMPSError || recordFrameDrop || recordFiltering;
    let raidError = false;
    let raidRebuilding = false;
    let raidFail = false;
    if (hddRaidEvents) {
      hddRaidEvents.map(item => {
        raidError = raidError || item.hddRaidDegrade;
        raidRebuilding = raidRebuilding || item.hddRaidRebuildStart;
        raidFail = raidFail || item.hddRaidFail;
        return item;
      });
    }
    const hddError = hddFull || hddNone || hddFail || iSCSIDisconnect
      || raidRebuilding || raidFail || raidError;
    const networkError = netCamTrafficOverFlow;
    const notification = newFWAvailable;
    return {
      systemError,
      hddError,
      networkError,
      notification,
      fanError,
      raidFail,
      raidRebuilding,
      raidError,
      dualSMPSError,
      recordFrameDrop,
      recordFiltering,
      hddFull,
      hddNone,
      hddFail,
      iSCSIDisconnect,
      netCamTrafficOverFlow,
      newFWAvailable,
    };
  }

  render() {
    const { render, systemEvent } = this.props;
    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
        ...this.checkErrorStatus(systemEvent),
      },
    );
  }
}

// AlarmCenterContainer.defaultProps = {
// };

AlarmCenterContainer.propTypes = {
  render: PropTypes.func.isRequired,
  systemEvent: PropTypes.instanceOf(Object).isRequired,
  alarmStop: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    systemEvent: state.systemInfomationModule.get('systemEvent').toJS(),
    alarmStop: state.liveMediaControlModule.get('alarmStop'),
  }),
)(AlarmCenterContainer);
