import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MediaControlIDList } from 'wisenet-ui/util/static/constants/mediaControl/mediaControlType';
import MediaStatusType from 'wisenet-ui/util/static/constants/mediaControl/mediaStatusType';
import UmpInfomationType from 'wisenet-ui/util/static/constants/umpPlayer/umpInfomationType';
import { getLanguage } from 'util/lib';
import {
  LiveMediaControlActions,
  MediaControlActions,
  CameraInfoActions,
  PTZZoomFocusActions,
} from 'store/actionCreators';

const minZoom = 1;
const maxZoom = 32;

class LiveVideoTileContainer extends React.Component {
  state = {
    profileNumber: '1',
    tileControlWork: {
      capture: false,
      sound: false,
      rotate: false,
      mic: false,
      instantPlayback: false,
      pcRecord: false,
    },
    channelInfo: {
      resolution: {
        width: 0,
        height: 0,
      },
      codec: '',
      bps: '0',
      fps: 0,
    },
    // sound: false, // true 시 mute 상태
    haveInstantPlayback: false,
    tileMode: 'normal', // ptz, instantPlayback, dZoom
    pcRecord: false, // pcRecord가 일하는 중인지 판단 여부
    capture: false,
    dZoom: false,
    rise: false, // TileControlBar 크기 확인 값
    umpStart: false,
  };

  constructor(props) {
    super(props);
    this.toast = null;
  }

  componentDidMount() {
    const {
      currentProfile,
      currentResolution,
      currentBitrate,
      uid,
      currentEncodingType,
      browserCheck,
    } = this.props;

    // 18,874,368 (18M)
    if (currentResolution) {
      let calcResolution = currentResolution.split('x');
      calcResolution = calcResolution[0] * calcResolution[1];
      LiveMediaControlActions.controlResolution({
        type: 'add',
        resolution: calcResolution,
        bitrate: currentBitrate,
        uid,
        codec: currentEncodingType,
        browser: browserCheck,
      });
    }

    this.onUpdate({
      profileNumber: `${currentProfile}`,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      // status: prevStatus,
      currentProfile: prevCurrentProfile,
      pattern: prevPattern,
      currentResolution: prevCurrentResolution,
      currentBitrate: prevCurrentBitrate,
    } = prevProps;
    const {
      uid,
      // status,
      currentProfile,
      pattern,
      currentResolution,
      currentBitrate,
      currentEncodingType,
      sound,
      browserCheck,
    } = this.props;

    const {
      tileMode: prevTileMode,
      umpStart: prevUmpStart,
    } = prevState;

    const {
      tileMode,
      umpStart,
      tileControlWork,
    } = this.state;

    if (currentProfile !== prevCurrentProfile) {
      this.onUpdate({
        profileNumber: `${currentProfile}`,
      });
    }

    if (pattern !== prevPattern && prevPattern === 'SPECIAL') {
      const { layoutPageCurrentNumber } = this.props;
      CameraInfoActions.setTileCameraData({
        uid,
        layoutPageCurrentNumber,
        data: {
          openTileControl: false,
        },
      });
    }

    if (tileMode !== prevTileMode) {
      const { layoutPageCurrentNumber } = this.props;
      const openTile = tileMode === 'normal';
      CameraInfoActions.setTileCameraData({
        uid,
        layoutPageCurrentNumber,
        data: {
          openTileControl: openTile,
        },
      });
    }

    if (JSON.stringify(prevCurrentResolution) !== JSON.stringify(currentResolution)
      || JSON.stringify(prevCurrentBitrate) !== JSON.stringify(currentBitrate)) {
      let calcResolution = currentResolution.split('x');
      calcResolution = calcResolution[0] * calcResolution[1];
      LiveMediaControlActions.controlResolution({
        type: 'update',
        resolution: calcResolution,
        bitrate: currentBitrate,
        uid,
        codec: currentEncodingType,
        browser: browserCheck,
      });
    }

    if (JSON.stringify(prevUmpStart) !== JSON.stringify(umpStart)) {
      if (sound) {
        MediaControlActions.setSoundUid({
          soundUid: null,
        });

        this.onUpdate({
          tileControlWork: {
            ...tileControlWork,
            sound: true,
          },
        });
      }
    }
  }

  componentWillUnmount() {
    const {
      uid,
      currentResolution,
      currentBitrate,
      currentEncodingType,
      browserCheck,
    } = this.props;

    let calcResolution = currentResolution.split('x');
    calcResolution = calcResolution[0] * calcResolution[1];
    LiveMediaControlActions.controlResolution({
      type: 'del',
      resolution: calcResolution,
      bitrate: currentBitrate,
      uid,
      codec: currentEncodingType,
      browser: browserCheck,
    });
  }

  // ump를 통해서 state로 값을 관리하는 부분
  onTileInfo = type => ({ onData, ...rest }) => { // onData, ...rest에 데이터 ump data 포함
    switch (type) {
      case UmpInfomationType.TAG_TYPE: {
        this.onUpdate({
          haveInstantPlayback: onData.tagType === 'video',
        });
        break;
      }
      case UmpInfomationType.RESOLUTION:
      case UmpInfomationType.CODEC:
      case UmpInfomationType.FPS: {
        const { channelInfo } = this.state;
        this.onUpdate({
          channelInfo: {
            ...channelInfo,
            ...onData,
          },
        });
        break;
      }
      case UmpInfomationType.CAPTURE: {
        this.workOff(type);
        this.onCapture(onData[type], rest);
        break;
      }
      case UmpInfomationType.MIC: {
        this.workOff(type);
        const mic = onData[type];
        const { uid } = this.props;

        if (onData.error) {
          this.openToast('Mic');
        }

        MediaControlActions.setMicUid({
          micUid: mic ? uid : null,
        });
        break;
      }
      case UmpInfomationType.SOUND: {
        this.workOff(type);
        const { uid } = this.props;
        const sound = onData[type];
        MediaControlActions.setSoundUid({
          soundUid: sound ? uid : null,
        });
        break;
      }
      case UmpInfomationType.ROTATE: {
        this.workOff(type);
        const { uid, layoutPageCurrentNumber } = this.props;
        CameraInfoActions.setTileCameraData({
          uid,
          layoutPageCurrentNumber,
          data: onData,
        });
        break;
      }
      case UmpInfomationType.PC_RECORD: {
        const record = onData[type];
        const { uid } = this.props;
        if (onData.error) {
          // error가 있는 경우
        }
        MediaControlActions.setRecordUid({
          recordUid: record ? uid : null,
        });
        this.workOff(type, onData);
        break;
      }
      case UmpInfomationType.INSTANT_PLAYBACK: {
        if (onData.error) {
          // error가 있는 경우
        } else {
          LiveMediaControlActions.getDeviceDate();
          const { setInstantPlaybackTimeRange, setInstantPlaybackSeekingTime } = this.props;
          const { startTime, endTime, currentTime } = onData;
          if (endTime) {
            setInstantPlaybackTimeRange(startTime, endTime);
          }
          setInstantPlaybackSeekingTime(currentTime, true);
          this.workOff(type);
        }
        break;
      }
      case UmpInfomationType.START: {
        this.onUpdate({
          umpStart: onData.start,
        });
        break;
      }
      default:
        break;
    }
  }

  workOff = (type, onData) => {
    const { tileControlWork } = this.state;
    const setWorkOff = {
      tileControlWork: {
        ...tileControlWork,
        [type]: false,
      },
    };
    this.onUpdate(onData ? {
      ...setWorkOff,
      [type]: onData[type],
    } : { ...setWorkOff });
  }

  onMouseEvent = type => event => {
    switch (type) {
      case 'enter': {
        const { uid, layoutPageCurrentNumber } = this.props;
        const { tileMode } = this.state;
        const openTile = tileMode === 'normal';
        CameraInfoActions.setTileCameraData({
          uid,
          layoutPageCurrentNumber,
          data: {
            mouseIn: true,
            openTileControl: openTile,
          },
        });
        break;
      }
      case 'leave': {
        // chrome 브라우저에서 여러번 클릭 시 원하지 않는 객체의 leave이벤트 발생으로 인한 예외처리
        const { uid, layoutPageCurrentNumber } = this.props;
        CameraInfoActions.setTileCameraData({
          uid,
          layoutPageCurrentNumber,
          data: {
            mouseIn: false,
          },
        });
        break;
      }
      case 'doubleClick': {
        const { pattern, isSelectTile } = this.props;
        if (pattern === 'SPECIAL' && isSelectTile) {
          const { backupPattern } = this.props;
          LiveMediaControlActions.patternControl({ pattern: backupPattern, isBackup: true });
        } else {
          LiveMediaControlActions.patternControl({ pattern: 'SPECIAL' });
        }
        break;
      }
      case 'wheel': {
        const { digitalZoom, setDigitalZoomInOut } = this.props;
        const { dZoom } = this.state;

        const rate = event.deltaY < 0 ? 1.1 : 0.9;
        let result = Math.round(digitalZoom * rate * 1e2) / 1e2;

        if (result >= maxZoom) result = maxZoom;
        if (result <= minZoom) result = minZoom;

        if (result === digitalZoom) return;

        setDigitalZoomInOut({
          digitalZoom: result,
          eventPos: {
            offsetX: event.nativeEvent.offsetX,
            offsetY: event.nativeEvent.offsetY,
          },
        });

        if (rate > 1 && !dZoom) this.setDZoomMode(true);
        break;
      }
      case 'down': {
        const {
          viewModeType,
          width,
          height,
          channel,
        } = this.props;

        if (typeof viewModeType !== 'undefined') {
          if (viewModeType === 'QuadView' && event) {
            let index = null;
            const centerX = width / 2;
            const centerY = height / 2;
            if (event.nativeEvent.offsetY < centerY) {
              index = event.nativeEvent.offsetX < centerX ? 1 : 2;
            } else {
              index = event.nativeEvent.offsetX < centerX ? 3 : 4;
            }
            PTZZoomFocusActions.setCurQuadForCh({
              channel,
              quadrant: index,
            });
          } else if (viewModeType.indexOf('QuadView.') !== -1) {
            const index = viewModeType.split('.')[1];
            PTZZoomFocusActions.setCurQuadForCh({
              channel,
              quadrant: index,
            });
          }
        }
        break;
      }
      case 'click': {
        const { layoutPageCurrentNumber, uid, isSelectTile } = this.props;
        if (!isSelectTile) {
          CameraInfoActions.setSelectTileCamera({ uid, layoutPageCurrentNumber });
        }
        break;
      }
      default:
        break;
    }
  }

  onKeyUp= e => {
    if (e.keyCode === 46) {
      this.onDeleteTile();
    }
  }

  onTileBarClick = type => () => { // event lint 에러로 인해서 주석
    // event.stopPropagation();
    const { tileControlWork, rise } = this.state;
    const { uid } = this.props;
    const time = new Date();
    const Month = time.getMonth() + 1;
    const currentT = `${uid.split('-')[0]}-${time.getFullYear()}${Month}${time.getDate()}${time.getHours()}${time.getMinutes()}`;
    let isSafari;
    let isRecord;

    switch (type) {
      case MediaControlIDList.DOWN:
      case MediaControlIDList.UP:
        this.onUpdate({
          rise: !rise,
        });
        break;
      case MediaControlIDList.PC_RECORD: {
        const { recordUid } = this.props;
        if (!tileControlWork[type]) {
          this.onUpdate({
            fileName: currentT,
          });
        }

        if (recordUid === uid) {
          MediaControlActions.setRecordUid({
            recordUid: null,
          });
        }

        if (recordUid === null || recordUid === uid) {
          isRecord = false;
        } else {
          isRecord = true;
        }

        break;
      }
      case MediaControlIDList.INSTANT_PLAYBACK: {
        const { haveInstantPlayback } = this.state;
        const { currentEncodingType } = this.props;
        // const { uid, pattern } = this.props;
        if (currentEncodingType !== '') { // 영상에 대한 정보가 없는 경우 동작 하지 않도록 하기 위함
          if (currentEncodingType === 'H264') {
            if (haveInstantPlayback) {
              // CameraInfoActions.setSelectTileCamera(uid);
              // if (pattern !== 'SPECIAL') {
              //   LiveMediaControlActions.backupLayoutControl({ needBackupLayoutData: true });
              // }
              this.onUpdate({
                tileMode: type,
                dZoom: false,
              });
            }
          } else {
            this.openToast(type);
          }
        }

        break;
      }
      case MediaControlIDList.PTZ_CONTROL: {
        this.onUpdate({
          tileMode: type,
          dZoom: false,
        });
        break;
      }
      case MediaControlIDList.D_ZOOM: {
        const { dZoom } = this.state;
        this.setDZoomMode(!dZoom);
        break;
      }
      // sound 정책 적용
      case MediaControlIDList.SOUND: {
        const { sound } = this.props;
        if (!sound) {
          MediaControlActions.setSoundUid({
            soundUid: null,
          });
        }
        break;
      }
      case MediaControlIDList.MIC: {
        const { mic } = this.props;
        if (!mic) {
          MediaControlActions.setMicUid({
            micUid: null,
          });
        }
        break;
      }
      case MediaControlIDList.ASPECT_RATIO: {
        MediaControlActions.aspectRatioControl({ uid });
        break;
      }
      case MediaControlIDList.CAPTURE: {
        const { browserCheck } = this.props;
        // console.log('***** browserCheck  ', browserCheck);

        if (browserCheck === 'Safari') {
          isSafari = true;
          this.openToast('Capture');
        }
        break;
      }
      default:
        break;
    }

    if ((typeof tileControlWork[type] !== 'undefined') && !tileControlWork[type] && !isSafari && !isRecord) {
      this.onUpdate({
        tileControlWork: {
          ...tileControlWork,
          [type]: true,
        },
      });
    }
  }

  onCapture = (event, data) => {
    // 기능 확인을 위한 코드
    const time = new Date();
    const Month = time.getMonth() + 1;
    const channel = data.channel + 1;
    const a = document.createElement('a');
    a.href = URL.createObjectURL(event.detail.blob);
    a.download = `${channel}-${time.getFullYear()}${Month}${time.getDate()}${time.getHours()}${time.getMinutes()}.png`;
    a.click();
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  getStatusIcon = status => {
    let icon = null;
    switch (status) {
      case 'AuthFail':
        icon = 'wni-video-authority';
        break;
      case 'ConnectFail':
      case 'VideoLoss':
        icon = 'wni-video-videoloss';
        break;
      // IA 변경으로 제거
      // case 'MaxUserLimit':
      //   icon = 'wni-video-maxuser';
      //   break;
      // case 'Disconnected':
      //   icon = 'wni-video-disconnect';
      //   break;
      default:
        icon = null;
        break;
    }
    return icon;
  };

  setDZoomMode = bDZoom => {
    this.onUpdate({
      dZoom: bDZoom,
    });
  }

  returnTileMode = () => {
    const { uid, layoutPageCurrentNumber } = this.props;
    CameraInfoActions.setTileCameraData({
      uid,
      layoutPageCurrentNumber,
      data: {
        openTileControl: true,
      },
    });
    this.onUpdate({
      tileMode: 'normal',
    });
  }

  setOpenTileBar = bOpen => {
    const { uid, layoutPageCurrentNumber } = this.props;
    CameraInfoActions.setTileCameraData({
      uid,
      layoutPageCurrentNumber,
      data: {
        openTileControl: bOpen,
      },
    });
  }

  onDeleteTile = () => {
    const {
      uid,
      layoutPageCurrentNumber,
      pattern,
      aspectRatio,
    } = this.props;
    CameraInfoActions.deleteTile({ uid, layoutPageCurrentNumber });
    if (pattern === 'SPECIAL') {
      const { backupPattern } = this.props;
      LiveMediaControlActions.patternControl({
        pattern: backupPattern,
        isBackup: true,
        uid,
        layoutPageCurrentNumber,
      });
    }
    if (aspectRatio) {
      MediaControlActions.aspectRatioControl({ uid });
    }
  }


  openToast = type => {
    switch (type) {
      case 'Mic':
        this.toast.openToast(
          <span>
            {getLanguage('lang_mic_state')}
          </span>, true,
        );
        break;
      case 'Capture':
        this.toast.openToast(
          <span>
            {getLanguage('lang_capture_safari')}
          </span>, true,
        );
        break;
      case MediaControlIDList.INSTANT_PLAYBACK:
        this.toast.openToast(
          <span>
            {getLanguage('lang_profile_support_msg')}
          </span>, true,
        );
        break;
      default:
        break;
    }
  }

  setRef = t => {
    this.toast = t;
  }

  render() {
    // const { render, cameraList, channel } = this.props;
    const { render, status } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
        statusIcon: this.getStatusIcon(status),
      },
    );
  }
}

LiveVideoTileContainer.defaultProps = {
  status: 'Success',
  recordUid: null,
  currentProfile: 1,
  currentResolution: '',
  currentBitrate: 0,
  currentEncodingType: '',
  viewModeType: undefined,
};

LiveVideoTileContainer.propTypes = {
  render: PropTypes.func.isRequired,
  pattern: PropTypes.string.isRequired,
  backupPattern: PropTypes.string.isRequired,
  // channel: PropTypes.number.isRequired,
  // userId: PropTypes.string.isRequired,
  // httpPort: PropTypes.number.isRequired,
  // model: PropTypes.string.isRequired,
  // ipAddress: PropTypes.string.isRequired,
  // addressType: PropTypes.string.isRequired,
  // channelName: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.entries(MediaStatusType).map(([, value]) => value)),
  // MediaStatusType 값 참조
  // devicePort: PropTypes.number.isRequired,
  // protocol: PropTypes.string.isRequired,
  // 'wisenet-ui/util/static/constants/mediaControl/recordStatusType' 참조
  // ptzInfo: PropTypes.oneOfType([PropTypes.any, PropTypes.shape({
  //   areaZoom: PropTypes.bool,
  //   digitalAutoTracking: PropTypes.bool,
  //   digitalPTZ: PropTypes.bool,
  //   focus: PropTypes.bool,
  //   group: PropTypes.bool,
  //   home: PropTypes.bool,
  //   iris: PropTypes.bool,
  //   pan: PropTypes.bool,
  //   panSpeed: PropTypes.bool,
  //   preset: PropTypes.bool,
  //   ptzLimit: PropTypes.bool,
  //   swing: PropTypes.bool,
  //   tilt: PropTypes.bool,
  //   tiltSpeed: PropTypes.bool,
  //   tour: PropTypes.bool,
  //   trace: PropTypes.bool,
  //   zoom: PropTypes.bool,
  //   zoomSpeed: PropTypes.bool,
  // })]).isRequired,
  layoutPageCurrentNumber: PropTypes.number.isRequired,
  mic: PropTypes.bool.isRequired,
  sound: PropTypes.bool.isRequired,
  recordUid: PropTypes.string,
  setInstantPlaybackTimeRange: PropTypes.func.isRequired,
  setInstantPlaybackSeekingTime: PropTypes.func.isRequired,
  isSelectTile: PropTypes.bool.isRequired,
  browserCheck: PropTypes.string.isRequired,
  aspectRatio: PropTypes.bool.isRequired,
  currentResolution: PropTypes.string,
  currentBitrate: PropTypes.number,
  currentProfile: PropTypes.number,
  currentEncodingType: PropTypes.string,
  digitalZoom: PropTypes.number.isRequired,
  viewModeType: PropTypes.string,
  setDigitalZoomInOut: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  channel: PropTypes.number.isRequired,
};

export default connect(
  (state, props) => {
    const { currentNumber: layoutPageCurrentNumber } = state.liveMediaControlModule.get('layoutPageInfo').toJS();
    const tileCameraList = state.cameraInfoModule.get('tileCameraListPage').get(`${layoutPageCurrentNumber}`);
    const findTile = tileCameraList.find(camera => camera.get('uid') === props.uid);

    return Object.assign({
      sessionKey: state.preLoadModule.get('liveSessionKey'),
      pattern: state.liveMediaControlModule.get('pattern'),
      backupPattern: state.liveMediaControlModule.get('backupPattern'),
      aspectRatio: !!state.mediaControlModule.get('aspectRatioList').find(aspectRatioInfo => aspectRatioInfo === props.uid),
      OSDDisplay: state.liveMediaControlModule.get('OSDDisplay'),
      channelInfoDisplay: state.liveMediaControlModule.get('channelInfoDisplay').toJS(),
      isSelectTile: state.cameraInfoModule.get('selectTileCamera').get('uid') === props.uid,
      layoutPageCurrentNumber,
      mic: state.mediaControlModule.get('micUid') === props.uid,
      sound: state.mediaControlModule.get('soundUid') === props.uid,
      recordUid: state.mediaControlModule.get('recordUid'),
      browserCheck: state.preLoadModule.get('browser'),
      tilePendding: !!state.loadingModule.get('layoutTilePenddingList').find(layoutTileUid => layoutTileUid === props.uid),
      controlResoution: false,
    }, findTile ? {
      ...findTile.toJS(),
      status: props.status,
      viewModeType: props.viewModeType,
      controlResoution: true,
    } : {});
  },
)(LiveVideoTileContainer);
