import styled, { withTheme, css } from 'styled-components';
import { IconButton, Button, Label } from 'wisenet-ui/components/atoms';
import { ListSelect } from 'components/molecules';

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    background-color: #dcdcdc;
    width: 100%;
  }
`;

export const ListSelectStyled = styled(ListSelect)`
  min-width: 87px;
  height: 25px;
  background-color: transparent;
  padding: 0;
  text-align-last: center;

  &:hover {
    color: #f37321;
    border-color: #f37321;
  }
`;

export const DateLabel = withTheme(styled(Label)`
  border: 1px solid ${props => props.theme.text.color2};
  width: 280px;
  padding: 3px;
  background-color: ${props => props.theme.background.color1};
`);

export const TextListItemWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  height: 28px;
  flex-basis: 28px;
  line-height: 28px;
  margin-top: 18px;
  margin-bottom: 16px;
  justify-content: space-between;
`;

export const ListWrapper = styled.div`
  flex: 1;
  flex-basis: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  .NewList {
    padding-top: 0px;
    & > li {
      & > div {
        padding-left: 0px;
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 28px;
  flex-basis: 28px;
  align-self: left;
  margin-top: 16px;
  margin-bottom: 19px;
`;

export const ButtonStyled = styled(Button)`
  flex-grow: 0;
  width: 70px;
  height: 28px;
  padding: 0;
  border: solid 1px #797979;
  color: ${props => props.theme.text.color1};
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  background-color: transparent;
  ${props => props.isAll && css`
    margin-left: 8.5px;
  `}
`;

export const SortButtonStyled = styled(IconButton)`
  ${props => props.isDescending && css`
    color: red;
  `}
  width: 25px;
  height: 25px;
  padding: 0;
`;

export const ListCounter = styled.div`
  flex-grow: 0;
  font-size: 14px;
  align-self: center;
`;

export const DummyDiv = styled.div`
  flex-grow: 1;
`;
