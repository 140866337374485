import React from 'react';
import PropTypes from 'prop-types';
import setupMenu from 'util/static/constants/setupMenu.js';
import { PhpSetupActions } from 'store/actionCreators';
import { connect } from 'react-redux';

class SetupPageContainer extends React.Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const { requestPageUrl } = location;
    this.callSetupPage(requestPageUrl);
  }

  componentDidUpdate(prevProps) {
    const { location: prevLocation } = prevProps;
    const { location } = this.props;
    if (prevLocation.requestPageUrl !== location.requestPageUrl) {
      const { requestPageUrl } = location;
      this.callSetupPage(requestPageUrl);
    }
  }

  callSetupPage = requestPageUrl => {
    if (process.env.NODE_ENV === 'production') {
      PhpSetupActions.getPhpSetupPage({
        requestPageUrl,
      });
    }
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
      setupMenu,
    });
  }
}

SetupPageContainer.defaultProps = {
  location: {},
};

SetupPageContainer.propTypes = {
  render: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object),
};

// export default SetupPageContainer;
export default connect(
  state => ({
    phpSetupPage: state.phpSetupModule.get('phpSetupPage'),
  }),
)(SetupPageContainer);
