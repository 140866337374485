import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import { getLanguage } from 'util/lib';
import {
  LayoutItemsWrapperStyled,
  LayoutIconStyled,
  LayoutButtonStyled,
  CustomReactTooltip,
  TooltipSpan,
} from './LiveListItemsStyled';

class LayoutListButton extends PureComponent {
  render() {
    const {
      onClickLayoutListCtrlButton,
      addingNewLayoutList,
      tileCameraListPage,
      layoutListData,
      selectedLayoutListItem,
    } = this.props;
    const tileCams = tileCameraListPage.toJS();
    const getLayoutListData = layoutListData.toJS();
    const maxItem = (getLayoutListData.length === 10);
    const emptyItem = (getLayoutListData.length === 0);
    const disableAddButton = (
      tileCams['1'].length === 0
      || maxItem
    ); // 배치된 카메라가 없거나 추가 또는 수정 중이거나 10개가 다 찼을때 disable true

    const displayNoneNewButton = (
      addingNewLayoutList === true
    ); // 추가 또는 수정 중일 때

    const displayNoneSaveButton = (
      addingNewLayoutList !== true
    ); // 추가 또는 수정 중이지 않을때

    const displayNone = (
      selectedLayoutListItem.length === 0
      || emptyItem
      || displayNoneNewButton
    ); // 선택 된 layout이 없을 때 버튼 display none

    const disableDeleteButton = (
      selectedLayoutListItem.length === 0
    );

    return (
      <LayoutItemsWrapperStyled>
        <LayoutButtonStyled // enable
          onClick={onClickLayoutListCtrlButton('Cancel')}
          displayNone={displayNoneSaveButton}
        >
          <LayoutIconStyled
            data-tip="cancel"
            data-for="cancel"
            className="wni wni-backmode"
          />
          <CustomReactTooltip
            id="cancel"
            type="light"
            place="top"
            effect="float"
            delayShow={500}
            getContent={() => (
              <TooltipSpan>{getLanguage('lang_cancel')}</TooltipSpan>
            )}
          />
        </LayoutButtonStyled>
        <LayoutButtonStyled // enable
          onClick={onClickLayoutListCtrlButton('Add')}
          displayNone={displayNoneNewButton}
          disabled={disableAddButton}
        >
          <LayoutIconStyled
            data-tip="add"
            data-for="add"
            className="wni wni-add"
          />
          <CustomReactTooltip
            id="add"
            type="light"
            place="top"
            effect="float"
            delayShow={500}
            getContent={() => (
              <TooltipSpan>{getLanguage('lang_add')}</TooltipSpan>
            )}
          />
        </LayoutButtonStyled>
        <LayoutButtonStyled // enalbe
          onClick={onClickLayoutListCtrlButton('Save')}
          displayNone={displayNoneSaveButton}
        >
          <LayoutIconStyled
            data-tip="save"
            data-for="save"
            className="wni wni-save"
          />
          <CustomReactTooltip
            id="save"
            type="light"
            place="top"
            effect="float"
            delayShow={500}
            getContent={() => (
              <TooltipSpan>{getLanguage('lang_save')}</TooltipSpan>
            )}
          />
        </LayoutButtonStyled>
        <LayoutButtonStyled
          onClick={onClickLayoutListCtrlButton('Edit')}
          displayNone={displayNone}
        >
          <LayoutIconStyled
            data-tip="edit"
            data-for="edit"
            className="wni wni-edit"
          />
          <CustomReactTooltip
            id="edit"
            type="light"
            place="top"
            effect="float"
            delayShow={500}
            getContent={() => (
              <TooltipSpan>{getLanguage('lang_edit')}</TooltipSpan>
            )}
          />
        </LayoutButtonStyled>
        <LayoutButtonStyled // enalbe
          onClick={onClickLayoutListCtrlButton('Delete')}
          displayNone={displayNone}
          disabled={disableDeleteButton}
        >
          <LayoutIconStyled
            data-tip="delete"
            data-for="delete"
            className="wni wni-delete"
          />
          <CustomReactTooltip
            id="delete"
            type="light"
            place="top"
            effect="float"
            delayShow={500}
            getContent={() => (
              <TooltipSpan>{getLanguage('lang_delete')}</TooltipSpan>
            )}
          />
        </LayoutButtonStyled>
      </LayoutItemsWrapperStyled>
    );
  }
}

LayoutListButton.propTypes = {
  onClickLayoutListCtrlButton: PropTypes.func.isRequired,
  addingNewLayoutList: PropTypes.bool.isRequired,
  tileCameraListPage: PropTypes.instanceOf(Map).isRequired,
  layoutListData: PropTypes.instanceOf(List).isRequired,
  selectedLayoutListItem: PropTypes.instanceOf(Object),
};

LayoutListButton.defaultProps = {
  selectedLayoutListItem: [],
};

export default connect(
  state => ({
    lang: state.langModule.get('lang'),
    tileCameraListPage: state.cameraInfoModule.get('tileCameraListPage'),
  }),
)(LayoutListButton);
