import styled, { css } from 'styled-components';

export const ListStyled = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${props => props.disabled && css`
    pointer-events: none;
    color: #d2d2d2 !important;
  `}
`;
