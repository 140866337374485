import 'wisenet-ui/styles/vendors/timeline-almende.css';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withContainer } from 'wisenet-ui/util/hoc';
import { SelectTimeRangeContainer } from 'wisenet-ui/containers/organisms';
import {
  SelectTimeRangeWrapperStyled,
  StartStyled,
  EndStyled,
  LeftMaskStyled,
  RightMaskStyled,
  // ExportButtonStyled,
  // TimeRangeStyled,
} from './SelectTimeRangeStyled';

class SelectTimeRange extends React.Component {
  constructor(props) {
    super(props);

    this.wrapper = null;
    this.start = null;
    this.end = null;
  }

  componentDidMount() {
    if (this.start && this.end) {
      const { setRef } = this.props;
      setRef('wrapper', this.wrapper);
      setRef('start', this.start);
      setRef('end', this.end);
    }
  }

  render() {
    const {
      handleMouseUp,
      handleMouseDown,
      handleMouseMove,
      handleClickMask,
      mouseDown,
      start,
      end,
      timeToScreen,
    } = this.props;
    const startLeft = timeToScreen(start);
    const endLeft = timeToScreen(end);
    return (
      <SelectTimeRangeWrapperStyled
        ref={ref => {
          this.wrapper = ref;
        }}
        className={classNames({ mouseDown: mouseDown.value })}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <LeftMaskStyled
          left={startLeft}
          onClick={handleClickMask}
        />
        <StartStyled
          ref={ref => {
            this.start = ref;
          }}
          left={startLeft}
          data-id="start"
          onMouseDown={handleMouseDown}
        />
        <EndStyled
          ref={ref => {
            this.end = ref;
          }}
          left={endLeft}
          data-id="end"
          onMouseDown={handleMouseDown}
        />
        <RightMaskStyled
          left={endLeft}
          onClick={handleClickMask}
        />
      </SelectTimeRangeWrapperStyled>
    );
  }
}

SelectTimeRange.propTypes = {
  setRef: PropTypes.func.isRequired,
  handleMouseUp: PropTypes.func.isRequired,
  handleMouseDown: PropTypes.func.isRequired,
  handleMouseMove: PropTypes.func.isRequired,
  handleClickMask: PropTypes.func.isRequired,
  mouseDown: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.bool,
  }).isRequired,
  timeToScreen: PropTypes.func.isRequired,
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
};

export default withContainer(SelectTimeRangeContainer, SelectTimeRange);
