import {
  take,
  put,
  call,
  all,
} from 'redux-saga/effects';
import { SunapiClient, setLanguage } from 'util/lib';
// import { setLanguage } from 'util/lib/LanguageKey';
import * as langActions from 'store/modules/base/langModule';
import languageList from 'util/static/constants/constantLang.js';
import { setCookie } from 'util/lib/Cookie';

const localLanguageGet = langName => (
  new Promise(resolve => {
    import(`util/languages/${langName}.json`).then(data => resolve(data));
  })
);

function* asyncSetCurrentLanguageSaga() {
  while (true) {
    const action = yield take(langActions.SET_CURRENT_LANGUAGE);
    try {
      const developMode = process.env.NODE_ENV !== 'production';
      const deviceInfoLanguage = action.payload;
      const currentLanguageIndex = languageList.findIndex(item => item.key === deviceInfoLanguage);
      // nvr php에서 사용하는 언어도 같이 세팅해주기 위해서 쿠키게 값을 써준다.
      setCookie('nvr_lang', currentLanguageIndex);
      const currentLanguage = languageList[currentLanguageIndex];
      const lang = yield developMode
        ? call(localLanguageGet, currentLanguage.name)
        : SunapiClient.get(`${process.env.PUBLIC_URL}languages/${currentLanguage.name}.json`);
      // LanguageKey.setLanguage(lang);
      setLanguage(developMode ? lang : lang.data);
      yield put(langActions.setCurrentLanguageSuccess({
        currentLanguage,
        languageLoaded: true,
      }));
    } catch (e) {
      yield put(langActions.setCurrentLanguageFailure());
    }
  }
}

function* asyncChangeLanguageSaga() {
  while (true) {
    const action = yield take(langActions.CHANGE_LANGUAGE);
    try {
      const developMode = process.env.NODE_ENV !== 'production';
      const currentLanguage = languageList[action.payload];
      // const lang = langs[currentLanguage.name];
      const lang = yield developMode
        ? call(localLanguageGet, currentLanguage.name)
        : SunapiClient.get(`${process.env.PUBLIC_URL}/language/${currentLanguage.name}.json`);

      // LanguageKey.setLanguage(lang);
      setLanguage(lang);
      yield put(langActions.changeLanguageSuccess({
        currentLanguage,
      }));
    } catch (e) {
      yield put(langActions.changeLanguageFailure());
    }
  }
}

function* asyncGetCurrentLanguageBeforeLoginSaga() {
  while (true) {
    yield take(langActions.GET_CURRENT_LANGUAGE_BEFORE_LOGIN);
    try {
      const uri = '/init-cgi/pw_init.cgi?msubmenu=statuscheck&action=view';
      const result = yield SunapiClient.get(uri);
      const { data: { Language } } = result;
      yield put(langActions.setCurrentLanguage(
        Language,
      ));
    } catch (e) {
      yield put(langActions.changeLanguageFailure());
    }
  }
}

export default function* rootLangSaga() {
  yield all([
    asyncSetCurrentLanguageSaga(),
    asyncChangeLanguageSaga(),
    asyncGetCurrentLanguageBeforeLoginSaga(),
  ]);
}
