import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class TargetDeviceContainer extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false;
    }
    return true;
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

TargetDeviceContainer.propTypes = {
  render: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    lang: state.langModule.get('lang'),
    overlappedIDList: state.searchTimelineModule.get('overlappedIDList'),
    timeLineSearchResults: state.searchTimelineModule.get('timeLineSearchResults'),
    calenderSearchResults: state.eventSearchModule.get('calenderSearchResults'),
  }),
)(TargetDeviceContainer);
