import styled, { withTheme } from 'styled-components';

export const Wrapper = withTheme(styled.div`
  width: 100%;
  height: 100%;
  padding: 0px;
  overflow: hidden;
  .react-datepicker__day-names {
    margin-top: 10px;
  }
  .react-datepicker__day-name {
    font-weight: normal;
    font-size: 10px;
    margin-left: 7px;
    color: ${props => props.theme.text.color2};
    background-color: ${props => props.theme.background.color1};
    margin: 4px;
  }
  .react-datepicker__header {
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${props => props.theme.background.color1};
    color: ${props => props.theme.text.color2};
  }
  .react-datepicker {
    border: none;
    width: 100%;
    height: 100%;
    color: ${props => props.theme.text.color2};
    background-color: ${props => props.theme.background.color1};
  }
  .react-datepicker__month-container {
    width: 100%;
    padding: 0px;
  }
  .react-datepicker__week {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .react-datepicker__day {
    font-family: NotoSans;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -0.2px;
    text-align: center;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    color: ${props => props.theme.text.color4};
    background-color: ${props => props.theme.background.color1};
    &:hover {
      color: ${props => props.theme.background.color1};
      background-color: ${props => props.theme.text.color4};
      border-radius: 50%;
    }
  }
  .react-datepicker__day--selected {
    font-weight: bold;
    color: ${props => props.theme.text.color8};
    background-color: ${props => props.theme.text.color4};
  }

  .react-datepicker__day--disabled {
    cursor: default;
    color: ${props => props.theme.text.color9};
    &:hover {
      color: ${props => props.theme.text.color9};
      background-color: ${props => props.theme.background.color1};
    }
  }
`);
