import React from 'react';
import PropTypes from 'prop-types';
import { getLanguage } from 'util/lib';
import { withContainer } from 'wisenet-ui/util/hoc';
import { FlexiblePage } from 'wisenet-ui/components/organisms';
import { SearchPageContainer, SearchCurrentTimeContainer } from 'containers/pages';
import {
  TextSearchTab,
  EventSearchTab,
  PlaybackVideoLayout,
  SearchResult,
} from 'components/organisms';
import { List } from 'immutable';
import {
  TabHeaderWrapperStyled,
  TabIconStyled,
  TabTitleStyled,
  MediaAndResultWrapperStyled,
  MideaWrapperStyled,
  SideControlButtonStyled,
  SideControlIconStyled,
  SearchPageWrapperStyled,
  SideComponentWrapperStyled,
  SideControlWrapperStyled,
  SearchTimelineStyled,
  SearchContentStyled,
  SearchMediaControlBarStyled,
  ResultControlStyled,
  ListTabsStyled,
} from './SearchPageStyled';

class SearchPage extends React.PureComponent {
  render() {
    const {
      cameraList,
      onSelectedTab,
      currentTabName,
      resultFolding,
      onChangeFolding,
      isFolding,
      isFullscreen,
      timelineFolding,
      defaultTabIndex,
    } = this.props;
    const searchTabData = [
      {
        key: 'tab_event',
        name: 'eventTab',
        header: (
          <TabHeaderWrapperStyled>
            <TabIconStyled className="wni wni-timesearch" />
            <TabTitleStyled>{getLanguage('lang_time')}</TabTitleStyled>
          </TabHeaderWrapperStyled>
        ),
        component: (
          <EventSearchTab data={cameraList} />
        ),
        onSelectedTab,
      },
      {
        key: 'tab_text',
        name: 'textTab',
        header: (
          <TabHeaderWrapperStyled isLast>
            <TabIconStyled className="wni wni-textsearch" />
            <TabTitleStyled>{getLanguage('lang_text')}</TabTitleStyled>
          </TabHeaderWrapperStyled>
        ),
        component: <TextSearchTab />,
        onSelectedTab,
      },
    ];
    return (
      <SearchPageWrapperStyled
        isFullscreen={isFullscreen}
        isFolding={isFolding}
        resultFolding={resultFolding}
      >
        <FlexiblePage
          headerSize={isFullscreen ? 0 : 60}
          sideFolding
          isFolding={isFolding}
          sideControlComponent={(
            <SideControlWrapperStyled
              noDisplay={isFullscreen}
            >
              <SideControlButtonStyled
                onClick={onChangeFolding}
              >
                <SideControlIconStyled
                  className="wni wni-side-showhidden side"
                  isFolding={isFolding}
                />
              </SideControlButtonStyled>
            </SideControlWrapperStyled>
          )}
          defaultSideWidth={292}
          sideComponent={(
            <SideComponentWrapperStyled>
              <ListTabsStyled defaultTabIndex={defaultTabIndex} tabData={searchTabData} />
            </SideComponentWrapperStyled>
          )}
          sideNoDisplay={isFullscreen}
        >
          <SearchCurrentTimeContainer
            currentTabName={currentTabName}
            render={({
              currentTime,
              setCurrentTime,
              setCurrentTimeWork,
              setCurrentTimeRange,
            }) => (
              <SearchContentStyled>
                <MediaAndResultWrapperStyled>
                  <MideaWrapperStyled>
                    <PlaybackVideoLayout
                      currentTabName={currentTabName}
                      setCurrentTime={setCurrentTime}
                    />
                  </MideaWrapperStyled>
                  <ResultControlStyled
                    isOver={(isFullscreen && resultFolding)}
                  >
                    <SideControlButtonStyled
                      onClick={() => onChangeFolding('result')}
                    >
                      <SideControlIconStyled
                        className="wni wni-side-showhidden result"
                        resultFolding={resultFolding}
                        isOver={(isFullscreen && resultFolding)}
                      />
                    </SideControlButtonStyled>
                  </ResultControlStyled>
                  <SearchResult
                    currentTabName={currentTabName}
                    currentTime={currentTime}
                    resultFolding={resultFolding}
                  />
                </MediaAndResultWrapperStyled>
                <SearchMediaControlBarStyled
                  currentTabName={currentTabName}
                  setCurrentTimeRange={setCurrentTimeRange}
                  currentTime={currentTime}
                />
                <SearchTimelineStyled
                  noDisplay={isFullscreen && timelineFolding}
                  displayChange={isFullscreen || timelineFolding || isFolding}
                  currentTabName={currentTabName}
                  currentTime={currentTime}
                  setCurrentTimeWork={setCurrentTimeWork}
                  setCurrentTimeRange={setCurrentTimeRange}
                />
              </SearchContentStyled>
            )}
          />
        </FlexiblePage>
      </SearchPageWrapperStyled>
    );
  }
}

SearchPage.defaultProps = {
  currentTabName: 'eventTab',
  defaultTabIndex: 0,
};

SearchPage.propTypes = {
  cameraList: PropTypes.instanceOf(List).isRequired,
  onSelectedTab: PropTypes.func.isRequired,
  currentTabName: PropTypes.string,
  resultFolding: PropTypes.bool.isRequired,
  onChangeFolding: PropTypes.func.isRequired,
  isFolding: PropTypes.bool.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  timelineFolding: PropTypes.bool.isRequired,
  defaultTabIndex: PropTypes.number,
};

export default withContainer(SearchPageContainer, SearchPage);
