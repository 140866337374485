import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { EventResult, TextResultTab } from 'components/organisms';
import { SearchResultContainer } from 'containers/organisms';
import {
  ResultWrapperStyled,
  RndStyled,
} from './SearchResultStyled';

const SearchResult = ({
  currentTabName,
  currentTime,
  resultSideWidth,
  onResizeSidebar,
  resultFolding,
}) => (
  <ResultWrapperStyled
    style={{
      width: resultSideWidth,
      flexBasis: resultSideWidth,
    }}
    isVisible={resultFolding}
  >
    <RndStyled
      enableResizing={{
        bottom: false,
        bottomLeft: false,
        bottomRight: false,
        left: true,
        right: false,
        top: false,
        topLeft: false,
        topRight: false,
      }}
      disableDragging
      size={{
        width: resultSideWidth,
      }}
      minWidth={316}
      onResize={onResizeSidebar}
    >
      {
        currentTabName === 'textTab' ? (
          <TextResultTab currentTime={currentTime} />
        ) : (
          <EventResult currentTime={currentTime} />
        )
      }
    </RndStyled>
  </ResultWrapperStyled>
);

SearchResult.defaultProps = {
};

SearchResult.propTypes = {
  currentTabName: PropTypes.string.isRequired,
  currentTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.number,
  ]).isRequired,
  onResizeSidebar: PropTypes.func.isRequired,
  resultSideWidth: PropTypes.string.isRequired,
  resultFolding: PropTypes.bool.isRequired,
};

export default withContainer(SearchResultContainer, SearchResult);
