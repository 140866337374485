import React from 'react';
import PropTypes from 'prop-types';

class TabExamplePageContainer extends React.Component {
  componentDidMount() {}

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.props,
      ...this.state,
    });
  }
}

TabExamplePageContainer.propTypes = {
  render: PropTypes.func.isRequired,
};

export default TabExamplePageContainer;
