export const CAMERA_MENU = [
  {
    id: 'CameraRegistration',
    title: 'lang_channel_setup',
    accessMenu: 'Device',
  },
  {
    id: 'CameraSetup',
    title: 'lang_camera_setup',
    accessMenu: 'Device',
  },
  {
    id: 'ProfileSetup',
    title: 'lang_profile_setup',
    child: [
      {
        id: 'NetCamRecordSetup',
        title: 'lang_record',
        accessMenu: 'Record',
      },
      {
        id: 'LiveSetup',
        title: 'lang_live',
        accessMenu: 'Device',
      },
      {
        id: 'LiveStreaming',
        title: 'lang_remote',
        accessMenu: 'Network',
      },
    ],
  },
  {
    id: 'CameraPassword',
    title: 'lang_camera_password',
    accessMenu: 'Device',
  },
];

export const RECORD_MENU = [
  {
    id: 'RecordingSchedule',
    title: 'lang_recording_schedule',
    accessMenu: 'Record',
  },
  {
    id: 'NvrRecordSetup',
    title: 'lang_record_settings',
    accessMenu: 'Record',
  },
  {
    id: 'RecordOption',
    title: 'lang_record_option',
    accessMenu: 'Record',
  },
];

export const EVENT_MENU = [
  {
    id: 'SensorDetection',
    title: 'lang_sensor_detection',
    child: [
      {
        id: 'NvrSensorDetection',
        title: 'lang_recorder',
        accessMenu: 'Event',
      },
      {
        id: 'NetCamSensorDetection',
        title: 'lang_camera',
        accessMenu: 'Event',
      },
    ],
  },
  {
    id: 'NetCamEventDetection',
    title: 'lang_camera_event',
    accessMenu: 'Event',
  },
  {
    id: 'VideoLossDetection',
    title: 'lang_video_loss_detection',
    accessMenu: 'Event',
  },
  {
    id: 'AlarmSchedule',
    title: 'lang_alarm_out_schedule',
    accessMenu: 'Event',
  },
];

export const DEVICE_MENU = [
  {
    id: 'storageDevice',
    title: 'lang_storage_device',
    child: [
      {
        id: 'DeviceFormat',
        title: 'lang_deivce_format',
        accessMenu: 'Device',
      },
      // {
      //   id: 'iSCSI',
      //   title: 'TITLE_STORAGE_ISCSI_SETUP',
      // },
      // {
      //   id: 'RAID',
      //   title: 'TITLE_STORAGE_RAID_SETUP',
      // },
      {
        id: 'HDDAlarm',
        title: 'lang_hdd_alarm',
        accessMenu: 'Device',
      },
    ],
  },
  {
    id: 'RemoteDevice',
    title: 'lang_remote_device',
    accessMenu: 'Device',
  },
  {
    id: 'Monitor',
    title: 'lang_monitor',
    accessMenu: 'Device',
  },
  {
    id: 'text',
    title: 'lang_text',
    child: [
      {
        id: 'POSConf',
        title: 'lang_device',
        accessMenu: 'Device',
      },
      {
        id: 'POSEventConf',
        title: 'lang_event',
        accessMenu: 'Device',
      },
    ],
  },
  {
    id: 'RS485',
    title: 'lang_serial',
    accessMenu: 'Device',
  },
];

export const NETWORK_MENU = [
  {
    id: 'interfaceSetting',
    title: 'lang_interface',
    child: [
      {
        id: 'NetworkInterface',
        title: 'lang_network',
        accessMenu: 'Network',
      },
      {
        id: 'NetworkPort',
        title: 'lang_port',
        accessMenu: 'Network',
      },
    ],
  },
  {
    id: 'DDNS',
    title: 'lang_menu_ddns',
    accessMenu: 'Network',
  },
  {
    id: 'IPFilter',
    title: 'lang_menu_ipfilter',
    accessMenu: 'Network',
  },
  {
    id: 'SSL',
    title: 'lang_ssl',
    accessMenu: 'Network',
  },
  {
    id: '802.1x',
    title: 'lang_menu_8021x',
    accessMenu: 'Network',
  },
  {
    id: 'email',
    title: 'lang_email',
    child: [
      {
        id: 'SMTP',
        title: 'lang_smtp',
        accessMenu: 'Network',
      },
      {
        id: 'EventMail',
        title: 'lang_event',
        accessMenu: 'Network',
      },
      {
        id: 'GroupAndRecipientEmail',
        title: 'lang_recipient',
        accessMenu: 'Network',
      },
    ],
  },
  {
    id: 'SNMP',
    title: 'lang_menu_snmp',
    accessMenu: 'Network',
  },
  {
    id: 'DHCPServer',
    title: 'lang_dhcp_server',
    accessMenu: 'Network',
  },
  {
    id: 'P2P',
    title: 'lang_p2p',
    accessMenu: 'Network',
  },
];

export const SYSTEM_MENU = [
  {
    id: 'DateTimeLanguage',
    title: 'lang_date_time_language',
    accessMenu: 'System',
  },
  {
    id: 'systemManagement',
    title: 'lang_system_management',
    child: [
      {
        id: 'SystemManagement',
        title: 'lang_system_information',
        accessMenu: 'System',
      },
    ],
  },
  {
    id: 'logInfomation',
    title: 'lang_log_information',
    child: [
      {
        id: 'SystemLog',
        title: 'lang_system_log',
        accessMenu: 'System',
      },
      {
        id: 'EventLog',
        title: 'lang_event_log',
        accessMenu: 'System',
      },
      {
        id: 'BackupLog',
        title: 'lang_backup_log',
        accessMenu: 'System',
      },
    ],
  },
];
