import styled from 'styled-components';
import { IconButton } from 'wisenet-ui/components/atoms';
import { ResizableAccordion } from 'wisenet-ui/components/organisms';

export const ResultWrapperStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ResultListAccordionStyled = styled(ResizableAccordion)`
  position: absolute;;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .accordion-item {
    border-color: ${props => props.theme.line.color6};
   &.data {
    flex: 1;
    position: relative;
   }
  }
  .accordion-item-header {
    border-color: ${props => props.theme.line.color2};
  }
  .accordion-item-content {
    padding: 0 20px;
    &.data {
    position: relative;
   }
  }
`;

export const CustomIconButtonStyled = styled(IconButton)`
  font-size: 20px;
  width: 32px;
  height: 42px;
  padding: 0;
`;

export const HeadTitleStyled = styled.span`
  font-size: 16px;
`;
