import styled, { withTheme } from 'styled-components';
import {
  Select,
  Input,
  Button,
  Span,
  ReactModalAdapter,
  IconButton,
} from 'wisenet-ui/components/atoms';
import Progress from 'react-progressbar';

export const StyledPropgressbar = withTheme(styled(Progress)`
  flex: 1;
  height: 15px;
  margin-left: 10px;
  border: 1px solid ${props => props.theme.line.color4};
  padding: 1px;
  .progressbar-progress {
    background-color: grey !important;
    border: 1px solid ${props => props.theme.line.color4};
  }
`);

export const Container = withTheme(styled.div`
  display: flex;
  flex-direction: column;
`);

export const Header = withTheme(styled.div`
  flex-basis: 44px;
  height: 44px;
  padding-top: 18x;
  border-bottom: 1px solid ${props => props.theme.line.color2};
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
`);

export const Footer = styled.div`
  flex-basis: 61px;
  height: 61px;
  border-top: 1px solid ${props => props.theme.line.color2};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Body = withTheme(styled.div`
  flex: 1;
  padding: 16px;
`);

export const TitleSpan = withTheme(styled(Span)`
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  margin-left: 16px;
`);

export const DateSpan = withTheme(styled(Span)`
  width: 150px;
  height: 19px;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
`);

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const FooterButton = styled(Button)`
  min-width: 100px;
  height: 28px;
  margin-left: 4px;
  object-fit: contain;
  padding-top: 4px;
  padding-bottom: 5px;
`;

export const TableWrapper = styled.div`
  margin-top: 15px;
  height: 281px;
`;

export const LayoutSelect = styled(Select)`
  width: 136px;
  height: 28px;
  object-fit: contain;
  float: left;
`;

export const StyledDatePickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  width: 500px;
  height: 38px;
  margin-left: 16px;
  margin-top: 7px;
`;

export const FileNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  margin-top: 7px;
`;

export const MenuSpan = withTheme(styled(Span)`
  // width: 66px;
  height: 19px;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  float: left;
  text-align: left;
  color: ${props => props.theme.text.color2};
`);

export const FileInput = styled(Input)`
  margin-left: 20px;
  width: 248px;
  height: 28px;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: ${props => props.theme.text.color2};
`;

export const StatusWrapper = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.17px;
  margin-top: 15px;
`;

export const StatusLabel = styled.div`
  display: flex;
  flex-basis: 120px;
  justify-content: space-between;
  align-items: center;
`;

export const ChannelSpan = withTheme(styled(Span)`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.color2};
`);

export const GuidePopupWrapper = withTheme(styled(ReactModalAdapter).attrs({
  className: 'Popup',
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  
  .ReactModal__Overlay {
    width: 100%;
    height: 100%;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    background-color: rgba(0,0,0,0.5) !important;
  }

  .ReactModal__Content {
    top: 50% !important;
    left: 50% !important;
    width: 300px;
    height: 170px;
    transform: translate(-50%, -50%) !important;
    background-color: #fff !important;
  }

  .ReactModal__Content--after-open {
    padding: 0px !important;
    overflow: hidden !important;
  }
`);

export const CompletePopupWrapper = withTheme(styled(ReactModalAdapter).attrs({
  className: 'Popup',
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  
  .ReactModal__Overlay {
    width: 100%;
    height: 100%;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    background-color: rgba(0,0,0,0.5) !important;
  }

  .ReactModal__Content {
    top: 50% !important;
    left: 50% !important;
    width: 300px;
    height: 350px;
    transform: translate(-50%, -50%) !important;
    background-color: #fff !important;
  }

  .ReactModal__Content--after-open {
    padding: 0px !important;
    overflow: hidden !important;
  }
`);

export const Icontyled = styled(IconButton)`
  width: 32px;
  height: 32px;
  font-size: 20px;
  padding: 0;
  margin-right: 6px;
`;
