import React from 'react';
import { getLanguage } from 'util/lib';
import WhiteThemeLogo from 'util/static/images/login_logo.svg';
// import PropTypes from 'prop-types';
import {
  ContainerStyled,
  ContentStyled,
  GuideWrapperStyled,
  GuideIconStyled,
  GuideStringStyled,
} from './MonitordiffGuideStyled';

const MonitordiffGuide = () => (
  <ContainerStyled>
    <ContentStyled>
      <img src={WhiteThemeLogo} alt="wisenet" />
      <GuideWrapperStyled>
        <GuideIconStyled className="wni wni-caution" />
        <GuideStringStyled>
          {getLanguage('lang_connection_error_msg')}
        </GuideStringStyled>
      </GuideWrapperStyled>
    </ContentStyled>
  </ContainerStyled>
);

// MonitordiffGuide.propTypes = {
// };

// MonitordiffGuide.defaultProps = {
// };

export default MonitordiffGuide;
