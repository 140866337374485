import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PTZAuxActions } from 'store/actionCreators';

class PTZAuxControllerContainer extends React.Component {
  state = {
    auxList: [],
  };

  shouldComponentUpdate(nextProps) {
    if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) {
      const { ptzInfo } = nextProps;
      if (typeof ptzInfo !== 'undefined' && ptzInfo.auxCommands) {
        const { maxAuxCount } = ptzInfo;
        const list = [];
        for (let i = 0; i < maxAuxCount; i += 1) {
          list.push(i);
        }
        this.setState({
          auxList: list,
        });
      } else {
        this.setState({
          auxList: [],
        });
      }
    }
    return true;
  }

  onClickAuxOn = index => {
    const { currentChannel } = this.props;
    PTZAuxActions.controlAux({
      Channel: currentChannel,
      AuxNum: index - 1,
      Command: 'AuxOn',
    });
  }

  onClickAuxOff = index => {
    const { currentChannel } = this.props;
    PTZAuxActions.controlAux({
      Channel: currentChannel,
      AuxNum: index - 1,
      Command: 'AuxOff',
    });
  }

  render() {
    const { render } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

PTZAuxControllerContainer.defaultProps = {
  currentChannel: 0,
  ptzInfo: {},
};

PTZAuxControllerContainer.propTypes = {
  render: PropTypes.func.isRequired,
  currentChannel: PropTypes.number,
  ptzInfo: PropTypes.instanceOf(Object),
};

export default connect(
  state => ({
    lang: state.langModule.get('lang'),
  }),
)(PTZAuxControllerContainer);
