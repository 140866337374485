import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AlarmCenterContainer } from 'containers/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { getLanguage } from 'util/lib';
import { ALARM_LIST } from './alarmList';
import {
  AlarmCenterWrapperStyled,
  IconStyled,
  DropDownMenuStyled,
  ErrorMessageWrapperStyled,
  ErrorTitleStyled,
  ErrorMessageStyled,
  CustomReactTooltip,
  TooltipSpan,
} from './AlarmCenterStyled';

const {
  SYSTEM,
  HDD,
  NETWORK,
  NOTIFICATION,
} = ALARM_LIST;

const MessageComponent = item => (
  <ErrorMessageWrapperStyled key={item.id}>
    <ErrorTitleStyled>{getLanguage(item.title)}</ErrorTitleStyled>
    <ErrorMessageStyled>{getLanguage(item.message)}</ErrorMessageStyled>
  </ErrorMessageWrapperStyled>
);

const LinkComponent = (item, onClick, type) => (
  <Link
    to={{
      pathname: '/setup',
      requestPageUrl: item.requestPageUrl,
    }}
    onClick={() => onClick(type)}
  >
    {MessageComponent(item)}
  </Link>
);

const AlarmCenter = props => {
  const {
    className,
    systemError,
    hddError,
    networkError,
    notification,
    systemDropDownVisible,
    hddDropDownVisible,
    networkDropDownVisible,
    notificationDropDownVisible,
    onChangeDropDownVisible,
    systemStatusConfirmed,
    hddStatusConfirmed,
    networkStatusConfirmed,
    notificationStatusConfirmed,
  } = props;

  const systemItems = systemError && SYSTEM.map(item => {
    if (props[item.id]) {
      return Object.prototype.hasOwnProperty.call(item, 'requestPageUrl')
        ? LinkComponent(item, onChangeDropDownVisible, 'system')
        : MessageComponent(item);
    }
    return null;
  });
  const hddItems = hddError && HDD.map(item => {
    if (props[item.id]) {
      return Object.prototype.hasOwnProperty.call(item, 'requestPageUrl')
        ? LinkComponent(item, onChangeDropDownVisible, 'hdd')
        : MessageComponent(item);
    }
    return null;
  });
  const networkItems = networkError && NETWORK.map(item => {
    if (props[item.id]) {
      return Object.prototype.hasOwnProperty.call(item, 'requestPageUrl')
        ? LinkComponent(item, onChangeDropDownVisible, 'network')
        : MessageComponent(item);
    }
    return null;
  });
  const notificationItems = notification && NOTIFICATION.map(item => {
    if (props[item.id]) {
      return Object.prototype.hasOwnProperty.call(item, 'requestPageUrl')
        ? LinkComponent(item, onChangeDropDownVisible, 'notification')
        : MessageComponent(item);
    }
    return null;
  });
  const dropDownMenus = [
    {
      id: 'lang_system',
      icon: 'wni wni-alarm-device',
      items: systemItems,
      isOpend: systemDropDownVisible,
      isConfirmed: systemStatusConfirmed,
      onClick: () => onChangeDropDownVisible('system'),
    },
    {
      id: 'lang_hdd',
      icon: 'wni wni-alarm-hdd',
      items: hddItems,
      isOpend: hddDropDownVisible,
      isConfirmed: hddStatusConfirmed,
      onClick: () => onChangeDropDownVisible('hdd'),
    },
    {
      id: 'lang_network',
      icon: 'wni wni-alarm-network',
      items: networkItems,
      isOpend: networkDropDownVisible,
      isConfirmed: networkStatusConfirmed,
      onClick: () => onChangeDropDownVisible('network'),
    },
    {
      id: 'lang_notification',
      icon: 'wni wni-alarm-notice',
      items: notificationItems,
      isOpend: notificationDropDownVisible,
      isConfirmed: notificationStatusConfirmed,
      onClick: () => onChangeDropDownVisible('notification'),
    },
  ];
  return (
    <AlarmCenterWrapperStyled className={className}>
      {
        dropDownMenus.map(dropDown => dropDown.items && (
          <React.Fragment
            key={dropDown.id}
          >
            <DropDownMenuStyled
              menuItems={dropDown.items}
              isOpend={dropDown.isOpend}
              onClickOutside={dropDown.onClick}
            >
              <IconStyled
                className={dropDown.icon}
                onClick={dropDown.onClick}
                isBlink={!dropDown.isConfirmed}
                data-tip={dropDown.id}
                data-for={dropDown.id}
              />
            </DropDownMenuStyled>
            <CustomReactTooltip
              id={dropDown.id}
              type="light"
              place="top"
              effect="float"
              delayShow={500}
              getContent={() => (
                <TooltipSpan>{getLanguage(dropDown.id)}</TooltipSpan>
              )}
            />
          </React.Fragment>
        ))
      }
    </AlarmCenterWrapperStyled>
  );
};

AlarmCenter.defaultProps = {
  className: null,
  systemError: false,
  hddError: false,
  networkError: false,
  notification: false,
};

AlarmCenter.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  systemError: PropTypes.bool,
  hddError: PropTypes.bool,
  networkError: PropTypes.bool,
  notification: PropTypes.bool,
  systemDropDownVisible: PropTypes.bool.isRequired,
  hddDropDownVisible: PropTypes.bool.isRequired,
  networkDropDownVisible: PropTypes.bool.isRequired,
  notificationDropDownVisible: PropTypes.bool.isRequired,
  onChangeDropDownVisible: PropTypes.func.isRequired,
  systemStatusConfirmed: PropTypes.bool.isRequired,
  hddStatusConfirmed: PropTypes.bool.isRequired,
  networkStatusConfirmed: PropTypes.bool.isRequired,
  notificationStatusConfirmed: PropTypes.bool.isRequired,
};

export default withContainer(AlarmCenterContainer, AlarmCenter);
