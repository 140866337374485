import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PTZSequenceActions } from 'store/actionCreators';
import { getLanguage } from 'util/lib';

class PTZPresetContainer extends React.Component {
  state = {
    showSettingPopup: false,
    showGuidePopup: false,
    currentPresetList: null,
    prevPreset: null,
    guideData: {},
  };

  componentWillReceiveProps(nextProps) {
    const { currentChannel, ptzInfo } = nextProps;
    const { prevChannel } = this.props;
    if (currentChannel !== -1) {
      const { presetList } = nextProps;
      if ((presetList === null || prevChannel !== currentChannel)
        && (typeof ptzInfo !== 'undefined' && ptzInfo.preset)) {
        // PTZSequenceActions.setPresetPending(true);
        const getData = {
          Channel: currentChannel,
        };

        PTZSequenceActions.getPreset(getData);

        let maxPreset = 300;
        if (typeof ptzInfo !== 'undefined' && ptzInfo.maxPreset) {
          const { maxPreset: maxPresetAttr } = ptzInfo;
          maxPreset = maxPresetAttr;
        }
        PTZSequenceActions.setMaxPreset(maxPreset);

        PTZSequenceActions.setPrevChannel(currentChannel);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      currentChannel,
      presetList: nextPresetList,
      ptzInfo,
      isActive,
    } = nextProps;

    const {
      currentChannel: prevChannel,
      presetList,
    } = this.props;

    document.getElementById('preset_disable_mask').style.display = 'block';
    document.getElementById('preset_wrapper').style.pointerEvents = 'none';

    if (typeof ptzInfo !== 'undefined' && ptzInfo.preset) {
      document.getElementById('preset_disable_mask').style.display = 'none';
      document.getElementById('preset_wrapper').style.pointerEvents = 'auto';
    } else {
      document.getElementById('preset_disable_mask').style.display = 'block';
      document.getElementById('preset_wrapper').style.pointerEvents = 'none';
    }

    if ((!Number.isNaN(prevChannel) || !Number.isNaN(currentChannel)) && isActive) {
      // 채널 변경 시
      if (prevChannel !== currentChannel) {
        if (nextPresetList !== null) {
          this.setState({
            currentPresetList: this.makePresetList(nextPresetList),
          });
        }
      } else if (nextPresetList !== null) {
        if (presetList !== null) {
          // 프리셋 추가/삭제 시 리스트 변경
          if (JSON.stringify(nextPresetList) !== JSON.stringify(presetList)) {
            this.setState({
              currentPresetList: this.makePresetList(nextPresetList),
            });
          } else {
            const { currentPresetList } = nextState;
            if (currentPresetList === null) {
              // 채널 선택 및 프리셋 세팅 후 페이지 이동 뒤
              this.setState({
                currentPresetList: this.makePresetList(nextPresetList),
              });
            }
          }
        } else {
          this.setState({
            currentPresetList: this.makePresetList(nextPresetList),
          });
        }
        return true;
      }
    }

    // 프리셋 팝업 상태 변경
    const {
      showSettingPopup,
      showGuidePopup,
    } = this.state;

    const {
      showSettingPopup: nextShowSettingPopup,
      showGuidePopup: nextShowGuidePopup,
    } = nextState;

    if ((showSettingPopup !== nextShowSettingPopup
      || showGuidePopup !== nextShowGuidePopup) && isActive) {
      return true;
    }
    return false;
  }

  makePresetList = presetList => {
    const array = [];
    for (let idx = 1; idx <= presetList.length; idx += 1) {
      const item = presetList[idx - 1];
      array.push({
        index: idx - 1,
        no: item.Preset,
        name: item.Name,
        isSelected: false,
      });
    }
    return array;
  };

  onSetPresetPopupConfirm = () => {
    const {
      currentPreset,
      currentChannel,
      currentPresetName,
    } = this.props;

    const getData = {
      Channel: currentChannel,
      Preset: currentPreset,
      Name: encodeURIComponent(currentPresetName),
    };

    PTZSequenceActions.addPreset(getData);

    this.setState({
      showSettingPopup: false,
    });
  }

  onSetPresetPopupCancel = () => {
    this.setState({
      showSettingPopup: false,
    });
  }

  openSetPresetPopup = () => {
    this.setState({
      showSettingPopup: true,
    });
  }

  onGuidePopupConfirm = () => {
    const { guideData } = this.state;

    if (guideData.id === 1) {
      const { prevPreset } = this.state;
      const { currentChannel } = this.props;

      if (prevPreset !== null) {
        const getData = {
          Channel: currentChannel,
          Preset: prevPreset.no,
        };
        PTZSequenceActions.deletePreset(getData);
      }
    }
    this.setState({
      showGuidePopup: false,
      showSettingPopup: false,
    });
  }

  onGuidePopupCancel = () => {
    this.setState({
      showGuidePopup: false,
    });
  }

  deletePreset = () => {
    const { prevPreset } = this.state;
    if (prevPreset !== null && prevPreset.isSelected) {
      this.setState({
        showSettingPopup: false,
        showGuidePopup: true,
        guideData: {
          id: 1,
          title: `${getLanguage('lang_delete')} ${getLanguage('lang_preset')}`,
          content: getLanguage('lang_preset_delet_msg'),
        },
      });
    }
  }

  onChangeData = (dataList, selectedData) => {
    if (selectedData.isSelected) {
      const { currentChannel } = this.props;
      const getData = {
        Channel: currentChannel,
        Preset: selectedData.no,
      };

      PTZSequenceActions.controlPreset(getData);
    }

    this.setState({
      currentPresetList: JSON.parse(JSON.stringify(dataList)),
      prevPreset: selectedData,
    });

    PTZSequenceActions.setCurrentPreset(selectedData.no);
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

PTZPresetContainer.propTypes = {
  render: PropTypes.func.isRequired,
  presetList: PropTypes.arrayOf(Object),
  currentPreset: PropTypes.number.isRequired,
  currentPresetName: PropTypes.string.isRequired,
  currentChannel: PropTypes.number,
  prevChannel: PropTypes.number,
  ptzInfo: PropTypes.objectOf(PropTypes.any),
  isActive: PropTypes.bool,
};

PTZPresetContainer.defaultProps = {
  presetList: [],
  currentChannel: -1,
  prevChannel: -1,
  ptzInfo: {},
  isActive: false,
};

export default connect(
  state => ({
    isPresetPending: state.ptzSequenceModule.get('isPresetPending'),
    presetList: state.ptzSequenceModule.get('presetList'),
    maxPreset: state.ptzSequenceModule.get('maxPreset'),
    currentPreset: state.ptzSequenceModule.get('currentPreset'),
    currentPresetName: state.ptzSequenceModule.get('currentPresetName'),
    prevChannel: state.ptzSequenceModule.get('prevChannel'),
  }),
)(PTZPresetContainer);
