import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, fromJS, Map } from 'immutable';
import { getLanguage } from 'util/lib';
import styled from 'styled-components';
import {
  LiveMediaControlActions,
  TextSearchActions,
  LayoutListActions,
} from 'store/actionCreators';

const I = styled.i`
  display: inline-block;
  margin-right:4px;
  text-align: center;
  font-size:20px;
`;

class LivePageContainer extends React.Component {
  state = {
    tileCameraListPage: [],
    dragTileCameraList: List([]),
    layoutListGetData: List([]),
    selectedLayoutListItem: [],
    isFolding: false,
    addingLayoutListItem: false,
    templayoutNameValue: '',
    realTimeFilteredPosDeviceList: [],
    authDropdownVisible: false,
    prevClickedLayoutButton: '',
    clickedLayoutButton: 'default',
    accordionState: {
      camera: true,
      layout: true,
      event: true,
      ptz: true,
    },
    isInitListHeight: true,
    ptzAccordionHeight: 'auto',
    selectedTabName: 'listTab',
    showLayoutPopup: false,
    layoutPopupData: {},
    stateCurCheckVideoTileExist: false,
    isSafari: false,
  }

  inputKey = '';

  ptzAccordionEl = {};

  ptzAccordionInitHeight = 'auto';

  constructor(props) {
    super(props);
    TextSearchActions.requestTextSearch({
      requestType: 'GET_POS_CONFIG',
    });
    LayoutListActions.getLayoutList();
  }

  componentDidMount() {
    if (this.ptzAccordionEl) {
      const { accordionState } = this.state;
      this.ptzAccordionInitHeight = `${this.ptzAccordionEl.clientHeight + 1}px`;
      this.onUpdate({
        accordionState: {
          ...accordionState,
          ptz: false,
        },
        ptzAccordionHeight: 'calc(100vh - 83px - 65%)',
      });
    }

    const {
      layoutListData: curLayoutListDataTemp,
    } = this.props;

    const isSafari = navigator.vendor === 'Apple Computer, Inc.';

    this.onUpdate({
      layoutListGetData: this.getLayoutListGetData(curLayoutListDataTemp),
      isSafari,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isFullscreen,
      realTimeFilteredPosDeviceList: curPropRealTimeFilteredPosDeviceList,
      layoutListData: curLayoutListDataTemp,
      checkLayoutListUpdate: curCheckLayoutListUpdate,
      errorMsg,
      pattern,
      backupPattern,
    } = this.props;
    const {
      selectedLayoutListItem: curSelectedListItem,
      addingLayoutListItem,
      stateCurCheckVideoTileExist,
      layoutListGetData,
    } = this.state;
    const {
      realTimeFilteredPosDeviceList: prevPropRealTimeFilteredPosDeviceList,
      layoutListData: prevLayoutListDataTemp,
    } = prevProps;
    const {
      selectedLayoutListItem: prevSelectedListItem,
    } = prevState;

    if (prevProps.isFullscreen !== isFullscreen && isFullscreen) {
      this.onUpdate({
        isFolding: true,
      });
    }

    const curLayoutListData = curLayoutListDataTemp.toJS();
    const prevLayoutListData = prevLayoutListDataTemp.toJS();

    // if (curLayoutListData.length === 10 && !addingLayoutListItem) {
    //   this.onUpdate({
    //     addingLayoutListItem: true,
    //   });
    // }

    if (curCheckLayoutListUpdate === false) {
      const showLayoutPopup = true;
      const message = errorMsg === 'menuUsed' ? 'lang_menu_currently_used' : 'lang_layout_name_already_exists';
      const layoutPopupData = {
        title: 'lang_error',
        content: getLanguage(message),
        showCancel: false,
      };
      this.onUpdate({
        showLayoutPopup,
        layoutPopupData,
        layoutListGetData: this.getLayoutListGetData(curLayoutListDataTemp),
      });
      LayoutListActions.setCheckLayoutListUpdateVal(null);
    }

    if (stateCurCheckVideoTileExist === true) {
      const showLayoutPopup = true;
      const layoutPopupData = {
        title: 'lang_error',
        content: getLanguage('lang_no_video_to_save_layout'),
        showCancel: false,
      };
      this.onUpdate({
        showLayoutPopup,
        layoutPopupData,
        stateCurCheckVideoTileExist: false,
        layoutListGetData: this.getLayoutListGetData(curLayoutListDataTemp),
      });
    }

    if (JSON.stringify(prevLayoutListData) !== JSON.stringify(curLayoutListData)) {
      this.onUpdate({
        layoutListGetData: this.getLayoutListGetData(curLayoutListDataTemp),
      });
    }

    if ((JSON.stringify(prevSelectedListItem) !== JSON.stringify(curSelectedListItem))
    && prevSelectedListItem.length !== 0) {
      this.updateLayoutListItem();
    }

    if (JSON.stringify(prevPropRealTimeFilteredPosDeviceList)
      !== JSON.stringify(curPropRealTimeFilteredPosDeviceList)) {
      this.onUpdate({
        realTimeFilteredPosDeviceList: curPropRealTimeFilteredPosDeviceList,
      });
    }

    if (addingLayoutListItem) {
      if (pattern !== prevProps.pattern && pattern !== 'SPECIAL') {
        const currentLayoutListGetData = layoutListGetData.toJS();
        const newLayoutListGetData = currentLayoutListGetData.map(item => {
          if (item.data.input) {
            let newIconLeft = item.iconLeft;
            let newPattern = item.pattern;
            if (pattern === 'SPECIAL') {
              newIconLeft = String(pattern);
            } else {
              newIconLeft = String(backupPattern);
            }
            newPattern = pattern === 'SPECIAL' ? backupPattern : pattern;
            return {
              ...item,
              iconLeft: newIconLeft,
              pattern: newPattern,
            };
          }
          return item;
        });
        this.onUpdate({
          layoutListGetData: fromJS(newLayoutListGetData),
        });
      }
    }
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  setDragTileCamera = (dragTileCameraList = List([])) => {
    this.setState({
      dragTileCameraList,
    });
  }

  updateSelectedLayoutListItem = selectedLayoutListItem => {
    const { prevClickedLayoutButton } = this.state;
    if (prevClickedLayoutButton !== 'Add' && prevClickedLayoutButton !== 'Edit') {
      this.setState({
        selectedLayoutListItem,
      });
    }
  }

  updateInputValue = inputValue => {
    this.setState({
      templayoutNameValue: inputValue,
    });
  }

  findArrayInText = (arr, text) => arr.find(item => item.text === text);

  onClickLayoutListCtrlButton = type => event => {
    event.stopPropagation();
    const {
      pattern,
      layoutType,
      tileCameraListPage,
      backupPattern,
      backupTileCameraListPage,
    } = this.props;
    const {
      selectedLayoutListItem,
      templayoutNameValue,
      prevClickedLayoutButton,
      layoutListGetData: layoutListDataTemp,
    } = this.state;
    const layoutListGetData = layoutListDataTemp.toJS();
    let addingLayoutListItemTemp = false;
    const listLength = layoutListGetData.length;
    const tileCams = tileCameraListPage.toJS();
    const tileCamsKeys = Object.keys(tileCams);
    const backupCams = backupTileCameraListPage.toJS();
    const backupCamsKeys = Object.keys(backupCams);

    switch (type) {
      case 'Add': {
        if (tileCams['1'].length === 0) {
          // ///// no cam on tile, can not add layout
          // console.log('/////// no cam on tile, can not add layout');
          break;
        }
        if (listLength < 10) {
          const newLayoutData = {
            id: Math.random().toString(36).substring(2, 15),
            // id: String(layoutListGetData.length),
            text: '',
            focused: false,
            pattern: '',
            data: {
              input: true,
            },
          };
          if (layoutType === 'dynamic') {
            newLayoutData.iconLeft = <I className="wni wni-monitoring" />;
          } else if (newLayoutData.pattern === 'SPECIAL') {
            newLayoutData.iconLeft = String(pattern);
          } else {
            newLayoutData.iconLeft = String(backupPattern);
          }
          newLayoutData.pattern = pattern === 'SPECIAL' ? backupPattern : pattern;
          layoutListGetData.push(newLayoutData);
          addingLayoutListItemTemp = true;
        }
        this.onUpdate({
          prevClickedLayoutButton: type,
          addingLayoutListItem: addingLayoutListItemTemp,
          layoutListGetData: fromJS(layoutListGetData),
        });
        break;
      }

      case 'Save': {
        for (let i = 0; i < listLength; i += 1) {
          if (layoutListGetData[i].data.input) {
            const newLayoutData = {
              id: layoutListGetData[i].id,
              // text: (templayoutNameValue === '' ? `Layout ${(i + 1)}` : templayoutNameValue),
              focused: false,
              ScreenInfos: [],
              data: {
                input: false,
              },
            };

            newLayoutData.text = templayoutNameValue || layoutListGetData[i].text;
            if (newLayoutData.text === '') {
              let j = 0;
              for (; j < listLength; j += 1) {
                if (!this.findArrayInText(layoutListGetData, `Layout ${(j + 1)}`)) {
                  break;
                }
              }
              newLayoutData.text = `Layout ${(j + 1)}`;
            }
            newLayoutData.iconLeft = layoutListGetData[i].iconLeft;
            newLayoutData.pattern = layoutListGetData[i].pattern;
            newLayoutData.ScreenInfos = layoutListGetData[i].ScreenInfos;
            layoutListGetData.splice(i, 1, newLayoutData);

            const dataForSave = {
              LayoutTitle: newLayoutData.text,
              LayoutType: newLayoutData.pattern === 'SPECIAL' ? backupPattern : newLayoutData.pattern,
              ScreenInfos: [],
            };

            if (tileCams['1'].length === 0) {
              this.onUpdate({
                stateCurCheckVideoTileExist: true,
              });
              break;
            }

            if (newLayoutData.pattern === 'SPECIAL') {
              for (let j = 0; j < backupCamsKeys.length; j += 1) {
                for (let k = 0; k < backupCams[backupCamsKeys[j]].length; k += 1) {
                  const item = backupCams[backupCamsKeys[j]];
                  const itemKeys = Object.keys(backupCams[backupCamsKeys[j]]);

                  dataForSave.ScreenInfos.push({
                    Page: Number(backupCamsKeys[j]),
                    Channel: item[itemKeys[k]].channel,
                    Index: item[itemKeys[k]].index !== undefined ? item[itemKeys[k]].index : k,
                    w: item[itemKeys[k]].w,
                    h: item[itemKeys[k]].h,
                    x: item[itemKeys[k]].x,
                    y: item[itemKeys[k]].y,
                  });
                }
              }
            } else {
              for (let j = 0; j < tileCamsKeys.length; j += 1) {
                for (let k = 0; k < tileCams[tileCamsKeys[j]].length; k += 1) {
                  const item = tileCams[tileCamsKeys[j]];
                  const itemKeys = Object.keys(tileCams[tileCamsKeys[j]]);

                  dataForSave.ScreenInfos.push({
                    Page: Number(tileCamsKeys[j]),
                    Channel: item[itemKeys[k]].channel,
                    Index: item[itemKeys[k]].index !== undefined ? item[itemKeys[k]].index : k,
                    w: item[itemKeys[k]].w,
                    h: item[itemKeys[k]].h,
                    x: item[itemKeys[k]].x,
                    y: item[itemKeys[k]].y,
                  });
                }
              }
            }

            if (prevClickedLayoutButton === 'Add') {
              LayoutListActions.addLayoutList(dataForSave);
            } else if (prevClickedLayoutButton === 'Edit') {
              dataForSave.LayoutId = Number(newLayoutData.id);
              LayoutListActions.editLayoutList(dataForSave);
            }
          }
        }
        addingLayoutListItemTemp = false;
        this.onUpdate({
          templayoutNameValue: '',
          prevClickedLayoutButton: type,
          addingLayoutListItem: addingLayoutListItemTemp,
          layoutListGetData: fromJS(layoutListGetData),
        });
        break;
      }

      case 'Edit': {
        for (let i = 0; i < listLength; i += 1) {
          if (layoutListGetData[i].id === selectedLayoutListItem.id) {
            const newLayoutData = {
              id: selectedLayoutListItem.id,
              text: layoutListGetData[i].text,
              focused: false,
              ScreenInfos: [],
              iconLeft: String(pattern),
              data: {
                input: true,
              },
            };
            newLayoutData.pattern = pattern;
            newLayoutData.ScreenInfos = layoutListGetData[i].ScreenInfos;
            layoutListGetData.splice(i, 1, newLayoutData);
            addingLayoutListItemTemp = true;
          }
        }
        this.onUpdate({
          prevClickedLayoutButton: type,
          addingLayoutListItem: addingLayoutListItemTemp,
          layoutListGetData: fromJS(layoutListGetData),
        });
        break;
      }

      case 'Delete': {
        const showLayoutPopup = true;
        const layoutPopupData = {
          title: 'lang_delete_layout',
          content: getLanguage('lang_check_delete_layout'),
          showCancel: true,
        };
        this.onUpdate({
          prevClickedLayoutButton: type,
          showLayoutPopup,
          layoutPopupData,
        });
        break;
      }

      case 'Cancel': {
        const {
          layoutListData,
        } = this.props;
        let newLayoutListGetData = layoutListGetData;
        if (prevClickedLayoutButton === 'Add') {
          newLayoutListGetData = newLayoutListGetData.filter(item => !item.data.input);
        } else if (prevClickedLayoutButton === 'Edit') {
          const storedLayoutListData = layoutListData.toJS();
          newLayoutListGetData = newLayoutListGetData.map(item => {
            if (item.data.input) {
              const storedLayoutItem = storedLayoutListData.find(
                layoutItem => String(layoutItem.LayoutId) === item.id,
              );
              return {
                ...item,
                ScreenInfos: storedLayoutItem.ScreenInfos,
                pattern: (storedLayoutItem.LayoutType === '4_4X4') ? '4_3X3' : storedLayoutItem.LayoutType,
                iconLeft: (storedLayoutItem.LayoutType === '4_4X4') ? '4_3X3' : storedLayoutItem.LayoutType,
                data: {
                  input: false,
                },
              };
            }
            return item;
          });
        }
        this.onUpdate({
          prevClickedLayoutButton: '',
          addingLayoutListItem: false,
          layoutListGetData: fromJS(newLayoutListGetData),
        });
        break;
      }

      default:
        break;
    }
  }

  onLayoutListPopupConfirm = () => {
    const {
      selectedLayoutListItem,
      layoutListGetData: layoutListDataTemp,
      prevClickedLayoutButton,
    } = this.state;
    const layoutListGetData = layoutListDataTemp.toJS();

    if (prevClickedLayoutButton === 'Delete') {
      for (let i = 0; i < layoutListGetData.length; i += 1) {
        if (layoutListGetData[i].id === selectedLayoutListItem.id) {
          layoutListGetData.splice(i, 1);
          LayoutListActions.removeLayoutList(selectedLayoutListItem.id);
        }
      }
      this.setState({
        layoutListGetData: fromJS(layoutListGetData),
        showLayoutPopup: false,
        selectedLayoutListItem: [],
      });
    } else {
      this.setState({
        showLayoutPopup: false,
        checkLayoutListUpdate: null,
      });
    }
  }

  onLayoutListPopupCancel = () => {
    this.setState({
      showLayoutPopup: false,
    });
  }

  getLayoutListGetData = layoutListData => {
    const layoutListDataTemp = layoutListData.toJS().map(item => {
      const result = {
        id: String(item.LayoutId),
        text: item.LayoutTitle,
        ScreenInfos: item.ScreenInfos,
        pattern: (item.LayoutType === '4_4X4') ? '4_3X3' : item.LayoutType,
        iconLeft: (item.LayoutType === '4_4X4') ? '4_3X3' : item.LayoutType,
        focused: false,
        data: {
          input: false,
        },
      };
      return result;
    }).filter(item => item.text !== '');

    return fromJS(layoutListDataTemp);
  }

  updateLayoutListItem = () => {
    const {
      layoutListGetData: layoutListDataTemp,
      addingLayoutListItem,
    } = this.state;
    const layoutListGetData = layoutListDataTemp.toJS();
    if (addingLayoutListItem) {
      const index = layoutListGetData.length - 1;
      const newLayoutData = {
        id: layoutListGetData[index].id,
        text: layoutListGetData[index].text,
        focused: false,
        ScreenInfos: [],
        data: {
          input: false,
        },
      };
      newLayoutData.icon = layoutListGetData[index].icon;
      newLayoutData.ScreenInfos = layoutListGetData[index].ScreenInfos;
      newLayoutData.pattern = layoutListGetData[index].pattern;

      layoutListGetData.pop();
      layoutListGetData.push(newLayoutData);
    }

    this.setState({
      layoutListGetData: fromJS(layoutListGetData),
      addingLayoutListItem: false,
    });
  }

  onKeyPress = e => {
    this.inputKey = `${this.inputKey}${e.key}`;
    if (this.inputKey.match('dev')) {
      LiveMediaControlActions.channelInfoModeControl();
      this.inputKey = '';
    }
  }

  onChangeFolding = () => {
    const { isFolding } = this.state;
    this.setState({
      isFolding: !isFolding,
    });
  }

  setRef = (e, id) => {
    this[id] = e;
  }

  onChangeAuthDropdownVisible = () => {
    const { authDropdownVisible } = this.state;
    this.setState({
      authDropdownVisible: !authDropdownVisible,
    });
  }

  onResize = (e, ref) => {
    const { isFullscreen } = this.props;
    const margin = isFullscreen ? '0px' : '83px';
    this.setState({
      isInitListHeight: false,
      ptzAccordionHeight: `calc(100vh - ${ref.style.height} - ${margin})`,
    });
  }

  onAccordionClick = id => {
    const { accordionState } = this.state;
    this.setState({
      accordionState: {
        ...accordionState,
        [id]: !accordionState[id],
      },
    });
  }

  onChangeSelectedTab = name => {
    this.setState({
      selectedTabName: name,
    });
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.props,
      ...this.state,
    });
  }
}

LivePageContainer.defaultProps = {
  pattern: '',
  layoutType: '',
  backupPattern: '',
  backupTileCameraListPage: Map({}),
  realTimeFilteredPosDeviceList: [],
  layoutListData: List([]),
  checkLayoutListUpdate: null,
};

LivePageContainer.propTypes = {
  render: PropTypes.func.isRequired,
  tileCameraListPage: PropTypes.instanceOf(Map).isRequired,
  pattern: PropTypes.string,
  layoutType: PropTypes.string,
  backupPattern: PropTypes.string,
  backupTileCameraListPage: PropTypes.instanceOf(Map),
  isFullscreen: PropTypes.bool.isRequired,
  realTimeFilteredPosDeviceList: PropTypes.arrayOf(PropTypes.any),
  layoutListData: PropTypes.instanceOf(List),
  checkLayoutListUpdate: PropTypes.bool,
  errorMsg: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    lang: state.langModule.get('lang'),
    tileCameraListPage: state.cameraInfoModule.get('tileCameraListPage'),
    selectedChannel: state.cameraInfoModule.get('selectTileCamera'),
    pattern: state.liveMediaControlModule.get('pattern'),
    layoutType: state.liveMediaControlModule.get('layoutType'),
    backupPattern: state.liveMediaControlModule.get('backupPattern'),
    backupTileCameraListPage: state.cameraInfoModule.get('backupTileCameraListPage'),
    isFullscreen: state.layoutModule.get('isFullscreen'),
    realTimeFilteredPosDeviceList: state.textSearchModule.get('realTimeFilteredPosDeviceList'),
    layoutListData: state.layoutListModule.get('layoutListData'),
    checkLayoutListUpdate: state.layoutListModule.get('checkLayoutListUpdate'),
    errorMsg: state.layoutListModule.get('errorMsg'),
    cameraListPendding: state.cameraInfoModule.get('cameraListPendding'),
  }),
)(LivePageContainer);
