import React from 'react';
import PropTypes from 'prop-types';
import { getLanguage } from 'util/lib';
import {
  StyledPopup,
  StyledButton,
  Container,
  TitleAreaStyled,
  ContentsAreaStyled,
  ButtonAreaStyled,
  HrStyled,
} from './OneButtonMessageBoxStyled';

const OneButtonMessageBox = ({
  isOpen,
  title,
  content,
  onConfirm,
}) => (
  <StyledPopup isOpen={isOpen} shouldCloseOnOverlayClick>
    <Container>
      <TitleAreaStyled>
        {title}
      </TitleAreaStyled>
      <HrStyled />
      <ContentsAreaStyled>
        {content}
      </ContentsAreaStyled>
      <HrStyled />
      <ButtonAreaStyled>
        <StyledButton onClick={onConfirm}>
          {getLanguage('lang_ok')}
        </StyledButton>
      </ButtonAreaStyled>
    </Container>
  </StyledPopup>
);

OneButtonMessageBox.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  onConfirm: PropTypes.func,
};

OneButtonMessageBox.defaultProps = {
  isOpen: false,
  title: 'No Title',
  content: 'No Contents',
  onConfirm: () => {},
};

export default OneButtonMessageBox;
