export default [
  {
    id: 'basic',
    label: 'Basic',
    icon: 'tui tui-wn5-basic',
    isSupport: true,
    childs: [
      {
        id: 'basic_videoProfile',
        label: 'Video profile',
        link: '/setup/basic/videoProfile',
        page: 'VideoProfilePage',
        isSupport: true,
      },
      {
        id: 'basic_device',
        label: 'Device',
        link: '/setup/basic/device',
        page: 'DevicePage',
        isSupport: true,
      },
      {
        id: 'basic_dateAndTime',
        label: 'Date & Time',
        link: '/setup/basic/dateTime',
        page: 'DateTimePage',
        isSupport: true,
      },
      {
        id: 'basic_network',
        label: 'Network',
        link: '/setup/basic/network',
        page: 'NetworkPage',
        isSupport: true,
      },
      {
        id: 'basic_tab',
        label: 'Tab example',
        link: '/setup/basic/tab',
        page: 'TabExamplePage',
        isSupport: true,
      },
      {
        id: 'basic_slider',
        label: 'Slider example',
        link: '/setup/basic/slider',
        page: 'SliderExamplePage',
        isSupport: true,
      },
      {
        id: 'basic_scheduler',
        label: 'Scheduler example',
        link: '/setup/basic/scheduler',
        page: 'SchedulerExamplePage',
        isSupport: true,
      },
      {
        id: 'basic_scheduler1',
        label: 'Table example',
        link: '/setup/basic/table',
        page: 'TableExamplePage',
        isSupport: true,
      },
      {
        id: 'basic_lang',
        label: 'Language',
        link: '/setup/basic/lang',
        page: 'LanguagePage',
        isSupport: true,
      },
      {
        id: 'basic_tile',
        label: 'TileBar',
        link: '/setup/basic/TileBar',
        page: 'TileBarPage',
        isSupport: true,
      },
      {
        id: 'basic_timeline',
        label: 'Timeline example',
        link: '/setup/basic/timeline',
        page: 'TimelineExamplePage',
        isSupport: true,
      },
      {
        id: 'basic_toast',
        label: 'Toast',
        link: '/setup/basic/toast',
        page: 'ToastPage',
        isSupport: true,
      },
    ],
  },
  {
    id: 'ptz',
    label: 'PTZ',
    icon: 'tui tui-wn5-toolbar-ptz',
    isSupport: true,
    childs: [
      {
        id: 'ptz_digitalPtz',
        label: 'Digital PTZ',
        link: '/setup/ptz/digitalPtz',
        page: 'VideoProfilePage',
        isSupport: true,
      },
    ],
  },
  {
    id: 'videoAudio',
    label: 'Video & Audio',
    icon: 'tui tui-wn5-toolbar-setup',
    isSupport: true,
    childs: [
      {
        id: 'videoAudio_videoSetup',
        label: 'Video setup',
        link: '/setup/videoAudio/videoSetup',
        page: 'VideoSetupPage',
        isSupport: true,
      },
    ],
  },
  {
    id: 'network',
    label: 'Network',
    icon: 'tui tui-wn5-network',
    isSupport: true,
    childs: [
      {
        id: 'network_ddns',
        label: 'DDNS',
        link: '/setup/network/ddns',
        page: 'VideoProfilePage',
        isSupport: true,
      },
    ],
  },
  {
    id: 'event',
    label: 'Event',
    icon: 'tui tui-wn5-alarm',
    isSupport: true,
    childs: [
      {
        id: 'event_eventSetup',
        label: 'Event setup',
        link: '/setup/event/eventSetup',
        page: 'VideoProfilePage',
        isSupport: true,
      },
    ],
  },
  {
    id: 'analytics',
    label: 'Analytics',
    icon: 'tui tui-wn5-iva',
    isSupport: true,
    childs: [
      {
        id: 'analytics_motiondetection',
        label: 'Motion detection',
        link: '/setup/analytics/motiondetection',
        page: 'VideoProfilePage',
        isSupport: true,
      },
      {
        id: 'analytics_iva',
        label: 'IVA',
        link: '/setup/analytics/iva',
        page: 'IvaPage',
        isSupport: true,
      },
    ],
  },
  {
    id: 'statistics',
    label: 'Statistics',
    icon: 'tui tui-statistics',
    isSupport: true,
    childs: [
      {
        id: 'statistics_peoplecounting',
        label: 'People counting',
        isSupport: true,
        childs: [
          {
            id: 'statistics_peoplecounting_search',
            label: 'Search',
            link: '/setup/statistics/peoplecounting/search',
            page: 'VideoProfilePage',
            isSupport: true,
          },
          {
            id: 'statistics_peoplecounting_setup',
            label: 'Setup',
            link: '/setup/statistics/peoplecounting/setup',
            page: 'VideoProfilePage',
            isSupport: true,
          },
        ],
      },
      {
        id: 'statistics_heatmap',
        label: 'Heatmap',
        isSupport: true,
        childs: [
          {
            id: 'statistics_heatmap_search',
            label: 'Search',
            link: '/setup/statistics/heatmap/search',
            page: 'VideoProfilePage',
            isSupport: true,
          },
          {
            id: 'statistics_heatmap_setup',
            label: 'Setup',
            link: '/setup/statistics/heatmap/setup',
            page: 'VideoProfilePage',
            isSupport: true,
          },
        ],
      },
    ],
  },
  {
    id: 'system',
    label: 'System',
    icon: 'tui tui-wn5-system',
    isSupport: true,
    childs: [
      {
        id: 'system_productInfo',
        label: 'Product information',
        link: '/setup/system/productInfo',
        page: 'VideoProfilePage',
        isSupport: true,
      },
    ],
  },
  {
    id: 'openPlatform',
    label: 'Open Platform',
    icon: 'tui tui-wn5-open-platform',
    isSupport: true,
    childs: [
      {
        id: 'openPlatform_openPlatform',
        label: 'Open Platform',
        link: '/setup/openPlatform/openPlatform',
        page: 'VideoProfilePage',
        isSupport: true,
      },
    ],
  },
];
