import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PatternIcon } from 'wisenet-ui/components/atoms';
import {
  PatternSelectStyled,
  IconButtonStyled,
  CustomReactTooltip,
  TooltipSpan,
} from './PatternSelectStyled';

class PatternSelect extends Component {
  patternIconsItem = [
    // 1분할은 선택하지 못하도록 막음
    // 'SPECIAL',
    '4_2X2',
    '4_3X3',
    '4_1+5',
    '4_1+7',
    '16_1X2',
    '16_2X1',
    '16_1X3',
    '16_3X2',
  ];

  patternIconsTooltipItem = [
    '2X2',
    '3X3',
    '1+5',
    '1+7',
    '1X2',
    '2X1',
    '1X3',
    '3X2',
  ];

  onClick = type => {
    const { onClick } = this.props;
    onClick(type);
  }

  render() {
    const { pattern } = this.props;

    return (
      <PatternSelectStyled>
        {this.patternIconsItem.map((patternIcon, index) => (
          <IconButtonStyled onClick={() => this.onClick(patternIcon)} key={`Icon-${patternIcon}`}>
            <PatternIcon
              type={patternIcon}
              select={pattern === patternIcon}
              data-tip={this.patternIconsTooltipItem[index]}
              data-for={this.patternIconsTooltipItem[index]}
            />
            <CustomReactTooltip
              id={this.patternIconsTooltipItem[index]}
              type="light"
              place="top"
              effect="float"
              delayShow={500}
              getContent={() => (
                <TooltipSpan>{this.patternIconsTooltipItem[index]}</TooltipSpan>
              )}
            />
          </IconButtonStyled>
        ))}
      </PatternSelectStyled>
    );
  }
}

PatternSelect.defaultProps = {
  pattern: '',
};

PatternSelect.propTypes = {
  onClick: PropTypes.func.isRequired,
  pattern: PropTypes.string,
};

export default PatternSelect;
