import styled from 'styled-components';
import { Button } from 'wisenet-ui/components/atoms';

export const TableWrapperStyled = styled.div`
  height: ${props => `${props.height}px`};
`;

export const LiveBottomWrapperStyled = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
`;

export const SetupButtonStyled = styled(Button)`
  min-width: 113px;
  height: 28px;
  font-size: 14px;
  padding: 0 10px;
`;
