import React from 'react';
import PropTypes from 'prop-types';
import staticLayoutPattern from 'wisenet-ui/util/static/constants/layoutPattern/staticLayoutPattern';
import classNames from 'classnames';
import {
  PatternIconTile,
  PatternIconLayout,
} from './PatternIconStyled';

const PatternIcon = ({
  type,
  className,
  select,
  ...rest
}) => {
  const { cols, rows, items } = staticLayoutPattern[type];
  const widthRatio = 100 / cols;
  const heightRatio = 100 / rows;

  return (
    <PatternIconLayout
      className={`
      ${
        classNames({
          select,
        })
      } 
      ${className}
    `}
      size={25}
      {...rest}
    >
      {items.map(item => (
        <PatternIconTile
          {...item}
          widthRatio={widthRatio}
          heightRatio={heightRatio}
          cols={cols}
          rows={rows}
          key={`PatternIconTile-${item.x}-${item.y}`}
        />
      ))}
    </PatternIconLayout>
  );
};

PatternIcon.defaultProps = {
  className: null,
  select: false,
};

PatternIcon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  select: PropTypes.bool,
};

export default PatternIcon;
