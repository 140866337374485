import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Body,
  Header,
  Footer,
  TitleSpan,
  ContentSpan,
  ConfirmButton,
} from './GuidePopupStyled';

class GuidePopup extends React.PureComponent {
  render() {
    const {
      onConfirm,
      title,
      content,
    } = this.props;
    return (
      <Container>
        <Header>
          <TitleSpan>{title}</TitleSpan>
        </Header>
        <Body>
          <ContentSpan>
            {content}
          </ContentSpan>
        </Body>
        <Footer>
          <ConfirmButton onClick={onConfirm}>Ok</ConfirmButton>
        </Footer>
      </Container>
    );
  }
}

GuidePopup.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onConfirm: PropTypes.func,
};

GuidePopup.defaultProps = {
  title: '',
  content: '',
  onConfirm: null,
};

export default GuidePopup;
