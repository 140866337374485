import styled, { css } from 'styled-components';
import { Button, ReactModalAdapter } from 'wisenet-ui/components/atoms';
import MenuTabs from 'components/molecules/tabs/MenuTabs/MenuTabs';

export const UserAuthWrapperStyled = styled.div`
  padding: 15px 0;
`;

export const PopupStyled = styled(ReactModalAdapter).attrs({
  className: 'Popup',
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  
  .ReactModal__Overlay {
    width: 100%;
    height: 100%;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    background-color: rgba(0,0,0,.5) !important;
  }

  .ReactModal__Content {
    top: 50% !important;
    left: 50% !important;
    width: 610px;
    height: 671px;
    transform: translate(-50%, -50%) !important;
    background-color:  ${props => props.theme.background.color1} !important;
    border: 1px solid ${props => props.theme.line.color5} !important;
    border-radius: 0px !important;
  }

  .ReactModal__Content--after-open {
    padding: 0px !important;
    overflow: hidden !important;
  }
`;

export const PopupButtonStyled = styled(Button)`
  width: 100px;
  height: 28px;
  font-size: 14px;
  padding: 0;
`;

export const SupportDescWrapperStyled = styled.div`
  margin-top: 62px;
`;

export const SupportDescStyled = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  height: 28px;

`;

export const LeftDescStyled = styled.div`
  flex: 1;
`;

export const RightDescStyled = styled.div`
  flex-basis: 157px;
`;

export const DescTitleStyled = styled.span`
  color: ${props => props.theme.text.color1};
  ${props => !props.isSupport && css`
    color: #a5a5a5;
  `}
`;

export const DetailButtonStyled = styled(Button)`
  display: inline-block;
  width: 68px;
  height: 28px;
  font-size: 14px;
  padding: 0;
  margin-left: 12px;
`;

export const SupportTabsStyled = styled(MenuTabs)`
  .tab-wrapper {
    height: 40px;
    .tab {
      width: 140px;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: -0.2px;
    }
  }
  .content-wrapper {
    padding-top: 16px;
  }
`;
