import styled, { css } from 'styled-components';

export const TableStyled = styled.table`
  text-align: center;
  color: ${props => props.theme.text.color5};
  font-size: 14px;
`;

export const TdStyled = styled.td`
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid ${props => props.theme.line.color2};
  ${props => props.isActive && css`
    color: #fff;
    background-color: ${props.theme.text.color4};
  `}
`;
