import styled, { withTheme } from 'styled-components';
import { Range } from 'rc-slider';
import { Button } from 'wisenet-ui/components/atoms';

export const SelectTimeRangeWrapperStyled = styled.div`
  position: absolute;
  top: 2px;
  left: 0;
  width: 100%;
  height: 300%;
  margin-top: -40px;
  padding-top: 40px;
  padding-bottom: 40px;

  &.mouseDown {
    z-index: 6;
  }
`;

export const ExportButtonStyled = styled(Button)`
  position: absolute;
  bottom: 14px;
  transform: translateY(100%);
`;

export const TimeRangeStyled = styled(Range)`
  position: absolute !important;
  top: 4px;
`;

export const StartStyled = withTheme(styled.div`
  width: 5px;
  height: 21px;
  border-left: 4px solid ${props => props.theme.subColor8};
  border-top: 2px solid ${props => props.theme.subColor8};
  border-bottom: 2px solid ${props => props.theme.subColor8};
  position: absolute;
  top: 40px;
  left: ${props => props.left}px;
  z-index: 6;
  cursor: move;
`);

export const EndStyled = withTheme(styled.div`
  width: 5px;
  height: 21px;
  border-right: 4px solid ${props => props.theme.subColor8};
  border-top: 2px solid ${props => props.theme.subColor8};
  border-bottom: 2px solid ${props => props.theme.subColor8};
  position: absolute;
  top: 40px;
  left: calc(${props => props.left}px - 5px);
  z-index: 6;
  cursor: move;
`);

export const LeftMaskStyled = withTheme(styled.div`
  width: ${props => props.left}px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7;
  background-color: ${props => props.theme.background.color1};
  opacity: 0.8;
`);

export const RightMaskStyled = withTheme(styled.div`
  width: calc(100% - ${props => props.left}px);
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 7;
  background-color: ${props => props.theme.background.color1};
  opacity: 0.8;
`);
