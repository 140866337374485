import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { LanguageSwitcher } from 'wisenet-ui/components/molecules';
import { LanguagePageContainer } from 'containers/pages';
import { getLanguage } from 'util/lib';

const LanguagePage = ({
  currentLanguage,
  changeLanguage,
}) => (
  <div>
    <LanguageSwitcher currentLanguage={currentLanguage.name} changeLanguage={changeLanguage} />
    <br />
    {getLanguage('MONITORING')}
    <br />
    {getLanguage('SEARCH')}
    <br />
    {getLanguage('SETUP')}
  </div>
);

LanguagePage.propTypes = {
  currentLanguage: PropTypes.objectOf(PropTypes.any).isRequired,
  changeLanguage: PropTypes.func,
};

LanguagePage.defaultProps = {
  changeLanguage: () => {},
};

export default withContainer(LanguagePageContainer, LanguagePage);
