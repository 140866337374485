import styled, { css } from 'styled-components';

export const TabWrapperStyled = styled.ul`
  display: flex;
`;

export const ContentWrapperStyled = styled.div`
  ${props => !props.isSelected && css`
    display: none !important;
  `}
`;
