import React from 'react';
import PropTypes from 'prop-types';

class ToastPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.toast = null;
    this.num = 0;
  }

  openToast = () => {
    this.num += 1;
    this.toast.openToast(`Toast ${this.num}`);
  }

  setRef = t => {
    this.toast = t;
  }

  render() {
    const {
      render,
    } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

ToastPageContainer.propTypes = {
  render: PropTypes.func.isRequired,
};

export default ToastPageContainer;
