import styled, { withTheme } from 'styled-components';

export const Wrapper = withTheme(styled.div`
  width: 100%;
  height: 100%;
  padding: 0px;
  .react-datepicker__day-names {
    font-weight: bold;
    font-size: 10px;
    /* margin-left: 15px; */
    color: ${props => props.theme.text.color2};
    background-color: ${props => props.theme.background.color1};
  }
  .react-datepicker-popper {
    z-index: 999 !important;
    position: fixed;
    width: 285px;
    #search-start-date-picker-popper
    , #search-end-date-picker-popper
    , #export-start-date-picker-popper
    , #export-end-date-picker-popper {
      .react-datepicker__triangle {
        display: none;
      }
    }
  }
  .react-datepicker__day-name {
    font-weight: bold;
    font-size: 10px;
    margin-left: 11px;
    color: ${props => props.theme.text.color2};
    background-color: ${props => props.theme.background.color1};
    margin: 6px;
  }
  .react-datepicker__header {
    background-color: ${props => props.theme.background.color1};
    color: ${props => props.theme.text.color2};
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 283px;
    padding: 2px auto;
  }
  .react-datepicker__month-container {
    padding: 0px;
    margin: 0px;
    width: 285px;
  }
  .react-datepicker__week {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .react-datepicker__day {
    font-weight: normal;
    margin-left: 4px;
    font-size: 10px;
    color: ${props => props.theme.text.color2};
    background-color: ${props => props.theme.background.color1};
    &:hover {
      color: ${props => props.theme.background.color1};
      background-color: ${props => props.theme.text.color2};
    }
  }
  .react-datepicker__day--selected {
    font-weight: bold;
    color: ${props => props.theme.background.color1};
    background-color: ${props => props.theme.text.color2};
  }
  .react-datepicker {
    width: 100%;
    height: 100%;
    border: 1px solid ${props => props.theme.line.color4};
    border-radius: 0em;
    color: ${props => props.theme.text.color2};
    background-color: ${props => props.theme.background.color1};
  }
`);
