import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, fromJS } from 'immutable';
import styled from 'styled-components';
import { PatternIcon } from 'wisenet-ui/components/atoms';
import { CameraInfoActions } from 'store/actionCreators';

const PatternIconStyled = styled(PatternIcon)`
  width: 20px;
  height: 20px;
  margin-right: 7px;
`;
class LayoutListContainer extends React.Component {
  stae = {
    layoutList: List([]),
  }

  componentDidMount() {
    const {
      layoutList: layoutListTemp,
    } = this.props;
    const layoutList = layoutListTemp.toJS();

    const layoutListData = layoutList.map(item => {
      const remakedItem = {};

      remakedItem.id = item.id;
      remakedItem.text = item.text;
      remakedItem.iconLeft = <PatternIconStyled type={item.pattern} />;
      remakedItem.focused = item.focused;
      remakedItem.pattern = item.pattern;
      remakedItem.ScreenInfos = item.ScreenInfos;

      return remakedItem;
    });
    this.onUpdate(
      fromJS(layoutListData),
    );
  }

  componentDidUpdate(prevProps) {
    const {
      layoutList: curPropsListDataTemp,
    } = this.props;
    const {
      layoutList: prevPropsListDataTemp,
    } = prevProps;
    const prevPropsListData = prevPropsListDataTemp.toJS();
    const curPropsListData = curPropsListDataTemp.toJS();

    if (JSON.stringify(prevPropsListData) !== JSON.stringify(curPropsListData)) {
      const layoutListData = curPropsListData.map(item => {
        const remakedItem = {
          id: '',
          text: '',
          ScreenInfos: [],
          data: {
            input: false,
          },
        };
        if (item.id === undefined || item.id === 'undefined') {
          remakedItem.id = Math.random().toString(36).substring(2, 15);
        } else {
          remakedItem.id = item.id;
        }
        remakedItem.text = item.text;
        remakedItem.iconLeft = <PatternIconStyled type={item.pattern} />;
        remakedItem.focused = item.focused;
        remakedItem.pattern = item.pattern;
        remakedItem.ScreenInfos = item.ScreenInfos;
        remakedItem.data.input = item.data.input;

        return remakedItem;
      });
      this.onUpdate(
        fromJS(layoutListData),
      );
    }
  }

  onUpdate = layoutList => (
    this.setState({
      layoutList,
    })
  )

  onClickListItem = selectedListItem => {
    const {
      updateSelectedLayoutListItem,
    } = this.props;
    updateSelectedLayoutListItem(selectedListItem);
  }

  onDBClickListItem = onTileCameraItems => {
    const { layoutList: curStateLayoutList } = this.state;
    const layoutList = curStateLayoutList.toJS();
    for (let i = 0; i < layoutList.length; i += 1) {
      if (onTileCameraItems[0] === i) {
        CameraInfoActions.onLayoutList({
          layoutList: layoutList[i].ScreenInfos,
          pattern: layoutList[i].pattern,
        });
      }
    }
  }

  render() {
    const { render } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

LayoutListContainer.defaultProps = {
};

LayoutListContainer.propTypes = {
  render: PropTypes.func.isRequired,
  layoutList: PropTypes.instanceOf(List).isRequired,
  updateSelectedLayoutListItem: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    lang: state.langModule.get('lang'),
  }),
)(LayoutListContainer);
