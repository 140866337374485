import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { PTZAuxControllerContainer } from 'containers/organisms';
import { getLanguage } from 'util/lib';
import {
  Container,
  Content,
  AuxButton,
  AuxLabel,
} from './PTZAuxControllerStyled';

const PTZAuxController = ({
  onClickAuxOn,
  onClickAuxOff,
  auxList,
}) => (
  <div>
    {auxList.length > 0
      && (
      <Container>
        {auxList.map(index => (
          <Content>
            <AuxLabel>{`AUX${index + 1}`}</AuxLabel>
            <AuxButton onClick={() => onClickAuxOn(index + 1)}>{getLanguage('lang_on')}</AuxButton>
            <AuxButton onClick={() => onClickAuxOff(index + 1)}>{getLanguage('lang_off')}</AuxButton>
          </Content>
        ))}
      </Container>
      )
    }
  </div>
);

PTZAuxController.propTypes = {
  onClickAuxOn: PropTypes.func.isRequired,
  onClickAuxOff: PropTypes.func.isRequired,
  auxList: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default withContainer(PTZAuxControllerContainer, PTZAuxController);
