import styled from 'styled-components';

export const Listgroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 5px;
  .active{
    color: #f37321;
  }
`;
