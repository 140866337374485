import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { PopoverContainer } from 'wisenet-ui/containers/organisms';
import {
  ManagerWrapperStyled,
  PopoverWrapperStyled,
} from './PopoverStyled';

const PortalComponent = ({
  children,
  position,
  setRef,
}) => createPortal(
  <PopoverWrapperStyled
    style={{
      top: position.top,
      left: position.left,
    }}
    ref={el => setRef('popupEl', el)}
  >
    {children}
  </PopoverWrapperStyled>,
  document.body,
);

const Popover = ({
  children,
  isOpen,
  content,
  onClick,
  setRef,
  position,
}) => (
  <ManagerWrapperStyled>
    {
      React.Children.map(children, child => (
        React.cloneElement(child, {
          onClick,
          ref: el => setRef('managerEl', el),
        })
      ))
    }
    {
      isOpen && (
        <PortalComponent
          position={position}
          setRef={setRef}
        >
          {content}
        </PortalComponent>
      )
    }
  </ManagerWrapperStyled>
);

Popover.defaultProps = {
  // onClickOutside: () => {},
  // padding: 10,
  // style: {},
};

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  position: PropTypes.instanceOf(Object).isRequired,
  // onClickOutside: PropTypes.func,
  // position: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  // padding: PropTypes.number,
  // style: PropTypes.instanceOf(Object),
};

export default withContainer(PopoverContainer, Popover);
