import styled, { css } from 'styled-components';

export const HeaderWrapperStyled = styled.div`
  display: flex;
  height: 60px;
  z-index: 6;
  background-color: ${props => props.theme.background.color1};
  color: ${props => props.theme.text.color2};
  margin: 0 24px 0 24px;
  ${props => props.isFullscreen && css`
    display: none;
  `}
  user-select: none;
`;

export const LogoWrapperStyled = styled.div`
  display: flex;
  flex-basis: 292px;
  justify-content: center;
  align-items: center;
`;

export const CenterWrapperStyled = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: 26px;
`;

export const AlarmAndFuncWrapperStyled = styled.div`
  display: flex;
  flex-basis: 432px;
  justify-content: flex-end;
  align-items: center;
`;

export const AlarmWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;
