import React from 'react';
import PropTypes from 'prop-types';
import {
  CAMERA_MENU,
  RECORD_MENU,
  EVENT_MENU,
  DEVICE_MENU,
  NETWORK_MENU,
  SYSTEM_MENU,
} from './userAuthList';

class UserAuthContainer extends React.Component {
  allSupport = true;

  shouldComponentUpdate(nextProps) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)) {
      return false;
    }
    return true;
  }

  checkAuthentication = (menu, data) => {
    const result = menu.map(item => {
      if (item.child) {
        const checkChild = this.checkAuthentication(item.child, data);
        const isAuth = checkChild.reduce(
          (accumulator, currentValue) => currentValue.value && accumulator,
          true,
        );
        return {
          ...item,
          child: checkChild,
          value: isAuth,
        };
      }
      const isAuth = (data[`${item.accessMenu}MenuAccess`].indexOf(item.id) !== -1);
      this.allSupport = this.allSupport && isAuth;
      return {
        ...item,
        value: isAuth,
      };
    });
    return result;
  }

  parseMenuData = data => {
    const cameraMenu = this.checkAuthentication(CAMERA_MENU, data);
    const recordMenu = this.checkAuthentication(RECORD_MENU, data);
    const eventMenu = this.checkAuthentication(EVENT_MENU, data);
    const deviceMenu = this.checkAuthentication(DEVICE_MENU, data);
    const networkMenu = this.checkAuthentication(NETWORK_MENU, data);
    const systemMenu = this.checkAuthentication(SYSTEM_MENU, data);
    return {
      cameraMenu,
      recordMenu,
      eventMenu,
      deviceMenu,
      networkMenu,
      systemMenu,
    };
  }

  render() {
    const { render } = this.props;
    const { userAuthInfo } = this.props;
    const authMenuData = userAuthInfo && this.parseMenuData(userAuthInfo);
    return render({
      ...this,
      ...this.state,
      ...this.props,
      ...authMenuData,
    });
  }
}

UserAuthContainer.defaultProps = { };

UserAuthContainer.propTypes = {
  render: PropTypes.func.isRequired,
  userAuthInfo: PropTypes.instanceOf(Object).isRequired,
};

export default UserAuthContainer;
