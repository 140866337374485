import styled, { withTheme, css } from 'styled-components';
import { Rnd } from 'react-rnd';
import ReactTooltip from 'react-tooltip';
import {
  IconButton,
  ReactModalAdapter,
  Button,
} from 'wisenet-ui/components/atoms';
import { Loading } from 'wisenet-ui/components/molecules';
import { ResizableAccordion } from 'wisenet-ui/components/organisms';
import { ListTabs } from 'components/molecules';

export const IconStyled = styled.i`
  display: flex;
  font-size: 20px;
  cursor: pointer;
  width: 32px;
  height: 42px;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${props => props.theme.background.color3};
  }

  &:active {
    background-color: ${props => props.theme.background.color4};
  }
`;

export const EventTabWrapper = styled.div`
  height: 100%;
  min-height: 400px;
`;

export const TabHeaderWrapperStyled = styled.div`
  width: 100%;
  border-right: 1px solid ${props => props.theme.line.color2};
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.isLast && css`
    border-right: none;
  `}
`;

export const TabIconStyled = styled.i`
  font-size: 20px;
`;

export const TabTitleStyled = styled.span`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  margin-left: 4px;
`;

export const ContentStyled = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const LivePageWrapperStyled = styled.div`
  background-color: ${props => props.theme.background.color1};
  width: 100%;
  padding-right: 26px;
  padding-left: 24px;
  &:focus {
    outline: none;
  }
  ${props => props.noPadding && css`
    padding-left: 0px;
    padding-right: 0px;
  `}
  position: relative;
`;

export const SideComponentWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 83px);
  ${props => props.isFullscreen && css`
    height: 100vh;
  `}
  border-top: 1px solid ${props => props.theme.line.color6};
  border-bottom: 1px solid ${props => props.theme.line.color6};
  overflow: hidden;
`;

export const SideControlWrapperStyled = withTheme(styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  margin-bottom: 58px;
  ${props => props.isVideoOver && css`
    position: absolute;
    width: 16px;
    top: calc(50% - 20px);
    color: #fff;
    z-index: 3;
    left: 8px;
    text-shadow: 1px 0px 0px #000;
  `}
  ${props => props.isFullscreen && css`
    margin-bottom: 0;
  `}
`);

export const SideControlButtonStyled = styled(IconButton)`
  width: 24px;
  height: 44px;
  padding: 0;
  color: ${props => props.theme.line.color4};
`;

export const SideControlIconStyled = styled.i`
  font-size: 30px;
  width: 16px;
  height: 30px;
  ${props => props.isFolding && css`
    transform: rotate(180deg);
  `}
`;

export const RndStyled = styled(Rnd)`
  position: static !important;
  transform: none !important;
  flex: 1;
  & > span {
    position: relative;
    display: flex;
  }
  ${props => (props.initheight === 'true') && css`
    height: calc(100vh - 83px - 35%) !important;
    ${(props.isfullscreen === 'true') && css`
      height: calc(100vh - 35%) !important;
    `}
  `}
  max-height: calc(100vh - 83px - 47px) !important;
  ${props => (props.isfullscreen === 'true') && css`
    max-height: calc(100vh - 47px) !important;
  `}
`;

export const ListTabsStyled = styled(ListTabs)`
 height: 100%;
 .content-wrapper {
  border-top: 1px solid ${props => props.theme.line.color3};
  overflow-x: hidden;
  overflow-y: auto;
 }
`;

export const ListAccordionStyled = styled(ResizableAccordion)`
  .accordion-item {
    border-color: ${props => props.theme.line.color3}
  }
  .accordion-item-header {
    border-color: ${props => props.theme.line.color1}
  }
  .accordion-item-content {
    padding: 0 12px;
  }
`;

export const PTZAccordionStyled = styled(ResizableAccordion)`
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px solid ${props => props.theme.line.color6};
  min-height: ${props => props.minHeight};
  max-height: ${props => props.maxHeight};
  .accordion-item {
    border-color: ${props => props.theme.line.color3}
  }
  .accordion-item-header {
    border-color: ${props => props.theme.line.color1}
  }
  .accordion-item-content {
    padding: 0 12px;
  }
`;

export const HeadTitleStyled = styled.span`
  font-size: 16px;
`;

export const PopupWrapper = withTheme(styled(ReactModalAdapter).attrs({
  className: 'Popup',
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  
  .ReactModal__Overlay {
    width: 100%;
    height: 100%;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    background-color: rgba(0,0,0,0.5) !important;
  }

  .ReactModal__Content {
    top: 50% !important;
    left: 50% !important;
    width: 300px;
    height: 200px;
    transform: translate(-50%, -50%) !important;
    background-color: ${props => props.theme.background.color1}!important;
    border: 1px solid ${props => props.theme.line.color5} !important;
    border-radius: 0px !important;
  }

  .ReactModal__Content--after-open {
    padding: 0px !important;
    overflow: hidden !important;
  }
`);

export const PopupButtonStyled = styled(Button)`
  min-width: 100px;
  height: 28px;
  font-size: 14px;
  padding: 0;
`;

export const PopupContentWrapperStyled = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-all;
`;

export const CustomReactTooltip = styled(ReactTooltip)`
  background-color: #555 !important;
  padding: 5px !important;
  &.place-top {
    &:after {
      border-top: none !important;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom: none !important;
    }
  }
`;

export const TooltipSpan = styled.span`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  margin: 0px;
  padding: 0px
`;

export const LoadingStyled = styled(Loading)`
  position: initial;
`;
