import React from 'react';
import PropTypes from 'prop-types';
import {
  ListStyled,
} from './TabStyled';

const Tab = ({
  header,
  tabIndex,
  isActive,
  onClick,
  toolTip,
  className,
  name,
  onSelectedTab,
  disabled,
}) => (
  <ListStyled
    className={`tab ${className} ${isActive && 'active'}`}
    onClick={e => {
      e.preventDefault();
      if (name) {
        onSelectedTab(name);
      }
      onClick(tabIndex);
    }}
    title={toolTip}
    disabled={disabled}
  >
    {header}
  </ListStyled>
);


Tab.propTypes = {
  header: PropTypes.node,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  isActive: PropTypes.bool,
  toolTip: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  name: PropTypes.string,
  onSelectedTab: PropTypes.func,
  disabled: PropTypes.bool,
};

Tab.defaultProps = {
  onClick: () => null,
  tabIndex: 0,
  isActive: false,
  header: null,
  toolTip: '',
  className: null,
  name: '',
  onSelectedTab: () => {},
  disabled: false,
};

export default Tab;
