import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { TextResultContainer } from 'containers/organisms';
import { getLanguage } from 'util/lib';
import {
  Container,
  PosWrapperStyled,
  ChannelContainer,
  HrStyled,
  PosSpanStyled,
  SpanTitleStyled,
  SpanDateTimeStyled,
  SpanChannelStyled,
  HighlightSpanStyled,
  ReceiptContainerStyled,
  PosChannelsStyled,
  PosChannelStyled,
  ReactTooltipStyled,
  TooltipSpanStyled,
} from './TextResultStyled';

const KeywordHighlight = props => {
  const { keyword } = props;
  let rest = props.text;
  const innerKeywordList = [];

  if (!keyword) {
    return (
      <></>
    );
  }

  for (let i = 0; i < keyword.length; i += 1) {
    const reg = new RegExp(keyword[i], 'gi');
    rest = rest.replace(reg, innerKey => {
      innerKeywordList.push(innerKey);
      return `${i}@@`;
    });
  }
  const textData = rest.split('@@');
  const textLength = textData.length;
  const highlight = textData.map((tdata, i) => {
    if (textLength === i + 1) {
      return <PosSpanStyled key={`highlightLast-${(i + 1) * (i + 1)}`}>{tdata}</PosSpanStyled>;
    }
    return (
      <PosSpanStyled key={`highlightNormal-${(i + 1) * (i + 1)}`}>
        {tdata.slice(0, tdata.length - 1)}
        <HighlightSpanStyled key={`highlight-${(i + 1) * (i + 1)}`}>
          {keyword[tdata[tdata.length - 1]]}
        </HighlightSpanStyled>
      </PosSpanStyled>
    );
  });
  return highlight;
};

const TextResult = ({ selectEvent, deviceInfo }) => {
  let channelList = selectEvent.ChannelIDList;
  let etcChannelList = [];
  if (channelList && channelList.length > 6) {
    channelList = selectEvent.ChannelIDList.slice(0, 5);
    etcChannelList = selectEvent.ChannelIDList.slice(5, selectEvent.ChannelIDList.length);
  }
  let Channels = null;
  if (selectEvent.ChannelIDList) {
    Channels = selectEvent.ChannelIDList.length > 6 ? (
      <PosChannelsStyled>
        {channelList.map(item => (
          <PosChannelStyled key={`channel-${item}`}>{item + 1}</PosChannelStyled>
        ))}
        <PosChannelStyled
          data-tip="etc"
          data-for="etc"
          isEtc
        >
          {'...'}
        </PosChannelStyled>
        <ReactTooltipStyled
          id="etc"
          type="light"
          effect="solid"
          place="bottom"
          delayShow={500}
          getContent={() => (
            <TooltipSpanStyled>
              <div>
                {getLanguage('lang_ch')}
              </div>
              {
                etcChannelList.map((number, index) => {
                  const item = number + 1;
                  const value = item >= 10 ? item : `0${item}`;
                  return ((index + 1) % 5 !== 0) ? `${value} ` : `${value} \n`;
                })
              }
            </TooltipSpanStyled>
          )}
        />
      </PosChannelsStyled>
    ) : (
      <PosChannelsStyled>
        {channelList.map(item => (
          <PosChannelStyled key={`channel-${item}`}>{item + 1}</PosChannelStyled>
        ))}
      </PosChannelsStyled>
    );
  }
  return (
    <Container id="textResult">
      <PosWrapperStyled>
        <SpanTitleStyled>{deviceInfo && deviceInfo.DeviceName}</SpanTitleStyled>
        <SpanDateTimeStyled>{selectEvent.Date}</SpanDateTimeStyled>
      </PosWrapperStyled>
      {
        selectEvent.ChannelIDList && <HrStyled />
      }
      <ChannelContainer>
        <SpanChannelStyled>
          {
            selectEvent.ChannelIDList && getLanguage('lang_ch')
          }
        </SpanChannelStyled>
        {Channels}
      </ChannelContainer>
      {
        selectEvent.Result && (
          <ReceiptContainerStyled>
            <KeywordHighlight text={selectEvent.TextData} keyword={selectEvent.KeywordsMatched} />
          </ReceiptContainerStyled>
        )
      }
    </Container>
  );
};

TextResult.propTypes = {
  deviceInfo: PropTypes.instanceOf(Object),
  selectEvent: PropTypes.shape({
    Result: PropTypes.number,
    DeviceID: PropTypes.number,
    Date: PropTypes.string,
    PlayTime: PropTypes.string,
    TextData: PropTypes.string,
  }).isRequired,
};

TextResult.defaultProps = {
  deviceInfo: {},
};

export default withContainer(TextResultContainer, TextResult);
