import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PTZSequenceActions } from 'store/actionCreators';

class PTZSwingContainer extends React.Component {
  state = {
    currentSwingList: null,
    prevSwing: null,
  };

  componentWillReceiveProps(nextProps) {
    const { currentChannel } = nextProps;
    const { prevChannel } = this.props;
    if (currentChannel !== -1) {
      const { swingList, ptzInfo } = nextProps;
      if ((swingList === null || prevChannel !== currentChannel)
        && (typeof ptzInfo !== 'undefined' && ptzInfo.swing)) {
        // PTZSequenceActions.setSwingPending(true);
        const getData = {
          Channel: currentChannel,
        };
        PTZSequenceActions.getSwing(getData);

        PTZSequenceActions.setPrevChannel(currentChannel);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      currentChannel,
      swingList: nextSwingList,
      ptzInfo,
      isActive,
    } = nextProps;

    const {
      currentChannel: prevChannel,
      swingList,
    } = this.props;

    const {
      currentSwingList: currentSwingListState,
      prevSwing,
    } = nextState;
    const { currentSwingList: prevSwingListState } = this.state;

    document.getElementById('swing_disable_mask').style.display = 'block';
    document.getElementById('swing_wrapper').style.pointerEvents = 'none';

    if (typeof ptzInfo !== 'undefined' && ptzInfo.swing) {
      document.getElementById('swing_disable_mask').style.display = 'none';
      document.getElementById('swing_wrapper').style.pointerEvents = 'auto';
    } else {
      document.getElementById('swing_disable_mask').style.display = 'block';
      document.getElementById('swing_wrapper').style.pointerEvents = 'none';
    }

    if ((!Number.isNaN(prevChannel) || !Number.isNaN(currentChannel)) && isActive) {
      if (prevChannel !== currentChannel) {
        if (prevChannel !== null && !Number.isNaN(prevChannel) && prevSwing !== null) {
          // 채널 변경시 이전 채널 동작 중지
          // const getData = {
          //   Channel: prevChannel,
          //   Mode: 'Stop',
          // };
          // PTZSequenceActions.controlSwing(getData);
          // this.setState({
          //   prevSwing: null,
          // });
        }
        if (nextSwingList !== null && typeof nextSwingList !== 'undefined') {
          this.setState({
            currentSwingList: this.makeSwingList(nextSwingList),
          });
          return true;
        }
        return false;
      }
      if (JSON.stringify(swingList) !== JSON.stringify(nextSwingList)) {
        // 채널 props 업데이트가 리스트 정보 업데이트보다 빨라서 별도 분기 처리.
        this.setState({
          currentSwingList: this.makeSwingList(nextSwingList),
        });
        return true;
      }
    }
    // 현재 탭에서 상태 변경 시
    if (prevSwingListState === null || currentSwingListState === null) {
      if (nextSwingList !== null && typeof nextSwingList !== 'undefined' && isActive) {
        this.setState({
          currentSwingList: this.makeSwingList(nextSwingList),
        });
        return true;
      }
      return false;
    }

    return true;
  }

  makeSwingList = swingList => {
    const array = [];
    if (swingList.length > 0) {
      const swingSequence = swingList[0].SwingSequence;
      for (let idx = 1; idx <= swingSequence.length; idx += 1) {
        array.push({
          index: idx - 1,
          no: idx,
          name: `Swing ${idx}`,
          mode: swingSequence[idx - 1].Mode,
          isSelected: false,
        });
      }
    }
    return array;
  };

  onChangeData = (dataList, selectedData) => {
    let getData = {};
    const { currentChannel } = this.props;

    if (selectedData.isSelected) {
      getData = {
        Channel: currentChannel,
        Mode: selectedData.mode,
      };
    } else {
      getData = {
        Channel: currentChannel,
        Mode: 'Stop',
      };
    }
    PTZSequenceActions.controlSwing(getData);

    this.setState({
      currentSwingList: dataList,
      prevSwing: selectedData,
    });
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

PTZSwingContainer.propTypes = {
  render: PropTypes.func.isRequired,
  swingList: PropTypes.arrayOf(Object),
  currentChannel: PropTypes.number,
  prevChannel: PropTypes.number,
  ptzInfo: PropTypes.objectOf(PropTypes.any),
  isActive: PropTypes.bool,
};

PTZSwingContainer.defaultProps = {
  swingList: [],
  currentChannel: -1,
  prevChannel: -1,
  ptzInfo: {},
  isActive: false,
};

export default connect(
  state => ({
    isSwingPending: state.ptzSequenceModule.get('isSwingPending'),
    swingList: state.ptzSequenceModule.get('swingList'),
    lang: state.langModule.get('lang'),
    prevChannel: state.ptzSequenceModule.get('prevChannel'),
  }),
)(PTZSwingContainer);
