import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';
import './util/static/font-icon/icons.css';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import '../node_modules/fullcalendar/dist/fullcalendar.min.css';
import './util/static/font/font.css';
import './wisenet-ui/util/function/log';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Auth, CustomXHR } from 'util/lib';
import { getCookie, eraseCookie } from 'util/lib/Cookie';
import Root from './Root';

const initAxios = () => {
  const baseURL = process.env.NODE_ENV !== 'production' ? 'http://192.168.123.227' : '';
  axios.defaults.baseURL = baseURL;
  axios.defaults.timeout = 30000;
  CustomXHR.baseURL = baseURL;
};

const initAuth = () => {
  if (process.env.NODE_ENV !== 'production') {
    eraseCookie('AUTH_HEADER_UWA');
  }
  Auth.defaultSet({
    userid: getCookie('ID') ? atob(getCookie('ID')) : null,
    authKey: getCookie('AUTH_KEY') || null,
    authHeader: getCookie('AUTH_HEADER_UWA') ? JSON.parse(getCookie('AUTH_HEADER_UWA')) : null,
  });
};

initAxios();
initAuth();

ReactDOM.render(<Root />, document.getElementById('root'));
