import {
  take,
  all,
  put,
  call,
} from 'redux-saga/effects';
import { SunapiClient } from 'util/lib';
import * as ptzAuxActions from 'store/modules/ptz/ptzAuxModule';

const CONTROL_AUX = '/stw-cgi/ptzcontrol.cgi?msubmenu=aux&action=control';

function* asyncControlAux() {
  while (true) {
    try {
      const { payload } = yield take(ptzAuxActions.CONTROL_AUX);
      const promise = yield call([SunapiClient, 'get'], CONTROL_AUX, payload);
      if (promise.status === 200 && typeof promise.data.Error === 'undefined') {
        yield put(ptzAuxActions.controlAuxSuccess());
      } else {
        yield put(ptzAuxActions.controlAuxFail());
      }
    } catch (exception) {
      yield put(ptzAuxActions.controlAuxFail());
    }
  }
}

export default function* rootPTZAuxSaga() {
  yield all([
    asyncControlAux(),
  ]);
}
