import React from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { DateHeader } from 'wisenet-ui/components/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { BasicDatePickerContainer } from 'containers/organisms';
import { Wrapper } from './BasicDatePickerStyled';

class BasicDatePicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
    this.onClickToday = this.onClickToday.bind(this);
  }

  onSelect = date => {
    const {
      onDateChange,
    } = this.props;

    onDateChange(date);
  }

  onClickToday = e => {
    const {
      onDateChange,
      today,
    } = this.props;

    this._calendar.handleSelect(today, e);

    onDateChange(today);
  }

  render() {
    const {
      selectedDate,
      includeDates,
    } = this.props;

    // const parent = document.getElementsByClassName('react-datepicker__day-names');

    // const days = [
    //   'lang_mid_sunday',
    //   'lang_mid_monday',
    //   'lang_mid_tuesday',
    //   'lang_mid_wednesday',
    //   'lang_mid_thursday',
    //   'lang_mid_friday',
    //   'lang_mid_saturday',
    // ];

    // if (typeof parent[0] !== 'undefined') {
    //   const nodeList = parent[0].childNodes;
    //   nodeList.forEach(node => {
    //     node.innerHTML = getLanguage(days.shift());
    //   });
    // }

    // const selectedDatePicker = includeDates.some(
    //   item => item.getMonth() === selectedDate.getMonth()
    //   && item.getFullYear() === selectedDate.getFullYear()
    //   && item.getDay() === selectedDate.getDay(),
    // ) ? selectedDate : includeDates[0];

    return (
      <Wrapper>
        <DatePicker
          useWeekdaysShort
          inline
          shouldCloseOnSelect={false}
          ref={c => { this._calendar = c; }}
          selected={selectedDate}
          onChange={this.onSelect}
          onMonthChange={this.onSelect}
          onYearChange={this.onSelect}
          dateFormat="yyyy-MM-dd HH:mm:ss"
          includeDates={includeDates}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <DateHeader
              date={date}
              changeYear={changeYear}
              changeMonth={changeMonth}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
              onClick={this.onClickToday}
            />
          )}
        />
      </Wrapper>
    );
  }
}

BasicDatePicker.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func,
  includeDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  today: PropTypes.instanceOf(Date),
};

BasicDatePicker.defaultProps = {
  selectedDate: null,
  onDateChange: () => {},
  includeDates: [],
  today: new Date(),
};

export default withContainer(BasicDatePickerContainer, BasicDatePicker);
