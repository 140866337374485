import React from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { withContainer } from 'wisenet-ui/util/hoc';
import { DatePickerContainer } from 'wisenet-ui/containers/organisms';
// import { getLanguage } from 'util/lib';
import DatePicker from 'react-datepicker';
import DateHeader from './DateHeader';
import { Wrapper } from './DatePickerPopperStyled';

class CustomDatePicker extends React.Component {
  constructor(props) {
    super(props);
    const { dateTimeObj } = this.props;
    const {
      year,
      month,
      day,
      hour,
      minute,
      second,
    } = dateTimeObj;

    const date = new Date(
      year,
      month - 1,
      day,
      hour,
      minute,
      second,
    );

    this.state = {
      currentDate: date,
    };
    this.onSelect = this.onSelect.bind(this);
    this.onClickToday = this.onClickToday.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const {
      isCalendarOpen: prevIsCalendarOpen,
      dateTimeObj,
    } = this.props;
    const {
      isCalendarOpen: nextIsCalendarOpen,
      actions,
      dateTimeObj: nextDateTimeObj,
    } = nextProps;

    if (prevIsCalendarOpen !== nextIsCalendarOpen) {
      if (nextIsCalendarOpen === false) {
        this._calendar.setOpen(nextIsCalendarOpen);
        setTimeout(() => {
          actions.setCalendarOpen(true);
        });
      }
    }

    if (JSON.stringify(dateTimeObj) !== JSON.stringify(nextDateTimeObj)) {
      const {
        year,
        month,
        day,
      } = nextDateTimeObj;
      this.setState({
        currentDate: new Date(year, month - 1, day),
      });
    }
    return true;
  }

  onSelect = date => {
    const { onDateChange, label } = this.props;
    onDateChange(date, label);
    this.setState({
      currentDate: date,
    });
  }

  onClickToday = e => {
    const { onDateChange, label } = this.props;
    const today = new Date();
    this._calendar.handleSelect(today, e);
    onDateChange(today, label);
    this.setState({
      currentDate: today,
    });
  }

  render() {
    const {
      popperModifiers,
      children: customComponents,
      actions,
    } = this.props;
    const { currentDate } = this.state;

    // const parent = document.getElementsByClassName('react-datepicker__day-names');

    // const days = [
    //   'lang_mid_sunday',
    //   'lang_mid_monday',
    //   'lang_mid_tuesday',
    //   'lang_mid_wednesday',
    //   'lang_mid_thursday',
    //   'lang_mid_friday',
    //   'lang_mid_saturday',
    // ];

    // if (typeof parent[0] !== 'undefined') {
    //   const nodeList = parent[0].childNodes;
    //   nodeList.forEach(node => {
    //     node.innerHTML = getLanguage(days.shift());
    //   });
    // }

    // if (typeof parent[1] !== 'undefined') {
    //   const nodeList = parent[1].childNodes;
    //   nodeList.forEach(node => {
    //     node.innerHTML = getLanguage(days.shift());
    //   });
    // }

    return (
      <Wrapper>
        <DatePicker
          useWeekdaysShort
          shouldCloseOnSelect={false}
          ref={c => { this._calendar = c; }}
          customInput={customComponents[0]}
          calendarContainer={
            ({ className, children }) => (
              React.cloneElement(
                customComponents[1],
                {
                  className,
                  children,
                  actions,
                },
              )
            )
          }
          selected={currentDate}
          onChange={this.onSelect}
          dateFormat="yyyy-MM-dd HH:mm:ss"
          popperModifiers={popperModifiers}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <DateHeader
              date={date}
              changeYear={changeYear}
              changeMonth={changeMonth}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
              onClick={this.onClickToday}
            />
          )}
        />
      </Wrapper>
    );
  }
}

CustomDatePicker.propTypes = {
  onDateChange: PropTypes.func,
  label: PropTypes.string,
  dateTimeObj: PropTypes.objectOf(PropTypes.any),
  isCalendarOpen: PropTypes.bool,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  popperModifiers: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

CustomDatePicker.defaultProps = {
  onDateChange: () => {},
  label: '',
  dateTimeObj: {},
  isCalendarOpen: true,
};

export default withContainer(DatePickerContainer, CustomDatePicker);
