import styled, { withTheme } from 'styled-components';
import { IconButton, Span } from 'wisenet-ui/components/atoms';
import ReactTooltip from 'react-tooltip';

export const PatternSelectStyled = styled.div`
  display: flex;
  width: 175px;
  height: 88px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const IconButtonStyled = withTheme(styled(IconButton)`
  padding: 0 9px;
`);

export const TooltipSpan = withTheme(styled(Span)`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  margin: 0px;
  padding: 0px
`);

export const CustomReactTooltip = withTheme(styled(ReactTooltip)`
  background-color: #555 !important;
  padding: 5px !important;
  &.place-top {
    &:after {
      border-top: none !important;
    }
  }
  &.place-left {
    &:after {
      border-left: 6px solid #555 !important;
    }
  }
  &.place-right {
    &:after {
      border-right: 6px solid #555 !important;
    }
  }
`);
