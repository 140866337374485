/* eslint-disable max-len */
import { createAction, handleActions } from 'redux-actions';
import { Map, List, fromJS } from 'immutable';
// import store from 'store/modules/index.js';

// action
export const INIT_SEARCH_TIMELINE = 'searchTimeline/INIT_SEARCH_TIMELINE';

export const SET_CURRENT_CHANNEL = 'searchTimeline/SET_CURRENT_CHANNEL';

export const SET_SELECT_EVENT = 'searchTimeline/SET_SELECT_EVENT';

export const GET_OVERLAPPED_ID_LIST = 'searchTimeline/GET_OVERLAPPED_ID';
export const GET_OVERLAPPED_ID_LIST_SUCCESS = 'searchTimeline/GET_OVERLAPPED_ID_SUCCESS';
export const GET_OVERLAPPED_ID_LIST_FAILURE = 'searchTimeline/GET_OVERLAPPED_ID_FAILURE';

export const SET_OVERLAPPED_ID = 'searchTimeline/SET_OVERLAPPED_ID';

export const GET_TIMELINE = 'searchTimeline/GET_TIMELINE';
export const GET_TIMELINE_SUCCESS = 'searchTimeline/GET_TIMELINE_SUCCESS';
export const GET_TIMELINE_FAILURE = 'searchTimeline/GET_TIMELINE_FAILURE';

export const CHANGE_EVENT_FILTER = 'searchTimeline/CHANGE_EVENT_FILTER';

export const APPLY_EVENT_LIST_FILTER = 'searchTimeline/APPLY_EVENT_LIST_FILTER';

export const SET_INSTANT_PLAYBACK_SEEKING = 'searchTimeline/SET_INSTANT_PLAYBACK_SEEKING';
export const SET_INSTANT_PLAYBACK_SEEKING_SUCCESS = 'searchTimeline/SET_INSTANT_PLAYBACK_SEEKING_SUCCESS';
export const SET_INSTANT_PLAYBACK_SEEKING_FAILURE = 'searchTimeline/SET_INSTANT_PLAYBACK_SEEKING_FAILURE';

export const GET_TIMEZONE = 'searchTimeline/GET_TIMEZONE';

// action create
export const setInitSearchTimeline = createAction(INIT_SEARCH_TIMELINE);

export const setCurrentChannel = createAction(SET_CURRENT_CHANNEL);

export const setSelectEvent = createAction(SET_SELECT_EVENT);

export const getOverlappedIdList = createAction(GET_OVERLAPPED_ID_LIST);
export const getOverlappedIdListSuccess = createAction(GET_OVERLAPPED_ID_LIST_SUCCESS);
export const getOverlappedIdListFailure = createAction(GET_OVERLAPPED_ID_LIST_FAILURE);

export const setOverlappedId = createAction(SET_OVERLAPPED_ID);

export const getTimeline = createAction(GET_TIMELINE);
export const getTimelineSuccess = createAction(GET_TIMELINE_SUCCESS);
export const getTimelineFailure = createAction(GET_TIMELINE_FAILURE);

export const chagneEventFilter = createAction(CHANGE_EVENT_FILTER);

export const applyEventListFilter = createAction(APPLY_EVENT_LIST_FILTER);

export const setInstantPlaybackSeeking = createAction(SET_INSTANT_PLAYBACK_SEEKING);

export const getTimezone = createAction(GET_TIMEZONE);

// timelineModule에서 필요한 데이터 표시
const defaultState = Map({
  currentChannel: -1,
  overlappedIDList: [0],
  selectedOverlappedID: 0,
  selectedOverlappedIDForUmp: 0,
  searchDate: {},
  timeLineSearchResults: [],
  selectEvent: Map({
    // EndTime: "2019-02-06T21:25:32Z",
    // Result: 3,
    // StartTime: "2019-02-06T21:24:36Z",
    // Type: "MotionDetection",
    // index: 1,
    // isReopen: true, // Vidoe의 재 렌더를 조정하기 위해서 넣은 값
  }),
  filterEvent: List([]),
  overlappedIdChangeTime: 0,
  instantPlaybackSeeking: {
    currentTime: '',
    localTime: '',
  },
  timezone: '0',
});

const initialState = defaultState;

// reducer
export default handleActions({
  [SET_CURRENT_CHANNEL]: (state, { payload }) => (
    state.set('currentChannel', payload)
  ),
  [SET_SELECT_EVENT]: (state, { payload }) => {
    const {
      isDst,
      StartTime: payloadStartTime,
      selectedOverlappedIDForUmp,
    } = payload;
    if (!payloadStartTime) {
      return state.set('selectEvent', fromJS({}));
    }
    const StartTime = new Date(payloadStartTime);
    if (isDst && StartTime instanceof Date) {
      StartTime.setHours(StartTime.getHours() - 1);
    }
    d.log('>>> Request playback Time::', StartTime.toISOString());
    return state.set('selectEvent', fromJS({
      ...payload,
      StartTime: StartTime.toISOString(),
    })).set('selectedOverlappedIDForUmp', selectedOverlappedIDForUmp);
  },
  [GET_OVERLAPPED_ID_LIST_SUCCESS]: (state, { payload }) => (
    state.set('overlappedIDList', payload)
  ),
  [GET_OVERLAPPED_ID_LIST_FAILURE]: state => (
    state.set('overlappedIDList', [])
  ),
  [SET_OVERLAPPED_ID]: (state, { payload: { overlappedId, overlappedIdChangeTime } }) => (
    state.set('selectedOverlappedID', overlappedId)
      .set('overlappedIdChangeTime', overlappedIdChangeTime)
  ),
  [GET_TIMELINE_SUCCESS]: (state, { payload: { filterEvent, timeLineSearchResults } }) => (
    state.set('timeLineSearchResults', timeLineSearchResults)
      .set('filterEvent', fromJS(filterEvent))
  ),
  [GET_TIMELINE_FAILURE]: state => (
    state.set('timeLineSearchResults', [])
  ),
  [INIT_SEARCH_TIMELINE]: () => (
    defaultState
  ),
  [SET_INSTANT_PLAYBACK_SEEKING]: (state, { payload: { currentTime, localTime } }) => (
    state.set('instantPlaybackSeeking', { currentTime, localTime })
  ),
}, initialState);
