import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, fromJS, Map } from 'immutable';
import styled from 'styled-components';
import MediaStatusType from 'wisenet-ui/util/static/constants/mediaControl/mediaStatusType';
import {
  SearchTimelineActions,
  EventSearchActions,
  SearchMediaControlActions,
} from 'store/actionCreators';

const I = styled.i`
  display: inline-block;
  margin-right:4px;
  text-align: center;
  font-size:20px;
`;

const PTZ_ICON_STYLE = {
  [MediaStatusType.DISCONNECTED]: 'wni wni-camera-box',
  [MediaStatusType.VIDEOLOSS]: 'wni wni-camera-ptz',
  [MediaStatusType.CONNECTFAIL]: 'wni wni-camera-ptz',
  [MediaStatusType.COVERT1]: 'wni wni-camera-ptz',
  [MediaStatusType.COVERT2]: 'wni wni-camera-ptz',
  [MediaStatusType.AUTHFAIL]: 'wni wni-camera-ptz',
  [MediaStatusType.ACCBLOCK]: 'wni wni-camera-ptz',
  [MediaStatusType.SUCCESS]: 'wni wni-camera-ptz',
};

const ANALOG_ICON_STYLE = {
  [MediaStatusType.DISCONNECTED]: 'wni wni-camera-box',
  [MediaStatusType.VIDEOLOSS]: 'wni wni-camera-analog',
  [MediaStatusType.CONNECTFAIL]: 'wni wni-camera-analog',
  [MediaStatusType.COVERT1]: 'wni wni-camera-analog',
  [MediaStatusType.COVERT2]: 'wni wni-camera-analog',
  [MediaStatusType.AUTHFAIL]: 'wni wni-camera-analog',
  [MediaStatusType.ACCBLOCK]: 'wni wni-camera-analog',
  [MediaStatusType.SUCCESS]: 'wni wni-camera-analog',
};

const CAMERA_ICON_STYLE = {
  [MediaStatusType.DISCONNECTED]: 'wni wni-camera-box',
  [MediaStatusType.VIDEOLOSS]: 'wni wni-camera-box',
  [MediaStatusType.CONNECTFAIL]: 'wni wni-camera-box',
  [MediaStatusType.COVERT1]: 'wni wni-camera-box',
  [MediaStatusType.COVERT2]: 'wni wni-camera-box',
  [MediaStatusType.AUTHFAIL]: 'wni wni-camera-box',
  [MediaStatusType.ACCBLOCK]: 'wni wni-camera-box',
  [MediaStatusType.SUCCESS]: 'wni wni-camera-box',
};

class SerachCameraListContainer extends React.Component {
  state = {
    data: List([]),
    curSelectedListItem: -1,
  }

  componentDidMount() {
    const {
      cameraList: curPropsListDataTemp,
      currentChannel,
    } = this.props;
    const curPropsListData = curPropsListDataTemp.toJS();
    const {
      curSelectedListItem,
    } = this.state;

    const cameraListData = this.convertChannelList(
      curPropsListData,
      curSelectedListItem,
      null,
      currentChannel,
    );

    if (currentChannel !== -1) {
      this.onClickListItem({ id: currentChannel });
    }

    this.onUpdate(
      fromJS(cameraListData),
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      cameraList: curPropsListDataTemp,
      currentChannel: curCurrentChannel,
      focusedCamera: curFocusedCameraTemp,
    } = this.props;
    const {
      cameraList: prevPropsListDataTemp,
      focusedCamera: prevFocusedCameraTemp,
    } = prevProps;
    const {
      curSelectedListItem,
    } = this.state;
    const {
      curSelectedListItem: prevSelectedListItem,
    } = prevState;
    const prevPropsListData = prevPropsListDataTemp.toJS();
    const curPropsListData = curPropsListDataTemp.toJS();
    const prevFocusedCamera = prevFocusedCameraTemp.toJS();
    const curFocusedCamera = curFocusedCameraTemp.toJS();

    if ((JSON.stringify(prevPropsListData) !== JSON.stringify(curPropsListData))
      || (JSON.stringify(prevSelectedListItem) !== JSON.stringify(curSelectedListItem))) {
      const cameraListData = this.convertChannelList(
        curPropsListData,
        curSelectedListItem,
        null,
        curCurrentChannel,
      );
      this.onUpdate(
        fromJS(cameraListData),
      );
    }

    if (JSON.stringify(prevFocusedCamera) !== JSON.stringify(curFocusedCamera)) {
      const cameraListData = this.convertChannelList(
        curPropsListData,
        curSelectedListItem,
        curFocusedCamera,
        curCurrentChannel,
      );
      this.onUpdate(
        fromJS(cameraListData),
      );
    }

    // if (JSON.stringify(curCurrentChannel) !== JSON.stringify(prevCurrentChannel)) {
    //   if (curCurrentChannel !== -1) {
    //     EventSearchActions.setSearchDate(new Date());
    //     EventSearchActions.setSelectDateOnCalendar({
    //       date: null,
    //     });
    //   }
    // }
  }

  onUpdate = data => (
    this.setState({
      data,
    })
  )

  convertChannelList = (channelList, selectedChannel, focusedCamera, currentChannel) => (
    channelList.reduce((arr, item) => {
      if (item.searchAccess === false) {
        if (item.channel === currentChannel) {
          SearchTimelineActions.setInitSearchTimeline();
          EventSearchActions.setIniteventSearchData();
        }
        return arr;
      }
      const remakedItem = {
        id: '',
        text: '',
        data: {
          ipAddress: '',
          model: '',
        },
      };
      if (item.channel !== undefined) {
        if (Number.isNaN(item.channel)) {
          remakedItem.id = item.channel;
        } else {
          remakedItem.id = String(item.channel);
        }
      }
      if (item.channelName !== undefined) {
        remakedItem.text = item.channelName;
      }

      if (selectedChannel >= 0) {
        if (selectedChannel === item.channel) {
          remakedItem.assigned = true;
        }
      }

      if (item.ipAddress !== undefined) {
        remakedItem.data.ipAddress = item.ipAddress;
      }
      if (item.model !== undefined) {
        remakedItem.data.model = item.model;
      }

      if (item.protocol === 'ANALOG') {
        remakedItem.iconLeft = this.toAnalogIcon(item.status);
      } else if (item.isOpticalPTZ) {
        remakedItem.iconLeft = this.toPtzIcon(item.status);
      } else {
        remakedItem.iconLeft = this.toCameraIcon(item.status);
      }

      if (focusedCamera) {
        if (Number(focusedCamera.channel) === Number(item.channel)) {
          remakedItem.focused = true;
        }
      } else if (item.focused !== undefined) {
        remakedItem.focused = item.focused;
      }

      // currentChannel 와 item의 index를 비교하여 selected 를 true로 변경해야함
      if (item.channel === currentChannel) {
        remakedItem.selected = true;
      }
      arr.push(remakedItem);
      return arr;
    }, [])
  )

  toPtzIcon = type => <I className={PTZ_ICON_STYLE[type]} />;

  toAnalogIcon = type => <I className={ANALOG_ICON_STYLE[type]} />;

  toCameraIcon = type => <I className={CAMERA_ICON_STYLE[type]} />;

  onClickListItem = selectedCameraInfo => {
    const { instantPlaybackSeeking } = this.props;
    const { currentTime, localTime } = instantPlaybackSeeking;
    if (currentTime !== '' && localTime !== '') {
      // 인스턴트 플레이백 통해서 들어온 경우
      // 1. ump 영상 정지
      // SearchMediaControlActions.playControl({
      //   playMode: 'play',
      // });
      /**
       * id = 채널
       */
      const { id } = selectedCameraInfo;
      // const { searchDateObj: { year, month } } = this.props;
      const year = new Date(localTime).getFullYear();
      const month = new Date(localTime).getMonth() + 1;
      // SearchTimelineActions.setCurrentChannel(Number(id));
      // const year = (new Date()).getUTCFullYear();
      // const month = (new Date()).getMonth() + 1;
      const param = {
        index: Number(id),
        date: `${year}-${month}`,
      };
      d.log('000 **** param.date', param.date);
      EventSearchActions.findRecordingDate(param);
      this.setState({
        curSelectedListItem: Number(id),
      });
    } else {
      // 디바이스 선택시 초기화 필요
      // 1. ump 영상 정지
      SearchMediaControlActions.playControl({
        playMode: 'stop',
      });
      // 2. 캘린더 선택된 날짜 초기화
      EventSearchActions.setSearchDate(null);
      // 3. Timeline 의 timeline data 및 overlapped id 항목 초기화
      SearchTimelineActions.setInitSearchTimeline();
      // 4. Event 결과 List 초기화

      /**
       * id = 채널
       */
      const { id } = selectedCameraInfo;
      // const { searchDateObj: { year, month } } = this.props;
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      SearchTimelineActions.setCurrentChannel(Number(id));
      // const year = (new Date()).getUTCFullYear();
      // const month = (new Date()).getMonth() + 1;
      const param = {
        index: Number(id),
        date: `${year}-${month}`,
      };
      d.log('111 **** param.date', param.date);
      EventSearchActions.findRecordingDate(param);
      // 5. time range 초기화
      SearchMediaControlActions.setSelectTimeRange({
        useSelectTimeRange: false,
      });
      this.setState({
        curSelectedListItem: Number(id),
      });
    }
  }

  render() {
    const { render } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

SerachCameraListContainer.defaultProps = {
  currentChannel: -1,
  focusedCamera: [],
  instantPlaybackSeeking: {},
};

SerachCameraListContainer.propTypes = {
  render: PropTypes.func.isRequired,
  cameraList: PropTypes.instanceOf(List).isRequired,
  currentChannel: PropTypes.number,
  focusedCamera: PropTypes.instanceOf(Map),
  instantPlaybackSeeking: PropTypes.instanceOf(Object),
};

export default connect(
  state => ({
    cameraList: state.cameraInfoModule.get('cameraList'),
    currentChannel: state.searchTimelineModule.get('currentChannel'),
    focusedCamera: state.cameraInfoModule.get('selectTileCamera'),
    searchDateObj: state.eventSearchModule.get('searchDateObj'),
    searchTimeObj: state.eventSearchModule.get('searchTimeObj'),
    instantPlaybackSeeking: state.searchTimelineModule.get('instantPlaybackSeeking'),
  }),
)(SerachCameraListContainer);
