import React from 'react';
import PropTypes from 'prop-types';
import { HeaderNaviContainer } from 'containers/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { getLanguage } from 'util/lib';
import {
  NavigationWrapperStyled,
  NaviLinkStyled,
  NavIconStyled,
  NavSpanStyled,
} from './HeaderNaviStyled';

const HeaderNavi = ({
  usergroupInfo,
  accessInfo,
  isAdmin,
}) => {
  let searchNotSupport = false;
  if (!isAdmin && usergroupInfo && usergroupInfo.SearchChannel) {
    const { SearchChannel: searchChannel } = usergroupInfo;
    searchNotSupport = searchChannel.length === 1 && searchChannel[0] === 'None';
  }
  if (!isAdmin && accessInfo && accessInfo.searchAccess) {
    searchNotSupport = false;
  }
  const links = [
    {
      name: getLanguage('lang_monitoring'),
      to: '/live',
      icon: 'wni-monitoring',
      disabled: false,
    },
    {
      name: getLanguage('lang_search'),
      to: '/search',
      icon: 'wni-search',
      disabled: searchNotSupport,
    },
    {
      name: getLanguage('lang_setup'),
      to: '/setup',
      icon: 'wni-setup',
      disabled: false,
    },
  ];

  return (
    <NavigationWrapperStyled>
      {
        links.map(item => (
          <NaviLinkStyled
            to={item.to}
            key={item.to}
            activeClassName="active"
            disabled={item.disabled}
          >
            <NavIconStyled className={`wni ${item.icon}`} />
            <NavSpanStyled>
              {item.name}
            </NavSpanStyled>
          </NaviLinkStyled>
        ))
      }
    </NavigationWrapperStyled>
  );
};

HeaderNavi.propTypes = {
  usergroupInfo: PropTypes.instanceOf(Object).isRequired,
  accessInfo: PropTypes.instanceOf(Object).isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default withContainer(HeaderNaviContainer, HeaderNavi);
