import React from 'react';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { Map, List } from 'immutable';

class LiveVideoTileStateContainer extends React.Component {
//   state = {
//     tileList: [],
//   }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false;
    }

    return true;
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  // onError = event => {
  //   console.log('************* event : ', event);
  //   const {
  //     tileCameraList,
  //   } = this.props;
  //   const newTileList = tileCameraList.toJS();
  //   // console.log('************* new tileList : ', newTileList);

  //   if (event.detail) {
  //     switch (event.detail.error) {
  //       case 0x020A: { // Talk service unavailable
  //         break;
  //       }
  //       default:
  //         break;
  //     }
  //   } else if (event.errorCode) {
  //     switch (event.errorCode) {
  //       case 0x0303: { // Mute service unavailable
  //         const removeIndex = newTileList.findIndex(x => x.uid === event.uid);
  //         console.log('************* removeIndex :', removeIndex);
  //         newTileList.splice(removeIndex, 1);
  //         console.log('************* remove tileList : ', newTileList);
  //       //   this.onUpdate({
  //       //     tileList: newTileList,
  //       //   });
  //         break;
  //       }
  //       default:
  //         break;
  //     }
  //   }
  // }

  // tileStatus = ({ status, uid }) => {
  //   console.log('************* LayoutContainer status : ', status);
  //   console.log('************* LayoutContainer uid : ', uid);
  // }


  render() {
    const { render } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

// LiveVideoTileStateContainer.propTypes = {
//   render: PropTypes.func.isRequired,
//   tileList: PropTypes.arrayOf(PropTypes.any).isRequired,
//   tileCameraList: PropTypes.oneOfType([PropTypes.any]).isRequired,
//   // layoutPageCurrentNumber: PropTypes.number.isRequired,
// };

// export default connect(
//   state => {
//     const { currentNumber: layoutPageCurrentNumber } =
//       state.liveMediaControlModule.get('layoutPageInfo').toJS();
//     return (
//       {
//         tileCameraList: state.cameraInfoModule
//           .get('tileCameraListPage').get(`${layoutPageCurrentNumber}`),
//         layoutPageCurrentNumber,
//       }
//     );
//   },
// )(LiveVideoTileStateContainer);

LiveVideoTileStateContainer.propTypes = {
  render: PropTypes.func.isRequired,
};

export default LiveVideoTileStateContainer;
