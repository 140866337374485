import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLanguage } from 'util/lib';
import {
  TextSearchActions,
  MessageBoxActions,
} from 'store/actionCreators';

class TextSearchContainer extends React.Component {
  state = {
    eventKeywordList: [],
    isCaseSensitive: false,
    isWholeWord: false,
    searchKeywords: null,
    disableKeyword: false,
  };

  componentDidMount() {
    TextSearchActions.requestTextSearch({
      requestType: 'GET_POS_EVENT_CONFIG',
    });
    this.eventKeywordToTree();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const { eventKeyword: prevEventKeyword } = prevProps;
    const { eventKeyword } = this.props;
    if (JSON.stringify(eventKeyword.toJS()) !== JSON.stringify(prevEventKeyword.toJS())) {
      this.eventKeywordToTree();
    }
  }

  onKeywordChange = e => {
    if ((e.target.value.match(/ /g) || []).length > 9) {
      MessageBoxActions.controlMessageBox({
        messageBoxInfo: {
          title: getLanguage('lang_warning'),
          content: getLanguage('lang_nomore_keywords_msg').replace('%1', '10'), // 'no more 10 keyword',
          isOpen: true,
        },
      });
      return;
    }
    TextSearchActions.setKeyword(e.target.value);
  }

  onSelectChanged = list => {
    for (let i = 0; i < list[0].children.length; i += 1) {
      const { checked } = list[0].children[i];
      TextSearchActions.setEventKeyword({
        index: i,
        checked: checked !== 0,
      });
    }
    let isItemChecked = false;
    if (list.length > 0) {
      list.some(item => {
        if (item.checked !== 0) {
          isItemChecked = true;
          return true;
        }
        return false;
      });
    }
    this.setState({
      disableKeyword: isItemChecked,
    });
  }

  onOptionChange = e => {
    const isChecked = !e.currentTarget.childNodes[0].checked;
    if (e.currentTarget.getAttribute('value') === 'case_sensitive') {
      TextSearchActions.setIsCaseSensitive(isChecked ? 'True' : 'False');
    } else {
      TextSearchActions.setIsWholeWord(isChecked ? 'True' : 'False');
    }
  }

  convertEventKeyword2TreeData = list => {
    const result = list.map(item => ({
      id: item.condition,
      name: item.condition,
      checked: item.isChecked === false ? 0 : 1,
      parents: ['all'],
      icon: {
        type: 'circle',
        color: 'red',
      },
    }));
    return result;
  }

  eventKeywordToTree = () => {
    const { eventKeyword } = this.props;
    this.setState({
      eventKeywordList: [
        {
          id: 'all',
          name: getLanguage('lang_all'),
          checked: 0,
          expanded: true,
          children: this.convertEventKeyword2TreeData(eventKeyword.toJS()),
        },
      ],
    });
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

TextSearchContainer.propTypes = {
  render: PropTypes.func.isRequired,
  eventKeyword: PropTypes.oneOfType([PropTypes.any]).isRequired,
};

export default connect(
  state => ({
    lang: state.langModule.get('lang'),
    isCaseSensitive: state.textSearchModule.get('isCaseSensitive'),
    isWholeWord: state.textSearchModule.get('isWholeWord'),
    overlappedIDList: state.searchTimelineModule.get('overlappedIDList'),
    metaDataState: state.textSearchModule.get('metaDataState'),
    posEventConfig: state.textSearchModule.get('posEventConfig'),
    eventKeyword: state.textSearchModule.get('eventKeyword'),
    isCalendarOpen: state.textSearchModule.get('isCalendarOpen'),
    searchStartDateTimeObjBackup: state.textSearchModule.get('searchStartDateTimeObjBackup'),
    searchEndDateTimeObjBackup: state.textSearchModule.get('searchEndDateTimeObjBackup'),
  }),
)(TextSearchContainer);
