import React from 'react';
import PropTypes from 'prop-types';
import { PTZSequenceActions } from 'store/actionCreators';
import { getLanguage } from 'util/lib';
import {
  Container,
  Body,
  Header,
  Footer,
  TitleSpan,
  NameInput,
  PresetNameWrapper,
  PresetListWrapper,
  PresetSelect,
  CloseButton,
  CancelButton,
  SaveButton,
  PresetLabel,
} from './PresetSettingPopupStyled';

class PresetSettingPopup extends React.Component {
  constructor(props) {
    super(props);
    this.onSelected.bind(this);
    this.onKeyPress.bind(this);
    this.onKeyUp.bind(this);
  }

  makePresetList = () => {
    const { maxPreset, presetList, currentPreset } = this.props;
    const array = [];
    for (let idx = 1; idx <= maxPreset; idx += 1) {
      let found = false;
      presetList.some(item => {
        if (item.no === idx) {
          found = true;
          if (currentPreset === item.no) {
            array.push(<option id={`preset_${idx}`} key={idx} value="default">{`${item.no} : ${item.name}`}</option>);
          } else {
            array.push(<option id={`preset_${idx}`} key={idx} value={idx}>{`${item.no} : ${item.name}`}</option>);
          }
          return true;
        }
        return false;
      });
      if (!found) {
        if (currentPreset === idx) {
          array.push(<option id={`preset_${idx}`} key={idx} value="default">{`${idx} : `}</option>);
        } else {
          array.push(<option id={`preset_${idx}`} key={idx} value={idx}>{`${idx} : `}</option>);
        }
      }
    }
    return array;
  }

  onSelected = selectedItem => {
    PTZSequenceActions.setCurrentPreset(Number(selectedItem.currentTarget.value));
  }

  onKeyUp = event => {
    const inputVal = document.getElementById('preset_name').value;
    const regex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;

    if (regex.test(inputVal)) {
      const ele = document.getElementById('preset_name');
      ele.value = inputVal.replace(regex, '');

      document.getElementById('preset_name').blur();
      document.getElementById('preset_name').focus();

      return false;
    }
    PTZSequenceActions.setCurrentPresetName(event.currentTarget.value);
    return true;
  }

  onKeyPress = event => {
    if (event.which && ((event.which >= 48 && event.which <= 57)
      || (event.which >= 65 && event.which <= 90)
      || (event.which >= 97 && event.which <= 122)
      || event.which === 8)) {
      if ((event.keyCode > 32 && event.keyCode < 48)
        || (event.keyCode > 57 && event.keyCode < 65)
        || (event.keyCode > 90 && event.keyCode < 97)
        || (event.keyCode < 128 && event.keyCode > 122)) { // 특수 문자
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
      PTZSequenceActions.setCurrentPresetName(event.currentTarget.value);
      return true;
    }

    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  render() {
    const {
      presetList,
      onConfirm,
      onCancel,
    } = this.props;
    return (
      <Container>
        <Header>
          <TitleSpan>{getLanguage('lang_set_preset ')}</TitleSpan>
          <CloseButton onClick={onCancel}>
            <i className="wni wni-close" />
          </CloseButton>
        </Header>
        <Body>
          { presetList !== null
            && presetList !== false
            && (
              <PresetListWrapper>
                <PresetLabel>{getLanguage('lang_num')}</PresetLabel>
                <PresetSelect
                  onChange={this.onSelected}
                  defaultValue="default"
                >
                  {this.makePresetList()}
                </PresetSelect>
              </PresetListWrapper>
            )
          }
          <PresetNameWrapper>
            <PresetLabel>{getLanguage('lang_name')}</PresetLabel>
            <NameInput
              id="preset_name"
              onKeyPress={this.onKeyPress}
              onKeyUp={this.onKeyUp}
              type="text"
            />
          </PresetNameWrapper>
        </Body>
        <Footer>
          <SaveButton onClick={onConfirm}>{getLanguage('lang_save')}</SaveButton>
          <CancelButton onClick={onCancel}>{getLanguage('lang_cancel')}</CancelButton>
        </Footer>
      </Container>
    );
  }
}

PresetSettingPopup.propTypes = {
  maxPreset: PropTypes.number,
  currentPreset: PropTypes.number.isRequired,
  presetList: PropTypes.arrayOf(Object),
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

PresetSettingPopup.defaultProps = {
  presetList: [],
  maxPreset: 0,
  onConfirm: null,
  onCancel: null,
};

export default PresetSettingPopup;
