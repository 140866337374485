import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { withContainer } from 'wisenet-ui/util/hoc';
import * as themes from 'wisenet-ui/styles/themes';
import { PreLoaderContainer } from 'containers/pages';
import MainStyled from 'styles/MainStyled';
import { Loading } from 'wisenet-ui/components/molecules';
import { OneButtonMessageBox } from 'wisenet-ui/components/organisms';

class PreLoader extends React.Component {
  componentDidMount() {}

  render() {
    const {
      children,
      theme,
      pagePendding,
      messageBoxInfo,
      onMessageBoxConfirm,
      languageLoaded,
    } = this.props;
    return (
      <ThemeProvider theme={themes[theme]}>
        <React.Fragment>
          {pagePendding && <Loading isPage />}
          <OneButtonMessageBox
            onConfirm={onMessageBoxConfirm}
            title={messageBoxInfo.title}
            content={messageBoxInfo.content}
            isOpen={messageBoxInfo.isOpen}
          />
          { languageLoaded ? (
            children
          ) : (
            <Loading isPage />
          )
          }
          <MainStyled />
        </React.Fragment>
      </ThemeProvider>
    );
  }
}

PreLoader.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string.isRequired,
  pagePendding: PropTypes.bool.isRequired,
  // umpScriptOnload: PropTypes.func.isRequired,
  messageBoxInfo: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    isOpen: PropTypes.bool,
  }).isRequired,
  onMessageBoxConfirm: PropTypes.func.isRequired,
  languageLoaded: PropTypes.bool.isRequired,
};

export default withContainer(PreLoaderContainer, PreLoader);
