import React from 'react';
import PropTypes from 'prop-types';
import { getLanguage } from 'util/lib';
import {
  MenuWrapperStyled,
  ParentWrapperStyled,
  ChildWrapperStyled,
  ChildItemWrapperStyled,
  CheckboxStyled,
  CheckTitleStyled,
} from './SupportDetailMenuStyled';

const SupportDetailMenu = ({
  className,
  data,
}) => (
  <div className={className}>
    {
      data.map(item => (
        <MenuWrapperStyled key={item.id}>
          <ParentWrapperStyled>
            <CheckboxStyled
              checked={item.value}
              disabled
            />
            <CheckTitleStyled>
              {getLanguage(item.title)}
            </CheckTitleStyled>
          </ParentWrapperStyled>
          {
            item.child && (
              <ChildWrapperStyled>
                {
                  item.child.map(childItem => (
                    <ChildItemWrapperStyled key={childItem.id}>
                      <CheckboxStyled
                        checked={childItem.value}
                        disabled
                      />
                      <CheckTitleStyled isChild>
                        {getLanguage(childItem.title)}
                      </CheckTitleStyled>
                    </ChildItemWrapperStyled>
                  ))
                }
              </ChildWrapperStyled>
            )
          }
        </MenuWrapperStyled>
      ))
    }
  </div>
);

SupportDetailMenu.defaultProps = {
  className: null,
  data: [],
};

SupportDetailMenu.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  data: PropTypes.instanceOf(Array),
};

export default SupportDetailMenu;
