import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { TabsContainer } from 'wisenet-ui/containers/organisms';
import {
  TabWrapperStyled,
  ContentWrapperStyled,
} from './TabsStyled';

const renderTabHeadComponent = (
  children,
  handleTabClick,
  selectedTabIndex,
) => (
  children.map((child, index) => (
    React.cloneElement(child, {
      onClick: handleTabClick,
      tabIndex: index,
      isActive: index === selectedTabIndex,
    })
  ))
);

const Tabs = ({
  className,
  children,
  handleTabClick,
  selectedTabIndex,
}) => (
  <div className={className}>
    <TabWrapperStyled className="tab-wrapper">
      {
        renderTabHeadComponent(children, handleTabClick, selectedTabIndex)
      }
    </TabWrapperStyled>
    {
      children.map((child, index) => (
        <ContentWrapperStyled
          className="content-wrapper"
          isSelected={selectedTabIndex === index}
          key={child.key}
        >
          {React.cloneElement(child.props.component, {
            tabIndex: index,
            isActive: index === selectedTabIndex,
          })}
        </ContentWrapperStyled>
      ))
    }
  </div>
);

Tabs.defaultProps = {
  className: null,
  handleTabClick: () => {},
  selectedTabIndex: 0,
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  handleTabClick: PropTypes.func,
  selectedTabIndex: PropTypes.number,
};

export default withContainer(TabsContainer, Tabs);
