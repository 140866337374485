import React from 'react';
import PropTypes from 'prop-types';

class TimePickerContainer extends React.Component {
  componentDidMount() {
    const { actions, dateTimeObj, label } = this.props;
    actions.setTime(Object.assign({ flag: label }, dateTimeObj));
  }

  onTimeChange = time => {
    const { actions } = this.props;
    actions.setTime(time);
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

TimePickerContainer.propTypes = {
  render: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.any),
  dateTimeObj: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.objectOf(PropTypes.any),
};

TimePickerContainer.defaultProps = {
  actions: null,
  label: '',
};

export default TimePickerContainer;
