import {
  take, all, put,
} from 'redux-saga/effects';
import axios from 'axios';
import { GET_PHP_SETUP_PAGE, getPhpSetupPageSuccess, getPhpSetupPageFailure } from 'store/modules/base/phpSetupModule';

const phpSetupURL = '/index.php/setup';

function* asyncPHPSetupPageGetSaga() {
  while (true) {
    const action = yield take(GET_PHP_SETUP_PAGE);
    const { requestPageUrl } = action.payload;

    const requestUrl = `${phpSetupURL}${requestPageUrl || ''}`;
    try {
      const requestIP = axios.defaults.baseURL === '' ? window.location.origin : axios.defaults.baseURL;

      yield put(getPhpSetupPageSuccess({
        phpSetupPage: `${requestIP}${process.env.MOUNT_DIR ? process.env.MOUNT_DIR : ''}${requestUrl}`,
      }));
    } catch (err) {
      yield put(getPhpSetupPageFailure());
    }
  }
}

export default function* rootPHPSetupPageSaga() {
  yield all([
    asyncPHPSetupPageGetSaga(),
  ]);
}
