import React from 'react';
import PropTypes from 'prop-types';
import { TilePTZDirectionControl, TilePTZFunctionBar } from 'wisenet-ui/components/molecules';
import { withContainer } from 'wisenet-ui/util/hoc';
import { TilePTZControlContainer } from 'containers/organisms';
import { TileDigitalZoomControl } from 'components/organisms';
import tilePTZControlType from 'wisenet-ui/util/static/constants/mediaControl/tilePTZControlType';

import {
  TilePTZControlStyled,
} from './TilePTZControlStyled';

class TilePTZControl extends React.PureComponent {
  render() {
    const {
      openPTZFunctionBar,
      onTilePTZControlEvent,
      onTilePTZDirectionMouseDown,
      onTilePTZDirectionMouseUp,
      onTilePTZFunctionEvent,
      ptzInfo,
      ptzMode,
      uid,
      width,
      height,
      setDigitalZoom,
      setOpenPTZFucntionBar,
      mouseIn,
      viewModeType,
      setDigitalZoomInOut,
      digitalZoom,
      eventPos,
      rotate,
      aspectRatio,
    } = this.props;

    return (
      <TilePTZControlStyled
        onMouseDown={onTilePTZControlEvent('down')}
        onMouseMove={onTilePTZControlEvent('move')}
        onMouseUp={onTilePTZControlEvent('up')}
        onWheel={onTilePTZControlEvent('wheel')}
        onMouseEnter={onTilePTZControlEvent('enter')}
        onMouseLeave={onTilePTZControlEvent('leave')}
      >
        {(ptzMode === tilePTZControlType.D_ZOOM) && (
          <TileDigitalZoomControl
            uid={uid}
            width={width}
            height={height}
            setDZoomMode={setDigitalZoom}
            setOpenTileBar={setOpenPTZFucntionBar}
            mouseIn={mouseIn}
            setDigitalZoomInOut={setDigitalZoomInOut}
            rotate={rotate}
            aspectRatio={aspectRatio}
            digitalZoom={digitalZoom}
            eventPos={eventPos}
          />
        )}
        {(mouseIn && ptzMode !== 'dZoom') && (
          <TilePTZDirectionControl
            onMouseDown={onTilePTZDirectionMouseDown}
            onMouseUp={onTilePTZDirectionMouseUp}
            ptzInfo={ptzInfo}
            viewModeType={viewModeType}
          />
        )}
        {openPTZFunctionBar && (
          <TilePTZFunctionBar
            onClick={onTilePTZFunctionEvent}
            ptzInfo={ptzInfo}
            ptzMode={ptzMode}
          />
        )}
      </TilePTZControlStyled>
    );
  }
}

TilePTZControl.defaultProps = {
  eventPos: null,
};

TilePTZControl.propTypes = {
  openPTZFunctionBar: PropTypes.bool.isRequired,
  onTilePTZControlEvent: PropTypes.func.isRequired,
  onTilePTZDirectionMouseDown: PropTypes.func.isRequired,
  onTilePTZDirectionMouseUp: PropTypes.func.isRequired,
  onTilePTZFunctionEvent: PropTypes.func.isRequired,
  ptzInfo: PropTypes.instanceOf(Object).isRequired,
  ptzMode: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  setDigitalZoom: PropTypes.func.isRequired,
  setOpenPTZFucntionBar: PropTypes.func.isRequired,
  mouseIn: PropTypes.bool.isRequired,
  viewModeType: PropTypes.string.isRequired,
  setDigitalZoomInOut: PropTypes.func.isRequired,
  rotate: PropTypes.number.isRequired,
  aspectRatio: PropTypes.bool.isRequired,
  digitalZoom: PropTypes.number.isRequired,
  eventPos: PropTypes.instanceOf(Object),
};

export default withContainer(TilePTZControlContainer, TilePTZControl);
