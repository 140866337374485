import styled, { withTheme } from 'styled-components';

const InputStyled = styled.input`
  outline: none;
  padding: 10px;
  border: 1px solid ${props => props.theme.line.color2};
  color: ${props => props.theme.text.color1};
  background-color: ${props => props.theme.background.color1};

  :focus,
  :hover {
    border: 1px solid ${props => props.theme.background.color6};
  }

  &.disabled {
    pointer-events: none;
    border: 1px solid ${props => props.theme.line.color1};
    color: ${props => props.theme.text.color3};
    background-color: ${props => props.theme.background.color2};
  }
`;

export default withTheme(InputStyled);
