import styled, { css } from 'styled-components';
import { ListTabs, MenuTabs } from 'components/molecules';

export const HeaderWrapperStyled = styled.div`
  width: 100%;
  border-right: 1px solid ${props => props.theme.line.color2};
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.isLast && css`
    border-right: none;
  `}
`;

export const IconStyled = styled.i`
  font-size: 20px;
`;

export const TitleStyled = styled.span`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  margin-left: 4px;
`;

export const CustomListTabs = styled(ListTabs)`
  .tab-wrapper {
    height: 30px;
  }
  .content-wrapper {
    padding: 20px;
  }
`;

export const PTZIconStyled = styled.i`
  font-size: 24px;
`;

export const StatusTabs = styled(MenuTabs)`
  .tab-wrapper {
    height: 40px;
    .tab {
      width: 140px;
      font-size: 14px;
    }
  }
  .content-wrapper {
    padding-top: 27px;
  }
`;
