import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class RecordStatusContainer extends React.Component {
  state = {
    recordStatusData: {},
    toggleValue: 0,
  };

  componentDidMount() {
    const {
      recordStatus,
    } = this.props;
    const {
      recordStatusData,
    } = this.state;
    if (
      recordStatusData.length === 0
      || JSON.stringify(recordStatusData) !== JSON.stringify(recordStatus)
    ) {
      this.setState({
        recordStatusData: recordStatus,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)
    ) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const {
      recordStatus,
      maxRecordStatus,
    } = this.props;
    const {
      recordStatusData,
      toggleValue,
    } = this.state;
    if (toggleValue === 0) {
      if (
        Object.keys(recordStatusData).length === 0
        || JSON.stringify(prevProps.recordStatus)
          !== JSON.stringify(recordStatus)
      ) {
        this.onUpdate({
          recordStatusData: recordStatus,
        });
      }
    } else if (toggleValue === 1) {
      if (
        Object.keys(recordStatusData).length === 0
        || JSON.stringify(prevProps.maxRecordStatus)
          !== JSON.stringify(recordStatus)
      ) {
        this.onUpdate({
          recordStatusData: maxRecordStatus,
        });
      }
    }
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  onChangeToggle = currentValue => {
    // 0 - current, 1 - max
    const { recordStatus, maxRecordStatus } = this.props;
    const newValue = currentValue === 0 ? 1 : 0;
    const newRecordTableData = newValue === 0 ? recordStatus : maxRecordStatus;
    this.setState({
      toggleValue: newValue,
      recordStatusData: newRecordTableData,
    });
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

RecordStatusContainer.defaultProps = { };

RecordStatusContainer.propTypes = {
  render: PropTypes.func.isRequired,
  recordStatus: PropTypes.instanceOf(Object).isRequired,
  maxRecordStatus: PropTypes.instanceOf(Object).isRequired,
};

export default connect(
  state => ({
    recordStatus: state.statusPopupModule.get('recordStatus').toJS(),
    maxRecordStatus: state.statusPopupModule.get('maxRecordStatus').toJS(),
  }),
)(RecordStatusContainer);
