import {
  createAction,
  handleActions,
} from 'redux-actions';
import { Map } from 'immutable';

export const SET_CURRENT_LANGUAGE = 'lang/SET_CURRENT_LANGUAGE';
export const SET_CURRENT_LANGUAGE_SUCCESS = 'lang/SET_CURRENT_LANGUAGE_SUCCESS';
export const SET_CURRENT_LANGUAGE_FAILURE = 'lang/SET_CURRENT_LANGUAGE_FAILURE';
export const setCurrentLanguage = createAction(SET_CURRENT_LANGUAGE);
export const setCurrentLanguageSuccess = createAction(SET_CURRENT_LANGUAGE_SUCCESS);
export const setCurrentLanguageFailure = createAction(SET_CURRENT_LANGUAGE_FAILURE);

export const CHANGE_LANGUAGE = 'lang/CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_SUCCESS = 'lang/CHANGE_LANGUAGE_SUCCESS';
export const CHANGE_LANGUAGE_FAILURE = 'lang/CHANGE_LANGUAGE_FAILURE';
export const changeLanguage = createAction(CHANGE_LANGUAGE);
export const changeLanguageSuccess = createAction(CHANGE_LANGUAGE_SUCCESS);
export const changeLanguageFailure = createAction(CHANGE_LANGUAGE_FAILURE);

export const GET_CURRENT_LANGUAGE_BEFORE_LOGIN = 'lang/GET_CURRENT_LANGUAGE_BEFORE_LOGIN';
export const getCurrentLanguageBeforeLogin = createAction(GET_CURRENT_LANGUAGE_BEFORE_LOGIN);

const initialState = Map({
  currentLanguage: {
    name: 'english',
    value: 'English',
  },
  isFirst: true,
  languageLoaded: false,
});

// reducer
export default handleActions({
  [SET_CURRENT_LANGUAGE_SUCCESS]: (state, { payload: { currentLanguage, languageLoaded } }) => {
    console.log('SET_CURRENT_LANGUAGE_SUCCESS');
    return state
      .set('currentLanguage', currentLanguage)
      .set('languageLoaded', languageLoaded)
      .set('isFirst', false);
  },
  [SET_CURRENT_LANGUAGE_FAILURE]: state => {
    console.log('SET_CURRENT_LANGUAGE_FAILURE');
    return state.set('currentLanguage', initialState.getIn('currentLanguage'));
  },
  [CHANGE_LANGUAGE_SUCCESS]: (state, { payload: { currentLanguage } }) => {
    console.log('CHANGE_LANGUAGE_SUCCESS');
    // const data = action.payload.lang;
    return state
      .set('currentLanguage', currentLanguage);
  },
  [CHANGE_LANGUAGE_FAILURE]: state => {
    console.log('CHANGE_LANGUAGE_FAILURE');
    return state.set('currentLanguage', initialState.getIn('currentLanguage'));
  },
}, initialState);
