import styled from 'styled-components';

export const ManagerWrapperStyled = styled.div`
  display: inline-block;
`;

export const PopoverWrapperStyled = styled.div`
  position: absolute;
  border: 1px solid ${props => props.theme.line.color5};
  background-color: ${props => props.theme.background.color1};
  z-index: 999;
`;
