import { createGlobalStyle, withTheme } from 'styled-components';

const MainStyled = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-family: 'NotoSans', sans-serif;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
    background-color: ${props => props.theme.background.color1};
    color: ${props => props.theme.text.color1};
    min-width: 1280px;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    text-decoration: none;
  }
  .wni {
    vertical-align:middle;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid;
    border-radius: 20px;
    color: ${props => props.theme.background.color1};
    background-color: ${props => props.theme.line.color4};
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    border: 0px solid;
    background-color: ${props => props.theme.text.color3};
  }

  &::-webkit-scrollbar-thumb:active {
    border: 0px solid;
    background-color: ${props => props.theme.text.color6};
  }
`;

export default withTheme(MainStyled);
