import React from 'react';
import { IvaPageContainer } from 'containers/pages';

const render = () => (
  <div>IvaPage</div>
);

const IvaPage = () => (
  <IvaPageContainer render={render} />
);

export default IvaPage;
