export default {
  line: {
    color1: '#e9e9e9',
    color2: '#d2d2d2',
    color3: '#b2b2b2',
    color4: '#a5a5a5',
    color5: '#797979',
    color6: '#000000',
    color7: '#d2d2d2',
  },
  text: {
    color1: '#000000',
    color2: '#4c4c4c',
    color3: '#797979',
    color4: '#f37321',
    color5: '#4c4c4c',
    color6: '#4c4c4c',
    color7: '#ca030a',
    color8: '#ffffff',
    color9: '#5e5e5e',
    color10: '#4c4c4c',
  },
  background: {
    color1: '#ffffff',
    color2: '#f2f2f2',
    color3: '#e9e9e9',
    color4: '#b2b2b2',
    color5: '#fde3d3',
    color6: '#f8ab7a',
    color7: '#d2d2d2',
    color8: '#ffffff',
    color9: '#e9e9e9',
    color10: '#fef1e8',
    color11: '#797979',
  },
};
