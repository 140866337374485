import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import {
  ExportStyeld,
} from './CsvExportStyled';

const CsvExport = ({
  fileName,
  data,
  headers,
  children,
  disabled,
}) => (
  <ExportStyeld disabled={disabled}>
    {disabled
      ? children
      : <CSVLink data={data} headers={headers} filename={fileName} uFEFF>{children}</CSVLink>}
  </ExportStyeld>
);

CsvExport.propTypes = {
  fileName: PropTypes.string,
  disabled: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  headers: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  children: PropTypes.oneOfType([PropTypes.any]),
};

CsvExport.defaultProps = {
  fileName: 'export.csv',
  disabled: true,
  data: [],
  headers: [],
  children: '',
};

export default CsvExport;
