import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class LiveStatusContainer extends React.Component {
  state = {
    liveStatusData: [],
  };

  componentDidMount() {
    const {
      liveStatus,
    } = this.props;
    const {
      liveStatusData,
    } = this.state;
    if (
      liveStatusData.length === 0
      || JSON.stringify(liveStatusData) !== JSON.stringify(liveStatus)
    ) {
      this.setState({
        liveStatusData: liveStatus,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)
    ) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const {
      liveStatus,
    } = this.props;
    const {
      liveStatusData,
    } = this.state;
    if (
      liveStatusData.length === 0
      || JSON.stringify(prevProps.liveStatus) !== JSON.stringify(liveStatus)
    ) {
      this.onUpdate({
        liveStatusData: liveStatus,
      });
    }
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

// LiveStatusContainer.defaultProps = { };

LiveStatusContainer.propTypes = {
  render: PropTypes.func.isRequired,
  liveStatus: PropTypes.instanceOf(Array).isRequired,
};

export default connect(
  state => ({
    liveStatus: state.statusPopupModule.get('liveStatus').toJS(),
  }),
)(LiveStatusContainer);
