import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class UserAuthContainer extends React.Component {
  state = {
    detailPopupVisible: false,
  }

  componentDidMount() { }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
    && JSON.stringify(nextState) === JSON.stringify(this.state)
    ) {
      return false;
    }
    return true;
  }

  onChangeDetailPopupVisible = () => {
    const { detailPopupVisible } = this.state;
    this.setState({
      detailPopupVisible: !detailPopupVisible,
    });
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

UserAuthContainer.defaultProps = {
};

UserAuthContainer.propTypes = {
  render: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    userAuthInfo: state.systemInfomationModule.get('usergroupInfo').toJS(),
    maxChannel: state.deviceInfoModule.get('maxChannel'),
  }),
)(UserAuthContainer);
