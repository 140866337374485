import React from 'react';
import PropTypes from 'prop-types';
import { PresetSettingPopup, PresetGuidePopup } from 'components/organisms';
import { PTZPresetContainer } from 'containers/organisms';
import { Table } from 'wisenet-ui/components/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { getLanguage } from 'util/lib';
import {
  Container,
  TableWrapper,
  ButtonWrapper,
  PresetDisableMask,
  StyledButton,
  StyledPopup,
} from './PTZPresetStyled';

const PTZPreset = ({
  openSetPresetPopup,
  showGuidePopup,
  deletePreset,
  onChangeData,
  maxPreset,
  showSettingPopup,
  onSetPresetPopupCancel,
  onSetPresetPopupConfirm,
  onGuidePopupCancel,
  onGuidePopupConfirm,
  currentPresetList,
  guideData,
  currentPreset,
}) => {
  const presetHeader = [
    {
      Header: getLanguage('lang_number'),
      accessor: 'no',
      sortable: true,
    },
    {
      Header: getLanguage('lang_name'),
      accessor: 'name',
      sortable: true,
    },
  ];

  return (
    <Container id="preset_wrapper">
      <StyledPopup
        isOpen={showSettingPopup}
        shouldCloseOnOverlayClick
      >
        <PresetSettingPopup
          presetList={currentPresetList}
          currentPreset={currentPreset}
          maxPreset={maxPreset}
          onConfirm={onSetPresetPopupConfirm}
          onCancel={onSetPresetPopupCancel}
        />
      </StyledPopup>
      <StyledPopup
        isOpen={showGuidePopup}
        shouldCloseOnOverlayClick
      >
        <PresetGuidePopup
          title={guideData.title}
          content={guideData.content}
          onConfirm={onGuidePopupConfirm}
          onCancel={onGuidePopupCancel}
        />
      </StyledPopup>
      <PresetDisableMask id="preset_disable_mask" />
      <ButtonWrapper>
        <StyledButton onClick={openSetPresetPopup}>
          {getLanguage('lang_set')}
        </StyledButton>
        <StyledButton onClick={deletePreset}>
          {getLanguage('lang_delete')}
        </StyledButton>
      </ButtonWrapper>
      <TableWrapper>
        { currentPresetList !== null
        && currentPresetList !== false
        && (
          <Table
            onChangeData={onChangeData}
            scroll
            header={presetHeader}
            data={currentPresetList}
            selectRow
            pageSize={9}
            noDataText="lang_no_preset"
          />
        )
      }
      </TableWrapper>
    </Container>
  );
};

PTZPreset.propTypes = {
  currentPresetList: PropTypes.arrayOf(Object),
  currentPreset: PropTypes.number.isRequired,
  maxPreset: PropTypes.number,
  guideData: PropTypes.instanceOf(Object),
  openSetPresetPopup: PropTypes.func.isRequired,
  showGuidePopup: PropTypes.bool.isRequired,
  deletePreset: PropTypes.func.isRequired,
  onChangeData: PropTypes.func.isRequired,
  showSettingPopup: PropTypes.bool.isRequired,
  onSetPresetPopupCancel: PropTypes.func.isRequired,
  onSetPresetPopupConfirm: PropTypes.func.isRequired,
  onGuidePopupCancel: PropTypes.func.isRequired,
  onGuidePopupConfirm: PropTypes.func.isRequired,
};

PTZPreset.defaultProps = {
  currentPresetList: [],
  maxPreset: 1,
  guideData: {},
};

export default withContainer(PTZPresetContainer, PTZPreset);
