import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { TimelineAlmende } from 'wisenet-ui/components/organisms';
import { TimelineExamplePageContainer } from 'containers/pages';
import { TimelineWrapperStyled, ButtonStyled } from './TimelineExamplePageStyled';

class TimelineExamplePage extends React.Component {
  componentDidMount() {
  }

  render() {
    const {
      datas,
      currentTime,
      selectDate,
      timeChanged,
      handleClick,
      handleChangedTimeRange,
      useSelectTimeRange,
      canceledTimeRange,
    } = this.props;
    const useMouseoverPopup = true;

    return (
      <TimelineWrapperStyled>
        <ButtonStyled onClick={handleClick} disabled={useSelectTimeRange}>구간선택</ButtonStyled>
        <TimelineAlmende
          datas={datas}
          currentTime={currentTime}
          selectDate={selectDate}
          timeChanged={timeChanged}
          changedTimeRange={handleChangedTimeRange}
          useMouseoverPopup={useMouseoverPopup}
          useSelectTimeRange={useSelectTimeRange}
          canceledTimeRange={canceledTimeRange}
        />
      </TimelineWrapperStyled>
    );
  }
}

TimelineExamplePage.propTypes = {
  datas: PropTypes.arrayOf(PropTypes.object),
  selectDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.number,
  ]),
  currentTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.number,
  ]),
  timeChanged: PropTypes.func,
  handleClick: PropTypes.func,
  handleChangedTimeRange: PropTypes.func,
  canceledTimeRange: PropTypes.func,
  useSelectTimeRange: PropTypes.bool,
};

TimelineExamplePage.defaultProps = {
  datas: [],
  selectDate: 0,
  currentTime: 0,
  timeChanged: () => {},
  handleClick: () => {},
  handleChangedTimeRange: () => {},
  canceledTimeRange: () => {},
  useSelectTimeRange: false,
};

export default withContainer(TimelineExamplePageContainer, TimelineExamplePage);
