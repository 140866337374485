import {
  convertResponseHeader,
  makeHA1,
  makeDigestResponse,
} from './DigestHeaderMakeHelp';

class Auth {
  userid = null;

  authHeader = null;

  password = null;

  loggedIn = false;

  history = null;

  defaultSet({ userid, authKey, authHeader }) {
    this.userid = userid;
    this.authKey = authKey;
    this.authHeader = authHeader;
    if (this.authKey) {
      this.loggedIn = true;
    }
  }

  init() {
    this.loggedIn = false;
    this.userid = null;
    this.authHeader = null;
    this.password = null;
  }

  makeAuthHeader({ header }) {
    this.authHeader = convertResponseHeader(header);
  }

  getAuthHeader = () => this.authHeader;

  // // login 할 때만 사용함.
  // setUserInfo({ userid, password }) {
  //   this.userid = userid;
  //   this.password = password;
  // }

  makeAuthKey({ userid, password }) {
    this.userid = userid;
    this.authKey = makeHA1(this.userid, this.authHeader.realm, password);
  }

  makeDigestHeader(method, url) {
    return this.authHeader ? makeDigestResponse(this.authHeader,
      this.authKey, this.userid, method, url) : null;
  }

  isAuthenticated() {
    return this.loggedIn;
  }

  loginSuccess() {
    this.loggedIn = true;
  }

  loginFailed() {
    this.init();
  }

  logout() {
    this.init();
    window.location.reload();
  }

  getUserid() {
    return this.userid;
  }

  getAuthKey() {
    return this.authKey;
  }

  setHistory(_history) {
    this.history = _history;
  }

  getHistory() {
    return this.history;
  }
}

export default new Auth();
