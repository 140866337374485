import styled, { withTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { Span } from 'wisenet-ui/components/atoms';

export const Container = styled.div`
  min-height: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: 'none';
`;

export const DisableMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: block;
  background-color: rgb(204, 204, 204);
  opacity: 0.3;
`;

export const ZoomFocusWrapper = styled.div`
  flex-basis: 90px;
  display: flex;
`;

export const TabWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const TabIconStyled = styled.i`
  font-size: 24px;
`;

export const AuxWrapper = styled.div`
  display: flex;
  margin: 5px;
`;

export const TooltipSpan = withTheme(styled(Span)`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  margin: 0px;
  padding: 0px
`);

export const CustomReactTooltip = withTheme(styled(ReactTooltip)`
  background-color: #555 !important;
  padding: 5px !important;
  &.place-top {
    &:after {
      border-top: none !important;
    }
  }
  &.place-left {
    &:after {
      border-left: 6px solid #555 !important;
    }
  }
  &.place-right {
    &:after {
      border-right: 6px solid #555 !important;
    }
  }
`);
