import React from 'react';
import PropTypes from 'prop-types';
import { getLanguage } from 'util/lib';
import {
  PopupContentWrapperStyled,
  TopWrapperStyled,
  MiddleWrapperStyled,
  BottomWrapperStyled,
  TitleStyled,
  Icontyled,
} from './PopupContentStyled';

const PopupContent = ({
  className,
  children,
  title,
  hasTopCloseButton,
  onClosePopup,
  bottomButtons,
}) => (
  <PopupContentWrapperStyled className={className}>
    {
      (title || hasTopCloseButton) && (
        <TopWrapperStyled>
          <TitleStyled>
            {getLanguage(title)}
          </TitleStyled>
          {hasTopCloseButton && (<Icontyled className="wni wni-close" onClick={onClosePopup} />)}
        </TopWrapperStyled>
      )
    }
    <MiddleWrapperStyled>
      {children}
    </MiddleWrapperStyled>
    {
      (bottomButtons && bottomButtons.length !== 0) && (
        <BottomWrapperStyled>
          {
            bottomButtons.map(button => button)
          }
        </BottomWrapperStyled>
      )
    }
  </PopupContentWrapperStyled>
);

PopupContent.defaultProps = {
  className: null,
  title: '',
  hasTopCloseButton: false,
  onClosePopup: () => {},
  bottomButtons: [],
};

PopupContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  hasTopCloseButton: PropTypes.bool,
  onClosePopup: PropTypes.func,
  bottomButtons: PropTypes.instanceOf(Array),
};

export default PopupContent;
