import styled, { withTheme } from 'styled-components';
import {
  Button,
  Span,
} from 'wisenet-ui/components/atoms';

export const Container = styled.div`
  width: 292px;
  height: 207px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.line.color4};
  background-color: ${props => props.theme.background.color1};
`;

export const Header = withTheme(styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 44px;
  border-bottom: 1px solid ${props => props.theme.line.color4};
`);

export const Body = withTheme(styled.div`
  width: 292px;
  height: 101px;
  border-bottom: 1px solid ${props => props.theme.line.color4};
  display: flex;
  justify-content: center;
  align-items: center;
`);

export const Footer = withTheme(styled.div`
  height: 61px;
  width: 100%;
  display: block;
  display: flex;
  flex-direction: row;
`);

export const TitleSpan = withTheme(styled(Span)`
  width: 165px;
  height: 19px;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.color2};
  margin-left: 16px;
  margin-top: 13px;
  margin-bottom: 8px;
`);

export const ContentSpan = withTheme(styled(Span)`
  width: auto;
  height: auto;
  position: absolute;
  margin: auto;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  padding: 0 20px;
  color: ${props => props.theme.text.color2};
`);

export const CloseButton = styled(Button)`
  width: 20px;
  height: 20px;
  object-fit: contain;
  padding: 0px;
  margin-top: 13px;
  margin-bottom: 8px;
  margin-left: 75px;
`;

export const CancelButton = styled(Button)`
  width: 100px;
  height: 28px;
  object-fit: contain;
  margin-left: 4px;
  margin-top: 16px;
  padding-top: 2px;
`;

export const SaveButton = styled(Button)`
  width: 100px;
  height: 28px;
  object-fit: contain;
  margin-left: 44px;
  margin-top: 16px;
  padding-top: 2px;
`;

export const PresetNameWrapper = styled.div`
  display: grid;
  grid-column-gap: 35px;
  grid-template-columns: 50px 150px;
  margin-left: 28px;
  margin-top: 3px;
  padding: 0px;
  height: 28px;
`;
