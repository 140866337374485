import {
  createAction,
  handleActions,
} from 'redux-actions';
import { Map } from 'immutable';

export const GET_DEVICE_INFO = 'deviceInfo/GET_DEVICE_INFO';
export const GET_DEVICE_INFO_SUCCESS = 'deviceInfo/GET_DEVICE_INFO_SUCCESS';
export const GET_DEVICE_INFO_FAILURE = 'deviceInfo/GET_DEVICE_INFO_FAILURE';
export const GET_DATE_INFO = 'deviceInfo/GET_DATE_INFO';
export const GET_DATE_INFO_SUCCESS = 'deviceInfo/GET_DATE_INFO_SUCCESS';
export const GET_DATE_INFO_FAILURE = 'deviceInfo/GET_DATE_INFO_FAILURE';
export const GET_MAXCHANNEL_INFO = 'deviceInfo/GET_DATE_INFO';
export const GET_MAXCHANNEL_SUCCESS = 'deviceInfo/GET_MAXCHANNEL_SUCCESS';
export const GET_MAXCHANNEL_FAILURE = 'deviceInfo/GET_MAXCHANNEL_FAILURE';
export const SET_DATE_INFO = 'deviceInfo/SET_DATE_INFO';

export const getdeviceInfo = createAction(GET_DEVICE_INFO);
export const getdeviceInfoSuccess = createAction(GET_DEVICE_INFO_SUCCESS);
export const getdeviceInfoFailure = createAction(GET_DEVICE_INFO_FAILURE);
export const getDateInfo = createAction(GET_DATE_INFO);
export const getDateInfoSuccess = createAction(GET_DATE_INFO_SUCCESS);
export const getDateInfoFailure = createAction(GET_DATE_INFO_FAILURE);
export const getMaxChannelInfo = createAction(GET_MAXCHANNEL_INFO);
export const getMaxChannelSuccess = createAction(GET_MAXCHANNEL_SUCCESS);
export const getMaxChannelFailure = createAction(GET_MAXCHANNEL_FAILURE);
export const setDateInfo = createAction(SET_DATE_INFO);

const initialState = Map({
  deviceInfo: Map({}),
  dateInfo: Map({}),
  timeZone: 0,
  dstInfo: Map({}),
  maxChannel: 1,
});

// reducer
export default handleActions({
  [GET_DEVICE_INFO_SUCCESS]: (state, action) => {
    const { deviceInfo } = action.payload;
    return state.set('deviceInfo', Map(deviceInfo));
  },
  [GET_DEVICE_INFO_FAILURE]: () => {
    console.log('[GET_DEVICE_INFO_FAILURE]');
  },
  [SET_DATE_INFO]: (state, action) => {
    const {
      dateInfo,
      dstInfo,
      timeZone,
    } = action.payload;
    return state.set('dateInfo', Map(dateInfo))
      .set('timeZone', timeZone)
      .set('dstInfo', Map(dstInfo));
  },
  [GET_DATE_INFO_FAILURE]: () => {
    console.log('[GET_DATE_INFO_FAILURE]');
  },
  [GET_MAXCHANNEL_SUCCESS]: (state, action) => {
    const {
      maxChannel,
    } = action.payload;
    return state.set('maxChannel', maxChannel);
  },
  [GET_MAXCHANNEL_FAILURE]: () => {
    console.log('[GET_DATE_INFO_FAILURE]');
  },
}, initialState);
