import styled, { withTheme } from 'styled-components';
import { IconButton } from 'wisenet-ui/components/atoms';

export const TilePTZDirectionWrapperStyled = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 150px;
  height: 150px;
  border-radius: 50%;
`;

export const ArrowIconStyled = withTheme(styled(IconButton)`
  padding: 0;
  top: calc(50% - 15px);
  position: absolute;
  left: calc(50% - 15.5px);
  transform: rotate(${props => `${props.deg ? props.deg : 0}deg`}) translateY(-64px);
  font-size: 32px;
  color: #fff;
  text-shadow: -1px -1px 2px #000, 1px -1px 2px #000, -1px 1px 2px #000, 1px 1px 2px #000;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.4);
  }
`);

export const TilePTZPointerStyled = styled.div`
  font-size: 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const IconStyled = withTheme(styled.i`
  color: #fff;
  text-shadow: -1px -1px 2px #000, 1px -1px 2px #000, -1px 1px 2px #000, 1px 1px 2px #000;
`);
