import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { NewList } from 'wisenet-ui/components/organisms';
import { EventExportPopup } from 'components/organisms';
import { EventResultContainer } from 'containers/organisms';
import { List } from 'immutable';
import { getLanguage } from 'util/lib';
import RecordStatusType from 'wisenet-ui/util/static/constants/mediaControl/recordStatusType';
import {
  EventResultListContainer,
  EventResultTopAreaStyled,
  EventResultMiddleAreaStyled,
  EventResultBottomStyled,
  ButtonStyled,
  SortButtonStyled,
  ListCounter,
  DummyDiv,
  ResultListAccordionStyled,
  HeadTitleStyled,
  CustomIconButtonStyled,
  StyledPopup,
  CustomReactTooltip,
  TooltipSpan,
} from './EventResultStyled';

const EventResult = ({
  results,
  onSelectEvent,
  descending,
  onClickViewMore,
  onClickViewAll,
  currentItemIndex,
  isDescending,
  currentTime,
  accordionState,
  onExportCancel,
  showExportPopup,
  onClickExportBtn,
}) => {
  const items = (results.length !== 0)
    ? results.map(item => {
      const comparisonCurrentTimeStart = item.StartDst
        ? new Date(currentTime).getTime() + (60 * 60 * 1000)
        : new Date(currentTime).getTime();
      const comparisonCurrentTimeEnd = item.EndDst
        ? new Date(currentTime).getTime() + (60 * 60 * 1000)
        : new Date(currentTime).getTime();
      const focused = new Date(item.oriStartTime).getTime() < comparisonCurrentTimeStart
        && new Date(item.oriEndTime).getTime() > comparisonCurrentTimeEnd;
      return {
        text: '',
        id: item.Index.toString(),
        data: {
          ...item,
          Type: getLanguage(RecordStatusType.getLanguageKey(item.Type)),
        },
        focused,
        dst: item.dst,
      };
    }).slice(0, currentItemIndex)
    : null;


  if (items && items.length > 0 && isDescending) {
    items.reverse();
  }

  const menuItems = [
    {
      id: 'textList',
      headerLeft: <HeadTitleStyled>{getLanguage('lang_event_list')}</HeadTitleStyled>,
      headerIcons: [
        <div key="textList-export-icon">
          <CustomIconButtonStyled
            data-tip="export"
            data-for="export"
            key="export"
            className="wni wni-export"
            onClick={onClickExportBtn}
          />
          <CustomReactTooltip
            id="export"
            type="light"
            place="top"
            effect="float"
            delayShow={500}
            getContent={() => (
              <TooltipSpan>{getLanguage('lang_export')}</TooltipSpan>
            )}
          />
        </div>,
      ],
      content: (
        <EventResultListContainer>
          <StyledPopup
            isOpen={showExportPopup}
            shouldCloseOnOverlayClick
          >
            <EventExportPopup
              onCancel={onExportCancel}
            />
          </StyledPopup>
          <EventResultTopAreaStyled>
            <SortButtonStyled
              onClick={descending}
              disabled={!(items && items.length)}
              className="wni wni-sorting"
              isDescending={isDescending}
            />
          </EventResultTopAreaStyled>
          <EventResultMiddleAreaStyled>
            {(items && items.length !== 0)
              && (
                <NewList
                  listData={List(items)}
                  // handleClick={onSelectEvent}
                  useExportOneClickListItem
                  exportClickListItem={onSelectEvent}
                />
              )
            }
          </EventResultMiddleAreaStyled>
          <EventResultBottomStyled>
            <ButtonStyled
              onClick={onClickViewMore}
              disabled={items && items.length >= results.length}
            >
              {getLanguage('lang_more')}
            </ButtonStyled>
            <ButtonStyled
              onClick={onClickViewAll}
              isAll
              disabled={items && items.length >= results.length}
            >
              {getLanguage('lang_view_all')}
            </ButtonStyled>
            <DummyDiv />
            <ListCounter>
              {(results.length !== 0 && items) && `
                ${items.length} / ${results.length}
              `}
            </ListCounter>
          </EventResultBottomStyled>
        </EventResultListContainer>
      ),
      isOpen: accordionState.eventList,
      contentScroll: true,
      disableFolding: true,
    },
  ];

  return (
    <ResultListAccordionStyled
      items={menuItems}
    />
  );
};

EventResult.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({
    Type: PropTypes.string,
    StartTime: PropTypes.string,
    EndTime: PropTypes.string,
  })),
  onSelectEvent: PropTypes.func.isRequired,
  descending: PropTypes.func,
  onClickViewMore: PropTypes.func.isRequired,
  onClickViewAll: PropTypes.func.isRequired,
  currentItemIndex: PropTypes.number,
  isDescending: PropTypes.bool.isRequired,
  currentTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.number,
  ]).isRequired,
  accordionState: PropTypes.instanceOf(Object).isRequired,
  showExportPopup: PropTypes.bool.isRequired,
  onExportCancel: PropTypes.func.isRequired,
  onClickExportBtn: PropTypes.func.isRequired,
};

EventResult.defaultProps = {
  results: [],
  descending: null,
  currentItemIndex: 10,
};

export default withContainer(EventResultContainer, EventResult);
