import React from 'react';
import PropTypes from 'prop-types';
import { BasicDatePicker } from 'wisenet-ui/components/organisms';
import { TargetDevice } from 'components/organisms';
import { EventSearchTabContainer } from 'containers/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { getLanguage } from 'util/lib';
import {
  AccordionWrapperStyled,
  DeviceAccordionStyled,
  DateAccordionStyled,
  HeadTitleStyled,
  RndStyled,
} from './EventSearchTabStyled';

const AccordionHeaderHeight = '45px';

const EventSearchTab = ({
  data,
  accordionState,
  onAccordionClick,
  height,
  initHeight,
  onResize,
  setRef,
}) => {
  const cameraList = data.toJS().map(camera => (
    {
      id: camera.channel,
      name: camera.channelName,
      icon: 'tui tui-ch-live-chlist',
      additional: ` (${camera.model})`,
    }
  ));

  const deviceListItems = [
    {
      id: 'deviceList',
      headerLeft: <HeadTitleStyled>{getLanguage('lang_target_device')}</HeadTitleStyled>,
      content: <TargetDevice cameraList={cameraList} />,
      isOpen: accordionState.deviceList,
    },
  ];
  const dateItems = [
    {
      id: 'date',
      headerLeft: <HeadTitleStyled>{getLanguage('lang_date')}</HeadTitleStyled>,
      content: <BasicDatePicker />,
      isOpen: accordionState.date,
    },
  ];

  let dateAccordionHeight = height;
  if (!accordionState.date) {
    dateAccordionHeight = AccordionHeaderHeight;
  }

  return (
    <AccordionWrapperStyled>
      <RndStyled
        enableResizing={{
          bottom: accordionState.date,
          bottomLeft: false,
          bottomRight: false,
          left: false,
          right: false,
          top: false,
          topLeft: false,
          topRight: false,
        }}
        disableDragging
        size={{
          width: '100%',
        }}
        onResize={(e, direction, ref) => onResize(e, ref)}
      >
        <DeviceAccordionStyled
          items={deviceListItems}
          onClick={onAccordionClick}
          allClosed={!accordionState.deviceList}
        />
      </RndStyled>
      <DateAccordionStyled
        items={dateItems}
        onClick={onAccordionClick}
        style={{
          flexBasis: dateAccordionHeight,
        }}
        minHeight={AccordionHeaderHeight}
        maxHeight={initHeight}
        innerRef={ref => setRef(ref)}
      />
    </AccordionWrapperStyled>
  );
};

EventSearchTab.defaultProps = {
  data: [],
};

EventSearchTab.propTypes = {
  data: PropTypes.oneOfType([PropTypes.any]),
  accordionState: PropTypes.instanceOf(Object).isRequired,
  onAccordionClick: PropTypes.func.isRequired,
  height: PropTypes.string.isRequired,
  initHeight: PropTypes.string.isRequired,
  onResize: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
};

export default withContainer(EventSearchTabContainer, EventSearchTab);
