import { createAction, handleActions } from 'redux-actions';
import { Map, List } from 'immutable';

// action
export const ASPECT_RATIO_CONTROL = 'mediaControl/ASPECT_RATIO_CONTROL';
export const ASPECT_RATIO_ALL_CONTROL = 'mediaControl/ASPECT_RATIO_ALL_CONTROL';
export const SET_MIC_UID = 'mediaControl/SET_MIC_UID';
export const SET_RECORD_UID = 'mediaControl/SET_RECORD_UID';
export const SET_SOUND_UID = 'mediaControl/SET_SOUND_UID';

// action create
export const aspectRatioControl = createAction(ASPECT_RATIO_CONTROL);
export const aspectRatioAllControl = createAction(ASPECT_RATIO_ALL_CONTROL);
export const setMicUid = createAction(SET_MIC_UID);
export const setRecordUid = createAction(SET_RECORD_UID);
export const setSoundUid = createAction(SET_SOUND_UID);

const initialState = Map({
  aspectRatioList: List([]),
  isAllAspectRatio: false,
  micUid: null,
  recordUid: null,
  soundUid: null,
});

// reducer
export default handleActions({
  [ASPECT_RATIO_CONTROL]: (state, { payload: { uid } }) => {
    const aspectRatioList = state.get('aspectRatioList');
    const findUid = aspectRatioList.findIndex(aspectUid => aspectUid === uid);
    if (findUid === -1) {
      return state.set('aspectRatioList', aspectRatioList.push(uid));
    }

    return state.set('aspectRatioList', aspectRatioList.delete(findUid))
      .set('isAllAspectRatio', false);
  },
  [ASPECT_RATIO_ALL_CONTROL]: (state, { payload: { tileCameraList } }) => {
    const isAllAspectRatio = state.get('isAllAspectRatio');
    const aspectRatioList = state.get('aspectRatioList');
    if (isAllAspectRatio) {
      return state.set('aspectRatioList', List([]))
        .set('isAllAspectRatio', false);
    }
    const uidList = tileCameraList.map(selectItem => selectItem.get('uid'));
    return state.set('aspectRatioList', aspectRatioList.push(...uidList))
      .set('isAllAspectRatio', true);
  },
  [SET_MIC_UID]: (state, { payload: { micUid } }) => (
    state.set('micUid', micUid)
  ),
  [SET_RECORD_UID]: (state, { payload: { recordUid } }) => (
    state.set('recordUid', recordUid)
  ),
  [SET_SOUND_UID]: (state, { payload: { soundUid } }) => (
    state.set('soundUid', soundUid)
  ),
}, initialState);
