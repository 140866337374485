import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { DateTimeSubmitterContainer } from 'wisenet-ui/containers/organisms';
import { getLanguage } from 'util/lib';
import {
  ButtonContainer,
  StyledButton,
} from './DateTimeSubmitterStyled';

const DateTimeSubmitter = ({
  onApply,
  onCancel,
}) => (
  <ButtonContainer>
    <StyledButton onClick={onApply}>{getLanguage('lang_apply')}</StyledButton>
    <StyledButton onClick={onCancel}>{getLanguage('lang_cancel')}</StyledButton>
  </ButtonContainer>
);

DateTimeSubmitter.propTypes = {
  onCancel: PropTypes.func,
  onApply: PropTypes.func,
  // flag: PropTypes.string,
};

DateTimeSubmitter.defaultProps = {
  onCancel: null,
  onApply: null,
  // flag: '',
};

export default withContainer(DateTimeSubmitterContainer, DateTimeSubmitter);
