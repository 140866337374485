import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'wisenet-ui/components/atoms';
import {
  SearchOptionRightStyled,
  IconButtonStyled,
} from './SearchTimelineOptionRightStyled';

class SearchTimelineOptionRight extends PureComponent {
  render() {
    const {
      zoomDT,
      onClick,
      IconStyled,
    } = this.props;

    const hour = Number(zoomDT.split('.')[0]);
    const min = Number(zoomDT.split('.')[1]) / 100;
    const sHour = hour === 0 ? '' : `${hour}h`;
    const sMin = min === 0 ? '' : ` ${(min * 60).toFixed(0)}min`;
    const time = sHour + sMin;

    return (
      <SearchOptionRightStyled>
        <Label>{time}</Label>
        <IconButtonStyled onClick={onClick('plus')}>
          <IconStyled className="wni wni-ptz-zoomin" />
        </IconButtonStyled>
        <IconButtonStyled onClick={onClick('minus')}>
          <IconStyled className="wni wni-ptz-zoomout" />
        </IconButtonStyled>
      </SearchOptionRightStyled>
    );
  }
}

SearchTimelineOptionRight.propTypes = {
  zoomDT: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  IconStyled: PropTypes.instanceOf(Object).isRequired,
};

export default SearchTimelineOptionRight;
