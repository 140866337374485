import React from 'react';
import PropTypes from 'prop-types';
import { PTZTraceContainer } from 'containers/organisms';
import { Table } from 'wisenet-ui/components/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { getLanguage } from 'util/lib';
import {
  Container,
  TraceDisableMask,
  TableWrapper,
} from './PTZTraceStyled';

const PTZPrsetTrace = ({
  onChangeData,
  currentTraceList,
}) => {
  const traceHeader = [
    {
      Header: getLanguage('lang_number'),
      accessor: 'no',
      sortable: true,
    },
    {
      Header: getLanguage('lang_name'),
      accessor: 'name',
      sortable: true,
    },
  ];

  return (
    <Container id="trace_wrapper">
      <TraceDisableMask id="trace_disable_mask" />
      <TableWrapper>
        { currentTraceList !== null
        && currentTraceList !== false
        && (
          <Table
            onChangeData={onChangeData}
            scroll
            header={traceHeader}
            data={currentTraceList}
            selectRow
            pageSize={9}
          />
        )
      }
      </TableWrapper>
    </Container>
  );
};

PTZPrsetTrace.propTypes = {
  currentTraceList: PropTypes.arrayOf(PropTypes.any),
  onChangeData: PropTypes.func,
};

PTZPrsetTrace.defaultProps = {
  currentTraceList: [],
  onChangeData: null,
};

export default withContainer(PTZTraceContainer, PTZPrsetTrace);
