import React from 'react';
import PropTypes from 'prop-types';

class ToggleContainer extends React.Component {
  toggleEl = null;

  firstTime = true;

  componentDidMount() {}

  setRef = el => {
    this.toggleEl = el;
  }

  onAfterChange = changedValue => {
    // some functions eles
    if (this.firstTime) {
      this.firstTime = false;
      const { onChange } = this.props;
      onChange(changedValue);
      this.toggleEl.blur();
    } else {
      this.firstTime = true;
    }
  };

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

ToggleContainer.defaultProps = {
  onChange: () => {},
};

ToggleContainer.propTypes = {
  render: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

export default ToggleContainer;
