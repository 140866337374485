import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { TextSearchContainerActions } from 'store/actionCreators';

class TextResultTabContainer extends React.Component {
  state ={
    accordionState: {
      textList: true,
      data: true,
    },
    headers: [
      { label: 'Pos Number', key: 'DeviceID' },
      { label: 'Pos Time', key: 'Date' },
      { label: 'Pos Data', key: 'TextData' },
    ],
    exportData: { data: [], fileName: '' },
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const {
      searchResult: prevSearchResult,
    } = prevProps;
    const {
      searchResult,
    } = this.props;
    if (JSON.stringify(prevSearchResult) !== JSON.stringify(searchResult)) {
      const today = new Date().YYYYMMDDHHMMSS();
      this.onUpdate({
        exportData: {
          data: searchResult,
          fileName: `${today.substr(0, 8)}_${today.substring(8, 14)}.csv`,
        },
      });
    }
  }

  onUpdate = data => {
    this.setState({
      ...data,
    });
  }

  onAccordionClick = id => {
    const { accordionState } = this.state;
    this.setState({
      accordionState: {
        ...accordionState,
        [id]: !accordionState[id],
      },
    });
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

TextResultTabContainer.propTypes = {
  render: PropTypes.func.isRequired,
  searchResult: PropTypes.oneOfType([PropTypes.any]).isRequired,
};

export default connect(
  state => ({
    searchResult: state.textSearchModule.get('searchResult'),
  }),
)(TextResultTabContainer);
