import { createAction, handleActions } from 'redux-actions';
import { Map, List } from 'immutable';

// action
export const GET_STATUS = 'statusPopup/GET_STATUS';
export const GET_STATUS_SUCCESS = 'statusPopup/GET_STATUS_SUCCESS';

// action create
export const getStatus = createAction(GET_STATUS);
export const getStatusSuccess = createAction(GET_STATUS_SUCCESS);

const initialState = Map({
  liveStatus: List([]),
  recordStatus: Map([]),
  maxRecordStatus: Map({}),
  networkStatus: List([]),
  getStatusTime: {},
});

// reducer
export default handleActions({
  [GET_STATUS_SUCCESS]: (state, action) => {
    const {
      liveStatus,
      recordStatus,
      maxRecordStatus,
      networkStatus,
      getStatusTime,
    } = action.payload;
    return state.set('liveStatus', List(liveStatus))
      .set('recordStatus', Map(recordStatus))
      .set('maxRecordStatus', Map(maxRecordStatus))
      .set('networkStatus', List(networkStatus))
      .set('getStatusTime', getStatusTime);
  },
}, initialState);
