import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { VideoSetupPageContainer } from 'containers/pages';
import { Sketchbook } from 'wisenet-ui/components/organisms';
// import { UmpPlayer } from 'wisenet-ui/components/molecules';
import { Button } from 'wisenet-ui/components/atoms';
import classnames from 'classnames/bind';
import styles from './VideoSetupPage.scss';


const cx = classnames.bind(styles);

const VideoSetupPage = ({
  objects,
  onButtonClick,
  updateObject,
}) => (
  <React.Fragment>
    <div>VideoSetupPage</div>
    <Sketchbook shape="rect" className={cx('sketchbook')} objects={objects} updateObject={updateObject}>
      {
        <p> No connected camera!! </p>
      }
    </Sketchbook>
    <Button point onClick={onButtonClick} />
  </React.Fragment>
);

VideoSetupPage.defaultProps = {
  objects: {
  },
  updateObject: null,
  onButtonClick: null,
};

VideoSetupPage.propTypes = {
  objects: PropTypes.arrayOf(PropTypes.object),
  updateObject: PropTypes.func,
  onButtonClick: PropTypes.func,
};

export default withContainer(VideoSetupPageContainer, VideoSetupPage);
