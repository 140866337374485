import styled, { css } from 'styled-components';
import { Rnd } from 'react-rnd';

export const ResultWrapperStyled = styled.div`
  height: 100%;
  background-color: ${props => props.theme.background.color2};
  border-top: 1px solid ${props => props.theme.line.color6};
  border-bottom: 1px solid ${props => props.theme.line.color6};
  overflow-y : hidden;
  ${props => props.isVisible && css`
     display: none;
  `};
  position: relative;
`;

export const RndStyled = styled(Rnd)`
  height: 100% !important;
  transform: none !important;
  background-color: inherit;
`;
