import styled, { withTheme, css } from 'styled-components';

const IconButtonStyled = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  color: ${props => props.theme.text.color2};
  border: none;
  outline: none;
  background-color: transparent;
  ${props => !props.mouseInStyleDisabled && css`
    &:hover {
      background-color: ${props.theme.background.color3};
    }

    &:active {
      background-color: ${props.theme.background.color4};
    }
  `}

  &.disabled {
    color: ${props => props.theme.line.color2};
  }

  &.border {
    padding: 3px;
    border: 1px solid ${props => props.theme.line.color4};
    background-color: ${props => props.theme.background.color1};

    i {
      font-size: 16px;
    }
    
    &:focus,
    &:hover {
      border: 1px solid ${props => props.theme.line.color4};
      background-color: ${props => props.theme.line.color2};
      color: ${props => props.theme.text.color2};
    }

    &:active {
      border: 1px solid ${props => props.theme.text.color1};
      background-color: ${props => props.theme.text.color1};
      color: ${props => props.theme.background.color1};
    }
    
    &.disabled {
      border: 1px solid ${props => props.theme.line.color4};
      background-color: ${props => props.theme.line.color2};
    }
  }
`;

export default withTheme(IconButtonStyled);
