import {
  take,
  all,
  put,
} from 'redux-saga/effects';
import { SunapiClient } from 'util/lib';
import * as manualBackupActions from 'store/modules/manualBackup/manualBackupModule';

const CALENDAR_SEARCH_URL = '/stw-cgi/recording.cgi?msubmenu=calendarsearch&action=view';

function* asyncGetCalendarSearch() {
  while (true) {
    const action = yield take(manualBackupActions.FIND_RECORDING_DATE);
    const { month, channelIdList } = action.payload;
    try {
      const param = {
        Month: month,
        ChannelIDList: channelIdList,
      };
      const result = yield SunapiClient.get(CALENDAR_SEARCH_URL, param);
      yield put(manualBackupActions.findRecordingDateSuccess(result.data));
    } catch (error) {
      yield put(manualBackupActions.findRecordingDateFailure());
    }
  }
}

export default function* rootManualBackupSaga() {
  yield all([
    asyncGetCalendarSearch(),
  ]);
}
