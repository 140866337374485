import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class HeaderContainer extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false;
    }
    return true;
  }

  render() {
    const { render } = this.props;
    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

HeaderContainer.propTypes = {
  render: PropTypes.func.isRequired,
};

export default withRouter(connect(
  state => ({
    theme: state.themeModule.get('theme'),
    isFullscreen: state.layoutModule.get('isFullscreen'),
  }),
)(HeaderContainer));
