import Auth from './Auth';
import { setCookie, eraseCookie } from './Cookie';

const logout = () => {
  eraseCookie('AUTH_KEY');
  eraseCookie('ID');
  eraseCookie('AUTH_HEADER_UWA');
  setCookie('AUTH_FAIL', 1);
  window.location.reload();
};

class CustomXHR {
  baseURL = '';

  makeHttpRequest = (method, url, config) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method.toUpperCase(), url, true);
    if (process.env.NODE_ENV === 'production') {
      xhr.setRequestHeader('XClient', 'XMLHttpRequest');
      xhr.withCredentials = true;
    }
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.timeout = 30000;

    Object.keys(config).map(configKey => {
      if (configKey === 'headers') {
        const headers = config[configKey];
        Object.keys(headers).map(headerKey => {
          xhr.setRequestHeader(headerKey, headers[headerKey]);
          return null;
        });
      } else if (configKey === 'onDownloadProgress') {
        xhr.addEventListener('progress', config[configKey]);
      } else if (configKey === 'timeout') {
        xhr.timeout = config[configKey];
      } else {
        xhr.setRequestHeader(configKey, config[configKey]);
      }
      return null;
    });

    return xhr;
  };

  makeRequesetResult = text => {
    try {
      return JSON.parse(text);
    } catch (error) {
      // sunapi cgi에서는 JSON 형태가 아니면 xml 형태이다.
      return text;
    }
  }

  requestHttp = ({
    method, url: _url, config, data, authRetry,
  }) => (
    new Promise((resolve, reject) => {
      const url = this.baseURL + _url;
      const xhr = this.makeHttpRequest(method, url, config);
      const customXHR = this;

      xhr.onreadystatechange = async function handleReadyState() {
        if (this.readyState === 4) {
          if (this.status === 401) {
            if (process.env.NODE_ENV === 'production') {
              // build 상황에서 401이 오는 것은 서버의 계정이 변경되었을 경우
              logout();
              reject(xhr);
            }
            // 개발할 때 401로 인해서 재 요청 로직
            const authData = {
              header: {
                'content-type': this.getResponseHeader('content-type'),
                'www-authenticate': this.getResponseHeader('www-authenticate'),
              },
            };
            Auth.makeAuthHeader(authData);
            if (!Auth.loggedIn) {
              Auth.makeAuthKey();
            }
            const newConfig = config;
            newConfig.headers.Authorization = Auth.makeDigestHeader(method, _url);
            const resData = await customXHR.requestHttp({
              method,
              url: _url,
              config: newConfig,
              data,
              authRetry: true,
            });
            resolve(resData);
          } else if (authRetry) {
            // 개발 중에 다른쪽에서 인증정보 변경된 경우
            if (this.status === 401 || this.status === 490) {
              logout();
              reject(xhr);
            }
          }
          const resData = customXHR.makeRequesetResult(xhr.responseText);

          resolve({
            data: resData,
            status: this.status === 1223 ? 204 : this.status,
          });
        }
      };

      xhr.onerror = function handleError() {
        reject(xhr);
      };

      xhr.ontimeout = function handleTimeout() {
        const history = Auth.getHistory();
        console.log(`[Timeout] ${url}`);
        if (history) {
          console.log('[Timeout] Change location to error page', history);
          history.push('/error');
        } else {
          /* eslint-disable no-restricted-globals */
          self.postMessage({
            type: 'postLoad/CHANGE_LOCATION_TO_ERROR',
            payload: {
              location: '/error',
            },
          });
        }
        reject(xhr);
      };

      xhr.send(data);
    })
  )

  post = async (url, data, config) => {
    const newConfig = {
      ...config,
      'Content-Type': 'application/json;charset=UTF-8',
    };
    return this.requestHttp({
      method: 'POST',
      url,
      config: newConfig,
      data: JSON.stringify(data),
    });
  }

  get = async (url, config) => {
    // console.log('get', url);
    const getResult = await this.requestHttp({
      method: 'GET',
      url,
      config,
    });
    // console.log('getResult', getResult);
    return getResult;
  }
}

export default new CustomXHR();
