import React from 'react';
import PropTypes from 'prop-types';
import { TextSearchContainer } from 'containers/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { Tree } from 'wisenet-ui/components/organisms';
import { getLanguage } from 'util/lib';
import DatePickerWrapper from './DatePickerWrapper';
import {
  Container,
  KeywordInput,
  KeywordLabel,
  OptionLabel,
  OptionCheckbox,
  OptionContainer,
  KeywordTreeWrapper,
  HrStyled,
} from './TextSearchStyled';

class TextSearch extends React.PureComponent {
  render() {
    const {
      onKeywordChange,
      onOptionChange,
      isCaseSensitive,
      isWholeWord,
      eventKeywordList,
      onSelectChanged,
      disableKeyword,
      isCalendarOpen,
      searchStartDateTimeObjBackup,
      searchEndDateTimeObjBackup,
    } = this.props;

    return (
      <Container>
        <DatePickerWrapper
          startDateTime={searchStartDateTimeObjBackup.toJS()}
          endDateTime={searchEndDateTimeObjBackup.toJS()}
          isCalendarOpen={isCalendarOpen}
        />
        <HrStyled />
        <KeywordLabel>{getLanguage('lang_event_keywords')}</KeywordLabel>
        <KeywordTreeWrapper>
          {
            eventKeywordList.length >= 0 && (
            <Tree
              treeData={eventKeywordList}
              onSelectChanged={onSelectChanged}
              showCheckbox
              showIcon
              canDrag={false}
            />
            )
          }
        </KeywordTreeWrapper>
        <HrStyled />
        <KeywordLabel>{getLanguage('lang_type_keyword')}</KeywordLabel>
        <KeywordInput
          type="text"
          onChange={onKeywordChange}
          placeholder={getLanguage('lang_all')}
          disabled={disableKeyword}
        />
        <OptionContainer>
          <OptionCheckbox checked={isCaseSensitive === 'True'} id="case_sensitive" value="case_sensitive" onClick={onOptionChange} />
          <OptionLabel>{getLanguage('lang_match_case_sensitivitiy')}</OptionLabel>
        </OptionContainer>
        <OptionContainer>
          <OptionCheckbox checked={isWholeWord === 'True'} id="whole_word" value="whole_word" onClick={onOptionChange} />
          <OptionLabel>{getLanguage('lang_match_whole_words')}</OptionLabel>
        </OptionContainer>
      </Container>
    );
  }
}

TextSearch.propTypes = {
  onKeywordChange: PropTypes.func,
  onOptionChange: PropTypes.func,
  isCaseSensitive: PropTypes.string,
  isWholeWord: PropTypes.string,
  eventKeywordList: PropTypes.arrayOf(PropTypes.any),
  onSelectChanged: PropTypes.func,
  disableKeyword: PropTypes.bool.isRequired,
  searchStartDateTimeObjBackup: PropTypes.objectOf(PropTypes.any).isRequired,
  searchEndDateTimeObjBackup: PropTypes.objectOf(PropTypes.any).isRequired,
  isCalendarOpen: PropTypes.bool,
};

TextSearch.defaultProps = {
  onKeywordChange: () => {},
  onOptionChange: () => {},
  isCaseSensitive: 'True',
  isWholeWord: 'True',
  eventKeywordList: [],
  onSelectChanged: () => {},
  isCalendarOpen: true,
};

export default withContainer(TextSearchContainer, TextSearch);
