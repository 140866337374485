import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LiveStatusContainer } from 'containers/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { Table } from 'wisenet-ui/components/organisms';
import { getLanguage } from 'util/lib';
import {
  TableWrapperStyled,
  LiveBottomWrapperStyled,
  SetupButtonStyled,
} from './LiveStatusStyled';

const rowHeight = 41;
const defaultTableHeight = 138;
const maxTableHeight = 442;

const LiveStatus = ({
  liveStatusData,
}) => {
  let tableHeight = defaultTableHeight;
  if (liveStatusData.length !== 0) {
    tableHeight = (liveStatusData.length * rowHeight + rowHeight);
    if (tableHeight > maxTableHeight) {
      tableHeight = maxTableHeight;
    }
  }
  const liveStatusHeader = [
    {
      Header: getLanguage('lang_ch'),
      accessor: 'ch',
      sortable: true,
      width: 50,
    },
    {
      Header: getLanguage('lang_model'),
      accessor: 'modelName',
      sortable: true,
    },
    {
      Header: getLanguage('lang_status'),
      accessor: 'status',
      sortable: true,
    },
    {
      Header: getLanguage('lang_location'),
      accessor: 'location',
      sortable: true,
    },
    {
      Header: getLanguage('lang_codec'),
      accessor: 'codec',
      sortable: true,
    },
    {
      Header: getLanguage('lang_resolution'),
      accessor: 'resolution',
      sortable: true,
    },
    {
      Header: getLanguage('lang_frame_rate'),
      accessor: 'framerate',
      sortable: true,
    },
  ];
  const translationData = liveStatusData.map(item => ({
    ...item,
    status: getLanguage(item.status),
    location: item.isAnalog ? `${getLanguage('lang_port')} ${item.location}` : item.location,
  }));
  return (
    <>
      <TableWrapperStyled
        height={tableHeight}
      >
        <Table
          header={liveStatusHeader}
          data={translationData}
          scroll
        />
      </TableWrapperStyled>
      <LiveBottomWrapperStyled>
        <Link
          to={{
            pathname: '/setup',
            requestPageUrl: '#camera/camera_camera_setup',
          }}
        >
          <SetupButtonStyled>{getLanguage('lang_camera_setup')}</SetupButtonStyled>
        </Link>
      </LiveBottomWrapperStyled>
    </>
  );
};

// LiveStatus.defaultProps = { };

LiveStatus.propTypes = {
  liveStatusData: PropTypes.instanceOf(Array).isRequired,
};

export default withContainer(LiveStatusContainer, LiveStatus);
