import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const NavigationWrapperStyled = styled.div`
  display: flex;
  flex: 1;
`;

export const NaviLinkStyled = styled(NavLink)`
  height: 60px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.text.color2};
  padding: 0 12px;
  &:hover {
    background-color: ${props => props.theme.background.color3};
  }
  &:active {
    background-color: ${props => props.theme.background.color4};
  }
  &.active {
    color: ${props => props.theme.text.color4};
  }
  ${props => props.disabled && css`
      color: #d2d2d2;
      cursor: not-allowed;
      pointer-events: none;
  `}
`;

export const NavIconStyled = styled.i`
  font-size: 24px;
  margin-right: 4px;
`;

export const NavSpanStyled = styled.span`
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.4px;
  vertical-align: middle;
`;
