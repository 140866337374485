/* eslint-disable */
import createWorkerMiddleware from 'redux-worker-middleware';
import eventWorker from './EventUpdateWorker';
import EventMonitorDiffWorker from 'worker-loader!../worker/eventStatus/EventMonitorDiffWorker';

const eventMonitorDiffWorker = createWorkerMiddleware(new EventMonitorDiffWorker());

export const workerList = [
  eventMonitorDiffWorker,
];

class WebWorker {
  constructor(worker) {
    const code = worker.toString();
    const blob = new Blob([`(${code})()`]);
    return new Worker(URL.createObjectURL(blob));
  }
}

const EventUpdateWorker = new WebWorker(eventWorker);

export {
  EventUpdateWorker,
};
