import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LiveMediaControlBarContainer } from 'containers/organisms';
import { ManualBackupPopup, StatusPopup } from 'components/organisms';
import { PopupContent } from 'components/molecules';
import { withContainer } from 'wisenet-ui/util/hoc';
import umpPlayMode from 'wisenet-ui/util/static/constants/umpPlayer/umpPlayMode';
import { MediaControlBar } from 'wisenet-ui/components/organisms';
import { getLanguage } from 'util/lib';
import {
  MediaControlBarWrapperStyled,
  StyledPopup,
  StatusPopupStyled,
  PopupButtonStyled,
} from './LiveMediaControlBarStyled';

class LiveMediaControlBar extends PureComponent {
  render() {
    const {
      onClick,
      layoutPageCurrentNumber,
      layoutPageMaxNumber,
      isMaunalRecord,
      onExportCancel,
      showExportPopup,
      showStatusPopup,
      onChangeStatusPopupVisible,
      patternDropDownVisible,
      onChangePatternDropdownVisible,
      haveEmptyPage,
      pattern,
      showRecordIcon,
      showAlarmIcon,
      showExportIcon,
    } = this.props;

    const mediaControlBarOption = {
      layoutRightButtonDisabled: pattern === 'SPECIAL' ? layoutPageCurrentNumber === layoutPageMaxNumber
        : haveEmptyPage && (layoutPageCurrentNumber === layoutPageMaxNumber),
      pattern,
    };

    return (
      <MediaControlBarWrapperStyled>
        <StyledPopup
          isOpen={showExportPopup}
          shouldCloseOnOverlayClick
        >
          <ManualBackupPopup
            flag="live"
            onCancel={onExportCancel}
          />
        </StyledPopup>
        <StatusPopupStyled
          isOpen={showStatusPopup}
        >
          <PopupContent
            title="lang_all_camera_status"
            hasTopCloseButton
            bottomButtons={[
              (
                <PopupButtonStyled key="statusPopupCloseButton" onClick={onChangeStatusPopupVisible}>{getLanguage('lang_ok')}</PopupButtonStyled>
              ),
            ]}
            onClosePopup={onChangeStatusPopupVisible}
          >
            <StatusPopup />
          </PopupContent>
        </StatusPopupStyled>
        <MediaControlBar
          type={umpPlayMode.LIVE}
          onClick={onClick}
          layoutPageInputValue={layoutPageCurrentNumber}
          layoutPageLimitValue={layoutPageMaxNumber}
          isMaunalRecord={isMaunalRecord}
          showRecordIcon={showRecordIcon}
          showAlarmIcon={showAlarmIcon}
          showExportIcon={showExportIcon}
          patternDropDownVisible={patternDropDownVisible}
          onChangePatternDropdownVisible={onChangePatternDropdownVisible}
          {...mediaControlBarOption}
        />
      </MediaControlBarWrapperStyled>
    );
  }
}

LiveMediaControlBar.defaultProps = {
  showRecordIcon: true,
  showAlarmIcon: true,
  showExportIcon: false,
};

LiveMediaControlBar.propTypes = {
  onClick: PropTypes.func.isRequired,
  layoutPageCurrentNumber: PropTypes.number.isRequired,
  layoutPageMaxNumber: PropTypes.number.isRequired,
  isMaunalRecord: PropTypes.bool.isRequired,
  showExportPopup: PropTypes.bool.isRequired,
  onExportCancel: PropTypes.func.isRequired,
  showStatusPopup: PropTypes.bool.isRequired,
  onChangeStatusPopupVisible: PropTypes.func.isRequired,
  patternDropDownVisible: PropTypes.bool.isRequired,
  onChangePatternDropdownVisible: PropTypes.func.isRequired,
  haveEmptyPage: PropTypes.bool.isRequired,
  pattern: PropTypes.string.isRequired,
  showRecordIcon: PropTypes.bool,
  showAlarmIcon: PropTypes.bool,
  showExportIcon: PropTypes.bool,
};

export default withContainer(LiveMediaControlBarContainer, LiveMediaControlBar);
