import React from 'react';
import PropTypes from 'prop-types';

class LiveVideoTileDigitalZoomContainer extends React.Component {
  state = {
    digitalZoom: 1,
    eventPos: null,
  };

  setDigitalZoomInOut = ({ digitalZoom, eventPos }) => {
    this.onUpdate({ digitalZoom, eventPos });
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  render() {
    const { render } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

LiveVideoTileDigitalZoomContainer.propTypes = {
  render: PropTypes.func.isRequired,
};

export default LiveVideoTileDigitalZoomContainer;
