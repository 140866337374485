import React from 'react';
import PropTypes from 'prop-types';
import { getLanguage } from 'util/lib';
import {
  MessageBoxActions,
  LoginActions,
  PostLoadActions,
} from 'store/actionCreators';
import { connect } from 'react-redux';

class AccessChangeContainer extends React.Component {
  changeContent = '';

  componentDidUpdate(prevProps) {
    const { sslInfo, messageBoxInfo, systemEvent } = this.props;
    const {
      sslInfo: prevSslInfo,
      messageBoxInfo: prevMessageBoxInfo,
      systemEvent: prevSystemEvent,
    } = prevProps;

    if ((prevSslInfo.size !== 0) && prevSslInfo.get('Policy') !== sslInfo.get('Policy')) {
      this.openMessageBox('SSL');
    } else if (prevSystemEvent.factoryReset !== systemEvent.factoryReset) {
      if (systemEvent.factoryReset) {
        this.openMessageBox('Factory Reset');
      }
    }

    if (this.changeContent !== '') {
      if (prevMessageBoxInfo.isOpen && (prevMessageBoxInfo.isOpen !== messageBoxInfo.isOpen)) {
        LoginActions.logout({
          destroy: this.checkLoginPage(),
        });
      }
    }
  }

  checkLoginPage = () => {
    let isDestroy = false;
    switch (this.changeContent) {
      case 'Password':
      case 'Admin ID':
        break;
      default:
        isDestroy = true;
        break;
    }
    return isDestroy;
  }

  openMessageBox = content => {
    this.changeContent = content;
    MessageBoxActions.controlMessageBox({
      messageBoxInfo: {
        title: getLanguage('lang_warning'),
        content: getLanguage('lang_mode_setting_changed_msg'),
        isOpen: true,
      },
    });

    PostLoadActions.setEventStatusMonitorWokrer({
      eventMonitorWorker: false,
    });

    // 창이 열린 후에 3초 이후에 닫는 로직 발생
    setTimeout(() => {
      const { messageBoxInfo } = this.props;
      if (messageBoxInfo.isOpen) {
        MessageBoxActions.controlMessageBox({
          messageBoxInfo: {
            title: '',
            content: '',
            isOpen: false,
          },
        });
      }
    }, 3000);
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

AccessChangeContainer.propTypes = {
  render: PropTypes.func.isRequired,
  messageBoxInfo: PropTypes.instanceOf(Object).isRequired,
  sslInfo: PropTypes.instanceOf(Object).isRequired,
  systemEvent: PropTypes.instanceOf(Object).isRequired,
};

export default connect(
  state => ({
    sslInfo: state.systemInfomationModule.get('sslInfo'),
    messageBoxInfo: state.messageBoxModule.get('messageBoxInfo'),
    systemEvent: state.systemInfomationModule.get('systemEvent').toJS(),
  }),
)(AccessChangeContainer);
