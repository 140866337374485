import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLanguage } from 'util/lib';
import {
  TextSearchActions,
  SearchMediaControlActions,
  SearchTimelineActions,
} from 'store/actionCreators';

const KETWORD_MAXLEN = 50;

class TextSearchTabContainer extends React.Component {
  state ={
    accordionState: {
      deviceList: true,
      textSearch: true,
    },
    height: 'auto',
    showGuidePopup: false,
    guideData: {},
  }

  textSearchAccordion = null;

  initHeight = 'auto';

  componentDidMount() {
    this.renewInterval = setInterval(this.TextSearchTokenHeartbeat, 10000);
    // Start 시간, End 시간, case sensitive, whole word 초기화 필요
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    TextSearchActions.setDate({
      flag: 'start',
      year: startDate.getFullYear(),
      month: startDate.getMonth() + 1,
      day: startDate.getDate(),
    });
    TextSearchActions.setTime({
      flag: 'start',
      hour: 0,
      minute: 0,
      second: 0,
    });
    TextSearchActions.applyDateTime('start');

    const endDate = new Date();
    TextSearchActions.setDate({
      flag: 'end',
      year: endDate.getFullYear(),
      month: endDate.getMonth() + 1,
      day: endDate.getDate(),
    });
    TextSearchActions.setTime({
      flag: 'end',
      hour: 23,
      minute: 59,
      second: 59,
    });
    TextSearchActions.applyDateTime('end');

    TextSearchActions.setKeyword('');
    TextSearchActions.setIsCaseSensitive('False');
    TextSearchActions.setIsWholeWord('False');

    const { liveTextEventSelect } = this.props;
    if (liveTextEventSelect && liveTextEventSelect.toJS().deviceID !== '') {
      this.onApply();
      TextSearchActions.setLiveTextEventListSelect(null);
    }
  }

  componentDidUpdate() {
    if (this.textSearchAccordion) {
      if (this.initHeight === 'auto' && this.textSearchAccordion.clientHeight !== 0) {
        this.initHeight = `${this.textSearchAccordion.clientHeight + 1}px`;
        this.onUpdate({
          height: this.initHeight,
        });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.renewInterval);
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  TextSearchTokenHeartbeat = () => {
    const {
      RenewHeartbeat,
      searchToken,
    } = this.props;
    if (RenewHeartbeat && searchToken !== '') {
      console.log('actions renew!');
      // TextSearchActions.requestTextSearch({
      //   requestType: 'CONTROL_METADATA',
      //   Mode: 'Renew',
      // });
      TextSearchActions.sendMetaDataRenew();
    }
  }

  onApply = () => {
    const { searchToken } = this.props;

    if (this.validateDate() && this.validateKeyword()) {
      // 어떤 탭이든 상관없이
      // 1. 영상화면 초기화
      // 2. 결과화면 초기화
      // 3. 영수증 화면 초기화
      SearchTimelineActions.setCurrentChannel(-1);
      SearchMediaControlActions.playControl({
        playMode: 'stop',
      });
      SearchTimelineActions.setSelectEvent({});
      TextSearchActions.controlMetaDataRenewStop();
      if (searchToken !== '') {
        TextSearchActions.requestTextSearch({
          requestType: 'CONTROL_METADATA',
          Mode: 'Cancel',
        });
      }

      TextSearchActions.initOverlappedIdList();

      TextSearchActions.requestTextSearch({
        requestType: 'CONTROL_METADATA',
        Mode: 'OverlappedId',
      });
    }
  }

  validateDate = () => {
    const { searchStartDateObj, searchEndDateObj } = this.props;

    const {
      year: sYear,
      month: sMonth,
      day: sDay,
      hour: sHour,
      minute: sMinute,
      second: sSecond,
    } = searchStartDateObj.toJS();

    const {
      year: eYear,
      month: eMonth,
      day: eDay,
      hour: eHour,
      minute: eMinute,
      second: eSecond,
    } = searchEndDateObj.toJS();

    if (sYear === eYear && sMonth === eMonth && sDay === eDay) {
      const startDate = new Date(sYear, sMonth - 1, sDay, sHour, sMinute, sSecond);
      const endDate = new Date(eYear, eMonth - 1, eDay, eHour, eMinute, eSecond);

      const timeDiff = Math.floor(((endDate.getTime() - startDate.getTime()) / 1000) % 60);

      if (timeDiff < 0) {
        this.setState({
          showGuidePopup: true,
          guideData: {
            id: 0,
            title: getLanguage('lang_error'),
            content: getLanguage('lang_set_end_time_larger_msg'), // '종료 시간을 시작 시간보다 크게 설정하십시오.',
          },
        });
        return false;
      }
    }

    return true;
  }

  validateKeyword = () => {
    const { keyword, eventKeyword } = this.props;

    let keywordString = '';
    if (eventKeyword.length > 0) {
      eventKeyword.map((item, idx) => {
        if (item.isChecked) {
          if (idx === eventKeyword.length - 1) {
            keywordString = `${keywordString}${item.condition}`;
          } else {
            keywordString = `${keywordString}${item.condition} `;
          }
        }
        return keywordString;
      });
    }

    if (keywordString === '') {
      keywordString = keyword;
    }

    if (keywordString.length > KETWORD_MAXLEN) {
      // 키워드 길이는 최대 50
      return false;
    }

    return true;
  }

  setRef = (ref, id) => {
    this[id] = ref;
  }

  onAccordionClick = id => {
    const { accordionState } = this.state;
    this.setState({
      accordionState: {
        ...accordionState,
        [id]: !accordionState[id],
      },
    });
  }

  onResize = (e, ref) => {
    const buttonHeight = '48px';
    this.setState({
      height: `calc(100% - ${ref.style.height} - ${buttonHeight})`,
    });
  }

  onGuidePopupConfirm = () => {
    this.setState({
      showGuidePopup: false,
    });
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

TextSearchTabContainer.defaultProps = {
  liveTextEventSelect: null,
};

TextSearchTabContainer.propTypes = {
  render: PropTypes.func.isRequired,
  searchToken: PropTypes.string.isRequired,
  searchStartDateObj: PropTypes.oneOfType([PropTypes.any]).isRequired,
  searchEndDateObj: PropTypes.oneOfType([PropTypes.any]).isRequired,
  keyword: PropTypes.string.isRequired,
  eventKeyword: PropTypes.oneOfType([PropTypes.any]).isRequired,
  RenewHeartbeat: PropTypes.bool.isRequired,
  liveTextEventSelect: PropTypes.instanceOf(Object),
};

export default connect(
  state => ({
    searchToken: state.textSearchModule.get('searchToken'),
    keyword: state.textSearchModule.get('keyword'),
    eventKeyword: state.textSearchModule.get('eventKeyword'),
    searchStartDateObj: state.textSearchModule.get('searchStartDateObj'),
    searchEndDateObj: state.textSearchModule.get('searchEndDateObj'),
    RenewHeartbeat: state.textSearchModule.get('RenewHeartbeat'),
    liveTextEventSelect: state.textSearchModule.get('liveTextEventSelect'),
  }),
)(TextSearchTabContainer);
