import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'wisenet-ui/components/atoms';
import { CustomTimePicker } from 'wisenet-ui/components/organisms';
import { EventSearchActions } from 'store/actionCreators';
import ClickOutsideWrapper from './ClickOutsideWrapper';
import {
  SearchOptionMiddleStyled,
  IconButtonStyled,
  TimeLableStyled,
  TimePickerWrapper,
} from './SearchTimelineOptionMiddleStyled';

class SearchTimelineOptionMiddle extends Component {
  componentDidMount() {}

  checkDSTRange = time => {
    const {
      dstInfo,
      dstEnableInfo,
      timelineDstRange,
      dstEnable,
    } = this.props;
    if (!dstEnableInfo) return false;
    const dstRangeIn = dstInfo.startDate.valueOf() < time.valueOf()
      && time.valueOf()
      < (dstEnable ? timelineDstRange.startTime.valueOf() : dstInfo.endDate.valueOf());
    return dstRangeIn;
  }

  render() {
    const {
      IconStyled,
      onClick,
      searchDateObj,
      calendarSearchDate,
      currentTime,
      timeLabelCtrl,
      timeZone,
      dstInfo,
      dstEnableInfo,
    } = this.props;

    const {
      checkDSTRange,
    } = this;

    const timeZoneT = timeZone || 0;

    const year = searchDateObj !== null ? searchDateObj.year : new Date().getFullYear();
    const month = searchDateObj !== null ? searchDateObj.month : new Date().getMonth() + 1;
    const day = searchDateObj !== null ? searchDateObj.day : new Date().getDate();

    const convertMonth = month < 10 ? `0${month}` : month;
    const convertDay = day < 10 ? `0${day}` : day;

    const yyyymmdd = `${year}-${convertMonth}-${convertDay}`;

    const convertCurrentTime = currentTime === '0' ? new Date(Number(currentTime)) : new Date(currentTime);
    const changeCurrentTime = convertCurrentTime.setHours(convertCurrentTime.getHours()
      + convertCurrentTime.getTimezoneOffset() / 60 - Number(timeZoneT));
    const changedCurrentTime = new Date(changeCurrentTime);
    // const convertDstEndDate = dstInfo.endDate.setHours(dstInfo.endDate.getHours() - 1);
    const dstRangeIn = dstInfo.startDate.valueOf() < changedCurrentTime.valueOf()
      && changedCurrentTime.valueOf() < dstInfo.endDate.valueOf();
    if (dstRangeIn && dstEnableInfo && checkDSTRange(changedCurrentTime)) {
      changedCurrentTime.setHours(changedCurrentTime.getHours() + 1);
    }
    const hhmmss = currentTime !== '0'
      ? new Date(changedCurrentTime).toTimeString().split(' ')[0]
      : '00:00:00';

    const timeObj = {
      hour: new Date(changedCurrentTime).getHours(),
      minute: new Date(changedCurrentTime).getMinutes(),
      second: new Date(changedCurrentTime).getSeconds(),
    };
    const date = new Date(year, month - 1, day);
    // eslint-disable-next-line max-len
    const calenderIndex = calendarSearchDate.findIndex(calendar => calendar.valueOf() === date.valueOf());
    const firstIconDisabled = calenderIndex === 0 || calenderIndex === -1;
    const laseIconDisabled = calenderIndex === -1
    || calenderIndex === calendarSearchDate.length - 1;

    return (
      <SearchOptionMiddleStyled>
        <IconButtonStyled className={`${firstIconDisabled && 'disabled'}`} onClick={onClick('left')}>
          <IconStyled className="wni wni-arrow-left" />
        </IconButtonStyled>
        <TimeLableStyled>
          <Label>{yyyymmdd}</Label>
        </TimeLableStyled>
        <IconButtonStyled className={`${laseIconDisabled && 'disabled'}`} onClick={onClick('right')}>
          <IconStyled className="wni wni-arrow-right" />
        </IconButtonStyled>
        <TimeLableStyled>
          {timeLabelCtrl
           && (
           <TimePickerWrapper>
             <ClickOutsideWrapper onClick={onClick}>
               <CustomTimePicker
                 actions={EventSearchActions}
                 dateTimeObj={timeObj}
               />
             </ClickOutsideWrapper>
           </TimePickerWrapper>
           )
          }
          {!timeLabelCtrl && <Label onClick={onClick('timeLabel')}>{hhmmss}</Label>}
        </TimeLableStyled>
      </SearchOptionMiddleStyled>
    );
  }
}

SearchTimelineOptionMiddle.defaultProps = {
  searchDateObj: null,
};

SearchTimelineOptionMiddle.propTypes = {
  IconStyled: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  searchDateObj: PropTypes.instanceOf(Object),
  calendarSearchDate: PropTypes.instanceOf(Object).isRequired,
  currentTime: PropTypes.string.isRequired,
  timeLabelCtrl: PropTypes.bool.isRequired,
  timeZone: PropTypes.number.isRequired,
  dstInfo: PropTypes.oneOfType([PropTypes.any]).isRequired,
  dstEnableInfo: PropTypes.bool.isRequired,
  timelineDstRange: PropTypes.instanceOf(Object).isRequired,
  dstEnable: PropTypes.bool.isRequired,
};

export default SearchTimelineOptionMiddle;
