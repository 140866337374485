import styled, { keyframes, withTheme, css } from 'styled-components';
import {
  Span,
} from 'wisenet-ui/components/atoms';

const ListTitleStyled = withTheme(styled.div`
  width: 100%;
  padding-left: 8px;
  display: flex;
  align-items: center;
  &.selected {
    background-color: ${props => props.theme.background.color5};
  }

  &.disableItem {
    color: #d2d2d2;
  }
`);

const ListMoveToTextSearchIconStyled = styled.i`
  float: right;
  margin-right: 5px;
`;

const ListCloseIconStyled = styled.i`
  float: right;
`;

const ListIconStyled = styled(Span)`
  &.focused {
    color:#f37321;
  }

  &.disableItem {
    color: #d2d2d2;
  }
`;

const ListIndexIconStyled = styled(Span)`
  border: solid 1px ${props => props.theme.text.color10};
  width:20px;
  height:20px;
  line-height:18px;
  border-radius:75px;
  text-align:center;
  margin-right:4px;
  font-size:12px;
  color: ${props => props.theme.text.color10};
  
  &.checked {
    background-color: ${props => props.theme.text.color10};
    color: ${props => props.theme.text.color8};
  }

  &.focused {
    background-color:#f37321;
    border: solid 1px #f37321;

    &.disableItem {
      background: #d2d2d2;
    }
  }

  &.disableItem {
    border: solid 1px #d2d2d2;
    color: #d2d2d2;
    /* pointer-events: none; */
  }
`;

const ListTextStyled = styled(Span)`
  &.focused {
    color:#f37321;
  }
`;

const EventResultListItemStyled = styled.div`
  font-size: 14px;
  display: flex;
  &:hover {
    width: 100%;
  }
`;

const EventResultListItemNormalStyled = styled(Span)`
  /* margin: 0px 5px 0px 0px; */
  font-size: 14px;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.color1};
  &.focused {
    color: ${props => props.focusColor};
  }
`;

const EventResultListItemDstStyled = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  ${props => props.dst && css`
    background-color: #22ae14;
  `}
`;

const EventResultListItemIndexStyled = styled(Span)`
  /* margin: 0px 5px 0px 0px; */
  width: 25px;
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.color2};
  &.focused {
    color: ${props => props.focusColor};
  }
`;

const EventResultListItemDatetimeStyled = styled(Span)`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: right;
  color: ${props => props.theme.text.color2};
  &.focused {
    color: ${props => props.focusColor};
  }
`;

const TextResultListItemStyled = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    height: 28px;
    align-items: center;
  /* &:hover {
    width: 100%;
  } */
`;

const TextResultListItemNormalStyled = styled(Span)`
  padding: 0px 5px 0px 0px;
  font-size: 14px;
  flex-grow: 0;
  flex-shrink: 0;
  color: ${props => props.theme.text.color1};
  &.focused {
    color: #f00;
  }
`;

const TextResultListItemIndexStyled = styled(Span)`
  padding: 0px 5px 0px 0px;
  font-size: 14px;
  flex-grow: 0;
  flex-shrink: 0;
  color: ${props => props.theme.text.color2};
  &.focused {
    color: #f00;
  }
`;

const TextResultListItemDatetimeStyled = styled(Span)`
  padding: 0px 5px 0px 0px;
  font-size: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  color: ${props => props.theme.text.color2};
  &.focused {
    color: #f00;
  }
`;

const TextResultListItemKeywordStyled = styled(Span)`
  padding: 0px 5px 0px 0px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  color: ${props => props.theme.text.color2};
  &.focused {
    color: #f00;
  }
`;

const blink = keyframes`
  0% {
    background-color: #f37321;
    opacity: 0.85;
  }
  49% {
    background-color: #f37321;
    opacity: 0.85;
  }
  50% {
    background-color: white;
  }
  100% {
    background-color: white;
  }
`;

const RealTimeEvnetWrapper = styled.div`
  height: 100%;
  ${props => props.useBlinkAnimation && css` 
    animation: ${blink} 0.5s linear; 
    animation-iteration-count: 10; 
  `}
`;

const EventTitleStyled = styled.div`
  font-size: 16px;
`;

const EventSubTitleWrapperStyled = styled.div`
  font-size: 12px;
`;

const EventDeviceNameStyled = styled(Span)`
  /* width: 50%; */
  margin: 0px 20px;
  font-size: 16px;
  color: ${props => props.theme.text.color2};
`;

const EventStartTimeStyled = styled(Span)`
  /* width: 50%; */
  display: inline;
  font-size: 12px;
  color: ${props => props.theme.text.color2};
  position: absolute;
  right: 20px;
`;

const EventChannelIDStyled = withTheme(styled.div`
  line-height: 20px;
  `);

const EventChannelTitleStyled = styled(Span)`
  margin: 0px 40px 0px 0px;
  font-size: 12px;
  color: ${props => props.theme.text.color2};
`;

const EventChannelListStyled = withTheme(styled.span`
  margin: 0px 20px;
  font-size: 12px;
  color: ${props => props.theme.text.color2};
  display: flex;
  Button {
    width : 24px;
    height: 16px;
    border-radius: 8px;
    margin: 0px 2px;
    font-size: 11px;
    padding: 0px 0px;
    
  }
`);

const EventContentsStyled = styled.div`
  width: 100%;
  font-size: 12px;
  white-space: pre;
  height: 100%;
  font-family: GulimChe, sans-serif;
  color: ${props => props.theme.text.color2};
`;

export {
  ListTitleStyled,
  ListMoveToTextSearchIconStyled,
  ListCloseIconStyled,
  ListIconStyled,
  ListIndexIconStyled,
  ListTextStyled,
  EventResultListItemStyled,
  EventResultListItemNormalStyled,
  EventResultListItemDstStyled,
  EventResultListItemIndexStyled,
  EventResultListItemDatetimeStyled,
  TextResultListItemStyled,
  TextResultListItemNormalStyled,
  TextResultListItemIndexStyled,
  TextResultListItemDatetimeStyled,
  TextResultListItemKeywordStyled,
  RealTimeEvnetWrapper,
  EventTitleStyled,
  EventSubTitleWrapperStyled,
  EventDeviceNameStyled,
  EventChannelIDStyled,
  EventChannelTitleStyled,
  EventChannelListStyled,
  EventStartTimeStyled,
  EventContentsStyled,
};
