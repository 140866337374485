import { createAction, handleActions } from 'redux-actions';
import { Map, List, fromJS } from 'immutable';

// action
export const SET_EXPORT_DATE = 'manualBackup/SET_EXPORT_DATE';
export const SET_EXPORT_TIME = 'manualBackup/SET_EXPORT_TIME';
export const APPLY_EXPORT_DATE_TIME = 'manualBackup/APPLY_EXPORT_DATE_TIME';
export const APPLY_DST_DATE_TIME = 'manualBackup/APPLY_DST_DATE_TIME';
export const CANCEL_EXPORT_DATE_TIME = 'manualBackup/CANCEL_EXPORT_DATE_TIME';

export const SET_CALENDAR_OPEN = 'manualBackup/SET_CALENDAR_OPEN';

export const FIND_RECORDING_DATE = 'manualBackup/FIND_RECORDING_DATE';
export const FIND_RECORDING_DATE_SUCCESS = 'manualBackup/FIND_RECORDING_DATE_SUCCESS';
export const FIND_RECORDING_DATE_FAILURE = 'manualBackup/FIND_RECORDING_DATE_FAILURE';

export const SET_IS_RECORDED_DATA_EXISTING = 'manualBackup/SET_IS_RECORDED_DATA_EXISTING';

export const SET_SELECT_TIME_RANGE = 'searchMediaControl/SET_SELECT_TIME_RANGE';
export const SET_TIME_RANGE = 'searchMediaControl/SET_TIME_RANGE';

// action create
export const setDate = createAction(SET_EXPORT_DATE);
export const setTime = createAction(SET_EXPORT_TIME);
export const applyDateTime = createAction(APPLY_EXPORT_DATE_TIME);
export const applyDSTDateTime = createAction(APPLY_DST_DATE_TIME);
export const cancelDateTime = createAction(CANCEL_EXPORT_DATE_TIME);

export const setCalendarOpen = createAction(SET_CALENDAR_OPEN);

export const findRecordingDate = createAction(FIND_RECORDING_DATE);
export const findRecordingDateSuccess = createAction(FIND_RECORDING_DATE_SUCCESS);
export const findRecordingDateFailure = createAction(FIND_RECORDING_DATE_FAILURE);

export const setIsRecordedDataExisting = createAction(SET_IS_RECORDED_DATA_EXISTING);

export const setSelectTimeRange = createAction(SET_SELECT_TIME_RANGE);
export const setTimeRange = createAction(SET_TIME_RANGE);

const today = new Date();

const initialState = Map({
  isCalendarOpen: true,
  exportStartDateObj: Map({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
    hour: 0,
    minute: 0,
    second: 0,
  }),
  exportEndDateObj: Map({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
    hour: 23,
    minute: 59,
    second: 59,
  }),
  exportStartDateTimeObjBackup: Map({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
    hour: 0,
    minute: 0,
    second: 0,
  }),
  exportEndDateTimeObjBackup: Map({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
    hour: 23,
    minute: 59,
    second: 59,
  }),
  isRecordedDataExisting: 'init',
  existingRecordedData: List([]),
  emptyRecordedData: List([]),
  useSelectTimeRange: false,
  selectedTimeRange: Map({
    startTime: {
      hour: 0,
      minute: 0,
      second: 0,
    },
    endTime: {
      hour: 0,
      minute: 0,
      second: 0,
    },
  }),
});

// reducer
export default handleActions({
  [SET_CALENDAR_OPEN]: (state, action) => (
    state.set('isCalendarOpen', action.payload)
  ),
  [SET_EXPORT_DATE]: (state, action) => {
    const {
      year,
      month,
      day,
    } = action.payload;
    return state
      .setIn(['exportStartDateTimeObjBackup', 'year'], year)
      .setIn(['exportStartDateTimeObjBackup', 'month'], month)
      .setIn(['exportStartDateTimeObjBackup', 'day'], day)
      .setIn(['exportEndDateTimeObjBackup', 'year'], year)
      .setIn(['exportEndDateTimeObjBackup', 'month'], month)
      .setIn(['exportEndDateTimeObjBackup', 'day'], day);
  },
  [SET_EXPORT_TIME]: (state, action) => {
    const {
      flag,
      hour,
      minute,
      second,
    } = action.payload;
    if (flag === 'start') {
      return state
        .setIn(['exportStartDateTimeObjBackup', 'hour'], hour)
        .setIn(['exportStartDateTimeObjBackup', 'minute'], minute)
        .setIn(['exportStartDateTimeObjBackup', 'second'], second);
    }
    return state
      .setIn(['exportEndDateTimeObjBackup', 'hour'], hour)
      .setIn(['exportEndDateTimeObjBackup', 'minute'], minute)
      .setIn(['exportEndDateTimeObjBackup', 'second'], second);
  },
  [APPLY_EXPORT_DATE_TIME]: (state, action) => {
    const { payload } = action;
    if (payload === 'start') {
      const startDateTimeObj = state.get('exportStartDateTimeObjBackup');
      return state
        .set('exportStartDateObj', startDateTimeObj)
        .setIn(['exportEndDateObj', 'year'], startDateTimeObj.get('year'))
        .setIn(['exportEndDateObj', 'month'], startDateTimeObj.get('month'))
        .setIn(['exportEndDateObj', 'day'], startDateTimeObj.get('day'));
    }
    const endDateTimeObj = state.get('exportEndDateTimeObjBackup');
    return state
      .set('exportEndDateObj', endDateTimeObj)
      .setIn(['exportStartDateObj', 'year'], endDateTimeObj.get('year'))
      .setIn(['exportStartDateObj', 'month'], endDateTimeObj.get('month'))
      .setIn(['exportStartDateObj', 'day'], endDateTimeObj.get('day'));
  },
  [APPLY_DST_DATE_TIME]: (state, action) => {
    const {
      startDateTime,
      endDateTime,
      dstRangeStartIn,
      dstRangeEndIn,
    } = action.payload;

    if (dstRangeStartIn) {
      const {
        month: sMonth,
        day: sDay,
        hour: sHour,
        minute: sMinute,
        second: sSecond,
      } = startDateTime;
      state
        .setIn(['exportStartDateObj', 'month'], sMonth)
        .setIn(['exportStartDateObj', 'day'], sDay)
        .setIn(['exportStartDateObj', 'hour'], sHour)
        .setIn(['exportStartDateObj', 'minute'], sMinute)
        .setIn(['exportStartDateObj', 'second'], sSecond);
    }

    if (dstRangeEndIn) {
      const {
        month: eMonth,
        day: eDay,
        hour: eHour,
        minute: eMinute,
        second: eSecond,
      } = endDateTime;
      state
        .setIn(['exportEndDateObj', 'month'], eMonth)
        .setIn(['exportEndDateObj', 'day'], eDay)
        .setIn(['exportEndDateObj', 'hour'], eHour)
        .setIn(['exportEndDateObj', 'minute'], eMinute)
        .setIn(['exportEndDateObj', 'second'], eSecond);
    }

    return state;
  },
  [CANCEL_EXPORT_DATE_TIME]: state => {
    const startDateTimeObj = state.get('exportStartDateObj');
    const endDateTimeObj = state.get('exportEndDateObj');
    return state
      .setIn(['exportStartDateTimeObjBackup', 'year'], startDateTimeObj.get('year'))
      .setIn(['exportStartDateTimeObjBackup', 'month'], startDateTimeObj.get('month'))
      .setIn(['exportStartDateTimeObjBackup', 'day'], startDateTimeObj.get('day'))
      .setIn(['exportStartDateTimeObjBackup', 'hour'], startDateTimeObj.get('hour'))
      .setIn(['exportStartDateTimeObjBackup', 'minute'], startDateTimeObj.get('minute'))
      .setIn(['exportStartDateTimeObjBackup', 'second'], startDateTimeObj.get('second'))
      .setIn(['exportEndDateTimeObjBackup', 'year'], endDateTimeObj.get('year'))
      .setIn(['exportEndDateTimeObjBackup', 'month'], endDateTimeObj.get('month'))
      .setIn(['exportEndDateTimeObjBackup', 'day'], endDateTimeObj.get('day'))
      .setIn(['exportEndDateTimeObjBackup', 'hour'], endDateTimeObj.get('hour'))
      .setIn(['exportEndDateTimeObjBackup', 'minute'], endDateTimeObj.get('minute'))
      .setIn(['exportEndDateTimeObjBackup', 'second'], endDateTimeObj.get('second'));
  },
  [FIND_RECORDING_DATE_SUCCESS]: (state, action) => {
    const result = action.payload;
    const targetDate = state.getIn(['exportStartDateObj', 'day']);
    const results = result.CalenderSearchResults;
    const targetChannels = [];
    const emptyChannels = [];
    results.forEach(item => {
      if (item.Result.charAt(targetDate - 1) === '1') {
        targetChannels.push(item.Channel);
      } else {
        emptyChannels.push(item.Channel);
      }
    });

    if (targetChannels.length === 0) {
      return state
        .set('isRecordedDataExisting', 'empty');
    }
    return state
      .set('isRecordedDataExisting', 'exist')
      .set('existingRecordedData', fromJS(targetChannels))
      .set('emptyRecordedData', fromJS(emptyChannels));
  },
  [FIND_RECORDING_DATE_FAILURE]: state => (
    state
      .set('isRecordedDataExisting', 'init')
  ),
  [SET_IS_RECORDED_DATA_EXISTING]: (state, action) => (
    state
      .set('isRecordedDataExisting', action.payload)
  ),
  [SET_SELECT_TIME_RANGE]: (state, { payload: { useSelectTimeRange } }) => (
    state.set('useSelectTimeRange', useSelectTimeRange)
  ),
  [SET_TIME_RANGE]: (state, { payload: { selectedTimeRangeInfo } }) => {
    const { startTime, endTime } = selectedTimeRangeInfo;
    if (startTime !== '' || endTime !== '') {
      const startLocalTime = new Date(startTime);
      const endLocalTime = new Date(endTime);
      console.log('SET_TIME_RANGE startLocalTime', startLocalTime, 'endLocalTime', endLocalTime);
      return state
        .setIn(['exportStartDateTimeObjBackup', 'year'], startLocalTime.getFullYear())
        .setIn(['exportStartDateTimeObjBackup', 'month'], startLocalTime.getMonth() + 1)
        .setIn(['exportStartDateTimeObjBackup', 'day'], startLocalTime.getDate())
        .setIn(['exportStartDateTimeObjBackup', 'hour'], startLocalTime.getHours())
        .setIn(['exportStartDateTimeObjBackup', 'minute'], startLocalTime.getMinutes())
        .setIn(['exportStartDateTimeObjBackup', 'second'], startLocalTime.getSeconds())
        .setIn(['exportEndDateTimeObjBackup', 'year'], endLocalTime.getFullYear())
        .setIn(['exportEndDateTimeObjBackup', 'month'], endLocalTime.getMonth() + 1)
        .setIn(['exportEndDateTimeObjBackup', 'day'], endLocalTime.getDate())
        .setIn(['exportEndDateTimeObjBackup', 'hour'], endLocalTime.getHours())
        .setIn(['exportEndDateTimeObjBackup', 'minute'], endLocalTime.getMinutes())
        .setIn(['exportEndDateTimeObjBackup', 'second'], endLocalTime.getSeconds())
        .setIn(['exportStartDateObj', 'year'], startLocalTime.getFullYear())
        .setIn(['exportStartDateObj', 'month'], startLocalTime.getMonth() + 1)
        .setIn(['exportStartDateObj', 'day'], startLocalTime.getDate())
        .setIn(['exportStartDateObj', 'hour'], startLocalTime.getHours())
        .setIn(['exportStartDateObj', 'minute'], startLocalTime.getMinutes())
        .setIn(['exportStartDateObj', 'second'], startLocalTime.getSeconds())
        .setIn(['exportEndDateObj', 'year'], endLocalTime.getFullYear())
        .setIn(['exportEndDateObj', 'month'], endLocalTime.getMonth() + 1)
        .setIn(['exportEndDateObj', 'day'], endLocalTime.getDate())
        .setIn(['exportEndDateObj', 'hour'], endLocalTime.getHours())
        .setIn(['exportEndDateObj', 'minute'], endLocalTime.getMinutes())
        .setIn(['exportEndDateObj', 'second'], endLocalTime.getSeconds());
    }
    return state;
  },
}, initialState);
