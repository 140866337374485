import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SearchTimelineActions,
  TextSearchActions,
  EventSearchActions,
} from 'store/actionCreators';
// import { Map, List } from 'immutable';

class TextListContainer extends React.Component {
  state = {
    isDescending: false,
    sortedList: [],
    overlappedIDListUI: [],
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.props) === JSON.stringify(nextProps)
      && JSON.stringify(this.state) === JSON.stringify(nextState)
    ) {
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps, prevState) { // , prevState, snapshot
    const {
      searchResult: prevSearchResult,
      overlappedIDList: prevOverlappedIDList,
      currentTime: prevCurrentTime,
    } = prevProps;

    const {
      searchResult,
      TotalResultsFound,
      overlappedIDList,
      currentTime,
    } = this.props;

    const {
      isDescending,
    } = this.state;

    const {
      isDescending: prevIsDecending,
    } = prevState;

    if (searchResult
      && JSON.stringify(prevSearchResult) !== JSON.stringify(searchResult)) {
      const sortedList = this.makeListDataComponent();
      if (sortedList && isDescending) {
        sortedList.reverse();
      }
      if (sortedList && sortedList.length >= TotalResultsFound) {
        TextSearchActions.controlMetaDataRenewStop();
      }
      this.onUpdate({
        sortedList,
      });
    }

    if (searchResult && prevIsDecending != null && (prevIsDecending !== isDescending)) {
      const sortedList = this.makeListDataComponent();
      if (sortedList && isDescending) {
        sortedList.reverse();
      }
      this.onUpdate({
        sortedList,
      });
    }

    if (JSON.stringify(prevOverlappedIDList) !== JSON.stringify(overlappedIDList)) {
      this.onUpdate({
        overlappedIDListUI: overlappedIDList,
      });
      if (overlappedIDList.length > 0) {
        TextSearchActions.setOverlappedIdIndex(overlappedIDList.length - 1);
        SearchTimelineActions.setOverlappedId({
          overlappedId: Number(overlappedIDList[overlappedIDList.length - 1]),
        });
        TextSearchActions.requestTextSearch({
          requestType: 'CONTROL_METADATA',
          Mode: 'Start',
        });
      }
    }

    if (searchResult && JSON.stringify(prevCurrentTime) !== JSON.stringify(currentTime)) {
      const sortedList = this.makeListDataComponent();
      if (sortedList && isDescending) {
        sortedList.reverse();
      }
      this.onUpdate({
        sortedList,
      });
    }
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  onChangeOverlappedId = event => {
    event.preventDefault();
    if (event.target.selectedOptions.length > 0) {
      TextSearchActions.requestTextSearch({
        requestType: 'CONTROL_METADATA',
        Mode: 'Cancel',
      });
      TextSearchActions.setOverlappedIdIndex(event.target.selectedIndex);
      SearchTimelineActions.setOverlappedId({ overlappedId: Number(event.target.value) });
      TextSearchActions.requestTextSearch({
        requestType: 'CONTROL_METADATA',
        Mode: 'Start',
      });
    } else {
      console.log('no select');
    }
  }

  makeListDataComponent = () => {
    const {
      posConfigList,
      searchResult,
      currentTime,
      TotalResultsFound,
    } = this.props;
    if (searchResult.length <= 0) {
      return [];
    }

    return searchResult.map((item, index) => {
      const deviceIndex = posConfigList.findIndex(conf => conf.DeviceID === item.DeviceID);
      const device = posConfigList[deviceIndex];
      const comparisonCurrentTime = item.dst
        ? new Date(currentTime).getTime() + (60 * 60 * 1000)
        : new Date(currentTime).getTime();
      const focused = new Date(item.PlayTime).getTime() < comparisonCurrentTime
        && new Date(item.PlayTime).getTime() + 5000 > comparisonCurrentTime;
      return ({
        id: `textResult-${index}`,
        text: '',
        data: {
          Index: (index + 1).toString().padStart(TotalResultsFound.toString().length, '0'),
          Device: device.DeviceName,
          DeviceID: device.DeviceID,
          Time: item.viewFormatDate,
          dst: item.dst,
          Keyword: item.KeywordsMatched.join(','),
        },
        focused,
      });
    });
  }

  onSelect = e => {
    const { Index, Keyword } = e.data;
    const keywordList = Keyword.split(',');
    const {
      searchResult,
      currentChannel,
      timeZone,
    } = this.props;

    const selectObject = searchResult[Number(Index - 1)];
    selectObject.KeywordsMatched = keywordList;
    // console.log('selectObject.Date', selectObject.LocalStartTime);
    EventSearchActions.setSearchDate(selectObject.LocalStartTime);
    SearchTimelineActions.setSelectEvent({
      ...selectObject,
      isReopen: true,
      StartTime: selectObject.PlayTime,
      isDst: selectObject.dst,
    });
    if (currentChannel === -1
      || typeof searchResult[Number(Index) - 1].ChannelIDList.find(ch => ch === currentChannel) === 'undefined'
    ) {
      SearchTimelineActions.setCurrentChannel(searchResult[Number(Index - 1)].ChannelIDList[0]);
    }
    const timeZoneT = timeZone || 0;
    const startTime = new Date(selectObject.PlayTime);
    startTime.setHours(startTime.getHours() - Number(timeZoneT));
    const startDate = new Date(
      startTime.getUTCFullYear(),
      startTime.getUTCMonth(),
      startTime.getUTCDate(),
      0,
      0,
      0,
    );
    console.log('TextSearch>> timeline >> ', startDate);
    SearchTimelineActions.getTimeline({
      startDate,
      type: 'textTab',
    });
  }

  onDescend = () => {
    const { isDescending } = this.state;
    this.setState({
      isDescending: !isDescending,
    });
  }

  onClickViewMore = () => {
    TextSearchActions.requestTextSearch({
      requestType: 'GET_METADATA',
      Mode: 'Result',
    });
  }

  onClickViewAll = () => {
    // 추가로 요청해야할 횟수를 계산하여 Action 발행
    TextSearchActions.requestTextSearch({
      requestType: 'GET_METADATA',
      Mode: 'Result',
      type: 'all',
    });
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

TextListContainer.defaultProps = {
  posConfigList: [],
  TotalResultsFound: 0,
  overlappedIDList: [],
  currentChannel: 0,
};

TextListContainer.propTypes = {
  render: PropTypes.func.isRequired,
  searchResult: PropTypes.arrayOf(PropTypes.shape({
    ChannelIDList: PropTypes.array,
    Data: PropTypes.string,
    PlayTime: PropTypes.string,
    Result: PropTypes.number,
    TextData: PropTypes.string,
  })).isRequired,
  currentTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.number,
  ]).isRequired,
  posConfigList: PropTypes.arrayOf(PropTypes.shape({
    DeviceName: PropTypes.string,
    Enable: PropTypes.bool,
    Port: PropTypes.number,
    EventPlaybackStartTime: PropTypes.number,
    EncodingType: PropTypes.string,
    ReceiptEnd: PropTypes.string,
    ReceiptStart: PropTypes.string,
    ChannelIDList: PropTypes.array,
  })),
  TotalResultsFound: PropTypes.number,
  overlappedIDList: PropTypes.instanceOf(Array),
  currentChannel: PropTypes.number,
  timeZone: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    lang: state.langModule.get('lang'),
    searchResult: state.textSearchModule.get('searchResult'),
    posConfigList: state.textSearchModule.get('posConfigList'),
    eventKeywordCheckStatus: state.textSearchModule.get('eventKeywordCheckStatus'),
    TotalResultsFound: state.textSearchModule.get('TotalResultsFound'),
    RenewHeartbeat: state.textSearchModule.get('RenewHeartbeat'),
    overlappedIDList: state.textSearchModule.get('overlappedIDList'),
    selectedOverlappedIDIndex: state.textSearchModule.get('selectedOverlappedIDIndex'),
    currentChannel: state.searchTimelineModule.get('currentChannel'),
    timeZone: state.deviceInfoModule.get('timeZone'),
  }),
)(TextListContainer);
