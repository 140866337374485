import axios from 'axios';
import Auth from './Auth';
import CustomXHR from './CustomXHR';

axios.interceptors.request.use(config => {
  if (!config.headers.Authorization) {
    const url = process.env.NODE_ENV !== 'production' ? `${axios.defaults.baseURL}${config.url}` : config.url;
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(config.method.toUpperCase(), url, true);
      if (process.env.NODE_ENV === 'production') {
        xhr.setRequestHeader('XClient', 'XMLHttpRequest');
        xhr.withCredentials = true;
      }
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.timeout = 10000;
      // eslint-disable-next-line func-names
      xhr.onreadystatechange = function () {
        if (this.readyState === 4) {
          if (this.status === 401) {
            const authData = {
              header: {
                'content-type': this.getResponseHeader('content-type'),
                'www-authenticate': this.getResponseHeader('www-authenticate'),
              },
            };
            Auth.makeAuthHeader(authData);
            if (!Auth.loggedIn) {
              Auth.makeAuthKey();
            }
            const digestHeader = Auth.makeDigestHeader(config.method.toUpperCase(), config.url);
            const newConfig = config;
            newConfig.headers.Authorization = digestHeader;
            resolve(newConfig);
          } else {
            reject(new Error(this));
          }
        }
      };
      xhr.send();
    });
  }
  return config.headers.Authorization ? config : null;
}, error => console.log('axios.interceptors.request.use error', error));

const makeHttpRequest = (url, digestHeader) => {
  const request = new XMLHttpRequest();
  request.open('POST', url, true);
  if (process.env.NODE_ENV === 'production') {
    request.setRequestHeader('XClient', 'XMLHttpRequest');
    request.withCredentials = true;
  }
  request.setRequestHeader('Accept', 'application/json');
  request.timeout = 10000;
  if (digestHeader) {
    try {
      // 한글로 로그인 시도 시 페이지가 죽는 이슈 발생하여 처리
      request.setRequestHeader('Authorization', digestHeader);
    } catch (error) {
      console.log('한글 login error', error);
    }
  }
  return request;
};

axios.interceptors.response.use(
  config => config,
  error => {
    if (error.code === 'ECONNABORTED') {
      console.log(`[Timeout] ${error.config.url}`);
      window.location.href = '/error';
    }
    return error;
  },
);

class SunapiClient {
  constructor() {
    this.sequencenum = 0;
  }

  jsonToText = json => {
    let url = '';
    Object.keys(json).forEach(key => {
      if (typeof json[key] === 'boolean') {
        url += `&${key}=${(json[key] === true ? 'True' : 'False')}`;
      } else {
        url += `&${key}=${json[key]}`;
      }
    });

    return url;
  };

  addSeqId = _url => {
    let url = _url;
    if (
      url.indexOf('attributes.cgi') === -1
      && url.indexOf('stw-cgi') !== -1
    ) {
      url = `${_url}&SunapiSeqId=${this.sequencenum}`;
      this.sequencenum += 1;
      if (this.sequencenum > 100000) {
        this.sequencenum = 0;
      }
    }
    return url;
  }

  login = (_url, data) => {
    let xhr = null;
    const url = process.env.NODE_ENV !== 'production' ? `${axios.defaults.baseURL}${_url}` : _url;
    return new Promise((resolve, reject) => {
      xhr = makeHttpRequest(url);
      // eslint-disable-next-line func-names
      xhr.onreadystatechange = function () {
        if (this.readyState === 4) {
          if (this.status === 401) {
            const authData = {
              header: {
                'content-type': this.getResponseHeader('content-type'),
                'www-authenticate': this.getResponseHeader('www-authenticate'),
              },
            };
            Auth.makeAuthHeader(authData);
            Auth.makeAuthKey(data);
            const digestHeader = Auth.makeDigestHeader('POST', url);
            xhr = makeHttpRequest(url, digestHeader);
            // eslint-disable-next-line func-names
            xhr.onreadystatechange = function () {
              if (this.readyState === 4) {
                if (this.status === 200) {
                  resolve();
                } else if (
                  this.status === 0
                  && process.env.NODE_ENV !== 'production'
                ) {
                  reject(new Error('retry'));
                } else {
                  reject(xhr);
                }
              }
            };
            xhr.send();
          } else {
            reject(xhr);
          }
        }
      };
      xhr.send();
    });
  }

  /**
   * Axios POST
   * @param {string}  _url            { 서버 요청 URL}
   * @param {object}  jsonData        { URL Parameter를 만들기 위한 data }
   * @param {any}     data            { body Parameter data 혹은 file data }
   * @param {object}  newHeaders  { header config를 수정할 필요가 있는경우 넣어주는 데이터 }
   * @memberof SunapiClient
   */
  post = (_url, jsonData, data, newHeaders, newConfig) => {
    let url = _url;

    if (jsonData) {
      // Make for URL Parameter
      url += this.jsonToText(jsonData);
    }
    const digestHeader = Auth.makeDigestHeader('POST', url);
    const config = {
      headers: {
        Authorization: digestHeader,
        ...newHeaders,
      },
      ...newConfig,
    };

    return CustomXHR.post(
      url,
      data,
      config,
    );
  };

  /**
   * Axios GET
   * @param {string}  _url            { 서버 요청 URL}
   * @param {object}  jsonData        { URL Parameter를 만들기 위한 data }
   * @param {object}  newHeaders  { header config를 수정할 필요가 있는경우 넣어주는 데이터 }
   * @memberof SunapiClient
   */
  get = (_url, jsonData, newHeaders, newConfig) => {
    let url = _url;

    // if (jsonData) {
    //   url += this.jsonToText(jsonData);
    //   if (url.indexOf('attributes.cgi') === -1) {
    //     url += `&SunapiSeqId=${this.sequencenum}`;
    //     this.sequencenum += 1;
    //   }
    //   if (this.sequencenum > 100000) {
    //     this.sequencenum = 0;
    //   }
    // }
    if (jsonData) {
      // Make for URL Parameter
      url += this.jsonToText(jsonData);
    }
    url = this.addSeqId(url);

    const digestHeader = Auth.makeDigestHeader('GET', url);

    const config = {
      headers: {
        Authorization: digestHeader,
        ...newHeaders,
      },
      ...newConfig,
    };

    return CustomXHR.get(
      url,
      config,
    );
  };

  sequence = queue => {
    const currentItem = queue.shift();

    if (typeof currentItem === 'undefined') {
      return;
    }

    // Get
    if (typeof currentItem.encodeddata === 'undefined') {
      this.get(
        currentItem.url,
        currentItem.reqData,
        '',
      ).then(() => {
        if (queue.length > 0) {
          this.sequence(queue);
        }
      });
    } else { // Post
      const newHeaders = {
        'Content-Type': 'application/x-www-form-urlencoded',
      };
      this.post(
        currentItem.url,
        currentItem.reqData,
        currentItem.encodeddata,
        newHeaders,
      ).then(() => {
        if (queue.length > 0) {
          this.sequence(queue);
        }
      });
    }
  };

  // sequence_es6 = (queue) => {
  //   // queue = [{url:'',data''}, ...]
  //   const funcs = queue.map(item => () => this.get(item.url, item.data, ''));
  //   funcs.reduce((promise, func) =>
  //     promise.then(result => func().then(Array.prototype.concat.bind(result))),
  //     Promise.resolve([]));
  // };
}

export default new SunapiClient();
