import React from 'react';
import PropTypes from 'prop-types';

class DropDownMenuContainer extends React.Component {
  menu = {};

  componentDidUpdate(prevProps) {
    const { isOpend } = this.props;
    if (prevProps.isOpend !== isOpend) {
      if (isOpend) {
        document.addEventListener('click', this.onClickEvent);
      } else {
        document.removeEventListener('click', this.onClickEvent);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickEvent);
  }

  setRef = el => {
    this.menu = el;
  }

  onClickEvent = e => {
    e.preventDefault();
    if (this.menu) {
      if (Object.keys(this.menu).length !== 0 && !this.menu.contains(e.target)) {
        const { onClickOutside } = this.props;
        onClickOutside();
      }
    }
  }

  render() {
    const { render } = this.props;
    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

DropDownMenuContainer.defaultProps = {
  onClickOutside: () => {},
};

DropDownMenuContainer.propTypes = {
  render: PropTypes.func.isRequired,
  isOpend: PropTypes.bool.isRequired,
  onClickOutside: PropTypes.func,
};

export default DropDownMenuContainer;
