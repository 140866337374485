import styled, { withTheme } from 'styled-components';
import { Button, ReactModalAdapter } from 'wisenet-ui/components/atoms';

export const SearchOptionStyled = styled.div`
  display: flex;
  top: 0;
  margin-bottom: 5px;
  align-items: center;
`;

export const PopupStyled = styled(ReactModalAdapter).attrs({
  className: 'Popup',
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  
  .ReactModal__Overlay {
    width: 100%;
    height: 100%;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    background-color: rgba(0,0,0,.5) !important;
  }

  .ReactModal__Content {
    top: 50% !important;
    left: 50% !important;
    width: 320px;
    height: 200px;
    transform: translate(-50%, -50%) !important;
    background-color: #fff !important;
    border: 1px solid #797979 !important;
    border-radius: 0px !important;
  }

  .ReactModal__Content--after-open {
    padding: 0px !important;
    overflow: hidden !important;
  }
`;

export const PopupButtonStyled = styled(Button)`
  width: 100px;
  height: 28px;
  font-size: 14px;
  padding: 0;
`;

export const IconStyled = withTheme(styled.i`
  font-size: 20px;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.text.color4};
  }
`);

export const PopupContentWrapperStyled = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-all;
`;
