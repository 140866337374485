import styled, { withTheme } from 'styled-components';
import { ListSelect } from 'components/molecules';
import { IconButton } from 'wisenet-ui/components/atoms';

export const SearchOptionMiddleStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const IconButtonStyled = withTheme(styled(IconButton)`
  padding: 0;

  &:hover {
    color: ${props => props.theme.text.color4};
  }
`);

export const TimeLableStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TimePickerWrapper = styled.div`
  display: flex;
  margin-top: -5px;
  height: 28px;
`;

export const ListSelectStyled = styled(ListSelect)`
  height: 25px;
  padding: 0 5px;
  margin-left: 20px;
`;
