import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MediaControlIDList } from 'wisenet-ui/util/static/constants/mediaControl/mediaControlType';
import UmpInfomationType from 'wisenet-ui/util/static/constants/umpPlayer/umpInfomationType';
import { SearchMediaControlActions } from 'store/actionCreators';
import { Map } from 'immutable';
import { getLanguage } from 'util/lib';

class PlaybackVideoTileContainer extends React.Component {
  state = {
    startTime: '',
    endTime: '',
    useIsoTimeFormat: true,
    seekingTime: '',
    openTileControl: false,
    tileControlWork: {
      capure: false,
      sound: false,
      rotate: false,
    },
    rotate: 0,
    sound: false, // true 시 mute 상태
    capture: false,
    aspectRatio: false,
  };

  constructor(props) {
    super(props);
    this.toast = null;
  }

  componentDidMount() {
    const {
      timeLine: propsTimeLine,
      selectEvent: propsSelectEvent,
      currentTabName,
    } = this.props;
    const timeLine = propsTimeLine ? propsTimeLine[0] : null;

    if (timeLine) {
      if (currentTabName !== 'textTab') {
        this.setTime([...timeLine.normal, ...timeLine.event]);
      } else {
        const selectEvent = propsSelectEvent.toJS();
        this.setTime([...timeLine.normal, ...timeLine.event], selectEvent.StartTime);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // const {
    //   selectEvent: nextSelectEvent,
    // } = nextProps;

    // const {
    //   selectEvent: propsSelectEvent,
    // } = this.props;

    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false;
    }

    // const toJSNextSelectEvent = nextSelectEvent.toJS();
    // const toJSPropsSelectEvent = propsSelectEvent.toJS();

    // if (JSON.stringify(toJSNextSelectEvent) !== JSON.stringify(toJSPropsSelectEvent)) {
    //   const selectEvent = nextSelectEvent.toJS();
    //   return !!selectEvent.isReopen; // undefined 인 경우 false로 변환하기 위해서 사용
    // }

    return true;
  }

  componentDidUpdate(prevProps, prevState) { // , prevState, snapshot
    const {
      timeLine: prevTimeLine,
    //   filterEvent: prevFilterEvent,
    } = prevProps;

    const {
      startTime: prevStartTime,
      seekingTime: prevSeekingTime,
      overlappedID: prevOverlappedID,
    } = prevState;
    const {
      startTime,
      seekingTime,
    } = this.state;

    const {
      timeLine: propsTimeLine,
      // filterEvent,
      overlappedIDList,
      currentTabName,
      // instantPlaybackSeeking,
      selectEvent: propsSelectEvent,
    } = this.props;

    // 타임라인을 처음 그릴 필요가 있는 경우
    if (JSON.stringify(prevTimeLine) !== JSON.stringify(propsTimeLine)
      && currentTabName === 'textTab') {
      const timeLine = propsTimeLine ? propsTimeLine[0] : null;

      if (timeLine) {
        const timelineResults = [...timeLine.normal, ...timeLine.event];
        const selectEvent = propsSelectEvent.toJS();
        this.setTime(timelineResults, selectEvent.StartTime);
      } else {
        SearchMediaControlActions.playControl({
          playMode: 'stop',
        });
      }
    } else {
      const {
        selectEvent: prevSelectEvent,
      } = prevProps;

      let overlappedID = prevOverlappedID;

      if (JSON.stringify(prevSelectEvent) !== JSON.stringify(propsSelectEvent)) {
        const selectEvent = propsSelectEvent.toJS();
        const prevSelect = prevSelectEvent.toJS();
        let changeDate = false;
        // 이벤트에서 EndTime이 존재 하지 않으면 해당 날짜 내에서 Seek을 하는 경우
        if (prevSelectEvent.size !== 0) {
          if (!!selectEvent.EndTime === true) {
            // 이전 값에서 Endtime이 없으면 비교 대상이 없기 때문에 날짜 변경으로 판단한다.
            if (!prevSelect.EndTime) {
              [overlappedID] = overlappedIDList;
              changeDate = true;
            } else {
              // startTime과 endTime 내에 시간이 존재 않지 않으면 날짜가 변경되었다고 판단.
              const prevStartValue = new Date(prevSelect.StartTime).valueOf();
              const prevEndValue = new Date(prevSelect.EndTime).valueOf();
              const selectStartValue = new Date(selectEvent.StartTime).valueOf();
              if (!(prevStartValue <= selectStartValue && prevEndValue >= selectStartValue)) {
                [overlappedID] = overlappedIDList;
                changeDate = true;
              }
            }
          }
          if (selectEvent.selectedOverlappedIDForUmp !== prevSelect.selectedOverlappedIDForUmp) {
            if (typeof selectEvent.selectedOverlappedIDForUmp !== 'undefined') {
              changeDate = true;
              overlappedID = selectEvent.selectedOverlappedIDForUmp;
            }
          }
        }

        const { playMode } = this.props;
        const playTime = (playMode === 'play') && !changeDate ? 'seekingTime' : 'startTime';
        d.log('[ump test] selectEvent - ', selectEvent);
        this.onUpdate({
          [playTime]: selectEvent.StartTime,
          overlappedID,
          endTime: selectEvent.EndTime,
        });
      }

      if (prevStartTime !== startTime) {
        const { playMode } = this.props;
        if (playMode !== 'play') {
          SearchMediaControlActions.playControl({
            playMode: 'play',
          });
        }
      }

      if (prevSeekingTime !== seekingTime) {
        const { playMode } = this.props;
        if (playMode !== 'play') {
          SearchMediaControlActions.playControl({
            playMode: 'play',
          });
        }
      }
    }
  }

  setTime = (timeLine, seekingTime = '') => {
    const {
      overlappedIDList,
      playMode,
      currentTabName,
    } = this.props;
    // console.log('play!!!!');
    if (playMode !== 'play') {
      SearchMediaControlActions.playControl({
        playMode: 'play',
      });
    }

    const setStartTime = seekingTime !== '' ? new Date(seekingTime) : timeLine[0].StartTime;
    const setEndTime = new Date(
      setStartTime.getFullYear(), setStartTime.getMonth(), setStartTime.getDate(), 23, 59, 59,
    );
    this.onUpdate({
      startTime: setStartTime.toISOString(),
      endTime: setEndTime.toISOString(),
      overlappedID: overlappedIDList[0],
      seekingTime,
      // text search에서 처음 시간을 넣는 값이 없어서 생성한 값
      timeLineStartTime: currentTabName === 'textTab' ? timeLine[0].StartTime : undefined,
    });
  }

  onTimestamp = data => {
    const { setCurrentTime } = this.props;

    setCurrentTime(data.detail.timestamp);
  }

  // ump를 통해서 state로 값을 관리하는 부분
  onTileInfo = type => ({ onData, ...rest }) => { // onData, ...rest에 데이터 ump data 포함
    switch (type) {
      case UmpInfomationType.CAPTURE: {
        this.workOff(type);
        this.onCapture(onData[type], rest);
        break;
      }
      case UmpInfomationType.SOUND: // mute에 대한 데이터
      case UmpInfomationType.ROTATE: {
        this.workOff(type, onData);
        break;
      }
      default:
        break;
    }
  }

  workOff = (type, onData) => {
    const { tileControlWork } = this.state;
    this.onUpdate({
      tileControlWork: {
        ...tileControlWork,
        [type]: false,
      },
      [type]: onData ? onData[type] : undefined,
    });
  }

  onMouseEvent = type => event => {
    switch (type) {
      case 'enter': {
        this.onUpdate({ openTileControl: true });
        break;
      }
      case 'leave': {
        // chrome 브라우저에서 여러번 클릭 시 원하지 않는 객체의 leave이벤트 발생으로 인한 예외처리
        if (window !== event.relatedTarget) {
          // event leave가 정상동작 할 때
          this.onUpdate({ openTileControl: false });
        }
        break;
      }
      default:
        break;
    }
  }


  onTileBarClick = type => event => {
    event.stopPropagation();
    const { tileControlWork } = this.state;
    const { browserCheck } = this.props;
    switch (type) {
      case MediaControlIDList.CAPTURE: {
        if (browserCheck === 'Safari') {
          this.openToast('Capture');
        } else if (!tileControlWork[type]) {
          this.onUpdate({
            tileControlWork: {
              ...tileControlWork,
              [type]: true,
            },
          });
        }
        break;
      }
      case MediaControlIDList.SOUND:
      case MediaControlIDList.ROTATE:
        if (!tileControlWork[type]) {
          this.onUpdate({
            tileControlWork: {
              ...tileControlWork,
              [type]: true,
            },
          });
        }
        break;
      case MediaControlIDList.ASPECT_RATIO: {
        const { aspectRatio } = this.state;
        this.onUpdate({
          aspectRatio: !aspectRatio,
        });
        break;
      }
      default:
        break;
    }
  }


  openToast = type => {
    switch (type) {
      case 'Capture':
        this.toast.openToast(
          <span>
            {getLanguage('lang_capture_safari')}
          </span>, true,
        );
        break;
      default:
        break;
    }
  }

  onCapture = (event, data) => {
    // 기능 확인을 위한 코드
    const time = new Date();
    const Month = time.getMonth() + 1;
    const channel = data.channel + 1;
    const a = document.createElement('a');
    a.href = URL.createObjectURL(event.detail.blob);
    a.download = `${channel}-${time.getFullYear()}${Month}${time.getDate()}${time.getHours()}${time.getMinutes()}.png`;
    a.click();
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  setRef = t => {
    this.toast = t;
  }


  // ump로 부터 받은 응답으로 영상의 모드를 변경할 경우 동작
  onPlayModeChange = ({ playMode }) => {
    SearchMediaControlActions.playControl({
      playMode,
    });
  }

  render() {
    const {
      render,
    } = this.props;
    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

PlaybackVideoTileContainer.propTypes = {
  render: PropTypes.func.isRequired,
  overlappedIDList: PropTypes.oneOfType([PropTypes.any]).isRequired,
  timeLine: PropTypes.oneOfType([PropTypes.any]).isRequired,
  // filterEvent: PropTypes.instanceOf(List).isRequired,
  setCurrentTime: PropTypes.func.isRequired,
  selectEvent: PropTypes.instanceOf(Map).isRequired,
  currentTabName: PropTypes.oneOf(['eventTab', 'textTab']).isRequired,
  playMode: PropTypes.string.isRequired,
  // instantPlaybackSeeking: PropTypes.instanceOf(Object).isRequired,
  browserCheck: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    sessionKey: state.preLoadModule.get('playbackSessionKey'),
    overlappedIDList: state.searchTimelineModule.get('overlappedIDList'),
    timeLine: state.searchTimelineModule.get('timeLineSearchResults'),
    filterEvent: state.searchTimelineModule.get('filterEvent'),
    playMode: state.searchMediaControlModule.get('playMode'),
    selectEvent: state.searchTimelineModule.get('selectEvent'),
    playSpeed: state.searchMediaControlModule.get('playSpeed'),
    OSDDisplay: state.searchMediaControlModule.get('OSDDisplay'),
    stepControlInfomation: state.searchMediaControlModule.get('stepControlInfomation'),
    currentChannel: state.searchTimelineModule.get('currentChannel'),
    browserCheck: state.preLoadModule.get('browser'),
    playModeChangeTime: state.searchMediaControlModule.get('playModeChangeTime'),
    // overlappedID: state.searchTimelineModule.get('selectedOverlappedID'),
    // overlappedIdChangeTime: state.searchTimelineModule.get('overlappedIdChangeTime'),
    // instantPlaybackSeeking: state.searchTimelineModule.get('instantPlaybackSeeking'),
  }),
)(PlaybackVideoTileContainer);
