import { createAction, handleActions } from 'redux-actions';
import { Map, List, fromJS } from 'immutable';

// action
export const GET_LAYOUTLIST = 'layoutList/GET_LAYOUTLIST';
export const GET_LAYOUTLIST_SUCCESS = 'layoutList/GET_LAYOUTLIST_SUCCESS';
export const GET_LAYOUTLIST_FAILURE = 'layoutList/GET_LAYOUTLIST_FAILURE';

export const ADD_LAYOUTLIST = 'layoutList/ADD_LAYOUTLIST';
export const ADD_LAYOUTLIST_SUCCESS = 'layoutList/ADD_LAYOUTLIST_SUCCESS';
export const ADD_LAYOUTLIST_FAILURE = 'layoutList/ADD_LAYOUTLIST_FAILURE';

export const REMOVE_LAYOUTLIST = 'layoutList/REMOVE_LAYOUTLIST';
export const REMOVE_LAYOUTLIST_SUCCESS = 'layoutList/REMOVE_LAYOUTLIST_SUCCESS';
export const REMOVE_LAYOUTLIST_FAILURE = 'layoutList/REMOVE_LAYOUTLIST_FAILURE';

export const EDIT_LAYOUTLIST = 'layoutList/EDIT_LAYOUTLIST';
export const EDIT_LAYOUTLIST_SUCCESS = 'layoutList/EDIT_LAYOUTLIST_SUCCESS';
export const EDIT_LAYOUTLIST_FAILURE = 'layoutList/EDIT_LAYOUTLIST_FAILURE';

export const SET_LAYOUTLIST_UPDATE_CHECK = 'layoutList/SET_LAYOUTLIST_UPDATE_CHECK';

// action create
export const getLayoutList = createAction(GET_LAYOUTLIST);
export const getLayoutListSuccess = createAction(GET_LAYOUTLIST_SUCCESS);
export const getLayoutListFailure = createAction(GET_LAYOUTLIST_FAILURE);

export const addLayoutList = createAction(ADD_LAYOUTLIST);
export const addLayoutListSuccess = createAction(ADD_LAYOUTLIST_SUCCESS);
export const addLayoutListFailure = createAction(ADD_LAYOUTLIST_FAILURE);

export const removeLayoutList = createAction(REMOVE_LAYOUTLIST);
export const removeLayoutListSuccess = createAction(REMOVE_LAYOUTLIST_SUCCESS);
export const removeLayoutListFailure = createAction(REMOVE_LAYOUTLIST_FAILURE);

export const editLayoutList = createAction(EDIT_LAYOUTLIST);
export const editLayoutListSuccess = createAction(EDIT_LAYOUTLIST_SUCCESS);
export const editLayoutListFailure = createAction(EDIT_LAYOUTLIST_FAILURE);

export const setCheckLayoutListUpdateVal = createAction(SET_LAYOUTLIST_UPDATE_CHECK);

const defaultState = Map({
  layoutListData: List([]),
  layoutListGetData: [],
  templayoutNameValue: '',
  checkLayoutListUpdate: null,
  errorMsg: '',
});

const initialState = defaultState;

export default handleActions({
  [GET_LAYOUTLIST_SUCCESS]: (state, action) => state.set('layoutListData', fromJS(action.payload)),
  [GET_LAYOUTLIST_FAILURE]: state => state.set('layoutListData', fromJS([])),
  [ADD_LAYOUTLIST_SUCCESS]: (state, action) => (
    state
      .set('layoutListData', fromJS(action.payload.layoutListData))
      .set('checkLayoutListUpdate', action.payload.checkLayoutListUpdate)
      .set('errorMsg', action.payload.errorMsg)
  ),
  [ADD_LAYOUTLIST_FAILURE]: state => state.set('layoutListData', fromJS([])),
  [REMOVE_LAYOUTLIST_SUCCESS]: (state, action) => (
    state
      .set('layoutListData', fromJS(action.payload.layoutListData))
      .set('checkLayoutListUpdate', action.payload.checkLayoutListUpdate)
      .set('errorMsg', action.payload.errorMsg)
  ),
  [REMOVE_LAYOUTLIST_FAILURE]: () => true,
  [EDIT_LAYOUTLIST_SUCCESS]: (state, action) => (
    state
      .set('layoutListData', fromJS(action.payload.layoutListData))
      .set('checkLayoutListUpdate', action.payload.checkLayoutListUpdate)
      .set('errorMsg', action.payload.errorMsg)
  ),
  [EDIT_LAYOUTLIST_FAILURE]: state => state.set('layoutListData', fromJS([])),
  [SET_LAYOUTLIST_UPDATE_CHECK]: (state, action) => state.set('checkLayoutListUpdate', action.payload),
}, initialState);
