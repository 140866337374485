import styled, { withTheme } from 'styled-components';
import { Span } from 'wisenet-ui/components/atoms';
import { Slider } from 'wisenet-ui/components/molecules';
import ReactTooltip from 'react-tooltip';

export const TileInstantPlaybackControlWrapperStyled = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const PlayControlIconStyled = withTheme(styled.i`
  font-size: 60px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  cursor: pointer;
  text-shadow: -1px -1px 2px #000, 1px -1px 2px #000, -1px 1px 2px #000, 1px 1px 2px #000;
  color: #ffffff;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`);

export const TileInstantPlaybackControlBottomWrapperStyled = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 2%;
  margin-bottom: 3%;
`;

export const IconStyled = withTheme(styled.i`
  font-size: 32px;
  cursor: pointer;
  margin-right: 5px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  text-shadow: -1px -1px 2px #000, 1px -1px 2px #000, -1px 1px 2px #000, 1px 1px 2px #000;
  color: #ffffff;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`);

export const SpanStyle = styled(Span)`
  font-size: 12px;
  text-shadow: -1px -1px 2px #000, 1px -1px 2px #000, -1px 1px 2px #000, 1px 1px 2px #000;
  color: #ffffff;
  &:nth-of-type(1) {
    float: left;
  }
  &:nth-of-type(2) {
    float: right;
  }
`;

export const SliderStyle = styled(Slider)`
  margin-bottom: 2.5%;
  padding: 0 3%;
  & > span > div > div {
    &[class*='rc-slider-rail'] {
      height: 6px !important;
    }
    &[class*='rc-slider-track'] {
      height: 6px !important;
    }
    &[class*='rc-slider-handle'] {
      border-radius: 100%;
      background-color: white !important;
      width: 22px !important;
      height: 22px !important;
      border-color: white !important;

      &::before {
        content: '';
        border: 5px solid #f37321;
        border-radius: 100%;
        width: 8px;
        height: 8px;
        position: absolute;
      }
    }
  }
`;

export const CustomReactTooltip = withTheme(styled(ReactTooltip)`
  background-color: #555 !important;
  padding: 5px !important;
  &.place-top {
    &:after {
      border-top: none !important;
    }
  }
  &.place-left {
    &:after {
      border-left: 6px solid #555 !important;
    }
  }
  &.place-right {
    &:after {
      border-right: 6px solid #555 !important;
    }
  }
`);

export const TooltipSpan = withTheme(styled(Span)`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  margin: 0px;
  padding: 0px
`);
