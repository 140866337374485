import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';

class LiveVideoTileBarContainer extends React.Component {
  state = {
    posEventDetection: false,
  }

  timer = null;

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
    && JSON.stringify(nextState) === JSON.stringify(this.state)
    ) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const {
      posEvent,
      alarmStop,
    } = this.props;
    if (posEvent !== null && prevProps.posEvent !== posEvent) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(this.initPosEventDetection, 5000);
      this.onUpdate({
        posEventDetection: true,
      });
    }
    if (prevProps.alarmStop !== alarmStop) {
      this.initPosEventDetection();
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  initPosEventDetection = () => {
    this.timer = null;
    this.setState({
      posEventDetection: false,
    });
  }

  checkIVADetection = () => {
    const { videoAnalytics } = this.props;
    const keys = Object.keys(videoAnalytics);
    let result = false;
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (videoAnalytics[key]) {
        result = true;
        break;
      }
    }
    return result;
  }

  render() {
    const { render } = this.props;
    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
        ivaDetection: this.checkIVADetection(),
      },
    );
  }
}

LiveVideoTileBarContainer.defaultProps = {
  videoAnalytics: {
    appearing: false,
    disappearing: false,
    entering: false,
    exiting: false,
    intrusion: false,
    loitering: false,
    passing: false,
  },
  posEvent: null,
};

LiveVideoTileBarContainer.propTypes = {
  render: PropTypes.func.isRequired,
  // channel Event
  // AMDStart: PropTypes.bool,
  // audioAnalytics: {
  //   scream: PropTypes.bool,
  //   gunshot: PropTypes.bool,
  //   explosion: PropTypes.bool,
  //   glassBreak: PropTypes.bool,
  // },
  // audioDetection: PropTypes.bool,
  // defocusDetection: PropTypes.bool,
  // faceDetection: PropTypes.bool,
  // fogDetection: PropTypes.bool,
  // lowFps: PropTypes.bool,
  // motionDetection: PropTypes.bool,
  // networkAlarmInput: PropTypes.bool,
  // networkCameraConnect: PropTypes.bool,
  // sdFail: PropTypes.bool,
  // sdFull: PropTypes.bool,
  // tampering: PropTypes.bool,
  // tracking: PropTypes.bool,
  // recorderAlarmInput: PropTypes.bool,
  videoAnalytics: PropTypes.shape({
    appearing: PropTypes.bool,
    disappearing: PropTypes.bool,
    entering: PropTypes.bool,
    exiting: PropTypes.bool,
    intrusion: PropTypes.bool,
    loitering: PropTypes.bool,
    passing: PropTypes.bool,
  }),
  posEvent: PropTypes.instanceOf(Map),
  alarmStop: PropTypes.bool.isRequired,
};

export default connect(
  (state, props) => {
    let havePosEventChannel = state.systemInfomationModule.get('posEvent').get('channelIDList').find(channel => channel === props.channel);
    havePosEventChannel = (typeof havePosEventChannel !== 'undefined');
    const cameraEvent = state.cameraInfoModule.get('cameraEventList').find(camera => camera.get('channel') === props.channel) || Map({});
    return ({
      ...cameraEvent.toJS(),
      posEvent: havePosEventChannel ? state.systemInfomationModule.get('posEvent') : null,
      alarmStop: state.liveMediaControlModule.get('alarmStop'),
    });
  },
)(LiveVideoTileBarContainer);
