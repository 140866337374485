import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PTZSequenceActions } from 'store/actionCreators';

class PTZTourContainer extends React.Component {
  state = {
    currentTourList: null,
    prevTour: null,
  };

  componentWillReceiveProps(nextProps) {
    const { currentChannel } = nextProps;
    const { prevChannel } = this.props;
    if (currentChannel !== -1) {
      const { tourList, ptzInfo } = nextProps;
      if ((tourList === null || prevChannel !== currentChannel)
        && (typeof ptzInfo !== 'undefined' && ptzInfo.tour)) {
        // PTZSequenceActions.setTourPending(true);
        const getData = {
          Channel: currentChannel,
        };

        PTZSequenceActions.getTour(getData);

        PTZSequenceActions.setPrevChannel(currentChannel);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      currentChannel,
      tourList: nextTourList,
      ptzInfo,
      isActive,
    } = nextProps;

    const {
      currentChannel: prevChannel,
      tourList,
    } = this.props;

    const {
      currentTourList: currentTourListState,
      prevTour,
    } = nextState;
    const { currentTourList: prevTourListState } = this.state;

    document.getElementById('tour_disable_mask').style.display = 'block';
    document.getElementById('tour_wrapper').style.pointerEvents = 'none';

    if (typeof ptzInfo !== 'undefined' && ptzInfo.tour) {
      document.getElementById('tour_disable_mask').style.display = 'none';
      document.getElementById('tour_wrapper').style.pointerEvents = 'auto';
    } else {
      document.getElementById('tour_disable_mask').style.display = 'block';
      document.getElementById('tour_wrapper').style.pointerEvents = 'none';
    }

    if ((!Number.isNaN(prevChannel) || !Number.isNaN(currentChannel)) && isActive) {
      if (prevChannel !== currentChannel) {
        if (prevChannel !== null && !Number.isNaN(prevChannel) && prevTour !== null) {
          // 채널 변경시 이전 채널 동작 중지
          // const getData = {
          //   Channel: prevChannel,
          //   Mode: 'Stop',
          //   Tour: prevTour.no,
          // };
          // PTZSequenceActions.controlTour(getData);

          // this.setState({
          //   prevTour: null,
          // });
        }
        if (nextTourList !== null) {
          this.setState({
            currentTourList: this.makeTourList(nextTourList),
          });
          return true;
        }
        return false;
      }
      if (JSON.stringify(tourList) !== JSON.stringify(nextTourList)) {
        // 채널 props 업데이트가 리스트 정보 업데이트보다 빨라서 별도 분기 처리.
        this.setState({
          currentTourList: this.makeTourList(nextTourList),
        });
        return true;
      }
    }
    // 현재 탭에서 상태 변경 시
    if (prevTourListState === null || currentTourListState === null) {
      if (nextTourList !== null && isActive) {
        this.setState({
          currentTourList: this.makeTourList(nextTourList),
        });
        return true;
      }
      return false;
    }

    return true;
  }

  makeTourList = tourList => {
    const array = [];
    for (let idx = 1; idx <= tourList.length; idx += 1) {
      array.push({
        index: idx - 1,
        no: idx,
        name: `Tour ${idx}`,
        isSelected: false,
      });
    }
    return array;
  };

  onChangeData = (dataList, selectedData) => {
    let getData = {};
    const { currentChannel } = this.props;

    if (selectedData.isSelected) {
      getData = {
        Channel: currentChannel,
        Mode: 'Start',
        Tour: selectedData.no,
      };
    } else {
      getData = {
        Channel: currentChannel,
        Mode: 'Stop',
        Tour: selectedData.no,
      };
    }
    PTZSequenceActions.controlTour(getData);

    this.setState({
      currentTourList: dataList,
      prevTour: selectedData,
    });
  }

  controlTour = option => {
    const { currentChannel } = this.props;
    const { currentTour, isOn } = this.state;
    const getData = {
      Channel: currentChannel,
    };

    if (currentTour !== option.id) {
      getData.Tour = option.id;
      getData.Mode = 'Start';
    } else if (isOn) {
      getData.Tour = option.id;
      getData.Mode = 'Stop';
    }

    PTZSequenceActions.controlTour(getData);

    this.setState({
      currentTour: option.id,
      isOn: !isOn,
    });
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

PTZTourContainer.propTypes = {
  render: PropTypes.func.isRequired,
  tourList: PropTypes.arrayOf(Object),
  currentChannel: PropTypes.number,
  prevChannel: PropTypes.number,
  ptzInfo: PropTypes.objectOf(PropTypes.any),
  isActive: PropTypes.bool,
};

PTZTourContainer.defaultProps = {
  tourList: [],
  currentChannel: -1,
  prevChannel: -1,
  ptzInfo: {},
  isActive: false,
};

export default connect(
  state => ({
    isTourPending: state.ptzSequenceModule.get('isTourPending'),
    tourList: state.ptzSequenceModule.get('tourList'),
    lang: state.langModule.get('lang'),
    prevChannel: state.ptzSequenceModule.get('prevChannel'),
  }),
)(PTZTourContainer);
