import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import {
  EventSearchActions,
  SearchTimelineActions,
  SearchMediaControlActions,
} from 'store/actionCreators';
import { JSONCompare } from 'wisenet-ui/util/function/compare';

class BasicDatePickerContainer extends React.Component {
  state = {
    includeDates: [],
    selectedDate: null,
    today: new Date(),
  }

  componentDidMount() {
    const {
      currentChannel,
      instantPlaybackSeeking,
      dateInfo,
    } = this.props;
    if (currentChannel !== -1
      && instantPlaybackSeeking
      && instantPlaybackSeeking.localTime !== '') {
      // 인스턴트 플레이백에서 진입 함
      this.setSelectedDate(instantPlaybackSeeking.localTime);
      this.onDateChange(new Date(instantPlaybackSeeking.localTime.replace(/-/g, '/')));
    } else {
      this.setSelectedDate();
    }

    const { LocalTime } = dateInfo.toJS();
    if (LocalTime) {
      this.setState({
        today: new Date(LocalTime.replace(' ', 'T')),
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      calenderSearchResults,
      searchDateObj,
      currentChannel,
      instantPlaybackSeeking,
      dateInfo,
    } = this.props;

    const {
      calenderSearchResults: prevCalenderSearchResults,
      searchDateObj: prevSearchDateObj,
      instantPlaybackSeeking: prevInstantPlaybackSeeking,
      dateInfo: prevDateInfo,
    } = prevProps;

    const {
      selectedDate,
    } = this.state;

    const {
      selectedDate: prevSelectedDate,
    } = prevState;


    if (JSON.stringify(prevCalenderSearchResults) !== JSON.stringify(calenderSearchResults)) {
      this.setCalendarDate();
    }
    if (JSON.stringify(prevSearchDateObj) !== JSON.stringify(searchDateObj)) {
      this.setSelectedDate();
    }
    // 선택된 year or month가 변경되면 해당 월의 캘린더서치를 다시 해야 함
    if (searchDateObj !== null && selectedDate !== null
      && (searchDateObj.month - 1 !== selectedDate.getMonth()
      || searchDateObj.year !== selectedDate.getFullYear())
    ) {
      // 선택된 월에서 날짜를 변경함으로 인해 다른 월로 변경되는 경우
      const param = {
        index: currentChannel,
        date: `${searchDateObj.year}-${searchDateObj.month}`,
      };
      EventSearchActions.findRecordingDate(param);
    }

    if (
      selectedDate !== null
      && JSON.stringify(selectedDate) !== JSON.stringify(prevSelectedDate)
    ) {
      SearchMediaControlActions.setSelectTimeRange({
        useSelectTimeRange: false,
      });
      SearchTimelineActions.getTimeline({
        startDate: selectedDate,
        type: 'eventTab',
      });
    }

    if (instantPlaybackSeeking.currentTime !== ''
      && instantPlaybackSeeking.localTime !== ''
      && JSON.stringify(instantPlaybackSeeking) !== JSON.stringify(prevInstantPlaybackSeeking)) {
      // 인스턴트 플레이백에서 진입 함
      this.setSelectedDate(instantPlaybackSeeking.localTime);
      this.onDateChange(new Date(instantPlaybackSeeking.localTime));
    }

    const { LocalTime } = dateInfo.toJS();
    const { prevLocalTime } = prevDateInfo.toJS();
    if (LocalTime != null && !JSONCompare(LocalTime, prevLocalTime)) {
      this.onUpdate({
        today: new Date(LocalTime.replace(' ', 'T')),
      });
    }
  }

  setCalendarDate() {
    const {
      calendarSearchDate,
    } = this.props;
    // Calendar 에 활성화 시킬 날짜를 넣어주도록 하는 Parameter

    this.setState({
      includeDates: calendarSearchDate,
    });
  }

  setSelectedDate = (date = null) => {
    const {
      searchDateObj,
    } = this.props;

    if (date !== null) {
      // 선택할 날짜를 넣었을 경우
      return this.setState({
        selectedDate: new Date(date),
      });
    }

    if (searchDateObj === null) {
      return this.setState({
        selectedDate: null,
      });
    }
    const selectedDate = new Date(searchDateObj.year, searchDateObj.month - 1, searchDateObj.day);

    return this.setState({
      selectedDate,
    });
  }

  onDateChange = date => {
    // console.log('???', date);
    EventSearchActions.setSearchDate(date);
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  );

  render() {
    const {
      render,
    } = this.props;

    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

BasicDatePickerContainer.propTypes = {
  render: PropTypes.func.isRequired,
  searchDateObj: PropTypes.oneOfType([PropTypes.any]),
  calenderSearchResults: PropTypes.oneOfType([PropTypes.any]),
  calendarSearchDate: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  currentChannel: PropTypes.number,
  instantPlaybackSeeking: PropTypes.instanceOf(Object),
  dateInfo: PropTypes.instanceOf(Map).isRequired,
};

BasicDatePickerContainer.defaultProps = {
  calenderSearchResults: [],
  calendarSearchDate: [],
  searchDateObj: null,
  currentChannel: -1,
  instantPlaybackSeeking: {},
};

export default connect(
  state => ({
    lang: state.langModule.get('lang'),
    searchDateObj: state.eventSearchModule.get('searchDateObj'),
    calenderSearchResults: state.eventSearchModule.get('calenderSearchResults'),
    calendarSearchDate: state.eventSearchModule.get('calendarSearchDate'),
    currentChannel: state.searchTimelineModule.get('currentChannel'),
    instantPlaybackSeeking: state.searchTimelineModule.get('instantPlaybackSeeking'),
    dateInfo: state.deviceInfoModule.get('dateInfo'),
  }),
)(BasicDatePickerContainer);
