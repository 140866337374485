import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PHPSetupPageStyled } from './PHPSetupPageStyled';

class PHPSetupPage extends PureComponent {
  render() {
    const {
      phpSetupPage,
    } = this.props;

    return (
      <PHPSetupPageStyled src={phpSetupPage} />
    );
  }
}

PHPSetupPage.propTypes = {
  phpSetupPage: PropTypes.oneOfType([PropTypes.any, PropTypes.string]).isRequired,
};

export default PHPSetupPage;
