import React from 'react';
import PropTypes from 'prop-types';
import ToastBoxStyled from './ToastBoxStyled';

const ToastBox = ({ children, ...rest }) => (
  <ToastBoxStyled {...rest}>
    {children}
  </ToastBoxStyled>
);

ToastBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

ToastBox.defaultProps = {
  children: '',
};

export default ToastBox;
