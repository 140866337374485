const eventUpdateWorker = () => {
  const beforeDeviceEvent = {
    alarmInput: {},
    alarmOutput: {},
    audioOutput: {},
    systemEvent: {},
  };

  let beforePosEventLength = 0;
  let beforeEventText = null;
  let currentConfigChangeJSONDataList = [];

  onmessage = receiveData => {
    const {
      type,
      messageData,
    } = receiveData.data;
    switch (type) {
      case 'deviceEvent': {
        eventUpdateWorker.checkDeviceSystemEvent(messageData);
        break;
      }
      case 'posEvent': {
        eventUpdateWorker.checkPosEvent(messageData);
        break;
      }
      case 'cameraEvent': {
        eventUpdateWorker.checkCameraEvent(messageData);
        break;
      }
      default:
        break;
    }
  };

  eventUpdateWorker.checkDeviceSystemEvent = deviceEvent => {
    const {
      alarmInput,
      alarmOutput,
      audioOutput,
      systemEvent,
    } = deviceEvent;
    const beforeEvent = beforeDeviceEvent;

    const convertSystemEvent = eventUpdateWorker.convertSystemEvent(systemEvent);

    const alarmInputChange = (
      JSON.stringify(beforeEvent.alarmInput) !== JSON.stringify(alarmInput)
    );
    const alarmOutputChange = (
      JSON.stringify(beforeEvent.alarmOutput) !== JSON.stringify(alarmOutput)
    );
    const audioOutputChange = (
      JSON.stringify(beforeEvent.audioOutput) !== JSON.stringify(audioOutput)
    );
    const systemEventChange = (
      JSON.stringify(beforeEvent.systemEvent) !== JSON.stringify(convertSystemEvent)
    );

    beforeEvent.alarmInput = alarmInputChange ? alarmInput : beforeEvent.alarmInput;
    beforeEvent.alarmOutput = alarmOutputChange ? alarmOutput : beforeEvent.alarmOutput;
    beforeEvent.audioOutput = audioOutputChange ? audioOutput : beforeEvent.audioOutput;
    beforeEvent.systemEvent = systemEventChange ? convertSystemEvent : beforeEvent.systemEvent;

    postMessage({
      type: 'deviceEvent',
      postData: {
        alarmInput: alarmInputChange ? alarmInput : null,
        alarmOutput: alarmOutputChange ? alarmOutput : null,
        audioOutput: audioOutputChange ? audioOutput : null,
        systemEvent: systemEventChange ? convertSystemEvent : null,
      },
    });
  };

  eventUpdateWorker.convertSystemEvent = systemEvent => ({
    AMDLoadFail: systemEvent.AMDLoadFail,
    adminLogin: systemEvent.AdminLogin,
    alarmReset: systemEvent.AlarmReset,
    backup: systemEvent.Backup,
    batteryFail: systemEvent.BatteryFail,
    beingUpdate: systemEvent.BeingUpdate,
    // configChange: systemEvent.ConfigChange, 현재는 의미 없음
    configRestore: systemEvent.ConfigRestore,
    dspDisplayStart: systemEvent.DSPDisplayStart,
    dspVASystemStart: systemEvent.DSPVASystemStart,
    endofFrame: systemEvent.EndofFrame,
    fwUpdate: systemEvent.FWUpdate,
    factoryReset: systemEvent.FactoryReset,
    hddFail: systemEvent.HDDFail,
    hddFull: systemEvent.HDDFull,
    hddNone: systemEvent.HDDNone,
    internalHDDConnect: systemEvent.InternalHDDConnect,
    internalHDDErase: systemEvent.InternalHDDErase,
    leftFanError: systemEvent.LeftFanError,
    netCamTrafficOverFlow: systemEvent.NetCamTrafficOverFlow,
    network: systemEvent.Network,
    newFWAvailable: systemEvent.NewFWAvailable,
    overwriteDecoding: systemEvent.OverwriteDecoding,
    passwordChange: systemEvent.PasswordChange,
    powerOn: systemEvent.PowerOn,
    powerReboot: systemEvent.PowerReboot,
    hddRaidDeviceAdd: systemEvent.RAIDDeviceAdd,
    hddRaidEvents: eventUpdateWorker.convertSystemEventRAIDEvents(systemEvent.RAIDEvents),
    hddRaidRecordRestriction: systemEvent.RAIDRecordRestriction,
    recordFiltering: systemEvent.RecordFiltering,
    recordFrameDrop: systemEvent.RecordFrameDrop,
    recording: systemEvent.Recording,
    recordingError: systemEvent.RecordingError,
    rightFanError: systemEvent.RightFanError,
    timeChange: systemEvent.TimeChange,
    usbHDDConnect: systemEvent.USBHDDConnect,
    iSCSIDisconnect: systemEvent.iSCSIDisconnect,
    frameFanError: systemEvent.FrameFanError,
    CPUFanError: systemEvent.CPUFanError,
    dualSMPSError: systemEvent.DualSMPSFail,
  });

  eventUpdateWorker.convertSystemEventRAIDEvents = raidEvents => (
    raidEvents && raidEvents.map(raidEvent => ({
      hddRaid: raidEvent.RAID,
      hddRaidBuildCancel: raidEvent.RAIDBuildCancel,
      hddRaidBuildFail: raidEvent.RAIDBuildFail,
      hddRaidBuilding: raidEvent.RAIDBuilding,
      hddRaidDegrade: raidEvent.RAIDDegrade,
      hddRaidEnable: raidEvent.RAIDEnable,
      hddRaidFail: raidEvent.RAIDFail,
      hddRaidRebuildEnd: raidEvent.RAIDRebuildEnd,
      hddRaidRebuildFail: raidEvent.RAIDRebuildFail,
      hddRaidRebuildStart: raidEvent.RAIDRebuildStart,
      hddRaidSetup: raidEvent.RAIDSetup,
    }))
  );

  eventUpdateWorker.checkPosEvent = checkPosEvent => {
    const { responseText } = checkPosEvent;
    const removeTrim = responseText.replace(/\n/gi, '').replace(/\t/gi, '').replace(/\r/gi, ''); // 2개 같이 선언시 안먹는다.
    const makeJSONDataList = removeTrim.match(/\{(.*?)\}/g);

    if (makeJSONDataList) {
      const posEventChange = beforePosEventLength !== makeJSONDataList.length;
      beforePosEventLength = posEventChange ? makeJSONDataList.length : beforePosEventLength;

      if (posEventChange) {
        const listJSONData = makeJSONDataList[makeJSONDataList.length - 1];
        if (!listJSONData.match(/Fail/)) {
          const convertChangePosEvent = JSON.parse(listJSONData);
          postMessage({
            type: 'posEvent',
            postData: {
              posEvent: {
                deviceID: convertChangePosEvent.DeviceID,
                receipt: convertChangePosEvent.Receipt,
                receivedDate: convertChangePosEvent.ReceivedDate,
              },
            },
          });
        }
      }
    }
  };

  eventUpdateWorker.checkCameraEvent = checkCameraEvent => {
    const { responseText, isFirstAction } = checkCameraEvent;
    const removeTrim = responseText.replace(beforeEventText, '').replace(/\n/gi, '').replace(/\t/gi, '').replace(/\r/gi, '');
    const removeHeader = removeTrim.replace(/--SamsungTechwinContent-type:application\/json/g, '\n');
    const makeJSONDataList = removeHeader.match(/\{(.*)\}/g);
    let changeData;
    beforeEventText = responseText;
    if (makeJSONDataList) {
      console.log('makeJSONDataList', makeJSONDataList);
      if (isFirstAction) {
        postMessage({
          type: 'loadData',
          postData: JSON.parse(makeJSONDataList[0]),
        });
      } else if (makeJSONDataList[0] !== '{"SystemEvent":{"ConfigChange":false}}') {
        // ...(스프레드 연산이)이 안된다...
        for (let i = 0; i < makeJSONDataList.length; i += 1) {
          currentConfigChangeJSONDataList.push(makeJSONDataList[i]);
        }
      } else if (makeJSONDataList.find(data => data.includes('SystemEvent'))) {
        const currentConfigList = eventUpdateWorker
          .removeSameEvent(currentConfigChangeJSONDataList);
        currentConfigChangeJSONDataList = [];
        // data 구분을 아직 많이 하지 않음.
        currentConfigList.some(data => {
          changeData = eventUpdateWorker.changeCheckCameraEvent(data);
          return changeData;
        });

        if (changeData) {
          postMessage(changeData);
        }
      } else {
        // channel에 관련된 걸 sunapi 요청하지 않고 반영하는 코드 작성 필요
      }
    }
  };

  eventUpdateWorker.removeSameEvent = currentConfigChaneJSONDataList => (
    Array.from(new Set(currentConfigChaneJSONDataList))
  );

  eventUpdateWorker.changeCheckCameraEvent = lastJSONData => {
    const convertData = JSON.parse(lastJSONData);

    // 분기 추가 필요
    const { ChannelEvent } = convertData;
    return ChannelEvent ? {
      type: 'cameraEvent',
      postData: ChannelEvent,
    } : undefined;
  };
};

export default eventUpdateWorker;
