import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class HeaderContainer extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false;
    }
    return true;
  }

  render() {
    const { render } = this.props;
    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

HeaderContainer.propTypes = {
  render: PropTypes.func.isRequired,
};

export default withRouter(connect(
  state => ({
    usergroupInfo: state.systemInfomationModule.get('usergroupInfo').toJS(),
    accessInfo: state.systemInfomationModule.get('accessInfo').toJS(),
    isAdmin: state.loginModule.get('isAdmin'),
  }),
)(HeaderContainer));
