import styled from 'styled-components';
import GridLayout from 'react-grid-layout';

export const VideoStaticLayoutWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const GridLayoutStyled = styled(GridLayout)`
  position: absolute;
  width: ${props => props.width}px;
  border-right: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
`;

export const DragItemStyled = styled.div`
  display: flex;
  width: ${props => props.width}px !important;
`;

export const DragItemEmptyStyled = styled.div`
  width: 100%;
  border-left: 1px solid #c9c9c9;
  border-top: 1px solid #c9c9c9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:52px;
  & > i {
    font-size: 160px;
    height: 40px;
    overflow: hidden;
    width: 160px;
    color: #333;
  }
`;
