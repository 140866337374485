import React from 'react';
import PropTypes from 'prop-types';

const minZoom = 1;
const maxZoom = 32;

class TileDigitalZoomControlContainer extends React.Component {
  state = { isSetRef: false };

  componentDidMount() {
    const {
      uid,
      digitalZoom,
      eventPos,
      width,
      height,
      setDigitalZoomInOut,
    } = this.props;
    this.left = 0;
    this.top = 0;
    this.zoom = 1;
    this.targetElement = document.getElementById(`live-ump-player-${uid}-Live`);

    if (eventPos) {
      const prevPos = this.calcPositionRatio(eventPos);
      this.zoom = digitalZoom;
      const curPos = this.calcPositionRatio(eventPos);

      this.left += Math.round((curPos.ratioX - prevPos.ratioX) * this.zoom * width);
      this.top += Math.round((curPos.ratioY - prevPos.ratioY) * this.zoom * height);
    } else {
      this.zoom = 1.1;
      setDigitalZoomInOut({
        digitalZoom: this.zoom,
      });
    }
    this.calcLimit();
    this.adjustLimit();
    this.updateMiniMapBox();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      width, height, mouseIn, rotate, aspectRatio, digitalZoom, eventPos, setDZoomMode,
    } = this.props;
    const { isSetRef } = this.state;
    if ((prevProps.digitalZoom !== digitalZoom) && eventPos) {
      const prevPos = this.calcPositionRatio(eventPos);
      this.zoom = digitalZoom;
      const curPos = this.calcPositionRatio(eventPos);

      this.left += Math.round((curPos.ratioX - prevPos.ratioX) * this.zoom * width);
      this.top += Math.round((curPos.ratioY - prevPos.ratioY) * this.zoom * height);

      this.calcLimit();
      this.adjustLimit();
      this.updateMiniMapBox();

      if (digitalZoom === minZoom) setDZoomMode(false);
    }
    if (!prevState.isSetRef && isSetRef) {
      this.updateMiniMap();
    }
    if (prevProps.width !== width || prevProps.height !== height) {
      this.adjustLimit();
    }
    if (mouseIn) {
      this.updateMiniMap();
    } else {
      this.stopCapture();
    }
    if (prevProps.rotate !== rotate || prevProps.aspectRatio !== aspectRatio) {
      this.stopCapture();
      this.startCapture();
    }
  }

  componentWillUnmount() {
    const { setDigitalZoomInOut } = this.props;
    setDigitalZoomInOut({
      digitalZoom: 1,
    });
    this.targetElement.style.left = '0px';
    this.targetElement.style.top = '0px';
    this.stopCapture();
  }

  setRef = (type, ref) => {
    this[type] = ref;
    if (type === 'mapContainer') {
      this.setState({ isSetRef: ref !== null });
    }
  }

  updateMiniMap = () => {
    const { width, height } = this.props;
    this.mapContainer.style.width = `${width * 0.25}px`;
    this.mapContainer.style.height = `${height * 0.25}px`;

    this.startCapture();
    this.updateMiniMapBox();
  }

  startCapture = () => {
    if (!this.captureInterval) {
      this.captureImage();
      this.captureInterval = setInterval(() => {
        this.captureImage();
      }, 1000);
    }
  }

  stopCapture = () => {
    clearInterval(this.captureInterval);
    this.captureInterval = null;
  }

  captureImage = () => {
    if (!this.targetElement || !this.mapContainer) return;
    const { rotate, aspectRatio } = this.props;
    let width = this.mapContainer.clientWidth;
    let height = this.mapContainer.clientHeight;

    this.mapCanvas.width = width;
    this.mapCanvas.height = height;

    const ctx = this.mapCanvas.getContext('2d');
    ctx.translate(width / 2, height / 2);
    ctx.rotate(rotate * Math.PI / 180);
    if (rotate % 180 !== 0) {
      width = this.mapContainer.clientHeight;
      height = this.mapContainer.clientWidth;
    }
    ctx.translate(-width / 2, -height / 2);

    if (aspectRatio) {
      const targetWidth = this.targetElement.videoWidth || this.targetElement.width;
      const targetHeight = this.targetElement.videoHeight || this.targetElement.height;
      const hRatio = width / targetWidth;
      const vRatio = height / targetHeight;
      const ratio = Math.min(hRatio, vRatio);
      const centerShiftX = (width - targetWidth * ratio) / 2;
      const centerShiftY = (height - targetHeight * ratio) / 2;
      ctx.drawImage(this.targetElement, 0, 0, targetWidth, targetHeight,
        centerShiftX, centerShiftY, targetWidth * ratio, targetHeight * ratio);
    } else {
      ctx.drawImage(this.targetElement, 0, 0, width, height);
    }
  }

  updateMiniMapBox = () => {
    if (!this.mapBox) return;
    const posRatio = this.calcPositionRatio({
      offsetX: 0,
      offsetY: 0,
    });

    this.mapBox.style.left = `${Math.round(posRatio.ratioX * (this.mapContainer.clientWidth))}px`;
    this.mapBox.style.top = `${Math.round(posRatio.ratioY * (this.mapContainer.clientHeight))}px`;
    this.mapBox.style.width = `${Math.round((1 / this.zoom) * this.mapContainer.clientWidth)}px`;
    this.mapBox.style.height = `${Math.round((1 / this.zoom) * this.mapContainer.clientHeight)}px`;
  }

  calcLimit = () => {
    const { width, height } = this.props;
    this.leftLimit = Math.round((width * (this.zoom - 1)) / 2);
    this.topLimit = Math.round((height * (this.zoom - 1)) / 2);

    // setDigitalZoomInOut({
    //   digitalZoom: this.zoom,
    // });
  }

  calcPositionRatio = pos => {
    const {
      width,
      height,
    } = this.props;
    let left = 0;
    let top = 0;
    left -= (parseInt(this.left, 10) || 0);
    top -= (parseInt(this.top, 10) || 0);
    const halfWidth = width / 2;
    const halfHeight = height / 2;
    const x = (((pos.offsetX + left - halfWidth) / this.zoom) + halfWidth) / (2 * halfWidth);
    const y = (((pos.offsetY + top - halfHeight) / this.zoom) + halfHeight) / (2 * halfHeight);

    return {
      ratioX: x,
      ratioY: y,
    };
  }

  adjustLimit = () => {
    if (this.left > this.leftLimit) {
      this.left = this.leftLimit;
    } else if (this.left < -this.leftLimit) {
      this.left = -this.leftLimit;
    }

    if (this.top > this.topLimit) {
      this.top = this.topLimit;
    } else if (this.top < -this.topLimit) {
      this.top = -this.topLimit;
    }

    this.targetElement.style.left = `${this.left}px`;
    this.targetElement.style.top = `${this.top}px`;
  }

  onTileDZoomControlEvent = type => event => {
    if (type !== 'leave') {
      event.stopPropagation();
    }
    if (type === 'move' && !this.downCheck) return;
    if (event.button === 2) return;

    switch (type) {
      case 'down': {
        const { setOpenTileBar } = this.props;
        setOpenTileBar(false);
        this.downCheck = true;
        this.curX = event.clientX;
        this.curY = event.clientY;
        break;
      }
      case 'move': {
        if (this.downCheck) {
          const moveX = this.curX - event.clientX;
          const moveY = this.curY - event.clientY;

          this.curX = event.clientX;
          this.curY = event.clientY;

          this.left -= moveX;
          this.top -= moveY;

          this.adjustLimit();
          this.updateMiniMapBox();
        }
        break;
      }
      case 'leave':
      case 'up': {
        if (type === 'up') {
          const { setOpenTileBar } = this.props;
          setOpenTileBar(true);
        }
        this.downCheck = false;
        break;
      }
      case 'wheel': {
        const {
          width,
          height,
        } = this.props;
        const rate = event.deltaY < 0 ? 1.1 : 0.9;
        let result = Math.round(this.zoom * rate * 1e2) / 1e2;
        if (rate > 1 && result >= maxZoom) result = maxZoom;
        if (rate < 1 && result <= minZoom) result = minZoom;
        if (result === this.zoom) return;
        if (result === minZoom) {
          const { setDZoomMode } = this.props;
          setDZoomMode(false);
        }

        const prevPos = this.calcPositionRatio(event.nativeEvent);
        this.zoom = result;
        const curPos = this.calcPositionRatio(event.nativeEvent);

        this.left += Math.round((curPos.ratioX - prevPos.ratioX) * this.zoom * width);
        this.top += Math.round((curPos.ratioY - prevPos.ratioY) * this.zoom * height);

        this.calcLimit();
        this.adjustLimit();
        this.updateMiniMapBox();
        break;
      }
      default:
        break;
    }
  }

  /* For PIP Control (Not use)
  onMiniMapControlEvent = type => event => {
    event.stopPropagation();
    if (type === 'move' && !this.downCheck) return;
    if (event.button === 2) return;

    switch (type) {
      case 'down': {
        this.downCheck = true;
        break;
      }
      case 'move': {
        if (this.downCheck) {
          console.log('+++move');
        }
        break;
      }
      case 'leave':
      case 'up': {
        this.downCheck = false;
        break;
      }
      default:
        break;
    }
  }
  */
  render() {
    const { render } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}
TileDigitalZoomControlContainer.defaultProps = {
  rotate: 0,
  setDigitalZoomInOut: () => {},
  eventPos: null,
};
TileDigitalZoomControlContainer.propTypes = {
  render: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  setDZoomMode: PropTypes.func.isRequired,
  setOpenTileBar: PropTypes.func.isRequired,
  mouseIn: PropTypes.bool.isRequired,
  rotate: PropTypes.number,
  aspectRatio: PropTypes.bool.isRequired,
  setDigitalZoomInOut: PropTypes.func,
  digitalZoom: PropTypes.number.isRequired,
  eventPos: PropTypes.instanceOf(Object),
};

export default TileDigitalZoomControlContainer;
