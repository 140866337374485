import styled, { withTheme, css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import {
  Label,
  Span,
  Checkbox,
  Button,
} from 'wisenet-ui/components/atoms';

const TreeStyled = withTheme(styled.div`
  height: 100%;

  ul li {
    background-color: ${props => props.theme.background.color1};
    color: ${props => props.theme.text.color2};
  }
  
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    user-select: none;
    &:active{
      color: #f37321;
      cursor: 'pointer';
    }
    &:hover{
      opacity:.75;
      cursor:'pointer';
    }
  }
`);

const TreeTitleStyled = styled(Label)`
  width: 100%;
`;

const TreeIconSpanStyled = styled(Span)`
  margin-left : 5px;
`;

const TreeSpanStyled = styled(Span)`
  margin-left : 5px;
`;

const TreeExtraSpanStyled = withTheme(styled.div`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: #797979;
  color: #ffffff;
  margin-left: 6px;
  padding-top: 3px;
  ${props => props.isEtc && css`
    padding-top: 0px;
  `}
`);

const TreeCheckboxStyled = styled(Checkbox)`
  width: 100%;
  margin-right: 10px;
`;

const TreeButtonStyled = styled(Button)`
  width: 25px !important;
  height: 25px !important;
  line-height: 25px !important;
  padding: 0px !important; 
  border: 0px !important;
  display: inline-block !important;
`;

const IconCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: ${props => props.color};
`;

const IconSquare = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${props => props.color};
`;

const TooltipSpan = styled(Span)`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  margin: 0px;
  padding: 0px;
  white-space: pre;
`;

const CustomReactTooltip = withTheme(styled(ReactTooltip)`
  position: fixed;
  background-color: #555 !important;
  padding: 5px !important;
  &.place-top {
    &:after {
      border-top: none !important;
    }
  }
  &.place-left {
    &:after {
      border-left: 6px solid #555 !important;
    }
  }
  &.place-right {
    &:after {
      border-right: 6px solid #555 !important;
    }
  }
`);

export {
  TreeStyled,
  TreeTitleStyled,
  TreeIconSpanStyled,
  TreeSpanStyled,
  TreeExtraSpanStyled,
  TreeCheckboxStyled,
  TreeButtonStyled,
  IconCircle,
  IconSquare,
  TooltipSpan,
  CustomReactTooltip,
};
