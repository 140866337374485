import styled, { css } from 'styled-components';
import { IconButton } from 'wisenet-ui/components/atoms';
import { Toast } from 'wisenet-ui/components/organisms';
import { Loading } from 'wisenet-ui/components/molecules';

export const LiveVideoTileStyled = styled.div`
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
  position: absolute;

  &::before {
    content: ' ';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid;
    ${props => props.needRightBold && css`
      border-right: 3px solid;
    `}
    box-sizing: border-box;
    z-index: 1;
    pointer-events: none;
  }

  &:hover::before {
    border-color: #fbd5bc;
  }

  ${props => props.isSelectTile && css`
    &::before,
    &:hover::before {
      border-color: #f37321;
    }
  `}
`;

export const LiveVideoTileStatusStyled = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  color:#fff;
  font-size:52px;
`;

export const TileCloseWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 9px;
  top: 5px;
`;

export const CloseIconStyled = styled(IconButton)`
  padding: 0;
  font-size: 20px;
  color: #fff;
  text-shadow: 0px 0px 2px #000, 0px 0px 2px rgba(0, 0, 0, 0.8), 0px 0px 2px #000;
  &:hover {
    color: #f37321;
    text-shadow: 0px 0px 2px #f37321, 0px 0px 2px rgba(0, 0, 0, 0.8), 0px 0px 2px #f37321;
  }
`;

export const VerticalLineStyled = styled.div`
  width: 1px;
  height: 16px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 1px #000, 0px 0px 1px rgba(0, 0, 0, 0.8), 0px 0px 1px #000;
  margin: 0 8px;
`;

export const LiveVideoTileTostStyled = styled(Toast)`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
`;

export const LoadingStyled = styled(Loading)`
  position: initial;
`;
