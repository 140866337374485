export default {
  SPECIAL: {
    rows: 1,
    cols: 1,
    items: [
      // { w: 1, h: 1, x: 0, y: 0 },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
      },
    ],
  },
  '4_2X2': {
    rows: 2,
    cols: 2,
    items: [
      // { w: 1, h: 1, x: 0, y: 0 },
      // { w: 1, h: 1, x: 1, y: 0 },
      // { w: 1, h: 1, x: 0, y: 1 },
      // { w: 1, h: 1, x: 1, y: 1 },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 0,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 1,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 1,
      },
    ],
  },
  '4_3X3': {
    rows: 3,
    cols: 3,
    items: [
      // { w: 1, h: 1, x: 0, y: 0 },
      // { w: 1, h: 1, x: 1, y: 0 },
      // { w: 1, h: 1, x: 2, y: 0 },
      // { w: 1, h: 1, x: 0, y: 1 },
      // { w: 1, h: 1, x: 1, y: 1 },
      // { w: 1, h: 1, x: 2, y: 1 },
      // { w: 1, h: 1, x: 0, y: 2 },
      // { w: 1, h: 1, x: 1, y: 2 },
      // { w: 1, h: 1, x: 2, y: 2 },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 0,
      },
      {
        w: 1,
        h: 1,
        x: 2,
        y: 0,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 1,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 1,
      },
      {
        w: 1,
        h: 1,
        x: 2,
        y: 1,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 2,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 2,
      },
      {
        w: 1,
        h: 1,
        x: 2,
        y: 2,
      },
    ],
  },
  '4_1+5': {
    rows: 3,
    cols: 3,
    items: [
      // { w: 2, h: 2, x: 0, y: 0 },
      // { w: 1, h: 1, x: 2, y: 0 },
      // { w: 1, h: 1, x: 2, y: 1 },
      // { w: 1, h: 1, x: 0, y: 2 },
      // { w: 1, h: 1, x: 1, y: 2 },
      // { w: 1, h: 1, x: 2, y: 2 },
      {
        w: 2,
        h: 2,
        x: 0,
        y: 0,
      },
      {
        w: 1,
        h: 1,
        x: 2,
        y: 0,
      },
      {
        w: 1,
        h: 1,
        x: 2,
        y: 1,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 2,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 2,
      },
      {
        w: 1,
        h: 1,
        x: 2,
        y: 2,
      },
    ],
  },
  '4_1+7': {
    rows: 4,
    cols: 4,
    items: [
      // { w: 3, h: 3, x: 0, y: 0 },
      // { w: 1, h: 1, x: 3, y: 0 },
      // { w: 1, h: 1, x: 3, y: 1 },
      // { w: 1, h: 1, x: 3, y: 2 },
      // { w: 1, h: 1, x: 0, y: 3 },
      // { w: 1, h: 1, x: 1, y: 3 },
      // { w: 1, h: 1, x: 2, y: 3 },
      // { w: 1, h: 1, x: 3, y: 3 },
      {
        w: 3,
        h: 3,
        x: 0,
        y: 0,
      },
      {
        w: 1,
        h: 1,
        x: 3,
        y: 0,
      },
      {
        w: 1,
        h: 1,
        x: 3,
        y: 1,
      },
      {
        w: 1,
        h: 1,
        x: 3,
        y: 2,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 3,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 3,
      },
      {
        w: 1,
        h: 1,
        x: 2,
        y: 3,
      },
      {
        w: 1,
        h: 1,
        x: 3,
        y: 3,
      },
    ],
  },
  '16_1X2': {
    rows: 2,
    cols: 1,
    items: [
      // { w: 1, h: 1, x: 0, y: 0 },
      // { w: 1, h: 1, x: 0, y: 1 },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 1,
      },
    ],
  },
  '16_2X1': {
    rows: 1,
    cols: 2,
    items: [
      // { w: 1, h: 1, x: 0, y: 0 },
      // { w: 1, h: 1, x: 1, y: 0 },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 0,
      },
    ],
  },
  '16_1X3': {
    rows: 1,
    cols: 3,
    items: [
      // { w: 1, h: 1, x: 0, y: 0 },
      // { w: 1, h: 1, x: 1, y: 0 },
      // { w: 1, h: 1, x: 2, y: 0 },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 0,
      },
      {
        w: 1,
        h: 1,
        x: 2,
        y: 0,
      },
    ],
  },
  '16_3X2': {
    rows: 2,
    cols: 3,
    items: [
      // { w: 1, h: 1, x: 0, y: 0 },
      // { w: 1, h: 1, x: 1, y: 0 },
      // { w: 1, h: 1, x: 2, y: 0 },
      // { w: 1, h: 1, x: 0, y: 1 },
      // { w: 1, h: 1, x: 1, y: 1 },
      // { w: 1, h: 1, x: 2, y: 1 },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 0,
      },
      {
        w: 1,
        h: 1,
        x: 2,
        y: 0,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 1,
      },
      {
        w: 1,
        h: 1,
        x: 1,
        y: 1,
      },
      {
        w: 1,
        h: 1,
        x: 2,
        y: 1,
      },
    ],
  },
};
