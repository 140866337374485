import {
  take,
  all,
  put,
  actionChannel,
  call,
  select,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { SunapiClient, getLanguage } from 'util/lib';
import * as textSearchActions from 'store/modules/textSearch/textSearchModule';
import * as loadingActions from 'store/modules/base/loadingModule';
import { setPosEvent } from 'store/modules/system/systemInfomationModule';
import * as messageBoxActions from 'store/modules/base/messageBoxModule';

// url
const CONTROL_METADATA_URL = '/stw-cgi/recording.cgi?msubmenu=metadata&action=control';
const GET_METADATA_URL = '/stw-cgi/recording.cgi?msubmenu=metadata&action=view';
const GET_POS_EVENT_CONFIG_URL = '/stw-cgi/recording.cgi?msubmenu=poseventconf&action=view';
const GET_POS_CONFIG_URL = '/stw-cgi/recording.cgi?msubmenu=posconf&action=view';
const GET_OVERLAPPED_ID_LIST_URL = '/stw-cgi/recording.cgi?msubmenu=overlapped&action=view';

const getSearchTime = (channel, { startDate, endDate }) => new Promise(resolve => {
  const s = new Date(startDate);
  let fromDate;
  let toDate;

  if (endDate) {
    fromDate = startDate;
    toDate = endDate;
  } else {
    fromDate = new Date(s.getFullYear(), s.getMonth(), s.getDate(), 0, 0, 0);
    toDate = new Date(s.getFullYear(), s.getMonth(), s.getDate(), 23, 59, 59);
  }
  const pad = (num, width) => {
    const n = num.toString();
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  };
  const toTimeString = date => `${date.getFullYear()}-${pad(date.getMonth() + 1, 2)}-${pad(date.getDate(), 2)}%20${pad(date.getHours(), 2)}:${pad(date.getMinutes(), 2)}:${pad(date.getSeconds(), 2)}`;

  const params = {
    FromDate: toTimeString(fromDate),
    ToDate: toTimeString(toDate),
  };

  const resultParams = channel.length != null && channel.length !== 0 ? {
    ...params,
    ChannelIDList: channel.join(','),
  } : params;

  resolve(resultParams);
});

function* getOverlappedIDList() {
  const textSearchState = yield select(state => state.textSearchModule);
  const {
    currentPOSDeviceList,
    searchStartDateObj,
    searchEndDateObj,
  } = textSearchState.toJS();

  const {
    year: sYear,
    month: sMon,
    day: sDay,
    hour: sHour,
    minute: sMin,
    second: sSec,
  } = searchStartDateObj;

  const {
    year: eYear,
    month: eMon,
    day: eDay,
    hour: eHour,
    minute: eMin,
    second: eSec,
  } = searchEndDateObj;

  const searchDate = yield call(getSearchTime, currentPOSDeviceList, {
    startDate: new Date(sYear, sMon - 1, sDay, sHour, sMin, sSec),
    endDate: new Date(eYear, eMon - 1, eDay, eHour, eMin, eSec),
  });

  try {
    const promise = yield call([SunapiClient, 'get'], GET_OVERLAPPED_ID_LIST_URL, searchDate);

    if (promise.status === 200 && typeof promise.data.Error === 'undefined') {
      yield put(textSearchActions.getOverlappedIdListSuccess(promise.data.OverlappedIDList));
    } else {
      yield put(textSearchActions.getOverlappedIdListFailure());
    }
  } catch (_) {
    yield put(textSearchActions.getOverlappedIdListFailure());
  }
}

function* getPOSConfig() {
  try {
    const promise = yield call([SunapiClient, 'get'], GET_POS_CONFIG_URL);

    if (promise.status === 200 && typeof promise.data.Error === 'undefined') {
      yield put(textSearchActions.getPOSConfigSuccess(promise.data.POSDevices));
    } else {
      yield put(textSearchActions.getPOSConfigFailure());
    }
  } catch (_) {
    yield put(textSearchActions.getPOSConfigFailure());
  }
}

function* getPOSEventConfig() {
  try {
    const promise = yield call([SunapiClient, 'get'], GET_POS_EVENT_CONFIG_URL);

    if (promise.status === 200 && typeof promise.data.Error === 'undefined') {
      const { data } = promise;
      if (typeof data.Keywords === 'undefined') {
        Object.assign(data, { Keywords: [] });
      }
      yield put(textSearchActions.getPOSEventConfigSuccess(promise.data));
    } else {
      yield put(textSearchActions.getPOSEventConfigFailure());
    }
  } catch (_) {
    yield put(textSearchActions.getPOSEventConfigFailure());
  }
}

function* controlMetaDataStart() {
  try {
    const tsModuleState = yield select(state => state.textSearchModule);
    const {
      searchStartDateObj,
      searchEndDateObj,
      isWholeWord,
      isCaseSensitive,
      keyword,
      eventKeywordCheckStatus,
      currentPOSDeviceList,
      overlappedIDList,
      selectedOverlappedIDIndex,
    } = tsModuleState.toJS();

    const {
      year: sYear,
      month: sMon,
      day: sDay,
      hour: sHour,
      minute: sMin,
      second: sSec,
    } = searchStartDateObj;

    const {
      year: eYear,
      month: eMon,
      day: eDay,
      hour: eHour,
      minute: eMin,
      second: eSec,
    } = searchEndDateObj;

    const searchDate = yield call(getSearchTime, currentPOSDeviceList, {
      startDate: new Date(sYear, sMon - 1, sDay, sHour, sMin, sSec),
      endDate: new Date(eYear, eMon - 1, eDay, eHour, eMin, eSec),
    });

    const params = {
      Mode: 'Start',
      MetadataType: 'POS',
      FromDate: searchDate.FromDate,
      ToDate: searchDate.ToDate,
      IsWholeWord: isWholeWord,
      IsCaseSensitive: isCaseSensitive,
    };

    if (currentPOSDeviceList.length > 0) {
      params.DeviceIDList = currentPOSDeviceList.join(',');
    }

    let keywordString = '';
    if (eventKeywordCheckStatus.length > 0) {
      eventKeywordCheckStatus.map(item => {
        if (item.isChecked) {
          keywordString = `${keywordString}${item.condition} `;
        }
        return keywordString;
      });
    }

    if (keywordString === '') {
      if (keyword !== '') {
        params.Keyword = encodeURI(keyword);
      }
    } else {
      params.Keyword = keywordString.substring(0, keywordString.length - 1);
      params.Keyword = encodeURI(params.Keyword);
    }

    if (overlappedIDList.length > 0 && overlappedIDList[0] !== 0) {
      params.OverlappedID = overlappedIDList[selectedOverlappedIDIndex];
    }

    const promise = yield call([SunapiClient, 'get'], CONTROL_METADATA_URL, params);

    if (promise.status === 200 && typeof promise.data.Error === 'undefined') {
      yield put(textSearchActions.controlMetaDataStartSuccess(promise.data.SearchToken));
      yield put(textSearchActions.requestTextSearch({
        requestType: 'GET_METADATA',
        Mode: 'Status',
      }));
    } else if (promise.data.Error && promise.data.Error.Code === 702) {
      console.log('ERROR', 'controlMetaDataStart', promise.data.Error);
      yield put(loadingActions.setPagePendding({
        pagePendding: false,
      }));
      yield put(messageBoxActions.controlMessageBox({
        messageBoxInfo: {
          title: getLanguage('lang_error'),
          content: getLanguage('lang_menu_currently_used'), // 'search token 발행 실패',
          isOpen: true,
        },
      }));
      yield put(textSearchActions.controlMetaDataStartFailure());
    } else {
      console.log('ERROR', 'controlMetaDataStart', promise.data.Error);
      yield put(loadingActions.setPagePendding({
        pagePendding: false,
      }));
      yield put(messageBoxActions.controlMessageBox({
        messageBoxInfo: {
          title: getLanguage('lang_error'),
          content: 'search token 발행 실패',
          isOpen: true,
        },
      }));
      yield put(textSearchActions.controlMetaDataStartFailure());
    }
  } catch (_) {
    console.log('ERROR', 'controlMetaDataStart()', _);
    yield put(loadingActions.setPagePendding({
      pagePendding: false,
    }));
    yield put(textSearchActions.controlMetaDataStartFailure());
  }
}

function* controlMetaDataCancel() {
  try {
    const textSearchState = yield select(state => state.textSearchModule);
    const {
      searchToken,
    } = textSearchState.toJS();

    const params = {
      Mode: 'Cancel',
      SearchToken: searchToken,
    };

    const promise = yield call([SunapiClient, 'get'], CONTROL_METADATA_URL, params);

    if (promise.status === 200 && typeof promise.data.Error === 'undefined') {
      yield put(textSearchActions.controlMetaDataCancelSuccess());
    } else {
      yield put(textSearchActions.controlMetaDataCancelFailure());
    }
  } catch (_) {
    yield put(textSearchActions.controlMetaDataCancelFailure());
  }
}

function* controlMetaDataRenew() {
  try {
    const textSearchState = yield select(state => state.textSearchModule);
    const {
      searchToken,
    } = textSearchState.toJS();

    const params = {
      Mode: 'Renew',
      SearchToken: searchToken,
    };

    const promise = yield call([SunapiClient, 'get'], CONTROL_METADATA_URL, params);

    if (promise.status === 200 && typeof promise.data.Error === 'undefined') {
      yield put(textSearchActions.controlMetaDataRenewSuccess());
    } else if (promise.data.Error && promise.data.Error.Code === 702) {
      yield put(messageBoxActions.controlMessageBox({
        messageBoxInfo: {
          title: getLanguage('lang_error'),
          content: getLanguage('lang_menu_currently_used'), // 'search token 발행 실패',
          isOpen: true,
        },
      }));
      yield put(textSearchActions.controlMetaDataStartFailure());
    } else {
      yield put(textSearchActions.controlMetaDataRenewFailure());
    }
  } catch (_) {
    yield put(textSearchActions.controlMetaDataRenewFailure());
  }
}

function* sendMetaDataRenew() {
  while (true) {
    yield take(textSearchActions.SEND_METADATA_RENEW);
    const textSearchState = yield select(state => state.textSearchModule);
    const {
      searchToken,
    } = textSearchState.toJS();

    const params = {
      Mode: 'Renew',
      SearchToken: searchToken,
    };

    const promise = yield call([SunapiClient, 'get'], CONTROL_METADATA_URL, params);

    if (promise.status === 200 && typeof promise.data.Error === 'undefined') {
      yield put(textSearchActions.controlMetaDataRenewSuccess());
    } else if (promise.data.Error && promise.data.Error.Code === 702) {
      yield put(messageBoxActions.controlMessageBox({
        messageBoxInfo: {
          title: getLanguage('lang_error'),
          content: getLanguage('lang_menu_currently_used'), // 'search token 발행 실패',
          isOpen: true,
        },
      }));
      yield put(textSearchActions.controlMetaDataStartFailure());
    } else {
      yield put(textSearchActions.controlMetaDataRenewFailure());
    }
  }
}

function* getMetaDataStatus() {
  try {
    const textSearchState = yield select(state => state.textSearchModule);
    const {
      searchToken,
    } = textSearchState.toJS();

    const params = {
      Type: 'Status',
      SearchToken: searchToken,
    };

    const promise = yield call([SunapiClient, 'get'], GET_METADATA_URL, params);

    if (promise.status === 200 && typeof promise.data.Error === 'undefined') {
      yield put(textSearchActions.getMetaDataStatusSuccess(promise.data.Status));
      yield put(textSearchActions.requestTextSearch({
        requestType: 'GET_METADATA',
        Mode: 'Result',
      }));
    } else {
      yield put(textSearchActions.getMetaDataStatusFailure());
    }
  } catch (_) {
    yield put(textSearchActions.getMetaDataStatusFailure());
  }
}

function* getMetaDataResultAll() {
  try {
    const textSearchState = yield select(state => state.textSearchModule);
    const deviceInfoModuleState = yield select(state => state.deviceInfoModule);
    const { timeZone } = deviceInfoModuleState.toJS();
    const {
      searchToken,
      searchStatus,
      ResultFromIndex,
      MaxResults,
      TotalResultsFound,
    } = textSearchState.toJS();

    const timeZoneT = timeZone || 0;

    if (searchStatus === 'Completed') {
      const pad2 = n => n.toString().padStart(2, '0');
      const timezoneString = timezone => (Number(timezone) < 0
        ? `+${pad2(Math.abs(Number(timezone)))}:00`
        : `-${pad2(Math.abs(Number(timezone)))}:00`);

      // DST 문구가 포함되어있다면 제거하는 함수
      const removeDST = timestring => timestring.replace(' DST', '').replace(' ', 'T');
      // 로컬 시간을 UTC기준으로 표시
      const toISODate = timestring => new Date(timestring).toISOString().split('.')[0].concat('Z');
      // DST 제거 및 GMT Timezone 반영하는 함수
      const toDateFromDstString = timestring => {
        const dst = !!timestring.includes('DST');
        return {
          time: toISODate(`${removeDST(timestring)}${timezoneString(timeZoneT)}`),
          dst,
        };
      };

      let currentResultIndex = ResultFromIndex;
      let newMetaDataSearchResults = [];
      while (currentResultIndex < TotalResultsFound) {
        const params = {
          Type: 'Results',
          ResultFromIndex: currentResultIndex,
          MaxResults,
          SearchToken: searchToken,
        };
        yield call(controlMetaDataRenew);
        const promise = yield call([SunapiClient, 'get'], GET_METADATA_URL, params);

        if (promise.status === 200 && typeof promise.data.Error === 'undefined') {
          const { MetaDataSearchResults } = promise.data;
          const newMetaDataSearchResult = yield MetaDataSearchResults.map(param => {
            const itemDate = new Date(removeDST(param.Date));
            const endTimeDate = new Date(removeDST(param.Date));
            endTimeDate.setSeconds(itemDate.getSeconds() + 5);
            const result = {
              ...param,
              LocalStartTime: itemDate,
              LocalEndTime: endTimeDate,
              viewFormatDate: `${pad2(itemDate.getMonth() + 1)}/${pad2(itemDate.getDate())}  ${pad2(itemDate.getHours())}:${pad2(itemDate.getMinutes())}:${pad2(itemDate.getSeconds())}`,
              PlayTime: toDateFromDstString(param.PlayTime).time,
              dst: toDateFromDstString(param.PlayTime).dst,
            };
            return result;
          });
          newMetaDataSearchResults = newMetaDataSearchResults.concat(newMetaDataSearchResult);
          currentResultIndex += MaxResults;
        } else {
          yield put(textSearchActions.getMetaDataResultFailure());
        }
      }
      yield put(textSearchActions.getMetaDataResultSuccess({
        results: newMetaDataSearchResults,
        TotalResultsFound,
      }));
      yield put(loadingActions.setPagePendding({
        pagePendding: false,
      }));
    } else {
      yield put(textSearchActions.requestTextSearch({
        requestType: 'GET_METADATA',
        Mode: 'Wait',
      }));
    }
  } catch (_) {
    yield put(textSearchActions.getMetaDataResultFailure());
  }
}

function* getMetaDataResult() {
  try {
    const textSearchState = yield select(state => state.textSearchModule);
    const deviceInfoModuleState = yield select(state => state.deviceInfoModule);
    const { timeZone } = deviceInfoModuleState.toJS();
    const {
      searchToken,
      searchStatus,
      ResultFromIndex,
      MaxResults,
    } = textSearchState.toJS();

    const timeZoneT = timeZone || 0;

    if (searchStatus === 'Completed') {
      const params = {
        Type: 'Results',
        ResultFromIndex,
        MaxResults,
        SearchToken: searchToken,
      };

      const pad2 = n => n.toString().padStart(2, '0');
      const timezoneString = timezone => (Number(timezone) < 0
        ? `+${pad2(Math.abs(Number(timezone)))}:00`
        : `-${pad2(Math.abs(Number(timezone)))}:00`);

      // DST 문구가 포함되어있다면 제거하는 함수
      const removeDST = timestring => timestring.replace(' DST', '').replace(' ', 'T');
      // 로컬 시간을 UTC기준으로 표시
      const toISODate = timestring => new Date(timestring).toISOString().split('.')[0].concat('Z');
      // DST 제거 및 GMT Timezone 반영하는 함수
      const toDateFromDstString = timestring => {
        const dst = !!timestring.includes('DST');
        return {
          time: toISODate(`${removeDST(timestring)}${timezoneString(timeZoneT)}`),
          dst,
        };
      };

      const promise = yield call([SunapiClient, 'get'], GET_METADATA_URL, params);

      if (promise.status === 200 && typeof promise.data.Error === 'undefined') {
        const { MetaDataSearchResults, TotalResultsFound } = promise.data;
        const newMetaDataSearchResults = yield MetaDataSearchResults.map(param => {
          const itemDate = new Date(removeDST(param.Date));
          const endTimeDate = new Date(removeDST(param.Date));
          endTimeDate.setSeconds(itemDate.getSeconds() + 5);
          const result = {
            ...param,
            LocalStartTime: itemDate,
            LocalEndTime: endTimeDate,
            viewFormatDate: `${pad2(itemDate.getMonth() + 1)}/${pad2(itemDate.getDate())}  ${pad2(itemDate.getHours())}:${pad2(itemDate.getMinutes())}:${pad2(itemDate.getSeconds())}`,
            PlayTime: toDateFromDstString(param.PlayTime).time,
            dst: toDateFromDstString(param.PlayTime).dst,
          };
          return result;
        });
        yield put(textSearchActions.getMetaDataResultSuccess({
          results: newMetaDataSearchResults,
          TotalResultsFound,
        }));
        yield put(loadingActions.setPagePendding({
          pagePendding: false,
        }));
      } else {
        yield put(textSearchActions.getMetaDataResultFailure());
      }
    } else {
      yield put(textSearchActions.requestTextSearch({
        requestType: 'GET_METADATA',
        Mode: 'Wait',
      }));
    }
  } catch (_) {
    yield put(textSearchActions.getMetaDataResultFailure());
  }
}

function* watchRequests() {
  const request = yield actionChannel(textSearchActions.REQUEST_TEXT_SEARCH);
  while (true) {
    const { payload } = yield take(request);
    console.log('watchRequests()', request, payload);

    if (payload.requestType === 'CONTROL_METADATA') {
      switch (payload.Mode) {
        case 'OverlappedId':
          yield put(loadingActions.setPagePendding({
            pagePendding: true,
          }));
          yield call(getOverlappedIDList);
          yield put(loadingActions.setPagePendding({
            pagePendding: false,
          }));
          break;
        case 'Start':
          yield put(loadingActions.setPagePendding({
            pagePendding: true,
          }));
          yield call(controlMetaDataStart);
          break;
        case 'Cancel':
          yield call(controlMetaDataCancel);
          break;
        case 'Renew':
          yield call(controlMetaDataRenew);
          break;
        default:
          break;
      }
    } else if (payload.requestType === 'GET_METADATA') {
      switch (payload.Mode) {
        case 'Status':
          yield call(getMetaDataStatus);
          break;
        case 'Result':
          yield put(loadingActions.setPagePendding({
            pagePendding: true,
          }));
          if (payload.type == null) {
            yield call(getMetaDataResult);
          } else {
            yield call(getMetaDataResultAll);
          }
          break;
        case 'Wait':
          yield delay(500);
          yield call(getMetaDataStatus);
          break;
        default:
          break;
      }
    } else if (payload.requestType === 'GET_POS_EVENT_CONFIG') {
      yield call(getPOSEventConfig);
    } else if (payload.requestType === 'GET_POS_CONFIG') {
      yield call(getPOSConfig);
    }
  }
}

function* asyncCheckPosConfigEvent() {
  while (true) {
    const action = yield take(textSearchActions.CHECK_POS_EVENT_CONFIG);
    const param = {
      DeviceIDList: action.payload.deviceID,
    };

    const { data: { POSDevices } } = yield SunapiClient.get(GET_POS_CONFIG_URL, param);
    // POS 설정 값을 세팅하는 부분이 추후에 통합이나 값의 형태가 정해져야한다.
    const channelList = [];

    if (POSDevices[0].ChannelIDList[0] === 'All') {
      const moduleState = yield select(state => ({
        cameraList: state.cameraInfoModule.get('cameraList').toJS(),
      }));

      const { cameraList } = moduleState;
      // eventList에서 all로 표현하기 위해서 사용
      channelList.push('All');
      cameraList.map(cameraInfo => {
        channelList.push(cameraInfo.channel);
        return null;
      });
    }
    yield put(setPosEvent({
      posEvent: {
        ...action.payload,
        channelIDList: channelList.length !== 0 ? channelList : (
          POSDevices[0].ChannelIDList.map(channel => Number(channel))
        ),
        deviceName: POSDevices[0].DeviceName,
        encodingType: POSDevices[0].EncodingType,
      },
    }));
  }
}

export default function* rootSearchSaga() {
  yield all([
    watchRequests(),
    asyncCheckPosConfigEvent(),
    sendMetaDataRenew(),
  ]);
}
