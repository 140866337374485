import styled, { withTheme } from 'styled-components';

const SelectStyled = styled.select`
  padding: 10px;
  outline: none;
  border: 0px solid ${props => props.theme.line.color4};
  border-bottom: solid 1px ${props => props.theme.line.color4};
  color: ${props => props.theme.text.color2};
  background-color: ${props => props.theme.background.color1};

  &.disabled {
    pointer-events: none;
    border: 0px solid ${props => props.theme.line.color4};
    border-bottom: solid 1px ${props => props.theme.line.color4};
    color: ${props => props.theme.text.color3};
    background-color: ${props => props.theme.line.color2};
  }
`;

export default withTheme(SelectStyled);
