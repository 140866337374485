import React from 'react';
import PropTypes from 'prop-types';

const ErrorComponent = ({ error }) => (
  <div>
    <br />
    <br />
    <p style={{ color: 'red' }}>{ error }</p>
    <br />
    <br />
  </div>
);

const withError = PropsComponent => {
  const WithError = props => {
    const { error } = props;
    return error ? (
      <ErrorComponent {...props} />
    ) : (
      <PropsComponent {...props} />
    );
  };
  WithError.propTypes = {
    error: PropTypes.string,
  };
  WithError.defaultProps = {
    error: null,
  };
  return WithError;
};

ErrorComponent.propTypes = {
  error: PropTypes.string.isRequired,
};

export default withError;
