import { createAction, handleActions } from 'redux-actions';
import { Map, List, fromJS } from 'immutable';

// action
export const SET_SEARCH_DATE = 'textSearch/SET_SEARCH_DATE';
export const SET_SEARCH_TIME = 'textSearch/SET_SEARCH_TIME';
export const APPLY_SEARCH_DATE_TIME = 'textSearch/APPLY_SEARCH_DATE_TIME';
export const CANCEL_SEARCH_DATE_TIME = 'textSearch/CANCEL_SEARCH_DATE_TIME';

export const GET_POS_CONFIG = 'textSearch/GET_POS_CONFIG';
export const GET_POS_CONFIG_SUCCESS = 'textSearch/GET_POS_CONFIG_SUCCESS';
export const GET_POS_CONFIG_FAILURE = 'textSearch/GET_POS_CONFIG_FAILURE';

export const GET_POS_EVENT_CONFIG = 'textSearch/GET_POS_EVENT_CONFIG';
export const GET_POS_EVENT_CONFIG_SUCCESS = 'textSearch/GET_POS_EVENT_CONFIG_SUCCESS';
export const GET_POS_EVENT_CONFIG_FAILURE = 'textSearch/GET_POS_EVENT_CONFIG_FAILURE';

// export const CONTROL_METADATA_START = 'textSearch/CONTROL_METADATA_START';
export const CONTROL_METADATA_START_SUCCESS = 'textSearch/CONTROL_METADATA_START_SUCCESS';
export const CONTROL_METADATA_START_FAILURE = 'textSearch/CONTROL_METADATA_START_FAILURE';

export const CONTROL_METADATA_CANCEL = 'textSearch/CONTROL_METADATA_CANCEL';
export const CONTROL_METADATA_CANCEL_SUCCESS = 'textSearch/CONTROL_METADATA_CANCEL_SUCCESS';
export const CONTROL_METADATA_CANCEL_FAILURE = 'textSearch/CONTROL_METADATA_CANCEL_FAILURE';

export const SEND_METADATA_RENEW = 'textSearch/SEND_METADATA_RENEW';
export const CONTROL_METADATA_RENEW = 'textSearch/CONTROL_METADATA_RENEW';
export const CONTROL_METADATA_RENEW_SUCCESS = 'textSearch/CONTROL_METADATA_RENEW_SUCCESS';
export const CONTROL_METADATA_RENEW_FAILURE = 'textSearch/CONTROL_METADATA_RENEW_FAILURE';
export const CONTROL_METADATA_RENEW_STOP = 'textSearch/CONTROL_METADATA_RENEW_STOP';

export const GET_METADATA_RESULT = 'textSearch/GET_METADATA_RESULT';
export const GET_METADATA_RESULT_SUCCESS = 'textSearch/GET_METADATA_RESULT_SUCCESS';
export const GET_METADATA_RESULT_FAILURE = 'textSearch/GET_METADATA_RESULT_FAILURE';

export const GET_METADATA_STATUS = 'textSearch/GET_METADATA_STATUS';
export const GET_METADATA_STATUS_SUCCESS = 'textSearch/GET_METADATA_STATUS_SUCCESS';
export const GET_METADATA_STATUS_FAILURE = 'textSearch/GET_METADATA_STATUS_FAILURE';

export const GET_OVERLAPPED_ID_LIST = 'textSearch/GET_OVERLAPPED_ID';
export const GET_OVERLAPPED_ID_LIST_SUCCESS = 'textSearch/GET_OVERLAPPED_ID_SUCCESS';
export const GET_OVERLAPPED_ID_LIST_FAILURE = 'textSearch/GET_OVERLAPPED_ID_FAILURE';

export const SET_OVERLAPPED_ID_INDEX = 'textSearch/SET_OVERLAPPED_ID_INDEX';
export const INIT_OVERLAPPED_ID_LIST = 'textSearch/INIT_OVERLAPPED_ID_LIST';

export const SET_KEYWORD = 'textSearch/SET_KEYWORD';
export const SET_EVENT_KEYWORD = 'textSearch/SET_EVENT_KEYWORD';
export const SET_IS_CASE_SENSITIVE = 'textSearch/SET_IS_CASE_SENSITIVE';
export const SET_IS_WHOLE_WORD = 'textSearch/SET_IS_WHOLE_WORD';

export const REQUEST_TEXT_SEARCH = 'textSearch/REQUEST_TEXT_SEARCH';

export const SET_REALTIME_POS_DEVICE_LIST = 'textSearch/SET_REALTIME_POS_DEVICE_LIST';
export const SET_CURRENT_POS_DEVICE_LIST = 'textSearch/SET_CURRENT_POS_DEVICE_LIST';

export const CHECK_POS_EVENT_CONFIG = 'textSearch/CHECK_POS_EVENT_CONFIG';

export const SET_CALENDAR_OPEN = 'manualBackup/SET_CALENDAR_OPEN';

export const SET_SEARCH_RESULT_INIT = 'textSearch/SET_SEARCH_RESULT_INIT';

export const SET_LIVE_TEXT_EVENT_LIST_SELECT = 'textSearch/SET_LIVE_TEXT_EVENT_LIST_SELECT';

// action create
export const setDate = createAction(SET_SEARCH_DATE);
export const setTime = createAction(SET_SEARCH_TIME);
export const applyDateTime = createAction(APPLY_SEARCH_DATE_TIME);
export const cancelDateTime = createAction(CANCEL_SEARCH_DATE_TIME);

// export const getPOSConfig = createAction(GET_POS_CONFIG);
export const getPOSConfigSuccess = createAction(GET_POS_CONFIG_SUCCESS);
export const getPOSConfigFailure = createAction(GET_POS_CONFIG_FAILURE);

// export const getPOSEventConfig = createAction(GET_POS_EVENT_CONFIG);
export const getPOSEventConfigSuccess = createAction(GET_POS_EVENT_CONFIG_SUCCESS);
export const getPOSEventConfigFailure = createAction(GET_POS_EVENT_CONFIG_FAILURE);

// export const controlMetaDataStart = createAction(CONTROL_METADATA_START);
export const controlMetaDataStartSuccess = createAction(CONTROL_METADATA_START_SUCCESS);
export const controlMetaDataStartFailure = createAction(CONTROL_METADATA_START_FAILURE);

// export const controlMetaDataCancel = createAction(CONTROL_METADATA_CANCEL);
export const controlMetaDataCancelSuccess = createAction(CONTROL_METADATA_CANCEL_SUCCESS);
export const controlMetaDataCancelFailure = createAction(CONTROL_METADATA_CANCEL_FAILURE);

export const sendMetaDataRenew = createAction(SEND_METADATA_RENEW);
// export const controlMetaDataRenew = createAction(CONTROL_METADATA_RENEW);
export const controlMetaDataRenewSuccess = createAction(CONTROL_METADATA_RENEW_SUCCESS);
export const controlMetaDataRenewFailure = createAction(CONTROL_METADATA_RENEW_FAILURE);
export const controlMetaDataRenewStop = createAction(CONTROL_METADATA_RENEW_STOP);

// export const getMetaDataStatus = createAction(GET_METADATA_STATUS);
export const getMetaDataStatusSuccess = createAction(GET_METADATA_STATUS_SUCCESS);
export const getMetaDataStatusFailure = createAction(GET_METADATA_STATUS_FAILURE);

// export const getMetaDataResult = createAction(GET_METADATA_RESULT);
export const getMetaDataResultSuccess = createAction(GET_METADATA_RESULT_SUCCESS);
export const getMetaDataResultFailure = createAction(GET_METADATA_RESULT_FAILURE);

export const getOverlappedIdList = createAction(GET_OVERLAPPED_ID_LIST);
export const getOverlappedIdListSuccess = createAction(GET_OVERLAPPED_ID_LIST_SUCCESS);
export const getOverlappedIdListFailure = createAction(GET_OVERLAPPED_ID_LIST_FAILURE);

export const setOverlappedIdIndex = createAction(SET_OVERLAPPED_ID_INDEX);
export const initOverlappedIdList = createAction(INIT_OVERLAPPED_ID_LIST);

export const setKeyword = createAction(SET_KEYWORD);
export const setEventKeyword = createAction(SET_EVENT_KEYWORD);
export const setIsCaseSensitive = createAction(SET_IS_CASE_SENSITIVE);
export const setIsWholeWord = createAction(SET_IS_WHOLE_WORD);

export const requestTextSearch = createAction(REQUEST_TEXT_SEARCH);

export const setRealTimePOSDeviceList = createAction(SET_REALTIME_POS_DEVICE_LIST);
export const setCurrentPOSDeviceList = createAction(SET_CURRENT_POS_DEVICE_LIST);

export const checkPosEventConfig = createAction(CHECK_POS_EVENT_CONFIG);

export const setCalendarOpen = createAction(SET_CALENDAR_OPEN);

export const setSearchResultInit = createAction(SET_SEARCH_RESULT_INIT);

export const setLiveTextEventListSelect = createAction(SET_LIVE_TEXT_EVENT_LIST_SELECT);

const today = new Date();

const initialState = Map({
  isCalendarOpen: true,
  searchToken: '',
  keyword: '',
  eventKeyword: List([
    Map({
      index: 0,
      condition: '',
      isChecked: false,
    }),
  ]),
  eventKeywordCheckStatus: List([
    Map({
      index: 0,
      condition: '',
      isChecked: false,
    }),
  ]),
  isCaseSensitive: 'False',
  isWholeWord: 'False',
  searchResult: [],
  searchStatus: '',
  searchStartDateObj: Map({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate() - 7,
    hour: 0,
    minute: 0,
    second: 0,
  }),
  searchEndDateObj: Map({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
    hour: 23,
    minute: 59,
    second: 59,
  }),
  searchStartDateTimeObjBackup: Map({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate() - 7,
    hour: 0,
    minute: 0,
    second: 0,
  }),
  searchEndDateTimeObjBackup: Map({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
    hour: 23,
    minute: 59,
    second: 59,
  }),
  posEventConfig: {
    AmountEventEnable: 'False',
    TotalAmount: 0,
    TotalType: '',
    Keywords: [],
  },
  posConfigList: [{
    DeviceName: '',
    Enable: false,
    Port: 0,
    EventPlaybackStartTime: 0,
    EncodingType: '',
    ReceiptEnd: '',
    ReceiptStart: '',
    ChannelIDList: [],
  }],
  realTimeFilteredPosDeviceList: [],
  currentPOSDeviceList: [],
  ResultFromIndex: 1,
  MaxResults: 200,
  TotalResultsFound: 0,
  RenewHeartbeat: false,
  overlappedIDList: [],
  selectedOverlappedIDIndex: 0,
  liveTextEventSelect: Map({
    channelList: [], // [5, 6, 7, 8]
    localTime: '',
    deviceID: '',
  }),
});

// reducer
export default handleActions({
  [SET_CALENDAR_OPEN]: (state, action) => (
    state.set('isCalendarOpen', action.payload)
  ),
  [SET_SEARCH_DATE]: (state, action) => {
    const {
      flag,
      year,
      month,
      day,
    } = action.payload;
    if (flag === 'start') {
      return state
        .setIn(['searchStartDateTimeObjBackup', 'year'], year)
        .setIn(['searchStartDateTimeObjBackup', 'month'], month)
        .setIn(['searchStartDateTimeObjBackup', 'day'], day);
    }
    return state
      .setIn(['searchEndDateTimeObjBackup', 'year'], year)
      .setIn(['searchEndDateTimeObjBackup', 'month'], month)
      .setIn(['searchEndDateTimeObjBackup', 'day'], day);
  },
  [SET_SEARCH_TIME]: (state, action) => {
    const {
      flag,
      hour,
      minute,
      second,
    } = action.payload;
    if (flag === 'start') {
      return state
        .setIn(['searchStartDateTimeObjBackup', 'hour'], hour)
        .setIn(['searchStartDateTimeObjBackup', 'minute'], minute)
        .setIn(['searchStartDateTimeObjBackup', 'second'], second);
    }
    if (flag === 'end') {
      return state
        .setIn(['searchEndDateTimeObjBackup', 'hour'], hour)
        .setIn(['searchEndDateTimeObjBackup', 'minute'], minute)
        .setIn(['searchEndDateTimeObjBackup', 'second'], second);
    }
    return state;
  },
  [APPLY_SEARCH_DATE_TIME]: (state, action) => {
    const { payload } = action;
    const startDateTimeObj = state.get('searchStartDateTimeObjBackup');
    const endDateTimeObj = state.get('searchEndDateTimeObjBackup');
    const startDateTime = startDateTimeObj.toJS();
    const endDateTime = endDateTimeObj.toJS();
    const {
      year: sYear,
      month: sMonth,
      day: sDay,
    } = startDateTime;
    const {
      year: eYear,
      month: eMonth,
      day: eDay,
    } = endDateTime;

    const startDate = new Date(sYear, sMonth - 1, sDay);
    const endDate = new Date(eYear, eMonth - 1, eDay);

    const startUTC = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const endUTC = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

    const oneDay = 1000 * 60 * 60 * 24;

    const dayDiff = Math.floor((endUTC - startUTC) / oneDay);

    let adjust = false;

    const dateOffset = (24 * 60 * 60 * 1000) * 7;

    if (dayDiff > 7 || dayDiff < 0) {
      adjust = true;
    }

    if (payload === 'start') {
      if (adjust) {
        endDate.setTime(startDate.getTime() + dateOffset);
        return state
          .set('searchStartDateObj', startDateTimeObj)
          .setIn(['searchEndDateObj', 'year'], endDate.getFullYear())
          .setIn(['searchEndDateObj', 'month'], endDate.getMonth() + 1)
          .setIn(['searchEndDateObj', 'day'], endDate.getDate())
          .setIn(['searchEndDateTimeObjBackup', 'year'], endDate.getFullYear())
          .setIn(['searchEndDateTimeObjBackup', 'month'], endDate.getMonth() + 1)
          .setIn(['searchEndDateTimeObjBackup', 'day'], endDate.getDate());
      }
      return state
        .set('searchStartDateObj', startDateTimeObj);
    }
    if (adjust) {
      startDate.setTime(endDate.getTime() - dateOffset);
      return state
        .set('searchEndDateObj', endDateTimeObj)
        .setIn(['searchStartDateObj', 'year'], startDate.getFullYear())
        .setIn(['searchStartDateObj', 'month'], startDate.getMonth() + 1)
        .setIn(['searchStartDateObj', 'day'], startDate.getDate())
        .setIn(['searchStartDateTimeObjBackup', 'year'], startDate.getFullYear())
        .setIn(['searchStartDateTimeObjBackup', 'month'], startDate.getMonth() + 1)
        .setIn(['searchStartDateTimeObjBackup', 'day'], startDate.getDate());
    }
    return state
      .set('searchEndDateObj', endDateTimeObj);
  },
  [CANCEL_SEARCH_DATE_TIME]: state => {
    const startDateTimeObj = state.get('searchStartDateObj');
    const endDateTimeObj = state.get('searchEndDateObj');
    return state
      .setIn(['searchStartDateTimeObjBackup', 'year'], startDateTimeObj.get('year'))
      .setIn(['searchStartDateTimeObjBackup', 'month'], startDateTimeObj.get('month'))
      .setIn(['searchStartDateTimeObjBackup', 'day'], startDateTimeObj.get('day'))
      .setIn(['searchStartDateTimeObjBackup', 'hour'], startDateTimeObj.get('hour'))
      .setIn(['searchStartDateTimeObjBackup', 'minute'], startDateTimeObj.get('minute'))
      .setIn(['searchStartDateTimeObjBackup', 'second'], startDateTimeObj.get('second'))
      .setIn(['searchEndDateTimeObjBackup', 'year'], endDateTimeObj.get('year'))
      .setIn(['searchEndDateTimeObjBackup', 'month'], endDateTimeObj.get('month'))
      .setIn(['searchEndDateTimeObjBackup', 'day'], endDateTimeObj.get('day'))
      .setIn(['searchEndDateTimeObjBackup', 'hour'], endDateTimeObj.get('hour'))
      .setIn(['searchEndDateTimeObjBackup', 'minute'], endDateTimeObj.get('minute'))
      .setIn(['searchEndDateTimeObjBackup', 'second'], endDateTimeObj.get('second'));
  },
  [GET_POS_CONFIG_SUCCESS]: (state, action) => (
    state.set('posConfigList', action.payload)
  ),
  [GET_POS_CONFIG_FAILURE]: state => (
    state.set('posConfigList', [{
      DeviceName: '',
      Enable: false,
      Port: 0,
      EventPlaybackStartTime: 0,
      EncodingType: '',
      ReceiptEnd: '',
      ReceiptStart: '',
      ChannelIDList: [],
    }])
  ),
  [GET_POS_EVENT_CONFIG_SUCCESS]: (state, action) => {
    const { payload } = action;
    const eventKeywords = [];
    payload.Keywords.map(item => eventKeywords.push(Map({
      index: item.KeywordIndex,
      condition: item.KeywordCondition,
      isChecked: false,
    })));
    return state.set('posEventConfig', payload)
      .set('eventKeyword', List(eventKeywords))
      .set('eventKeywordCheckStatus', List(eventKeywords));
  },
  [GET_POS_EVENT_CONFIG_FAILURE]: state => (
    state.set('posEventConfig', {
      AmountEventEnable: 'False',
      TotalAmount: 0,
      TotalType: '',
      Keywords: [],
    })
  ),
  [CONTROL_METADATA_START_SUCCESS]: (state, action) => {
    // renew 타이머 시작 하는 시점이 여기가 되어야 할 것으로 보임
    console.log('control metadata start success... (search token 생성 완료)', action.payload);
    return state.set('searchToken', action.payload)
      .set('searchResult', [])
      .set('ResultFromIndex', 1)
      .set('MaxResults', 200)
      .set('TotalResultsFound', 0)
      .set('RenewHeartbeat', false);
  },
  [CONTROL_METADATA_START_FAILURE]: state => {
    console.log('control metadata start failure... ');
    return state.set('searchToken', '')
      .set('ResultFromIndex', 1)
      .set('MaxResults', 200)
      .set('TotalResultsFound', 0)
      .set('RenewHeartbeat', false);
  },
  [CONTROL_METADATA_CANCEL_SUCCESS]: state => (
    state.set('searchToken', '')
      .set('ResultFromIndex', 1)
      .set('MaxResults', 200)
      .set('TotalResultsFound', 0)
      .set('RenewHeartbeat', false)
  ),
  [CONTROL_METADATA_RENEW_SUCCESS]: state => {
    console.log('control metadata renew success...', state.toJS().searchToken);
    return state.set('searchToken', state.toJS().searchToken);
  },
  [CONTROL_METADATA_RENEW_FAILURE]: state => {
    console.log('control metadata renew failure...', state.toJS());
  },
  [CONTROL_METADATA_RENEW_STOP]: state => {
    console.log('control metadata renew stop!');
    return state.set('RenewHeartbeat', false);
  },
  [GET_METADATA_STATUS_SUCCESS]: (state, action) => (
    state.set('searchStatus', action.payload)
  ),
  [GET_METADATA_RESULT_SUCCESS]: (state, action) => {
    console.log('get metadata result success... total ->', action.payload.TotalResultsFound);
    const RenewHeartbeat = action.payload.TotalResultsFound
      !== state.toJS().searchResult.concat(action.payload.results).length;
    return state.set('searchResult', state.toJS().searchResult.concat(action.payload.results))
      .set('ResultFromIndex', state.toJS().ResultFromIndex + action.payload.results.length)
      // .set('MaxResults', action.payload.MaxResults)
      .set('TotalResultsFound', action.payload.TotalResultsFound)
      .set('RenewHeartbeat', RenewHeartbeat);
  },
  [GET_METADATA_RESULT_FAILURE]: state => {
    console.log('get metadata result failure... (searchResult initialize....)');
    return state.set('searchResult', [])
      .set('ResultFromIndex', 1)
      .set('MaxResults', 200)
      .set('TotalResultsFound', 0);
  },
  [SET_KEYWORD]: (state, action) => (
    state.set('keyword', action.payload)
  ),
  [SET_EVENT_KEYWORD]: (state, action) => {
    const eventKeywordCheckStatus = state.get('eventKeywordCheckStatus');
    return state.set('eventKeywordCheckStatus', eventKeywordCheckStatus.update(
      action.payload.index,
      item => item.set('isChecked', action.payload.checked),
    ));
  },
  [SET_IS_CASE_SENSITIVE]: (state, action) => (
    state.set('isCaseSensitive', action.payload)
  ),
  [SET_IS_WHOLE_WORD]: (state, action) => (
    state.set('isWholeWord', action.payload)
  ),
  [SET_REALTIME_POS_DEVICE_LIST]: (state, action) => (
    state.set('realTimeFilteredPosDeviceList', action.payload)
  ),
  [SET_CURRENT_POS_DEVICE_LIST]: (state, action) => (
    state.set('currentPOSDeviceList', action.payload)
  ),
  [GET_OVERLAPPED_ID_LIST_SUCCESS]: (state, { payload }) => (
    state.set('overlappedIDList', payload.sort((x, y) => x - y))
  ),
  [GET_OVERLAPPED_ID_LIST_FAILURE]: state => (
    state.set('overlappedIDList', [])
  ),
  [SET_OVERLAPPED_ID_INDEX]: (state, { payload }) => (
    state.set('selectedOverlappedIDIndex', payload)
  ),
  [INIT_OVERLAPPED_ID_LIST]: state => (
    state.set('overlappedIDList', [])
  ),
  [SET_SEARCH_RESULT_INIT]: state => (
    state.set('searchResult', [])
  ),
  [SET_LIVE_TEXT_EVENT_LIST_SELECT]: (state, { payload }) => (
    state.set('liveTextEventSelect', fromJS(payload))
  ),
}, initialState);
