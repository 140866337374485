import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class EventSearchTabContainer extends React.Component {
  state ={
    accordionState: {
      deviceList: true,
      date: true,
    },
    height: 'auto',
  }

  dateAccordion = null;

  initHeight = 'auto';

  componentDidMount() {
    if (this.dateAccordion) {
      this.initHeight = `${this.dateAccordion.clientHeight + 1}px`;
      this.onUpdate({
        height: this.initHeight,
      });
    }
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  )

  setRef = ref => {
    this.dateAccordion = ref;
  }

  onAccordionClick = id => {
    const { accordionState } = this.state;
    this.setState({
      accordionState: {
        ...accordionState,
        [id]: !accordionState[id],
      },
    });
  }

  onResize = (e, ref) => {
    this.setState({
      height: `calc(100% - ${ref.style.height})`,
    });
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

EventSearchTabContainer.propTypes = {
  render: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    lang: state.langModule.get('lang'),
  }),
)(EventSearchTabContainer);
