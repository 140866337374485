import {
  take,
  put,
  all,
} from 'redux-saga/effects';
import { Auth, SunapiClient } from 'util/lib';
import * as loginActions from 'store/modules/login/loginModule';
import * as loadingActions from 'store/modules/base/loadingModule';
import { setCookie, eraseCookie } from 'util/lib/Cookie';

const url = '/index.php';

const loginHandleCookie = (authKey, id, header) => {
  setCookie('AUTH_KEY', authKey);
  setCookie('ID', btoa(id));
  setCookie('AUTH_HEADER_UWA', JSON.stringify(header));
};

function* asyncLoginSaga() {
  while (true) {
    const action = yield take(loginActions.LOGIN);
    yield put(loadingActions.setPagePendding({
      pagePendding: true,
    }));

    try {
      yield SunapiClient.login(url, action.payload);
      const authKey = Auth.getAuthKey();
      const authHeader = Auth.getAuthHeader();
      yield put(loginActions.loginSuccess());
      loginHandleCookie(authKey, action.payload.userid, authHeader);
      Auth.loginSuccess();
      action.payload.history.push('/live');
    } catch (error) {
      console.log('Login failed', error);
      if (error && error.message === 'retry') {
        const authKey = Auth.getAuthKey();
        const authHeader = Auth.getAuthHeader();
        yield put(loginActions.loginSuccess());
        loginHandleCookie(authKey, action.payload.userid, authHeader);
        Auth.loginSuccess();
        action.payload.history.push('/live');
      } else {
        const errorCode = error && error.status;
        let errorMessage = '';
        if (errorCode === 401) {
          if (action.payload.password.length < 8) {
            errorMessage = 'lang_password_error_least_character';
          } else {
            errorMessage = 'lang_wrong_id_password_msg';
          }
        } else if (errorCode === 490) {
          errorMessage = 'lang_login_account_blocked_msg';
        } else {
          errorMessage = 'Internal error';
        }
        yield put(loginActions.loginFailure({ errorMessage }));
        Auth.loginFailed();
      }
    }

    yield put(loadingActions.setPagePendding({
      pagePendding: false,
    }));
  }
}

function* asyncLogoutSaga() {
  while (true) {
    const { payload } = yield take(loginActions.LOGOUT);
    const destroy = payload && payload.destroy;

    eraseCookie('AUTH_KEY');
    eraseCookie('ID');
    eraseCookie('AUTH_HEADER_UWA');

    if (destroy) {
      // 설정이 바뀌어서 강제로 창을 제거 하기 위해서 사용
      window.open('about:blank', '_self').self.close();
    } else {
      Auth.logout();
    }
  }
}

function* asyncCheckIsAdminSaga() {
  while (true) {
    yield take(loginActions.CHECK_IS_ADMIN);
    const userID = Auth.getUserid();
    const result = yield SunapiClient.get(`/stw-cgi/security.cgi?msubmenu=users&action=view&UserID=${userID}`);

    const userInfo = result.data && result.data.Users && result.data.Users[0];
    const isAdmin = userInfo && userInfo.UserLevel === 'Admin';
    yield put(loginActions.checkIsAdminSuccess({
      isAdmin,
    }));
  }
}

export default function* rootLoginSaga() {
  yield all([
    asyncLoginSaga(),
    asyncLogoutSaga(),
    asyncCheckIsAdminSaga(),
  ]);
}
