import React from 'react';
import PropTypes from 'prop-types';

class DatePickerContainer extends React.Component {
  onDateChange = (date, label) => {
    const { actions } = this.props;
    actions.setDate({
      flag: label,
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    });
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

DatePickerContainer.propTypes = {
  render: PropTypes.func.isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
};

export default DatePickerContainer;
