import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withContainers } from 'wisenet-ui/util/hoc';
import { Loading } from 'wisenet-ui/components/molecules';
import { VideoLayout } from 'wisenet-ui/components/organisms';
import { LiveVideoLayoutContainer, LiveVideoTileStateContainer } from 'containers/organisms';
import { LiveVideoTile } from 'components/organisms';
import { LiveVideoLayoutStyled } from './LiveVideoLayoutStyled';

class LiveVideoLayout extends PureComponent {
  render() {
    const {
      setMoveItem,
      onError,
      layoutPendding,
      ...rest
    } = this.props;

    // 생성시킬 Tile Component 정의
    const tile = (
      <LiveVideoTile
        onError={onError}
        selectTileCamera={rest.selectTileCamera}
      />
    );

    return (
      <LiveVideoLayoutStyled>
        {layoutPendding && <Loading />}
        <VideoLayout
          VideoTile={tile}
          onMouseUpItem={setMoveItem}
          {...rest}
        />
      </LiveVideoLayoutStyled>
    );
  }
}

LiveVideoLayout.propTypes = {
  type: PropTypes.oneOf(['dynamic', 'static']).isRequired,
  tileList: PropTypes.arrayOf(PropTypes.any).isRequired,
  pattern: PropTypes.string.isRequired,
  setMoveItem: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  layoutPendding: PropTypes.bool.isRequired,
};

export default withContainers([LiveVideoLayoutContainer, LiveVideoTileStateContainer],
  LiveVideoLayout);
