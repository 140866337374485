import styled, { withTheme, css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { IconButton, Button, Span } from 'wisenet-ui/components/atoms';
import { Slider } from 'wisenet-ui/components/molecules';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
`;

export const StyledSlider = styled(Slider)`
  height: 2px;
  margin-top: 20px;
  padding-bottom: 20px;
`;

export const ZoomButton = withTheme(styled(IconButton)`
  color: ${props => props.theme.text.color1};
  border: none;
  font-size: 24px;
  width: 40px;
  height: 40px;
  padding: 0;
`);

export const NearButton = withTheme(styled(IconButton)`
  color: ${props => props.theme.text.color1};
  border: none;
  width: 40px;
  height: 40px;
  padding: 0;
`);

export const FarButton = withTheme(styled(IconButton)`
  color: ${props => props.theme.text.color1};
  border: none;
  width: 40px;
  height: 40px;
  padding: 0;
  margin-left: 1px;
`);

export const AutoButton = withTheme(styled(Button)`
  color: ${props => props.theme.text.color1};
  border: 1px solid ${props => props.theme.line.color4};
  width: 80px;
  height: 28px;
  padding: 2px;
  margin-left: 105px;
`);

export const ButtonIconStyled = styled.i`
  font-size: 28px;
  ${props => props.isZoom && css`
    font-size: 24px;
  `}
`;

export const TooltipSpan = withTheme(styled(Span)`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  margin: 0px;
  padding: 0px
`);

export const CustomReactTooltip = withTheme(styled(ReactTooltip)`
  background-color: #555 !important;
  padding: 5px !important;
  &.place-top {
    &:after {
      border-top: none !important;
    }
  }
  &.place-left {
    &:after {
      border-left: 6px solid #555 !important;
    }
  }
  &.place-right {
    &:after {
      border-right: 6px solid #555 !important;
    }
  }
`);
