import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';

class PTZTabContainer extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { selectedChannel, accessInfo } = nextProps;
    const currentChannel = selectedChannel.get('channel');
    if (typeof currentChannel === 'undefined'
      || (typeof accessInfo.ptzAccess !== 'undefined' && !accessInfo.ptzAccess)) {
      document.getElementById('ptz_disable_mask').style.display = 'block';
      document.getElementById('ptztab_wrapper').style.pointerEvents = 'none';
    } else {
      document.getElementById('ptz_disable_mask').style.display = 'none';
      document.getElementById('ptztab_wrapper').style.pointerEvents = 'auto';
    }
    return true;
  }

  onDragStart = e => {
    e.preventDefault();
  }

  onDragOver = e => {
    e.preventDefault();
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

PTZTabContainer.propTypes = {
  render: PropTypes.func.isRequired,
  selectedChannel: PropTypes.instanceOf(Map),
  accessInfo: PropTypes.instanceOf(Object),
};

PTZTabContainer.defaultProps = {
  selectedChannel: Map({}),
  accessInfo: {},
};

export default connect(
  state => {
    const selectedChannel = state.cameraInfoModule.get('selectTileCamera').get('channel');
    let viewModeType = '';

    if (typeof selectedChannel !== 'undefined') {
      const cameraList = state.cameraInfoModule.get('cameraList');
      const findTileInfo = cameraList.find(camera => camera.get('channel') === selectedChannel) || Map({});
      const {
        videoProfilepolicy,
        videoProfile,
      } = findTileInfo.toJS();

      if (typeof videoProfilepolicy !== 'undefined' && videoProfile) {
        const currentProfile = state.liveMediaControlModule.get('pattern') === 'SPECIAL'
          ? videoProfilepolicy.RecordProfile
          : videoProfilepolicy.NetworkProfile;

        if (currentProfile) {
          const {
            ViewModeType,
          } = videoProfile.Profiles.find(item => item.Profile === currentProfile);
          viewModeType = ViewModeType;
        }
      }
    }

    return {
      viewModeType,
      selectedChannel: state.cameraInfoModule.get('selectTileCamera'),
      accessInfo: state.systemInfomationModule.get('accessInfo').toJS(),
    };
  },
)(PTZTabContainer);
