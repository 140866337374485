import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { TextSearchTabContainer } from 'containers/organisms';
import { PosTargetDevice, TextSearch } from 'components/organisms';
import { getLanguage } from 'util/lib';
import GuidePopup from './GuidePopup';
import {
  ButtonContainer,
  StyledButton,
  AccordionWrapperStyled,
  DeviceAccordionStyled,
  TextAccordionStyled,
  HeadTitleStyled,
  RndStyled,
  GuidePopupWrapper,
} from './TextSearchTabStyled';

const AccordionHeaderHeight = '45px';

const TextSearchTab = ({
  onApply,
  accordionState,
  onAccordionClick,
  height,
  initHeight,
  onResize,
  setRef,
  showGuidePopup,
  guideData,
  onGuidePopupConfirm,
}) => {
  const deviceListItems = [
    {
      id: 'deviceList',
      headerLeft: <HeadTitleStyled>{getLanguage('lang_target_device')}</HeadTitleStyled>,
      content: <PosTargetDevice />,
      isOpen: accordionState.deviceList,
    },
  ];
  const textSearchItems = [
    {
      id: 'textSearch',
      headerLeft: <HeadTitleStyled>{getLanguage('lang_text_search')}</HeadTitleStyled>,
      content: <TextSearch />,
      isOpen: accordionState.textSearch,
    },
  ];

  let textSearchAccordionHeight = height;
  if (!accordionState.textSearch) {
    textSearchAccordionHeight = AccordionHeaderHeight;
  }

  return (
    <AccordionWrapperStyled>
      <GuidePopupWrapper
        isOpen={showGuidePopup}
        shouldCloseOnOverlayClick
      >
        <GuidePopup
          title={guideData.title}
          content={guideData.content}
          onConfirm={onGuidePopupConfirm}
        />
      </GuidePopupWrapper>
      <RndStyled
        enableResizing={{
          bottom: accordionState.textSearch,
          bottomLeft: false,
          bottomRight: false,
          left: false,
          right: false,
          top: false,
          topLeft: false,
          topRight: false,
        }}
        disableDragging
        size={{
          width: '100%',
        }}
        onResize={(e, direction, ref) => onResize(e, ref)}
      >
        <DeviceAccordionStyled
          items={deviceListItems}
          onClick={onAccordionClick}
          allClosed={!accordionState.deviceList}
        />
      </RndStyled>
      <TextAccordionStyled
        items={textSearchItems}
        onClick={onAccordionClick}
        style={{
          flexBasis: textSearchAccordionHeight,
        }}
        minHeight={AccordionHeaderHeight}
        maxHeight={initHeight}
        innerRef={ref => setRef(ref, 'textSearchAccordion')}
      />
      <ButtonContainer>
        <StyledButton onClick={onApply}>{getLanguage('lang_apply')}</StyledButton>
      </ButtonContainer>
    </AccordionWrapperStyled>
  );
};

TextSearchTab.propTypes = {
  onApply: PropTypes.func,
  accordionState: PropTypes.instanceOf(Object).isRequired,
  onAccordionClick: PropTypes.func.isRequired,
  height: PropTypes.string.isRequired,
  initHeight: PropTypes.string.isRequired,
  onResize: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  showGuidePopup: PropTypes.bool.isRequired,
  guideData: PropTypes.objectOf(PropTypes.any).isRequired,
  onGuidePopupConfirm: PropTypes.func.isRequired,
};

TextSearchTab.defaultProps = {
  onApply: () => {},
};

export default withContainer(TextSearchTabContainer, TextSearchTab);
