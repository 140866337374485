import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PTZSequenceActions } from 'store/actionCreators';

class PTZTraceContainer extends React.Component {
  state = {
    currentTraceList: null,
    prevTrace: null,
  };

  componentWillReceiveProps(nextProps) {
    const { currentChannel, traceList, ptzInfo } = nextProps;
    const { prevChannel } = this.props;
    if (currentChannel !== -1) {
      if ((traceList === null || prevChannel !== currentChannel)
        && (typeof ptzInfo !== 'undefined' && ptzInfo.trace)) {
        // PTZSequenceActions.setTracePending(true);
        const getData = {
          Channel: currentChannel,
        };

        PTZSequenceActions.getTrace(getData);

        PTZSequenceActions.setPrevChannel(currentChannel);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      currentChannel,
      traceList: nextTraceList,
      ptzInfo,
      isActive,
    } = nextProps;

    const {
      currentChannel: prevChannel,
      traceList,
    } = this.props;

    const {
      currentTraceList: currentTraceListState,
      prevTrace,
    } = nextState;
    const { currentTraceList: prevTraceListState } = this.state;

    document.getElementById('trace_disable_mask').style.display = 'block';
    document.getElementById('trace_wrapper').style.pointerEvents = 'none';

    if (typeof ptzInfo !== 'undefined' && ptzInfo.trace) {
      document.getElementById('trace_disable_mask').style.display = 'none';
      document.getElementById('trace_wrapper').style.pointerEvents = 'auto';
    } else {
      document.getElementById('trace_disable_mask').style.display = 'block';
      document.getElementById('trace_wrapper').style.pointerEvents = 'none';
    }

    if ((!Number.isNaN(prevChannel) || !Number.isNaN(currentChannel)) && isActive) {
      if (prevChannel !== currentChannel) {
        if (prevChannel !== null && !Number.isNaN(prevChannel) && prevTrace !== null) {
          // 채널 변경시 이전 채널 동작 중지
          // const getData = {
          //   Channel: prevChannel,
          //   Mode: 'Stop',
          //   Trace: prevTrace.no,
          // };
          // PTZSequenceActions.controlTrace(getData);

          // this.setState({
          //   prevTrace: null,
          // });
        }
        if (nextTraceList !== null) {
          this.setState({
            currentTraceList: this.makeTraceList(nextTraceList),
          });
          return true;
        }
        return false;
      }
      if (JSON.stringify(traceList) !== JSON.stringify(nextTraceList)) {
        // 채널 props 업데이트가 리스트 정보 업데이트보다 빨라서 별도 분기 처리.
        this.setState({
          currentTraceList: this.makeTraceList(nextTraceList),
        });
        return true;
      }
    }
    // 현재 탭에서 상태 변경 시
    if (prevTraceListState === null || currentTraceListState === null) {
      if (nextTraceList !== null && isActive) {
        this.setState({
          currentTraceList: this.makeTraceList(nextTraceList),
        });
        return true;
      }
      return false;
    }

    return true;
  }

  makeTraceList = traceList => {
    const array = [];
    for (let idx = 1; idx <= traceList.length; idx += 1) {
      array.push({
        index: idx - 1,
        no: idx,
        name: `Trace ${idx}`,
        isSelected: false,
      });
    }
    return array;
  };

  onChangeData = (dataList, selectedData) => {
    let getData = {};
    const { currentChannel } = this.props;

    if (selectedData.isSelected) {
      getData = {
        Channel: currentChannel,
        Mode: 'Start',
        Trace: selectedData.no,
      };
    } else {
      getData = {
        Channel: currentChannel,
        Mode: 'Stop',
        Trace: selectedData.no,
      };
    }
    PTZSequenceActions.controlTrace(getData);

    this.setState({
      currentTraceList: dataList,
      prevTrace: selectedData,
    });
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

PTZTraceContainer.propTypes = {
  render: PropTypes.func.isRequired,
  traceList: PropTypes.arrayOf(Object),
  currentChannel: PropTypes.number,
  prevChannel: PropTypes.number,
  ptzInfo: PropTypes.objectOf(PropTypes.any),
  isActive: PropTypes.bool,
};

PTZTraceContainer.defaultProps = {
  traceList: [],
  currentChannel: -1,
  prevChannel: -1,
  ptzInfo: {},
  isActive: false,
};

export default connect(
  state => ({
    isTracePending: state.ptzSequenceModule.get('isTracePending'),
    traceList: state.ptzSequenceModule.get('traceList'),
    lang: state.langModule.get('lang'),
    prevChannel: state.ptzSequenceModule.get('prevChannel'),
  }),
)(PTZTraceContainer);
