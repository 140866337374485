import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { NewList } from 'wisenet-ui/components/organisms';
import { TextListContainer } from 'containers/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { getLanguage } from 'util/lib';
import {
  ListContainer,
  ListCounter,
  ListSelectStyled,
  HeaderWrapper,
  ListWrapper,
  SortButtonStyled,
  ButtonWrapper,
  ButtonStyled,
  DummyDiv,
} from './TextListStyled';


class TextList extends React.PureComponent {
  render() {
    const {
      onSelect,
      onDescend,
      isDescending,
      onClickViewMore,
      onClickViewAll,
      TotalResultsFound,
      sortedList,
      RenewHeartbeat,
      overlappedIDListUI,
      onChangeOverlappedId,
      selectedOverlappedIDIndex,
    } = this.props;

    return (
      <ListContainer>
        <HeaderWrapper>
          <ListSelectStyled
            value={overlappedIDListUI[selectedOverlappedIDIndex]}
            listData={overlappedIDListUI.map((overlappedID, index) => ({
              data: overlappedID,
              text: `${getLanguage('lang_overlapped')} ${index}`,
            }))}
            onChange={onChangeOverlappedId}
          />
          <SortButtonStyled onClick={onDescend} className="wni wni-sorting" isDescending={isDescending} />
        </HeaderWrapper>
        <ListWrapper>
          {!!sortedList.length
            && (
              <NewList
                listData={List(sortedList)}
                handleClick={onSelect}
                useExportOneClickListItem
                exportClickListItem={onSelect}
              />
            )}
        </ListWrapper>
        <ButtonWrapper>
          <ButtonStyled
            onClick={onClickViewMore}
            disabled={!RenewHeartbeat}
          >
            {getLanguage('lang_more')}
          </ButtonStyled>
          <ButtonStyled
            onClick={onClickViewAll}
            disabled={!RenewHeartbeat}
            isAll
          >
            {getLanguage('lang_view_all')}
          </ButtonStyled>
          <DummyDiv />
          <ListCounter>
            {!!sortedList.length && `${sortedList.length} / ${TotalResultsFound}`}
          </ListCounter>
        </ButtonWrapper>
      </ListContainer>
    );
  }
}

TextList.propTypes = {
  onSelect: PropTypes.func,
  onDescend: PropTypes.func,
  isDescending: PropTypes.bool,
  onClickViewMore: PropTypes.func.isRequired,
  onClickViewAll: PropTypes.func.isRequired,
  TotalResultsFound: PropTypes.number,
  sortedList: PropTypes.arrayOf(PropTypes.any).isRequired,
  RenewHeartbeat: PropTypes.bool.isRequired,
  overlappedIDListUI: PropTypes.instanceOf(Array),
  onChangeOverlappedId: PropTypes.func.isRequired,
  selectedOverlappedIDIndex: PropTypes.number,
};

TextList.defaultProps = {
  onSelect: () => {},
  onDescend: () => {},
  isDescending: false,
  TotalResultsFound: 0,
  overlappedIDListUI: [],
  selectedOverlappedIDIndex: 0,
};

export default withContainer(TextListContainer, TextList);
