export default {
  line: {
    color1: '#333333',
    color2: '#333333',
    color3: '#4c4c4c',
    color4: '#4c4c4c',
    color5: '#4c4c4c',
    color6: '#797979',
    color7: '#4c4c4c',
  },
  text: {
    color1: '#ffffff',
    color2: '#b2b2b2',
    color3: '#797979',
    color4: '#f37321',
    color5: '#e9e9e9',
    color6: '#a5a5a5',
    color7: '#e43030',
    color8: '#000000',
    color9: '#a1a1a1',
    color10: '#d2d2d2',
  },
  background: {
    color1: '#1e1e1e',
    color2: '#262626',
    color3: '#333333',
    color4: '#4c4c4c',
    color5: '#452b1b',
    color6: '#c25c1a',
    color7: '#262626',
    color8: '#e9e9e9',
    color9: '#262626',
    color10: '#452b1b',
    color11: '#a5a5a5',
  },
};
