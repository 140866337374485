import { createAction, handleActions } from 'redux-actions';
import { Map, fromJS, List } from 'immutable';

// action
export const SET_POS_EVENT = 'systemEvent/SET_POS_EVENT';
export const SET_ALARM_INPUT = 'systemEvent/SET_ALARM_INPUT';
export const SET_ALARM_OUTPUT = 'systemEvent/SET_ALARM_OUTPUT';
export const SET_AUDIO_OUTPUT = 'systemEvent/SET_AUDIO_OUTPUT';
export const SET_SYSTEM_EVENT = 'systemEvent/SET_SYSTEM_EVENT';
export const SET_ACCESS_INFO = 'systemEvent/SET_ACCESS_INFO';
export const SET_SSL_INFO = 'systemEvent/SET_SSL_INFO';
export const SET_USERGROUP_INFO = 'systemEvent/SET_USERGROUP_INFO';

// action create
export const setPosEvent = createAction(SET_POS_EVENT);
export const setAlarmInput = createAction(SET_ALARM_INPUT);
export const setAlarmOutput = createAction(SET_ALARM_OUTPUT);
export const setAudioOutput = createAction(SET_AUDIO_OUTPUT);
export const setSystemEvent = createAction(SET_SYSTEM_EVENT);
export const setAccessInfo = createAction(SET_ACCESS_INFO);
export const setSSLInfo = createAction(SET_SSL_INFO);
export const setUsergroupInfo = createAction(SET_USERGROUP_INFO);


const initialState = Map({
  posEvent: Map({
    channelIDList: List([]), // [1, 2, 3]
    deviceID: 0,
    deviceName: '', // TEXT 03
    encodingType: '', // EUC-KR
    receipt: '', // ...what the
    receivedDate: '', // 2019-04-19T09:43:57Z
  }),
  alarmInput: Map({
    // 1: false,
  }),
  alarmOutput: Map({
    // 1: false,
  }),
  audioOutput: Map({
    // 1: false,
  }),
  systemEvent: Map({
    // AMDLoadFail: false,
    // adminLogin: false,
    // alarmReset: false,
    // backup: false,
    // batteryFail: false,
    // beingUpdate: false,
    // configChange: false,
    // configRestore: false,
    // dspDisplayStart: false,
    // dspVASystemStart: false,
    // endofFrame: false,
    // fwUpdate: false,
    // factoryReset: false,
    // hddFail: false,
    // hddFull: false,
    // hddNone: false,
    // internalHDDConnect: false,
    // internalHDDErase: false,
    // leftFanError: false,
    // netCamTrafficOverFlow: false,
    // network: false,
    // newFWAvailable: false,
    // overwriteDecoding: false,
    // passwordChange: false,
    // powerOn: false,
    // powerReboot: false,
    // hddRaidDeviceAdd: false,
    // hddRaidEvents: [
    //   {
    //   hddRaid: false,
    //   hddRaidBuildCancel: false,
    //   hddRaidBuildFail: false,
    //   hddRaidBuilding: false,
    //   hddRaidDegrade: false,
    //   hddRaidEnable: false,
    //   hddRaidFail: false,
    //   hddRaidRebuildEnd: false,
    //   hddRaidRebuildFail: false,
    //   hddRaidRebuildStart: false,
    //   hddRaidSetup: false,
    // }],
    // hddRaidRecordRestriction: false,
    // recordFiltering: false,
    // recordFrameDrop: false,
    // recording: false,
    // recordingError: false,
    // rightFanError: false,
    // timeChange: false,
    // usbHDDConnect: false,
    // iSCSIDisconnect: false,
  }),
  accessInfo: Map({
    recordStartAccess: true,
    recordStopAccess: true,
    ptzAccess: true,
    alarmOutputAccess: true,
    shutdownAccess: true,
  }),
  sslInfo: Map({
    // Policy: '',
    // PublicCertificateInstalled: false,
  }),
  usergroupInfo: Map({
    // admin 계정이 아닐 경우에만 사용합니다.
  }),
});

// reducer
export default handleActions({
  [SET_POS_EVENT]: (state, { payload: { posEvent } }) => (
    state.set('posEvent', fromJS(posEvent))
  ),
  [SET_ALARM_INPUT]: (state, { payload: { alarmInput } }) => (
    state.set('alarmInput', fromJS(alarmInput))
  ),
  [SET_ALARM_OUTPUT]: (state, { payload: { alarmOutput } }) => (
    state.set('alarmOutput', fromJS(alarmOutput))
  ),
  [SET_AUDIO_OUTPUT]: (state, { payload: { audioOutput } }) => (
    state.set('audioOutput', fromJS(audioOutput))
  ),
  [SET_SYSTEM_EVENT]: (state, { payload: { systemEvent } }) => (
    state.set('systemEvent', fromJS(systemEvent))
  ),
  [SET_ACCESS_INFO]: (state, { payload: { accessInfo } }) => (
    state.set('accessInfo', fromJS(accessInfo))
  ),
  [SET_SSL_INFO]: (state, { payload: { sslInfo } }) => (
    state.set('sslInfo', fromJS(sslInfo))
  ),
  [SET_USERGROUP_INFO]: (state, { payload: { usergroup } }) => (
    state.set('usergroupInfo', fromJS(usergroup))
  ),
}, initialState);
