import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, fromJS, Map } from 'immutable';
import { TextSearchActions } from 'store/actionCreators';
import { withRouter } from 'react-router-dom';

class EventListContainer extends React.Component {
  state = {
    eventListData: List([]),
    alarmStopEvent: true,
  }

  componentDidUpdate(nextProps) {
    const {
      posEvent: nextPosEventTemp,
      alarmStop: nextAlarmStop,
    } = nextProps;
    const {
      posEvent: posEventTemp,
      realTimeFilteredPosDeviceList,
      alarmStop,
      timeZone: propsTimeZone,
      tileCameraList,
      dstInfo: propsDstInfo,
    } = this.props;
    const {
      eventListData: curEventListData,
      alarmStopEvent,
    } = this.state;
    const eventListData = curEventListData.toJS();
    const nextPosEvent = nextPosEventTemp.toJS();
    const posEvent = posEventTemp.toJS();

    if (JSON.stringify(nextAlarmStop) !== JSON.stringify(alarmStop)) {
      this.ouUpdateAlarmStop(
        !alarmStopEvent,
      );
    }

    if (JSON.stringify(nextPosEvent) !== JSON.stringify(posEvent)) {
      if (posEvent.deviceName !== '') {
        let includedInTileList = false;
        for (let i = 0; i < tileCameraList.length; i += 1) {
          const targetChannel = tileCameraList[i].channel;
          if (posEvent.channelIDList[0] === 'All') {
            // all 설정일 경우 find 하지 않고 event 표시
            includedInTileList = true;
            break;
          }
          const isIncluded = posEvent.channelIDList.find(item => item === targetChannel);
          if (typeof isIncluded !== 'undefined') {
            includedInTileList = true;
            break;
          }
        }
        if (!includedInTileList) {
          // 발생한 pos event에 설정된 채널이 현재 layout page에 없다면 event를 표시하지 않음
          return;
        }
        let filterCheck = false;
        if (realTimeFilteredPosDeviceList[0] !== 'Nothing') {
          if (realTimeFilteredPosDeviceList.length === 0 || realTimeFilteredPosDeviceList[0] === 'textEvent' || realTimeFilteredPosDeviceList[0] === undefined) {
            filterCheck = true;
          } else {
            for (let i = 0; i < realTimeFilteredPosDeviceList[0].length; i += 1) {
              if (realTimeFilteredPosDeviceList[0][i] === posEvent.deviceName) {
                filterCheck = true;
                break;
              }
            }
          }
        }
        if (filterCheck) {
          const ChannelList = posEvent.channelIDList.reduce((arr, channel) => {
            if (channel === 'All') {
              return arr;
            }
            arr.push(channel + 1);
            return arr;
          }, []);
          const deviceTime = new Date(posEvent.receivedDate);
          // device와 같은 시간으로 나타내기 위해 GMT 시간 계산
          const timeZone = -(propsTimeZone);
          const tz = deviceTime.getTime()
            + (deviceTime.getTimezoneOffset() * 60000)
            + (timeZone * 3600000);
          deviceTime.setTime(tz);
          const dstInfo = propsDstInfo.toJS();
          const dstStart = new Date(
            deviceTime.getFullYear(),
            dstInfo.dstStartInfo.month - 1,
            1,
            dstInfo.dstStartInfo.hours,
            dstInfo.dstStartInfo.minutes,
            dstInfo.dstStartInfo.seconds,
          );
          const dateFromCalandar = (dayOfWeek, firstOfWeek, weekOfMonth) => (
            dayOfWeek < firstOfWeek
              ? (weekOfMonth) * 7 - firstOfWeek + (dayOfWeek + 1)
              : (weekOfMonth - 1) * 7 - firstOfWeek + (dayOfWeek + 1)
          );
          let firstDayOfMonth = new Date(
            dstStart.getFullYear(),
            dstStart.getMonth(),
            1,
          ).getDay(); // 해당 월의 1일이 무슨 요일

          dstStart.setDate(
            dateFromCalandar(dstInfo.dstStartInfo.dayOfWeek,
              firstDayOfMonth, dstInfo.dstStartInfo.weekOfMonth),
          );
          if (dstStart.getMonth() !== dstInfo.dstStartInfo.month - 1) {
            dstStart.setDate(dstStart.getDate() - 7);
          }
          const dstEnd = new Date(
            deviceTime.getFullYear(),
            dstInfo.dstEndInfo.month - 1,
            1,
            dstInfo.dstEndInfo.hours,
            dstInfo.dstEndInfo.minutes,
            dstInfo.dstEndInfo.seconds,
          );
          firstDayOfMonth = new Date(dstEnd.getFullYear(), dstEnd.getMonth(), 1).getDay();

          dstEnd.setDate(
            dateFromCalandar(dstInfo.dstEndInfo.dayOfWeek,
              firstDayOfMonth, dstInfo.dstEndInfo.weekOfMonth),
          );
          if (dstEnd.getMonth() !== dstInfo.dstEndInfo.month - 1) {
            dstEnd.setDate(dstEnd.getDate() - 7);
          }
          if (dstStart <= deviceTime && dstEnd >= deviceTime) {
            deviceTime.setHours(deviceTime.getHours() + 1);
          }
          // console.log('!!! DST', dstStart, dstEnd, deviceTime);
          const result = {
            id: posEvent.receivedDate,
            text: '',
            data: {
              Type: 'Text event',
              ChannelList,
              DeviceName: posEvent.deviceName,
              StartTime: posEvent.receivedDate,
              DeviceID: posEvent.deviceID,
              Data: posEvent.receipt,
              disableItem: false,
              deviceTime,
            },
          };
          if (eventListData.length === 10) {
            eventListData.pop();
          }
          eventListData.unshift(result);
          if (document.getElementById('live-eventlist-wrapper').parentNode) {
            document.getElementById('live-eventlist-wrapper').parentNode.scrollTop = 0;
          }
          this.onUpdateListData(fromJS(eventListData));
        }
      }
    }
  }

  onUpdateListData = eventListData => (
    this.setState({
      eventListData,
    })
  );

  ouUpdateAlarmStop = alarmStopEvent => (
    this.setState({
      alarmStopEvent,
    })
  );

  onClickDeleteItem = selectedItem => {
    const { eventListData } = this.state;
    const listData = eventListData.toJS();

    for (let i = 0; i < listData.length; i += 1) {
      if (listData[i].id === selectedItem) {
        listData.splice(i, 1);
      }
    }

    this.onUpdateListData(fromJS(listData));
  }

  onClickMoveToSearch = data => {
    const { history } = this.props;

    TextSearchActions.setLiveTextEventListSelect({
      channelList: data.ChannelList,
      localTime: data.deviceTime,
      deviceID: data.DeviceID,
    });

    history.push('/search');
  }

  render() {
    const { render } = this.props;
    const { eventListData } = this.state;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
        eventListData: eventListData.toJS(),
      },
    );
  }
}

EventListContainer.defaultProps = {
  posEvent: Map({}),
  realTimeFilteredPosDeviceList: [],
  alarmStop: true,
};

EventListContainer.propTypes = {
  render: PropTypes.func.isRequired,
  posEvent: PropTypes.instanceOf(Map),
  realTimeFilteredPosDeviceList: PropTypes.arrayOf(PropTypes.any),
  alarmStop: PropTypes.bool,
  timeZone: PropTypes.number.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  tileCameraList: PropTypes.instanceOf(Array).isRequired,
  dstInfo: PropTypes.instanceOf(Map).isRequired,
};

export default withRouter(connect(
  state => {
    const { currentNumber: currentLayoutPage } = state.liveMediaControlModule.get('layoutPageInfo').toJS();
    const stateTileCameraList = state.cameraInfoModule.get('tileCameraListPage');
    return ({
      tileCameraList: stateTileCameraList.get(`${currentLayoutPage}`).toJS(),
      lang: state.langModule.get('lang'),
      posEvent: state.systemInfomationModule.get('posEvent'),
      realTimeFilteredPosDeviceList: state.textSearchModule.get('realTimeFilteredPosDeviceList'),
      alarmStop: state.liveMediaControlModule.get('alarmStop'),
      timeZone: state.deviceInfoModule.get('timeZone'),
      dstInfo: state.deviceInfoModule.get('dstInfo'),
    });
  },
)(EventListContainer));
