import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PTZSequenceActions } from 'store/actionCreators';

class PTZGroupContainer extends React.Component {
  state = {
    currentGroupList: null,
    prevGroup: null,
  };

  componentWillReceiveProps(nextProps) {
    const {
      currentChannel,
      ptzInfo,
      groupList,
    } = nextProps;
    const { prevChannel } = this.props;
    if (currentChannel !== -1) {
      if ((groupList === null || prevChannel !== currentChannel)
        && (typeof ptzInfo !== 'undefined' && ptzInfo.group)) {
        // PTZSequenceActions.setGroupPending(true);
        const getData = {
          Channel: currentChannel,
        };

        PTZSequenceActions.getGroup(getData);

        PTZSequenceActions.setPrevChannel(currentChannel);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      currentChannel,
      groupList: nextGroupList,
      ptzInfo,
      isActive,
    } = nextProps;

    const {
      currentChannel: prevChannel,
      groupList,
    } = this.props;

    const {
      currentGroupList: currentGroupListState,
      prevGroup,
    } = nextState;
    const { currentGroupList: prevGroupListState } = this.state;

    document.getElementById('group_disable_mask').style.display = 'block';
    document.getElementById('group_wrapper').style.pointerEvents = 'none';

    if (typeof ptzInfo !== 'undefined' && ptzInfo.group) {
      document.getElementById('group_disable_mask').style.display = 'none';
      document.getElementById('group_wrapper').style.pointerEvents = 'auto';
    } else {
      document.getElementById('group_disable_mask').style.display = 'block';
      document.getElementById('group_wrapper').style.pointerEvents = 'none';
    }

    if ((!Number.isNaN(prevChannel) || !Number.isNaN(currentChannel)) && isActive) {
      if (prevChannel !== currentChannel) {
        if (prevChannel !== null && !Number.isNaN(prevChannel) && prevGroup !== null) {
          // 채널 변경시 이전 채널 동작 중지
          // const getData = {
          //   Channel: prevChannel,
          //   Mode: 'Stop',
          //   Group: prevGroup.no,
          // };
          // PTZSequenceActions.controlGroup(getData);

          // this.setState({
          //   prevGroup: null,
          // });
        }
        if (nextGroupList !== null) {
          this.setState({
            currentGroupList: this.makeGroupList(nextGroupList),
          });
          return true;
        }
        return false;
      }
      if (JSON.stringify(groupList) !== JSON.stringify(nextGroupList)) {
        // 채널 props 업데이트가 리스트 정보 업데이트보다 빨라서 별도 분기 처리.
        this.setState({
          currentGroupList: this.makeGroupList(nextGroupList),
        });
        return true;
      }
    }
    // 현재 탭에서 상태 변경 시
    if (prevGroupListState === null || currentGroupListState === null) {
      if (nextGroupList !== null && isActive) {
        this.setState({
          currentGroupList: this.makeGroupList(nextGroupList),
        });
        return true;
      }
      return false;
    }

    return true;
  }

  makeGroupList = groupList => {
    const array = [];
    for (let idx = 1; idx <= groupList.length; idx += 1) {
      array.push({
        index: idx - 1,
        no: idx,
        name: `Group ${idx}`,
        isSelected: false,
      });
    }
    return array;
  };

  onChangeData = (dataList, selectedData) => {
    let getData = {};
    const { currentChannel } = this.props;

    if (selectedData.isSelected) {
      getData = {
        Channel: currentChannel,
        Mode: 'Start',
        Group: selectedData.no,
      };
    } else {
      getData = {
        Channel: currentChannel,
        Mode: 'Stop',
        Group: selectedData.no,
      };
    }
    PTZSequenceActions.controlGroup(getData);

    this.setState({
      currentGroupList: dataList,
      prevGroup: selectedData,
    });
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

PTZGroupContainer.propTypes = {
  render: PropTypes.func.isRequired,
  groupList: PropTypes.arrayOf(Object),
  currentChannel: PropTypes.number,
  prevChannel: PropTypes.number,
  ptzInfo: PropTypes.objectOf(PropTypes.any),
  isActive: PropTypes.bool,
};

PTZGroupContainer.defaultProps = {
  groupList: [],
  currentChannel: -1,
  prevChannel: -1,
  ptzInfo: {},
  isActive: false,
};

export default connect(
  state => ({
    isGroupPending: state.ptzSequenceModule.get('isGroupPending'),
    groupList: state.ptzSequenceModule.get('groupList'),
    lang: state.langModule.get('lang'),
    prevChannel: state.ptzSequenceModule.get('prevChannel'),
  }),
)(PTZGroupContainer);
