import styled, { withTheme } from 'styled-components';
import {
  Label,
  Input,
  Checkbox,
} from 'wisenet-ui/components/atoms';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 10px;
  padding-bottom: 5px;
`;

export const KeywordLabel = styled(Label)`
  font-size: 12px;
  margin-left: 15px;
  margin-top: 10px;
`;

export const KeywordInput = styled(Input)`
  width: 188px;
  height: 30px;
  margin-top: 10px;
  align-self: center;
  border: 0px;
  border-bottom: 1px solid ${props => props.theme.line.color1};;
`;

export const OptionLabel = styled(Label)`
  font-size: 12px;
`;

export const OptionCheckbox = styled(Checkbox)`
  font-size: 12px;
  margin-left: 3px;
  margin-right: 7px;
  margin-bottom: 1px;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
  padding: 0px;
`;

export const KeywordTreeWrapper = withTheme(styled.div`
  border: 0px;
  width: 188px;
  height: 130px;
  overflow: auto;
  align-self: center;
  margin-top: 10px;
`);

export const HrStyled = withTheme(styled.hr`
  width: 188px;
  height: 1px;
  color: ${props => props.theme.line.color1};
  margin: 0px 15px 0px 15px;
`);
