import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  PreLoadActions,
  MessageBoxActions,
  ThemeActions,
  LangActions,
} from 'store/actionCreators';

class PreLoaderContainer extends React.Component {
  componentDidMount() {
    LangActions.getCurrentLanguageBeforeLogin();
    PreLoadActions.browserCheck();
    if (
      typeof localStorage.getItem('WISENET-NVR-THEME') === 'undefined'
      || localStorage.getItem('WISENET-NVR-THEME') === null
    ) {
      localStorage.setItem('WISENET-NVR-THEME', 'white');
    }

    const themeName = localStorage.getItem('WISENET-NVR-THEME');
    ThemeActions.changeTheme({
      theme: themeName,
      setStorage: true,
    });
  }

  umpScriptOnload = ({ scriptTags }) => {
    if (scriptTags) {
      const scriptTag = scriptTags[0];
      PreLoadActions.fileUmpLoadCheck(scriptTag);
    }
  }

  onMessageBoxConfirm = () => MessageBoxActions.controlMessageBox({
    messageBoxInfo: {
      title: '',
      content: '',
      isOpen: false,
    },
  });

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

PreLoaderContainer.propTypes = {
  render: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    theme: state.themeModule.get('theme'),
    pagePendding: state.loadingModule.get('pagePendding'),
    messageBoxInfo: state.messageBoxModule.get('messageBoxInfo'),
    languageLoaded: state.langModule.get('languageLoaded'),
  }),
  () => ({}),
)(PreLoaderContainer);
