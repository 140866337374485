import React, { useEffect } from 'react';
import { withError } from 'wisenet-ui/util/hoc';
import PropTypes from 'prop-types';
import {
  SketchbookDefaultStyled,
  SketchbookAbsoluteStyled,
  CraypasRectStyled,
  CraypasCircleStyled,
} from './SketchbookStyled';

const Sketchbook = ({
  children,
  shape,
  updateObject,
  objects,
  readonly,
  aim,
  aimEdge,
  screenwidth,
  screenheight,
}) => {
  useEffect(() => {
    console.log('Sketchbook DidMount');
  }, []);
  return (
    <SketchbookDefaultStyled width="100%" height="100%">
      { children }
      <SketchbookAbsoluteStyled id="sketchbook">
        {
          (shape === 'rect')
            ? <CraypasRectStyled id="craypasrect" updateObject={updateObject} screenwidth={screenwidth} screenheight={screenheight} objects={objects} readonly={readonly} aim={aim} aimEdge={aimEdge} />
            : <CraypasCircleStyled id="craypascircle" updateObject={updateObject} screenwidth={screenwidth} screenheight={screenheight} objects={objects} readonly={readonly} aim={aim} aimEdge={aimEdge} />
        }
      </SketchbookAbsoluteStyled>
    </SketchbookDefaultStyled>
  );
};

Sketchbook.propTypes = {
  children: PropTypes.node,
  shape: PropTypes.string,
  updateObject: PropTypes.func,
  objects: PropTypes.arrayOf(PropTypes.object),
  readonly: PropTypes.bool,
  aim: PropTypes.bool,
  aimEdge: PropTypes.bool,
  screenwidth: PropTypes.number,
  screenheight: PropTypes.number,
};

Sketchbook.defaultProps = {
  shape: 'rect',
  updateObject: null,
  objects: [],
  children: null,
  readonly: false,
  aim: false,
  aimEdge: false,
  screenwidth: 100,
  screenheight: 100,
};

export default withError(Sketchbook);
