import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SearchTimelineActions,
  TextSearchActions,
} from 'store/actionCreators';

class SearchResultContainer extends React.Component {
  state = {
    resultSideWidth: '316px',
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) === JSON.stringify(this.props)
      && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const { currentTabName } = this.props;
    const { currentTabName: prevCurrentTabName } = prevProps;
    if (currentTabName !== prevCurrentTabName) {
      this.initializeTabData();
    }
  }

  initializeTabData = () => {
    // 어떤 탭이든 상관없이
    // 1. 영상화면 초기화
    // 2. 결과화면 초기화
    // 3. 영수증 화면 초기화
    SearchTimelineActions.setCurrentChannel(-1);
    SearchTimelineActions.setSelectEvent({});
    TextSearchActions.controlMetaDataRenewStop();
  }

  onResizeSidebar = (e, direction, ref) => {
    this.setState({
      resultSideWidth: ref.style.width,
    });
  }

  render() {
    const { render } = this.props;
    return render({
      ...this,
      ...this.state,
      ...this.props,
    });
  }
}

SearchResultContainer.propTypes = {
  render: PropTypes.func.isRequired,
  currentTabName: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    isFullscreen: state.layoutModule.get('isFullscreen'),
  }),
)(SearchResultContainer);
