import React from 'react';
import PropTypes from 'prop-types';
import { List, fromJS } from 'immutable';
import staticLayoutPattern from 'wisenet-ui/util/static/constants/layoutPattern/staticLayoutPattern';

class VideoLayoutContainer extends React.Component {
  state = {
    rows: 1,
    cols: 1,
    margin: [0, 0],
    tileList: List([]),
    emptyTileList: List([]),
    height: 900,
    width: 1200,
    // verticalCompact: false,
    preventCollision: true,
    useCSSTransforms: true,
    // autoSize: false,
  };

  componentDidMount() {
    const { pattern } = this.props;
    this.changePattern(pattern);
  }

  componentDidUpdate(prevProps, prevState) { // prevProps, prevState, snapshot
    const {
      pattern: prevPattern,
      isReset: prevReset,
    } = prevProps;

    const {
      tileList: propsTileList,
      pattern,
      isReset,
    } = this.props;

    const {
      tileList: stateTileList,
    } = this.state;

    const {
      tileList: prevStateTileList,
    } = prevState;

    const tileList = stateTileList.toJS();

    const stateTileListLength = tileList.length;

    if (stateTileListLength < propsTileList.length) {
      const newTile = propsTileList[stateTileListLength];
      if (newTile.index) { // i는 lib에서 특수한 key값으로 사용
        const removeTile = this.removeLayoutEmpty(newTile.index);
        tileList.push({
          ...removeTile,
          ...newTile,
          empty: false,
          isDraggable: pattern !== 'SPECIAL',
          static: !!newTile.static, // true
        });

        this.onUpdate({
          tileList: fromJS(tileList),
        });
      } else {
        const emptyCheckArr = this.haveLayoutEmpty();

        if (emptyCheckArr) {
          tileList.push({
            ...emptyCheckArr,
            channel: newTile.channel,
            empty: false,
            isDraggable: pattern !== 'SPECIAL',
            static: !!newTile.static, // true
            uid: newTile.uid,
          });
          this.onUpdate({
            tileList: fromJS(tileList),
          });
        }
      }
    } else if (stateTileListLength > propsTileList.length) {
      tileList.some((tile, tileIndex) => {
        const findTile = propsTileList.find(propsTile => (
          propsTile.uid === tile.uid
        ));
        if (!findTile) {
          const {
            emptyTileList: stateEmptyTileList,
          } = this.state;
          const emptyTileList = stateEmptyTileList.toJS();

          const emptyTile = {
            ...tileList.splice(tileIndex, 1)[0],
            channel: null,
            empty: true,
            isDraggable: false,
          };

          emptyTileList.splice(emptyTile.index, 0, emptyTile);
          emptyTileList.sort((a, b) => a.index - b.index);

          this.onUpdate({
            tileList: fromJS(tileList),
            emptyTileList: fromJS(emptyTileList),
          });
          return true;
        }
        return false;
      });
    } else if (((stateTileListLength === propsTileList.length) && propsTileList.length !== 0) && (
      prevReset !== isReset
    )) {
      if (isReset) {
        const emptyTileList = [];
        const newTileList = [];
        const patternInfo = staticLayoutPattern[pattern];

        const {
          items,
        } = patternInfo;

        items.map((item, index) => {
          const findTile = propsTileList.find(tile => tile.index === index);
          if (!findTile) {
            emptyTileList.push({
              ...item,
              i: `${index.toString()}-${Math.floor((Math.random() * 1000) + 1)}`,
              empty: true,
              isDraggable: false,
              isResizable: false,
              static: false,
              index,
            });
          } else {
            newTileList.push({
              ...item,
              ...findTile,
            });
          }
          return null;
        });

        this.onUpdate({
          tileList: fromJS(newTileList),
          emptyTileList: fromJS(emptyTileList),
        });
      }
    } else if (JSON.stringify(stateTileList) !== JSON.stringify(prevStateTileList) && pattern !== 'SPECIAL') {
      const {
        setCurrentTileList,
      } = this.props;
      setCurrentTileList({
        layoutPositionInfoList: fromJS(tileList),
      });
    }

    if (prevPattern !== pattern) {
      this.changePattern(pattern);
    }

    if (isReset && stateTileListLength !== 0) {
      const patternInfo = staticLayoutPattern[pattern];
      const { items } = patternInfo;

      this.onUpdate({
        tileList: fromJS([]),
        emptyTileList: fromJS(items.map((item, index) => (
          {
            ...item,
            i: `${index.toString()}-${Math.floor((Math.random() * 1000) + 1)}`,
            empty: true,
            isDraggable: false,
            isResizable: false,
            static: false,
            index,
          }
        ))),
      });
    }
  }

  changePattern = pattern => {
    const emptyTileList = [];
    const { tileList: stateTileList } = this.state;
    const tileList = stateTileList.toJS();
    const patternInfo = staticLayoutPattern[pattern];

    const {
      rows,
      cols,
      items,
    } = patternInfo;

    if (pattern === 'SPECIAL') {
      const { selectUid } = this.props;
      const findTile = tileList.find(tile => tile.uid === selectUid);
      this.setState({
        cols,
        rows,
        emptyTileList: fromJS(findTile ? emptyTileList : [{
          ...items[0],
          i: `${0}-${Math.floor((Math.random() * 1000) + 1)}`,
          empty: true,
          isDraggable: false,
          isResizable: false,
          static: false,
          index: 0,
        }]),
        tileList: fromJS(findTile ? [findTile] : []),
      });
    } else {
      items.map((item, index) => {
        const findTileIndex = tileList.findIndex(tile => tile.index === index);
        if (findTileIndex === -1) {
          emptyTileList.push({
            ...item,
            i: `${index.toString()}-${Math.floor((Math.random() * 1000) + 1)}`,
            empty: true,
            isDraggable: false,
            isResizable: false,
            static: false,
            index,
          });
        } else {
          tileList[findTileIndex] = {
            ...tileList[findTileIndex],
            ...item,
          };
        }
        return null;
      });

      this.setState({
        cols,
        rows,
        emptyTileList: fromJS(emptyTileList),
        tileList: fromJS(tileList),
      });
    }
  }

  haveLayoutEmpty = () => {
    const {
      emptyTileList: stateEmptyTileList,
    } = this.state;

    const emptyTileList = stateEmptyTileList.toJS();

    const emptyTile = emptyTileList.shift();
    this.setState({
      emptyTileList: fromJS(emptyTileList),
    });

    return emptyTile;
  }

  removeLayoutEmpty = index => {
    const {
      emptyTileList: stateEmptyTileList,
    } = this.state;

    const emptyTileList = stateEmptyTileList.toJS();
    const findEmptyTileIndex = emptyTileList.findIndex(emptyTile => emptyTile.index === index);
    if (findEmptyTileIndex !== -1) {
      const removeTile = emptyTileList.splice(findEmptyTileIndex, 1);

      this.setState({
        emptyTileList: fromJS(emptyTileList),
      });
      return removeTile[0];
    }
    return null;
  }

  setComponentSize = ({ height, width }) => {
    this.setState({
      height,
      width,
    });
  }

  makeDragItemDragEndEvent = data => (
    new CustomEvent('dragItemDragEnd', {
      bubbles: true,
      cancelable: true,
      detail: data,
    })
  );

  getStaticTile = childDom => {
    if (!childDom) {
      return null;
    }

    if (childDom.className.indexOf('react-grid-item') !== -1
      || childDom.className.indexOf('react-grid-layout') !== -1) {
      return childDom;
    }

    return this.getStaticTile(childDom.parentElement);
  }

  moveCheck = type => (_, oldItem, newItem, p, e, el) => {
    switch (type) {
      case 'dragStart':
        this.selectElementTransform = null;
        break;
      case 'drag': {
        // 이동중인 item의 위치 정보 기록
        this.selectElementTransform = el.style.transform;
        break;
      }
      case 'dragStop': {
        if (this.selectElementTransform) {
          const element = el;
          element.style.pointerEvents = 'none';
          element.style.display = 'none';
          const findDom = document.elementFromPoint(e.clientX, e.clientY);
          element.style.display = 'flex';

          if (findDom && findDom.className.indexOf('react-grid-placeholder') === -1) {
            const findStaticDom = this.getStaticTile(findDom);
            if (findStaticDom) {
              const {
                tileList: stateTileList,
                emptyTileList: stateEmptyTileList,
              } = this.state;

              const allTileList = [...stateTileList.toJS(), ...stateEmptyTileList.toJS()];
              const fromEvent = this.makeDragItemDragEndEvent({
                fromData: allTileList.find(tile => tile.i === oldItem.i),
              });
              const changeEvent = this.makeDragItemDragEndEvent({ isChange: true });

              // 옮기려는 곳과 옮겨지는 장소에 대해서 style 변경
              findStaticDom.dispatchEvent(fromEvent);
              findStaticDom.style.zIndex = 10;
              element.dispatchEvent(changeEvent);
              element.style.zIndex = 20;
              // 이동하려고 하는 장소의 위치 값 입력
              findStaticDom.style.transform = element.style.transform;
              element.style.transform = this.selectElementTransform;
              element.style.trasition = 'all 700ms ease';
              findStaticDom.style.trasition = 'all 700ms ease';

              // 옮기려는 곳과 옮겨지는 장소에 대해서 style 복구
              setTimeout(() => {
                const ele = el; // lint error prevention
                ele.style.zIndex = '';
                findStaticDom.style.zIndex = '';
                element.style.trasition = 'none';
                findStaticDom.style.trasition = 'none';
              }, 700);
            }
          }
          element.style.pointerEvents = 'inherit';
        }
        break;
      }
      default:
        break;
    }
  }

  dragItemDragEnd = event => toData => {
    event.preventDefault();
    const { tileChange } = this;
    tileChange(event.detail.fromData, toData);
  }

  tileChange = (fromData, toData) => {
    const {
      tileList: stateTileList,
      emptyTileList: stateEmptyTileList,
    } = this.state;
    const { tileItemChange } = this;
    const tileList = stateTileList.toJS();
    const emptyTileList = stateEmptyTileList.toJS();
    const dragAfterTileList = tileList.map(tileItemChange(fromData, toData));
    const dragAfterEmptyTileList = emptyTileList.map(tileItemChange(fromData, toData));

    dragAfterEmptyTileList.sort((a, b) => a.index - b.index);
    setTimeout(() => this.onUpdate({
      tileList: fromJS(dragAfterTileList),
      emptyTileList: fromJS(dragAfterEmptyTileList),
    }));
  }

  tileItemChange = (fromData, toData) => tile => {
    if (tile.i === fromData.i) {
      return {
        ...tile,
        x: toData.x,
        y: toData.y,
        w: toData.w,
        h: toData.h,
        index: toData.index,
      };
    }

    if (tile.i === toData.i) {
      return {
        ...tile,
        x: fromData.x,
        y: fromData.y,
        w: fromData.w,
        h: fromData.h,
        index: fromData.index,
      };
    }
    return tile;
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  );

  render() {
    const {
      render,
    } = this.props;

    const {
      height,
      rows,
      cols,
      tileList: stateTileList,
      emptyTileList: stateEmptyTileList,
      width,
    } = this.state;

    const rowHeight = Math.ceil(height / rows);
    const itemWidth = Math.ceil(width / cols);

    const tileList = fromJS([...stateTileList.toJS(), ...stateEmptyTileList.toJS()]);

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
        tileList,
        rowHeight,
        itemWidth,
      },
    );
  }
}

VideoLayoutContainer.defaultProps = {
  pattern: 'SPECIAL',
  selectUid: '0',
  isReset: false,
  setCurrentTileList: () => {},
};

VideoLayoutContainer.propTypes = {
  render: PropTypes.func.isRequired,
  pattern: PropTypes.string,
  tileList: PropTypes.oneOfType([PropTypes.any]).isRequired,
  selectUid: PropTypes.string,
  isReset: PropTypes.bool,
  setCurrentTileList: PropTypes.func,
};

export default VideoLayoutContainer;
