import React from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { withContainer } from 'wisenet-ui/util/hoc';
import { FlexiblePage, Popover } from 'wisenet-ui/components/organisms';
import { getLanguage } from 'util/lib';
// import { LiveEventFilter, LayoutListButton } from 'wisenet-ui/components/molecules';
import { PopupContent } from 'components/molecules';
import { LivePageContainer } from 'containers/pages';
import {
  // EventTabWrapper,
  PTZTab,
  LiveVideoLayout,
  LiveCameraList,
  LayoutList,
  EventList,
  LiveMediaControlBar,
} from 'components/organisms';
import LiveEventFilter from '../../../components/molecules/LiveListItems/LiveEventFilter';
import LayoutListButton from '../../../components/molecules/LiveListItems/LayoutListButton';
import {
  TabHeaderWrapperStyled,
  TabIconStyled,
  TabTitleStyled,
  ContentStyled,
  SideControlButtonStyled,
  SideControlIconStyled,
  LivePageWrapperStyled,
  SideComponentWrapperStyled,
  SideControlWrapperStyled,
  IconStyled,
  RndStyled,
  ListTabsStyled,
  ListAccordionStyled,
  PTZAccordionStyled,
  HeadTitleStyled,
  PopupWrapper,
  PopupButtonStyled,
  PopupContentWrapperStyled,
  CustomReactTooltip,
  TooltipSpan,
  LoadingStyled,
} from './LivePageStyled';

const AccordionHeaderHeight = '45px';

const LivePage = ({
  // listData,
  selectedChannel,
  dragTileCameraList,
  setDragTileCamera,
  layoutListGetData,
  selectedLayoutListItem,
  addingLayoutListItem,
  updateSelectedLayoutListItem,
  onClickLayoutListCtrlButton,
  onKeyPress,
  isFullscreen,
  onChangeFolding,
  isFolding,
  updateInputValue,
  setRef,
  realTimeFilteredPosDeviceList,
  authDropdownVisible,
  onChangeAuthDropdownVisible,
  accordionState,
  onAccordionClick,
  onResize,
  ptzAccordionHeight,
  isInitListHeight,
  ptzAccordionInitHeight,
  onChangeSelectedTab,
  showLayoutPopup,
  layoutPopupData,
  onLayoutListPopupConfirm,
  onLayoutListPopupCancel,
  isSafari,
  cameraListPendding,
}) => {
  const ptzHeight = (isFullscreen && ptzAccordionHeight === 'calc(100vh - 83px - 65%)') ? 'calc(100vh - 65%)' : ptzAccordionHeight;
  const listAccordionItems = [
    {
      id: 'camera',
      headerLeft: <HeadTitleStyled>{getLanguage('lang_camera')}</HeadTitleStyled>,
      content: <LiveCameraList setDragTileCamera={setDragTileCamera} />,
      isOpen: accordionState.camera,
    },
    {
      id: 'layout',
      headerLeft: <HeadTitleStyled>{getLanguage('lang_layout')}</HeadTitleStyled>,
      headerRight: (
        <LayoutListButton
          onClickLayoutListCtrlButton={onClickLayoutListCtrlButton}
          addingNewLayoutList={addingLayoutListItem}
          layoutListData={layoutListGetData}
          selectedLayoutListItem={selectedLayoutListItem}
        />
      ),
      content: (
        <LayoutList
          layoutList={layoutListGetData}
          addingNewLayoutList={addingLayoutListItem}
          updateSelectedLayoutListItem={updateSelectedLayoutListItem}
          updateInputValue={updateInputValue}
        />
      ),
      isOpen: accordionState.layout,
    },
  ];

  const eventAccordionItems = isSafari ? [] : [
    {
      id: 'event',
      headerLeft: <HeadTitleStyled>{getLanguage('lang_event')}</HeadTitleStyled>,
      headerRight: (
        <Popover
          isOpen={authDropdownVisible}
          onChangeOpenStatus={onChangeAuthDropdownVisible}
          onClickOutside={onChangeAuthDropdownVisible}
          padding={10}
          align="center"
          content={(
            <LiveEventFilter
              realTimeFilteredPosDeviceList={realTimeFilteredPosDeviceList}
              onCloseFilter={onChangeAuthDropdownVisible}
            />
          )}
        >
          <IconStyled
            onClick={onChangeAuthDropdownVisible}
            className="wni wni-filter"
            data-tip="event-list-filter"
            data-for="event-list-filter"
          />
          {/* <>
            <IconStyled
              onClick={onChangeAuthDropdownVisible}
              className="wni wni-filter"
              data-tip="filter"
              data-for="filter"
            />
            <CustomReactTooltip
              id="filter"
              type="light"
              place="top"
              effect="float"
              delayShow={500}
              getContent={() => (
                <TooltipSpan>{getLanguage('lang_filter')}</TooltipSpan>
              )}
            />
          </> */}
        </Popover>
      ),
      content: <EventList
        realTimeFilteredPosDeviceList={realTimeFilteredPosDeviceList}
      />,
      isOpen: accordionState.event,
    },
  ];

  const ptzAccordionItems = [
    {
      id: 'ptz',
      headerLeft: <HeadTitleStyled>{getLanguage('lang_ptz')}</HeadTitleStyled>,
      content: <PTZTab selectedChannel={selectedChannel} />,
      isOpen: accordionState.ptz,
    },
  ];

  const tabData = [
    {
      key: 'tab_list',
      name: 'listTab',
      header: (
        <TabHeaderWrapperStyled>
          <TabIconStyled className="wni wni-list-view" />
          <TabTitleStyled>{getLanguage('lang_list')}</TabTitleStyled>
        </TabHeaderWrapperStyled>
      ),
      component: (
        <ListAccordionStyled
          items={listAccordionItems}
          onClick={onAccordionClick}
        />
      ),
      onSelectedTab: onChangeSelectedTab,
    },
    {
      key: 'tab_event',
      name: 'eventTab',
      disabled: isSafari,
      header: (
        <TabHeaderWrapperStyled isLast>
          <TabIconStyled className="wni wni-alarm" />
          <TabTitleStyled>{getLanguage('lang_event')}</TabTitleStyled>
        </TabHeaderWrapperStyled>
      ),
      component: (
        <ListAccordionStyled
          items={eventAccordionItems}
          onClick={onAccordionClick}
        />
      ),
      onSelectedTab: onChangeSelectedTab,
    },
  ];
  return (
    <LivePageWrapperStyled
      tabIndex="-1"
      onKeyPress={onKeyPress}
      noPadding={(isFolding || isFullscreen)}
    >
      <FlexiblePage
        headerSize={isFullscreen ? 0 : 60}
        sideFolding
        isFolding={isFolding}
        sideControlComponent={(
          <SideControlWrapperStyled
            isVideoOver={isFullscreen && isFolding}
            isFullscreen={isFullscreen}
          >
            <SideControlButtonStyled
              onClick={onChangeFolding}
            >
              <SideControlIconStyled
                className="wni wni-side-showhidden"
                isFolding={isFolding}
              />
            </SideControlButtonStyled>
          </SideControlWrapperStyled>
        )}
        defaultSideWidth={292}
        sideComponent={(
          <SideComponentWrapperStyled
            isFullscreen={isFullscreen}
          >
            <RndStyled
              enableResizing={{
                bottom: accordionState.ptz,
                bottomLeft: false,
                bottomRight: false,
                left: false,
                right: false,
                top: false,
                topLeft: false,
                topRight: false,
              }}
              disableDragging
              size={{
                width: '100%',
              }}
              initheight={isInitListHeight.toString()}
              isfullscreen={isFullscreen.toString()}
              onResize={(e, direction, ref) => onResize(e, ref)}
            >
              <ListTabsStyled tabData={tabData} />
            </RndStyled>
            <PTZAccordionStyled
              items={ptzAccordionItems}
              onClick={onAccordionClick}
              style={{
                flexBasis: accordionState.ptz ? ptzHeight : AccordionHeaderHeight,
              }}
              minHeight={AccordionHeaderHeight}
              maxHeight={ptzAccordionInitHeight}
              innerRef={ref => setRef(ref, 'ptzAccordionEl')}
            />
          </SideComponentWrapperStyled>
        )}
      >
        <ContentStyled>
          <LiveVideoLayout
            dragTileCameraList={dragTileCameraList}
            setDragTileCamera={setDragTileCamera}
          />
          {
            !isFullscreen && (
              <LiveMediaControlBar />
            )
          }
        </ContentStyled>
      </FlexiblePage>
      <PopupWrapper
        isOpen={showLayoutPopup}
        shouldCloseOnOverlayClick={layoutPopupData.shouldCloseOnOverlayClick}
      >
        <PopupContent
          title={layoutPopupData.title}
          hasTopCloseButton
          bottomButtons={[
            (
              <PopupButtonStyled
                key="layoutConfirmButton"
                onClick={onLayoutListPopupConfirm}
              >
                {getLanguage('lang_ok')}
              </PopupButtonStyled>
            ),
            layoutPopupData.showCancel && (
              <PopupButtonStyled
                key="layoutCancelButton"
                onClick={onLayoutListPopupCancel}
                style={{
                  marginLeft: '4px',
                }}
              >
                {getLanguage('lang_cancel')}
              </PopupButtonStyled>
            ),
          ]}
          onClosePopup={
            layoutPopupData.showCancel ? onLayoutListPopupCancel : onLayoutListPopupConfirm
          }
        >
          <PopupContentWrapperStyled>
            {layoutPopupData.content}
          </PopupContentWrapperStyled>
        </PopupContent>
      </PopupWrapper>
      <CustomReactTooltip
        id="event-list-filter"
        type="light"
        place="top"
        effect="float"
        delayShow={500}
        getContent={() => (
          <TooltipSpan>{getLanguage('lang_filter')}</TooltipSpan>
        )}
      />
      {cameraListPendding && <LoadingStyled />}
    </LivePageWrapperStyled>
  );
};

LivePage.propTypes = {
  // lang: PropTypes.objectOf(PropTypes.string).isRequired,
  // listData: PropTypes.instanceOf(Array),
  selectedChannel: PropTypes.instanceOf(Map).isRequired,
  dragTileCameraList: PropTypes.instanceOf(List).isRequired,
  setDragTileCamera: PropTypes.func.isRequired,
  layoutListGetData: PropTypes.instanceOf(List).isRequired,
  selectedLayoutListItem: PropTypes.instanceOf(Object),
  addingLayoutListItem: PropTypes.bool,
  updateSelectedLayoutListItem: PropTypes.func.isRequired,
  onClickLayoutListCtrlButton: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  onChangeFolding: PropTypes.func.isRequired,
  isFolding: PropTypes.bool.isRequired,
  updateInputValue: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  realTimeFilteredPosDeviceList: PropTypes.arrayOf(PropTypes.any),
  authDropdownVisible: PropTypes.bool.isRequired,
  onChangeAuthDropdownVisible: PropTypes.func.isRequired,
  accordionState: PropTypes.instanceOf(Object).isRequired,
  onAccordionClick: PropTypes.func.isRequired,
  onResize: PropTypes.func.isRequired,
  ptzAccordionHeight: PropTypes.string.isRequired,
  ptzAccordionInitHeight: PropTypes.string.isRequired,
  onChangeSelectedTab: PropTypes.func.isRequired,
  isInitListHeight: PropTypes.bool.isRequired,
  showLayoutPopup: PropTypes.bool.isRequired,
  layoutPopupData: PropTypes.instanceOf(Object),
  onLayoutListPopupConfirm: PropTypes.func.isRequired,
  onLayoutListPopupCancel: PropTypes.func.isRequired,
  isSafari: PropTypes.bool.isRequired,
  cameraListPendding: PropTypes.bool.isRequired, // List가 없을 때는 live page 동작을 못하도록 막는다.
};

LivePage.defaultProps = {
  // listData: [{
  //   name: '',
  //   checked: false,
  //   icon: '',
  //   enable: '',
  //   deviceType: '',
  //   deviceName: '',
  //   additional: '',
  // }],
  selectedLayoutListItem: [],
  addingLayoutListItem: false,
  realTimeFilteredPosDeviceList: [],
  layoutPopupData: {},
};


export default withContainer(LivePageContainer, LivePage);
