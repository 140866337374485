import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { SetupPageContainer } from 'containers/pages';
import { PHPSetupPage } from 'pages';
import { SetupRoute } from 'routes';
import { SideMenu, FlexiblePage } from 'wisenet-ui/components/organisms';

const defaultPath = ['basic'];

const SetupPage = ({
  setupMenu,
  location,
  phpSetupPage,
  // sidebarPosition,
}) => {
  const contents = process.env.NODE_ENV === 'production' ? (<></>) : (
    <FlexiblePage
      defaultSideWidth={316}
      sideComponent={(
        <SideMenu data={setupMenu} location={location} defaultPath={defaultPath} />
      )}
    >
      <SetupRoute data={setupMenu} />
    </FlexiblePage>
  );
  return (
    <>
      {phpSetupPage ? <PHPSetupPage phpSetupPage={phpSetupPage} /> : contents}
    </>
  );
};

SetupPage.propTypes = {
  setupMenu: PropTypes.instanceOf(Array),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  // sidebarPosition: PropTypes.string,
  phpSetupPage: PropTypes.string,
};

SetupPage.defaultProps = {
  setupMenu: [],
  // sidebarPosition: 'left',
  phpSetupPage: null,
};

export default withContainer(SetupPageContainer, SetupPage);
