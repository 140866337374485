import styled, { withTheme } from 'styled-components';
import { Button, ReactModalAdapter } from 'wisenet-ui/components/atoms';

export const StyledPopup = styled(ReactModalAdapter).attrs({
  className: 'Popup',
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  
  // 팝업 바깥 영역의 스타일 지정
  .ReactModal__Overlay {
    width: 100%;
    height: 100%;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    background-color: rgba(0,0,0,.5) !important;
  }

  // 팝업 내부 영역의 스타일 지정
  .ReactModal__Content {
    top: 50% !important;
    left: 50% !important;
    width: 292px;
    height: 207px;
    transform: translate(-50%, -50%) !important;
    background-color: ${props => props.theme.background.color1}!important;
    border: 1px solid ${props => props.theme.line.color5} !important;
    border-radius: 0px !important;
  }

  .ReactModal__Content--after-open {
    padding: 0px !important;
    overflow: hidden !important;
  }
`;

export const StyledButton = withTheme(styled(Button)`
  border-left-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  /* border-color: ${props => props.theme.text.color1}; */
  text-align: center;
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  min-width: 70px;
  height: 30px;
  padding: 0px;
  margin-left: 5px;
`);

export const Container = withTheme(styled.div`
  display: flex;
  padding: 15px;
  flex-direction: column;
  width: 100%;
  height: 100%;
`);

export const TitleAreaStyled = withTheme(styled.div`
  flex-grow: 0;
  font-family: AppleSDGothicNeo;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
`);

export const ContentsAreaStyled = withTheme(styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  word-break: break-all;
`);

export const ButtonAreaStyled = withTheme(styled.div`
  display: flex;
  align-self: center;
  flex-grow: 0;
`);

export const HrStyled = withTheme(styled.hr`
  width: 100%;
  height: 1px;
  color: ${props => props.theme.line.color1};
`);
