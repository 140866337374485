import {
  take,
  put,
  all,
} from 'redux-saga/effects';
import { SunapiClient } from 'util/lib';
import * as statusPopupActions from 'store/modules/statusPopup/statusPopupModule';

const codecToString = value => {
  let result = '-';
  switch (value) {
    case 1:
      result = 'MJPEG';
      break;
    case 2:
      result = 'MPEG4';
      break;
    case 3:
      result = 'H.264';
      break;
    case 4:
      result = 'H.265';
      break;
    default:
      result = '-';
      break;
  }
  return result;
};

// 추후 다국어 key 값으로 변경
const statusToString = value => {
  let result = '-';
  switch (value) {
    case 0:
      result = 'lang_disconnected';
      break;
    case 1:
      result = 'lang_connected';
      break;
    default:
      result = '-';
      break;
  }
  return result;
};

const recordTypeToString = value => {
  let result = '-';
  if (value < 0) {
    result = '-';
  } else {
    switch (value) {
      case 0:
        result = '-';
        break;
      case 1:
      case 2:
        result = 'lang_nomal';
        break;
      default:
        result = 'lang_event';
        break;
    }
  }
  return result;
};

const recordFrameToString = value => {
  let result = '-';
  switch (value) {
    case 0:
      result = 'lang_full';
      break;
    case 1:
      result = 'lang_i_frame';
      break;
    default:
      result = '-';
      break;
  }
  return result;
};

const setColors = value => {
  // exceededInputData: item.orange,
  // profileError: item.yellow,
  // exceededHddCapacity: item.violet,
  let color = '';
  let backgroundColor = '';
  if (Number(value.orange)) {
    color = '#e12d33';
  } else if (Number(value.violet)) {
    color = '#4b37be';
  }
  if (Number(value.yellow)) {
    backgroundColor = 'rgba(255, 229, 182, 0.9)';
  }
  return {
    color,
    backgroundColor,
  };
};

/*
  cgi로 status 구현 TBD(Record status cgi 구현 안됨)
  codec, resolution, framerate 표기 사양
  해당 채널의 profilePolicies data가 없는 경우 표기 불가
  VideoStatus가 Covert1, Covert2, Off인 상태에서는 표기하지 않음
  Analog camera의 경우 표기하지 않음
function* convertLiveStatusData(camera, profilePolicies) {
  let defaultStatus = {
    codec: '-',
    resolution: '-',
    framerate: '-',
  };
  const profilePolicy = profilePolicies.find(item => item.Channel === camera.Channel);
  if (
    profilePolicy
    && camera.VideoState === 'On'
    && camera.Protocol !== 'ANALOG'
  ) {
    const reqData = {
      Channel: camera.Channel,
      Profile: profilePolicy.LiveProfile,
    };
    const { data: { VideoProfiles } } = yield SunapiClient.get(
      '/stw-cgi/media.cgi?msubmenu=videoprofile&action=view',
      reqData
    );
    const profiles = VideoProfiles[0].Profiles[0];
    defaultStatus = {
      codec: profiles.EncodingType,
      resolution: profiles.Resolution,
      framerate: profiles.FrameRate,
    };
  }
  return {
    index: camera.Channel,
    ch: camera.Channel + 1,
    modelName: camera.Model,
    location: camera.IPAddress || `Port ${Number(camera.PORT) + 1}`,
    status: (camera.Status === 'Success' || camera.Status === 'AccBlock')
      ? 'Connected' : 'Disconnected',
    ...defaultStatus,
  };
}
*/

const covertRecordStatus = recordData => (
  {
    totalBitrateRecord: recordData.cur_bitrate,
    totalBitrateMax: recordData.max_bitrate,
    overWrite: Number(recordData.hdd_overwrite) === 0 ? 'lang_off' : 'lang_on', // 0 - off, 1 - on
    hddFreeSize: recordData.hddfreesize,
    hddTotalSize: recordData.hddtotalsize,
    recordFreeDays: recordData.days,
    recordFreeHours: recordData.hours,
    recordTotalDays: recordData.total_days,
    recordTotalHours: recordData.total_hours,
    channelStatus: recordData.status.map((item, index) => {
      let defaultStatus = {
        profile: '-',
        recordType: '-',
        recordFrame: '-',
        framerateInput: '-',
        framerateRecord: '-',
        bitrateLimit: '-',
        bitrateInput: '-',
        bitrateRecord: '-',
        bitrateInputAndLimit: '-',
        color: '',
        backgroundColor: '',
        exceededInputData: 0,
        profileError: 0,
        exceededHddCapacity: 0,
      };
      if (item.video !== '0') {
        defaultStatus = {
          profile: item.profile,
          recordType: recordTypeToString(Number(item.rectype)),
          recordFrame: recordFrameToString(Number(item.recrate)),
          framerateInput: `${item.frame_input} fps`,
          framerateRecord: `${item.frame_record} fps`,
          bitrateLimit: `${item.data_setup} M`,
          bitrateInput: `${item.data_input} M`,
          bitrateRecord: `${item.data_record} M`,
          bitrateInputAndLimit: `${item.data_sum} %`,
          exceededInputData: Number(item.orange),
          profileError: Number(item.yellow),
          exceededHddCapacity: Number(item.violet),
        };
      }
      const colors = setColors(item);
      return {
        index,
        ch: index + 1,
        ...defaultStatus,
        ...colors,
      };
    }),
  }
);

const convertLiveStatusData = (camera, index) => {
  const statusNum = Number(camera.status);
  let defaultStatus = {
    codec: '-',
    resolution: '-',
    framerate: '-',
  };
  if (statusNum !== 0 && camera.status !== '-' && !camera.isAnalog) {
    defaultStatus = {
      codec: codecToString(Number(camera.codec)),
      resolution: camera.resol,
      framerate: `${camera.rate} fps`,
    };
  }
  return {
    index,
    ch: index + 1,
    modelName: camera.model,
    status: statusToString(statusNum),
    location: camera.ip,
    isAnalog: camera.isAnalog,
    ...defaultStatus,
  };
};

const convertNetworkStatusData = data => data.map((item, index) => ({
  index,
  title: 'lang_network',
  inBound: `${item.InBound.toFixed(1)} M`,
  outBound: `${item.OutBound.toFixed(1)} M`,
}));

function* asyncGetStatusSaga() {
  while (true) {
    yield take(statusPopupActions.GET_STATUS);
    try {
      /*
        cgi로 status 구현 TBD(Record status cgi 구현 안됨)
        const { data: { RegisteredCameras: registeredCamera } } = yield SunapiClient.get(
          '/stw-cgi/media.cgi?msubmenu=cameraregister&action=view'
        );
        const { data: { VideoProfilePolicies: profilePolicies } } = yield SunapiClient.get(
          '/stw-cgi/media.cgi?msubmenu=videoprofilepolicy&action=view'
        );
        const liveStatus = yield all(
          registeredCamera.map(camera => convertLiveStatusData(camera, profilePolicies)),
        );
      */
      const getStatusTime = new Date();
      const { data: { RegisteredCameras: registeredCamera } } = yield SunapiClient.get(
        '/stw-cgi/media.cgi?msubmenu=cameraregister&action=view',
      );
      let result = yield SunapiClient.post('/index.php/common/get_live_status');
      const { data: liveData } = result;
      result = yield SunapiClient.post('/index.php/common/get_record_status');
      const { data: recordData } = result;
      result = yield SunapiClient.post('/index.php/common/get_record_status', null, { Max: true });
      const { data: maxRecordData } = result;
      const liveStatus = liveData && liveData.map(
        (item, index) => convertLiveStatusData(
          {
            ...item,
            isAnalog: registeredCamera[index] && registeredCamera[index].Protocol === 'ANALOG',
          },
          index,
        ),
      );
      const recordStatus = recordData && covertRecordStatus(recordData);
      const maxRecordStatus = recordData && covertRecordStatus(maxRecordData);
      result = yield SunapiClient.get('/stw-cgi/network.cgi?msubmenu=ethstatus&action=view');
      const { data: networkData } = result;
      const networkStatus = networkData.EthernetStaus
        && convertNetworkStatusData(networkData.EthernetStaus);
      yield put(statusPopupActions.getStatusSuccess({
        liveStatus,
        recordStatus,
        maxRecordStatus,
        getStatusTime,
        networkStatus,
      }));
    } catch (e) {
      console.log('[Status] GET Live Status Failed', e);
    }
  }
}

export default function* rootLangSaga() {
  yield all([
    asyncGetStatusSaga(),
  ]);
}
