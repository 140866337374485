import React from 'react';
import PropTypes from 'prop-types';
import each from 'lodash/each';
import assign from 'lodash/assign';
import Timeline from 'wisenet-ui/util/vendors/timeline-almende';
import RecordStatusType from 'wisenet-ui/util/static/constants/mediaControl/recordStatusType';
import { getLanguage } from 'util/lib';

const noop = () => {};
const events = [
  'add',
  'change',
  'edit',
  'delete',
  'ready',
  // 'currentTimeTick',
  // 'click',
  // 'contextmenu',
  // 'doubleClick',
  // 'groupDragged',
  'changed',
  'rangechange',
  'rangechanged',
  'select',
  'timechange',
  'timechanged',
  // 'mouseOver',
  // 'mouseMove',
  // 'itemover',
  // 'itemout',
];

const eventPropTypes = {};
const eventDefaultProps = {};

each(events, event => {
  // eslint-disable-next-line no-unused-expressions
  ((eventPropTypes[event] = PropTypes.func),
  (eventDefaultProps[`${event}Handler`] = noop));
});
const localDate = new Date();
const start = new Date(localDate.getFullYear(),
  localDate.getMonth(), localDate.getDate());
const end = new Date(localDate.getFullYear(),
  localDate.getMonth(), localDate.getDate(), 23, 59, 59);
const options = {
  start,
  end,
  min: start,
  max: end,
  type: 'range',
  stackEvents: false,
  editable: false,
  animate: false,
  animateZoom: false,
  // eventMargin: 1, // minimal margin between events
  // eventMarginAxis: 1, // minimal margin between events and the axis
  showMajorLabels: false,
  showCustomTime: true,
  showCurrentTime: false,
  cluster: true,
  clusterMaxItems: 30, // The maximum quantity of items that can be shown outside a cluster.
  // axisOnTop: true,
  snapEvents: true,
  // dragAreaWidth: 20,
  selectable: true,
  // showNavigation: true,
  groupsWidth: 0,
  zoomMax: 1000 * 60 * 60 * 24,
  zoomMin: 1000 * 60 * 10, // minimum zoom interval for the visible range
  // groupMinHeight: '50px',
  height: '40px',
};

class TimelineAlmendeContainer extends React.Component {
  state = {
    offset: {},
    scrollWidth: 0,
    scrollLeft: 0,
    useDstRange: false,
    dstRangeInfo: [],
  }

  dstSrollDiff = 0;

  dstScrollWidth = 0;

  componentDidMount() {
    window.addEventListener('resize', this.checkRangeResize);
    this.init();
  }

  // shouldComponentUpdate(nextProps) {
  // // return true;
  // const {
  //   currentTime,
  //   // groups,
  //   // options,
  //   // selection,
  //   // customTimes,
  // } = this.props;

  // const currentTimeChange = currentTime !== nextProps.currentTime;
  // // const groupsChange = groups !== nextProps.groups;
  // // const optionsChange = options !== nextProps.options;
  // // const customTimesChange = customTimes !== nextProps.customTimes;
  // // const selectionChange = selection !== nextProps.selection;

  // return (
  //   currentTimeChange
  //   // || groupsChange
  //   // || optionsChange
  //   // || customTimesChange
  //   // || selectionChange
  // );
  // }

  componentDidUpdate(prevProps) {
    // const { currentTime } = this.props;
    // if (prevProps.currentTime !== currentTime) {
    //   this.$el.setCurrentTime(currentTime);
    // }

    // if (this.$el) {
    //   this.init();
    // }

    const {
      timeLine: prevTimeLine,
      datas: prevDatas, // TimelineExamplePage의 data
      zoom: prevZoom,
      currentTime: prevCurrentTime,
      displayChange: prevDisplayChange,
      filter: prevFilter,
    } = prevProps;

    const {
      timeLine,
      searchDateObj,
      overlappedIDList,
      datas, // TimelineExamplePage의 data
      zoom,
      currentTime,
      timeZone,
      displayChange,
      filter,
      dstEnableInfo,
    } = this.props;

    if (prevDatas !== datas) { // TimelineExamplePage의 data
      this.init();
      return;
    }

    if (prevCurrentTime !== currentTime) {
      d.log('>>> current time changed >>> ', currentTime);
      const convertCurrentTime = new Date(currentTime);
      const changeCurrentTime = convertCurrentTime.setHours(convertCurrentTime.getHours()
        + convertCurrentTime.getTimezoneOffset() / 60 - Number(timeZone));
      const changedCurrentTime = new Date(changeCurrentTime);
      const dstRangeIn = this.checkDSTRange(new Date(changeCurrentTime));
      if (dstRangeIn && dstEnableInfo) {
        changedCurrentTime.setHours(changedCurrentTime.getHours() + 1);
      }
      this.$el.setCustomTime(changedCurrentTime);
    }

    if (JSON.stringify(prevTimeLine) !== JSON.stringify(timeLine)) {
      // 이 부분이 문젠거 같은데 왜그런지 모르겠네요.(DST표시)
      const date = new Date();
      if (searchDateObj !== null) {
        const { year, month, day } = searchDateObj;
        date.setFullYear(year);
        date.setMonth(month - 1);
        date.setDate(day);
      }
      const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);

      const changedOption = {
        start: startDate,
        end: endDate,
        min: startDate,
        max: endDate,
        ...this.options,
      };
      this.$el.setOptions(changedOption);
      if (timeLine.length !== 0) {
        const items = timeLine.Results.map((result, index) => {
          const filtered = typeof filter.find(data => data === result.Type) === 'undefined'
            ? 'Filtered'
            : '';
          // d.log('>>> timeline >>>', RecordStatusType.getLanguageKey(result.Type));
          return ({
            startTime: new Date(result.LocalStartTime),
            endTime: new Date(result.LocalEndTime),
            channel: Number(timeLine.Channel + 1),
            overlappedId: overlappedIDList,
            resultId: result.Result,
            id: index,
            group: getLanguage(RecordStatusType.getLanguageKey(result.Type)), // result.Type,
            start: this.toDate(result.LocalStartTime),
            end: this.toDate(result.LocalEndTime), // end is optional
            editable: false,
            className: `${result.Type} ${filtered}`,
          });
        });

        // console.log('>> Timeline', items);

        this.$el.draw(items);
      } else {
        this.$el.draw();
      }
      this.$el.setVisibleChartRange(startDate, endDate);
      this.zoomChanged();
    }

    if (zoom !== 0 && prevZoom !== zoom) {
      this.zoom(zoom);
    }

    if (prevDisplayChange !== displayChange || displayChange === true) {
      this.checkRangeResize();
    }

    if (prevFilter.length !== filter.length) {
      if (timeLine.length !== 0) {
        const items = timeLine.Results.map((result, index) => {
          const filtered = typeof filter.find(data => data === result.Type) === 'undefined'
            ? 'Filtered'
            : '';

          return ({
            startTime: new Date(result.LocalStartTime),
            endTime: new Date(result.LocalEndTime),
            channel: Number(timeLine.Channel + 1),
            overlappedId: overlappedIDList,
            resultId: result.Result,
            id: index,
            group: getLanguage(RecordStatusType.getLanguageKey(result.Type)), // result.Type,
            start: this.toDate(result.LocalStartTime),
            end: this.toDate(result.LocalEndTime), // end is optional
            editable: false,
            className: `${result.Type} ${filtered}`,
          });
        });

        this.$el.draw(items);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkWindowResize);
  }

  toDate = dateString => {
    const dateStringConv = dateString.includes('Z') ? dateString.replace('Z', '') : dateString;
    const [date, time] = dateStringConv.split('T');
    const [year, month, day] = date.split('-');
    const [hour, minute, second] = time.split(':');
    return new Date(year, month - 1, day, hour, minute, second);
  }

  setRef = (name, elem) => {
    this[name] = elem;

    if (this.container) {
      this.$el = new Timeline.Timeline(this.container, options);
    }
  }

  handleMouseMove = e => {
    // const { clientX, currentTarget } = e;
    // const { offsetLeft } = currentTarget;
    const wrapper = e.currentTarget.offsetParent;
    const wrapperWidth = wrapper.offsetWidth;
    let x = e.clientX - wrapper.offsetLeft;
    const time = this.$el.screenToTime(x);
    const hour = new Date(time).getHours();
    const minutes = new Date(time).getMinutes();
    const seconds = new Date(time).getSeconds();
    const convertHours = hour < 10 ? `0${hour}` : hour;
    const convertMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const convertSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const formattedTime = `${convertHours}:${convertMinutes}:${convertSeconds}`;

    this.popup.querySelector('.time').innerHTML = formattedTime;
    this.popup.querySelector('.popup-line').style.left = '50%';

    const halfWidth = this.popup.offsetWidth / 2;
    // 0 ~ halfWidth
    if (x < halfWidth) {
      const diff = halfWidth - x;
      this.popup.querySelector('.popup-line').style.left = `calc(50% - ${diff}px)`;
      x += diff;
    }
    // end - helfWidth ~ end
    if (x > wrapperWidth - halfWidth) {
      const diff = x - (wrapperWidth - halfWidth);
      this.popup.querySelector('.popup-line').style.left = `calc(50% + ${diff}px)`;
      x -= diff;
    }

    this.popup.style.left = `${x}px`;

    // this.setState({
    //   time: formattedTime,
    // });
  }

  getData = data => {
    if (typeof (data.row) !== 'undefined') {
      return this.$el.getItem(data.row);
    }
    const clusterItems = this.$el.getCluster(data.cluster).items;
    const clusterStart = this.$el.getCluster(data.cluster).start;
    clusterItems.sort((a, b) => {
      const hasProperty = Object.prototype.hasOwnProperty.call(a, 'end');
      if (hasProperty) {
        return a.end - b.end;
      }
      return null;
    });
    const item = {
      start: clusterStart,
      end: clusterItems[clusterItems.length - 1].end,
      ...clusterItems[0],
    };
    return item;
  }

  checkDSTRange = time => {
    const { dstInfo, timelineDstRange, dstEnable } = this.props;
    // console.log('checkDSTRange time ', time, ' timelineDstRange ', timelineDstRange,
    //   ' dstEnable', dstEnable);
    const dstRangeIn = dstInfo.startDate.valueOf() < time.valueOf()
      && time.valueOf()
      < (dstEnable ? timelineDstRange.startTime.valueOf() : dstInfo.endDate.valueOf());

    // console.log('checkDSTRange dstRangeIn ', dstRangeIn, dstInfo.startDate.valueOf(),
    //   time.valueOf(), timelineDstRange.startTime.valueOf(), dstInfo.endDate.valueOf());
    // console.log('checkDSTRange dstInfo.startDate.valueOf() < time.valueOf() ',
    //   dstInfo.startDate.valueOf() < time.valueOf(), ' time.valueOf() < (!dstEnable
    //   ? timelineDstRange.startTime.valueOf() : dstInfo.endDate.valueOf())',
    //   time.valueOf() <
    //   (!dstEnable ? timelineDstRange.startTime.valueOf() : dstInfo.endDate.valueOf()));
    return dstRangeIn;
  }

  checkTimelineDSTRange = time => {
    const { timelineDstRange } = this.props;
    if (!timelineDstRange) {
      return false;
    }
    const timelineDstRangeIn = timelineDstRange.startTime.valueOf() < time.valueOf()
      && time.valueOf() < timelineDstRange.endTime.valueOf();
    return timelineDstRangeIn;
  }

  handleClick = e => {
    const { onTimechanged } = this.props;
    const { useDstRange, dstRangeInfo } = this.state;
    const x = e.clientX - e.currentTarget.offsetParent.offsetParent.offsetLeft;
    const time = this.$el.screenToTime(x);
    this.$el.setCustomTime(time);

    // const dstRangeIn = this.checkDSTRange(time);
    const selectedDataIndex = this.$el.getSelection();
    const selectedData = selectedDataIndex.length !== 0
      ? this.getData(selectedDataIndex[0]) : [];
    let inDst = false;

    if (useDstRange) {
      if (dstRangeInfo.startTime.valueOf() <= time.valueOf()
        && time.valueOf() <= dstRangeInfo.endTime.valueOf()) {
        inDst = true;
      }
    }

    // 여기서 시간을 minus 하면 전달된 시간이 search timelinemodule 에서 한번 더 -1 되어 잘못된 시간이 생김
    // if (dstRangeIn && dstEnableInfo) {
    //   time.setHours(time.getHours() - 1);
    //   console.log('handleClick dstRangeIn && dstEnableInfo ',
    //    dstRangeIn && dstEnableInfo, ' time - 1 ', time);
    // }

    // this.moveToCurrentTime();
    const dstInfoChange = {
      inDst,
      screenPoint: x,
    };

    onTimechanged({ time, selectedData, dstInfoChange });
  }

  zoomChanged = () => {
    const { zoomChangeEvent, timelineDstRange, timelineDstEnable } = this.props;
    const currentOptions = this.$el.getOptions();
    const range = this.$el.getVisibleChartRange();
    const currentOptionRange = currentOptions.end - currentOptions.start;
    const currentRange = range.end - range.start;
    const scrollWidth = (range.end - range.start) / currentOptionRange * 100;

    const diff = (range.end - range.start) / (1000 * 60 * 60);
    const scrollDiff = range.start - currentOptions.start;
    const rangeScrollDiff = scrollDiff / (currentOptionRange - currentRange) * 100;

    // if (typeof dstInfo.endDate !== 'undefined') {
    const dstRangeInfo = timelineDstEnable ? timelineDstRange : [];
    let useDstRange = false;

    if (timelineDstEnable && timelineDstRange) {
      const rangeDate = new Date(range.start.getFullYear(),
        range.start.getMonth(),
        range.start.getDate());
      const dstDate = typeof dstRangeInfo.startTime !== 'undefined'
        ? new Date(dstRangeInfo.startTime.getFullYear(),
          dstRangeInfo.startTime.getMonth(),
          dstRangeInfo.startTime.getDate())
        : 0;

      useDstRange = rangeDate.valueOf() === dstDate.valueOf();

      if (useDstRange) {
        this.dstInfoSet(dstRangeInfo);
      }
    }
    this.checkRangeResize();
    // }
    // zoomMin 0.2, zoomMax 24.0
    zoomChangeEvent({ timelineRange: diff.toFixed(2) });

    this.setState({
      scrollWidth,
      scrollLeft: rangeScrollDiff,
      useDstRange,
      dstRangeInfo,
    });
  }

  dstInfoSet = ({ startTime, endTime }) => {
    // DST range setting
    const range = this.$el.getVisibleChartRange();
    const dstScrollDiff = startTime - range.start;
    this.dstSrollDiff = (dstScrollDiff) / (range.end - range.start) * 100;
    this.dstScrollWidth = (endTime - startTime) / (range.end - range.start) * 100;
  }

  checkRangeResize = () => {
    this.$el.redraw();
  }

  handleScroll = value => {
    const { scrollLeft, useDstRange, dstRangeInfo } = this.state;
    const range = this.$el.getVisibleChartRange();
    const diff = (range.end - range.start) / (1000 * 60 * 60);

    const moveFactor = ((24 - diff) / diff / 100) * (value - scrollLeft);
    this.$el.move(moveFactor);
    if (useDstRange) {
      this.dstInfoSet(dstRangeInfo);
    }

    this.checkRangeResize();

    this.setState({ scrollLeft: value });
  }

  // handleExport = value => {
  //   const { handleTimeRangeExport } = this.props;
  //   const startTime = this.$el.screenToTime(value[0]);
  //   const endTime = this.$el.screenToTime(value[1]);

  //   handleTimeRangeExport({ startTime, endTime });
  // }

  handleChangedTimeRange = (startTime, endTime) => {
    const { changedTimeRange } = this.props;
    changedTimeRange(startTime, endTime);
  }

  moveToCurrentTime = () => {
    const range = this.$el.getVisibleChartRange();
    const currentTime = this.$el.getCustomTime();
    const diff = (range.end.valueOf() - range.start.valueOf());
    const tempRangeStart = new Date(currentTime.valueOf() - diff / 2);
    const startDiff = (range.start.valueOf() - tempRangeStart.valueOf());

    const startNew = new Date(currentTime.valueOf() - diff / 2 + startDiff);
    const endNew = new Date(startNew.valueOf() + diff);

    this.$el.setVisibleChartRange(startNew, endNew);

    this.zoomChanged();
  }

  screenToTime = x => (
    this.$el.screenToTime(x)
  );

  timeToScreen = time => (
    this.$el.timeToScreen(time)
  );

  getCustomTime = () => (
    this.$el.getCustomTime()
  );

  init() {
    const { datas, selectDate } = this.props;
    const selectDateStart = new Date(selectDate.getFullYear(),
      selectDate.getMonth(), selectDate.getDate());
    const selectDateEnd = new Date(selectDate.getFullYear(),
      selectDate.getMonth(), selectDate.getDate(), 23, 59, 59);
    this.$el.setVisibleChartRange(selectDateStart, selectDateEnd);
    Timeline.events.addListener(this.$el, 'rangechanged', this.zoomChanged);
    this.$el.draw(datas);
  }

  zoom(zoomVal) {
    this.$el.zoom(zoomVal);
    this.$el.trigger('rangechanged');
  }

  render() {
    const { render } = this.props;
    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

// TimelineAlmende.propTypes = assign(
TimelineAlmendeContainer.propTypes = {
  render: PropTypes.func.isRequired,
  datas: PropTypes.arrayOf(PropTypes.object),
  selectDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.number,
  ]),
  currentTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.number,
  ]),
  onTimechanged: PropTypes.func,
  zoomChangeEvent: PropTypes.func,
  zoom: PropTypes.number,
  // handleTimeRangeExport: PropTypes.func,
  timeLine: PropTypes.oneOfType([PropTypes.any]),
  searchDateObj: PropTypes.oneOfType([PropTypes.any]),
  overlappedIDList: PropTypes.oneOfType([PropTypes.any]),
  changedTimeRange: PropTypes.func,
  timelineDstRange: PropTypes.oneOfType([PropTypes.any]),
  timelineDstEnable: PropTypes.bool,
  timeZone: PropTypes.number.isRequired,
  displayChange: PropTypes.bool.isRequired,
  filter: PropTypes.arrayOf(PropTypes.string),
  dstEnable: PropTypes.bool.isRequired,
  dstEnableInfo: PropTypes.bool.isRequired,
  dstInfo: PropTypes.oneOfType([PropTypes.any]).isRequired,
};
// eventPropTypes,
// );

TimelineAlmendeContainer.defaultProps = assign(
  {
    datas: [],
    selectDate: new Date(),
    currentTime: new Date(),
    onTimechanged: () => {},
    zoomChangeEvent: () => {},
    animateTimeout: () => {},
    handleTimeRangeExport: () => {},
    timeLine: {},
    zoom: 0,
    searchDateObj: {},
    overlappedIDList: {},
    changedTimeRange: () => {},
    timelineDstRange: {},
    timelineDstEnable: false,
    dstEnable: false,
    filter: [],
  },
  eventDefaultProps,
);

export default TimelineAlmendeContainer;
