import styled, { withTheme } from 'styled-components';
import Slider from 'rc-slider';

export const TimelineWrapper = styled.div`
  width: 100%;
  height: 45px;
  position: relative;

  /* timeline-almende.js에서 팝업 Class name을 변경하기 좋도록 아이디를 통해 팝업에 스타일 접근함 */
  #js-timeline-mouseover-popup {
    position: absolute;
    top: 0;
    z-index: 4;
    display: none;

    padding: 7px 10px;
    border-radius: 2px;

    text-align: center;
    transform: translate(-50%, -100%);

    .popup-line {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 100%);
      width: 2px;
      height: 8px;
    }

    .event-name {
      font-size: 11px;
      margin-bottom: 2px;
      white-space: pre;
    }

    .time {
      font-size: 14px;
      font-weight: bold;
    }
  }
`;

export const DSTSelectedStyled = styled.div`
  position: absolute;
  top: 0;
  left: ${props => `${props.dstSrollDiff}%`};
  width: ${props => `${props.dstScrollWidth}%`};
  height: 3px;
  background-color: #22ae14;
`;

export const SliderWrapperStyled = styled.div`
  width: 100%;
  height: 10px;
  overflow-x: hidden;

  position: absolute;
  left: 0;
  bottom: 0;
`;

export const SliderParentStyled = withTheme(styled.div`
  width: ${props => `${100 - props.width}%`};
  height: 10px !important;

  position: absolute !important;
  left: ${props => `${props.width / 2}%`};
  bottom: 0;
  display: none;
  /* overflow: hidden; */

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: -100%;
    width: 300%;
    height: 100%;
    z-index: 1;
    background-color: ${props => props.theme.background.color2};
  }

  &.show {
    display: block;
  }
`);

export const SliderStyled = withTheme(styled(Slider)`
  /* .rc-slider { */
  padding: 0 !important;
  height: 10px !important;

  .rc-slider-rail {
    height: 10px;
    border-radius: 0;
  }

  .rc-slider-track {
    display: none;
  }

  .rc-slider-handle {
    z-index: 2;
    border: 1px solid ${props => props.theme.text.color4};
  }

    /* .rc-slider-handle {
      margin-top: 0;
      margin-left: 0;
      border-radius: 0;
      width: ${props => `${props.scrollbarWidth}%`} !important;
      height: 10px;
    } */
  /* } */
`);

export const TimelineWrapperStyled = styled.div`
  overflow: hidden;
  position: relative;
  user-select: none;
  & .timeline-content>div:first-child {
    background-color: ${props => props.theme.background.color3};
  }

  & .timeline-customtime {
    background-color: ${props => props.theme.background.color1};
    border: 5px solid ${props => props.theme.line.color6};
  }

  #timeline-container {

    /* opacity: ${props => (props.useSelectTimeRange ? 0.5 : 1)}; */
  }
`;
