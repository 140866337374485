import styled from 'styled-components';
import { Checkbox } from 'wisenet-ui/components/atoms';
import MenuTabs from 'components/molecules/tabs/MenuTabs/MenuTabs';

export const UserAuthDetailWrapperStyled = styled.div`
  margin-top: 15px;
`;

export const CheckAllWrapperStyled = styled.div`
  height: 20px;
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
`;

export const CheckboxStyled = styled(Checkbox)`
  & > span {
    &::before {
      border-color: #d2d2d2;
    }
    &::after {
      border-color: #b2b2b2;
    }
  }
`;

export const CheckTitleStyled = styled.span`
  font-size: 14px;
  margin-left: 9px;
  color: #797979;
`;

export const DetailTabsStyled = styled(MenuTabs)`
  .tab-wrapper {
    height: 40px;
    .tab {
      width: 140px;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: -0.2px;
    }
  }
  .content-wrapper {
    padding-top: 18px;
  }
`;
