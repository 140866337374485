import {
  take, all, put,
} from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { SunapiClient, Auth } from 'util/lib';
import * as postLoadActions from 'store/modules/page/postLoadModule';
import * as textSearchActions from 'store/modules/textSearch/textSearchModule';
import { EventUpdateWorker } from 'util/worker';

const posEventDiffChannel = channel();

function* watchPosEventChannel() {
  while (true) {
    const { posEvent } = yield take(posEventDiffChannel);
    yield put(textSearchActions.checkPosEventConfig({
      ...posEvent,
    }));
  }
}

const posEventCheckWorker = ({ responseText }) => {
  EventUpdateWorker.postMessage({
    type: 'posEvent',
    messageData: {
      responseText,
    },
  });

  EventUpdateWorker.onmessage = ({ data: { type, postData } }) => {
    if (type === 'posEvent') {
      posEventDiffChannel.put(postData);
    }
  };
};

function* asyncPosDataChecker() {
  let posDataCheckerSession = null;
  while (true) {
    const action = yield take(postLoadActions.SET_POS_DATA_CHECK);
    const { posDataCheck } = action.payload;

    if (posDataCheck) {
      if (!posDataCheckerSession) {
        posDataCheckerSession = SunapiClient.get('/stw-cgi/recording.cgi?msubmenu=posdata&action=monitordiff', undefined, undefined, {
          onDownloadProgress: event => {
            posEventCheckWorker(event.target);
          },
          timeout: 0,
        });
      }
    } else {
      posDataCheckerSession = null;
    }
  }
}

function* changeLocationToError() {
  while (true) {
    const { payload: { location } } = yield take(postLoadActions.CHANGE_LOCATION_TO_ERROR);
    const history = Auth.getHistory();
    console.log('[Timeout] Change location to error page', history);
    history.push(location);
  }
}

export default function* rootUmpInitailizeSaga() {
  yield all([
    asyncPosDataChecker(),
    watchPosEventChannel(),
    changeLocationToError(),
  ]);
}
