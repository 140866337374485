import {
  createAction,
} from 'redux-actions';

export const CONTROL_AUX = 'ptzZoomFocus/CONTROL_AUX';
export const CONTROL_AUX_SUCCESS = 'ptzZoomFocus/CONTROL_AUX_SUCCESS';
export const CONTROL_AUX_FAIL = 'ptzZoomFocus/CONTROL_AUX_FAIL';

export const controlAux = createAction(CONTROL_AUX);
export const controlAuxSuccess = createAction(CONTROL_AUX_SUCCESS);
export const controlAuxFail = createAction(CONTROL_AUX_FAIL);
