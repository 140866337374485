import styled from 'styled-components';

export const LoginTemplateStyled = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoginFormStyled = styled.div`
  width: 524px;
  height: 316px;
  border: 1px solid ${props => props.theme.line.color5};
`;
