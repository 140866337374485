const bindFunction = ({ active, bindFunc }) => (
  active ? bindFunc.bind(bindFunc, `[${new Date().format('MM/dd hh:mm:ss')}]`) : () => {}
);

const logNameList = ['log', 'error', 'warn', 'debug'];

const recordOriginLogFunction = bindObj => {
  const devlog = bindObj;
  logNameList.map(logName => {
    devlog[`ori${logName}`] = devlog[logName];
    return null;
  });
};

const activeBind = ({ active, bindObj }) => {
  const devlog = bindObj;
  logNameList.map(logName => {
    devlog[logName] = bindFunction({ active, bindFunc: devlog[`ori${logName}`] });
    return null;
  });
};

window.d = Object.assign({}, console);
(() => {
  recordOriginLogFunction(d);
  const active = sessionStorage.getItem('debug') === 'true';
  activeBind({ active, bindObj: d });
})();

window.uwaLog = active => {
  // debeging을 하기 위한 용도로 사용하는 값
  sessionStorage.setItem('debug', active);
  activeBind({ active, bindObj: d });
};
