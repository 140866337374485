import styled, { css } from 'styled-components';

export const LoadingStyled = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2000;
`;

export const LoadingWrapper = styled.div`
  z-index: 2000;
  ${props => (props.isPage ? css`
    position: fixed;
    width: 100vw;
    height: 100vh;
  ` : css`
    position: relative;
    width: 100%;
    height: 100%;
    float: left;
  `)}
`;
