import styled from 'styled-components';
import { Tabs } from 'wisenet-ui/components/organisms';

export const ListTabsStyled = styled(Tabs)`
  display: flex;
  flex-direction: column;
  .tab-wrapper {
    flex-basis: 46px;
    flex-shrink: 0;
    .tab {
      flex: 1;
      color: ${props => props.theme.text.color3};
      &.active {
        color: ${props => props.theme.text.color4};
      }
      &:hover {
        background-color: ${props => props.theme.background.color3};
      }
      &:active {
        background-color: ${props => props.theme.background.color4};
      }
    }
  }
  .content-wrapper {
    flex-grow: 1;
    overflow: hidden;
  }
`;
