import styled from 'styled-components';

const transformCalc = ({ rotate }, translateX, translateY) => (
  `rotate(${rotate}deg) translateX(${translateX}%) translateY(${translateY}%)`
);
  // const xChange = rotate >= 180;
  // const yChagne = (rotate % 270) !== 0;
  // const xRatio = xChange ? -100 : 0;
  // const yRatio = yChagne ? -100 : 0;
  // return `rotate(${rotate}deg) translateX(${xRatio}%) translateY(${yRatio}%)`;

const isRotate = ({ rotate }) => (rotate % 180 !== 0);

const videoRatioCalc = props => {
  const videoSize = {
    width: props.height / (props.resolution.height / props.resolution.width),
    height: props.width / (props.resolution.width / props.resolution.height),
  };

  let moveLeft = 0;
  let moveTop = 0;
  let translateX = 0;
  let translateY = 0;

  const digitalZoomWidth = props.width * props.digitalZoom;
  const digitalZoomHeight = props.height * props.digitalZoom;

  if (props.rotate % 180 === 0) {
    if (props.width > videoSize.width) {
      moveTop = (props.height - videoSize.height) / 2;
    } else {
      moveTop = (props.width - videoSize.width) / 2;
    }
    moveTop = -Math.abs(moveTop);
  } else {
    if (props.width > videoSize.width) {
      moveLeft = -(props.width - props.height) / 2;
      moveTop = (props.width - videoSize.height) / 2;
    }
    moveTop = (props.width - props.height) / 2;
    moveLeft = (props.height - videoSize.width) / 2;
    if (props.aspectRatio) {
      moveLeft = (props.height - props.width) / 2;
    }
  }

  if (props.rotate === 0) {
    if (props.width > videoSize.width) {
      translateY = (moveTop / digitalZoomHeight) * 100;
    } else {
      translateX = (moveTop / digitalZoomWidth) * 100;
    }
  } else if (props.rotate === 180) {
    if (props.width > videoSize.width) {
      translateY = -(moveTop / digitalZoomHeight) * 100;
    } else {
      translateX = -(moveTop / digitalZoomWidth) * 100;
    }
  } else if (props.rotate === 90) {
    translateX = (moveTop / digitalZoomWidth) * 100;
    translateY = -(moveLeft / digitalZoomHeight) * 100;
  } else if (props.rotate === 270) {
    translateX = -(moveTop / digitalZoomWidth) * 100;
    translateY = (moveLeft / digitalZoomHeight) * 100;
  }

  if (!props.aspectRatio) {
    // const changeType = props.width > videoSize.width ? 'height' : 'width';
    const changeType = props.width > videoSize.width ? 'height' : 'width';
    const scale = 1 - ((videoSize[changeType] - props[changeType]) / videoSize[changeType]);
    // transform: scale(${isRotate(props) ? `1, ${scale}` : `${scale}, 1`}) ${transformCalc(props)};
    const testScale = props.digitalZoom * scale;
    let scaleText = `${changeType !== 'width' ? `${props.digitalZoom}, ${testScale}` : `${testScale}, ${props.digitalZoom}`}`;
    if (isRotate(props)) {
      scaleText = `${isRotate(props) && changeType !== 'width' ? `${testScale}, ${props.digitalZoom}` : `${props.digitalZoom}, ${testScale}`}`;
    }

    return `
      transform: scale(${scaleText}) ${transformCalc(props, translateX, translateY)};
      ${changeType}: auto !important;
      ${changeType === 'width' ? 'height' : 'width'}: ${props[changeType === 'width' ? 'height' : 'width']}px !important;
    `;
  }
  translateX = !isRotate(props) ? 0 : translateX;
  translateY = !isRotate(props) ? 0 : translateY;

  return `
    transform: scale(${props.digitalZoom}) ${transformCalc(props, translateX, translateY)};
    width: ${props.width}px !important;
    height: ${props.height}px !important;
    `;
};

const canvasRatioCalc = props => {
  let moveLeft = 0;
  let moveTop = 0;
  let translateX = 0;
  let translateY = 0;

  const digitalZoomWidth = props.width * props.digitalZoom;
  const digitalZoomHeight = props.height * props.digitalZoom;

  if (props.rotate % 180 !== 0) {
    moveLeft = (props.height - props.width) / 2;
    moveTop = -moveLeft;
    if (props.rotate === 90) {
      moveTop = (props.width - props.height) / 2;
      moveLeft = (props.height - props.width) / 2;
    }

    if (props.rotate === 90) {
      translateX = (moveTop / digitalZoomWidth) * 100;
      translateY = -(moveLeft / digitalZoomHeight) * 100;
    } else if (props.rotate === 270) {
      translateX = -(moveTop / digitalZoomWidth) * 100;
      translateY = (moveLeft / digitalZoomHeight) * 100;
    }
  }

  if (props.aspectRatio) {
    const videoRatio = props.resolution.width / props.resolution.height;
    const changeType = props.width > (props.height * videoRatio) ? 'width' : 'height';
    const canvasRatio = changeType === 'width' ? props.height * videoRatio : props.width / videoRatio;
    const originMarginLength = (((props[changeType] - canvasRatio)) / 2);
    const marginLength = (((props[changeType] - canvasRatio)) / 2) * props.digitalZoom;
    translateX = (moveTop / (digitalZoomWidth)) * 100;
    translateY = -(moveLeft / (digitalZoomHeight - (marginLength * 2))) * 100;

    if (props.rotate === 90) {
      translateX = ((moveTop + (originMarginLength - marginLength)) / (digitalZoomWidth)) * 100;
      translateY = -(moveLeft / (digitalZoomHeight - (marginLength * 2))) * 100;
    } else if (props.rotate === 270) {
      translateX = -((moveTop + (originMarginLength - marginLength)) / (digitalZoomWidth)) * 100;
      translateY = (moveLeft / (digitalZoomHeight - (marginLength * 2))) * 100;
    }

    return `
      transform: scale(${props.digitalZoom}) ${transformCalc(props, translateX, translateY)};
      ${changeType === 'width' ? 'height' : 'width'}: ${props[changeType === 'width' ? 'height' : 'width']}px !important;
      ${changeType}: auto !important;
      margin: ${changeType === 'width' ? `0 ${marginLength}px` : `${marginLength}px 0`};
    `;
  }
  return `
    transform: scale(${props.digitalZoom}) ${transformCalc(props, translateX, translateY)};
    width: ${props.width}px !important;
    height: ${props.height}px !important;
  `;
};

export default styled.div`
  width: ${props => (isRotate(props) ? props.height : props.width)}px;
  height: ${props => (isRotate(props) ? props.width : props.height)}px;
  position: absolute;

  video {
    ${props => videoRatioCalc({
    ...props,
    width: isRotate(props) ? props.height : props.width,
    height: isRotate(props) ? props.width : props.height,
  })}
    position: absolute;
  }

  canvas {
    ${props => canvasRatioCalc({
    ...props,
    width: isRotate(props) ? props.height : props.width,
    height: isRotate(props) ? props.width : props.height,
  })}
    position: absolute;
  }
`;
