import CryptoJS from 'crypto-js';

const decimalToHex = (dec, _padding) => {
  let hex = Number(dec).toString(16);
  const padding = typeof (_padding) === 'undefined' || _padding === null ? 2 : _padding;

  while (hex.length < padding) {
    hex = `0${hex}`;
  }
  return hex;
};

export const makeHA1 = (_userid, realm, _password) => {
  const HA1 = CryptoJS.MD5(`${_userid}:${realm}:${_password}`).toString();
  return HA1;
};

const makeResponse = (HA1, url, method, nonce, nc, cnonce, qop) => {
  const HA2 = CryptoJS.MD5(`${method}:${url}`).toString();
  const response = CryptoJS.MD5(`${HA1}:${nonce}:${decimalToHex(nc, 8)}:${cnonce}:${qop}:${HA2}`).toString();
  return response;
};

const generateCnonce = () => {
  const characters = 'abcdef0123456789';
  let token = '';
  for (let i = 0; i < 16; i += 1) {
    const randNum = Math.round(Math.random() * characters.length);
    token += characters.substr(randNum, 1);
  }
  return token;
};

export const makeDigestResponse = (header, authKey, userid, method, url) => {
  const copyHeader = header;
  copyHeader.nc += 1;
  copyHeader.cnonce = generateCnonce();

  const responseValue = makeResponse(authKey, url,
    method.toUpperCase(), copyHeader.nonce, copyHeader.nc,
    copyHeader.cnonce, copyHeader.qop);

  return `${copyHeader.scheme} username="${userid}", realm="${copyHeader.realm}", nonce="${copyHeader.nonce}", uri="${url}", cnonce="${copyHeader.cnonce}" nc="${decimalToHex(copyHeader.nc, 8)}", qop="${copyHeader.qop}", response="${responseValue}"`;
};

export const convertResponseHeader = responseHeader => (
  Object.prototype.hasOwnProperty.call(responseHeader, 'www-authenticate') ? {
    realm: responseHeader['www-authenticate'].split('"')[1],
    nonce: responseHeader['www-authenticate'].split('"')[3],
    qop: responseHeader['www-authenticate'].split('"')[5],
    cnonce: '0482f40715d384c',
    opaque: null,
    scheme: 'Digest',
    nc: 0,
  } : null
);
