import { createAction, handleActions } from 'redux-actions';
import { Map } from 'immutable';
import { getCookie } from 'util/lib/Cookie';
// import { Auth } from 'util/lib';

// action
export const LOGIN = 'login/LOGIN';
export const LOGIN_PENDDING = 'login/LOGIN_PENDDING';
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'login/LOGIN_FAILURE';

export const LOGOUT = 'login/LOGOUT';

export const CHECK_IS_ADMIN = 'login/CHECK_IS_ADMIN';
export const CHECK_IS_ADMIN_SUCCESS = 'login/CHECK_IS_ADMIN_SUCCESS';

// action create
export const login = createAction(LOGIN);
export const loginPendding = createAction(LOGIN_PENDDING);
export const loginSuccess = createAction(LOGIN_SUCCESS);
export const loginFailure = createAction(LOGIN_FAILURE);

export const logout = createAction(LOGOUT);

export const checkIsAdmin = createAction(CHECK_IS_ADMIN);
export const checkIsAdminSuccess = createAction(CHECK_IS_ADMIN_SUCCESS);

const errorMessage = getCookie('AUTH_FAIL');

const initialState = Map({
  error: false,
  errorMessage: errorMessage === '1' ? 'Invalid ID and password. Please check again.' : '',
  isAdmin: false,
});

// reducer
export default handleActions({
  [LOGIN_SUCCESS]: state => (
    state
      .set('error', false)
      .set('errorMessage', '')
  ),
  [LOGIN_FAILURE]: (state, action) => (
    state
      .set('error', true)
      .set('errorMessage', action.payload.errorMessage)
  ),
  [CHECK_IS_ADMIN_SUCCESS]: (state, { payload: { isAdmin } }) => (
    state.set('isAdmin', isAdmin)
  ),
}, initialState);
