import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { EventListContainer } from 'containers/organisms';
import { PosEvent } from 'components/molecules';
import {
  EventTabWrapper,
} from './EventListStyled';

class EventList extends React.PureComponent {
  render() {
    const {
      eventListData,
      onClickDeleteItem,
      onClickMoveToSearch,
    } = this.props;
    return (
      <EventTabWrapper id="live-eventlist-wrapper">
        {eventListData.map(item => (
          <PosEvent
            event={item}
            key={item.id}
            onClickDeleteItem={onClickDeleteItem}
            onClickMoveToSearch={onClickMoveToSearch}
          />
        ))}
      </EventTabWrapper>
    );
  }
}

EventList.propTypes = {
  eventListData: PropTypes.instanceOf(Array).isRequired,
  onClickDeleteItem: PropTypes.func.isRequired,
  onClickMoveToSearch: PropTypes.func.isRequired,
};

export default withContainer(EventListContainer, EventList);
