export const ALARM_LIST = {
  SYSTEM: [
    {
      id: 'fanError',
      title: 'lang_fan_failure',
      message: 'lang_fan_failure_msg',
    },
    {
      id: 'dualSMPSError',
      title: 'lang_dual_smps_failure',
      message: 'lang_dual_smps_failure_msg',
    },
    {
      id: 'recordFrameDrop',
      title: 'lang_recording_status_alert',
      message: 'lang_recording_status_alert_msg',
      requestPageUrl: '#device/device_storage_setup_device_format',
    },
    {
      id: 'recordFiltering',
      title: 'lang_rec_restriction',
      message: 'lang_rec_restriction_msg',
      requestPageUrl: '#record/record_record_setup',
    },
  ],
  HDD: [
    {
      id: 'hddFull',
      title: 'lang_disk_full',
      message: 'lang_disk_full_msg',
      requestPageUrl: '#device/device_storage_setup_device_format',
    },
    {
      id: 'hddNone',
      title: 'lang_no_hdd',
      message: 'lang_no_hdd_msg',
    },
    {
      id: 'hddFail',
      title: 'lang_hdd_error',
      message: 'lang_hdd_error_msg',
    },
    {
      id: 'raidError',
      title: 'lang_raid_error',
      message: 'lang_raid_error_msg',
      requestPageUrl: '#device/device_storage_setup_raid',
    },
    {
      id: 'raidRebuilding',
      title: 'lang_raid_rebuilding',
      message: 'lang_raid_rebuilding_msg',
      requestPageUrl: '#device/device_storage_setup_raid',
    },
    {
      id: 'raidFail',
      title: 'lang_raid_failed',
      message: 'lang_raid_failed_msg',
      requestPageUrl: '#device/device_storage_setup_raid',
    },
    {
      id: 'iSCSIDisconnect',
      title: 'lang_iscsi_disconnected',
      message: 'lang_iscsi_disconnected_msg',
      requestPageUrl: '#device/device_storage_setup_iscsi',
    },
  ],
  NETWORK: [
    {
      id: 'netCamTrafficOverFlow',
      title: 'lang_network_traffice_overload',
      message: 'lang_network_traffice_overload_msg',
      requestPageUrl: '#camera/camera_camera_setup',
    },
  ],
  NOTIFICATION: [
    {
      id: 'newFWAvailable',
      title: 'lang_software_upgrade',
      message: 'lang_software_upgrade_msg',
      requestPageUrl: '#system/system_system_infomation',
    },
  ],
};
