import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'wisenet-ui/components/atoms';
import {
  MenuTabsStyled,
} from './MenuTabsStyled';

const MenuTabs = ({
  tabData,
  className,
  align,
}) => (
  <MenuTabsStyled
    className={className}
    align={align}
  >
    {tabData.map(data => (
      <Tab
        key={data.key}
        header={data.header}
        component={data.component}
        toolTip={data.toolTip}
      />
    ))}
  </MenuTabsStyled>
);

MenuTabs.defaultProps = {
  className: null,
  align: '',
};

MenuTabs.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  tabData: PropTypes.instanceOf(Array).isRequired,
  align: PropTypes.string,
};

export default MenuTabs;
