export default {
  AUDIO_DETECTION: 'AudioDetection',
  VIDEO_ANALYSIS: 'VideoAnalysis',
  ALARM_INPUT: 'AlarmInput',
  NORMAL: 'Normal',
  MOTION_DETECTION: 'MotionDetection',
  MANUAL: 'Manual',
  DEFOCUS_DETECTION: 'DefocusDetection',
  AUTO_TRACKING: 'AutoTracking',
  FOG_DETECTION: 'FogDetection',
  AUDIO_ANALYSIS: 'AudioAnalysis',
  EMERGENCY_TRIGGER: 'EmergencyTrigger',
  GSENSOR_EVENT: 'GSensorEvent',
  FACE_DETECTION: 'FaceDetection',
  TAMPERING_DETECTION: 'TamperingDetection',
  getLanguageKey: type => {
    switch (type) {
      case 'AudioDetection':
        return 'lang_menu_audiodetection';
      case 'VideoAnalysis':
        return 'lang_menu_iva';
      case 'AlarmInput':
        return 'lang_alarmInput';
      case 'Normal':
        return 'lang_normal_recording';
      case 'MotionDetection':
        return 'lang_motion_detection';
      case 'Manual':
        return 'lang_manual_recording';
      case 'DefocusDetection':
        return 'lang_defocus_detection';
      case 'AutoTracking':
        return 'lang_autotracking';
      case 'FogDetection':
        return 'lang_menu_fogdetection';
      case 'AudioAnalysis':
        return 'lang_menu_soundclassification';
      case 'EmergencyTrigger':
        return 'lang_unknown';
      case 'GSensorEvent':
        return 'lang_unknown';
      case 'FaceDetection':
        return 'lang_faceDetection';
      case 'TamperingDetection':
        return 'lang_tampering';
      default:
        return 'lang_unknown';
    }
  },
};
