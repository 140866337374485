import React from 'react';
import PropTypes from 'prop-types';
import { getLanguage } from 'util/lib';
// import {
//   Button,
// } from 'wisenet-ui/components/atoms';
import {
  PosWrapperStyled,
  PosTitleWrapperStyled,
  PosTitleStyled,
  PosUtilBtnWrapperStyled,
  IconButtonStyled,
  PosDeviceWrapperStyled,
  PosDeviceNameStyled,
  PosEventTimeStyled,
  PosDataWrapperStyled,
  PosDataStyled,
  PosChannelWrapperStyled,
  PosChannelsStyled,
  PosChannelStyled,
  ReactTooltipStyled,
  TooltipSpanStyled,
} from './PosEventStyled';

class PosEvent extends React.Component {
  state = {
    blink: false,
  }

  timer = null;

  componentDidMount() {
    this.timer = setTimeout(this.offBlick, 5000);
    this.setState({
      blink: true,
    });
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  offBlick = () => {
    this.timer = null;
    this.setState({
      blink: false,
    });
  }

  render() {
    const {
      className,
      event,
      // onClickMoveToSearch,
      onClickDeleteItem,
    } = this.props;

    const {
      blink,
    } = this.state;
    let channelList = event.data.ChannelList && event.data.ChannelList;
    let etcChannelList = [];
    if (channelList && channelList.length > 6) {
      channelList = event.data.ChannelList.slice(0, 5);
      etcChannelList = event.data.ChannelList.slice(5, event.data.ChannelList.length);
    }
    const Channels = event.data.ChannelList && event.data.ChannelList.length > 6 ? (
      <PosChannelsStyled>
        {channelList.map(item => (
          <PosChannelStyled key={`channel-${item}`}>{item}</PosChannelStyled>
        ))}
        <PosChannelStyled
          data-tip="etc"
          data-for="etc"
          isEtc
        >
          {'...'}
        </PosChannelStyled>
        <ReactTooltipStyled
          id="etc"
          type="light"
          effect="solid"
          place="bottom"
          delayShow={500}
          getContent={() => (
            <TooltipSpanStyled>
              <div>
                {getLanguage('lang_ch')}
              </div>
              {
                etcChannelList.map((item, index) => {
                  const value = item >= 10 ? item : `0${item}`;
                  return ((index + 1) % 5 !== 0) ? `${value} ` : `${value} \n`;
                })
              }
            </TooltipSpanStyled>
          )}
        />
      </PosChannelsStyled>
    ) : (
      <PosChannelsStyled>
        {channelList.map(item => (
          <PosChannelStyled key={`channel-${item}`}>{item}</PosChannelStyled>
        ))}
      </PosChannelsStyled>
    );
    return (
      <PosWrapperStyled
        className={className}
      >
        <PosTitleWrapperStyled>
          <PosTitleStyled
            isBlink={blink}
          >
            {getLanguage('lang_text')}
          </PosTitleStyled>
          <PosUtilBtnWrapperStyled>
            {/* <IconButtonStyled
              className="wni wni-play"
              onClick={() => onClickMoveToSearch(event.data)}
              data-tip="pos-go-search"
              data-for="pos-go-search"
            />
            <ReactTooltipStyled
              id="pos-go-search"
              type="light"
              effect="solid"
              place="bottom"
              delayShow={500}
              getContent={() => (
                <TooltipSpanStyled>
                  {getLanguage('lang_search')}
                </TooltipSpanStyled>
              )}
            /> */}
            <IconButtonStyled
              className="wni wni-close"
              onClick={() => onClickDeleteItem(event.data.StartTime)}
            />
          </PosUtilBtnWrapperStyled>
        </PosTitleWrapperStyled>
        <PosDeviceWrapperStyled>
          <PosDeviceNameStyled>
            {event.data.DeviceName}
          </PosDeviceNameStyled>
          <PosEventTimeStyled>
            {event.data.deviceTime.format('yyyy.MM.dd HH:mm:ss')}
          </PosEventTimeStyled>
        </PosDeviceWrapperStyled>
        <PosChannelWrapperStyled>
          <span>
            {getLanguage('lang_ch')}
          </span>
          <PosChannelsStyled>
            {Channels}
          </PosChannelsStyled>
        </PosChannelWrapperStyled>
        <PosDataWrapperStyled>
          {event.data.Data !== undefined
            && (
              <PosDataStyled>
                {
                  event.data.Data.replace(/<keyword>/gi, '').replace(/<\/keyword>/gi, '')
                }
              </PosDataStyled>
            )
          }
        </PosDataWrapperStyled>
      </PosWrapperStyled>
    );
  }
}

PosEvent.defaultProps = {
  className: null,
  event: {},
};

PosEvent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  event: PropTypes.instanceOf(Object),
  onClickDeleteItem: PropTypes.func.isRequired,
  // onClickMoveToSearch: PropTypes.func.isRequired,
};

export default PosEvent;
