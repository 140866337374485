import { Component } from 'react';
import PropTypes from 'prop-types';
import umpReadyState from 'wisenet-ui/util/static/constants/umpPlayer/umpReadyState';
import umpPlayMode from 'wisenet-ui/util/static/constants/umpPlayer/umpPlayMode';
import { MessageBoxActions } from 'store/actionCreators';
import { getLanguage } from 'util/lib';

class TilePlaybackContainer extends Component {
  state = {
    mode: umpPlayMode.PLAYBACK,
  }

  waitingPlayModeChange = false;

  handlePlayMode = {};

  keepStartTime = '';

  keepEndTime = '';

  isPause = false; // ump가 현재 상태의 값을 올바르게 주지 못하는 경우가 있어서 사용하는 값.

  isResponsePause = false;

  componentDidMount() {
    this.handlePlayMode = {
      play: this.play,
      pause: this.pause,
      stop: this.stop,
      forward: this.forward,
      backward: this.backward,
      backwardPlay: this.backwardPlay,
      forwardPlay: this.forwardPlay,
    };
  }

  componentDidUpdate(prevProps) { // prevState, snapshot
    const {
      playMode: prevPlayMode,
      startTime: prevStartTime,
      playSpeed: prevPlaySpeed,
      seekingTime: prevSeekingTime,
      stepControlInfomation: prevStepControlInfomation,
      overlappedID: prevOverlappedID,
      changeTileState: prevChangeTileState,
      endTime: prevEndTime,
    } = prevProps;

    const {
      playMode,
      startTime,
      playSpeed,
      umpPlayer,
      seekingTime,
      stepControlInfomation,
      overlappedID,
      changeTileState,
      endTime,
      sound,
      timeLineStartTime,
    } = this.props;

    d.log('[TilePlaybackContainer][cdu] Render');
    if (umpPlayer) {
      const { handlePlayMode } = this;
      d.log('[TilePlaybackContainer][cdu] umpPlayer');
      if (prevPlayMode !== playMode) {
        handlePlayMode[playMode]();
      } else if (prevStartTime !== startTime) {
        d.log('[TilePlaybackContainer][cdu] startTime', prevStartTime, '=>', startTime);
        if (prevOverlappedID !== overlappedID) {
          d.log('[TilePlaybackContainer][cdu] overlappedID', prevOverlappedID, '=>', overlappedID);
          if (umpPlayer.isplay) {
            d.log('[TilePlaybackContainer][cdu] umpPlayer.stop()');
            umpPlayer.stop();
          }
          d.log('[TilePlaybackContainer][cdu] umpPlayer.play()');
          umpPlayer.startTime = startTime;
          umpPlayer.endTime = endTime;
          this.keepStartTime = timeLineStartTime || startTime;
          this.keepEndTime = endTime;
          umpPlayer.overlappedId = overlappedID;
          umpPlayer.playSpeed = playSpeed;
          umpPlayer.play();
        } else if ((!umpPlayer.isplay) && !this.waitingPlayModeChange) {
          d.log('[TilePlaybackContainer][cdu] !umpPlayer.isplay');
          umpPlayer.startTime = startTime;
          this.keepStartTime = startTime;
          this.keepEndTime = endTime;
          umpPlayer.overlappedId = overlappedID;
          umpPlayer.playSpeed = playSpeed;
          umpPlayer.play();
          setTimeout(() => {
            if (sound) {
              // 배속 재생 후에 pause 동작하고 seek을 할 경우 sound를 유지 하지 않아서 넣은 로직
              umpPlayer.unmute();
              umpPlayer.setAudioVolume(10);
            }
          }, 1000);
          this.waitingPlayModeChange = true;
          // 날짜를 변경 하게 되면 영상을 멈추고 다시 시작하도록 한다.
          // seek으로 구현 시 websocket이 끊어 지는 경우가 발생해서 조치
        } else if (prevEndTime !== endTime) {
          d.log('[TilePlaybackContainer][cdu] endTime', prevEndTime, '=>', endTime);
          umpPlayer.stop();
          this.keepStartTime = timeLineStartTime || startTime;
          this.keepEndTime = endTime;
          umpPlayer.startTime = startTime;
          umpPlayer.endTime = endTime;
          umpPlayer.overlappedId = overlappedID;
          umpPlayer.playSpeed = playSpeed;
          umpPlayer.play();
        }
      } else if (playSpeed !== prevPlaySpeed) {
        d.log('[TilePlaybackContainer][cdu] playSpeed', prevPlaySpeed, '=>', playSpeed);
        if (stepControlInfomation.mode !== prevStepControlInfomation.mode) {
          d.log('[TilePlaybackContainer][cdu] (stepControlInfomation.mode !== prevStepControlInfomation.mode)');
          handlePlayMode[stepControlInfomation.mode]();
        }
        umpPlayer.playSpeed = playSpeed;
      } else if (seekingTime !== prevSeekingTime) {
        d.log('[TilePlaybackContainer][cdu] seekingTime', prevSeekingTime, '=>', seekingTime);
        // 역방향인 경우에 ump가 endTime을 넣을 경우 정상적으로 동작하지 않아서
        // 영상을 멈추고 다시 시작하는 로직 추가
        if (stepControlInfomation.mode === 'backwardPlay') {
          d.log('[TilePlaybackContainer][cdu] backwardPlay');
          umpPlayer.stop();
          handlePlayMode[stepControlInfomation.mode](seekingTime);
          umpPlayer.playSpeed = playSpeed;
          umpPlayer.overlappedId = overlappedID;
          umpPlayer.play();
        } else {
          d.log('[TilePlaybackContainer][cdu] !backwardPlay');
          umpPlayer.seekingTime = seekingTime;
        }
      } else if (
        JSON.stringify(stepControlInfomation) !== JSON.stringify(prevStepControlInfomation)
      ) {
        d.log('[TilePlaybackContainer][cdu] stepControlInfomation', prevStepControlInfomation, '=>', stepControlInfomation);
        handlePlayMode[stepControlInfomation.mode]();
      } else if (prevOverlappedID !== overlappedID) {
        d.log('[TilePlaybackContainer][cdu] just overlappedID', prevOverlappedID, '=>', overlappedID);
        if (umpPlayer.isplay) {
          d.log('[TilePlaybackContainer][cdu] umpPlayer.isplay > umpPlayer.stop()');
          umpPlayer.stop();
        }
        d.log('[TilePlaybackContainer][cdu] umpPlayer.play() - 1');
        this.keepStartTime = timeLineStartTime || startTime;
        this.keepEndTime = endTime;
        umpPlayer.startTime = startTime;
        umpPlayer.endTime = endTime;
        umpPlayer.overlappedId = overlappedID;
        umpPlayer.playSpeed = playSpeed;
        umpPlayer.play();
      }

      // 영상 상태가 변경되지 않았는데 play를 하는 로직이 계속 생겨서 넣어둔 로직
      if (prevChangeTileState !== changeTileState) {
        this.waitingPlayModeChange = false;
      }
    }
  }

  play = () => {
    const {
      startTime,
      endTime,
      umpPlayer,
      seekingTime,
      overlappedID,
      timeLineStartTime,
      playModeChangeTime,
    } = this.props;

    if (this.isPause) {
      // rtsp를 통해서 역배속이나 높은 배속으로 할때 resume이 정상동작 하지 않아서 스피드를 1배속으로 변경함
      // 역배속 후 resume 할 경우 endTime 오류로 websocket closed 발생, endTime 추가 입력
      d.log('[TilePlaybackContainer][cdu] play keepEndTime - ', this.keepEndTime);
      umpPlayer.endTime = this.keepEndTime;
      umpPlayer.playSpeed = 1;
      if (this.isResponsePause) {
        umpPlayer.play();
      } else {
        umpPlayer.resume();
        // resume을 할 때 프레임이 없어서 동작을 정상적으로 하지 않는 경우를 위한 예외처리 추가
        // resume을 한 후에 seek을 하도록 처리 정상적인 play가 안되는 경우를 위한 예외
        setTimeout(() => {
          if (playModeChangeTime !== '') {
            umpPlayer.seekingTime = playModeChangeTime;
          }
        }, 300);
      }
    } else if (startTime !== '') {
      const { playSpeed } = this.props;
      umpPlayer.startTime = seekingTime !== '' ? seekingTime : startTime;
      umpPlayer.endTime = endTime;
      this.keepStartTime = timeLineStartTime || startTime;
      this.keepEndTime = endTime;
      umpPlayer.overlappedId = overlappedID;
      umpPlayer.playSpeed = playSpeed;
      umpPlayer.play();
      this.waitingPlayModeChange = true;
    }
    this.isPause = false;
  }

  pause = () => {
    const { umpPlayer } = this.props;
    this.waitingPlayModeChange = false;
    this.isPause = true;

    if (umpPlayer.readyState === umpReadyState.PLAYING) {
      umpPlayer.pause();
    }
  }

  stop = () => {
    // TileContainer에서 stop을 하는 동작을 수행하기 때문에 사용하지 않는다.

    // const { umpPlayer } = this.props;

    // if (umpPlayer.isplay !== umpReadyState.STOPPED) {
    //   umpPlayer.stop();
    // }
  }

  forward = () => {
    const { umpPlayer } = this.props;

    if (umpPlayer.readyState === umpReadyState.PAUSED) {
      umpPlayer.endTime = this.keepEndTime;
      umpPlayer.forward();
    }
  }

  backward = () => {
    const { umpPlayer } = this.props;

    if (umpPlayer.readyState === umpReadyState.PAUSED) {
      umpPlayer.endTime = this.keepStartTime;
      umpPlayer.backward();
    }
  }

  forwardPlay = () => {
    const { umpPlayer, stepControlInfomation: { currentStepTime } } = this.props;

    umpPlayer.startTime = currentStepTime;
    umpPlayer.endTime = this.keepEndTime;
  }

  backwardPlay = seekingTime => {
    const { umpPlayer, stepControlInfomation: { currentStepTime } } = this.props;

    umpPlayer.endTime = this.keepStartTime;
    umpPlayer.startTime = seekingTime || currentStepTime;
  }

  onUpdate = data => (
    this.setState({
      ...data,
    })
  );

  onStatechange = event => {
    if (event.detail) {
      d.log('[TilePlaybackContainer] - onStatechange', event.detail);
      switch (event.detail.error) {
        case 0: {
          if (event.detail.readyState === 1) {
            this.isResponsePause = false;
          }
          break;
        }
        case 8: { // TimeOver
          const { onPlayModeChange } = this.props;
          this.isResponsePause = true;
          onPlayModeChange({
            playMode: 'pause',
          });
          break;
        }
        default:
          break;
      }
    }
  };

  onError = event => {
    if (event.detail) {
      d.log('[TilePlaybackContainer] - onError', event.detail);
      switch (event.detail.error) {
        case 0x020A: { // Talk service unavailable
          break;
        }
        case 0x0204: { // Maximum user Reached
          MessageBoxActions.controlMessageBox({
            messageBoxInfo: {
              title: getLanguage('lang_warning'),
              content: getLanguage('lang_search_maximum_user') + getLanguage('lang_check_reconnect'),
              isOpen: true,
            },
          });
          break;
        }
        default:
          break;
      }
    }
  }

  render() {
    const {
      render,
    } = this.props;

    return render(
      {
        ...this.props,
        ...this.state,
        ...this,
      },
    );
  }
}

TilePlaybackContainer.defaultProps = {
  playMode: 'stop',
  startTime: '', // '2018-12-07T12:28:06Z'
  endTime: '', // '2018-11-13T13:28:06Z'
  playSpeed: 1,
  umpPlayer: null,
  seekingTime: '',
  stepControlInfomation: {
    mode: '',
    currentStepTime: '',
  },
  overlappedID: 0,
  changeTileState: 0,
  sound: false,
  timeLineStartTime: undefined,
  playModeChangeTime: '',
  onPlayModeChange: () => {},
};

TilePlaybackContainer.propTypes = {
  render: PropTypes.func.isRequired,
  playMode: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  // overlappedId: PropTypes.number,
  playSpeed: PropTypes.number,
  umpPlayer: PropTypes.oneOfType([PropTypes.any]),
  seekingTime: PropTypes.string,
  stepControlInfomation: PropTypes.shape({
    mode: PropTypes.string,
    currentStepTime: PropTypes.string,
  }),
  overlappedID: PropTypes.oneOfType([PropTypes.any]),
  changeTileState: PropTypes.number,
  sound: PropTypes.bool,
  timeLineStartTime: PropTypes.string,
  playModeChangeTime: PropTypes.string,
  onPlayModeChange: PropTypes.func,
};

export default TilePlaybackContainer;
