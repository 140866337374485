import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

const ReactModalAdapter = ({ className, ...props }) => {
  ReactModal.setAppElement('body');
  return (
    <ReactModal
      portalClassName={className}
      {...props}
    />
  );
};

ReactModalAdapter.propTypes = {
  className: PropTypes.string,
};

ReactModalAdapter.defaultProps = {
  className: '',
};

export default ReactModalAdapter;
