import styled, { withTheme, css } from 'styled-components';
import { IconButton } from 'wisenet-ui/components/atoms';
import { ListTabs } from 'components/molecules';
import {
  SearchTimeline,
  SearchMediaControlBar,
} from 'components/organisms';

export const TabHeaderWrapperStyled = styled.div`
  width: 100%;
  border-right: 1px solid ${props => props.theme.line.color2};
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.isLast && css`
    border-right: none;
  `}
`;

export const TabIconStyled = styled.i`
  font-size: 20px;
`;

export const TabTitleStyled = styled.span`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  margin-left: 4px;
`;

export const MediaAndResultWrapperStyled = styled.div`
  display: flex;
  flex: 1;
`;

export const MideaWrapperStyled = styled.div`
  flex: 1;
`;

export const SearchPageWrapperStyled = styled.div`
  background-color: ${props => props.theme.background.color1};
  width: 100%;
  padding: 0 24px;
  ${props => (props.isFolding || props.isFullscreen) && css`
    padding-left: 0px;
  `}
  ${props => (props.resultFolding || props.isFullscreen) && css`
    padding-right: 0px;
  `}
`;

export const SideComponentWrapperStyled = styled.div`
  height: calc(100% - 23px);
  border-top: 1px solid ${props => props.theme.line.color6};
  border-bottom: 1px solid ${props => props.theme.line.color6};
`;

export const SideControlWrapperStyled = withTheme(styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: calc(100vh - 218px);
  color: #a5a5a5;
  ${props => props.noDisplay && css`
    display: none;
  `};
`);

export const SideControlButtonStyled = styled(IconButton)`
  width: 24px;
  height: 44px;
  padding: 0;
  color: ${props => props.theme.line.color4};
`;

export const SideControlIconStyled = styled.i`
  font-size: 30px;
  width: 16px;
  height: 30px;
  &.side {
    ${props => props.isFolding && css`
      transform: rotate(180deg);
    `}
  }
  &.result {
    ${props => !props.resultFolding && css`
      transform: rotate(180deg);
    `}
  }
`;

export const SearchTimelineStyled = styled(SearchTimeline)`
  height: 92px;
  flex-basis: 92px;
  padding-top: 13px;
  padding-bottom: 14px;
  border-top: 1px solid #d2d2d2;
  ${props => props.noDisplay && css`
    display: none;
  `}
`;

export const SearchContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SearchMediaControlBarStyled = styled(SearchMediaControlBar)`
`;

export const ResultControlStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  color: #a5a5a5;
  ${props => props.isOver && css`
    position: absolute;
    width: 16px;
    left: calc(100vw - 25px);
    top: calc(50% - 91px);
    color: #fff;
    text-shadow: 1px 0px 0px #000;
  `}
`;

export const ListTabsStyled = styled(ListTabs)`
 height: 100%;
 .content-wrapper {
    position: relative;
  }
`;
