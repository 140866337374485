import styled, { withTheme } from 'styled-components';
import {
  Button,
  IconButton,
  ReactModalAdapter,
} from 'wisenet-ui/components/atoms';
import { Rnd } from 'react-rnd';
import { ResizableAccordion } from 'wisenet-ui/components/organisms';

export const AccordionWrapperStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

export const RndStyled = styled(Rnd)`
  position: relative !important;
  transform: none !important;
  flex-grow: 1;
`;

export const DeviceAccordionStyled = styled(ResizableAccordion)`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-top: 1px solid ${props => props.theme.line.color3};
  .accordion-item {
    border-color: ${props => props.theme.line.color3}
  }
  .accordion-item-header {
    border-color: ${props => props.theme.line.color1}
  }
  .accordion-item-content {
    padding: 0 12px;
  }
`;

export const TextAccordionStyled = styled(ResizableAccordion)`
  flex-shrink: 0;
  overflow-y: auto;
  border-top: 1px solid ${props => props.theme.line.color6};
  min-height: ${props => props.minHeight};
  max-height: ${props => props.maxHeight};
  .accordion-item {
    border-color: ${props => props.theme.line.color3}
  }
  .accordion-item-header {
    border-color: ${props => props.theme.line.color1}
  }
  .accordion-item-content {
    padding: 0 12px;
  }
`;

export const CustomIconButtonStyled = styled(IconButton)`
  font-size: 20px;
  padding: 0;
  &:hover {
    color: #f37321;
  }
`;

export const HeadTitleStyled = styled.span`
  font-size: 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: 48px;
  padding-top: 20px;
  flex-shrink: 0;
`;

export const StyledButton = styled(Button)`
  width: 96px;
  height: 28px;
  margin-left: 8px;
  padding: 0;
`;

export const GuidePopupWrapper = withTheme(styled(ReactModalAdapter).attrs({
  className: 'Popup',
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  
  .ReactModal__Overlay {
    width: 100%;
    height: 100%;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    background-color: rgba(0,0,0,0.5) !important;
  }

  .ReactModal__Content {
    top: 50% !important;
    left: 50% !important;
    width: 300px;
    height: 170px;
    transform: translate(-50%, -50%) !important;
    background-color: #fff !important;
  }

  .ReactModal__Content--after-open {
    padding: 0px !important;
    overflow: hidden !important;
  }
`);
