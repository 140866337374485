import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PTZZoomFocusActions } from 'store/actionCreators';

class PTZFocusZoomControllerContainer extends React.Component {
  state = {
    zoom: 0,
  };

  onSliderAfterChange = ({ id }) => {
    const { currentChannel } = this.props;
    this.setState({
      [id]: 0,
    });
    PTZZoomFocusActions.controlStop({
      Channel: currentChannel,
    });
  }

  onSliderChange = ({ value, id }) => {
    const {
      currentChannel,
      viewModeType,
      curQuadForCh,
    } = this.props;
    const { channel, quadrant } = curQuadForCh;
    this.setState({
      [id]: value,
    });

    const getData = {
      Zoom: value,
      Channel: currentChannel,
      controlStop: false,
    };

    if (viewModeType.indexOf('QuadView') !== -1 && channel === currentChannel) {
      getData.SubViewIndex = quadrant;
    }

    if (getData !== null) {
      PTZZoomFocusActions.controlZoom(getData);
    }
  }

  onZoomBtnChange = flag => {
    const {
      currentChannel,
      viewModeType,
      curQuadForCh,
    } = this.props;
    const { channel, quadrant } = curQuadForCh.toJS();
    let getData = null;
    if (flag === '+') {
      getData = {
        Zoom: 4,
        Channel: currentChannel,
      };
    } else {
      getData = {
        Zoom: -4,
        Channel: currentChannel,
      };
    }

    if (viewModeType.indexOf('QuadView') !== -1 && channel === currentChannel) {
      getData.SubViewIndex = quadrant;
    }

    if (getData !== null) {
      PTZZoomFocusActions.controlZoom(getData);
    }
  }

  onFocusBtnChange = flag => {
    const { currentChannel } = this.props;
    PTZZoomFocusActions.controlFocus({
      Channel: currentChannel,
      Focus: flag,
    });
  }

  onZoomStop = () => {
    const { currentChannel, curQuadForCh, viewModeType } = this.props;
    const { channel, quadrant } = curQuadForCh.toJS();
    const getData = {
      Channel: currentChannel,
      Zoom: 'Stop',
    };

    if (viewModeType.indexOf('QuadView') !== -1 && channel === currentChannel) {
      getData.SubViewIndex = quadrant;
    }

    PTZZoomFocusActions.controlZoomStop(getData);
  }

  onFocusStop = () => {
    const { currentChannel } = this.props;
    PTZZoomFocusActions.controlFocusStop({
      Channel: currentChannel,
      Focus: 'Stop',
    });
  }

  onAutoFocusBtnChange = () => {
    const { currentChannel, imageInfo } = this.props;
    PTZZoomFocusActions.controlAutoFocus({
      Channel: currentChannel,
      Mode: imageInfo.autoFocus ? 'AutoFocus' : 'SimpleFocus',
    });
  }

  render() {
    const { render } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

PTZFocusZoomControllerContainer.defaultProps = {
  currentChannel: -1,
  imageInfo: {},
  viewModeType: undefined,
};

PTZFocusZoomControllerContainer.propTypes = {
  render: PropTypes.func.isRequired,
  currentChannel: PropTypes.number,
  imageInfo: PropTypes.objectOf(PropTypes.any),
  curQuadForCh: PropTypes.objectOf(PropTypes.any).isRequired,
  viewModeType: PropTypes.string,
};

export default connect(
  state => ({
    curQuadForCh: state.ptzZoomFocusModule.get('curQuadForCh'),
  }),
)(PTZFocusZoomControllerContainer);
