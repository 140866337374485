let langList = [];

export const setLanguage = lang => {
  langList = lang;
};

export const getLanguage = langKey => {
  const language = typeof langList[langKey] !== 'undefined'
    ? langList[langKey]
    : langKey;
  return language;
};
