import React from 'react';
import PropTypes from 'prop-types';
import { StatusPopupContainer } from 'containers/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { LiveStatus, RecordStatus, NetworkStatus } from 'components/organisms';
import { getLanguage } from 'util/lib';
import {
  StatusWrapperStyled,
  RefreshAndTimeWrapperStyled,
  RefreshButtonStyled,
  StatusTabsStyled,
  CustomReactTooltip,
  TooltipSpan,
} from './StatusPopupStyled';

const StatusPopup = ({
  className,
  renewalTime,
  onRefresh,
}) => {
  const statusTabs = [
    {
      key: 'tabLiveStatus',
      header: getLanguage('lang_live'),
      component: (
        <LiveStatus />
      ),
    },
    {
      key: 'tabRecordStatus',
      header: getLanguage('lang_record'),
      component: (
        <RecordStatus />
      ),
    },
    {
      key: 'tabNetworkStatus',
      header: getLanguage('lang_network'),
      component: (
        <NetworkStatus />
      ),
    },
  ];
  return (
    <StatusWrapperStyled className={className}>
      <RefreshAndTimeWrapperStyled>
        <span>{`${getLanguage('lang_last_update_time')} ${renewalTime}`}</span>
        <RefreshButtonStyled
          className="wni wni-refresh"
          onClick={onRefresh}
          data-tip="status-popup-refresh"
          data-for="status-popup-refresh"
        />
        <CustomReactTooltip
          id="status-popup-refresh"
          type="light"
          place="top"
          effect="float"
          delayShow={500}
          getContent={() => (
            <TooltipSpan>{getLanguage('lang_refresh')}</TooltipSpan>
          )}
        />
      </RefreshAndTimeWrapperStyled>
      <StatusTabsStyled tabData={statusTabs} align="left" />
    </StatusWrapperStyled>
  );
};

StatusPopup.defaultProps = {
  className: null,
  renewalTime: '',
};

StatusPopup.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  renewalTime: PropTypes.string,
  onRefresh: PropTypes.func.isRequired,
};

export default withContainer(StatusPopupContainer, StatusPopup);
