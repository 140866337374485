import React from 'react';
import PropTypes from 'prop-types';
import { UserAuthContainer } from 'containers/organisms';
import { PopupContent, SupportChannel } from 'components/molecules';
import { UserAuthDetail } from 'components/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { getLanguage } from 'util/lib';
import {
  UserAuthWrapperStyled,
  PopupStyled,
  PopupButtonStyled,
  DetailButtonStyled,
  SupportDescWrapperStyled,
  SupportDescStyled,
  LeftDescStyled,
  RightDescStyled,
  DescTitleStyled,
  SupportTabsStyled,
} from './UserAuthStyled';

const UserAuth = ({
  className,
  userAuthInfo,
  detailPopupVisible,
  onChangeDetailPopupVisible,
  maxChannel,
}) => {
  const supportChannelTabs = [
    {
      key: 'tabSupportLive',
      header: getLanguage('lang_live'),
      component: (
        <SupportChannel data={userAuthInfo.LiveChannel} maxChannel={maxChannel} />
      ),
    },
    {
      key: 'tabSupportSearch',
      header: getLanguage('lang_search'),
      component: (
        <SupportChannel data={userAuthInfo.SearchChannel} maxChannel={maxChannel} />
      ),
    },
    {
      key: 'tabSupportBackup',
      header: getLanguage('lang_backup'),
      component: (
        <SupportChannel data={userAuthInfo.BackupChannel} maxChannel={maxChannel} />
      ),
    },
  ];
  const menuSupport = userAuthInfo.MenuAccess && !(userAuthInfo.MenuAccess.length === 1 && userAuthInfo.MenuAccess[0] === 'None');
  return (
    <UserAuthWrapperStyled className={className}>
      <SupportTabsStyled tabData={supportChannelTabs} />
      <SupportDescWrapperStyled>
        <SupportDescStyled>
          <LeftDescStyled>
            <DescTitleStyled isSupport={menuSupport}>{getLanguage('lang_main_menu')}</DescTitleStyled>
            <DetailButtonStyled
              key="userAuthDetailButton"
              onClick={onChangeDetailPopupVisible}
              disabled={!menuSupport}
            >
              {getLanguage('lang_details')}
            </DetailButtonStyled>
          </LeftDescStyled>
          <RightDescStyled>
            <DescTitleStyled isSupport={userAuthInfo.RecordStartAccess}>{getLanguage('lang_record')}</DescTitleStyled>
          </RightDescStyled>
        </SupportDescStyled>
        <SupportDescStyled>
          <LeftDescStyled>
            <DescTitleStyled isSupport={userAuthInfo.RecordStopAccess}>{getLanguage('lang_record_stop')}</DescTitleStyled>
          </LeftDescStyled>
          <RightDescStyled>
            <DescTitleStyled isSupport={userAuthInfo.PTZAccess}>{getLanguage('lang_ptz')}</DescTitleStyled>
          </RightDescStyled>
        </SupportDescStyled>
        <SupportDescStyled>
          <LeftDescStyled>
            <DescTitleStyled
              isSupport={userAuthInfo.AlarmOutputAccess}
            >
              {getLanguage('lang_remote_alarm_out')}
            </DescTitleStyled>
          </LeftDescStyled>
          <RightDescStyled>
            <DescTitleStyled isSupport={userAuthInfo.ShutdownAccess}>{getLanguage('lang_shutdown')}</DescTitleStyled>
          </RightDescStyled>
        </SupportDescStyled>
      </SupportDescWrapperStyled>
      <PopupStyled
        isOpen={detailPopupVisible}
      >
        <PopupContent
          title="lang_permission_information"
          hasTopCloseButton
          bottomButtons={[
            (
              <PopupButtonStyled key="userAuthDetailPopupCloseButton" onClick={onChangeDetailPopupVisible}>{getLanguage('lang_ok')}</PopupButtonStyled>
            ),
          ]}
          onClosePopup={onChangeDetailPopupVisible}
        >
          <UserAuthDetail userAuthInfo={userAuthInfo} />
        </PopupContent>
      </PopupStyled>
    </UserAuthWrapperStyled>
  );
};

UserAuth.defaultProps = {
  className: null,
  maxChannel: 0,
};

UserAuth.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  userAuthInfo: PropTypes.instanceOf(Object).isRequired,
  detailPopupVisible: PropTypes.bool.isRequired,
  onChangeDetailPopupVisible: PropTypes.func.isRequired,
  maxChannel: PropTypes.number,
};

export default withContainer(UserAuthContainer, UserAuth);
