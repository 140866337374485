import styled, { css } from 'styled-components';
import ReactTable from 'react-table';

export const ReactTableStyled = styled(ReactTable)`
  text-align: center;
  border: none !important;
  ${props => props.scroll && css`
      height: inherit;
  `}
  .rt-noData {
    padding: 0 !important;
  }
  .rt-table {
    position: relative;
  }
  .rt-thead.-header {
    position: sticky;
    top: 0;
    ${props => props.isMultiHeader && css`
      top: 40px;
    `}
    left: 0;
    z-index: 1;
    box-shadow: none !important;
    border-top: 1px solid ${props => props.theme.line.color4};
    background: ${props => props.theme.background.color3} !important;
    ${props => props.changeBackground && css`
      background: ${props.theme.background.color2} !important;
    `}
  }
  .rt-thead.-headerGroups {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    border-top: 1px solid ${props => props.theme.line.color4};
    border-bottom: none !important;
    background: ${props => props.theme.background.color3} !important;
    ${props => props.changeBackground && css`
      background: ${props.theme.background.color2} !important;
    `}
  }
  .rt-tbody {
    border-top: 1px solid ${props => props.theme.line.color7};
    border-bottom: 1px solid ${props => props.theme.line.color4};
    overflow: unset !important;
  }
  .rt-tr-group {
    border-bottom: 1px solid ${props => props.theme.line.color2} !important;
    &:nth-last-child(1) {
      border-bottom: none !important;
    }
    ${props => props.selectRow && css`
      cursor: pointer;
      &:hover {
        background-color: ${props.theme.background.color9};
      }
      &:active {
        background-color: ${props.theme.line.color2};
      }
    `}
  }
  ${props => css`
    .rt-th, .rt-td {
      height: ${props.rowHeight}px;
      line-height: ${props.rowHeight}px !important;
      padding: 0 !important;
    }
  `}
  .rt-th {
    border-right: none !important;
    font-size: 12px;
    background-color: ${props => props.theme.background.color3};
    &.multi-header-even {
      background-color: ${props => props.theme.background.color2};
    }
  }
  .rt-td {
    border-right: none !important;
    font-size: 14px;
  }
  .-sort-desc {
    box-shadow: none !important;
    #sort-icon {
      &:after {
        position: relative;
        left: 2px;
        bottom: 1px;
        content: "▼";
        font-size: 10px;
        color: ${props => props.theme.text.color5};
      }
    }
  }
  .-sort-asc {
    box-shadow: none !important;
    #sort-icon {
      &:after {
        position: relative;
        left: 2px;
        content: "▲";
        font-size: 10px;
        color: ${props => props.theme.text.color5};
        top: -4px;
      }
    }
  }
`;

export const SortIconStyled = styled.div`
  display: inline-block;
  width: 1px;
  height: 1px;
`;
