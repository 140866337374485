import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { IconButton } from 'wisenet-ui/components/atoms';
import MenuTabs from 'components/molecules/tabs/MenuTabs/MenuTabs';

export const StatusWrapperStyled = styled.div`
  margin-top: 15px;
  margin-bottom: 23px;
  color: ${props => props.theme.text.color1};
`;

export const RefreshAndTimeWrapperStyled = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 64px;
  right: 16px;
  z-index: 1;
`;

export const RefreshButtonStyled = styled(IconButton)`
  display: inline-block;
  width: 32px;
  height: 32px;
  font-size: 20px;
  padding: 0;
`;

export const StatusTabsStyled = styled(MenuTabs)`
  .tab-wrapper {
    height: 40px;
    .tab {
      width: 140px;
      font-size: 14px;
    }
  }
  .content-wrapper {
    padding-top: 27px;
  }
`;

export const TooltipSpan = styled.span`
  font-family: NotoSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  margin: 0px;
  padding: 0px
`;

export const CustomReactTooltip = styled(ReactTooltip)`
  background-color: #555 !important;
  padding: 5px !important;
  &.place-top {
    &:after {
      border-top: none !important;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom: none !important;
    }
  }
`;
