import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { PreLoadActions } from 'store/actionCreators';
import { getLanguage } from 'util/lib';
import RecordStatusType from 'wisenet-ui/util/static/constants/mediaControl/recordStatusType';

class EventExportPopupContainer extends React.Component {
  state = {
    showGuidePopup: false,
    showCompletePopup: false,
    guideData: {},
    completeData: {
      message: '',
      tableData: [],
    },
    startBackup: false,
    fileName: '',
    currentBackupPercent: 0,
    totalBackupPercent: 0,
    processedTotalBackupPercent: 0,
    exportEventList: [],
    targetEventList: [],
    targetEvent: {
      ch: 1,
      no: 1,
      oriStartTime: '',
      oriEndTime: '',
      startTime: '',
      endTime: '',
    },
    eventIdx: 0,
    doBackupCall: false,
  };

  tick = 0;

  constructor(props) {
    super(props);
    PreLoadActions.sessionKeyGet();
  }

  componentDidMount() {
    this.initEventList();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) {
      return true;
    }

    if (JSON.stringify(nextState) !== JSON.stringify(this.state)) {
      const {
        showCompletePopup,
        doBackupCall,
      } = nextState;
      if (doBackupCall) {
        this.setState({
          startBackup: true,
          doBackupCall: false,
        });
      }
      // 백업 중지
      // 백업 완료 || 백업 중간 중지
      if (showCompletePopup) {
        this.tick = 0;
        this.setState({
          totalBackupPercent: 0,
          processedTotalBackupPercent: 0,
          currentBackupPercent: 0,
          targetEventList: [],
        });
      }
      return true;
    }

    return false;
  }

  initEventList = () => {
    const { filterEvent, currentChannel } = this.props;
    const eventList = filterEvent.toJS();
    const result = eventList.map((item, idx) => {
      let sTimeString = item.StartTime;
      let eTimeString = item.EndTime;
      let dst = '-';
      const pad2 = n => n.toString().padStart(2, '0');
      if (item.StartDst) {
        const sTimeObj = this.toDate(item.StartTime);
        sTimeObj.setHours(sTimeObj.getHours() - 1);
        sTimeString = `${sTimeObj.getFullYear()}-${pad2(sTimeObj.getMonth() + 1)}-${pad2(sTimeObj.getDate())}T${pad2(sTimeObj.getHours())}:${pad2(sTimeObj.getMinutes())}:${pad2(sTimeObj.getSeconds())}Z`;
        dst = 'DST';
      }

      if (item.EndDst) {
        const eTimeObj = this.toDate(item.EndTime);
        eTimeObj.setHours(eTimeObj.getHours() - 1);
        eTimeString = `${eTimeObj.getFullYear()}-${pad2(eTimeObj.getMonth() + 1)}-${pad2(eTimeObj.getDate())}T${pad2(eTimeObj.getHours())}:${pad2(eTimeObj.getMinutes())}:${pad2(eTimeObj.getSeconds())}Z`;
        dst = 'DST';
      }

      return {
        index: idx,
        no: idx + 1,
        event: getLanguage(RecordStatusType.getLanguageKey(item.Type)), // item.Type,
        languageKey: getLanguage(RecordStatusType.getLanguageKey(item.Type)),
        startTime: item.LocalStartTime.split('T')[1],
        oriStartTime: sTimeString,
        endTime: item.LocalEndTime.split('T')[1],
        oriEndTime: eTimeString,
        dst,
        isSelected: false,
        ch: currentChannel + 1,
        exportStatus: '',
      };
    });

    this.setState({
      exportEventList: result,
    });
  }

  toDate = dateString => {
    const dateStringConv = dateString.includes('Z') ? dateString.replace('Z', '') : dateString;
    const [date, time] = dateStringConv.split('T');
    const [year, month, day] = date.split('-');
    const [hour, minute, second] = time.split(':');
    return new Date(year, month - 1, day, hour, minute, second);
  }

  onChangeCheckTableData = newData => {
    this.setState({
      exportEventList: newData,
    });
  }

  validateChannel = () => {
    const {
      exportEventList,
    } = this.state;
    let showPopup = true;
    exportEventList.forEach(item => {
      if (item.isSelected) {
        showPopup = false;
      }
    });

    if (showPopup) {
      this.setState({
        showGuidePopup: true,
        guideData: {
          id: 0,
          title: getLanguage('lang_error'),
          content: getLanguage('lang_export_nodata_msg'),
        },
      });
      return false;
    }
    return true;
  }

  doBackup = () => {
    if (this.validateChannel()) {
      const {
        exportEventList,
        targetEventList,
      } = this.state;
      exportEventList.forEach(item => {
        if (item.isSelected) {
          targetEventList.push(item);
        }
      });

      this.setState({
        targetEventList,
        targetEvent: targetEventList[0],
        doBackupCall: true,
        eventIdx: 0,
      });
    }
  }

  onGuidePopupConfirm = () => {
    this.setState({
      showGuidePopup: false,
    });
  }

  onCompletePopupConfirm = () => {
    this.setState({
      showCompletePopup: false,
      completeData: {
        message: '',
        tableData: [],
      },
    });
  }

  onBackup = type => {
    const {
      startBackup,
    } = this.state;

    if (!startBackup) {
      return;
    }
    const { event } = type;

    if (type.type === 'progress') {
      this.tick += 1;
      if (!(this.tick % 50)) {
        this.handleBackupProgress(type.percent);
      }
    } else if (type.type === 'end') {
      console.log('onBackup end event.detail', event.detail);
      this.handleBackupEnd(event.detail);
    } else if (type.type === 'error') {
      // const { event } = type;
      // this.handleBackupError(event.detail);
      console.log('onBackup error event.detail', event.detail);
      this.handleBackupEnd(event.detail);
    }
  }

  handleBackupEnd = info => {
    if (info.error === 768) {
      return;
    }
    const {
      completeData,
      targetEvent,
      targetEventList,
      eventIdx,
    } = this.state;

    const completeTableData = completeData.tableData;
    if (info.state === 1538) {
      completeTableData.push({
        no: targetEvent.no,
        status: getLanguage('lang_export_network_error'),
      });
    } else if (info.error === 515) {
      completeTableData.push({
        no: targetEvent.no,
        status: getLanguage('lang_export_no_video'),
      });
    } else if (info.state === 1539
        || info.state === 1540
        || info.state === 1541
        || info.state === 1542
        || info.state === 1543
        || info.state === 1544) {
      completeTableData.push({
        no: targetEvent.no,
        status: getLanguage('lang_export_failure'),
      });
    } else {
      completeTableData.push({
        no: targetEvent.no,
        status: getLanguage('lang_export_complete'),
      });
    }

    if (typeof targetEventList[eventIdx + 1] !== 'undefined') {
      this.setState({
        startBackup: false,
        currentBackupPercent: 0,
        processedTotalBackupPercent: parseInt(100 * ((eventIdx + 1) / targetEventList.length), 10),
        totalBackupPercent: parseInt(100 * ((eventIdx + 1) / targetEventList.length), 10),
      });
      this.tick = 0;
      setTimeout(() => { // 연속 백업시 오류 발생 일정 간격 생성
        this.setState({
          startBackup: true,
          targetEvent: targetEventList[eventIdx + 1],
          eventIdx: eventIdx + 1,
          completeData: {
            tableData: completeTableData,
          },
          // doBackupCall: true,
        });
      }, 1500);

      console.log('onBackup targetEventList', targetEventList[eventIdx + 1]);
    } else {
      this.setState({
        startBackup: false,
      });
      this.tick = 0;
      this.setState({
        completeData: {
          message: getLanguage('lang_export_complete_msg'),
          tableData: completeTableData,
        },
        eventIdx: 0,
        showCompletePopup: true,
      });
      console.log('onBackup lang_export_complete');
    }
  }

  handleBackupError = info => {
    if (info.error === 768) {
      return;
    }

    const {
      completeData,
      targetEvent,
    } = this.state;

    this.setState({
      startBackup: false,
    });

    if (info.state === 1538) {
      this.setState({
        completeData: {
          message: getLanguage('lang_export_complete_msg'),
          tableData: completeData.tableData.concat({
            no: targetEvent.no,
            status: getLanguage('lang_export_network_error'),
          }),
        },
        showCompletePopup: true,
      });
    } else if (info.error === 515) {
      this.setState({
        completeData: {
          message: getLanguage('lang_export_complete_msg'),
          tableData: completeData.tableData.concat({
            no: targetEvent.no,
            status: getLanguage('lang_export_no_video'),
          }),
        },
        showCompletePopup: true,
      });
    } else if (info.state === 1539
      || info.state === 1540
      || info.state === 1541
      || info.state === 1542
      || info.state === 1543
      || info.state === 1544
      || info.state === 1545) {
      this.setState({
        completeData: {
          message: getLanguage('lang_export_complete_msg'),
          tableData: completeData.tableData.concat({
            no: targetEvent.no,
            status: getLanguage('lang_export_failure'),
          }),
        },
        showCompletePopup: true,
      });
    } else {
      this.setState({
        completeData: {
          message: getLanguage('lang_export_complete_msg'),
          tableData: completeData.tableData.concat({
            no: targetEvent.no,
            status: getLanguage('lang_complex_error'),
          }),
        },
        showCompletePopup: true,
      });
    }
  }

  handleBackupProgress = progress => {
    const {
      currentBackupPercent,
      processedTotalBackupPercent,
      targetEventList,
    } = this.state;
    const percent = progress > 100 ? 100 : parseInt(progress, 10);

    if (currentBackupPercent !== percent) {
      this.setState({
        currentBackupPercent: percent,
        totalBackupPercent: processedTotalBackupPercent
          + parseInt(percent / targetEventList.length, 10),
      });
    }
  }

  onStopBackup = () => {
    const { completeData, targetEvent } = this.state;
    const completedArray = completeData.tableData;

    completedArray[targetEvent.index] = {
      no: targetEvent.no,
      status: getLanguage('lang_export_failure'),
    };

    console.log('onBackup Stop lang_export_complete');
    this.setState({
      startBackup: false,
      showCompletePopup: true,
      completeData: {
        message: getLanguage('lang_export_complete_msg'),
        tableData: completedArray,
      },
    });
  }

  render() {
    const {
      render,
    } = this.props;

    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

EventExportPopupContainer.propTypes = {
  render: PropTypes.func.isRequired,
  filterEvent: PropTypes.instanceOf(List),
  currentChannel: PropTypes.number,
};

EventExportPopupContainer.defaultProps = {
  filterEvent: List([]),
  currentChannel: 0,
};

export default connect(
  state => ({
    sessionKey: state.preLoadModule.get('backupSessionKey'),
    searchDateObj: state.eventSearchModule.get('searchDateObj'),
    filterEvent: state.searchTimelineModule.get('filterEvent'),
    currentChannel: state.searchTimelineModule.get('currentChannel'),
  }),
)(EventExportPopupContainer);
