import styled from 'styled-components';
import { IconButton } from 'wisenet-ui/components/atoms';

export const PopupContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 16px;
  color: ${props => props.theme.text.color2};
`;

export const TopWrapperStyled = styled.div`
  flex-basis: 44px;
  height: 44px;
  padding-top: 18x;
  border-bottom: 1px solid ${props => props.theme.line.color2};
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
`;

export const MiddleWrapperStyled = styled.div`
  flex: 1;
`;

export const BottomWrapperStyled = styled.div`
  flex-basis: 61px;
  height: 61px;
  border-top: 1px solid ${props => props.theme.line.color2};
  padding-top: 16px;
  display: flex;
  justify-content: center;
`;

export const TitleStyled = styled.span`
  height: 20px;
  line-height: 20px;
`;

export const Icontyled = styled(IconButton)`
  width: 32px;
  height: 32px;
  font-size: 20px;
  padding: 0;
`;
