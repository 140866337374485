import React from 'react';
import { withContainers } from 'wisenet-ui/util/hoc';
import { MainRoute } from 'routes';
import { MainPageContainer, AccessChangeContainer } from 'containers/pages';
import { Header } from 'components/organisms';
import { MainContainerStyled } from './MainPageStyled';

const MainPage = () => (
  <MainContainerStyled>
    <Header />
    <MainRoute />
  </MainContainerStyled>
);

export default withContainers(
  [MainPageContainer, AccessChangeContainer],
  MainPage,
);
