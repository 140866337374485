import { createAction, handleActions } from 'redux-actions';
import { Map } from 'immutable';

// action
export const PLAY_CONTROL = 'searchMediaControl/PLAY_CONTROL';
export const PLAY_SPEED_CONTROL = 'searchMediaControl/PLAY_SPEED_CONTROL';
export const OSD_DISPLAY_CONTROL = 'searchMediaControl/OSD_DISPLAY_CONTROL';
export const SET_SELECT_TIME_RANGE = 'searchMediaControl/SET_SELECT_TIME_RANGE';
export const STEP_CONTROL = 'searchMediaControl/STEP_CONTROL';

// action create
export const playControl = createAction(PLAY_CONTROL);
export const playSpeedControl = createAction(PLAY_SPEED_CONTROL);
export const OSDDisplayControl = createAction(OSD_DISPLAY_CONTROL);
export const setSelectTimeRange = createAction(SET_SELECT_TIME_RANGE);
export const stepControl = createAction(STEP_CONTROL);

const initialState = Map({
  playMode: 'stop',
  playModeChangeTime: '',
  playSpeed: 1,
  OSDDisplay: true,
  useSelectTimeRange: false,
  stepControlInfomation: {
    mode: 'forwardPlay',
    currentStepTime: '',
  },
});

// reducer
export default handleActions({
  [PLAY_CONTROL]: (state, { payload: { playMode, playModeChangeTime } }) => (
    state.set('playMode', playMode)
      .set('playModeChangeTime', playModeChangeTime)
  ),
  [PLAY_SPEED_CONTROL]: (state, { payload: { playSpeed } }) => (
    state.set('playSpeed', playSpeed)
  ),
  [OSD_DISPLAY_CONTROL]: state => state.set('OSDDisplay', !state.get('OSDDisplay')),
  [SET_SELECT_TIME_RANGE]: (state, { payload: { useSelectTimeRange } }) => (
    state.set('useSelectTimeRange', useSelectTimeRange)
  ),
  [STEP_CONTROL]: (state, { payload: { stepControlInfomation } }) => (
    state.set('stepControlInfomation', stepControlInfomation)
  ),
}, initialState);
