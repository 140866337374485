import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from 'wisenet-ui/util/hoc';
import { ResizableAccordionContainer } from 'wisenet-ui/containers/organisms';
import { getLanguage } from 'util/lib';
import {
  AccordionItemStyled,
  AccordionItemHeaderStyled,
  HeaderLeftStyled,
  HeaderRightStyled,
  HeaderButtonStyled,
  AccordionItemContentStyled,
  RndStyled,
  CustomReactTooltip,
  TooltipSpan,
} from './ResizableAccordionStyled';

const ResizableAccordion = ({
  className,
  items,
  onClick,
  onChanged,
  onResize,
  resize,
  height,
  innerRef,
  style,
}) => {
  const lastIndex = items.length - 1;
  return (
    <div
      className={className}
      ref={innerRef}
      style={style}
    >
      {items.map((item, index) => (
        <AccordionItemStyled
          className={`accordion-item ${item.id}`}
          isOpen={item.isOpen}
          key={item.id}
          maxHeight={resize && lastIndex !== index}
        >
          <AccordionItemHeaderStyled
            className={`accordion-item-header ${item.id}`}
          >
            <HeaderLeftStyled>
              {item.headerLeft}
            </HeaderLeftStyled>
            <HeaderRightStyled>
              {item.headerRight}
              {
                item.headerIcons && item.headerIcons.map(icon => (
                  icon
                ))
              }
              {
                !item.disableFolding && (
                  <>
                    <HeaderButtonStyled
                      data-tip={`${item.id}-accordion-close-open-button`}
                      data-for={`${item.id}-accordion-close-open-button`}
                      isOpen={item.isOpen}
                      onClick={() => onClick(item.id)}
                    />
                    <CustomReactTooltip
                      id={`${item.id}-accordion-close-open-button`}
                      type="light"
                      place="top"
                      effect="float"
                      isOpend
                      delayShow={500}
                      getContent={() => {
                        const tooltip = item.isOpen ? 'lang_closed' : 'lang_open';
                        return (
                          <TooltipSpan>{getLanguage(tooltip)}</TooltipSpan>
                        );
                      }}
                    />
                  </>
                )
              }
            </HeaderRightStyled>
          </AccordionItemHeaderStyled>
          {
            (resize && lastIndex !== index) ? (
              <RndStyled
                enableResizing={{
                  bottom: true,
                  bottomLeft: false,
                  bottomRight: false,
                  left: false,
                  right: false,
                  top: false,
                  topLeft: false,
                  topRight: false,
                }}
                disableDragging
                size={{
                  width: '100%',
                  height: item.isOpen ? height[index] : '0px',
                }}
                minHeight={0}
                onResize={(e, direction, ref) => onResize(e, ref, index)}
                isdisplay={item.isOpen.toString()}
              >
                <AccordionItemContentStyled
                  isOpen={item.isOpen}
                  className={`accordion-item-content ${item.id}`}
                  resize
                  onChange={onChanged}
                  scroll={item.contentScroll}
                  style={{
                    height: '100%',
                    minHeight: '0px',
                  }}
                >
                  {item.content}
                </AccordionItemContentStyled>
              </RndStyled>
            ) : (
              <AccordionItemContentStyled
                isOpen={item.isOpen}
                className={`accordion-item-content ${item.id}`}
                onChange={onChanged}
                scroll={item.contentScroll}
              >
                {item.content}
              </AccordionItemContentStyled>
            )
          }
        </AccordionItemStyled>
      ))}
    </div>
  );
};

ResizableAccordion.defaultProps = {
  className: null,
  onClick: () => {},
  onChanged: () => {},
  resize: false,
  innerRef: () => {},
  style: {},
};

ResizableAccordion.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  items: PropTypes.instanceOf(Array).isRequired,
  onClick: PropTypes.func,
  onChanged: PropTypes.func,
  resize: PropTypes.bool,
  height: PropTypes.instanceOf(Array).isRequired,
  onResize: PropTypes.func.isRequired,
  innerRef: PropTypes.func,
  style: PropTypes.instanceOf(Object),
};

export default withContainer(ResizableAccordionContainer, ResizableAccordion);
