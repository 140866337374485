import styled, { withTheme } from 'styled-components';
import { Table } from 'wisenet-ui/components/organisms';
import {
  Button,
  Span,
} from 'wisenet-ui/components/atoms';

export const Container = styled.div`
  width: 300px;
  height: 350px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.text.color1};
  background-color: ${props => props.theme.background.color1};
`;

export const Header = withTheme(styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid ${props => props.theme.text.color1};
  background-color: ${props => props.theme.line.color4};
`);

export const Body = withTheme(styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`);

export const Footer = withTheme(styled.div`
  height: 40px;
  width: 100%;
  display: block;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5px;
`);

export const TitleSpan = withTheme(styled(Span)`
  width: 165px;
  height: 19px;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${props => props.theme.background.color1};
  margin-left: 16px;
  margin-top: 4px;
  margin-bottom: 8px;
`);

export const StatusSpan = withTheme(styled(Span)`
  width: 100%;
  height: 19px;
  font-family: NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.color2};
  margin-left: 16px;
  margin-top: 4px;
  margin-bottom: 8px;
`);

export const CloseButton = styled(Button)`
  width: 100px;
  height: 25px;
  object-fit: contain;
  padding-top: 2px;
`;

export const TableWrapper = withTheme(styled.div`
  border: 1px solid ${props => props.theme.text.color1};
  height: 200px;
  width: 260px;
  margin: 5px auto;
`);

export const StyledTable = withTheme(styled(Table)`
  .custom-header {
    background-color: ${props => props.theme.background.color2};
    font-weight: bold !important;
  }
  border: 1px solid ${props => props.theme.text.color1};
`);

export const StatusWrapper = styled.div`
  width: 100%;
  margin: 5px auto;
`;
