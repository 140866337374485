import React from 'react';
import PropTypes from 'prop-types';
import { LiveEventFilterContainer } from 'containers/organisms';
import { withContainer } from 'wisenet-ui/util/hoc';
import { Button } from 'wisenet-ui/components/atoms';
import { Tree } from 'wisenet-ui/components/organisms';
import { getLanguage } from 'util/lib';
import {
  EventFilterWrapperStyled,
  EventFilterHeaderStyled,
  EventFilterTitle,
  EventFilterCloseStyled,
  TreeWrapperStyled,
  EventFilterBottomStyled,
} from './LiveListItemsStyled';

const LiveEventFilter = ({
  eventFilterList,
  willExportTree,
  applyEventFilter,
  setApply,
  setCancel,
}) => (
  <EventFilterWrapperStyled>
    <EventFilterHeaderStyled>
      <EventFilterTitle>
        {getLanguage('lang_filter')}
      </EventFilterTitle>
      <EventFilterCloseStyled
        className="wni wni-close"
        onClick={() => setCancel()}
      />
    </EventFilterHeaderStyled>
    <TreeWrapperStyled>
      <Tree
        treeData={eventFilterList}
        showCheckbox
        // showIcon
        canDrag={false}
        exportTreeData={willExportTree}
        exportTreeDataFunc={applyEventFilter}
        setApply={setApply}
      />
    </TreeWrapperStyled>
    <EventFilterBottomStyled>
      <Button
        onClick={() => setApply(true)}
      >
        {getLanguage('lang_ok')}
      </Button>
      <Button
        onClick={() => setCancel()}
        style={{ marginLeft: '4px' }}
      >
        {getLanguage('lang_cancel')}
      </Button>
    </EventFilterBottomStyled>
  </EventFilterWrapperStyled>
);

LiveEventFilter.propTypes = {
  eventFilterList: PropTypes.arrayOf(PropTypes.shape({
    DeviceName: PropTypes.string,
    Enable: PropTypes.bool,
    Port: PropTypes.number,
    EventPlaybackStartTime: PropTypes.number,
    EncodingType: PropTypes.string,
    ReceiptEnd: PropTypes.string,
    ReceiptStart: PropTypes.string,
    ChannelIDList: PropTypes.arrayOf(PropTypes.string),
  })),
  setApply: PropTypes.func,
  setCancel: PropTypes.func,
  willExportTree: PropTypes.bool.isRequired,
  applyEventFilter: PropTypes.func.isRequired,
  // lang: PropTypes.object.isRequired,
};

LiveEventFilter.defaultProps = {
  eventFilterList: [{
    DeviceName: '',
    Enable: false,
    Port: 0,
    EventPlaybackStartTime: 0,
    EncodingType: '',
    ReceiptEnd: '',
    ReceiptStart: '',
    ChannelIDList: [],
  }],
  setApply: () => {},
  setCancel: () => {},
};

export default withContainer(LiveEventFilterContainer, LiveEventFilter);
