import React from 'react';
import PropTypes from 'prop-types';
import tilePTZControlType from 'wisenet-ui/util/static/constants/mediaControl/tilePTZControlType';
import { MediaControlIDList } from 'wisenet-ui/util/static/constants/mediaControl/mediaControlType';
import { getLanguage } from 'util/lib';
import {
  TilePTZFunctionBarStyled,
  IconButtonStyled,
  CustomReactTooltip,
  TooltipSpan,
} from './TilePTZFunctionBarStyled';

class TilePTZFunctionBar extends React.PureComponent {
  render() {
    const {
      onClick,
      ptzInfo,
      ptzMode,
    } = this.props;

    const {
      MANUAL_TRACKING,
      AUTO_TRACKING,
      AREA_ZOOM,
      RETURN_1X,
      BACK,
    } = tilePTZControlType;

    const {
      CAPTURE,
      INSTANT_PLAYBACK,
      D_ZOOM,
    } = MediaControlIDList;

    const backButton = {
      clickId: BACK,
      iconId: 'wni wni-backmode',
      support: true,
    };

    const ptzFunctionItems = [
      {
        clickId: CAPTURE,
        iconId: 'wni wni-capture',
        support: true,
        languageKey: 'lang_capture',
      },
      {
        clickId: INSTANT_PLAYBACK,
        iconId: 'wni wni-instant-playback',
        support: true,
        languageKey: 'lang_instant_viewer',
      },
      {
        clickId: MANUAL_TRACKING,
        iconId: 'wni wni-tracking-manual',
        support: ptzInfo && ptzInfo.digitalAutoTracking,
      },
      {
        clickId: AUTO_TRACKING,
        iconId: 'wni wni-tracking-auto',
        support: ptzInfo && ptzInfo.digitalAutoTracking,
      },
      {
        clickId: AREA_ZOOM,
        iconId: 'wni wni-areazoom',
        support: ptzInfo && ptzInfo.areaZoom,
      },
      {
        clickId: RETURN_1X,
        iconId: 'wni wni-return-1-x',
        support: ptzInfo && ptzInfo.areaZoom,
      },
      {
        clickId: D_ZOOM,
        iconId: 'wni wni-dzoom',
        support: true,
        active: ptzMode === D_ZOOM,
        languageKey: 'lang_digital_zoom',
      },
    ];

    return (
      <React.Fragment>
        <IconButtonStyled
          className={`${backButton.iconId} leftButton`}
          onMouseDown={e => { e.stopPropagation(); }}
          onMouseUp={e => { e.stopPropagation(); }}
          onDoubleClick={e => { e.stopPropagation(); }}
          onClick={onClick(backButton.clickId)}
          key={backButton.iconId}
          isActive={backButton.active}
          data-tip="Cancel-Tile-PTZ-Function"
          data-for="Cancel-Tile-PTZ-Function"
        />
        <CustomReactTooltip
          id="Cancel-Tile-PTZ-Function"
          type="light"
          place="top"
          effect="float"
          isOpend
          delayShow={500}
          getContent={() => (
            <TooltipSpan>{getLanguage('lang_cancel')}</TooltipSpan>
          )}
        />
        <TilePTZFunctionBarStyled>
          {ptzFunctionItems.map(item => item.support && (
            <IconButtonStyled
              onMouseDown={e => { e.stopPropagation(); }}
              onMouseUp={e => { e.stopPropagation(); }}
              onDoubleClick={e => { e.stopPropagation(); }}
              onClick={onClick(item.clickId)}
              key={item.iconId}
              isActive={item.active}
              className={`${item.iconId}`}
              data-tip={`${item.clickId}-Tile-PTZ-Function`}
              data-for={`${item.clickId}-Tile-PTZ-Function`}
            >
              <CustomReactTooltip
                id={`${item.clickId}-Tile-PTZ-Function`}
                type="light"
                place="top"
                effect="float"
                isOpend
                delayShow={500}
                getContent={() => (
                  <TooltipSpan>{getLanguage(item.languageKey)}</TooltipSpan>
                )}
              />
            </IconButtonStyled>
          ))}
        </TilePTZFunctionBarStyled>
      </React.Fragment>
    );
  }
}

TilePTZFunctionBar.defaultProps = {
  onClick: () => {},
};

TilePTZFunctionBar.propTypes = {
  onClick: PropTypes.func,
  ptzInfo: PropTypes.instanceOf(Object).isRequired,
  ptzMode: PropTypes.string.isRequired,
};

export default TilePTZFunctionBar;
