import React from 'react';
import PropTypes from 'prop-types';
import { getLanguage } from 'util/lib';
import {
  Container,
  Body,
  Header,
  Footer,
  TitleSpan,
  ContentSpan,
  CloseButton,
  CancelButton,
  SaveButton,
} from './PresetGuidePopupStyled';

class PresetGuidePopup extends React.PureComponent {
  render() {
    const {
      onConfirm,
      onCancel,
      title,
      content,
    } = this.props;
    return (
      <Container>
        <Header>
          <TitleSpan>{title}</TitleSpan>
          <CloseButton onClick={onCancel}>
            <i className="wni wni-close" />
          </CloseButton>
        </Header>
        <Body>
          <ContentSpan>
            {content}
          </ContentSpan>
        </Body>
        <Footer>
          <SaveButton onClick={onConfirm}>{getLanguage('lang_ok')}</SaveButton>
          <CancelButton onClick={onCancel}>{getLanguage('lang_cancel')}</CancelButton>
        </Footer>
      </Container>
    );
  }
}

PresetGuidePopup.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

PresetGuidePopup.defaultProps = {
  title: '',
  content: '',
  onConfirm: null,
  onCancel: null,
};

export default PresetGuidePopup;
