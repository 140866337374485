import React from 'react';
import PropTypes from 'prop-types';

class SelectTimeRangeContainer extends React.Component {
  constructor(props) {
    super(props);

    this._wrapper = null;
    this._start = null;
    this._end = null;
  }

  state = {
    start: new Date(),
    end: new Date(),
    mouseDown: {
      id: null,
      value: false,
    },
  }

  componentDidMount() {
    const {
      getCustomTime,
      timeToScreen,
      screenToTime,
      changed,
    } = this.props;
    const currentTime = getCustomTime();
    const maxLeft = this._wrapper.getBoundingClientRect().width;
    // CurrentTime 기준 30분 전/후에 Start, End 구간 기본 설정 (30분은 임의로 설정함)
    const startRange = new Date(new Date(currentTime).setMinutes(currentTime.getMinutes() - 30));
    const endRange = new Date(new Date(currentTime).setMinutes(currentTime.getMinutes() + 30));

    const start = timeToScreen(startRange) < 0 ? screenToTime(0) : startRange;
    const end = timeToScreen(endRange) > maxLeft ? screenToTime(maxLeft) : endRange;

    this.setState({
      start,
      end,
    });

    changed(start.toISOString(), end.toISOString());
  }

  handleMouseUp = () => {
    const { changed } = this.props;
    const { start, end, mouseDown } = this.state;

    this.setState({
      mouseDown: {
        ...mouseDown,
        value: false,
      },
    });

    changed(start.toISOString(), end.toISOString());
  }

  handleMouseDown = e => {
    this.setState({
      mouseDown: {
        id: e.target.dataset.id,
        value: true,
      },
    });
  }

  handleMouseMove = e => {
    const { screenToTime, timeToScreen, getCustomTime } = this.props;
    const { mouseDown } = this.state;
    const { clientX, currentTarget } = e;
    if (mouseDown.value) {
      const {
        width: offsetWidth,
        left: offsetLeft,
      } = currentTarget.offsetParent.getBoundingClientRect();
      const currentTimeX = timeToScreen(getCustomTime());
      const x = clientX - offsetLeft;
      if (
        (mouseDown.id === 'start' && x >= 0 && x <= currentTimeX)
        || (mouseDown.id === 'end' && x <= offsetWidth && x >= currentTimeX)
      ) {
        this.setState({
          [mouseDown.id]: screenToTime(x),
        });
      }
    }
  }

  handleClickMask = () => {
    const { canceledTimeRange } = this.props;
    canceledTimeRange();
  }

  setRef = (name, elem) => {
    this[`_${name}`] = elem;

    if (name === 'wrapper') {
      const { screenToTime } = this.props;
      this.setState({
        start: screenToTime(0),
        end: screenToTime(this[`_${name}`].offsetWidth),
      });
    }
  }

  render() {
    const { render } = this.props;
    return render(
      {
        ...this,
        ...this.props,
        ...this.state,
      },
    );
  }
}

SelectTimeRangeContainer.propTypes = {
  render: PropTypes.func.isRequired,
  changed: PropTypes.func.isRequired,
  screenToTime: PropTypes.func.isRequired,
  canceledTimeRange: PropTypes.func.isRequired,
  timeToScreen: PropTypes.func.isRequired,
  getCustomTime: PropTypes.func.isRequired,
};

export default SelectTimeRangeContainer;
