import {
  take,
  all,
  select,
  put,
  call,
} from 'redux-saga/effects';
import { SunapiClient } from 'util/lib';
import { setTileCameraListPage, setBackupTileCameraListPage, setTileCameraData } from 'store/modules/camera/cameraInfoModule';
import * as liveMediaControlActions from 'store/modules/mediaControl/liveMediaControlModule';
import staticLayoutPattern from 'wisenet-ui/util/static/constants/layoutPattern/staticLayoutPattern';

function* asyncAlarmStopSaga() {
  while (true) {
    yield take(liveMediaControlActions.ALARM_STOP);
    try {
      yield SunapiClient.get('/stw-cgi/io.cgi?msubmenu=alarmreset&action=control');

      yield put(liveMediaControlActions.setAlarmStop());
    } catch (error) {
      console.log('[Error] Alarm stop cgi failed');
    }
  }
}

function* asyncManualRecordingStartSaga() {
  while (true) {
    yield take(liveMediaControlActions.MANULA_RECORDING_START);
    try {
      SunapiClient.get('/stw-cgi/recording.cgi?msubmenu=manualrecording&action=control&Mode=Start');
    } catch (error) {
      console.log('[Error] Menual Recording start cgi failed');
    }
  }
}

function* asyncManualRecordingStopSaga() {
  while (true) {
    yield take(liveMediaControlActions.MANULA_RECORDING_STOP);
    try {
      SunapiClient.get('/stw-cgi/recording.cgi?msubmenu=manualrecording&action=control&Mode=Stop');
    } catch (error) {
      console.log('[Error] Menual Recording stop cgi failed');
    }
  }
}

function* LayoutPageInfomationChange({
  tileCameraListPage,
  layoutPageCurrentNumber,
  layoutPageMaxNumber,
  layoutPagePatternTileCount,
}) {
  yield put(setTileCameraListPage({
    tileCameraListPage,
  }));

  yield put(liveMediaControlActions.setLayoutPageInfo({
    layoutPageCurrentNumber,
    layoutPageMaxNumber,
    layoutPagePatternTileCount,
  }));
}

const makeTileCameraListPage = ({
  tileCameraListPage,
  currentPatternTileCount,
}) => {
  const changeTileCameraListPage = {
    1: [],
  };
  let index = 0;
  const tileCameraListMerge = Object.keys(tileCameraListPage).reduce(
    (res, value) => {
      const soltTileCameraList = tileCameraListPage[value].sort((a, b) => (
        (a.index > b.index) ? 1 : -1
      ));
      const changeTileList = soltTileCameraList.map(tileCameraInfo => {
        const tileInfo = tileCameraInfo;
        delete tileInfo.w;
        delete tileInfo.h;
        delete tileInfo.x;
        delete tileInfo.y;
        tileInfo.index = index % currentPatternTileCount;
        index += 1;
        return tileInfo;
      });
      return res.concat(changeTileList);
    }, [],
  );

  let pageCount = 1;

  while (tileCameraListMerge.length !== 0) {
    changeTileCameraListPage[pageCount] = tileCameraListMerge.splice(
      0, currentPatternTileCount,
    );
    pageCount += 1;
  }

  return changeTileCameraListPage;
};

function* asyncLayoutPageChangeSaga() {
  let beforePageNumber = 1;

  while (true) {
    const action = yield take(liveMediaControlActions.PATTERN_CONTROL);
    const { pattern, isBackup, layoutListInject } = action.payload;
    let currentPageNumber = 1;
    const currentPatternTileCount = staticLayoutPattern[pattern].items.length;

    const moduleState = yield select(state => ({
      tileCameraListPage: state.cameraInfoModule.get('tileCameraListPage').toJS(),
      selectTileCamera: state.cameraInfoModule.get('selectTileCamera').toJS(),
      beforePageNumberStore: state.liveMediaControlModule.get('layoutPageInfo').get('currentNumber'),
      backupTileCameraListPage: state.cameraInfoModule.get('backupTileCameraListPage').toJS(),
    }));

    const { tileCameraListPage, selectTileCamera } = moduleState;
    const beforeTileCameraListPage = JSON.parse(JSON.stringify(tileCameraListPage));
    // layout list에서 등록 시 기존 등록된 page를 지우도록 변경
    let changeTileCameraListPage = layoutListInject ? { 1: [] } : makeTileCameraListPage({
      tileCameraListPage,
      currentPatternTileCount,
    });

    let currentMaxNumber = Object.keys(changeTileCameraListPage).length;

    if (isBackup) {
      const { uid: removeUid } = action.payload;
      const { backupTileCameraListPage } = moduleState;
      changeTileCameraListPage = backupTileCameraListPage;

      if (removeUid) {
        const removeIndex = changeTileCameraListPage[beforePageNumber].findIndex(
          changeTile => (changeTile.uid === removeUid),
        );
        changeTileCameraListPage[beforePageNumber].splice(removeIndex, 1);
      }
      currentMaxNumber = Object.keys(changeTileCameraListPage).length;
      currentPageNumber = beforePageNumber;
    } else if (pattern !== 'dynamic' && pattern === 'SPECIAL') {
      const { beforePageNumberStore } = moduleState;
      Object.values(changeTileCameraListPage).map((pageList, objectIndex) => {
        const findIndex = pageList.findIndex(value => value.uid === selectTileCamera.uid);
        if (findIndex !== -1) {
          currentPageNumber = objectIndex + 1;
        }
        return null;
      });

      yield put(setBackupTileCameraListPage({
        backupTileCameraListPage: beforeTileCameraListPage,
      }));

      beforePageNumber = beforePageNumberStore;
    }

    yield call(LayoutPageInfomationChange, {
      tileCameraListPage: changeTileCameraListPage,
      layoutPageCurrentNumber: currentPageNumber,
      layoutPageMaxNumber: currentMaxNumber,
      layoutPagePatternTileCount: currentPatternTileCount,
    });
  }
}

function* asyncGetDeviceDateSaga() {
  while (true) {
    yield take(liveMediaControlActions.GET_DEVICE_DATE);
    const result = yield SunapiClient.get('/stw-cgi/system.cgi?msubmenu=date&action=view');

    yield put(liveMediaControlActions.getDeviceDateSuccess({
      systemDataInfo: result.data,
    }));
  }
}

// 18MB: 18,874,368B, 20MB: 20,971,520B
// 2560 x 9ch을 지원하기 위해  23,592,960B 허용으로 변경
const limitResolution18M = 18880000;
// const limitBitrate20M = 20980000;
const limitBitrate20M = 23600000;
// H265 채널 개수 4개 이상일 경우 화면 정지
const limitH265Resolution4M = 4194304;
let limitH265Channel = true;

let controlnotOverTotalResolution = true;

function* asyncVideoResolutionCheckSaga() {
  while (true) {
    yield take(liveMediaControlActions.CONTROL_RESOLUTION);

    const moduleState = yield select(state => ({
      tileCameraListPage: state.cameraInfoModule.get('tileCameraListPage').toJS(),
      resolutionInfo: state.liveMediaControlModule.get('resolutionInfo').toJS(),
      layoutPageCurrentNumber: state.liveMediaControlModule.get('layoutPageInfo').get('currentNumber'),
    }));

    const { resolutionInfo, layoutPageCurrentNumber, tileCameraListPage } = moduleState;
    const tileCameraList = tileCameraListPage[layoutPageCurrentNumber];
    let totalResolution = 0;
    let totalBitrate = 0;
    // H256일 경우 추가 시나리오 발생
    let totalH265Channel = 0;
    let totalH265Resolution = 0;
    let totalH265Bitrate = 0;

    for (let i = 0; i < resolutionInfo.length; i += 1) {
      if (!Number.isNaN(resolutionInfo[i].resolution)) {
        totalResolution += resolutionInfo[i].resolution;
      } else {
        console.log('resolution is NaN ', resolutionInfo[i].uid);
      }

      if (!Number.isNaN(resolutionInfo[i].bitrate)) {
        totalBitrate += (resolutionInfo[i].bitrate * 1000);
      } else {
        console.log('bitrate is NaN ', resolutionInfo[i].uid);
      }

      // for DEBUG
      if (!(totalBitrate < limitBitrate20M)) {
        console.log('**', resolutionInfo[i].uid, 'totalBitrate ', totalBitrate);
      }
      if (!(totalResolution < limitResolution18M)) {
        console.log('**', resolutionInfo[i].uid, 'totalResolution ', totalResolution);
      }

      controlnotOverTotalResolution = totalResolution < limitResolution18M
      && totalBitrate < limitBitrate20M;

      // H256일 경우 추가 시나리오 발생 Chrome일 경우
      if ((resolutionInfo[i].codec === 'H265') && (resolutionInfo[i].browser === 'Chrome')) {
        totalH265Channel += 1;

        if (totalH265Channel > 4) {
          limitH265Channel = false;
        } else {
          limitH265Channel = true;
        }

        // Resolution
        if (!Number.isNaN(resolutionInfo[i].resolution)) {
          totalH265Resolution += resolutionInfo[i].resolution;
        } else {
          console.log('resolution is NaN ', resolutionInfo[i].uid);
        }
        // Bitrate
        if (!Number.isNaN(resolutionInfo[i].bitrate)) {
          totalH265Bitrate += (resolutionInfo[i].bitrate * 1000);
        } else {
          console.log('bitrate is NaN ', resolutionInfo[i].uid);
        }

        // for DEBUG
        if (!(totalH265Resolution < limitH265Resolution4M)) {
          console.log('*** totalH265Resolution ', totalH265Resolution);
        }
        if (!(totalH265Bitrate < limitH265Resolution4M)) {
          console.log('*** totalH265Bitrate ', totalH265Bitrate);
        }

        controlnotOverTotalResolution = controlnotOverTotalResolution
        && limitH265Channel
        && totalH265Resolution < limitH265Resolution4M
        && totalH265Bitrate < limitH265Resolution4M;
      } else {
        controlnotOverTotalResolution = totalResolution < limitResolution18M
        && totalBitrate < limitBitrate20M;
      }
      // 불필요 action 호출 방지위해 추가
      const findTile = tileCameraList.find(x => x.uid === resolutionInfo[i].uid);
      if (typeof findTile !== 'undefined') {
        if ((findTile.notOverTotalResolution) !== (controlnotOverTotalResolution)) {
          yield put(setTileCameraData({
            uid: resolutionInfo[i].uid,
            layoutPageCurrentNumber,
            data: {
              notOverTotalResolution: controlnotOverTotalResolution,
            },
          }));
        }
      }
    }
  }
}

export default function* rootHeaderSaga() {
  yield all([
    asyncAlarmStopSaga(),
    asyncLayoutPageChangeSaga(),
    asyncManualRecordingStartSaga(),
    asyncManualRecordingStopSaga(),
    asyncGetDeviceDateSaga(),
    asyncVideoResolutionCheckSaga(),
  ]);
}
