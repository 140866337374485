import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { withContainer } from 'wisenet-ui/util/hoc';
import Slider from 'rc-slider';
import { ToggleContainer } from 'wisenet-ui/containers/organisms';
import { WrapperStyled, SliderWrapperStyled } from './ToggleStyled';
import 'rc-slider/assets/index.css';

const Toggle = ({
  theme,
  className,
  handleStyle,
  railStyle,
  trackStyle,
  value,
  handleText,
  disabled,
  setRef,
  onAfterChange,
}) => {
  const attrs = {
    handleStyle: {
      backgroundColor: theme.background.color1,
      border: `1px solid ${disabled ? theme.line.color4 : theme.text.color3}`,
      height: 23,
      width: 23,
      marginLeft: value === 0 ? 0 : -22,
      marginTop: -4,
      cursor: disabled ? 'default' : 'pointer',
      ...handleStyle,
    },
    trackStyle: {
      backgroundColor: disabled ? theme.background.color7 : theme.line.color2,
      height: 14,
      width: null,
      cursor: disabled ? 'default' : 'pointer',
      ...trackStyle,
    },
    railStyle: {
      backgroundColor: disabled ? theme.background.color7 : theme.line.color2,
      height: 14,
      width: null,
      cursor: disabled ? 'default' : 'pointer',
      ...railStyle,
    },
  };
  return (
    <WrapperStyled
      className={className}
    >
      <SliderWrapperStyled
        value={value}
        handleText={handleText}
        disabled={disabled}
      >
        <Slider
          ref={el => setRef(el)}
          min={0}
          max={1}
          value={value}
          disabled={disabled}
          onAfterChange={onAfterChange}
          {...attrs}
        />
      </SliderWrapperStyled>
    </WrapperStyled>
  );
};

Toggle.defaultProps = {
  className: null,
  handleStyle: null,
  railStyle: null,
  trackStyle: null,
  theme: null,
  handleText: null,
  disabled: false,
};

Toggle.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  handleStyle: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  railStyle: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  trackStyle: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  theme: PropTypes.oneOfType([PropTypes.any]),
  value: PropTypes.number.isRequired,
  handleText: PropTypes.oneOfType([PropTypes.array]),
  disabled: PropTypes.bool,
  setRef: PropTypes.func.isRequired,
  onAfterChange: PropTypes.func.isRequired,
};

export default withContainer(ToggleContainer, withTheme(Toggle));
