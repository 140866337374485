import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { SearchOptions } from 'components/organisms';
import { getLanguage } from 'util/lib';
import RecordStatusType from 'wisenet-ui/util/static/constants/mediaControl/recordStatusType';
import {
  SearchOptionLeftStyled,
  DropDownMenuStyled,
  ListSelectStyled,
} from './SearchTimelineOptionLeftStyled';

const CHECKED = 1;
// const PARTIALCHECKED = 2;
// const UNCHECKED = 0;

// Timeline에 그려질 색과 맞춤
// TimelineStyled.jsx 참고
const IconTypeNormalManual = '#8dc63f';
const IconTypeEvent = 'red';
// const IconTypeDST = '#22ae14';

class SearchTimelineOptionLeft extends Component {
  state = {
    authDropdownVisible: false,
    treeData: [
      {
        id: 'all',
        name: 'lang_all',
        checked: CHECKED,
        expanded: true,
        children: [
          {
            id: RecordStatusType.NORMAL, // 'Normal',
            name: RecordStatusType.getLanguageKey(RecordStatusType.NORMAL),
            checked: CHECKED,
            parents: ['all'],
            icon: {
              type: 'circle',
              color: IconTypeNormalManual,
            },
          },
          {
            id: RecordStatusType.MANUAL, // 'Manual',
            name: RecordStatusType.getLanguageKey(RecordStatusType.MANUAL),
            checked: CHECKED,
            parents: ['all'],
            icon: {
              type: 'circle',
              color: IconTypeNormalManual,
            },
          },
          {
            id: RecordStatusType.MOTION_DETECTION, // 'MotionDetection',
            name: RecordStatusType.getLanguageKey(RecordStatusType.MOTION_DETECTION),
            checked: CHECKED,
            parents: ['all'],
            icon: {
              type: 'circle',
              color: IconTypeEvent,
            },
          },
          {
            id: RecordStatusType.VIDEO_ANALYSIS, // 'VideoAnalysis',
            name: RecordStatusType.getLanguageKey(RecordStatusType.VIDEO_ANALYSIS),
            checked: CHECKED,
            parents: ['all'],
            icon: {
              type: 'circle',
              color: IconTypeEvent,
            },
            expanded: true,
          },
          {
            id: RecordStatusType.FACE_DETECTION, // 'FaceDetection',
            name: RecordStatusType.getLanguageKey(RecordStatusType.FACE_DETECTION),
            checked: CHECKED,
            parents: ['all'],
            icon: {
              type: 'circle',
              color: IconTypeEvent,
            },
          },
          {
            id: RecordStatusType.TAMPERING_DETECTION, // 'TamperingDetection',
            name: RecordStatusType.getLanguageKey(RecordStatusType.TAMPERING_DETECTION),
            checked: CHECKED,
            parents: ['all'],
            icon: {
              type: 'circle',
              color: IconTypeEvent,
            },
          },
          {
            id: RecordStatusType.AUTO_TRACKING, // 'AutoTracking',
            name: RecordStatusType.getLanguageKey(RecordStatusType.AUTO_TRACKING),
            checked: CHECKED,
            parents: ['all'],
            icon: {
              type: 'circle',
              color: IconTypeEvent,
            },
          },
          {
            id: RecordStatusType.DEFOCUS_DETECTION, // 'DefocusDetection',
            name: RecordStatusType.getLanguageKey(RecordStatusType.DEFOCUS_DETECTION),
            checked: CHECKED,
            parents: ['all'],
            icon: {
              type: 'circle',
              color: IconTypeEvent,
            },
          },
          {
            id: RecordStatusType.FOG_DETECTION, // 'FogDetection',
            name: RecordStatusType.getLanguageKey(RecordStatusType.FOG_DETECTION),
            checked: CHECKED,
            parents: ['all'],
            icon: {
              type: 'circle',
              color: IconTypeEvent,
            },
          },
          {
            id: RecordStatusType.AUDIO_DETECTION, // 'AudioDetection',
            name: RecordStatusType.getLanguageKey(RecordStatusType.AUDIO_DETECTION),
            checked: CHECKED,
            parents: ['all'],
            icon: {
              type: 'circle',
              color: IconTypeEvent,
            },
          },
          {
            id: RecordStatusType.AUDIO_ANALYSIS, // 'AudioAnalysis',
            name: RecordStatusType.getLanguageKey(RecordStatusType.AUDIO_ANALYSIS),
            checked: CHECKED,
            parents: ['all'],
            icon: {
              type: 'circle',
              color: IconTypeEvent,
            },
            expanded: true,
          },
          {
            id: RecordStatusType.ALARM_INPUT, // 'AlarmInput',
            name: RecordStatusType.getLanguageKey(RecordStatusType.ALARM_INPUT),
            checked: CHECKED,
            parents: ['all'],
            icon: {
              type: 'circle',
              color: IconTypeEvent,
            },
          },
        ],
      },
    ],
  }

  componentDidMount() {
    // 처음 theme 적용을 위한 재 랜더
    this.forceUpdate();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      overlappedIDList: nextOverlappedIDList,
      currentTabName: nextCurrentTabName,
      selectEvent: nextSelectEvent,
      selectedOverlappedID: nextSelectedOverlappedID,
    } = nextProps;

    const {
      overlappedIDList,
      currentTabName,
      selectEvent,
      selectedOverlappedID,
    } = this.props;

    const {
      authDropdownVisible: nextAuthDropdownVisible,
    } = nextState;

    const {
      authDropdownVisible,
    } = this.state;

    if (JSON.stringify(nextOverlappedIDList) === JSON.stringify(overlappedIDList)
      && JSON.stringify(nextCurrentTabName) === JSON.stringify(currentTabName)
      && JSON.stringify(nextSelectEvent) === JSON.stringify(selectEvent)
      && JSON.stringify(nextAuthDropdownVisible) === JSON.stringify(authDropdownVisible)
      && JSON.stringify(nextSelectedOverlappedID) === JSON.stringify(selectedOverlappedID)
    ) {
      return false;
    }

    return true;
  }

  updateTreeData = treeDatas => {
    this.setState({
      treeData: treeDatas,
    });
  }

  onChangeAuthDropdownVisible = () => {
    const { authDropdownVisible } = this.state;
    this.setState({
      authDropdownVisible: !authDropdownVisible,
    });
  }

  render() {
    const {
      onClick,
      overlappedIDList,
      currentTabName,
      selectEvent,
      selectedOverlappedID,
      IconStyled,
    } = this.props;

    const {
      authDropdownVisible,
      treeData,
    } = this.state;

    const transitionData = treeData && treeData.map(item => {
      if (item.children) {
        return {
          ...item,
          name: getLanguage(item.name),
          children: item.children.map(childItem => ({
            ...childItem,
            name: getLanguage(childItem.name),
          })),
        };
      }
      return {
        ...item,
        name: getLanguage(item.name),
      };
    });
    const dropdownMenuItems = [
      (
        <div key="filter">
          <SearchOptions
            treeData={transitionData}
            onCloseFilter={this.onChangeAuthDropdownVisible}
            updateTreeData={this.updateTreeData}
          />
        </div>
      ),
    ];

    const DropDownMenu = () => (
      <DropDownMenuStyled
        isOpend={authDropdownVisible}
        onClickOutside={this.onChangeAuthDropdownVisible}
        menuItems={dropdownMenuItems}
      >
        <IconStyled onClick={this.onChangeAuthDropdownVisible} className="wni wni-filter" />
      </DropDownMenuStyled>
    );

    const searchOptionLeftContent = currentTabName === 'eventTab'
      ? (
        <React.Fragment>
          <DropDownMenu />
        </React.Fragment>
      ) : (
        <ListSelectStyled
          listData={selectEvent.toJS().ChannelIDList
            ? selectEvent.toJS().ChannelIDList.map(item => ({
              data: item,
              text: `CH ${item + 1}`,
            }))
            : []}
          onChange={onClick('channel')}
        />
      );

    return (
      <SearchOptionLeftStyled>
        {searchOptionLeftContent}
        <ListSelectStyled
          listData={currentTabName === 'eventTab'
            ? overlappedIDList.map((overlappedID, index) => ({
              data: overlappedID,
              text: `${getLanguage('lang_overlapped')} ${index}`,
              selected: overlappedID === selectedOverlappedID,
            }))
            : [{
              data: selectedOverlappedID,
              text: `${getLanguage('lang_overlapped')} ${0}`, // 선택된 index 값 기입 해야함.
            }]}
          onChange={onClick('overlap')}
        />
      </SearchOptionLeftStyled>
    );
  }
}

SearchTimelineOptionLeft.propTypes = {
  IconStyled: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  overlappedIDList: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedOverlappedID: PropTypes.number.isRequired,
  currentTabName: PropTypes.string.isRequired,
  selectEvent: PropTypes.instanceOf(Map).isRequired,
};

export default SearchTimelineOptionLeft;
