import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

const ResizeButton = ({
  locX,
  locY,
  size,
  onMouseDown,
  onMouseUp,
}) => {
  const [isHighlight, setIsHighlight] = useState(false);
  useEffect(() => {
    console.log('ResizeButton DidMount');
  }, []);
  const onMouseOver = () => {
    setIsHighlight(true);
  };

  const onMouseLeave = () => {
    setIsHighlight(false);
  };

  const onFocus = () => {

  };

  let stroke;
  if (isHighlight === true) {
    stroke = '#282828';
  } else {
    stroke = '#484848';
  }
  const points = `${locX},${locY - size} ${locX},${locY} ${locX - size},${locY}`;
  return (
    <polyline
      id="resize-button"
      points={points}
      fill="transparent"
      stroke={stroke}
      strokeWidth="3"
      onMouseOver={onMouseOver}
      onFocus={onFocus}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    />
  );
};

ResizeButton.propTypes = {
  locX: PropTypes.number.isRequired,
  locY: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
};

ResizeButton.defaultProps = {
  onMouseDown: null,
  onMouseUp: null,
};

export default ResizeButton;
